<template>
  <div class="container">
    <div class="row mt-2">
      <div class="col">
        <h2 class="text-center">Estadisticas</h2>
      </div>
      <div class="w-100"></div>
    </div>
    <div class="row justify-content-center align-items-end">
      <div class="col-3">
        <small class="text-muted">FECHA INICIAL</small>
        <date-picker
          valueType="format"
          v-model="startDate"
          input-class="form-control text-center"
        />
      </div>
      <div class="col-3">
        <small class="text-muted">FECHA FINAL</small>
        <date-picker
          valueType="format"
          v-model="endDate"
          input-class="form-control text-center"
        />
      </div>
      <div class="col-2">
        <button class="btn btn-success" @click="load()">ACEPTAR</button>
      </div>
      </div>
      <!-- <div class="col-3 border text-center py-3 bg-white">
        <h2>${{formatNumber()}}</h2>
        <div class="w-100"></div>
        <small>TOTAL VENTAS</small>
      </div>
      <div class="col-3 border text-center py-3 bg-white">
        <h2>${{formatNumber()}}</h2>
        <div class="w-100"></div>
        <small>TOTAL DESCUENTOS</small>
      </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  mounted() {
    this.startDate = this.$moment().startOf('month').format()
    this.endDate = this.$moment().endOf('month').format()
  },
  methods: {
    load() {
      console.log(this.$moment(this.endDate).diff(this.startDate, 'days'))
      if(this.$moment(this.endDate).diff(this.startDate, 'days') > 90) {
        return this.$swal({
          icon:'error',
          title: 'Error',
          text: 'Maximo 90 dias'
        })
      } 
    }
  },
  computed: {},
};
</script>