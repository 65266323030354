<template>
  <div
    class="row bgwhite no-gutters h-100 d-flex"
    style="margin-top: -20px; min-widht: 100vw"
  >
    <div class="col-12">
      <b-tabs
        fill
        class="w-100 px-0"
        active-nav-item-class="bg-primary text-white font-weight-bold"
        v-model="tab"
      >
        <template #tabs-end>
          <b-nav-item href="#" role="presentation" @click="toggleMenu()">{{
            !$store.state.showMenu ? "MOSTRAR" : "OCULTAR"
          }}</b-nav-item>
        </template>
        <b-tab title="MAPA" v-if="info.deliveryEnabled">
          <div class="row no-gutters">
            <div class="col">
              <div class="row">
                <div class="col-lg-6 col-md-6 overflow-hidden">
                  <div
                    class="badge d-none text-center font-weight-bold py-2 mb-2"
                    role="alert"
                    :class="{
                      'badge-primary': status == 'Conectando',
                      'badge-success': status == 'Conectado',
                      'badge-warning': status == 'Reconectando',
                    }"
                  >
                    {{ status.toUpperCase() }}
                  </div>
                  <ul class="list-group mt-2">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col">
                          <h1 class="text-center">
                            {{ timeFormated }}
                          </h1>
                          <small class="d-block text-center text-uppercase">
                            {{ time | moment("dddd LL") }}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li style="list-style: none">
                      <div class="row no-gutters mt-2">
                        <div class="col">
                          <div
                            class="badge d-block"
                            v-if="info.webEnabled"
                            :class="{
                              'badge-danger': webTicketsPending > 0,
                              'badge-light': webTicketsPending == 0,
                            }"
                          >
                            <h4 class="mb-0">
                              <font-awesome-icon icon="bell" />
                              {{ webTicketsPending }}
                            </h4>
                            <div class="w-100"></div>
                            <small>WEB</small>
                          </div>
                        </div>

                        <div class="col">
                          <div class="badge d-block badge-success">
                            <h4 class="mb-0">
                              <font-awesome-icon icon="check-circle" />
                              {{ ticketsSuccess }}
                            </h4>
                            <div class="w-100"></div>
                            <small>TOTAL HOY</small>
                          </div>
                        </div>

                        <div class="col">
                          <div class="d-block px-1 badge badge-warning">
                            <h4 class="mb-0">
                              <font-awesome-icon icon="clock" />
                              {{ ticketsPending }}
                            </h4>
                            <div class="w-100"></div>
                            <small>PENDIENTES</small>
                          </div>
                        </div>

                        <div class="w-100 py-1"></div>

                        <div class="col-6">
                          <div class="badge d-block badge-warning">
                            <h4 class="mb-0">
                              <font-awesome-icon icon="clock" />
                              {{ nextTicketHour }}
                              <small
                                style="
                                  font-size: 0.55em;
                                  position: absolute;
                                  bottom: -2px;
                                  right: 0px;
                                "
                                class="p-1 d-none"
                                >()</small
                              >
                            </h4>
                            <div class="w-100"></div>
                            <small
                              >SIG PEDIDO
                              <span v-if="nextTicketHourinMinutes != -1"
                                >EN {{ nextTicketHourinMinutes }}"</span
                              ></small
                            >
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="px-1 badge d-block badge-info">
                            <h4 class="mb-0">
                              <font-awesome-icon icon="clock" />
                              {{ lastTicketHour }}
                            </h4>
                            <div class="w-100"></div>
                            <small>ULTIMO PEDIDO</small>
                          </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-3 d-none">
                          <h4 class="px-1">
                            <span class="badge badge-info w-100">
                              <font-awesome-icon icon="car-side" />
                            </span>
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col pr-4">
                  <small>Conectados: {{ locations.length }}</small>
                  <div
                    class="overflow-auto custom_scroll"
                    style="max-height: 30vh"
                  >
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="location in locations"
                        :key="location._id"
                      >
                        <div class="row align-items-center no-gutters">
                          <div class="col-1">
                            <b-form-checkbox
                              name="check-button"
                              v-model="focus"
                              size="md"
                              :value="location"
                              :checked="focus._id == location._id"
                            ></b-form-checkbox>
                          </div>
                          <div class="col-7 pl-2">
                            <div class="row">
                              <div class="col">
                                <span
                                  class="text-uppercase font-weight-bold"
                                  style="font-size: 0.8em"
                                >
                                  {{ location.user.name }}
                                </span>
                              </div>
                              <div class="w-100"></div>
                              <div class="col">
                                <small class="d-block text-muted">
                                  <font-awesome-icon
                                    icon="eye"
                                    class="mr-1 d-none"
                                  />
                                  {{
                                    location.updatedAt | moment("from", "now")
                                  }}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="w-100"></div>

                <div class="col-12 px-0">
                  <GmapMap
                    ref="mapRef"
                    :center="centerMap"
                    :zoom="focus ? 14.7 : 13"
                    :options="{
                      mapTypeControl: false,
                      showTraffic: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      draggable: !focus ? true : false,
                    }"
                    map-type-id="roadmap"
                    class="map"
                    :style="{
                      height: '70vh',
                    }"
                  >
                    <GmapMarker
                      v-for="location in locations"
                      :key="location._id"
                      :label="`${location.user.name[0].toUpperCase()}`"
                      :position="{
                        lat: location.latitude,
                        lng: location.longitude,
                      }"
                    ></GmapMarker>

                    <!-- <GmapMarker
                  v-if="info.location"
                 
                  :position="info.location"
                  :shape="shape"
                ></GmapMarker> -->
                    <GmapInfoWindow
                      v-if="info.location"
                      :options="{
                        pixelOffset: {
                          width: 0,
                          height: -25,
                        },
                        zIndex: -10,
                        content: `
                    <div class='text-center'> 
                      <img src='${getLogo(
                        info.logo
                      )}' class='d-block' height='20' />
                    
                      </div>
                    `,
                      }"
                      :position="info.location"
                    ></GmapInfoWindow>
                  </GmapMap>
                </div>
              </div>

              <div
                style="
                  position: absolute;
                  z-index: 999;
                  bottom: 30px;
                  left: 50%;
                  width: 200px;
                "
              >
                <div style="position: absolute; left: -50%; top: -90px">
                  <img
                    :src="getLogo(info.logo)"
                    v-if="info.logo"
                    alt="Logo"
                    class="img-fluid py-4"
                    style="max-width: 200px; max-height: 100px"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 tickets px-2 custom_scroll">
              <ul class="list-group">
                <li
                  class="list-group-item py-0"
                  v-for="ticket in tickets"
                  :key="ticket._id"
                  :class="{
                    'list-group-item-primary':
                      ticket.delivery.status == 'En camino',
                    'list-group-item-success':
                      ticket.delivery.status == 'Entregado',
                  }"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="row align-items-center">
                        <div class="col px-0">
                          <small class="px-0"> {{ ticket.hour }} - </small>
                          <small class="font-weight-bold text-uppercase pl-0">
                            {{ ticket.clientName }} - {{ ticket.address }}
                          </small>
                          <span class="d-block">
                            <span
                              class="text-uppercase pr-2 d-inline-block address"
                            >
                              {{ getPayment(ticket) }}
                            </span>
                          </span>
                        </div>
                        <div class="col-3">
                          <small
                            v-if="ticket.type == 'Retiro'"
                            class="badge text-white bg-dark badge-pill d-block py-1"
                            style="font-size: 10px"
                          >
                            RETIRO
                          </small>
                          <small
                            v-else
                            :class="{
                              'badge-success':
                                ticket.delivery.status == 'Entregado',
                              'badge-primary':
                                ticket.delivery.status == 'Esperando',
                              'badge-warning':
                                ticket.delivery.status == 'En camino',
                              'badge-secondary':
                                ticket.delivery.status == 'A bordo',
                            }"
                            class="badge badge-pill d-block py-1"
                            style="font-size: 10px"
                          >
                            {{ ticket.delivery.status }}
                          </small>
                          <small
                            v-if="ticket.delivery_user"
                            class="badge badge-info badge-pill d-block mt-1 py-1 text-uppercase"
                            style="font-size: 10px; overflow-x: hidden"
                          >
                            {{ ticket.delivery_user.name }}
                          </small>
                        </div>
                        <div class="col-12 px-0">
                          <div class="w-100"></div>
                          <span v-if="ticket.events.length != 0">
                            <small
                              :key="i"
                              v-for="(event, i) in ticket.events"
                              style="display: block; font-size: 12px"
                              class=""
                            >
                              <div
                                v-if="
                                  ticket.delivery.status != 'Entregado' ||
                                  i > ticket.events.length - 2
                                "
                                v-show="event.user.name != 'SYSTEM'"
                              >
                                <strong>
                                  ({{ event.createdAt | moment("HH:mm") }})
                                  {{
                                    event.createdAt
                                      | moment("from", "now", true)
                                  }}
                                </strong>
                                {{ event.user.name }} {{ event.value }}
                              </div>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item d-none">
                  {{ lastUpdated | moment("HH:mm:ss") }} -
                  {{ $moment(lastUpdated).fromNow() }}
                </li>
              </ul>
            </div>
          </div>
        </b-tab>
        <b-tab title="COMANDAS" class="h-100" v-if="info.deliveryEnabled">
          <div class="fixed-bottom" style="bottom: 47px">
            <div
              class="container1"
              style="width: 19%; min-width: 300px; margin: 0 auto"
            >
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col">
                      <h2 class="text-center">
                        {{ timeFormated }}
                      </h2>
                      <small class="d-block text-center text-uppercase">
                        {{ time | moment("dddd LL") }}
                      </small>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div style="width: 100%; overflow-x: scroll; height: 90vh">
            <ul class="list-group list-group-horizontal h-100">
              <li
                class="list-group-item w-100 h-100"
                v-if="pendingTickets.length == 0"
              >
                <h3 class="text-center pt-5 h-100">No hay pedidos</h3>
              </li>
              <li
                class="list-group-item py-0 px-3 overflow-hidden"
                v-for="ticket in pendingTickets"
                :key="ticket._id"
                :style="{
                  'min-width': `${ticketWidth}px`,
                  'width': `${ticketWidth}px`,
                }"
                :class="{
                  'list-group-item-primary':
                    ticket.delivery.status == 'En camino',
                  'list-group-item-success':
                    ticket.delivery.status == 'Entregado',
                }"
              >
                <div class="row px-0">
                  <div class="col text-center mb-2 px-0 align-self-end">
                    <div class="badge badge-info d-block">
                      <h3 class="px-0 mb-0">{{ ticket.hour || "--:--" }}</h3>
                      <small>{{ ticket.createdAt | moment("HH:mm") }}</small>
                    </div>
                  </div>
                  <div
                    class="col-3 px-0"
                    v-if="ticket.delivery.status != 'Entregado'"
                  >
                    <button
                      class="btn btn-block btn-outline-success btn-lg h-100"
                      @click="setReady(ticket._id)"
                      :disabled="loading"
                    >
                      <font-awesome-icon icon="check-circle" size="1x" />
                    </button>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-12 px-1">
                    <div class="row no-gutters align-items-center pb-1">
                      <div class="col-2 text-center pr-1">
                        <font-awesome-icon
                          class="text-muted"
                          size="2x"
                          :icon="ticket.type == 'Retiro' ? 'building' : 'home'"
                        />
                      </div>
                      <div class="col-10">
                        <span class="font-weight-bold text-uppercase pl-0">
                          {{ ticket.clientName }}
                        </span>
                        <span class="d-block">
                          <span
                            class="text-uppercase pr-2 d-inline-block address"
                          >
                            {{ ticket.address }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 px-0 "
                    :id="`t${ticket._id}`"
                    :class="{
                      'container-items': ticketColumns > 1,
                    }"
                    style="max-height: 70vh"
                  >
                    <div
                      class="row px-2 "
                      :style="{
                        'font-size': `${fontSize}px`,
                      }"
                    >
                      <div
                        class="mb-1 border bglight py-2 overflow-hidden"
                        :class="{
                          'col-12': ticketColumns == 1,
                          'col-6': ticketColumns == 2,
                          'col-4': ticketColumns == 3,
                        }"
                        :key="`${ticket._id}-${product._id}-${i}`"
                        v-for="(product, i) in ticket.products"
                      >
                        <span class="text-uppercase font-weight-bold"
                          >{{ product.qty }} x {{ product.name }}</span
                        >
                        <div
                          class="row"
                          v-if="product.options && product.options.length > 0"
                        >
                          <div class="col-12 text-center">
                            <div class="w-75 border-bottom"></div>
                          </div>
                          <div
                            class="col-12 ml-1"
                            v-for="(option, i) in product.options"
                            :key="i"
                            :style="{
                              'font-size': `${fontSize - 3}px`,
                            }"
                          >
                            <span>
                              <span class="text-uppercase" v-if="option.label">
                                {{ option.label }}:
                              </span>
                              <span
                                class="font-weight-bold text-uppercase"
                                v-if="!option.ref"
                                >{{
                                  option.selected
                                    ? option.selected
                                    : "- - - - -"
                                }}</span
                              >
                              <span
                                class="font-weight-bold text-uppercase"
                                v-else-if="
                                  option.values && option.values.length > 0
                                "
                                >{{
                                  option.values
                                    ? option.values
                                        .map((o) => {
                                          if (o.value)
                                            return `${o.name} ($${o.value})`;
                                          else return `${o.name}`;
                                        })
                                        .join(" ")
                                        .replace("undefined", "- - - -")
                                    : ""
                                }}</span
                              >
                              <span
                                class="font-weight-bold text-uppercase"
                                v-else
                                >{{
                                  option.selected
                                    ? option.selected
                                    : "- - - - -"
                                }}
                                {{
                                  option.selected && option.selected.value
                                    ? `$${option.selected.value}`
                                    : ""
                                }}</span
                              >
                            </span>
                          </div>

                         
                        </div>
                         <div
                            class="col-12 bgwhite border px-1 py-1"
                            v-if="product.details"
                          >
                            <!-- <span class="font-weight-bold1">NOTAS: </span> -->
                            <span
                              style="
                                padding: 0px 0px;
                                margin-top: 0px;
                                white-space: pre-line;
                                font-family: sans-serif;
                                text-transform: uppercase;
                                font-weight: bold;
                              "
                              :style="{
                                'font-size': `${fontSize - 2}px`,
                              }"
                              >- {{ product.details }}</span
                            >
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </b-tab>
        <b-tab
          title="COMANDAS/MESAS"
          class="h-100"
          v-if="info.restaurantEnabled"
        >
          <div class="fixed-bottom" style="bottom: 47px">
            <div
              class="container1"
              style="width: 30%; min-width: 300px; margin: 0 auto"
            >
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col">
                      <h1 class="text-center">
                        {{ timeFormated }}
                      </h1>
                      <small class="d-block text-center text-uppercase">
                        {{ time | moment("dddd LL") }}
                      </small>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div style="width: 100%; overflow-x: scroll; height: 90vh">
            <ul class="list-group list-group-horizontal h-100">
              <li
                class="list-group-item w-100 h-100"
                v-if="pendingTables.length == 0"
              >
                <h3 class="text-center pt-5 h-100">No hay pedidos</h3>
              </li>
              <li
                class="list-group-item py-0 px-3"
                v-for="(ticket, i) in pendingTables"
                :key="i"
                :style="{
                  'min-width': `${ticketWidth}px`,
                }"
              >
                <div class="row">
                  <div class="col">
                    <div class="col text-center mb-2 px-0 align-self-end">
                      <div class="badge badge-info d-block">
                        <h3 class="px-0 mb-0">
                          {{ ticket.updatedAt | moment("HH:mm") }}
                        </h3>
                      </div>
                    </div>
                    <div class="col-12 px-1">
                      <div class="row no-gutters align-items-center pb-1">
                        <div class="col-10">
                          <span class="font-weight-bold text-uppercase pl-0">
                            {{ ticket.name }} - {{ ticket.location }}
                          </span>
                          <span class="d-block" v-if="ticket.user">
                            <span class="text-uppercase pr-2 d-inline-block">
                              {{ ticket.user.name }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 px-0"
                      :id="`t${ticket._id}`"
                      :class="{
                        'container-items': ticketColumns > 1,
                      }"
                      style="max-height: 70vh"
                    >
                      <div
                        :key="`${ticket._id}-${product._id}-${i}`"
                        v-for="(product, i) in ticket.products"
                        class="row my-1 border no-gutters bglight p-1 w-100"
                        :style="{
                          'font-size': `${fontSize}px`,
                          'max-width': `${ticketWidth / ticketColumns}px`,
                        }"
                      >
                        <div class="col-12 mb-2">
                          <span class="text-uppercase font-weight-bold"
                            >{{ product.qty }} x {{ product.name }} ({{
                              product.createdAt | moment("HH:mm")
                            }})</span
                          >
                          <div
                            class="row"
                            v-if="product.options && product.options.length > 0"
                          >
                            <div class="col-12 text-center">
                              <div class="w-75 border-bottom"></div>
                            </div>
                            <div
                              class="col-12 ml-1"
                              v-for="(option, i) in product.options"
                              :key="i"
                              :style="{
                                'font-size': `${fontSize - 3}px`,
                              }"
                            >
                              <span>
                                <span
                                  class="text-uppercase"
                                  v-if="option.label"
                                >
                                  {{ option.label }}:
                                </span>
                                <span
                                  class="font-weight-bold text-uppercase"
                                  v-if="!option.ref"
                                  >{{
                                    option.selected
                                      ? option.selected
                                      : "- - - - -"
                                  }}</span
                                >
                                <span
                                  class="font-weight-bold text-uppercase"
                                  v-else-if="
                                    option.values && option.values.length > 0
                                  "
                                  >{{
                                    option.values
                                      ? option.values
                                          .map((o) => {
                                            if (o.value)
                                              return `${o.name} ($${o.value})`;
                                            else return `${o.name}`;
                                          })
                                          .join(" ")
                                          .replace("undefined", "- - - -")
                                      : ""
                                  }}</span
                                >
                                <span
                                  class="font-weight-bold text-uppercase"
                                  v-else
                                  >{{
                                    option.selected
                                      ? option.selected
                                      : "- - - - -"
                                  }}
                                  {{
                                    option.selected && option.selected.value
                                      ? `$${option.selected.value}`
                                      : ""
                                  }}</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-12 bgwhite border px-1 py-2"
                          v-if="product.details"
                        >
                          <!-- <span class="font-weight-bold1">NOTAS: </span> -->
                          <span
                            style="
                              padding: 0px 0px;
                              margin-top: 0px;
                              white-space: pre-line;
                              font-family: sans-serif;
                              text-transform: uppercase;
                              font-weight: bold;
                            "
                            :style="{
                              'font-size': `${fontSize - 2}px`,
                            }"
                            >- {{ product.details }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </b-tab>
        <b-tab title="SLIDER" v-if="enabledSlider">
          <div class="row">
            <div class="col">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="sliderInterval * 1000"
                :controls="sliderControls"
                :indicators="sliderIndicators"
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333"
              >
                <b-carousel-slide
                  v-for="(slide, i) in sliders"
                  :key="`slider-${i}`"
                  :caption="slide.caption"
                  :text="slide.text"
                  :img-src="slide.url"
                ></b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </b-tab>
        <b-tab title="CONFIGURACION">
          <div class="row mt-2 px-5 py-3 h-100 align-items-end">
            <div class="col">
              <div class="row" v-if="info.deliveryEnabled">
                <div class="col-12 mb-3">
                  <h3>General</h3>
                </div>
                <div class="col">
                  <b-form-checkbox
                    v-model="showLocal"
                    name="check-button"
                    switch
                  >
                    Mostrar retiros? <b>({{ showLocal ? "Si" : "No" }})</b>
                  </b-form-checkbox>
                  <small class="text-muted"
                    >Desapareceran una vez pasada la hora de entrega</small
                  >
                </div>
                <div class="col">
                  <b-form-checkbox
                    v-model="onlyWithTime"
                    name="check-button"
                    switch
                  >
                    Solo pedidos con hora <b
                      >({{ onlyWithTime ? "Si" : "No" }})</b
                    >
                  </b-form-checkbox>
                  <small class="text-muted"
                    >Mostrar pedidos que tengan hora de pedido</small
                  >
                </div>
                <div class="col">
                  <b-form-checkbox
                    v-model="showTraffic"
                    name="check-button"
                    switch
                  >
                    Mostrar trafico <b>({{ showTraffic ? "Si" : "No" }})</b>
                  </b-form-checkbox>
                  <small class="text-muted"
                    >Se mostrara minutos una vez activado</small
                  >
                </div>
                <div class="mt-4 w-100"></div>
                <div class="col">
                  <b-form-checkbox
                    v-model="fullscreen"
                    name="check-button"
                    switch
                  >
                    Pantalla completa <b>({{ fullscreen ? "Si" : "No" }})</b>
                  </b-form-checkbox>
                </div>
                <!-- <div class="col">
                  <small>Tiempo de actualizacion (segundos)</small>
                  <input
                    type="number"
                    v-model="refreshTimeout"
                    class="form-control"
                  />
                </div> -->
              </div>
              <div class="row mt-5" v-if="info.deliveryEnabled">
                <div class="col-12 mb-3">
                  <h3>Comandas</h3>
                </div>
                <div class="col">
                  <small>ANCHO TICKET</small>
                  <b-form-input
                    v-model.number="ticketWidth"
                    type="number"
                    :min="180"
                    :max="800"
                  />
                  <small class="text-muted">MIN: 180 - MAX: 800</small>
                </div>
                <div class="col">
                  <small>TAMAÑO LETRA</small>
                  <b-form-input
                    v-model.number="fontSize"
                    type="number"
                    :min="10"
                    :max="30"
                  />
                  <small class="text-muted">MIN: 10 - MAX: 30</small>
                </div>
                <div class="col">
                  <small>COLUMNAS PRODUCTOS</small>
                  <b-form-input
                    v-model="ticketColumns"
                    type="number"
                    :min="1"
                    :max="2"
                  />
                  <small class="text-muted">MIN: 1 - MAX: 3</small>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12 mb-3">
                  <h3>Slider (Carrusel de imagenes)</h3>
                </div>
                <div class="col">
                  <small>Habilitar</small>
                  <b-form-checkbox
                    v-model="enabledSlider"
                    name="check-button"
                    switch
                  >
                    <b>({{ enabledSlider ? "Si" : "No" }})</b>
                  </b-form-checkbox>
                </div>
                <div class="col" v-if="enabledSlider">
                  <small>Imagenes</small>
                  <b-form-file v-model="images" multiple></b-form-file>
                </div>
                <div class="col" v-if="enabledSlider">
                  <small>Tiempo slider (segundos)</small>
                  <input
                    type="number"
                    v-model="sliderInterval"
                    class="form-control"
                  />
                </div>
                <div class="col" v-if="enabledSlider">
                  <b-checkbox v-model="sliderControls" switch
                    >Controles</b-checkbox
                  >
                  <small>Controles slider</small>
                </div>
                <div class="col" v-if="enabledSlider">
                  <b-checkbox v-model="sliderIndicators" switch
                    >Indicadores</b-checkbox
                  >
                  <small>Controles Indicadores</small>
                </div>
                <div class="w-100 py-2"></div>
                <div class="col-auto"  v-if="enabledSlider">
                  <b-button variant="primary" @click="saveSliders()"
                    >Guardar Sliders</b-button
                  >
                </div>
                <div class="col-12"  v-if="enabledSlider">
                  <ul class="list-group-item">
                    <li class="list-group-item" v-for="(slider, i) in sliders" :key="`slider-${i}`">
                      <div class="row">
                        <div class="col-2">
                          <img
                            :src="slider.url"
                            class="img-fluid"
                            style="max-height: 100px"
                          />
                        </div>
                        <div class="col">
                          <small>TITULO</small>
                          <input
                            type="text"
                            v-model="slider.caption"
                            class="form-control"
                          />
                        </div>
                        <div class="col">
                          <small>DESCRIPCION</small>
                          <input
                            type="text"
                            v-model="slider.text"
                            class="form-control"
                          />
                        </div>
                        <div class="col-auto">
                          <b-button
                            variant="danger"
                            @click="removeSlider(slider)"
                            >Eliminar</b-button
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
// import storage from "electron-json-storage";
import { storage, ipcRenderer } from "../multi-utils";
export default {
  name: "Map",
  data() {
    return {
      loading: false,
      onlyWithTime: false,
      enabledSlider: false,
      refreshTimeout: 20000,
      sliderControls: true,
      sliderIndicators: true,
      sliderInterval: 5,
      slide: 0,
      sliders: [],
      images: [],
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly",
      },
      ticketColumns: 1,
      ticketWidth: 300,
      fontSize: 12,
      tab: null,
      svgHome:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktZ2VvLWZpbGwiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNCA0YTQgNCAwIDEgMSA0LjUgMy45NjlWMTMuNWEuNS41IDAgMCAxLTEgMFY3Ljk3QTQgNCAwIDAgMSA0IDMuOTk5em0yLjQ5MyA4LjU3NGEuNS41IDAgMCAxLS40MTEuNTc1Yy0uNzEyLjExOC0xLjI4LjI5NS0xLjY1NS40OTNhMS4zMTkgMS4zMTkgMCAwIDAtLjM3LjI2NS4zMDEuMzAxIDAgMCAwLS4wNTcuMDlWMTRsLjAwMi4wMDhhLjE0Ny4xNDcgMCAwIDAgLjAxNi4wMzMuNjE3LjYxNyAwIDAgMCAuMTQ1LjE1Yy4xNjUuMTMuNDM1LjI3LjgxMy4zOTUuNzUxLjI1IDEuODIuNDE0IDMuMDI0LjQxNHMyLjI3My0uMTYzIDMuMDI0LS40MTRjLjM3OC0uMTI2LjY0OC0uMjY1LjgxMy0uMzk1YS42MTkuNjE5IDAgMCAwIC4xNDYtLjE1LjE0OC4xNDggMCAwIDAgLjAxNS0uMDMzTDEyIDE0di0uMDA0YS4zMDEuMzAxIDAgMCAwLS4wNTctLjA5IDEuMzE4IDEuMzE4IDAgMCAwLS4zNy0uMjY0Yy0uMzc2LS4xOTgtLjk0My0uMzc1LTEuNjU1LS40OTNhLjUuNSAwIDEgMSAuMTY0LS45ODZjLjc3LjEyNyAxLjQ1Mi4zMjggMS45NTcuNTk0QzEyLjUgMTMgMTMgMTMuNCAxMyAxNGMwIC40MjYtLjI2Ljc1Mi0uNTQ0Ljk3Ny0uMjkuMjI4LS42OC40MTMtMS4xMTYuNTU4LS44NzguMjkzLTIuMDU5LjQ2NS0zLjM0LjQ2NS0xLjI4MSAwLTIuNDYyLS4xNzItMy4zNC0uNDY1LS40MzYtLjE0NS0uODI2LS4zMy0xLjExNi0uNTU4QzMuMjYgMTQuNzUyIDMgMTQuNDI2IDMgMTRjMC0uNTk5LjUtMSAuOTYxLTEuMjQzLjUwNS0uMjY2IDEuMTg3LS40NjcgMS45NTctLjU5NGEuNS41IDAgMCAxIC41NzUuNDExeiIvPgo8L3N2Zz4=",
      drivers: [],
      status: "Conectando",
      socket: null,
      driver: {
        coords: {
          lat: -36.7955523,
          lng: -73.1017496,
        },
      },
      markers: [],
      time: new Date(),
      locations: [],
      toggle: true,
      fullscreen: false,
      height: "320px",
      focus: null,
      lastUpdated: Date.now(),
      timeoutTicket: null,
      lastUpdatedMap: Date.now(),
      showLocal: true,
      showTraffic: false,
      tables: [],
    };
  },
  computed: {
    timeFormated() {
      if (this.info.timeFormat == "24") {
        return this.$moment(this.time).format("HH:mm:ss");
      } else {
        return this.$moment(this.time).format("hh:mm:ss");
      }
    },
    pendingTables() {
      let tables = this.$store.state.tablesPending.map((t) => {
        t.products = t.products.filter((p) => {
          if (p.readyAt == null) return p;
        });
        return t;
      });
      return tables;
    },
    pendingTickets() {
      return this.tickets
        .filter((t) => {
          if (t.delivery && t.delivery.status)
            return t.delivery.status != "Entregado";
        })
        .sort((a, b) => {
          if (a.hour == "" || a.hour == null) {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
          }
          if (a.hour == null) return -1;
          if (a.hour < b.hour) return -1;
          if (a.hour > b.hour) return 1;
          return 0;
        });
    },
    nextTicketHourinMinutes() {
      if (this.nextTicketHour == "--:--") return -1;

      return this.$moment(this.nextTicketHour, "HH:mm").diff(
        this.$moment(),
        "minutes"
      );
    },
    nextTicketHour() {
      let t = this.tickets[0];
      if (t && t._id) {
        return t.hour;
      } else {
        return "--:--";
      }
    },
    lastTicketHour() {
      let t = this.tickets[this.tickets.length - 1];
      if (t && t._id) {
        return t.hour;
      } else {
        return "--:--";
      }
    },
    webTickets() {
      return this.$store.state.webTickets;
    },
    webTicketsPending() {
      return this.webTickets.reduce((t, w) => {
        if (w.status == "Pendiente") return t + 1;
        return t;
      }, 0);
    },
    ticketsSuccess() {
      return this.$store.state.tickets.reduce((t, w) => {
        if (w.deletedAt == null) return t + 1;
        return t;
      }, 0);
    },
    ticketsPending() {
      return this.tickets.length;
    },
    mapTimeout() {
      if (this.info) return this.info.mapTimeout;
      return 1;
    },
    centerMap() {
      if (this.focus) {
        const { latitude, longitude } = this.focus;
        return {
          lat: latitude,
          lng: longitude,
        };
      } else return { lat: -36.8006383, lng: -73.0564978 };
    },
    coords() {
      return {
        lat: this.driver.coords.latitude,
        lng: this.driver.coords.longitude,
      };
    },
    tickets() {
      let tickets = this.$store.state.tickets;
      // if (tickets) {
      //   tickets.sort((a, b) => {
      //     if (a.hour < b.hour) return -1;
      //     if (a.hour > b.hour) return 1;
      //     return 0;
      //   });
      // }
      const today = this.$moment();

      tickets = tickets.filter((ticket) => {
        if (!ticket.deletedAt) {
          // check if entregado
          let event = ticket.events.find(
            (el) =>
              el &&
              el.value &&
              el.value.toLowerCase().indexOf("entregado") != -1
          );
          // check if domicilio
          if (ticket.type == "Domicilio") {
            // check if not entregado
            if (ticket.delivery.status != "Entregado") {
              return ticket;
            } else if (event) {
              if (
                today.diff(this.$moment(event.createdAt), "minutes") <
                this.mapTimeout
              ) {
                return ticket;
              }
            }
          } else if (this.showLocal) {
            console.log("ticket pending", ticket.clientName);
            const timeFormat = this.info.timeFormat;

            //console.log("show local, format:", this.mapTimeout);

            const diff = today.diff(
              this.$moment(ticket.hour, "HH:mm"),
              "minutes"
            );

            const diffCreated = today.diff(
              this.$moment(ticket.createdAt),
              "minutes"
            );

            const currentTimeAmPm = this.$moment().format("A");
            const pm = this.$moment(
              ticket.hour + " " + currentTimeAmPm,
              "hh:mm A"
            );
            const diff2 = today.diff(pm, "minutes");
            // console.log({ hour: pm, diff2 });

            if (timeFormat == "24") {
              // console.log("time 24");
              if (diff < this.mapTimeout) return ticket;
            } else if (timeFormat == "12") {
              //console.log("time 12", diff2);
              if (diff2 < this.mapTimeout) return ticket;
            }
            console.log("diff no time", diffCreated);
            if (
              !this.onlyWithTime &&
              ticket.hour == null &&
              diffCreated < this.mapTimeout
            ) {
              return ticket;
            }
          }
        }
      });
      return tickets;
    },
    info() {
      return this.$store.state.info;
    },
  },
  mounted() {
    this.$store.commit("hideMenu");
    this.time = this.$moment();

    this.resize();

    if (this.info.restaurantEnabled) this.$store.dispatch("getTablesPending");

    if (localStorage.getItem("sliders")) {
      this.sliders = JSON.parse(localStorage.getItem("sliders"));
    }

    if (localStorage.getItem("enabledSlider")) {
      this.enabledSlider = JSON.parse(localStorage.getItem("enabledSlider"));
    }

    if (localStorage.getItem("onlyWithTime")) {
      this.onlyWithTime = JSON.parse(localStorage.getItem("onlyWithTime"));
    }

    ipcRenderer.on("changeTab", (event, arg) => {
      console.log({ arg });
      this.tab = arg;
    });

    this.$http.get("info").then((res) => {
      this.$store.commit("setInfo", res.data.data);
      //if (!res.data.data.deliveryEnabled) this.$router.push("/");
    });

    this.intervalTime = setInterval(() => {
      this.time = this.$moment();
    }, 1000);

    this.loadTickets();
    this.getLocations();

    this.fullscreen = window.innerHeight == screen.height;

    document.addEventListener("fullscreenchange", this.fullscreenChange);

    window.addEventListener("resize", this.resize);

    storage.getMany(
      [
        "showLocal",
        "showTraffic",
        "mapfontSize",
        "ticketWidth",
        "ticketColumns",
      ],
      (err, data) => {
        if (data.showLocal) this.showLocal = data.showLocal;
        if (data.showTraffic) this.showTraffic = data.showTraffic;
        if (data.mapfontSize) this.fontSize = data.mapfontSize || 12;
        if (data.ticketWidth) this.ticketWidth = data.ticketWidth || 300;
        if (data.ticketColumns) this.ticketColumns = data.ticketColumns || 1;
      }
    );
  },
  methods: {
    saveSliders() {
      localStorage.setItem("sliders", JSON.stringify(this.sliders));
    },
    removeSlider(slider) {
      this.sliders = this.sliders.filter((s) => s != slider);
    },
    setReady(tid) {
      this.loading = true;
      this.$http
        .post(`ticket/${tid}/delivery`, {
          status: "Entregado",
        })
        .then(() => {
          this.loadTickets();
        })
        .catch(() => {
          this.$noty.error("Ha ocurrido un error al actualizar el ticket");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTicketsByUser(user) {
      const { _id } = user;
      return this.$store.state.tickets.reduce((to, cur) => {
        if (cur.delivery && cur.delivery.user == _id) return to + 1;
        return to;
      }, 0);
    },
    getTickets() {
      return this.tickets;
    },
    toggleMenu() {
      this.$store.commit("toggleMenu");
      event.preventDefault();
    },
    resize() {
      this.height = window.innerHeight - 0 + "px";
    },
    fullscreenChange() {
      // this.fullscreen = document.fullscreenElement ? true : false;
      // this.$store.commit("toggleMenu");
    },
    loadTickets() {
      this.loading = true;
      clearTimeout(this.timeoutTicket);
      const date =
        this.$moment().hour() < 1
          ? this.$moment().subtract("1", "days")
          : this.$moment();
      // const date = this.$moment().subtract("1", "days")
      this.$http.get("web/ticket").then((res) => {
        this.$store.commit("setWebTickets", res.data.data);
      });
      this.$http
        .get(`ticket?date=${date.format("YYYY-MM-DD")}`)
        .then((res) => {
          this.$store.commit("setTickets", res.data.data);
          this.lastUpdated = Date.now();
          this.getLocations();
        })
        .catch(() => {
          // this.$noty.error("Ha ocurrido un error al cargar los tickets");
        })
        .finally(() => {
          this.loading = false;
          this.timeoutTicket = setTimeout(
            this.loadTickets,
            this.refreshTimeout
          );
        });
    },
    getLocations() {
      this.$http
        .get("pos")
        .then((res) => {
          // filtramos los conductores que esten activos
          // al menos 1 hora
          let drivers = res.data.data.filter((driver) => {
            const diff = this.$moment().diff(
              this.$moment(driver.updatedAt),
              "hours"
            );
            if (diff < 2) return driver;
          });
          // array donde guardaremos los conductores
          const locations = [];
          // recorremos cada uno y lo vamos agregando al array
          // y setenado nuevas props
          var i = 0;
          drivers.forEach((location) => {
            const driver = {
              ...location,
            };
            locations.push(driver);
            if (i == 0 && this.focus == null) {
              this.focus = driver;
            } else if (this.focus._id == driver._id) {
              this.focus = driver;
            }
            i++;
          });
          // agregamos el resultado a la vista
          this.locations = locations;

          // mostramos el trafico
          if (this.showTraffic) {
            this.$refs.mapRef.$mapPromise.then((map) => {
              // eslint-disable-next-line no-undef
              let trafficLayer = new google.maps.TrafficLayer();
              trafficLayer.setMap(map);
            });
          }

          // hacemos focus
          if (this.focus != null) {
            this.$refs.mapRef.$mapPromise.then((map) => {
              map.panTo({
                lat: this.focus.latitude,
                lng: this.focus.longitude,
              });
            });
          }
        })
        .catch(() => {
          //this.$noty.error("Ha ocurrido un error al cargar los usuarios")
        });
    },
  },
  beforeDestroy() {
    // this.socket.disconnect();
    // this.socket = null;
    clearTimeout(this.timeoutTicket);
    clearInterval(this.intervalTime);
    clearInterval(this.intervalTicket);
    if (document.fullscreen) document.exitFullscreen();
    document.removeEventListener("fullscreenchange", this.fullscreenChange);
    document.removeEventListener("resize", this.resize);
  },
  watch: {
    enabledSlider() {
      localStorage.setItem("enabledSlider", this.enabledSlider);
    },
    sliderControls() {
      storage.set("sliderControls", this.sliderControls);
    },
    sliderInterval() {
      storage.set("sliderInterval", this.sliderInterval);
    },
    sliderTransition() {
      storage.set("sliderTransition", this.sliderTransition);
    },
    images() {
      console.log("images changed", this.images);
      // push images to this.slides
      this.images.forEach((image) => {
        let render = new FileReader();
        render.onload = (e) => {
          this.sliders.push({
            url: e.target.result,
            text: "",
            caption: "",
          });
        };
        render.readAsDataURL(image);
      });
    },
    // watch current url hash changes
    fontSize() {
      storage.set("mapfontSize", this.fontSize);
    },
    ticketWidth() {
      storage.set("ticketWidth", this.ticketWidth);
    },
    ticketColumns() {
      storage.set("ticketColumns", this.ticketColumns);
    },
    showLocal() {
      storage.set("showLocal", this.showLocal);
    },
    showTraffic() {
      storage.set("showTraffic", this.showTraffic);
    },
    onlyWithTime() {
      storage.set("onlyWithTime", this.onlyWithTime);
    },
    focus() {
      // console.log("Focus Changed", val);
    },
    fullscreen(val) {
      if (val) {
        document.body.requestFullscreen();
        this.$store.commit("hideMenu");
      } else {
        document.exitFullscreen();
      }
    },
  },
};
</script>

<style scoped>
.container-items {
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
#map {
  min-height: 90vh;
  /* min-height: 400px; */
}
.container-fluid {
  max-width: 100% !important;
}
.table-tickets {
  min-width: 100%;
  max-height: 550px;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}
.table-tickets td {
  padding: 0.5px 10px;
}
.table-tickets td,
.table-tickets tbody,
.table-tickets tr {
  width: 100%;
  display: block;
}

.map {
  width: 100%;
}
.users {
  position: absolute;
  top: 10px;
  left: 10px;
}
.tickets {
  top: 0px;
  right: 5px;
  z-index: 999;
  max-height: 95vh;
  overflow-y: scroll;
}
.address {
  max-width: 90%;
  overflow: hidden;
  font-size: 10px;
  white-space: nowrap;
}
</style>
