<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h3>Mis pagos</h3>
      </div>
      <div class="ml-auto col-2 d-none col-md-block">
        <button
          class="btn btn-success btn-block"
          :disabled="loading"
          @click="load()"
        >
          ACTUALIZAR
        </button>
      </div>
      <div class="col-6" v-if="total > 0">
        <button class="btn btn-primary btn-block" @click="showPaymentModal()">
          PAGAR
        </button>
      </div>
      <div class="w-100 py-2"></div>
      <div class="col-12" v-if="total > 0">
        <div class="alert alert-danger text-center">
          Tienes un pago pendiente por
          <strong>${{ total | formatNumber }}</strong>
        </div>
      </div>
      <div class="col">
        <ul class="list-group" v-if="invoices.length == 0">
          <li class="list-group-item">
            <h5 class="text-center py-5">No hay informacion</h5>
          </li>
        </ul>
        <ul class="list-group" v-else>
          <li
            class="list-group-item"
            v-for="invoice in invoices"
            :key="invoice._id"
          >
            <div class="row align-items-center">
              <div class="col-4 col-md-2">
                <small class="text-muted">CREADA</small>
                <div class="w-100"></div>
                {{ invoice.createdAt | moment("DD/MM/YY") }}
              </div>
              <div class="col-8 col-md">
                <strong>{{ invoice.name }}</strong>
                <div class="w-100"></div>
                <small
                  class="d-block"
                  v-for="d in invoice.details"
                  :key="d._id"
                >
                  {{ d.name }} - ${{ d.total | formatNumber }}
                </small>
              </div>
              <div class="col-md-2">
                <small class="text-muted">VENCE</small>
                <div class="w-100"></div>
                {{ invoice.expire | moment("DD/MM/YY") }}
                <div class="w-100"></div>
                <small
                  class="font-italic"
                  v-if="invoice.status == 'pendiente'"
                  >{{ invoice.expire | moment("from", "now") }}</small
                >
              </div>
              <div class="col-md-2">
                <span
                  class="badge w-100 py-1"
                  :class="{
                    'badge-success': invoice.status == 'pagada',
                    'badge-danger': invoice.status != 'pagada',
                  }"
                >
                  <small>{{ invoice.status | uppercase }}</small></span
                >
              </div>
              <div class="col-2 text-right">
                <small class="text-muted">TOTAL</small>
                <div class="w-100"></div>
                <strong>${{ invoice.total | formatNumber }}</strong>
              </div>
              <div class="w-100"></div>
              <div class="col d-none">
                <div class="row" v-for="p in invoice.items" :key="p._id">
                  <div class="col-7 text-uppercase">
                    <span>{{ p.qty }} x </span>
                    <strong>{{ p.name }}</strong>
                  </div>
                  <div class="col-3 text-right">${{ p.value }}</div>
                </div>
              </div>
              <div class="col d-none">
                <pre>{{ invoice.notes }}</pre>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <b-modal ref="payment-modal" size="md">
      <b-tabs pills justify content-class="p-2 mt-2">
        <b-tab title="Transferencia">
          <div class="row">
            <div class="col">
              <strong class="">DATOS DE TRANSFERENCIA</strong>
              <div class="w-100 py-2"></div>
              <pre class="mb-0">
  Nombre: Servicios informáticos SG limitada. 
  RUT: 76.434.084-1 
  Banco:  Banco Estado 
  N° de cuenta: 533-7-157576-2 
  Tipo de cuenta: Chequera electrónica / Cuenta Vista 
  Correo: pagos@ticketapp.cl
  <strong class="bglight w-100 py-2 mt-2 d-block text-center fs-4">MONTO: ${{total | formatNumber}}</strong>
          </pre>
              <div class="alert alert-info">
                Una vez realizado la transferencia enviar comprobante al correo
                para verificar su pago
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Pago en linea">
          <button
            class="btn btn-primary py-2 btn-block"
            :disabled="true"
            @click="pay()"
          >
            Pagar por WebPay (Flow)
          </button>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { doAction } from "../utils";
export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    invoices() {
      return this.$store.state.invoices;
    },
    total() {
      let invoices = this.invoices.filter((i) => i.status == "pendiente");
      let reducer = (total, current) => total + current.total;
      return invoices.reduce(reducer, 0);
    },
    invoice() {
      if (this.invoices.length == 0) return {};
      return this.invoices[0];
    },
  },
  methods: {
    pay() {
      this.loading = true;

      let invoices = this.invoices.filter((i) => i.status == "pendiente")
        .map((i) => i._id);
        
      this.$http
        .post("invoice/flow", { invoices })
        .then((res) => {
          let data = res.data.data;
          console.log("ta listo", data);
          window.open(`${data.url}?token=${data.token}`, "_blank");
          //doAction(`${data.url}?token=${data.token}`);
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
    showPaymentModal() {
      this.$refs["payment-modal"].show();
    },
    load() {
      this.loading = true;
      this.$http
        .get("invoice")
        .then((res) => {
          this.$store.commit("setInvoices", res.data.data);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
  },
};
</script>