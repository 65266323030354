<template>
  <div style="font-family: sans-serif">
    <div
      class="row"
      :style="{ width: `${pageWidth}%`, 'margin-left': `${marginLeft}px` }"
    >
      <div class="col-12">
        <div
          style="text-align: center; margin: 15px 0px 0px 0px"
          v-if="info.printLogo"
        >
          <img :src="$store.state.logo" width="90%" style="max-width: 300px; margin: 0 auto; max-height: 150px;object-fit:contain" />
        </div>
        <div style="font-size: 11px">
          <span
            style="font-weight: bold; display: block"
            :style="{ 'font-size': `${fontSize}px` }"
          >
            ORDEN DE TRABAJO N° {{ ot.number || "" }}
          </span>
          <br />
          <strong>
            {{ info ? info.name : "" }}
          </strong>
          <br />
          <small>
            <strong>
              {{ info ? info.address : "" }}
              <br />
              {{ info ? info.phone : "" }}
            </strong>
          </small>
          <br />
          <small>FECHA: {{ ot.createdAt | moment("DD/MM/YYYY HH:mm") }}</small>
        </div>
        <div>
          <table
            style="margin-top: 20px; text-transform: uppercase"
            :style="{ 'font-size': `${fontSize}px`, 'text-Align': 'left' }"
          >
            <thead>
              <tr>
                <th>
                  <small>Datos del cliente</small>
                </th>
              </tr>
              <tr>
                <td colspan="2">
                  <small>
                    {{ ot.name }}
                    <span v-if="ot.rut">({{ ot.rut }})</span></small
                  >
                </td>
              </tr>
              <tr colspan="2">
                <td>
                  <small>Tel: {{ ot.phone }}</small>
                </td>
              </tr>
              <tr colspan="2">
                <td>
                  <small
                    >Entrega:
                    {{ ot.endDate | moment("dddd, DD/MM/YYYY") }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2" style="font-weight: bold">
                  <small v-if="!ot.description">{{ "Sin descripcion" }}</small>
                  <small v-html="ot.description"></small>
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div style="margin-top: 10px">
          <hr />
          <table
            :style="{
              'font-size': `${fontSize}px`,
              'text-Align': 'left',
              width: '100%',
            }"
          >
            <tbody>
              <tr v-for="p in ot.products" :key="p._id">
                <th style="padding: 10px 0">
                  <strong>{{ p.name.toUpperCase() }}</strong>
                  <small style="display: block">CODIGO: {{ p.code }}</small>
                  <small style="display: block">CANTIDAD: {{ p.qty }}</small>
                  <small style="display: block"
                    >VALOR: ${{ formatNumber(p.value) }}</small
                  >
                  <small v-if="p.discount" style="display: block"
                    >DCTO: -${{ formatNumber(p.discount) }}</small
                  >
                  <div
                    v-if="p.details"
                    :style="{ 'font-size': `${fontSize - 1}px` }"
                    style="margin-top: 5px"
                  >
                    - {{ p.details }}
                  </div>
                </th>
                <th style="text-align: right">${{ formatNumber(p.total) }}</th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>
               <tr style="text-align: right">
                <th>SUB-TOTAL:</th>
                <th>${{ formatNumber(ot.sub_total) }}</th>
              </tr>
              <tr style="text-align: right" v-if="ot.discount">
                <th>DESCUENTO:</th>
                <th>${{ formatNumber(ot.discount) }}</th>
              </tr>
              <tr style="text-align: right">
                <th>TOTAL:</th>
                <th>${{ formatNumber(ot.total) }}</th>
              </tr>
              <tr style="text-align: right">
                <th>ABONOS:</th>
                <th>${{ formatNumber(ot.total - ot.balance) }}</th>
              </tr>
              <tr style="text-align: right">
                <th>SALDO:</th>
                <th>${{ formatNumber(ot.balance) }}</th>
              </tr>
            </tfoot>
          </table>

          <span
            :style="{
              'font-size': `${fontSize - 1}px`,
              margin: '10px 0px',
              ' white-space': 'pre-wrap',
            }"
            style="display: block"
            v-if="info.custom_footer_ot"
            v-html="info.custom_footer_ot"
          >
          </span>

          <span
            style="
              text-align: center;
              font-size: 9px;
              margin-top: 5px;
              display: block;
              font-weight: bold;
              font-family: sans-serif;
            "
          >
            WWW.TICKETAPP.CL
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['businessInfo'],
  name: "OtPrint",
  data() {
    return {
      timeout: null,
      fontSize: 12,
      pageWidth: 90,
      marginLeft: 0,
    };
  },
  computed: {
    ot() {
      //return localStorage.OT ? JSON.parse(localStorage.OT) : {};
      return this.$store.state.OT;
    },
    info() {
      // if (localStorage.info) return JSON.parse(localStorage.info);
      // return this.$store.state.info;
      return this.businessInfo
    },
  },
  mounted() {
    if (localStorage.fontSize) this.fontSize = localStorage.fontSize;
  },
};
</script>

<style scoped>
nav {
  display: none !important;
}
body {
  width: 200px !important;
}
.table td,
.table th {
  padding: 0.4rem;
  vertical-align: top;
}
@media print {
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  body {
    min-width: 100% !important;
  }
  .ticket-scroll {
    height: auto;
    overflow-y: visible;
    padding: 0;
  }
  .row {
    padding: 0;
    margin: 0;
  }
  .col,
  .col-3 {
    padding: 0;
  }
  .container {
    width: 10%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>