<template>
  <div class="container-app">
    <div class="row">
      <div class="col">
        <h1>Herramienta de Certificacion</h1>
      </div>
      <div class="w-100"></div>
      <div class="col">
        <small>SUBIR SET DE PRUEBAS</small>
        <div class="w-100"></div>
        <b-form-file
          v-model="file"
          accept=".txt"
          :state="Boolean(file)"
          placeholder="Seleccione un archivo o arrástrelo aquí..."
          drop-placeholder="Suelta el archivo aquí..."
        ></b-form-file>
      </div>
      <div class="col-auto align-self-end">
        <b-button variant="primary" @click="readFile" class="mt-3"
          >Subir</b-button
        >
      </div>

      <div class="w-100"></div>

      <div class="col-12 mt-4">
        <b-tabs>
          <b-tab title="CASOS">
            <div class="row">
              <div class="w-100 py-2"></div>
              <div class="col">
                <small>NUMERO DE ATENCION</small>
                <input
                  type="text"
                  class="form-control"
                  v-model="set.numeroAtencion"
                />
              </div>
              <div class="w-100 py-2"></div>
              <div class="col-4">
                <small>RUT RECEPTOR</small>
                <input type="text" class="form-control fs-2" v-model="rut" />
              </div>
              <div class="col">
                <small>RAZON SOCIAL</small>
                <input type="text" class="form-control fs-2" v-model="rs" />
              </div>
              <div class="w-100"></div>
              <div class="col">
                <small>DIRECCION</small>
                <input
                  type="text"
                  class="form-control"
                  v-model="billing_address"
                />
              </div>
              <div class="col">
                <small>COMUNA</small>
                <input type="text" class="form-control" v-model="commune" />
              </div>
              <div class="w-100 py-2"></div>
              <div class="col-12">
                <small>CASOS</small>
                <ul class="list-group">
                  <li class="list-group-item" v-if="set.casos.length == 0">
                    <h4 class="text-center py-5">Suba un archivo</h4>
                  </li>
                  <li
                    class="list-group-item list-group-item-action"
                    v-for="caso in set.casos"
                    :key="caso.numero"
                  >
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <div class="col-12">
                            <small>CASO</small>
                            <input
                              type="text"
                              class="form-control form-control-lg fs-2"
                              v-model="caso.numero"
                            />
                          </div>
                          <div class="col">
                            <small>DOCUMENTO</small>
                            <input
                              type="text"
                              class="form-control"
                              v-model="caso.documento"
                            />
                          </div>
                          <div class="col">
                            <small>FOLIO</small>
                            <input
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  caso.folio == null || caso.folio == '',
                                'is-valid':
                                  caso.folio != null && caso.folio != '',
                              }"
                              v-model="caso.folio"
                            />
                          </div>
                          <div class="w-100 py-2"></div>
                          <div class="col-12">
                            <small>REFERENCIAS</small>
                            <div class="row" v-if="caso.ref.length == 0">
                              <div class="col">
                                <h6 class="text-center">Sin referencias</h6>
                              </div>
                            </div>
                            <div
                              class="row"
                              v-for="(ref, i) in caso.ref"
                              :key="`ref-${caso.numero}-${i}`"
                            >
                              <div class="col">
                                <small>TIPO DOCUMENTO</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ref.TpoDoc"
                                />
                              </div>
                              <div class="col">
                                <small>FOLIO REF</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  :class="{
                                    'is-invalid':
                                      ref.folioRef == null ||
                                      ref.folioRef == '',
                                    'is-valid':
                                      ref.folioRef != null &&
                                      ref.folioRef != '',
                                  }"
                                  v-model="ref.folioRef"
                                />
                              </div>
                              <div class="w-100"></div>
                              <div class="col-3">
                                <small>COD REF</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ref.codRef"
                                />
                              </div>
                              <div class="col">
                                <small>RAZON REF</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ref.razon"
                                />
                              </div>
                              <div class="w-100"></div>
                              <div class="col">
                                <small>FECHA</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ref.fechaRef"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <small>ITEMS</small>
                            <div
                              class="w-100 border border-bottom border-light"
                            ></div>
                            <div
                              class="row pb-3"
                              v-for="(item, i) in caso.items"
                              :key="`${caso.numero}-${i}`"
                            >
                              <div class="col-12">
                                <input
                                  type="text"
                                  class="form-control fs-4"
                                  v-model="item.item"
                                />
                              </div>
                              <div class="col">
                                <small>IVA</small>
                                <b-checkbox
                                  v-model="item.iva"
                                  class="text-center"
                                  size="lg"
                                  >IVA</b-checkbox
                                >
                              </div>
                              <div class="col">
                                <small>CANTIDAD</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="item.cantidad"
                                />
                              </div>
                              <div class="col">
                                <small>VALOR</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="item.valor"
                                />
                              </div>
                               <div class="col">
                                <small>VALOR NETO</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="item.total"
                                />
                              </div>
                              <div class="col">
                                <small>DESCUENTO</small>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="item.descuento"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <small>DESCUENTO GLOBAL</small>
                            <input
                              type="text"
                              class="form-control"
                              v-model="caso.descuento"
                            />
                          </div>
                          <div class="col-12 mt-3">
                            <button
                              class="btn btn-success mr-2"
                              @click="generateDTE(caso)"
                              :disabled="casoState(caso)"
                            >
                              GENERAR DTE
                            </button>

                            <button
                              class="btn btn-secondary mr-2"
                              @click="addItem(caso)"
                            >
                              AGREGAR ITEM
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <small>XML</small>
                        <div
                          class="
                            custom_scroll
                            overflow-auto
                            border border-success
                            p-2
                            bg-light
                            text-success
                          "
                          style="height: 550px"
                        >
                          <textarea
                            class="form-control"
                            v-model="caso.dte"
                            readonly
                            :ref="`dte-${caso.numero}`"
                            style="height: 500px"
                          ></textarea>
                        </div>
                        <div class="w-100 py-2"></div>
                        <button
                          class="btn btn-info mr-2"
                          @click="copyDTE(caso)"
                          :disabled="caso.dte == null || caso.dte == ''"
                        >
                          COPIAR DTE
                        </button>
                        <button
                          :disabled="caso.dte == null || caso.dte == ''"
                          class="btn btn-primary mr-2"
                          @click="downloadXML(caso)"
                        >
                          DESCARGAR DTE
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
          <b-tab title="ENVIAR">
            <div class="row">
              <div class="col-12">
                <small>TIPO DOCUMENTO DE SET</small>
                <select class="form-control" v-model="tipoSet">
                  <option value="DTE-33">FACTURA ELECTRONICA</option>
                  <option value="DTE-39">BOLETA ELECTRONICA</option>
                </select>
              </div>
              <div
                class="col-12"
                v-for="caso in casosDTE"
                :key="`dte-${caso.numero}`"
              >
                <strong
                  >"{{ getDocumentType(caso.documento).replace("-", "") }}F{{
                    caso.folio
                  }}_{{ info._id }}.xml",</strong
                >
              </div>
              <div class="w-100 py-2"></div>
              <button
                class="btn btn-success px-5"
                :disabled="casosDTE.length == 0"
                @click="sendDTE"
              >
                ENVIAR
              </button>
            </div>
          </b-tab>
           <b-tab title="CF">
            <div class="row">
              <div class="col-8">
                <small>TIPO DOCUMENTO DE SET</small>
                <select class="form-control" v-model="tipoSet">
                  <!-- <option value="DTE-33">FACTURA ELECTRONICA</option> -->
                  <option value="DTE-39">BOLETA ELECTRONICA</option>
                </select>
              </div>
              <div class="col-4">
                <small>SEC ENVIO</small>
                <input type="text" class="form-control" v-model="secEnvio" />
              </div>
              <div
                class="col-12"
                v-for="caso in casosDTE"
                :key="`dte-${caso.numero}`"
              >
                <strong
                  >"{{ getDocumentType(caso.documento).replace("-", "") }}F{{
                    caso.folio
                  }}_{{ info._id }}.xml",</strong
                >
              </div>
              <div class="w-100 py-2"></div>
              <button
                class="btn btn-success px-5"
                :disabled="casosDTE.length == 0"
                @click="sendCF"
              >
                ENVIAR
              </button>
            </div>
           </b-tab>
          <b-tab title="VENTAS">
            <div class="row">
              <div
                class="col-12"
                v-for="caso in casosDTE"
                :key="`dte-${caso.numero}`"
              >
                <strong
                  >"{{ getDocumentType(caso.documento).replace("-", "") }}F{{
                    caso.folio
                  }}_{{ info._id }}.xml",</strong
                >
              </div>
              <div class="w-100 py-2"></div>
              <button
                class="btn btn-success px-5"
                :disabled="casosDTE.length == 0"
                @click="generateVentas"
              >
                ENVIAR
              </button>
            </div>
          </b-tab>
          <b-tab title="COMPRAS">
            <div class="row">
              <div class="col">
                <small>RUT EMISOR</small>
                <input
                  type="text"
                  class="form-control fs-4"
                  v-model="rutCompras"
                />
              </div>
              <div class="col">
                <small>FACTOR</small>
                <input type="text" class="form-control fs-4" v-model="factor" />
              </div>
              <div class="col-12 py-2">
                <button
                  class="btn btn-success btn-block"
                  @click="generateCompras"
                >
                  GENERAR
                </button>
              </div>
              <div class="col">
                <ul class="list-group">
                  <li
                    class="list-group-item list-group-item-action"
                    v-for="compra in set.compras"
                    :key="`${compra.documento}-${compra.folio}`"
                  >
                    <div class="row">
                      <div class="col">
                        <small>DOCUMENTO</small>
                        <input
                          type="text"
                          class="form-control fs-4"
                          v-model="compra.documento"
                        />
                      </div>
                      <div class="col">
                        <small>FOLIO</small>
                        <input
                          type="text"
                          class="form-control fs-4"
                          v-model="compra.folio"
                        />
                      </div>
                      <div class="w-100"></div>
                      <div class="col">
                        <small>DETALLE</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="compra.detalle"
                        />
                      </div>
                      <div class="col-2">
                        <small>EXENTO</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="compra.exento"
                        />
                      </div>
                      <div class="col-2">
                        <small>TOTAL NETO</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="compra.totalNeto"
                        />
                      </div>
                      <div class="w-100"></div>
                      <div class="col-4">
                        <small>DOC REFERENCIA</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="compra.tipoDocRef"
                        />
                      </div>
                      <div class="col-3">
                        <small>FOLIO DOC</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="compra.folioDocRef"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "certtool",
  data() {
    return {
      secEnvio: 1,
      rutCompras: '78885550-8',
      tipoSet: 'DTE-33',
      file: null,
      dtes: [],
      factor: 0.6,
      set: {
        numeroAtencion: 0,
        casos: [],
        compras: [],
      },
      rs: "TicketApp SpA",
      giro: "ACTIVIDADES DE CONSULTORIA DE INFORMATICA Y DE GESTION DE INSTALACIONES",
      rut: "77625644-7",
      billing_address: "Ohiggins 770 Local 33",
      commune: "Concepcion",
    };
  },
  computed: {
    casosDTE() {
      return this.set.casos.filter(
        (caso) => caso.dte != null && caso.dte != ""
      );
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    copyDTE(caso) {
      if (this.$refs[`dte-${caso.numero}`] != null) {
        this.$refs[`dte-${caso.numero}`][0].select();
        document.execCommand("copy");
      }
    },
    sendCF() {
      const tickets = this.casosDTE.map((caso) => {
        let dte = this.getDocumentType(caso.documento).replace("-", "");
        return `${dte}F${caso.folio}_${this.info._id}.xml`;
      });
      this.$http
        .post(`sii/dte/cf`, {
          tickets,
          secEnvio: this.secEnvio
        })
        .then((res) => {
          console.log(res);
          this.$noty.success({
            title: "CF enviado",
            text: "Se ha generado el CF",
          });
        });
    },
    generateCompras() {
      const tickets = this.set.compras.map((caso) => {
        let data = {
          folio: parseInt(caso.folio),
          dteType: parseInt(
            this.getDocumentType(caso.documento).replace("DTE-", "")
          ),
          totalNeto: parseInt(caso.totalNeto),
          RUTDoc: this.rutCompras,
          totalExento: parseInt(caso.exento),
          observaciones: caso.detalle,
        };

        if (caso.detalle == "FACTURA CON IVA USO COMUN") {
          data.factor = this.factor;
        }

        if (caso.detalle == "ENTREGA GRATUITA DEL PROVEEDOR") {
          data.ivaNoRec = {
            cod: 4,
          };
        }

        if (caso.detalle == "COMPRA CON RETENCION TOTAL DEL IVA") {
          data.otrosImp = {
            cod: 15,
          };
        }

        if (caso.tipoDocRef) {
          data.tipoDocRef = parseInt(this.getDocumentType(caso.tipoDocRef).replace("DTE-", ""));
          data.folioDocRef = caso.folioDocRef;
        }

        return data;
      });

      let data = {
        tickets,
        periodo: this.$moment().format("YYYY-MM"),
        tipo: "COMPRA",
      };
      this.$http.post(`sii/book2`, data).then((res) => {
        this.$noty.success({
          title: "Libro de compras generado",
          text: "Se ha generado el libro de compras",
        });
        console.log(res);
      });
    },
    generateVentas() {
      const tickets = this.casosDTE.map((caso) => {
        let dte = this.getDocumentType(caso.documento).replace("-", "");
        return `${dte}F${caso.folio}_${this.info._id}.xml`;
      });
      this.$http
        .post(`sii/book`, {
          tickets,
          periodo: this.$moment().format("YYYY-MM"),
          tipo: "VENTA",
          folioNoti: 100,
        })
        .then((res) => {
          console.log(res);
        });
    },
    sendDTE() {
      const tickets = this.casosDTE.map((caso) => {
        let dte = this.getDocumentType(caso.documento).replace("-", "");
        return `${dte}F${caso.folio}_${this.info._id}.xml`;
      });
      this.$http
        .post(`sii/dte/send`, {
          tickets,
          type: this.tipoSet
        })
        .then((res) => {
          console.log(res);
        });
    },
    getCodRef(razon) {
      const obj = {
        "ANULA FACTURA": 1,
        "ANULA NOTA DE CREDITO ELECTRONICA": 1,
        "CORRIGE GIRO DEL RECEPTOR": 2,
        "CORRIGE TEXTO DOCUMENTO DE REFERENCIA": 2,
        "DEVOLUCION DE MERCADERIAS": 3,
        "CORRIGE MONTOS": 3,
      };
      return obj[razon] || 0;
    },
    casoState(caso) {
      return caso.folio == null || caso.folio == "";
    },
    addItem(caso) {
      caso.items.push({
        item: "",
        cantidad: 0,
        valor: 0,
        descuento: null,
      });
    },
    downloadXML(caso) {
      const { numero, dte, documento } = caso;
      const blob = new Blob([dte], { type: "text/xml" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${documento}-${numero}.xml`;
      link.click();
    },
    getDocumentType(documento) {
      let doc = documento.toUpperCase().replace(/\s{1,}$/g, "");
      const docs = {
        "FACTURA ELECTRONICA": "DTE-33",
        "NOTA DE CREDITO ELECTRONICA": "DTE-61",
        "NOTA DE DEBITO ELECTRONICA": "DTE-56",
        FACTURA: "DTE-30",
        "NOTA DE CREDITO": "DTE-60",
        "FACTURA DE COMPRA ELECTRONICA": "DTE-46",
        "BOLETA ELECTRONICA": "DTE-39",
      };

      return docs[doc] || "DTE-0";
    },
    generateDTE(caso) {
      const products = caso.items.map((item) => {
        return {
          name: item.item,
          qty: item.cantidad,
          neto: item.valor,
          iva: item.iva,
          discount: parseInt(item.descuento) || null,
        };
      });

      const acteco =
        this.info.siiActeco && this.info.siiActeco.length > 0
          ? this.info.siiActeco[0].code
          : 0;

      let data = {
        rs: this.rs,
        giro: this.giro,
        rut: this.rut,
        billing_address: this.billing_address,
        commune: this.commune,
        acteco,
        folio: caso.folio,
        products: products,
        discountPrc: caso.descuento,
        documentType: this.getDocumentType(caso.documento),
         ref: [
          {
            razon: "CASO " + caso.numero,
            TpoDoc: "SET",
          },
          ...caso.ref,
        ],
      };

      if(data.documentType == 'DTE-39') {
        data.ref[0].razon = 'CASO-' + caso.numero
      }

      this.$http
        .post(`sii/dte?plain=1`, data)
        .then((res) => {
          caso.dte = res.data.toString();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    readFile() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          this.parseLines(text);
        };
        reader.readAsText(this.file, "ISO-8859-1");
      }
    },
    parseLines(text) {
      const set = {
        numeroAtencion: 0,
        casos: [],
      };

      //  NUMERO DE ATENCION:
      if (text.match(/NUMERO DE ATENCION/)) {
        const numeroAtencion = text.match(/NUMERO DE ATENCION: (\d+)/)[1];
        set.numeroAtencion = numeroAtencion;
      }

      // line only start with 'CASO 3654789-1'
      //console.log({text});

      let texto = `${text}`;

      let lines = texto.split("\n");

      let caso = {};

      lines.forEach((line, i) => {
        // SET BOLETA
        if (line.match(/^CASO-(\d+)/)) {
          caso.numero = line.match(/^CASO-(\d+)/)[1];

          caso.documento = "BOLETA ELECTRONICA";
          caso.dte = "";
          caso.ref = [];

          caso.items = [];

          if (caso.documento) {
            let indexItems = i + 4;
            let lineItems = lines[indexItems];

            // iterate items
            while (lineItems.length > 1 && lineItems.indexOf("\n") != 0) {
              let current = lineItems
                .split("\t")
                .filter((item) => item != "" && item != "\r")
                .map((item) => item.replace(/\s{2,}|\r/g, ""));
              console.log(lineItems, current);

              caso.items.push({
                item: current[0],
                iva: current[0].toLowerCase().indexOf("exento") == -1,
                cantidad: current[1],
                valor: current[2],
                //total: current[2],
                descuento: (current[3] || "0").replace("%", ""),
              });

              indexItems++;
              lineItems = lines[indexItems];
            }
          }
        }
        // SET FACTURA
        if (line.match(/^CASO\s(\d+-\d+)/)) {
          caso.numero = line.match(/^CASO\s(\d+-\d+)/)[1];

          let documento = lines[i + 2]
            .replace(/^DOCUMENTO\s/, "")
            .replace(/\t/g, "")
            .replace(/\r/, "");
          caso.documento = documento;
          caso.dte = "";
          caso.ref = [];

          caso.items = [];

          if (caso.documento == "FACTURA ELECTRONICA") {
            let indexItems = i + 5;
            let lineItems = lines[indexItems];

            // iterate items
            while (lineItems.length > 1 && lineItems.indexOf("--") != 0) {
              let current = lineItems
                .split("\t")
                .filter((item) => item != "" && item != "\r")
                .map((item) => item.replace(/\s{2,}|\r/g, ""));
              console.log(lineItems, current);

              caso.items.push({
                item: current[0],
                iva: current[0].indexOf("EXENTO") == -1,
                cantidad: current[1],
                valor: current[2],
                descuento: (current[3] || "0").replace("%", ""),
              });

              indexItems++;
              lineItems = lines[indexItems];

              let dctoGlobal = lines[indexItems + 1].match(
                /DESCUENTO GLOBAL ITEMES AFECTOS/
              );
              console.log("dcto global", dctoGlobal);
              if (dctoGlobal) {
                let dcto = lines[indexItems + 1].match(/(\d+)/)[0];
                console.log("dcto", dcto);
                caso.descuento = dcto;
              } else {
                caso.descuento = 0;
              }
            }
          }

          if (
            caso.documento == "NOTA DE CREDITO ELECTRONICA" ||
            caso.documento == "NOTA DE DEBITO ELECTRONICA"
          ) {
            let ref = lines[i + 3].match(/CASO\s(\d+-\d+)/)[0];
            let lineDocRef = lines[i + 3].replace(/\s{2}/g, " ");
            //console.log("line doc ref", lineDocRef);
            let docRef = lineDocRef.match(
              /(FACTURA ELECTRONICA|NOTA DE CREDITO ELECTRONICA|NOTA DE DEBITO ELECTRONICA)/
            );
            //console.log("doc ref", docRef);
            let razon = lines[i + 4].match(/RAZON REFERENCIA\s(.*)/)[1];
            let date = this.$moment().format("YYYY-MM-DD");
            //console.log("ref", ref);

            let casoFind = set.casos.find((c) => ref.indexOf(c.numero) != -1);

            caso.ref.push({
              razon,
              TpoDoc: this.getDocumentType(docRef[0]).replace("DTE-", ""),
              codRef: this.getCodRef(razon),
              folioRef: casoFind ? casoFind.folio : null,
              fechaRef: date,
            });

            if (casoFind) {
              let casoitems = JSON.parse(JSON.stringify(casoFind.items));
              let cod = this.getCodRef(razon);
              if (cod == 1 || cod == 3) {
                caso.items = casoitems;
              }
              if (cod == 2) {
                caso.items.push({
                  ...casoitems[0],
                  valor: 0,
                  cantidad: 1,
                });
              }
            }
          }
        }

        if (caso.numero && caso.documento) {
          set.casos.push(caso);
          caso = {};
        }

        if (line.match(/^SET LIBRO DE COMPRAS - NUMERO DE ATENCION:\s(\d+)/)) {
          console.log(line);
          set.compras = [];

          let indexItems = i + 7;
          let lineItems = lines[indexItems];

          while (lineItems.length > 1 && lineItems.indexOf("==") != 0) {
            let currentLine = lineItems
              .split("\t")
              .filter((item) => item != "" && item != "\r")
              .map((item) => item.replace(/\s{2,}|\r/g, ""));

            let detalleLine = lines[indexItems + 1]
              .split("\t")
              .filter((item) => item != "" && item != "\r")
              .map((item) => item.replace(/\s{2,}|\r/g, ""));

            let valuesLine = lines[indexItems + 2]
              .split("\t")
              .filter((item) => item != "" && item != "\r")
              .map((item) => item.replace(/\s{2,}|\r/g, ""))
              .reverse();

            let data = {
              documento: currentLine[0],
              folio: currentLine[1],
              detalle: detalleLine[0],
              totalNeto: valuesLine[0],
              exento: valuesLine[1] || 0,
            };

            if (data.documento == "NOTA DE CREDITO") {
              let m = detalleLine[0].match(
                /(\w+)\s(ELECTRONICA)?\s?(\d{1,}$)/g
              );
              let [folioRef] = m[0].match(/(\d{1,}$)/g);
              let [tipoRef] = m[0].match(/(\D+)/g);
              console.log({ m });
              data.folioDocRef = folioRef;
              data.tipoDocRef = tipoRef;
            }

            set.compras.push(data);
            // next line
            indexItems = indexItems + 4;
            lineItems = lines[indexItems];
          }
        }
      });

      console.log(set);
      this.set = set;
      // const matches = texto.matchAll(
      //   /CASO\s(\d+-\d+)\n={1,}\n([\s\S]*?)(\n{3,}|$|-{2,})/g
      // );
      // //console.log([...matches]);

      // let casos = [...matches]

      // for (let i=0; i<casos.length; i++) {
      //   console.log(casos[i]);
      //   set.casos.push(casos[i]);
      // }

      // console.log(set);
    },
  },
  mounted() {},
  watch: {
    file() {
      console.log(this.file);
    },
  },
};
</script>