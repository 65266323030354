<template>
  <b-overlay :show="loading">
    <div class="container-fluid">
      <div class="row" v-if="showInfo">
        <div class="col-12">
          <div class="alert alert-info">
            El reporte se ha actualizado
            <router-link :to="`/tickets/report2?date=${dateFilter}`"
              >Ir al reporte antiguo</router-link
            >
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-12">
          <h4 class="text-center text-uppercase" v-if="dateFilter">
            INFORME DEL DIA {{ dateFilter | moment("dddd") }}
            {{ dateFilter | moment("DD-MM-YY") }}
          </h4>
        </div>
        <div
          class="col-md-6 mb-3"
          v-if="hasPermission(user, 'ticket_list_change_date')"
        >
          <div class="row justify-content-center align-items-end">
            <div class="col-1 d-none">
              <button class="btn btn-block" @click="dateSubs()">
                <font-awesome-icon icon="chevron-left" />
              </button>
            </div>
            <div class="col-lg-4">
              <div class="input-group">
                <small>FECHA INICIAL</small>
                <date-picker
                  valueType="format"
                  v-model="dateFilter"
                  input-class="form-control pl-4"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="input-group">
                <small>FECHA TERMINO</small>
                <date-picker
                  valueType="format"
                  v-model="enddateFilter"
                  input-class="form-control pl-4"
                />
              </div>
            </div>
            <div class="col-1 d-none">
              <button class="btn btn-block" @click="dateAdd()">
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-block btn-success rounded" @click="load()">
                <div
                  class="spinner-border spinner-border-sm text-light mb"
                  role="status"
                  v-if="loading"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <font-awesome-icon icon="redo" v-if="!loading" />
              </button>
            </div>
          </div>

          <div class="col col-lg col-sm-12 mb-4 bg-light shadow py-3 d-none">
            <div class="row h-100 no-gutters align-items-end">
              <div class="col-12 h-100">
                <div
                  class="py-4 mb-2 text-center row h-50"
                  :class="{
                    'text-success': getScore >= 50,
                    'text-info': getScore < 50 && getScore > 10,
                    'text-danger': getScore <= 0,
                  }"
                >
                  <div class="col-12 py-0">
                    <h5 class="position-absolute" style="top: 0px; right: 30px">
                      <span v-if="report.total - report.totalPrev > 0">+</span>
                      ${{ (report.total - report.totalPrev) | formatNumber }}
                    </h5>
                    <h1
                      class="font-weight-bolder mt-5"
                      style="font-size: 3.6em"
                    >
                      {{ getScore }}%
                    </h1>
                    <small class="d-none text-muted"></small>
                  </div>
                </div>

                <div class="col-12 h-50">
                  <ul class="list-group border-0 align-self-end">
                    <li class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <small class="text-muted">TOTAL HOY</small>
                        </div>
                        <div class="col text-right">
                          <h5 class="my-2 font-weight-bolder">
                            ${{ report.total | formatNumber }}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <small class="text-muted">TOTAL AYER</small>
                        </div>
                        <div class="col text-right">
                          <h5 class="my-2 font-weight-bolder">
                            ${{ report.totalPrev | formatNumber }}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100"></div>
        <div class="col-12">
          <b-tabs pills content-class="py-2" fill>
            <b-tab title="GENERAL">
              <div class="row no-gutters">
                <div class="col-12 py-4">
                  <small class="d-block text-center text-muted"
                    >CLICK PARA ACTIVAR/DESACTIVAR</small
                  >
                  <b-overlay
                    :opacity="0.7"
                    :show="report.ticketsPerHour.length == 0"
                  >
                    <template #overlay>
                      <div class="text-center">
                        <p id="cancel-label">Sin informacion</p>
                      </div>
                    </template>
                    <apexchart
                      width="100%"
                      height="260"
                      type="line"
                      :options="chartDays"
                    />
                  </b-overlay>
                </div>
                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Total general"
                    textClass="text-primary"
                    format
                    icon="money-bill-wave"
                    iconClass="text-muted"
                    :text="totalGeneral"
                  />
                </div>
                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Total Ventas"
                    textClass="text-primary"
                    format
                    icon="money-bill-wave"
                    iconClass="text-muted"
                    :text="report.total"
                  />
                </div>
                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Gastos totales"
                    textClass="text-danger"
                    format
                    icon="money-bill-wave"
                    iconClass="text-danger text-through"
                    :text="report.expenses"
                  />
                </div>
                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Balance del dia"
                    textClass="text-success"
                    format
                    icon="money-bill-wave"
                    iconClass="text-success"
                    :text="report.total - report.totalExpenses"
                  />
                </div>

                <div class="col-md-6 col-lg-3 col-6 d-none">
                  <item-icon
                    title="Total dia anterior"
                    textClass="text-secondary"
                    format
                    :text="report.totalPrev"
                  />
                </div>

                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Cantidad de ventas"
                    textClass="text-success"
                    icon="file-alt"
                    iconClass="text-muted"
                    :text="report.ticketsCount"
                  />
                </div>

                <!-- <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Cantidad de items"
                    textClass="text-muted"
                    icon="file-alt"
                    iconClass="text-muted"
                    :text="report.ticketsCount"
                  />
                </div> -->

                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="Tickets Cancelados"
                    format
                    :subtitle="`#${report.ticketsCountDeleted}`"
                    :text="report.totalDeleted"
                    icon="file-alt"
                    iconClass="text-danger"
                  />
                </div>

                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="dctos productos"
                    icon="caret-down"
                    iconClass="text-warning"
                    textClass="text-warning"
                    :text="report.totalDiscount"
                    format
                  />
                </div>

                <div class="col-md-6 col-lg-3 col-6">
                  <item-icon
                    title="dctos globales"
                    icon="caret-down"
                    iconClass="text-danger"
                    textClass="text-warning"
                    :text="report.totalGlobalDiscount"
                    format
                  />
                </div>

                <div class="col-md-6 col-lg-3 col-6" v-if="info.webEnabled && report.webTickets">
                  <item-icon
                    title="Pedidos web"
                    textClass="text-success"
                    :text="report.webTickets.total"
                    format
                    :subtitle="`#${report.webTickets.items}`"
                    icon="globe"
                    iconClass="text-muted"
                  />
                </div>

                <!-- <div
                  class="col-md-6 col-lg-3 col-6"
                  v-if="info.deliveryEnabled"
                >
                  <item-icon
                    title="Total Envios"
                    textClass="text-warning"
                    :text="ticketsWebEnvioInProducts"
                    icon="truck"
                    format
                    iconClass="text-muted"
                  />
                </div> -->

                <div class="col-md-6 col-lg-3 col-6" v-if="info.otEnabled">
                  <item-icon
                    title="Total Abonos"
                    textClass="text-info"
                    :text="deposit"
                    format
                  />
                </div>

                <div
                  class="col-md-6 col-lg-3 col-6"
                  v-if="info.beEnabled"
                  v-for="type in report.documentTypes"
                  :key="type"
                >
                  <item-icon
                    :title="`Total ${getDTEName(type._id)}`"
                    textClass="text-info"
                    format
                    :text="type.total"
                    :subtitle="`#${type.items}`"
                  />
                </div>

                <div class="col-md-6 col-lg-3 col-6" v-if="info.sumupEnabled">
                  <item-icon
                    title="Total SumUp"
                    textClass="text-info"
                    format
                    subtitle="sumup.com"
                    :text="sumupTotal"
                  />
                </div>

                <div
                  class="col-md-6 col-lg-3 col-6"
                  v-if="info.deliveryEnabled"
                  v-for="type in report.types"
                  :key="type"
                >
                  <item-icon
                    :title="`Total ${type._id}`"
                    textClass="text-info"
                    format
                    :text="type.total"
                    :subtitle="`#${type.items}`"
                  />
                </div>
              </div>

              <div class="row mt-4 py-3">
                <div class="col-12">
                  <b-tabs pills justified content-class="py-2">
                    <b-tab title="VISOR GENERAL">
                      <div class="row">
                        <div class="col-12">
                          <small class="text-muted"
                            >MEDIOS DE PAGOS | TOTAL ${{
                              getTotalReport | formatNumber
                            }}
                            EN PAGOS</small
                          >
                          <ul
                            class="list-group"
                            v-if="loading & (getReportPayments.length == 0)"
                          >
                            <li class="list-group-item" v-for="i in 4" :key="i">
                              <div class="row">
                                <div class="col">
                                  <b-skeleton width="40%" />
                                </div>
                                <div class="col-3">
                                  <b-skeleton width="10%" />
                                </div>
                                <div class="col-2 ml-auto">
                                  <b-skeleton />
                                </div>
                                <div class="col-12">
                                  <b-skeleton
                                    :width="`${Math.floor(
                                      Math.random() * (80 - 20 + 1) + 20
                                    )}%`"
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul class="list-group" v-else>
                            <li
                              class="list-group-item"
                              v-if="report.payments.length == 0"
                            >
                              <h4 class="text-center py-3">
                                No hay informacion
                              </h4>
                            </li>
                            <li
                              class="list-group-item list-group-item-action"
                              v-for="(p, i) in report.payments"
                              :key="`payment-${i}`"
                            >
                              <div class="row align-items-end">
                                <div class="col-12 d-none">
                                  {{ p }}
                                </div>
                                <div class="col-12 text-uppercase">
                                  <strong class="d-block">{{ p._id }}</strong>
                                </div>
                                <div class="ml-auto col-4 col-lg-2 text-center">
                                  <small>CANTIDAD</small>
                                  <div class="w-100"></div>
                                  {{ p.items }}
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small>TOTAL PAGADO</small>
                                  <div class="w-100"></div>
                                  <strong
                                    >${{ p.amount | formatNumber }}</strong
                                  >
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small>VUELTOS</small>
                                  <div class="w-100"></div>
                                  <strong>${{ p.diff | formatNumber }}</strong>
                                </div>
                                <div class="col-4 col-lg-2 text-right d-none">
                                  <small>TOTAL TICKETS</small>
                                  <div class="w-100"></div>
                                  <strong
                                    >${{ p.ttotal | formatNumber }}</strong
                                  >
                                </div>
                                <div class="col-4 col-lg-2 text-right d-none">
                                  <small>TOTAL DELIVERY</small>
                                  <div class="w-100"></div>
                                  <strong
                                    >${{ p.totalDelivery | formatNumber }}</strong
                                  >
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small>TOTAL</small>
                                  <div class="w-100"></div>
                                  <strong
                                    >${{ p.gTotal | formatNumber }}</strong
                                  >
                                </div>

                                <div class="col-12 mt-1">
                                  <b-progress :max="report.total">
                                    <b-progress-bar
                                      :value="p.total"
                                      variant="info"
                                    />
                                  </b-progress>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="VISOR POR CAJERO">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <div v-if="cashers.length > 0">
                            <small class="pt-2">CAJEROS</small>
                            <ul class="list-group" v-if="cashers.length > 0">
                              <li
                                class="list-group-item"
                                v-for="(s, i) in cashers"
                                :key="`casher-${i}`"
                              >
                                <div class="row no-gutters align-items-center">
                                  <div class="col-1">
                                    <b-checkbox v-model="s.check" size="lg" />
                                  </div>
                                  <div class="col pl-3">
                                    <strong class="d-block text-uppercase">{{
                                      s.name
                                    }}</strong>
                                    <small class="d-block">{{ s.email }}</small>
                                  </div>
                                  <div class="col-12 ml-auto font-weight-bold">
                                    <h4
                                      class="d-block text-uppercase text-right font-weight-bold"
                                    >
                                      ${{ formatNumber(s.total) }}
                                    </h4>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div v-else>
                            <h3 class="text-center py-5">No hay cajeros</h3>
                          </div>
                        </div>
                        <div class="col-12 col-md">
                          <small class="text-muted d-none1"
                            >MEDIOS DE PAGOS | TOTAL ${{
                              getTotalReportCashers | formatNumber
                            }}
                            EN PAGOS</small
                          >
                          <ul
                            class="list-group"
                            v-if="loading & (getReportPayments.length == 0)"
                          >
                            <li class="list-group-item" v-for="i in 4" :key="i">
                              <div class="row">
                                <div class="col">
                                  <b-skeleton width="40%" />
                                </div>
                                <div class="col-3">
                                  <b-skeleton width="10%" />
                                </div>
                                <div class="col-2 ml-auto">
                                  <b-skeleton />
                                </div>
                                <div class="col-12">
                                  <b-skeleton
                                    :width="`${Math.floor(
                                      Math.random() * (80 - 20 + 1) + 20
                                    )}%`"
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul class="list-group" v-else>
                            <li
                              class="list-group-item"
                              v-if="getReportPayments.length == 0"
                            >
                              <h4 class="text-center py-3">
                                No hay informacion
                              </h4>
                            </li>
                            <li
                              class="list-group-item list-group-item-action"
                              v-for="(p, i) in getReportPayments"
                              :key="`payment-${i}`"
                            >
                              <div class="row align-items-end">
                                <div class="col-12 col-md text-uppercase">
                                  <small class="text-muted">{{
                                    p._id.user.name
                                  }}</small>
                                  <strong class="d-block">{{
                                    p._id.payment
                                  }}</strong>
                                </div>
                                <div class="ml-auto col-4 col-lg-2 text-center">
                                  <small class="d-block">CANTIDAD</small>
                                  {{ p.items }}
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small class="d-block">TOTAL PAGADO</small>
                                  <strong
                                    >${{ p.amount | formatNumber }}</strong
                                  >
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small class="d-block">DIFF</small>
                                  <strong>${{ p.diff | formatNumber }}</strong>
                                </div>
                                <div class="col-4 col-lg-2 text-right d-none">
                                  <small class="d-block">TICKETS</small>
                                  <strong>${{ p.total | formatNumber }}</strong>
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small class="d-block">TOTAL</small>
                                  <strong>${{ p.total | formatNumber }}</strong>
                                </div>
                                <div class="col-12 mt-1">
                                  <b-progress :max="report.total">
                                    <b-progress-bar
                                      :value="p.total"
                                      variant="info"
                                    />
                                  </b-progress>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="VISOR POR CAJERO SIN DELIVERY">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <div v-if="cashers.length > 0">
                            <small class="pt-2">CAJEROS</small>
                            <ul class="list-group" v-if="cashers.length > 0">
                              <li
                                class="list-group-item"
                                v-for="(s, i) in cashers"
                                :key="`casher-${i}`"
                              >
                                <div class="row no-gutters align-items-center">
                                  <div class="col-1">
                                    <b-checkbox v-model="s.check" size="lg" />
                                  </div>
                                  <div class="col pl-3">
                                    <strong class="d-block text-uppercase">{{
                                      s.name
                                    }}</strong>
                                    <small class="d-block">{{ s.email }}</small>
                                  </div>
                                  <div class="col-12 ml-auto font-weight-bold">
                                    <h4
                                      class="d-block text-uppercase text-right font-weight-bold"
                                    >
                                      ${{ formatNumber(s.total) }}
                                    </h4>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div v-else>
                            <h3 class="text-center py-5">No hay cajeros</h3>
                          </div>
                        </div>
                        <div class="col-12 col-md">
                          <small class="text-muted d-none1"
                            >MEDIOS DE PAGOS | TOTAL ${{
                              getTotalReportCashers | formatNumber
                            }}
                            EN PAGOS</small
                          >
                          <ul
                            class="list-group"
                            v-if="loading & (getReportPaymentsWithoutDelivery.length == 0)"
                          >
                            <li class="list-group-item" v-for="i in 4" :key="i">
                              <div class="row">
                                <div class="col">
                                  <b-skeleton width="40%" />
                                </div>
                                <div class="col-3">
                                  <b-skeleton width="10%" />
                                </div>
                                <div class="col-2 ml-auto">
                                  <b-skeleton />
                                </div>
                                <div class="col-12">
                                  <b-skeleton
                                    :width="`${Math.floor(
                                      Math.random() * (80 - 20 + 1) + 20
                                    )}%`"
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul class="list-group" v-else>
                            <li
                              class="list-group-item"
                              v-if="getReportPayments.length == 0"
                            >
                              <h4 class="text-center py-3">
                                No hay informacion
                              </h4>
                            </li>
                            <li
                              class="list-group-item list-group-item-action"
                              v-for="(p, i) in getReportPayments"
                              :key="`payment-${i}`"
                            >
                              <div class="row align-items-end">
                                <div class="col-12 col-md text-uppercase">
                                  <small class="text-muted">{{
                                    p._id.user.name
                                  }}</small>
                                  <strong class="d-block">{{
                                    p._id.payment
                                  }}</strong>
                                </div>
                                <div class="ml-auto col-4 col-lg-2 text-center">
                                  <small class="d-block">CANTIDAD</small>
                                  {{ p.items }}
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small class="d-block">TOTAL PAGADO</small>
                                  <strong
                                    >${{ p.amount | formatNumber }}</strong
                                  >
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small class="d-block">DIFF</small>
                                  <strong>${{ p.diff | formatNumber }}</strong>
                                </div>
                                <div class="col-4 col-lg-2 text-right d-none1">
                                  <small class="d-block">TICKETS</small>
                                  <strong>${{ p.ttotal | formatNumber }}</strong>
                                </div>
                                <div class="col-6 col-lg-2 text-right">
                                  <small class="d-block">TOTAL</small>
                                  <strong>${{ p.total | formatNumber }}</strong>
                                </div>
                                <div class="col-12 mt-1">
                                  <b-progress :max="report.total">
                                    <b-progress-bar
                                      :value="p.total"
                                      variant="info"
                                    />
                                  </b-progress>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-tab>
              
                  </b-tabs>
                </div>
              </div>

              <!-- start general -->
              <div class="row d-none">
                <div class="col-md-3" style="padding: 0px 0; margin: 0">
                  <small class="d-block mt-3" v-if="info.deliveryEnabled"
                    >TIPO DE PEDIDOS</small
                  >
                  <ul class="list-group" v-if="info.deliveryEnabled">
                    <li class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <strong class="d-block">Repartos</strong>
                          <small class="text-uppercase"
                            >Total de pedidos
                          </small>
                        </div>
                        <div class="col-2 text-center">
                          {{ totalDelivery }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <strong class="d-block">Retiros</strong>
                          <small class="text-uppercase"
                            >Total de pedidos
                          </small>
                        </div>
                        <div class="col-2 text-center">
                          {{ totalLocal }}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="list-group" v-if="info.webEnabled">
                    <li class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <strong class="d-block">Pedidos web</strong>
                          <small class="text-uppercase">Total de pedidos</small>
                        </div>
                        <div class="col-2 text-center">
                          {{ totalWeb }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6" v-if="false">
                  <ul class="list-group">
                    <li class="list-group-item text-success">
                      <div class="row align-items-center">
                        <div class="col">
                          <strong class="d-block">Total del dia</strong>
                          <small class="text-uppercase">Todas las ventas</small>
                        </div>
                        <div class="col-4 text-right font-weight-bold">
                          ${{ formatNumber(totalTickets + deposit) || 0 }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item text-danger">
                      <div class="row align-items-center">
                        <div class="col font-weight-bold">
                          <strong class="d-block">Gastos del dia</strong>
                          <small class="text-uppercase"
                            >Suma de todas los gastos</small
                          >
                        </div>
                        <div class="col text-right font-weight-bold">
                          -${{ formatNumber(expensesTotal) }}
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item list-group-item-dark">
                      <div class="row align-items-center">
                        <div class="col">
                          <strong class="d-block">Balance del dia</strong>
                          <small class="text-uppercase">VENTA - GASTOS</small>
                        </div>
                        <div class="col text-right font-weight-bold">
                          ${{
                            formatNumber(
                              totalTickets + deposit - expensesTotal
                            ) || 0
                          }}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div
                    class="alert alert-warning mt-2 font-weight-bold text-center"
                    v-if="ticketWithoutPayment > 0"
                  >
                    <font-awesome-icon icon="bell" class="mr-2" /> Tienes ${{
                      formatNumber(ticketWithoutPayment)
                    }}
                    sin medios de pagos
                  </div>
                  <ul class="list-group mt-3" v-if="false">
                    <li
                      class="list-group-item list-group-item-dark font-weight-bold text-uppercase"
                    >
                      <div class="row">
                        <div class="col-3">Pago</div>
                        <div class="col text-right">Tickets</div>
                        <div class="col-3 text-right" v-if="info.otEnabled">
                          OT
                        </div>
                        <div class="col text-right">TOTAL</div>
                      </div>
                    </li>
                    <li class="list-group-item" v-for="p in payments" :key="p">
                      <div class="row">
                        <div class="col-3">
                          <strong>{{ p }}</strong>
                        </div>
                        <div class="col text-right">
                          <div class="row">
                            <div class="col-4">
                              ({{ totalPaymentCount(p) }})
                            </div>
                            <div class="col">
                              <strong>
                                ${{ formatNumber(totalPayment(p)) }}</strong
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-3 text-right" v-if="info.otEnabled">
                          ${{ formatNumber(totalPaymentOT(p)) }}
                        </div>
                        <div class="col text-right font-weight-bold">
                          ${{
                            formatNumber(totalPaymentOT(p) + totalPayment(p))
                          }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-4">
                  <pre>{{ report }}</pre>
                </div>
                <div class="col-md-6 col-lg-4 d-none">
                  <div v-if="info.otEnabled">
                    <b-button
                      :aria-expanded="visibleCatOt ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      @click="visibleCatOt = !visibleCatOt"
                      class="btn-block mt-2"
                    >
                      DETALLE POR CATEGORIAS OT
                    </b-button>
                    <b-collapse
                      id="collapse-4"
                      v-model="visibleCatOt"
                      class="mt-2"
                    >
                      <div class="" style="height: 300px; overflow-y: scroll">
                        <div class="text-center">
                          <small class="d-block alert alert-info"
                            >Equivale a las OT generadas, no tiene relacion con
                            los abonos</small
                          >
                        </div>
                        <ul class="list-group">
                          <li
                            class="list-group-item"
                            v-if="otCategory.length == 0"
                          >
                            <h4 class="text-center p-3">Sin informacion</h4>
                          </li>
                          <li
                            class="list-group-item"
                            v-for="(t, i) in otCategory"
                            :key="i"
                          >
                            <div class="row">
                              <div class="col-6">
                                <span class="font-weight-bold">
                                  {{ t._id.name }}
                                </span>
                              </div>
                              <div class="col">
                                ${{ formatNumber(t.total) }}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </b-collapse>
                  </div>

                  <div v-if="info.otEnabled">
                    <b-button
                      :aria-expanded="visibleDeposit ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      @click="visibleDeposit = !visibleDeposit"
                      class="btn-block mt-2"
                    >
                      DETALLE ABONOS
                    </b-button>
                    <b-collapse
                      id="collapse-4"
                      v-model="visibleDeposit"
                      class="mt-2"
                    >
                      <div class="" style="height: 300px; overflow-y: scroll">
                        <ul class="list-group">
                          <li
                            class="list-group-item"
                            v-if="deposits.length == 0"
                          >
                            <h4 class="text-center p-3">Sin informacion</h4>
                          </li>
                          <li
                            :class="{
                              'list-group-item-danger': t.deletedAt,
                            }"
                            class="list-group-item"
                            v-for="(t, i) in deposits"
                            :key="i"
                          >
                            <div class="row">
                              <div class="col-2 font-weight-bold">
                                {{ t.createdAt | moment("HH:mm") }}
                              </div>
                              <div class="col-3 font-weight-bold">
                                OT#{{ t.ot.number }}
                              </div>
                              <div class="col-4">
                                <span class="font-weight-bold">
                                  {{ t.payment }}
                                </span>
                              </div>
                              <div class="col text-right">
                                ${{ formatNumber(t.amount) }}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
              <!-- end general -->
            </b-tab>

            <b-tab title="VENTAS">
              <div class="row">
                <div
                  class="col-6 overflow-auto custom_scroll"
                  style="max-height: 50vh"
                >
                  <small
                    >VENTAS POR CATEGORIA | TOTAL
                    {{ perCategory.length }}</small
                  >
                  <ul class="list-group">
                    <li class="list-group-item" v-if="perCategory.length == 0">
                      <h4 class="text-center p-3">Sin informacion</h4>
                    </li>
                    <li
                      class="list-group-item"
                      v-for="(t, i) in perCategory"
                      :key="i"
                    >
                      <div class="row">
                        <div class="col-6">
                          <span class="font-weight-bold">
                            {{ t._id.name }}
                          </span>
                        </div>
                        <div class="col">
                          <span class="font-weight-bold">
                            {{ t.items }}
                          </span>
                        </div>
                        <div class="col text-right font-weight-bold">
                          ${{ formatNumber(t.total) }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  class="col-6 overflow-auto custom_scroll"
                  style="max-height: 50vh"
                >
                  <small
                    >VENTAS POR PRODUCTO | TOTAL
                    {{ report.products.length }}</small
                  >
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-if="report.products.length == 0"
                    >
                      <h4 class="text-center p-3">Sin informacion</h4>
                    </li>
                    <li
                      class="list-group-item"
                      v-for="(t, i) in report.products"
                      :key="i"
                    >
                      <div class="row align-items-center">
                        <div class="col-6">
                          <span class="">
                            <small>COD: {{ t._id.code }}</small>
                            <div class="w-100"></div>
                            <strong class="text-uppercase">{{
                              t._id.name
                            }}</strong>
                          </span>
                        </div>

                        <div class="col">
                          <span class="font-weight-bold">
                            {{ t.items }}
                          </span>
                        </div>
                        <div class="col">${{ t._id.value | formatNumber }}</div>
                        <div class="col text-right font-weight-bold">
                          ${{ formatNumber(t.total) }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row py-2">
                <div class="col">
                  <small>BUSCAR</small>
                  <input
                    type="text"
                    class="form-control"
                    v-model="ticketFilter"
                  />
                  <small
                    >CODIGO O NOMBRE PRODUCTO - CLIENTE - DIRECCION -
                    TELEFONO</small
                  >
                </div>
                <div class="col-4">
                  <small>MEDIO DE PAGO</small>
                  <select class="form-control" v-model="paymetFilter">
                    <option value="">TODOS</option>
                    <option
                      :value="p"
                      v-for="p in payments"
                      :key="`payment-${p}`"
                    >
                      {{ p }}
                    </option>
                  </select>
                </div>
                <div class="col-4">
                  <small>MOSTRAR</small>
                  <select class="form-control" v-model="limit">
                    <option value="">TODOS</option>
                    <option :value="i * 50" v-for="i in 10" :key="`limit-${i}`">
                      {{ i  * 50}}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="custom_scroll"
                style="height: 80vh; overflow-y: scroll"
              >
                <small class="font-italic"
                  >MOSTRANDO {{ ticketsFiltered.length }}</small
                >
                <ul class="list-group py-4">
                  <li class="list-group-item" v-if="tickets.length == 0">
                    <h4 class="text-center py-3">Sin informacion</h4>
                  </li>
                  <li
                    class="list-group-item list-group-item-action click"
                    v-for="t in ticketsFiltered"
                    :key="t._id"
                    @click="loadTicket(t)"
                    :class="{
                      'list-group-item-danger': t.deletedAt,
                    }"
                  >
                    <div class="row">
                      <div class="col-12" v-if="t.deletedAt">
                        Cancelado: {{ t.reason }}
                      </div>
                      <div class="col-2 text-center">
                        <span class="font-weight-bold">
                          {{ t.createdAt | moment("HH:mm") }}
                        </span>
                        <div class="w-100"></div>
                        <small class="font-weight-bold text-uppercase">
                          {{ t.user_sell && t.user_sell.name }}
                        </small>
                        <div class="w-100 border-bottom my-2"></div>
                        <span
                          class="badge badge-primary px-2"
                          v-if="t.clientName != 'anon'"
                        >
                          <small class="text-uppercase">
                            {{ t.clientName }}
                          </small>
                        </span>
                        <span class="badge badge-secondary px-2">
                          <small class="text-uppercase">
                            {{ t.type }}
                          </small>
                        </span>
                        <span
                          class="badge badge-success px-2"
                          v-if="t.webTicket"
                        >
                          <small>PEDIDO WEB</small>
                        </span>
                      </div>
                      <div class="col">
                        <div class="row no-guuters">
                          <div
                            class="col-12 py-2 bglight"
                            v-for="(p, i) in t.products"
                            :key="i"
                          >
                            <div class="row align-items-center">
                              <div class="col">
                                <span class="d-block font-weight-bold">
                                  {{ p.qty }} x
                                  <span class="text-uppercase">
                                    {{ p.name }}
                                  </span>
                                </span>
                                <small class="d-block">
                                  CODIGO: {{ p.code }}
                                </small>
                                <small class="d-block">
                                  VALOR UNITARIO: ${{ formatNumber(p.value) }}
                                </small>
                              </div>
                              <div class="col-3 text-right">
                                ${{ formatNumber(p.total) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 mt-1 font-weight-bold"
                        v-if="t.discount"
                      >
                        <div class="row">
                          <div class="col-4 ml-auto text-right">DESCUENTO</div>
                          <div class="col-3 text-right">
                            ${{ formatNumber(t.discount) }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 font-weight-bold">
                        <div
                          class="row align-items-center py"
                          v-for="p in t.payments"
                          :key="p._id"
                        >
                          <div class="col-md-6 ml-auto text-right">
                            <span class="text-uppercase">{{ p.name }}</span>
                          </div>
                          <div class="col-md-2 text-right bglight py-3">
                            <h5>${{ formatNumber(p.amount) }}</h5>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 font-weight-bold">
                        <div class="row align-items-center py">
                          <div class="col-md-6 ml-auto text-right">
                            <span class="text-uppercase">TOTAL</span>
                          </div>
                          <div
                            class="col-md-2 font-weight-bold text-right bg-dark text-white py-3"
                            style="font-size: 24px"
                          >
                            ${{ formatNumber(t.total) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </b-tab>

            <b-tab title="SUMUP" v-if="info.sumupEnabled">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <strong class="d-block">Cobros SumUP</strong>
                      <small class="text-uppercase">Cobros sin propinas</small>
                    </div>
                    <div class="col-4 text-right text-success">
                      ${{ formatNumber(sumupTotal - totalTips) }}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <strong class="d-block">Propinas SumUP</strong>
                      <small class="text-uppercase">Cobros con propinas</small>
                    </div>
                    <div class="col-4 text-right text-success">
                      ${{ formatNumber(totalTips) }}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <strong class="d-block">Total SumUP</strong>
                      <small class="text-uppercase">Desde sumup.com</small>
                    </div>
                    <div class="col-4 text-right text-success font-weight-bold">
                      ${{ formatNumber(sumupTotal) }}
                    </div>
                  </div>
                </li>
              </ul>

              <div class="w-100 py-2"></div>

              <ul class="list-group">
                <li
                  class="list-group-item click list-group-item-action"
                  :key="sumup._id"
                  v-for="sumup in sumupAll"
                  @click="viewTicket(sumup.ticket)"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <strong class="d-block">{{
                        sumup.user ? sumup.user.name : "- - - -"
                      }}</strong>
                      <small class="text-uppercase">USUARIO</small>
                    </div>
                    <div class="col-3 text-right">
                      ${{ formatNumber(sumup.amount) }}
                      <div class="w-100"></div>
                      <small class="text-uppercase">TOTAL PEDIDO</small>
                    </div>
                    <div class="col-3 text-right text-success">
                      ${{ formatNumber(sumup.tip) }}
                      <div class="w-100"></div>
                      <small class="text-uppercase">PROPINA</small>
                    </div>
                    <div class="col-3 text-right font-weight-bold">
                      ${{ formatNumber(sumup.total) }}
                      <div class="w-100"></div>
                      <small class="text-uppercase">TOTAL COBRADO</small>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="GASTOS">
              <div class="" style="height: 300px; overflow-y: scroll">
                <ul class="list-group">
                  <li class="list-group-item" v-if="expenses.length == 0">
                    <h4 class="text-center py-3">Sin informacion</h4>
                  </li>
                  <li
                    class="list-group-item"
                    v-for="t in expenses"
                    :key="t._id"
                    :class="{
                      'list-group-item-danger': t.deletedAt,
                    }"
                  >
                    <div class="row">
                      <div class="col-2">
                        <span class="font-weight-bold">
                          {{ t.createdAt | moment("HH:mm") }}
                        </span>
                      </div>
                      <div class="col text-uppercase font-weight-bold">
                        {{ t.name }}
                      </div>
                      <div class="col-3 text-right">
                        $ {{ formatNumber(t.value) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import itemIcon from "./itemIcon.vue";
import VueApexCharts from "vue-apexcharts";
import { getDTEName, getDTEList, getTransactionName } from "../utils";

export default {
  props: {
    showInfo: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    itemIcon,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      limit: 50,
      ticketFilter: "",
      paymetFilter: "",
      loading: true,
      cash: {},
      sumup: [],
      totalTips: 0,
      deposit: 0,
      deposits: [],
      categories: [],
      visible: false,
      visibleExpenses: false,
      visibleCat: false,
      visibleDeposit: false,
      visibleCatOt: false,
      perCategory: [],
      otCategory: [],
      expenses: [],
      dateFilter: null,
      enddateFilter: null,
      users: [0],
      cashers: [],
      payments_tickets: new Map(),
      report: {
        total: 0,
        totalPrev: 0,
        tickets: {},
        totalTickets: 0,
        totalExpenses: 0,
        totalOt: 0,
        payments: [],
        cashers: [],
        category: [],
        ticketsPerHour: [],
        products: [],
        ticketList: [],
      },
      sumupAll: [],
    };
  },
  methods: {
    getDTEName,
    viewTicket(ticket) {
      this.$store.commit("setTicket", ticket);
      this.$emit("showTicket");
    },
    loadReport() {
      let start = this.$moment(this.dateFilter);
      let end = this.$moment(this.enddateFilter);
      let diff = this.$moment(end).diff(start, "days");
      //console.log("diff", diff);
      if (diff > 31) {
        this.$noty.info(
          "Maximo 31 dias de diferencia. Ajustando automaticamente"
        );
        this.enddateFilter = this.$moment(this.dateFilter)
          .add(30, "days")
          .format("YYYY-MM-DD");
      } else if (diff < 0) {
        this.$noty.info(
          "La fecha final debe ser mayor a la inicial. Ajustando automaticamente"
        );
        this.enddateFilter = this.$moment(this.dateFilter)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
      this.loading = true;
      this.$http
        .get(`ticket/report?date=${this.dateFilter}&end=${this.enddateFilter}`)
        .then((res) => {
          this.report = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadTicket(t) {
      this.$store.commit("setTicket", t);
      this.$emit("showTicket");
    },
    dateAdd() {
      this.dateFilter = this.$moment(this.dateFilter)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    dateSubs() {
      this.dateFilter = this.$moment(this.dateFilter)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    totalPayment(payment) {
      // let total = 0;
      // this.tickets.forEach((t) => {
      //   if (t.deletedAt == null) {
      //     if (t.payments && t.payments.length > 0) {
      //       // t.payments.reduce((t,c) => {
      //       //   return t + c.
      //       // }, 0)
      //       t.payments.forEach((p) => {
      //         if (p.name == payment) {
      //           if (p.total <= p.amount) total = total + p.total || 0;
      //           if (p.total > p.amount) total = total + p.amount || 0;
      //         }
      //       });
      //     } else if (t.payment == payment &&t.payments.length == 0) {
      //       total = total + t.total;
      //     }
      //   } else {
      //     console.log('ticketeliminado')
      //   }
      //   console.log(`1 - ${payment}`, total)
      // });
      // console.log(`2 - ${payment}`, total)
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + t.total;
        if (t.payments) {
          return (
            c +
            t.payments.reduce((a, p) => {
              if (p.name == payment) {
                if (t.total == p.amount) return a + p.total;
                if (p.total < p.amount) return a + p.total;
                if (p.total > p.amount) return a + p.amount;
                else return a + p.total;
              }
              return a;
            }, 0)
          );
        }
        return c;
      }, 0);
      // return total;
    },
    totalPaymentCount(payment) {
      // let total = 0;
      // this.tickets.forEach((t) => {
      //   if (t.deletedAt == null) {
      //     if (t.payments && t.payments.length > 0) {
      //       // t.payments.reduce((t,c) => {
      //       //   return t + c.
      //       // }, 0)
      //       t.payments.forEach((p) => {
      //         if (p.name == payment) {
      //           if (p.total <= p.amount) total = total + p.total || 0;
      //           if (p.total > p.amount) total = total + p.amount || 0;
      //         }
      //       });
      //     } else if (t.payment == payment &&t.payments.length == 0) {
      //       total = total + t.total;
      //     }
      //   } else {
      //     console.log('ticketeliminado')
      //   }
      //   console.log(`1 - ${payment}`, total)
      // });
      // console.log(`2 - ${payment}`, total)
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + 1;
        if (t.payments && t.payments) {
          return (
            c +
            t.payments.reduce((a, p) => {
              if (p.name == payment) {
                return a + 1;
              }
              return a;
            }, 0)
          );
        }
        return c;
      }, 0);
      // return total;
    },
    countPayment(payment) {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + 1;
        return c;
      }, 0);
    },
    totalPaymentOT(payment) {
      return this.deposits.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + t.amount;
        return c;
      }, 0);
    },
    countPaymentOT(payment) {
      return this.deposits.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + 1;
        return c;
      }, 0);
    },
    load() {
      let end = this.$moment(this.enddateFilter).format("YYYY-MM-DD");
      this.loadReport();

      this.$http.get(`ticket?date=${this.dateFilter}`).then((res) => {
        this.$store.commit("setTickets", res.data.data);
      });

      this.$http
        .get(`ticket/category?date=${this.dateFilter}&end=${end}`)
        .then((res) => {
          this.perCategory = res.data.data;
        });

      this.$http
        .get(`ticket/cash?date=${this.dateFilter}&end=${end}`)
        .then((res) => {
          this.cashers = res.data.data.map((c) => {
            if (typeof c.check == "undefined") c.check = true;
            return {
              ...c._id,
              check: c.check,
              total: c.total,
            };
          });
        });

      // this.$http
      //   .get("category")
      //   .then((res) => (this.categories = res.data.data));

      if (this.info.otEnabled) {
        this.$http
          .get(`ot/category?date=${this.dateFilter}&end=${end}`)
          .then((res) => {
            this.otCategory = res.data.data;
          });
        this.$http
          .get(`deposit?date=${this.dateFilter}&end=${end}`)
          .then((res) => {
            this.deposits = res.data.data;
            this.deposit = res.data.data.reduce((t, item) => {
              if (item.deletedAt) return t;
              return item.amount + t;
            }, 0);
          });
      }

      this.$http
        .get(`expenses?date=${this.dateFilter}&end=${end}`)
        .then((res) => {
          this.expenses = res.data.data;
        });

      if (this.info.sumupEnabled) {
        this.$http
          .get(`payments?date=${this.dateFilter}`)

          .then((res) => {
            this.sumup = res.data.data;
            return this.$http.get(`sumup/all?date=${this.dateFilter}`);
          })
          .then((res) => {
            this.sumupAll = res.data.data;
            this.totalTips = res.data.data.reduce((total, item) => {
              if (item.status == "success") return item.tip + total;
              return total;
            }, 0);
          })
          .catch(() => {
            //console.log(err);
            this.$noty.error("Error un sumup");
          });
      }
    },
  },
  computed: {
    ticketsDomicilio() {
      // count total types == 'Domicilio'
      return this.tickets.reduce((total, ticket) => {
        if (ticket.deletedAt == null) {
          if (ticket.type == "Domicilio") total += 1;
        }
        return total;
      }, 0);
    },
    ticketsWebEnvioInProducts() {
      return this.tickets.reduce((total, ticket) => {
        if (ticket.deletedAt == null) {
          ticket.products.forEach((p) => {
            //console.log(p.code)
            if (p.code.includes("ENVIO")) total += p.total;
            if (p.code.includes("REPARTO")) total += p.total;
          });
        }
        return total;
      }, 0);
    },
    ticketsFiltered() {
      let payment = this.paymetFilter.toLowerCase();
      let filter = this.ticketFilter.toLowerCase();

      if (!this.report.ticketList) return [];
      const list = this.report.ticketList.filter((t) => {
        //let rs = false;

        if (filter != "") {
          if (
            t.clientName.toLowerCase().includes(filter) ||
            t.phone.toLowerCase().includes(filter) ||
            t.address.toLowerCase().includes(filter) ||
            t.products.some((p) => p.code.toLowerCase().includes(filter)) ||
            t.products.some((p) => p.name.toLowerCase().includes(filter))
          )
            return true;
        }

        if (payment != "") {
          if (t.payment.toLowerCase() == payment) {
            return true;
          }
          if (t.payments && t.payments.length > 0) {
            return t.payments.some((p) => {
              if (p.name.toLowerCase() == payment) {
                return true;
              }
              return false;
            });
          }
          return false;
        }

        if (payment == "" && filter == "") {
          return true;
        }
      });

      if (this.limit == "") return list;
      return list.slice(0, this.limit);
    },
    totalGeneral() {
      return this.deposit + this.report.total;
    },
    chartDays() {
      let days = this.report.ticketsPerHour.map((day) => {
        return this.$moment(`${day._id}`, "HH").add(1, "hour").format("HH:mm");
      });

      return {
        series: [
          {
            name: "TOTAL",
            data: this.report.ticketsPerHour.map((day) => {
              return day.total;
            }),
          },
          {
            name: "VENTAS",
            data: this.report.ticketsPerHour.map((day) => {
              return day.items;
            }),
          },
        ],
        markers: {
          size: 1,
        },
        chart: {
          id: "ticket-per-hour",
          toolbar: {
            show: false,
          },
          stacked: true,
          type: "bar",
        },
        //colors: ["#18578d", "#f8b600"],
        xaxis: {
          categories: days,
        },
        stroke: {
          curve: "smooth",
        },
        legend: {
          position: "top",
          fontWeight: "bold",
        },
        // tooltip: {
        //   custom: function ({ series, seriesIndex, dataPointIndex }) {
        //     return (
        //       '<div class="arrow_box p-3" style="width: 200px; text-align:center">' +
        //       self.formatNumber(series[seriesIndex][dataPointIndex]) +
        //       "</span>" +
        //       "</div>"
        //     );
        //   },
        // },
      };
    },
    getScore() {
      const { report } = this;
      if (report.total == 0) return 0;
      if (report.totalPrev == 0) return 100;
      // get percent compare report.totalPrev to report.total in  100
      return (
        ((report.total - report.totalPrev) / report.totalPrev) *
        100
      ).toFixed(2);
    },
    getReportPayments() {
      const { paymentsCasher } = this.report;
      if (!paymentsCasher) return [];
      let cashers = this.cashers.filter((c) => !c.check).map((c) => c._id);
      return this.report.paymentsCasher.filter((p) => {
        if (!cashers.includes(p._id.user._id)) return p;
      });
    },
    getReportPaymentsWithoutDelivery() {
      const { paymentsCasher } = this.report;
      if (!paymentsCasher) return [];
      let cashers = this.cashers.filter((c) => !c.check).map((c) => c._id);
      return this.report.paymentsWithoutDelivery.filter((p) => {
        if (!cashers.includes(p._id.user._id)) return p;
      });
    },
    getCashers() {
      let cashers = this.cashers.map((c) => {
        if (typeof c.check == "undefined") c.check = true;
        return {
          ...c._id,
          check: c.check,
          total: c.total,
        };
      });
      return cashers;
    },
    getTotalReportCashers() {
      const payments = this.getReportPayments;
      if (!payments) return 0;
      const reducer = (total, item) => total + item.total;
      return payments.reduce(reducer, 0);
    },
    getTotalReport() {
      const payments = this.report.payments;
      if (!payments) return 0;
      const reducer = (total, item) => total + item.gTotal;
      return payments.reduce(reducer, 0);
    },
    ticketWithoutPayment() {
      let total = 0;
      this.tickets.forEach((t) => {
        // console.log({t});
        // not deleted
        if (!t.deletedAt) {
          // not payments
          if (t.payments.length == 0) {
            if (t.payment == null || t.payment == "") {
              return (total = total + t.total);
            }
          }
        }
      });
      return total;
    },
    totalNoDTE() {
      return this.tickets.reduce((c, t) => {
        if (!t.isDTE && !t.deletedAt) return c + 1;
        return c;
      }, 0);
    },
    totalDTE() {
      return this.tickets.reduce((c, t) => {
        if (t.isDTE && !t.deletedAt) return c + 1;
        return c;
      }, 0);
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    info() {
      return this.$store.state.info;
    },
    expensesTotal() {
      return this.expenses.reduce((c, t) => {
        if (t.deletedAt) return c;
        return c + t.value;
      }, 0);
    },
    totalDiscount() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.discount) return c + t.discount;
        return c;
      }, 0);
    },
    totalDiscountProducts() {
      let total = 0;
      this.tickets.forEach((t) => {
        if (t.deletedAt) return false;
        else
          t.products.forEach((p) => {
            if (p.discount) {
              total += parseInt(p.value * p.qty - p.total);
            }
          });
      });
      return total;
    },
    totalTrans() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Transferencia") return c + t.total;
        return c;
      }, 0);
    },
    totalUnknown() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Desconocido") return c + 1;
        return c;
      }, 0);
    },
    totalUnknownMount() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Desconocido") return c + t.total;
        return c;
      }, 0);
    },
    totalCashCount() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Efectivo") return c + 1;
        return c;
      }, 0);
    },
    totalTransfer() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Transferencia") return c + 1;
        return c;
      }, 0);
    },
    totalCard() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Tarjeta") return c + 1;
        return c;
      }, 0);
    },
    totalDelivery() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.type == "Domicilio") return c + 1;
        return c;
      }, 0);
    },
    totalWeb() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.webTicket) return c + 1;
        return c;
      }, 0);
    },
    totalLocal() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.type == "Retiro") return c + 1;
        return c;
      }, 0);
    },
    totalCash() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Efectivo") return c + t.total;
        return c;
      }, 0);
    },
    ticketsDeleted() {
      return this.tickets.reduce((total, ticket) => {
        if (ticket.deletedAt != null) return 1 + total;
        else return total;
      }, 0);
    },
    totalTickets() {
      if (this.tickets == []) return 0;
      else {
        return this.tickets.reduce((total, ticket) => {
          if (ticket.deletedAt == null && ticket.payment != "OT")
            return ticket.total + total;
          else return total;
        }, 0);
      }
    },
    totalItems() {
      return null;
    },
    tickets() {
      if (this.dateFilter == this.$moment().format("YYYY-MM-DD")) {
        return [
          ...this.$store.state.pending_tickets,
          ...this.$store.state.tickets,
        ];
      } else {
        return this.$store.state.tickets;
      }
    },
    sumupTotal() {
      return this.sumup.reduce((total, sumup) => {
        if (sumup.status == "FAILED") return total;
        else return total + sumup.amount;
      }, 0);
    },
  },
  mounted() {
    this.dateFilter =
      this.$route.query.date || this.$moment().format("YYYY-MM-DD");
    this.enddateFilter =
      this.$route.query.end || this.$moment().format("YYYY-MM-DD");
    this.load();
  },
  watch: {
    dateFilter() {
      //this.load();
      this.$router.push({
        query: { date: this.dateFilter, end: this.enddateFilter },
      });
    },
    enddateFilter() {
      this.$router.push({
        query: { date: this.dateFilter, end: this.enddateFilter },
      });
      //this.load();
    },
  },
};
</script>

<style scoped>
th {
  font-size: 12px;
}
</style>
