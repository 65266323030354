<template>
  <div class="container">
    <div class="row">
      <div class="col-5">
        <h3>Crear PDF XML</h3>
        <div class="w-100 py-2"></div>
        <input type="file" @change="setFile" />
        <div class="w-100 py-2"></div>
        <input type="checkbox" class="form-control d-inline w-25" v-model="cedible" /> Copia Cédible
        <button class="btn btn-primary btn-block" @click="readFile">GENERAR</button>
      </div>
      <div class="col">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      cedible: false,
    };
  },
  methods: {
    readFile() {
      const reader = new FileReader();

      reader.onload = (e) => {
        let data = e.target.result;

        let Detalles = data.match(/<Detalle>(.|\s)*?<\/Detalle>/gm);
        let FchEmis = data.match(/<FchEmis>(.*?)<\/FchEmis>/g);
        let Folio = data.match(/<Folio>(.*?)<\/Folio>/g);
        if (Folio) {
          Folio = Folio[0].replace(/<Folio>/g, "").replace(/<\/Folio>/g, "");
        }
        let TpoDoc = data.match(/<TipoDTE>(.*?)<\/TipoDTE>/g);
        if (TpoDoc) {
          TpoDoc = TpoDoc[0]
            .replace(/<TipoDTE>/g, "")
            .replace(/<\/TipoDTE>/g, "");
        }

        if (FchEmis) {
          FchEmis = FchEmis[0]
            .replace(/<FchEmis>/g, "")
            .replace(/<\/FchEmis>/g, "");
        }
        let RutRecep = data.match(/<RUTRecep>(.*?)<\/RUTRecep>/g);
        if (RutRecep) {
          RutRecep = RutRecep[0]
            .replace(/<RUTRecep>/g, "")
            .replace(/<\/RUTRecep>/g, "");
        }
        let RznSocRecep = data.match(/<RznSocRecep>(.*?)<\/RznSocRecep>/g);
        if (RznSocRecep) {
          RznSocRecep = RznSocRecep[0]
            .replace(/<RznSocRecep>/g, "")
            .replace(/<\/RznSocRecep>/g, "");
        }
        let GirosRecep = data.match(/<GiroRecep>(.*?)<\/GiroRecep>/g);
        if (GirosRecep) {
          GirosRecep = GirosRecep[0]
            .replace(/<GiroRecep>/g, "")
            .replace(/<\/GiroRecep>/g, "");
        }

        let DirRecep = data.match(/<DirRecep>(.*?)<\/DirRecep>/g);
        if (DirRecep != null) {
          DirRecep = DirRecep[0]
            .replace(/<DirRecep>/g, "")
            .replace(/<\/DirRecep>/g, "");
        }

        let CmnaRecep = data.match(/<CmnaRecep>(.*?)<\/CmnaRecep>/g);
        if (CmnaRecep) {
          CmnaRecep = CmnaRecep[0]
            .replace(/<CmnaRecep>/g, "")
            .replace(/<\/CmnaRecep>/g, "");
        }

        let MntNeto = data.match(/<MntNeto>(.*?)<\/MntNeto>/g);
        if (MntNeto) {
          MntNeto = MntNeto[0]
            .replace(/<MntNeto>/g, "")
            .replace(/<\/MntNeto>/g, "");
        }
        let MntExe = data.match(/<MntExe>(.*?)<\/MntExe>/g);
        if (MntExe) {
          MntExe = MntExe[0]
            .replace(/<MntExe>/g, "")
            .replace(/<\/MntExe>/g, "");
        }
        let MntTotal = data.match(/<MntTotal>(.*?)<\/MntTotal>/g);
        if (MntTotal) {
          MntTotal = MntTotal[0]
            .replace(/<MntTotal>/g, "")
            .replace(/<\/MntTotal>/g, "");
        }
        let IVA = data.match(/<IVA>(.*?)<\/IVA>/g);
        if (IVA) IVA = IVA[0].replace(/<IVA>/g, "").replace(/<\/IVA>/g, "");

        let TED = data.match(/<TED(.|\n)*?<\/TED>/gm);
        if (TED) TED = TED[0];

        console.log({
          Detalles,
          TED,
          FchEmis,
        });

        let products = Detalles.map((d, i) => {
          let pname = d.match(/<NmbItem>(.*?)<\/NmbItem>/g);
          if (pname)
            pname = pname[0]
              .replace(/<NmbItem>/g, "")
              .replace(/<\/NmbItem>/g, "");

          let pqty = d.match(/<QtyItem>(.*?)<\/QtyItem>/g);
          if (pqty)
            pqty = pqty[0]
              .replace(/<QtyItem>/g, "")
              .replace(/<\/QtyItem>/g, "");

          let pvalue = d.match(/<PrcItem>(.*?)<\/PrcItem>/g);
          if (pvalue)
            pvalue = pvalue[0]
              .replace(/<PrcItem>/g, "")
              .replace(/<\/PrcItem>/g, "");

          let piva = d.match(/<IndExe>(.*?)<\/IndExe>/g);
          if (piva)
            piva = piva[0].replace(/<IndExe>/g, "").replace(/<\/IndExe>/g, "");

          return {
            name: pname.replace(/&#(\d+);/g, function (match, dec) {
              return String.fromCodePoint(dec);
            }),
            code: `item-${i}`,
            value: pvalue,
            iva: piva ? 0 : 1,
            qty: pqty,
            total: pvalue * pqty,
          };
        });

        let refs = [];

        let RefsDoc = data.match(/<Referencia>(.|\n)*?<\/Referencia>/gm);
        if (RefsDoc) {
          RefsDoc.forEach((ref) => {
            refs.push({
              type: ref
                .match(/<TpoDocRef>(.*?)<\/TpoDocRef>/g)[0]
                .replace(/<TpoDocRef>/g, "")
                .replace(/<\/TpoDocRef>/g, ""),
              folio: ref
                .match(/<FolioRef>(.*?)<\/FolioRef>/g)[0]
                .replace(/<FolioRef>/g, "")
                .replace(/<\/FolioRef>/g, ""),
              date: ref
                .match(/<FchRef>(.*?)<\/FchRef>/g)[0]
                .replace(/<FchRef>/g, "")
                .replace(/<\/FchRef>/g, ""),
              reason: ref
                .match(/<RazonRef>(.*?)<\/RazonRef>/g)[0]
                .replace(/<RazonRef>/g, "")
                .replace(/<\/RazonRef>/g, ""),
            });
          });
        }

        this.printTicket({
          folio: Folio,
          dteType: TpoDoc,
          isDTE: true,
          products,
          total: MntTotal,
          createdAt: this.$moment(FchEmis),
          sub_total: MntNeto,
          exento: MntExe,
          iva: IVA,
          TED,
          cedible: this.cedible,
          clientName: 'anon',
          receptor: {
            rs: RznSocRecep,
            rut: RutRecep,
            activity: GirosRecep,
            address: `${DirRecep} - ${CmnaRecep}`,
            fmaPago: this.cedible ? 1 : 2,
          },
          refs,
        });
      };
      reader.readAsText(this.file);
    },
    setFile(e) {
      this.file = e.target.files[0];
    },
    printTicket(data) {
      console.log({ data });
      let ticket = {
        number: 1,
        createdAt: Date.now(),
        type: "Retiro",
        clientName: "",
        phone: "",
        total: 999999,
        sub_total: 999999,
        status: "Por pagar",
        products: [
          {
            name: "Producto 1",
            code: "P1",
            value: 999999,
            qty: 1,
            total: 999999,
          },
          {
            name: "Producto 2",
            code: "P2",
            value: 999999,
            qty: 1,
            discount: 100,
            total: 0,
          },
        ],
        notes: "",
        ...data,
      };
      this.$emit("printTicket", { ticket });
    },
  },
};
</script>