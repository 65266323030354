export function parseTemplate(template, data) {
  const regex = /{{(.*?)}}/g; // Regular expression to match the placeholders

  return template.replace(regex, (match, key) => {
    const keys = key.trim().split("."); // Split nested keys into an array
    let value = data;

    // Traverse the nested keys to get the corresponding value
    for (const nestedKey of keys) {
      value = value[nestedKey];
      if (value === undefined) break; // Break if any nested key is not found
    }

    return value !== undefined ? value : match; // Replace with the value or keep the placeholder if not found
  }).replace(
    /<h([1-6])/g,
    '<h$1 style="text-align:center"'
  ).replace(
    /<p class='ql-align-([a-z])'/g,
    '<p style="text-align:$1" class="'
  )

}

export function isElectron() {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
    return true;
  }

  return false;
}

export function openWhatsApp(p, message = '') {
  let phone = p.replace(/ /g, "");
  let l = ``;
  if (phone.indexOf("56") == 0) {
    l = phone;
  } else {
    l = `56${phone}`;
  }

  if (isElectron()) {
    const shell = require('electron').shell
    if (message) {
      shell.openExternal(`https://wa.me/${l}/?text=${encodeURIComponent(message)}`);
    } else {
      shell.openExternal(`https://wa.me/${l}`);
    }
  } else {
    if (message) {
      window.open(`https://wa.me/${l}/?text=${encodeURIComponent(message)}`, '_blank');
    } else {
      window.open(`https://wa.me/${l}`, '_blank');
    }
  }
}

export function log(obj) {
  // eslint-disable-next-line no-console
  return console.log(obj)
}

export function doAction(action, router) {
  // console.log(action);
  if (isElectron()) {
    const shell = require('electron').shell
    if (action.indexOf("http") == 0) {
      shell.openExternal(action);
    } else {
      router.push(action);
    }
  } else {
    if (action.indexOf("http") == 0) {
      window.open(action);
    } else {
      router.push(action);
    }
  }

}

export function getDTEList() {
  const obj = {
    'SET': 'SET',
    'QUOTE': 'Cotización',
    'PRETICKET': 'PRETICKET',
    'TICKET': 'Comprobante',
    0: 'No definido',
    30: 'Factura',
    32: 'Factura de venta bienes y servicios no afectos o exentos de IVA',
    33: 'Factura electrónica',
    34: 'Factura no afecta o exenta electrónica',
    35: 'Boleta',
    38: 'Boleta exenta',
    39: 'Boleta electrónica',
    40: 'Liquidación de factura',
    41: 'Boleta exenta electrónica',
    43: 'Liquidación de factura electrónica',
    45: 'Factura de compra',
    46: 'Factura de compra electrónica',
    48: 'Pago electrónico',
    50: 'Guía de despacho',
    52: 'Guía de despacho electrónica',
    55: 'Nota de débito',
    56: 'Nota de débito electrónica',
    60: 'Nota de crédito',
    61: 'Nota de crédito electrónica',
    103: 'Liquidación',
    110: 'Factura de exportación electrónica',
    111: 'Nota de débito de exportación electrónica',
    112: 'Nota de crédito de exportación electrónica',
    801: 'Orden de compra',
    // 802: 'Nota de pedido',
    // 803: 'Contrato',
    // 804: 'Resolución',
    // 805: 'Proceso de ChileCompra',
    // 806: 'Ficha de ChileCompra',
    // 807: 'DUS',
    // 808: 'B/L (Conocimiento de embarque)',
    // 809: 'AWB (AIR WAY BILL)',
    // 810: 'MIC/DTA',
    // 811: 'Carta de porte',
    // 812: 'Resolución del SNA donde califica Servicio de Exportación',
    // 813: 'Pasaporte',
    // 814: 'Certificado de depósito Prod. Chile',
    // 815: 'Vale de prenda Bolsa de Prod. Chile',
    // 820: 'Codigo de instrucción en el registro de acuerdos con plazo de pago excepcional',
    // 821: 'Geo Referencia predio',
    // 822: 'Rol avalúo de predio',
    // 823: 'Plan de manejo CONAF'
  }
  return obj
}

export function getDTEName(type = 0) {
  let obj = getDTEList()
  if (typeof type === 'string') type = type.replace('DTE-', '')
  return obj[type] || 'No definido'
}


export function getTransactionName(type = 0) {
  let obj = {
    1: 'Del giro',
    2: 'Supermercado',
    3: 'Bienes raíces',
    4: 'Activos fijos',
    5: 'IVA Uso Común',
    6: 'IVA No recuperable',
  }
  return obj[type] || 'No definido'
}

export function formatRUT(rut) {
  // fomart rut string to 12.345.678-9
  if (!rut) return '--.---.---.-';
  let r = rut.toString().replace(/[^0-9kK]/g, '');
  let t = r.length > 1 ? r.substring(0, r.length - 1) : '';
  let d = r.substring(r.length - 1);
  let format = '';
  while (t.length > 3) {
    format = '.' + t.substr(t.length - 3) + format;
    t = t.substring(0, t.length - 3);
  }
  return (t + format + '-' + d).toUpperCase();
}

export function clearTicket() {
  return {
    products: [],
    amount: 0,
    clientName: "",
    createdAt: null,
    address: "",
    hour: null,
    phone: "",
    type: "Retiro",
    print: true,
    notes: "",
    status: "Por pagar",
    payment: "",
    discount: 0,
    tip: 0,
    documentType: "TICKET",
    adminDate: null,
    rut: "",
    rs: "",
    giro: "",
    dir: "",
    payments: [],
    ref: [],
    fmaPago: "2",
    tpoTranVenta: 1,
    acteco: "",
  }
}

export function copyOptions(product, copy, setNewOption) {
  product.newOptions.forEach((o, i) => {
    const name = o.ref.customName || o.ref.name;

    if (!copy.options) {
      //console.log('copiando newOptions', copy.options, copy.newOptions)
      console.log('copiando opciones')
      copy.options = copy.newOptions.map((op) => {
        return {
          ...op,
          selected: op.ref.selected,
          values: op.ref.values
        }
      })
    }

    const selected = copy.options[i] //.find((op) => op.label == name);
    if (!selected) return console.log("no se encontro selected", selected, name);

    if (!selected.selected)
      return console.log("no se encontro la opcion seleccionada", selected, name);

    //console.log("set", name, selected.selected, selected);

    if (o.ref.type == "select") {
      // select multiple
      if (o.ref.multiple) {
        //console.log('multiple')
        const values = selected.values || selected.selected || []

        if (!values)
          return console.log("no se encontro la opcion m", name, values);

        values.forEach((value) => {
          console.log("multiple value", value);

          const valueSelected = o.ref.options.find((opt) => {
            return opt._id.name == value.name;
          });

          if (!valueSelected)
            return console.log("no se encontro la opcion", name, selected);
          console.log("value", valueSelected);
          setNewOption(o.ref, valueSelected._id, product);
        });
      }
      // no es multiple
      else {
        //console.log('no multiple')

        // buscamos el valor seleccionado
        let value = o.ref.options.find((opt) => {
          //console.log('opt', opt._id, selected.selected)
          if (opt._id.name == selected.selected) {
            return opt._id.name == selected.selected
          }
          if (selected.selected.name && opt._id.name == selected.selected.name) {
            return opt._id.name == selected.selected.name
          }

          if (opt._id.optionGroup && opt._id.optionGroup.options) {
            console.log('buscando en optionGroup', opt._id.optionGroup, selected.selected)
            const isIn = opt._id.optionGroup.options.find((og) => og._id.name == selected.selected)
            if (isIn) {
              console.log('encontrado', isIn)
              return isIn
            }
          }
        });

        if (!value)
          return console.log("no se encontro la opcion 2", name, selected);

        //console.log("value", value);

        if (value._id.optionGroup && value._id.optionGroup.options) {
          //console.log('buscando en optionGroup 2', value._id.optionGroup, selected.selected.name)
          const isIn = value._id.optionGroup.options.find((og) => og._id.name == selected.selected)
          if (isIn) {
            console.log('encontrado', isIn)
            value = isIn
          }
        }
        
        setNewOption(o.ref, value._id, product);
      }
    }
    // es numero
    else if (o.ref.type == "number") {
      o.ref.selected = parseInt(selected.selected);
    }
  });
}

export function pushProduct(ticket, product, qty = 1) {
  let cid = Date.now();

  if (!ticket) return console.error('Ticket is not defined')

  if (!product) return console.error('Product is not defined')

  const storages = product.storages || [];

  const [storage] = storages

  const storageId = storage ? storage.storage._id : null

  ticket.products.unshift({
    cid: cid,
    code: product.code,
    name: product.name,
    image: product.image,
    original_value: product.value,
    value: product.value,
    total: (product.value) * qty,
    discount: 0,
    unit: product.unit,
    iva: product.iva,
    editableValue: product.editableValue,
    description: product.description,
    newOptions: product.newOptions || [],
    selected_options: [],
    qty: qty,
    details: product.details,
    promos: product.promos,
    stockEnabled: product.stock,
    stockqty: product.qty,
    storages: storages,
    storage: storageId,
  });
}

export function getErrorMessage(error) {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.message) {
        return error.response.data.message
      }
    }
  }
  return `Error: ${error.message}`
}