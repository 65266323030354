<template>
  <div class="container-fluid py-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row">
          <div class="col-4 ml-auto py-2">
            <date-picker
              valueType="format"
              v-model="dateFilter"
              type="month"
              input-class="form-control pl-4"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <b-tabs
          vertical
          pills
          v-model="tab"
          active-nav-item-class=""
          nav-wrapper-class="text-uppercase font-weight-bold"
        >
          <b-tab title="Top clientes Mes">
            <ul class="list-group" v-if="loading">
              <li
                class="list-group-item click list-group-item-action"
                v-for="i in 8"
                :key="`loading-${i}`"
              >
                <div class="row">
                  <div class="col-4">
                    <small>NOMBRE</small>
                    <div class="w-100"></div>
                    <strong class="text-uppercase">
                      <b-skeleton />
                    </strong>
                  </div>
                  <div class="col">
                    <small>TELEFONO</small>
                    <div class="w-100"></div>
                    <b-skeleton />
                  </div>
                  <div class="col text-center">
                    <small>PEDIDOS</small>
                    <div class="w-100"></div>
                    <b-skeleton />
                  </div>
                  <div class="col text-center">
                    <small>PEDIDO MAX</small>
                    <div class="w-100"></div>
                    <b-skeleton />
                  </div>
                  <div class="col text-right">
                    <small>TOTAL</small>
                    <div class="w-100"></div>
                    <strong><b-skeleton /></strong>
                  </div>
                </div>
                <div class="w-100 mt-3"></div>
                <div class="col-12">
                  <div class="row mb-1">
                    <div class="col text-right">
                      <small class="font-weight-bold mr-3">
                        <b-skeleton width="20%" class="float-right" />
                      </small>
                    </div>

                    <div class="col-3 border-bottom">
                      <small class="text-uppercase">
                        <b-skeleton />
                      </small>
                    </div>
                    <div class="col text-center border-bottom">
                      <small><b-skeleton width="50%" /></small>
                    </div>
                    <div class="col border-bottom text-right">
                      <small class="font-weight-bold"><b-skeleton /></small>
                    </div>
                    <div class="col"></div>
                  </div>
                  <div class="row mb-1">
                    <div class="col text-right">
                      <small class="font-weight-bold mr-3">
                        <b-skeleton width="20%" class="float-right" />
                      </small>
                    </div>

                    <div class="col-3 border-bottom">
                      <small class="text-uppercase">
                        <b-skeleton />
                      </small>
                    </div>
                    <div class="col text-center border-bottom">
                      <small><b-skeleton width="50%" /></small>
                    </div>
                    <div class="col border-bottom text-right">
                      <small class="font-weight-bold"><b-skeleton /></small>
                    </div>
                    <div class="col"></div>
                  </div>
                  <div class="row">
                    <div class="col text-right">
                      <small class="font-weight-bold mr-3">
                        <b-skeleton width="20%" class="float-right" />
                      </small>
                    </div>

                    <div class="col-3 border-bottom">
                      <small class="text-uppercase">
                        <b-skeleton />
                      </small>
                    </div>
                    <div class="col text-center border-bottom">
                      <small><b-skeleton width="50%" /></small>
                    </div>
                    <div class="col border-bottom text-right">
                      <small class="font-weight-bold"><b-skeleton /></small>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group" v-else>
              <li class="list-group-item py-5" v-if="clients.length == 0">
                <h5 class="text-center py-5">No hay informacion</h5>
              </li>
              <li
                class="list-group-item click list-group-item-action"
                v-for="({ _id: client, ...data }, i) in clients"
                :class="{
                  active: current == client.email,
                }"
                @click="setCurrent(client, data)"
                :key="`client-${i}`"
              >
                <div class="row align-items-center">
                  <div class="col-5">
                    <div class="row align-items-center">
                      <div class="col">
                        <strong class="text-uppercase">{{
                          data.clientName
                        }}</strong>
                        <div class="w-100"></div>
                        <small class="text-muted">{{ client.email }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col text-center">
                    <small>PEDIDOS</small>
                    <div class="w-100"></div>
                    {{ data.tickets }}
                  </div>
                  <div class="col text-center">
                    <small>PEDIDO MAX</small>
                    <div class="w-100"></div>
                    <span class="badge badge-success"
                      >${{ formatNumber(data.max) }}</span
                    >
                  </div>
                  <div class="col text-right">
                    <small>TOTAL EN PEDIDOS</small>
                    <div class="w-100"></div>
                    <strong>${{ formatNumber(data.total) }}</strong>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col-12" v-if="data.data.length <= 10">
                    <div class="w-100 mt-3"></div>
                    <div
                      class="row"
                      v-for="(data, i) in data.data"
                      :key="`address${i}-${client.phone}`"
                    >
                      <div class="col text-right">
                        <small class="font-weight-bold text-uppercase mr-3">{{
                          data.createdAt | moment("ddd DD/MM")
                        }}</small>
                      </div>

                      <div class="col-5 border-bottom">
                        <small
                          class="text-uppercase"
                          v-if="data.type == 'Retiro'"
                          >{{ data.type }}</small
                        >
                        <small class="text-uppercase" v-else>{{
                          data.data
                        }}</small>
                      </div>
                      <div class="col-3 text-center border-bottom">
                        <small class="text-uppercase">{{ data.payment }}</small>
                      </div>
                      <div class="col border-bottom text-right">
                        <small class="font-weight-bold"
                          >${{ formatNumber(data.total) }}</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </b-tab>

          <b-tab title="Buscar">
            <div class="row">
              <div class="col-12">
                <small
                  >BUSCAR POR NOMBRE, CORREO, TELEFONO, DIRECCION LUEGO PRESIONE
                  ENTER</small
                >
                <div class="w-100"></div>
                <input
                  type="text"
                  class="form-control"
                  v-model="filter"
                  @keyup.enter="search()"
                />
              </div>

              <div class="col-12">
                <small>Mostrando 30 resultados</small>
                <ul class="list-group">
                  <li
                    class="list-group-item py-5"
                    v-if="filterClients.length == 0"
                  >
                    <h5 class="text-center py-5">No hay informacion</h5>
                  </li>
                  <li
                    class="list-group-item click list-group-item-action"
                    v-for="({ _id: client, ...data }, i) in filterClients"
                    :class="{
                      active: current == client.email,
                    }"
                    @click="setCurrent(client, data)"
                    :key="`client-${i}`"
                  >
                    <div class="row align-items-center">
                      <div class="col-5">
                        <div class="row align-items-center">
                          <div class="col">
                            <strong class="text-uppercase">{{
                              data.clientName
                            }}</strong>
                            <div class="w-100"></div>
                            <small class="text-muted">{{ client.email }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col text-center">
                        <small>PEDIDOS</small>
                        <div class="w-100"></div>
                        {{ data.tickets }}
                      </div>
                      <div class="col text-center">
                        <small>PEDIDO MAX</small>
                        <div class="w-100"></div>
                        <span class="badge badge-success"
                          >${{ formatNumber(data.max) }}</span
                        >
                      </div>
                      <div class="col text-right">
                        <small>TOTAL EN PEDIDOS</small>
                        <div class="w-100"></div>
                        <strong>${{ formatNumber(data.total) }}</strong>
                      </div>
                    </div>

                    <div class="row pb-2" v-if="false">
                      <div class="col-12" v-if="data.data.length <= 10">
                        <div class="w-100 mt-3"></div>
                        <div
                          class="row"
                          v-for="(data, i) in data.data"
                          :key="`address${i}-${client.phone}`"
                        >
                          <div class="col text-right">
                            <small
                              class="font-weight-bold text-uppercase mr-3"
                              >{{ data.createdAt | moment("ddd DD/MM") }}</small
                            >
                          </div>

                          <div class="col-5 border-bottom">
                            <small
                              class="text-uppercase"
                              v-if="data.type == 'Retiro'"
                              >{{ data.type }}</small
                            >
                            <small class="text-uppercase" v-else>{{
                              data.data
                            }}</small>
                          </div>
                          <div class="col-3 text-center border-bottom">
                            <small>{{ data.payment }}</small>
                          </div>
                          <div class="col border-bottom text-right">
                            <small class="font-weight-bold"
                              >${{ formatNumber(data.total) }}</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>

          <b-tab title="Comentarios">
            <div class="row mb-4">
              <div class="col">
                <item-icon
                  title="Promedio"
                  icon="star"
                  icon-class="text-warning"
                  :text="ratingsAvg"
                  />
              </div>
              <div class="col">
                <item-icon
                  title="Total comentarios"
                  icon="comment"
                  :text="ratings.length"
                  />
              </div>
            </div>
            <div class="overflow-auto custom_scroll" style="max-height: 70vh">
              <ul class="list-group">
              <li
                class="list-group-item list-group-item-action click"
                v-for="rating in ratings"
                :key="rating._id"
                :class="{
                  active: currentRating == rating._id,
                }"
                @click="showWebTicket(rating._id)"
              >
                <div class="row align-items-center no-padding">
                  <div class="col-2 text-center px-0">
                    <strong>#{{ rating.number }}</strong>
                    <div class="w-100"></div>
                    <small>{{ rating.createdAt | moment("ddd DD/MM") }}</small>
                  </div>
                  <div class="col-auto">
                    <font-awesome-icon
                      :icon="['fas', 'star']"
                      :class="{
                        'text-warning': rating.rating.note >= 4,
                        'text-success': rating.rating.note == 3,
                        'text-muted': rating.rating.note == 2,
                        'text-danger': rating.rating.note == 1,
                      }"
                    ></font-awesome-icon>
                    {{ rating.rating.note }}
                  </div>
                  <div class="col-3">
                    <strong>{{ rating.clientName }}</strong>
                    <div class="w-100"></div>
                    {{ rating.type }}
                  </div>
                  <div class="col">
                    <small>{{ rating.rating.comment }}</small>
                  </div>
                </div>
              </li>
            </ul>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <b-modal ref="modal" size="lg" :title="client.clientName">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <small>CORREO</small>
                <div class="w-100"></div>
                <strong>{{ clientid }}</strong>
              </div>
              <div class="w-100 py-1"></div>
              <div class="col">
                <small>ULTIMO TELEFONO</small>
                <div class="w-100"></div>
                <strong>{{ client.phone }}</strong>
              </div>
            </div>
          </div>

          <div class="col-12 mt-4">
            <ul class="list-group">
              <div
                class="list-group-item click list-group-item-action"
                v-for="t in tickets"
                :key="t._id"
                @click="showTicket(t)"
              >
                <div class="row align-items-center">
                  <div class="col-2 text-center">
                    <span class="font-weight-bold"
                      >{{ t.createdAt | moment("ddd DD/MM") }}
                    </span>
                    <div class="w-100"></div>
                    <small>{{ t.createdAt | moment("HH:mm") }}</small>
                  </div>
                  <div class="col text-uppercase">
                    <small class="mr-5">{{ t.clientName }}</small
                    ><small>{{ t.phone }}</small>
                    <div class="w-100"></div>
                    <div class="font-weight-bold">
                      {{ t.type }}
                      <span v-if="t.type != 'Retiro'">{{ t.address }}</span>
                    </div>
                  </div>
                  <div class="col-3 text-right font-weight-bold">
                    ${{ formatNumber(t.total) }}
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import itemIcon from '../../components/itemIcon.vue';
export default {
  components: { itemIcon },
  computed: {
    ratingsAvg() {
      let sum = 0;
      if(this.ratings.length == 0) return (0).toFixed(1)
      this.ratings.forEach((rating) => {
        sum += rating.rating.note;
      });
      return (sum / this.ratings.length).toFixed(1);
    },
  },
  data() {
    return {
      tab: 1,
      currentRating: null,
      ratings: [],
      clients: [],
      dateFilter: null,
      current: {},
      clientid: {},
      loading: true,
      client: {},
      tickets: [],
      filter: "",
      filterClients: [],
    };
  },
  mounted() {
    this.dateFilter = this.$moment().format();
    this.getRagings();

    console.log(this.$route.query)

    if(this.$route.query.search) {
      console.log('is search')
      this.filter = this.$route.query.search
      this.search()
      this.tab = 1
    }
  },
  methods: {
    showWebTicket(id) {
      this.currentRating = id;
      this.$http.get(`web/ticket/${id}`).then((res) => {
        let t = res.data.data
        if (t) this.$store.commit("setWebTicket", t);
        this.$emit("showWebTicket");
      });
    },
    getRagings() {
      this.$http.get("web/rating").then((res) => {
        this.ratings = res.data.data;
      });
    },
    search() {
      this.loading = true;
      this.$http
        .get(`web/clients?date=${this.dateFilter}&filter=${this.filter}`)
        .then((res) => (this.filterClients = res.data.data))
        .finally(() => {
          this.loading = false;
        });
    },
    loadClient() {
      this.$http
        .get(`web/client?email=${this.clientid}&date=${this.dateFilter}`)
        .then((res) => {
          this.tickets = res.data.data;
          this.$refs["modal"].show();
        });
    },
    setCurrent(client, data) {
      console.log("uwu", data);
      this.clientid = client.email;
      this.client = data;
      this.loadClient();
    },
    load() {
      this.loading = true;
      this.$http
        .get(`web/clients?date=${this.dateFilter}`)
        .then((res) => (this.clients = res.data.data))
        .finally(() => {
          this.loading = false;
        });
    },
    showTicket(t) {
      this.$store.commit("setWebTicket", t);
      this.$emit("showWebTicket");
    },
  },
  watch: {
    dateFilter() {
      this.load();
    },
  },
};
</script>