"use strict"
import { isElectron } from './utils'
let storage = {}
let ipcRenderer = {}
let webFrame, remote

if (isElectron()) {
  storage = require("electron-json-storage")
  // storage.setDataPath(os.homedir() + '/.storage')
  //console.log('fs',fs)
  //console.log('home dir', storage.getDataPath())

  // storage.get = (key, cb) => {
  //   if(cb) return cb(null, localStorage.getItem(key))
  //   return localStorage.getItem(key)
  // }
  // storage.set = (key, val, cb = null) => {
  //   if (typeof val == 'object') {
  //     localStorage.setItem(key, JSON.stringify(val))
  //   }
  //   else localStorage.setItem(key, val)
  //   if (cb && typeof cb == 'function') cb()
  // }

  // storage.getMany = (arr, cb) => {
  //   let data = {}
  //   arr.forEach(a => {
  //     data[a] = storage.get(a)
  //   })
  //   cb(null, data)
  //}

  //console.log('datapath', dataPath);

  ipcRenderer = require('electron').ipcRenderer
  console.log({ ipcRenderer })
  remote = require('electron').remote
  //remote = require('electron')
  webFrame = require('electron').webFrame
} else {
  storage.get = (key, cb) => {
    if (cb) {
      if (!localStorage.getItem(key)) return cb('No data found', null)
      return cb(null, JSON.parse(localStorage.getItem(key)))
    }
    else {
      if (!localStorage.getItem(key)) return null
      return JSON.parse(localStorage.getItem(key))
    }
  }

  storage.set = (key, val, cb = null) => {
    if (typeof val == 'object') {
      localStorage.setItem(key, JSON.stringify(val))
    }
    else localStorage.setItem(key, val)
    if (cb && typeof cb == 'function') cb()
  }

  storage.getMany = (arr, cb) => {
    let data = {}
    arr.forEach(a => {
      data[a] = storage.get(a)
    })
    cb(null, data)
  }

  ipcRenderer.send = function () {
    console.log('IpcRenderer Fake')
  }

  ipcRenderer.on = function () {
    console.log('IpcRenderer Fake')
  }
}

console.log('This is electron', isElectron())

export {
  storage,
  ipcRenderer,
  remote,
  webFrame
}