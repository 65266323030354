<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h4>RESUMEN REGISTRO DE VENTAS</h4>
        <span>
          A continuación, se muestra un resumen por tipo de documento del
          detalle de este registro.
        </span>
      </div>
      <div class="col-12 mt-4">
        <small class="font-italic"
          >Fecha de actualizacion:
          {{ Date.now() | moment("DD-MM-YY HH:mm") }}</small
        >
      </div>
    </div>

    <div class="row mt-3" v-show="!book._id">
      <div class="col-12">
        <h5>Resúmenes por tipo de documento</h5>
        <ul class="list-group">
          <li class="list-group-item list-group-item-primary font-weight-bold">
            <div class="row no-gutters align-items-center">
              <div class="col-3">
                <small>Tipo Documento</small>
              </div>
              <div class="col-1 text-center">
                <small>Total Docs</small>
              </div>
              <div class="col-2 text-right">
                <small>Total Exento</small>
              </div>
              <div class="col-2 text-right">
                <small>Monto Neto</small>
              </div>
              <div class="col-2 text-right">
                <small> IVA </small>
              </div>
              <div class="col-2 text-right">
                <small class="font-weight-bold">Monto Total</small>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="books.length == 0">
            <h4 class="text-center py-4">No tienes informacion</h4>
          </li>
          <li
            class="list-group-item list-group-item-action click"
            v-for="book in books"
            @click="loadBook(book)"
            :key="book._id"
          >
            <div class="row no-gutters">
              <div class="col-3">
                {{ getDTEName(book.docType) }} ({{ book.docType }})
              </div>
              <div class="col-1 text-center">
                {{ book.docs }}
              </div>
              <div class="col-2 text-right">
                ${{ book.exento | formatNumber }}
              </div>
              <div class="col-2 text-right">
                ${{ book.neto | formatNumber }}
              </div>
              <div class="col-2 text-right">${{ book.IVA | formatNumber }}</div>
              <div class="col-2 text-right">
                ${{ book.total | formatNumber }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-3" v-show="book._id">
      <div class="col-2">
        <button class="btn btn-primary btn-block btn-sm" @click="clearBook()">
          VOLVER
        </button>
      </div>
      <div class="col-12 mt-2">
        <h5>Registro</h5>
        <h5>
          Tipo de documento: {{ getDTEName(book.docType) }} ({{ book.docType }})
        </h5>
        <strong>Total documentos: {{ docs.length }}</strong>
      </div>
      <div class="col-12">
        <b-overlay :show="loading">
          <ul class="list-group">
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-1">XML</div>
                <div class="col-1">
                  <strong>FOLIO</strong>
                </div>
                <div class="col">
                  <strong>RUT</strong>
                </div>
                <div class="col">
                  <strong>FECHA</strong>
                </div>
                <div class="col text-right">
                  <strong>NETO</strong>
                </div>
                <div class="col text-right">
                  <strong>IVA</strong>
                </div>
                <div class="col text-right">
                  <strong>TOTAL</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item" v-for="(doc, i) in docs" :key="doc._id">
              <div class="row align-items-center">
                <div class="col-1">
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click="showXML(doc)"
                  >
                    <font-awesome-icon icon="file-download" />
                  </button>
                </div>
                <div class="col-1">
                  <strong class="click text-primary" @click="viewDocument(doc._id)">{{ doc.folio }}</strong>
                </div>
                <div class="col" v-if="doc.receptor">
                  <small :id="`rut-${i}`" class="click text-primary">{{
                    doc.receptor && doc.receptor.rut | formatRut
                  }}</small>
                  <div class="w-100"></div>
                  <small>{{
                    (doc.receptor && doc.receptor.rs) || "No encontrado"
                  }}</small>
                  <!-- <b-tooltip
                    :target="`rut-${i}`"
                    placement="bottom"
                  > -->
                  <!-- </b-tooltip> -->
                </div>
                <div class="col" v-else>No rut</div>
                <div class="col">
                  {{ doc.createdAt | moment("DD/MM/YYYY") }}
                </div>
                <div class="col text-right">${{ doc.neto | formatNumber }}</div>
                <div class="col text-right">${{ doc.iva | formatNumber }}</div>
                <div class="col text-right">
                  <strong> ${{ doc.total | formatNumber }}</strong>
                </div>
              </div>
            </li>
          </ul>
        </b-overlay>
      </div>
    </div>

    <b-modal ref="modal-xml" title="XML" size="lg">
      <div class="row">
        <div class="col-12 mb-4">
          <button class="btn btn-primary btn-block" @click="downloadXML()">
            Descargar XML
          </button>
        </div>
        <div class="col-12">
          <pre class="text-danger">{{ xml }}</pre>
        </div>
      </div>
    </b-modal>

    <b-modal ref="viewDocument" size="xl">
      <div class="row">
        <div class="col-4 ml-auto d-none">
          <button @click="getEmisorData()">Actualizar datos emisor</button>
        </div>
        <div class="w-100"></div>
        <div class="col">
          <strong>RUT EMISOR:</strong> {{ document.rutEmisor | formatRut }}
          <div class="w-100"></div>
          <strong>RAZON SOCIAL:</strong> {{ document.rznSoc }}
          <div class="w-100"></div>
          <strong>GIRO:</strong> {{ document.giroEmis }}
          <div class="w-100"></div>
          <strong>DIRECCION:</strong> {{ document.dirOrigen }}
          <div class="w-100"></div>
          <strong>FECHA:</strong> {{ document.fchEmis }}
        </div>

        <div class="col-5 text-center">
          <div
            class="border border-dark py-3 text-uppercase"
            style="border-width: 3px !important"
          >
            <strong
              >{{ getDTEName(document.dteType) }} ({{
                document.dteType
              }})</strong
            >
            <br />
            <strong>N°</strong> {{ document.folio }}
          </div>
          S.I.I {{ document.ciudadOrigen }}
        </div>

        <div class="w-100 py-2"></div>

        <div class="col">
          <h5>Detalles</h5>

          <div class="w-100"></div>

          <ul class="list-group">
            <li
              class="list-group-item list-group-item-action"
              v-for="d in document.detalles"
              :key="d.nroLinDet"
            >
              <div class="row align-items-center">
                <div class="col-1 text-center">
                  {{ d.qtyItem }}
                </div>
                <div class="col font-weight-bold">
                  {{ d.nmbItem | decode }}
                </div>
                <div class="ml-auto col-2 text-right">
                  ${{ d.prcItem | formatNumber }}
                </div>
                <div class="ml-auto col-2 text-right">
                  <strong>${{ d.montoItem | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-2 text-right ml-auto">
                  <strong>NETO</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntNeto | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row" v-if="document.mntExe">
                <div class="col-2 text-right ml-auto">
                  <strong>EXENTO</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntExe | formatNumber }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-2 text-right ml-auto">
                  <strong>IVA (19%)</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntIva | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-2 text-right ml-auto">
                  <strong>TOTAL</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntTotal | formatNumber }}</strong>
                </div>
              </div>
            </li>
          </ul>

          <div class="col-4 mt-3 text-center">
             <img
            
            :src="`https://api.ticketapp.cl/ted?bcid=pdf417&text=${TEDURL}&height=45`"
            alt="TED"
            style="width: 100%; margin: 0 auto;display:block"
          />
          <small class="text-center">TIMBRE ELECTRONICO SII</small>
          </div>
        </div>
        <div class="col-12 my-4 overflow-scroll" v-if="document.xml">
          <b-button v-b-toggle.collapse-3 block variant="primary" class="m-1"
            >VER XML</b-button
          >
          <b-collapse id="collapse-3">
            <pre class="text-secondary border p-2 bg-light">{{
              document.xml
            }}</pre>
          </b-collapse>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getDTEName } from "../../utils";
export default {
  name: "",
  data() {
    return {
      document: {},
      loading: true,
      book: {
        docs: [],
      },
      docs: [],
      xml: '',
      books: [
        // {
        //   _id: 1,
        //   docType: 39,
        //   docs: [1, 2, 3, 4, 5, 6],
        //   exento: 0,
        //   neto: 3831085,
        //   IVA: 727910,
        //   IVARec: 0,
        //   IVAComun: 0,
        //   total: 4565271,
        // },
        // {
        //   _id: 1,
        //   docType: 33,
        //   docs: [1, 2, 3],
        //   exento: 0,
        //   neto: 383108,
        //   IVA: 72791,
        //   IVARec: 0,
        //   IVAComun: 0,
        //   total: 456527,
        // },
      ],
    };
  },
  mounted() {
    //this.load();
  },
  computed: {},
  methods: {
     viewDocument(id) {
      this.loading = true;
      this.$http
        .get(`ticket/${id}`)
        .then((res) => {
          this.$store.commit('setTicket', res.data.data)
          this.$emit('showTicket', res.data.data)
          this.document = res.data.data;
          console.log("🚀 ~ file: ventas.vue:203 ~ showXML ~ doc:", this.document)
          //this.$refs.viewDocument.show()
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadXML() {
      let doc = this.xml;
      let blob = new Blob([doc], { type: "text/xml" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "xml.xml";
      link.click();
    },
    showXML(doc){
      console.log("🚀 ~ file: ventas.vue:203 ~ showXML ~ doc:", doc)
      
      this.xml = doc.DTE;
      this.$refs['modal-xml'].show()
    },
    clearBook() {
      this.book = {
        docs: [],
      };
    },
    loadBook(book) {
      let date = this.$route.query.date;
      this.loading = true;
      this.book = book;
      this.docs = [];
      this.$http
        .get(`sii/list/${book.docType}?date=${date}`)
        .then((res) => {
          this.docs = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      let date = this.$route.query.date;
      this.$http.get(`sii/list?date=${date}`).then((res) => {
        console.log(res);
        this.books = res.data.data;
      });
    },
    loadDocs() {
      this.$http
        .get(`sii/list/type?=dteType=${this.book.docType}`)
        .then((res) => {
          console.log(res);
          this.docs = res.data.data;
        });
    },
    getDTEName,
  },
};
</script>