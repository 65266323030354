<template>
  <div class="container-fluid">
    <b-overlay :show="loading">
      <b-tabs
        fill
        nav-class="font-weight-bold text-uppercase"
        @input="changeTab()"
      >
        <b-tab title="Crear">
          <ot-new @showOT="showOT" @printOT="printOT" />
        </b-tab>
        <b-tab title="Buscar">
          <ot-search @showOT="showOT" search @printOT="printOT" />
        </b-tab>
        <b-tab title="Inicio" active tabID="0">
          <ot-list @showOT="showOT" @printOT="printOT" />
        </b-tab>
        <b-tab title="Pendientes">
          <ot-list
            @showOT="showOT"
            @printOT="printOT"
            status="pendiente"
            showfilter="false"
            tabID="1"
          />
        </b-tab>
        <b-tab title="Finalizadas">
          <ot-list
            @showOT="showOT"
            @printOT="printOT"
            status="finalizada"
            tabID="2"
          />
        </b-tab>
        <b-tab title="Calendario" tabID="3">
          <ot-calendar @showOT="showOT" @printOT="printOT" />
        </b-tab>
      </b-tabs>
      <ot-modal ref="modalOT" @printOT="printOT" />
    </b-overlay>
  </div>
</template>
<script>
import otCalendar from "../components/ot-calendar";
import otList from "../components/ot-list";
import otNew from "./OtNew.vue";
import otSearch from "../components/ot-search";
import otModal from "../components/ot-modal.vue";

export default {
  components: {
    otCalendar,
    otList,
    otNew,
    otSearch,
    otModal,
  },
  data() {
    return {
      tabID: 0,
      loading: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    showOT(id = this.ot._id) {
      this.loading = true;
      this.otId = id;

      this.$refs.modalOT.view(id, () => {
        this.loading = false;
      });
     // this.loading = false;
    },
    printOT(data) {
      console.log({ printOT: data });
      this.$store.commit("setOT", data.ot);
      this.$emit("printOT", data);
    },
    changeTab() {},
  },
};
</script>

<style scoped>
</style>