var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-4"},[_c('h3',[_vm._v("ARCHIVOS "+_vm._s(_vm.files.length))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":_vm.load}},[_vm._v("\n            ACTUALIZAR\n          ")])])]),_vm._l((_vm.folders),function(folder){return _c('div',{key:folder._id},[_c('div',{staticClass:"row mt-3 px-1"},[_c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Carpeta: "+_vm._s(folder._id))])]),_vm._l((folder.files),function(file,index){return _c('div',{key:index,staticClass:"col-3 bgwhite border px-1"},[_c('div',{staticClass:"row px-2 py-2 overflow-hidden h-100"},[_c('div',{staticClass:"col-12"},[_c('div',{style:({
                    width: '100%',
                    height: '280px',
                    'background-image': ("url(https://static.ticketapp.cl/uploads/meta/" + (file.small) + ")"),
                    'background-size': 'contain',
                    'background-position': 'center',
                    'background-repeat': 'no-repeat',
                    'z-index': '0',
                  })})]),_c('div',{staticClass:"col"},[_c('strong',{staticClass:"w-100 d-block text-wrap"},[_vm._v(_vm._s(file.originalName)+"\n                ")]),_c('small',[_vm._v(_vm._s(_vm._f("moment")(file.createdAt,"DD-MM-YYYY"))+" -\n                  "+_vm._s(_vm.sizeInMb(file.size))+" MB !")]),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"row"},[(!_vm.modal)?_c('div',{staticClass:"col-12 mb-2"},[_c('small',[_vm._v("CARPETA - "+_vm._s(file.folder))]),(!file.other)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(file.folder),expression:"file.folder"}],staticClass:"form-control form-control-sm",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(file, "folder", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.updateFile(file)}]}},[_vm._l((_vm.folders),function(folder){return _c('option',{key:folder._id},[_vm._v("\n                        "+_vm._s(folder._id)+"\n                      ")])}),(_vm.other)?_c('option',{domProps:{"value":_vm.other}},[_vm._v(_vm._s(_vm.other))]):_vm._e()],2):_vm._e(),_c('small',{staticClass:"click d-block",on:{"click":function($event){file.other = true}}},[_vm._v("Crear nueva carpeta")]),(file.other)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(file.folder),expression:"file.folder"}],staticClass:"form-control",attrs:{"placeholder":"Nueva carpeta"},domProps:{"value":(file.folder)},on:{"change":function($event){return _vm.updateFile(file)},"input":function($event){if($event.target.composing){ return; }_vm.$set(file, "folder", $event.target.value)}}}):_vm._e()]):_vm._e(),(!_vm.modal)?_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-sm btn-primary btn-block",on:{"click":function($event){return _vm.copyClipboard(
                          ("https://static.ticketapp.cl/uploads/meta/" + (file.name))
                        )}}},[_vm._v("\n                      COPIAR link\n                    ")])]):_vm._e(),(_vm.modal)?_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-sm btn-primary btn-block",on:{"click":function($event){return _vm.selected(
                          ("https://static.ticketapp.cl/uploads/meta/" + (file.name))
                        )}}},[_vm._v("\n                      USAR\n                    ")])]):_vm._e(),(!_vm.modal)?_c('div',{staticClass:"col-6"},[_c('b-button',{attrs:{"block":"","variant":"danger","size":"sm"},on:{"click":function($event){return _vm.remove(file)}}},[_vm._v("Delete")])],1):_vm._e()])])])])})],2)])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }