<template>
  <div style="font-family: sans-serif">
    <div
      v-if="ticket.isDTE && printMode != 3 && ticket.dteType != 39"
      style="
        text-align: center;
        margin-bottom: 5px;
        float: right;
        max-width: 220px;
        padding-left: 10px;
        width: 100%;
      "
    >
      <div style="border: 1px solid black; margin: 0px 0; padding: 3px 5px">
        <small style="display: block" v-if="businessInfo.rut">
          RUT: {{ businessInfo.rut | formatRut }}
        </small>
        <span
          style="font-weight: bold"
          >{{ getDTEName(ticket.dteType) }} <br />
          N°{{ ticket.folio }}</span
        >
      </div>
      <small v-if="ticket.dteType != 'QUOTE'">S.I.I. {{ businessInfo.city | uppercase }}</small>
    </div>
    <div
      v-if="businessInfo"
    >
      <header-logo
        :src="logo"
        v-if="logo && businessInfo.printLogo && printMode != 3"
        style="max-width: 180px"
      />
      <div v-if="!hideInfo">
        <strong
          style="font-family: sans-serif; display: block; margin-bottom: 5px"
        >
          {{ businessInfo.custom_name }}
        </strong>
        <strong
          style="font-family: sans-serif"
        >
          {{ businessInfo.name }}
          <small style="display: block" v-if="businessInfo.rut">
            RUT: {{ businessInfo.rut | formatRut }}
          </small>
        </strong>
        <small style="display: block" v-if="businessInfo.giro">{{
          businessInfo.giro
        }}</small>

        <small
          style="display: block; margin-top: 5px"
          v-if="businessInfo && !hideInfo"
        >
          <strong>
            <div>
              DIRECCION: {{ businessInfo.address | uppercase }}
              {{ businessInfo.commune | uppercase }}
            </div>
            <div v-if="businessInfo.phone">
              TELEFONO: {{ businessInfo.phone }}
            </div>
            <div v-if="businessInfo.custom_header">
              <div style="white-space: pre-line">
                {{ businessInfo.custom_header | uppercase }}
              </div>
            </div>
          </strong>
        </small>
      </div>
      <small v-if="!ticket.isDTE"  style="margin-right: 5px">
        COMPROBANTE DE VENTA N°{{
          ticket.number ? ticket.number : "(Modo offline)"
        }}
      </small>
      <small>FECHA: {{ticket.createdAt | moment('DD/MM/YYYY HH:mm')}}</small>
       <div
          style="font-family: sans-serif"
        >
          <small>
            ATENDIDO POR:
            {{
              ticket.user_sell
                ? ticket.user_sell.name
                : "Administrador" | uppercase
            }}
          </small>
          <small v-if="ticket.webTicket" style="display:block">
           <strong style="margin: 0px 0px">PEDIDO WEB</strong>
          </small>
        </div>

      <small v-if="ticket.isDTE" style="font-weight: bold">
        {{ getDTEName(ticket.dteType) }} N°{{ ticket.folio }}
      </small>
      <div
        v-if="ticket.receptor && printMode == 1"
        style="
          font-weight: bold;
          border: 1px solid #cacaca;
          margin-top: 5px;
          padding: 10px;
        "
      >
        <div>
          <small
            ><strong>{{ ticket.receptor.rs }}</strong></small
          >
        </div>
        <div>
          <small
            ><strong>RUT:</strong> {{ ticket.receptor.rut || '0000000-0' | formatRut }}</small
          >
        </div>
        <div v-if="ticket.receptor.phone">
          <small
            ><strong>Telefono:</strong> {{ ticket.receptor.phone }}</small
          >
        </div>
        <div>
          <small
            ><strong>DIRECCION:</strong> {{ ticket.receptor.address }}
            {{ ticket.receptor.commune }}</small
          >
        </div>
        <div>
          <small><strong>GIRO:</strong> {{ ticket.receptor.activity }}</small>
        </div>
      </div>
      <div
        v-if="ticket.isDTE && ticket.refs && ticket.refs.length > 0"
        style="
          font-weight: bold;
          border: 1px solid #cacaca;
          margin-top: 5px;
          padding: 10px;
        "
      >
        <div v-for="(ref, i) in ticket.refs" :key="`ref-${i}`">
          <small class="mr-2"><strong>Referencia: </strong></small>
          <small>({{ ref.type }}) {{ getDTEName(ref.type) }}  #{{ ref.folio }} </small> <small class="mx-3">Fecha: {{ ref.date }}</small>
          <div></div>
          <small>{{ref.reason}}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDTEName } from "../../utils";
import headerLogo from "./header-logo.vue";
export default {
  components: { headerLogo },
  computed: {
    logo() {
      return localStorage.logo || "";
    },
  },
  props: ["ticket", "businessInfo", "fontSize", "hideInfo", "printMode"],
  methods: {
    getDTEName,
  },
};
</script>