<template>
  <div>
    <div class="fixed-top p-4">
      <button
        class="btn btn-info btn-lg"
        ref="usersButton"
        id="usersButton"
        @click="showSidebarAndBlur()"
      >
        <font-awesome-icon icon="users"></font-awesome-icon>
      </button>
      <b-tooltip target="usersButton" show>Sesiones recientes</b-tooltip>
    </div>
    <b-sidebar backdrop shadow width="50%" left v-model="showSidebar">
      <div class="row no-gutters" style="overflow-x: hidden">
        <div
          class="col-12 align-self-center"
          id="session"
          v-if="sessions.length > 0"
        >
          <small class="text-muted text-uppercase d-block mb-2">
            Sesiones recientes ({{ sessions.length }})
          </small>
          <ul class="list-group" id="session-content1">
            <li class="list-group-item fixed-top">
              <div class="row">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    ref="emailFilterInput"
                    v-model.trim="emailFilter"
                    @keyup.enter="setFirstSession()"
                    placeholder="Buscar..."
                  />
                </div>
              </div>
            </li>
            <li
              class="list-group-item list-group-item-action click"
              v-for="s in sessionList"
              :key="`session-${s.email}`"
              @click="setEmail(s.email, s)"
              :class="{
                active:
                  email == s.email ||
                  (emailFilter.length > 0 && sessionList.length == 1),
              }"
            >
              <div class="row align-items-center">
                <div class="col-3 text-center">
                  <font-awesome-icon
                    icon="user-circle"
                    size="4x"
                    v-if="!s.logo"
                  />
                  <img
                    :src="getLogo(s.logo)"
                    alt="logo"
                    class="img-fluid"
                    style="max-width: 80px"
                    v-else
                  />
                </div>
                <div class="col-7">
                  <strong class="d-block text-uppercase">{{ s.name }}</strong>
                  <small class="text-uppercase">{{ s.email }}</small>
                </div>
                <div class="col-2">
                  <button class="btn" @click="remove(s.email)">
                    <font-awesome-icon
                      icon="times"
                      class="text-danger"
                    ></font-awesome-icon>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>
    <div class="container">
      <div class="row h-100 justify-content-center" id="login">
        <div
          class="col-10 col-md-8 col-lg-5 align-self-center bgwhite p-5 border shadow-sm"
        >
          <div class="row justify-content-center py-2">
            <div class="col text-center pb-3">
              <font-awesome-icon
                icon="user-circle"
                size="5x"
                v-if="!session.logo"
              />
              <img
                :src="getLogo(session.logo)"
                alt="logo"
                class="img-fluid"
                style="max-width: 250px; max-height: 150px"
                v-else
              />
            </div>
            <div class="w-100"></div>
            <div class="col">
              <h4 class="text-center d-none">INGRESAR</h4>

              <div class="w-100 border-bottom"></div>

              <div class="alert alert-danger mt-2" role="alert" v-if="error">
                <font-awesome-icon icon="times" class="font-weight-bold" />
                Usuario o contraseña incorrecto
              </div>

              <small
                for=""
                class="mt-3 mb-2 d-inline-block font-weight-bold text-uppercase"
              >
                Correo
              </small>

              <div class="input-group">
                <input
                  type="text"
                  ref="emailInput"
                  class="form-control text-muted"
                  autocapitalize="off"
                  placeholder="correo@dominio.cl"
                  v-model="email"
                  autocomplete="on"
                  :disabled="loading"
                  v-focus
                  @keyup.enter="login()"
                  :class="{
                    'is-invalid': email.indexOf('@') == -1,
                    'is-valid': email.indexOf('@') != -1,
                  }"
                />
                <button
                  class="btn btn-outline-danger d-none"
                  @click="email = ''"
                >
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
              </div>

              <small
                for=""
                class="mt-3 mb-2 d-block font-weight-bold text-uppercase"
              >
                Contraseña
              </small>
              <div class="alert alert-warning my-2" v-if="caps">
                <font-awesome-icon icon="exclamation-circle" class="mr-1" />
                <strong>BLOQ MAYUS ACTIVADO</strong>
              </div>
              <div class="input-group">
                <input
                  ref="passwordInput"
                  :type="type"
                  class="form-control"
                  placeholder="*******"
                  v-model="password"
                  :disabled="loading"
                  :class="{
                    'is-invalid': password.length < 3,
                    'is-valid': password.length > 3,
                  }"
                  @keyup="checkUppercase"
                  @keyup.enter="login()"
                />
                <button class="btn btn-outline-info" @click="toggleType()">
                  <font-awesome-icon icon="eye"></font-awesome-icon>
                </button>
              </div>

              <div class="row mt-4 no-gutters">
                <div class="col">
                  <button
                    class="btn btn-success btn-block"
                    :disabled="loading"
                    :class="{
                      disabled: loading || email == '' || password == '',
                    }"
                    @click="login()"
                  >
                    {{ loading ? "Cargando..." : "INGRESAR" }}
                  </button>
                </div>
                <div class="col-auto">
                  <b-button
                    :disabled="loading"
                    v-b-toggle.collapse-1
                    variant="success"
                    class="btn-block"
                  >
                    <font-awesome-icon icon="cog" />
                  </b-button>
                </div>
              </div>

              <router-link
                to="/recover"
                v-if="false"
                class="btn btn-link btn-block"
              >
                Olvide mi contraseña
              </router-link>

              <b-collapse id="collapse-1" class="mt-2">
                <div class="border bg-light py-4 px-2">
                  <b-form-checkbox class="" v-model="remember_me">
                    Recordarme
                  </b-form-checkbox>
                </div>
                <div class="py-2" v-show="remember_me">
                  <small>DURACION SESION</small>
                  <select class="form-control" v-model="sessionTime">
                    <option value="1">24 horas</option>
                    <option value="7">1 semana (Default)</option>
                    <option value="30">1 mes</option>
                    <option value="365">1 año</option>
                  </select>
                </div>
              </b-collapse>

              <div class="w-100 py-2"></div>

              <div
                class="text-center text-uppercase click"
                @click="doAction('https://ticketapp.cl')"
              >
                <small>www.ticketapp.cl</small>
              </div>

              <div class="w-100 py-2"></div>

              <button
                class="btn btn-link btn-block"
                @click="
                  doAction(
                    'https://api.whatsapp.com/send?phone=56986470414&text='
                  )
                "
              >
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  focusable="false"
                  role="img"
                  aria-label="whatsapp"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="b-icon mr-1 bi"
                >
                  <g>
                    <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                    ></path>
                  </g>
                </svg>
                Soporte via WhatsApp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from "../multi-utils";

export default {
  name: "Login",
  data() {
    return {
      sessionTime: 1,
      show: false,
      loading: false,
      email: "",
      password: "",
      remember_me: false,
      error: false,
      sessions: [],
      emailFilter: "",
      type: "password",
      showSidebar: false,
      session: {},
      caps: false,
    };
  },
  computed: {
    sessionList() {
      if (this.emailFilter.length > 0) {
        let filter = this.emailFilter.trim();
        let sessions = this.sessions.filter((s) => {
          if (s.name.toLowerCase().indexOf(filter) != -1) return s;
          if (s.email.indexOf(filter) != -1) return s;
        });
        return sessions;
      } else return this.sessions;
    },
  },
  mounted() {
    // if (localStorage.sessions && false) {
    //   console.log("session from localstorage");
    //   this.sessions = JSON.parse(localStorage.sessions);
    // } else {
    storage.get("sessions", (err, val) => {
      if(err) return console.log('error session', err);
      console.log({ val });
      this.sessions = val;
    });

    if (localStorage.session) {
      this.session = JSON.parse(localStorage.session);
      this.setEmail(this.session.email, this.session);
    } else this.$refs.emailInput && this.$refs.emailInput.focus();
    // storage.get("email", (err, val) => {
    //   if (val) {
    //     this.email = val;
    //     this.$refs.passwordInput && this.$refs.passwordInput.focus();
    //   } else {
    //     this.$refs.emailInput && this.$refs.emailInput.focus();
    //   }
    // });

    this.show = true;
  },
  methods: {
    checkUppercase(event) {
      var caps = event.getModifierState && event.getModifierState("CapsLock");
      this.caps = caps;
      console.log({ event, caps });
    },
    setFirstSession() {
      console.log("set first");
      if (this.sessionList && this.sessionList.length == 1) {
        let firstSession = this.sessionList[0];
        firstSession && this.setEmail(firstSession.email, firstSession);
      }
    },
    showSidebarAndBlur() {
      this.showSidebar = true;
      this.$refs["usersButton"].blur();
      setTimeout(() => {
        this.$refs["emailFilterInput"].focus();
      }, 500);
    },
    setEmail(email, session) {
      this.session = session;
      this.showSidebar = false;
      this.emailFilter = "";
      this.email = email;
      this.password = "";
      this.$refs.passwordInput && this.$refs.passwordInput.focus();
    },
    toggleType() {
      if (this.type == "password") this.type = "text";
      else this.type = "password";
    },
    remove(email) {
      let sessions = this.sessions;
      this.sessions = sessions.filter((s) => s.email != email);
      this.email = "";
      storage.set("sessions", this.sessions);
    },
    login() {
      this.error = false;
      this.loading = true;
      this.type = "password";
      let info = {
        // cpus: os.cpus(),
        // freemem: os.freemem(),
        // totalmem: os.totalmem(),
      };
      // get name of cpu
      const data = {
        email: this.email.toLowerCase().replace(/ /g, ""),
        password: this.password,
        info: info,
        session_time: this.sessionTime,
      };
      setTimeout(() => {
        this.$http
          .post("auth/login", data)
          .then((res) => {
            localStorage.token = res.data.token;
            localStorage.email = this.email;
            this.$store.commit("login", true);

            storage.set("email", this.email, () => {
              this.$router.push("/loading");
            });

            // return Promise.all([
            //   this.$http.get("me", { headers: { token: res.data.token } }),
            //   this.$http.get("info", { headers: { token: res.data.token } }),
            //   this.$http.get("product", { headers: { token: res.data.token } }),
            //   this.$http.get("ticket", { headers: { token: res.data.token } }),
            //   this.$http.get("product/featured", {
            //     headers: { token: res.data.token },
            //   }),
            // ]);
          })
          .catch((err) => {
            console.log({ errorLogin: err });
            if (err.status == 401) {
              this.$noty.error("El negocio se encuentra desabilitado.");
            } else if (err.status == 403) {
              this.$noty.error("El usuario se encuentra desabilitado.");
            } else {
              this.$noty.error("Error al iniciar sesion");
              this.error = true;
            }
          })
          .finally(() => {
            this.loading = false;
            setTimeout(() => {
              this.$refs["passwordInput"].focus();
            }, 500);
          });
      }, 1000);
    },
  },
  watch: {
    session() {
      localStorage.session = JSON.stringify(this.session);
    },
    sessions() {
     //localStorage.sessions = JSON.stringify(this.sessions);
    },
  },
};
</script>

<style>
#login {
  margin-top: auto;
  margin-bottom: auto;
  min-height: 80vh;
}
#session-content {
  max-height: 60vh;
  overflow-y: scroll;
}
#sessions {
  background: linear-gradient(120deg, #290447 20%, #3f0c60 35%, #7f25a9 100%);
}
</style>
