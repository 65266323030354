<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-12 col-md-2">
        <button class="btn btn-primary btn-block" @click="back">Volver</button>
      </div>
      <div class="col">
        <span class="fs-4">{{ table.name }}</span>
      </div>
    </div>
    <b-overlay :show="loading">
      <div class="row" v-if="table.status == 'available'">
        <div class="col-6 offset-3">
          <div class="row no-gutters">
            <div class="col-12">
              <small>Cantidad de personas</small>
              <b-form-spinbutton v-model="people" size="lg" class="py-2" />
            </div>
            <div class="col-12">
              <button
                class="btn btn-success btn-lg btn-block py-3"
                @click="openTable(table)"
              >
                ABRIR MESA COMO {{ user.name }}
                <div class="w-100"></div>
              </button>
            </div>
            <div class="col-12 mt-5" v-if="config.reserveEnabled">
              <button
                class="btn btn-secondary btn-lg btn-block py-3"
                @click="reserve(table)"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="mr-2"
                ></font-awesome-icon>
                RESERVAR
                <div class="w-100"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-end px-xs-0" v-else>
        <div class="col-12">
          <div class="row no-gutters">
            <div
              class="col-md-6 pb-2 pb-md-0"
              v-if="hasPermission(user, 'ticket_tpv')"
            >
              <button
                class="btn btn-success btn-lg btn-block"
                @click="closeTable()"
              >
                PAGAR MESA
                <small class="d-block">GENERAR VENTA Y CERRAR</small>
              </button>
            </div>

            <div class="col-6 col-md">
              <div class="btn-group btn-block">
                <button
                  class="btn btn-info h-100 btn-lg"
                  @click="printLocal(table)"
                >
                  <font-awesome-icon icon="print"></font-awesome-icon>
                  <small class="d-block">ESTE DISPOSITIVO</small>
                </button>
                <button
                  class="btn btn-secondary h-100 btn-lg"
                  @click="print(table)"
                  v-if="info.remotePrintEnabled"
                >
                  <font-awesome-icon icon="print"></font-awesome-icon>
                  <small class="d-block">REMOTO</small>
                </button>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <button
                class="btn btn-primary h-100 btn-lg btn-block"
                @click="showProducts()"
              >
                <font-awesome-icon icon="plus"></font-awesome-icon>
                <small class="d-block">AGREGAR</small>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 py-2"></div>
        <div class="col">
          <b-tabs justified>
            <b-tab :title="`Productos (${table.products.length})`">
              <div class="">
                <div class="row overflow-auto" style="max-height: 60vh">
                  <div class="col-12" v-if="table.products.length == 0">
                    <h4 class="text-center pt-5">No tienes productos</h4>
                    <span class="d-block text-center"
                      >Agrega productos en + para comenzar</span
                    >
                  </div>
                  <div
                    class="col-12"
                    v-for="(product, i) in table.products"
                    :key="`product-${product.code}-${i}`"
                  >
                    <div
                      :class="{
                        bglight: product.readyAt,
                        'bg-yellow': !product.readyAt,
                      }"
                      class="border p-3 rounded click position-relative shadow-sm"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-2 col-md-1">
                              <input
                                type="checkbox"
                                class="form-control"
                                v-model="product.selected"
                              />
                            </div>
                            <div class="col">
                              <strong class="font-weight-bold text-uppercase"
                                >{{ product.qty }} x {{ product.name }}
                              </strong>
                              <strong>
                                ${{ product.value | formatNumber }}</strong
                              >
                              <div class="w-100"></div>
                              <font-awesome-icon
                                icon="clock"
                                class="text-info"
                              ></font-awesome-icon>
                              <small class="text-uppercase mx-2">{{
                                product.createdAt || "--:--" | moment("HH:mm")
                              }}</small>
                              <small
                                class="text-uppercase font-italic mr-4 d-none d-md-inline-block"
                                v-if="product.createdAt"
                                >({{
                                  product.createdAt || "" | moment("from")
                                }})</small
                              >

                              <font-awesome-icon
                                icon="check-circle"
                                class="text-success"
                              ></font-awesome-icon>
                              <small class="text-uppercase mx-2">{{
                                product.readyAt || "--:--" | moment("HH:mm")
                              }}</small>
                              <small
                                class="text-uppercase font-italic d-none d-md-inline-block"
                                v-if="product.readyAt"
                                >({{
                                  product.readyAt || "" | moment("from")
                                }})</small
                              >
                            </div>
                            <div class="col-12"></div>
                          </div>
                        </div>
                        <div class="col-3 col-md-1 text-center d-none">
                          <!-- <b-checkbox
                                v-model="product.selected"
                                size="lg"
                              /> -->

                          <div
                            class="col-7 col-md-2 d-none1"
                            v-if="!product.readyAt"
                          >
                            <button
                              class="btn btn-danger btn-block d-none"
                              :disabled="product.readyAt"
                              @click="removeProduct(product, i)"
                            >
                              <font-awesome-icon icon="trash" />
                            </button>
                            <div class="w-100 py-0"></div>
                          </div>
                          <!--                       
                      <b-form-spinbutton
                        size="sm"
                        v-model="product.qty"
                        :disabled="product.readyAt"
                      ></b-form-spinbutton> -->
                        </div>
                        <div class="col">
                          <div class="w-100"></div>

                          <div class="row no-gutters">
                            <div
                              class="col-12 p-0"
                              v-for="op in product.options"
                              :key="`${product._id}-${op._id}`"
                            >
                              <div v-if="op.label" class="text-uppercase">
                                <small class="font-weight-bold mr-2">{{
                                  op.label
                                }}</small
                                ><small>{{ getSelected(op) }}</small>
                              </div>
                            </div>
                          </div>

                          <b-button
                            v-b-toggle="`collapse-${product._id}-inner`"
                            size="sm"
                            variant="outline-primary"
                            class="ml-0 w-90"
                            v-if="
                              product.newOptions &&
                              product.newOptions.length > 0
                            "
                          >
                            Mostrar/Ocultar opciones
                          </b-button>
                          <b-collapse
                            visible
                            :id="`collapse-${product._id}-inner`"
                            class="mt-2"
                          >
                            <div class="mt-2 px-2" v-if="product.newOptions">
                              <div
                                class="col-12 align-items-center py-1 no-gutters"
                                v-for="(
                                  { ref: option, custom_label }, i
                                ) in product.newOptions"
                                :key="`p-${i}`"
                              >
                                <product-option
                                  @remove="removeOption"
                                  :product="product"
                                  :index="i"
                                  :customLabel="custom_label"
                                  :option="option"
                                >
                                  {{ custom_label || option.label }}
                                </product-option>
                              </div>
                            </div>
                          </b-collapse>

                          <div class="w-100"></div>

                          <div class="w-100"></div>
                          <div v-if="product.showNotes">
                            <div class="row no-gutters">
                              <div class="col">
                                <textarea
                                  class="form-control"
                                  rows="2"
                                  v-model="product.details"
                                ></textarea>
                              </div>
                              <div class="col-auto">
                                <button
                                  class="btn btn-primary btn-block h-100 px-4"
                                  @click="finishNotes(product)"
                                >
                                  <font-awesome-icon
                                    icon="check-circle"
                                  ></font-awesome-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="product.details">
                              <small>{{ product.details }}</small>
                            </div>
                            <b-button
                              size="sm"
                              variant="link"
                              class="ml-0 w-90"
                              @click="product.showNotes = true"
                            >
                              editar notas
                            </b-button>
                            <b-button
                              size="sm"
                              variant="link"
                              class="ml-0 w-90"
                              v-if="
                                !product.readyAt &&
                                hasPermission(user, 'tables_edit_qty')
                              "
                              @click="showQty(product)"
                            >
                              Editar cantidad
                            </b-button>
                          </div>
                        </div>
                        <div class="col-auto" v-if="!product.readyAt">
                          <button
                            class="btn btn-success btn-block"
                            @click="setReady(product)"
                          >
                            <font-awesome-icon
                              icon="check-circle"
                              size="1x"
                              class="mr-1"
                            />
                            <span class="d-none d-md-block"> ENTREGADO </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Funciones">
              <div class="row">
                <div class="col-6">
                  <small class="text-muted">MODO DE IMPRESION</small>
                  <select class="form-control" v-model="printMode">
                    <option :value="1">Completa</option>
                    <option :value="2">Simple</option>
                    <option :value="3">Detalle</option>
                    <option :value="4">Completa + Detalle</option>
                    <option :value="5">Completa + Cambio</option>
                  </select>

                  <div class="row mt-2">
                    <div class="col-12 col-lg-6" v-if="info.remotePrintEnabled">
                      <button
                        class="btn btn-secondary btn-lg btn-block"
                        @click="print(table)"
                        :disabled="table.products.length == 0"
                      >
                        IMPRIMIR REMOTO
                        <small class="d-block">ENVIAR A IMPRIMIR EN RED</small>
                      </button>
                    </div>
                    <div class="col-12 col-lg-6" v-if="info.remotePrintEnabled">
                      <button
                        class="btn btn-primary btn-lg btn-block"
                        @click="printSelected(table)"
                        :disabled="selected == 0"
                      >
                        IMPRIMIR SELECCIONADOS
                        <small class="d-block">REMOTO</small>
                      </button>
                    </div>
                    <div class="col-12 col-lg">
                      <button
                        class="btn btn-info btn-lg btn-block"
                        @click="printLocal(table)"
                      >
                        IMPRIMIR TODO
                        <small class="d-block">EN ESTE DISPOSITIVO</small>
                      </button>
                    </div>
                    <div class="col-12 col-lg">
                      <button
                        class="btn btn-primary btn-lg btn-block"
                        @click="printSelectedLocal(table)"
                        :disabled="selected == 0"
                      >
                        IMPRIMIR SELECCIONADOS
                        <small class="d-block">EN ESTE DISPOSITIVO</small>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <small>PRODUCTOS</small>

                  <div class="row">
                    <div class="col">
                      <button
                        class="btn btn-secondary btn-block"
                        @click="selectAll()"
                      >
                        SELECCIONAR TODOS
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn btn-info btn-block"
                        @click="selectNotReady()"
                      >
                        SELECCIONAR NO LISTOS
                      </button>
                    </div>
                  </div>
                  <div v-if="hasPermission(user, 'tables_editname')">
                    <small>CAMBIAR NOMBRE</small>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="table.name"
                        v-if="editName"
                      />
                      <button
                        class="btn btn-outline-secondary btn-block"
                        @click="editName = true"
                        v-else
                      >
                        CAMBIAR NOMBRE DE MESA
                      </button>
                    </div>
                  </div>
                  <div v-if="hasPermission(user, 'tables_move')">
                    <small>MOVER MESA</small>
                    <div class="input-group">
                      <select class="form-control" v-model="tableTarget">
                        <option value="" disabled>Selecciona mesa</option>
                        <optgroup
                          v-for="t in tables"
                          :label="t._id"
                          :key="t._id"
                        >
                          <option
                            v-for="table in t.tables"
                            :key="table._id"
                            :value="table._id"
                          >
                            {{ table.name }}
                          </option>
                        </optgroup>
                      </select>
                      <button
                        class="btn btn-outline-warning"
                        @click="moveProducts()"
                      >
                        MOVER PRODUCTOS DE MESA
                      </button>
                    </div>
                  </div>
                  <div>
                    <small>CAMBIAR CANTIDAD DE PERSONAS</small>
                    <div class="input-group">
                      <b-form-spinbutton
                        v-model="table.people"
                        size="lg"
                        class="py-2"
                      />
                    </div>
                  </div>
                </div>

                <div class="w-100 py-2"></div>

                <!-- <div
                  class="col-md-6 py-2"
                  v-if="hasPermission(user, 'tables_close')"
                >
                  <button
                    class="btn btn-success btn-lg btn-block"
                    @click="closeTable()"
                  >
                    PAGAR MESA
                    <small class="d-block">GENERAR VENTA Y CERRAR</small>
                  </button>
                </div> -->

                <div
                  class="col-md-6 py-2"
                  v-if="hasPermission(user, 'tables_delete_product')"
                >
                  <button
                    class="btn btn-danger btn-lg btn-block"
                    @click="deleteSelected()"
                    :disabled="selected.length == 0"
                  >
                    ELIMINAR PRODUCTOS
                    <small class="d-block">SOLO SELECCIONADOS</small>
                  </button>
                </div>
                <div class="col-md-6 py-2" v-else>
                  <button class="btn btn-danger btn-lg btn-block" disabled>
                    ELIMINAR PRODUCTOS
                    <small class="d-block"
                      >NECESITAS PERMISO DE UN ADMINISTRADOR</small
                    >
                  </button>
                </div>

                <div class="w-100"></div>

                <div
                  class="col-md-12 py-3"
                  v-if="hasPermission(user, 'tables_close')"
                >
                  <button
                    class="btn btn-outline-primary btn-lg btn-block py-2"
                    @click="updateTable()"
                  >
                    ACTUALIZAR MESA
                  </button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-overlay>

    <b-modal ref="options" title="Opciones" size="xl" @ok="pushProduct()">
      <div class="mt-2 px-2" v-if="product.newOptions">
        <div
          class="col-12 align-items-center py-1 no-gutters"
          v-for="({ ref: option, custom_label }, i) in product.newOptions"
          :key="`p-${i}`"
        >
          <product-option
            @remove="removeOption"
            :product="product"
            :index="i"
            :customLabel="custom_label"
            :option="option"
          >
            {{ custom_label || option.label }}
          </product-option>
        </div>
      </div>
      <div class="w-100 py-2"></div>
      <!-- notas -->
      <div class="row">
        <div class="col">
          <label for="">Notas</label>
          <textarea
            class="form-control"
            rows="2"
            v-model="product.details"
          ></textarea>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="qty-modal"
      title="Cantidad"
      size="md"
      @ok="updateQty(product)"
    >
      <label for="">Cantidad</label>
      <input
        type="number"
        class="form-control"
        ref="qty-input"
        v-model="product.qty"
        @focus="$event.target.select()"
      />
    </b-modal>

    <b-modal
      ref="reserve-modal"
      title="Reservar Mesa"
      size="md"
      @ok="saveReserve()"
      ok-title="Guardar"
      cancel-title="Cancelar"
    >
      <label for="">Fecha</label>
      <input
        type="date"
        class="form-control"
        ref="qty-input"
        v-model="date"
        @focus="$event.target.select()"
      />
      <label class="mt-2" for="">Hora</label>
      <input
        type="time"
        class="form-control"
        ref="qty-input"
        v-model="time"
        @focus="$event.target.select()"
      />
      <label class="mt-2" for="">Nombre</label>
      <input
        type="text"
        class="form-control"
        ref="qty-input"
        v-model="name"
        @focus="$event.target.select()"
      />
      <label class="mt-2" for="">Telefono</label>
      <input
        type="phone"
        class="form-control"
        ref="qty-input"
        v-model="phone"
        @focus="$event.target.select()"
      />
      <label class="mt-2" for="">Notas (Opcional)</label>
      <textarea
        class="form-control"
        ref="qty-input"
        v-model="details"
        @focus="$event.target.select()"
      ></textarea>
    </b-modal>

    <b-modal ref="products-modal" size="lg">
      <div class="row">
        <div class="col-12 col-md-12">
          <small>CATEGORIAS</small>
          <select
            class="form-control d-block d-md-none1"
            v-model="selectedCategory"
          >
            <option :value="null">Todas las categorias</option>
            <option
              v-for="category in categories"
              :key="category._id"
              :value="category._id"
            >
              {{ category.name }}
            </option>
          </select>
          <div
            class="overflow-auto custom_scroll d-none d-md-block1"
            style="max-height: 65vh"
          >
            <ul class="list-group">
              <li
                class="list-group-item click text-uppercase"
                :class="{
                  active: selectedCategory == null,
                }"
                @click="selectedCategory = null"
              >
                Todas las categorias
              </li>
              <li
                class="list-group-item click text-uppercase"
                v-for="category in categories"
                :key="category._id"
                @click="selectedCategory = category._id"
                :class="selectedCategory == category._id ? 'active' : ''"
              >
                {{ category.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <small>INGRESA EL CODIGO DEL PRODUCTO</small>
          <div class="input-group align-items-end">
            <input
              type="text"
              placeholder="Ingrese codigo del producto y presione enter"
              v-model="fakeFilter"
              class="form-control text-uppercase"
              v-focus
              autocomplete="off"
              id="inputCode"
              ref="inputCode"
              v-on:keyup.enter="filter = fakeFilter"
              enterkeyhint="go"
            />
            <button class="btn btn-primary mt-2 px-4" @click="findProduct">
              <font-awesome-icon icon="search" />
            </button>
            <button
              class="btn btn-secondary mt-2 px-4"
              @click="fakeFilter = filter = ''"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
        <div class="col-12">
          <featured @code="addCode" :categories="categories" />
        </div>
        <div class="col">
          <div
            class="overflow-auto custom_scroll mt-2"
            style="max-height: 50vh"
            ref="products"
          >
            <ul class="list-group">
              <li
                class="list-group-item click list-group-item-action"
                v-for="product in productsCategories"
                :key="product._id"
              >
                <div class="row align-items-end">
                  <!-- <div class="col-3 col-md-2 d-none">
                            <div class="btn-group btn-block d-none">
                              <button
                                class="btn btn-danger btn-sm"
                                @click="changeQty(product, 'subs')"
                              >
                                -
                              </button>
                              <button
                                class="btn btn-success btn-sm"
                                @click="changeQty(product, 'add')"
                              >
                                +
                              </button>
                            </div>
                          </div> -->
                  <div class="col-3 col-md-1 d-none d-md-block">
                    <img
                      :src="getImage(product.image)"
                      class="img-fluid mx-auto"
                      style="max-height: 80px; max-width: 80px; width: 100%"
                    />
                  </div>
                  <div class="col-9 col-md px-0">
                    {{ product.name }}
                    <strong> ${{ product.value | formatNumber }}</strong>
                    <div class="border-top w-50"></div>
                    <small class="d-block">COD: {{ product.code }}</small>
                    <div class="badge badge-info">
                      {{
                        product.category
                          ? product.category.name
                          : "Sin categoria"
                      }}
                    </div>
                    <small class="d-block">{{ product.description }}</small>
                  </div>

                  <div class="col-md-2 col-7 click px-1">
                    <small class="text-muted">CANT</small>
                    <!-- <input
                              type="text"
                              class="form-control form-control-sm text-center"
                              v-model.number="product.qty"
                              @focus="$event.target.select()"
                            /> -->
                    <b-form-spinbutton
                      v-model="product.qty"
                      size="sm"
                      class="py-2"
                    />
                  </div>
                  <div class="col-5 col-md-1">
                    <button
                      class="btn btn-success btn-block h-100"
                      @click="addProduct(product)"
                    >
                      <font-awesome-icon icon="plus" />
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import productOption from "../pdv/productOption";
import featured from "../pdv/featuredProducts";
import { clearTicket, getErrorMessage } from "../../utils";

export default {
  components: {
    productOption,
    featured,
  },
  data() {
    return {
      filter: "",
      fakeFilter: "",
      table: {
        products: [],
      },
      loading: true,
      people: 1,
      selectedCategory: null,
      printMode: 1,
      editName: false,
      product: {},
      code: "",
      date: "",
      time: "",
      details: "",
      name: "",
      phone: "",
      tableTarget: "",
    };
  },
  mounted() {
    this.load();

    this.$store.commit("hideMenu");

    if (localStorage.getItem("printMode") !== null)
      this.printMode = localStorage.getItem("printMode" || 1);
  },
  beforeDestroy() {
    this.$store.commit("toggleMenu");
  },
  computed: {
    tables() {
      return this.$store.state.tables;
    },
    info() {
      return this.$store.state.info;
    },
    config() {
      return this.$store.state.tablesConfig;
    },
    categories() {
      const categories = this.$store.state.categories;

      console.log("cats", this.config);

      if (this.config.categories && this.config.categories.length > 0) {
        return categories.filter((c) => {
          return this.config.categories.includes(c._id);
        });
      }
      return categories;
    },
    productsCategories() {
      let products = this.$store.state.products.filter((product) => {
        if (!product.category || !product.category._id) return false;

        if (this.selectedCategory) {
          return product.category._id == this.selectedCategory;
        } else if (
          this.config.categories &&
          this.config.categories.length > 0
        ) {
          if (this.categories.find((c) => c._id == product.category._id))
            return true;
        } else {
          return true;
        }
      });

      if (this.filter) {
        const filter = this.filter.toLowerCase().trim();
        products = products.filter(
          (p) =>
            p.name.toLowerCase().includes(filter) ||
            p.code.toLowerCase().includes(filter)
        );
      }

      return products.map((product) => {
        return {
          ...product,
          qty: 1,
        };
      });
    },
    selected() {
      return this.table.products.filter((product) => product.selected);
    },
  },
  methods: {
    loadTables() {},
    moveProducts() {
      const tableTarget = this.tableTarget;
      const table = this.table;

      if (tableTarget == "")
        return this.$noty.error("Seleccione una mesa para mover los productos");

      if (tableTarget == table._id)
        return this.$swal({
          title: "Error",
          text: "No puedes mover productos a la misma mesa",
          icon: "error",
        });

      this.$http
        .post(`table/${table._id}/move`, {
          target: tableTarget,
        })
        .then(() => {
          this.$noty.success("Productos movidos de mesa");
          this.$router.push("/restaurant/" + tableTarget);
        })
        .catch((error) => {
          this.$noty.error(error);
        });
    },
    addCode(code) {
      const product = this.productsCategories.find((p) => p.code == code);

      if (product) {
        this.addProduct(product);
        this.fakeFilter = "";
        this.$refs.inputCode.focus();
      } else {
        this.$noty.error("Producto no encontrado");
      }
    },
    showProducts() {
      this.$refs["products-modal"].show();
    },
    saveReserve() {
      const id = this.$route.params.id;
      if (!this.date || !this.time)
        return this.$noty.error("Debe seleccionar fecha y hora");
      this.$http
        .post(`table/${id}/reserve`, {
          date: this.date,
          time: this.time,
          details: this.details,
          name: this.name,
          phone: this.phone,
        })
        .then(() => {
          this.$noty.success("Mesa reservada");
          this.$refs["reserve-modal"].hide();
        })
        .catch(() => {
          this.$noty.error("Error al reservar mesa");
        });
    },
    reserve(table) {
      this.$refs["reserve-modal"].show();
    },
    printSelectedLocal(table) {
      const selected = table.products.filter((o) => o.selected);

      if (selected.length === 0)
        return this.$noty.error("Seleccione productos para imprimir");

      const sub_total = table.products.reduce((a, b) => {
        return a + b.value * b.qty;
      }, 0);

      this.$emit("printTicket", {
        ticket: {
          clientName: table.name,
          table: table._id,
          tip: 0,
          products: selected.map((p) => {
            return {
              ...p,
              options: [],
              total: p.value * p.qty,
              discount: 0,
              iva: true,
            };
          }),
          sub_total: sub_total,
          total: sub_total,
        },
        type: this.printMode,
      });
    },
    printSelected(table) {
      const selected = table.products.filter((o) => o.selected);

      if (selected.length === 0)
        return this.$noty.error("Seleccione productos para imprimir");

      const ids = selected.map((o) => {
        if (o._id) return o._id;
      });
      if (ids.length == 0)
        return this.$noty.error("No se pueden imprimir productos sin ID");

      const payload = {
        products: ids,
      };

      this.$http
        .post("print", { table: table._id, type: this.printMode, payload })
        .then(() => {
          this.$noty.success("Enviado a imprimir en red");
        })
        .catch(() => {
          this.$noty.error("Error al imprimir en red. Intente nuevamente");
        });
    },
    printLocal(table) {
      const sub_total = table.products.reduce((a, b) => {
        return a + b.value * b.qty;
      }, 0);

      const ticket = {
        clientName: table.name,
        table: table._id,
        tip: 0,
        number: ` #${table.name}`,
        products: table.products.map((p) => {
          return {
            ...p,
            //options: [],
            total: p.value * p.qty,
            discount: 0,
            iva: true,
          };
        }),
        sub_total: sub_total,
        total: sub_total,
      };
      this.$store.commit("setTicket", ticket);
      this.$emit("printTicket", {
        ticket,
        type: this.printMode,
      });
    },
    print(table) {
      this.$http
        .post("print", { table: table._id, type: this.printMode })
        .then(() => {
          this.$noty.success("Enviado a imprimir en red");
        })
        .catch(() => {
          this.$noty.error("Error al imprimir en red. Intente nuevamente");
        });
    },
    setReady(product) {
      product.readyAt = Date.now();
      this.updateTable();
    },
    findProduct() {
      const code = this.code.toUpperCase();
      const product = this.productsCategories.find((p) => p.code == code);
      if (product) {
        this.addProduct(product);
        this.code = "";
        this.$refs.inputCode.focus();
      } else {
        this.$noty.error("Producto no encontrado");
      }
    },
    openTable(table) {
      this.$http
        .post(`table/${table._id}`, {
          people: this.people,
        })
        .then((res) => {
          this.load();
          this.table = res.data.data;
        });
      // this.$refs.modal.hide();
    },
    closeTable() {
      let ticket = clearTicket();
      //this.table.status = "available";
      //this.$refs.modal.hide();

      let products = this.table.products.map((p) => {
        return {
          ...p,
          options: [],
          total: p.value * p.qty,
          discount: 0,
          iva: true,
        };
      });

      let sub_total = products.reduce((a, b) => {
        return a + b.value * b.qty;
      }, 0);

      // si no hay productos, limpiar mesa
      if (products.length == 0) {
        return this.$http
          .post(`table/${this.table._id}/clear`)
          .then(() => {
            this.$noty.success("Mesa cerrada");
            return this.$router.push("/restaurant");
          })
          .catch((err) => {
            console.log(err);
          });
      }

      let newTickets = localStorage.getItem("newTickets")
        ? JSON.parse(localStorage.getItem("newTickets"))
        : [];

      newTickets.push({
        ...ticket,
        //sub_total,
        clientName: `${this.table.name}`,
        table: this.table._id,
        tip: Math.round(sub_total * 0.1),
        products,
      });

      localStorage.setItem("newTickets", JSON.stringify(newTickets));

      this.table.status == "open";
      this.table.products = [];
      this.table.user = null;
      this.table.startedAt = null;

      this.$router.push("/new?gui=classic");
    },
    async deleteSelected() {
      const confirm = await this.$swal({
        title: "Eliminar productos",
        text: "¿Estas seguro que deseas eliminar los productos seleccionados?",
        icon: "warning",
        buttons: ["Cancelar", "Eliminar"],
        dangerMode: true,
      });

      if (!confirm) return;
      // search if in selected products there are products with readyAt
      const selected = this.table.products.filter((o) => o.selected);
      const ready = selected.filter((o) => o.readyAt);
      if (ready.length > 0 && this.user.role != "admin")
        return this.$swal({
          title: "Error",
          text: "No puedes eliminar productos entregados. Necesitas permisos de administrador",
          icon: "error",
        });

      //this.table.products = this.table.products.filter((o) => !o.selected);
      this.$http
        .delete(`table/${this.table._id}/product`, {
          body: { products: selected },
        })
        .then((res) => {
          this.table = res.data.data;
          this.$noty.success("Productos eliminados");
        })
        .catch(() => {
          this.$noty.error("Error al eliminar productos");
        });
    },
    updateTable() {
      this.$http
        .patch(`table/${this.table._id}`, this.table)
        .then((res) => {
          this.table = res.data.data;
          this.load();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectNotReady() {
      this.table.products = this.table.products.map((o) => {
        if (!o.readyAt) o.selected = true;
        return o;
      });
    },
    getSelected(option) {
      //console.log({ option });
      //option.selected ? option.selected : "- - - - -"
      if (option) {
        if (option.value) return `${option.selected} ($${option.value})`;
        else return `${option.selected}`;
      } else return "- - - - -";
    },
    selectAll() {
      this.table.products = this.table.products.map((o) => {
        o.selected = true;
        return o;
      });
    },
    changeQty(product, type) {
      if (type == "add") product.qty++;
      if (type == "subs" && product.qty > 1) product.qty--;
      this.$forceUpdate();
    },
    updateQty(product) {
      this.table.products = this.table.products.map((o) => {
        if (o._id == product._id) o.qty = product.qty;
        return o;
      });
      this.$refs["qty-modal"].hide();
      this.updateTable();
    },
    showQty(product) {
      this.product = product;
      this.$refs["qty-modal"].show();
      setTimeout(() => {
        this.$refs["qty-input"].focus();
      }, 500);
    },
    getSelected(option) {
      //console.log({ option });
      //option.selected ? option.selected : "- - - - -"
      if (option) {
        if (option.value) return `${option.selected} ($${option.value})`;
        else return `${option.selected}`;
      } else return "- - - - -";
    },
    finishNotes(product) {
      product.showNotes = false;
      this.updateTable();
    },
    pushProduct(pr) {
      let p = pr || this.product;
      //this.table.products.unshift();
      this.$http
        .post(`table/${this.table._id}/product`, {
          product: p,
        })
        .then((res) => {
          this.table = res.data.data;
          this.product = {};
          this.$noty.success("Producto agregado");
          //this.updateTable();
        })
        .catch((err) => {
          console.log(err);
          this.$noty.error("Error al agregar producto");
        });
      //this.updateTable();
    },
    removeOption({ product, index }) {
      product.newOptions.splice(index, 1);
    },
    showOptions(product) {
      this.$refs["options"].show();
      this.product = product;
    },
    async addProduct(product) {
      let p = JSON.parse(JSON.stringify(product || this.product));
      // reset qty
      product.qty = 1;
      // delete p._id;
      delete p._id;
      p.createdAt = Date.now();

      if (p.newOptions && p.newOptions.length > 0) return this.showOptions(p);

      if (!p.options) p.options = [];
      if (!p.name) return this.$noty.error("Debe seleccionar un producto");
      //p._id = await bson.ObjectId()
      // let options = p.newOptions.map((o) => {
      //   console.log(o.ref);
      //   let values = o.ref.options.map((v) => {
      //     return {
      //       name: v._id.name,
      //       value: v._id.value,
      //     };
      //   });

      //   return {
      //     ...o,
      //     values,
      //     selected: o.selected ? o.selected : null,
      //   };
      // });
      //return false;
      this.pushProduct(p);
    },
    back() {
      this.$router.push("/restaurant");
    },
    load() {
      const id = this.$route.params.id;

      if (!id) return this.$router.push("/restaurant");

      this.$http
        .get(`table/${id}`)
        .then((res) => {
          const table = res.data.data;

          if (
            table.status !== "available" &&
            table.user !== this.user._id &&
            this.user.role != "admin"
          ) {
            this.$noty.error("La mesa ya esta ocupada");
            return this.$refs["modal"].hide();
          }

          this.table = table;

          //this.$refs.modal.show();

          // this.$router.push({
          //   query: {
          //     table: this.table._id,
          //   },
          // });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    fakeFilter() {
      if (this.fakeFilter == "") this.filter = "";
    },
    printMode: function (val) {
      localStorage.setItem("printMode", val);
    },
    '$route.params.id': function (val) {
      this.load();
    }
  },
};
</script>