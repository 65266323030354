<template>
  <div class="row mb-4" v-if="products.length > 0">
    <div class="col-12">
      <small class="mr-4 text-uppercase">Accesos rapidos</small>
      <small class="text-uppercase click text-muted" @click="toggle()">{{
        !show ? "Mostrar" : "Ocultar"
      }}</small>
    </div>
    <div class="col-12" v-if="products.length > 0 && show">
      <div class="row no-gutters">
        <div
          class="col-6 col-md-3 col-lg-2 col-sm-4 overflow-hidden"
          v-for="product in products"
          :key="product._id"
        >
          <button
            @click="add(product)"
            class="btn btn-sm btn-outline-secondary btn-block h-100"
          >
            <span class="font-weight-bold">{{ product.code }} </span>
            <div class="px-2 overflow-hidden">
              <!-- <marquee behavior="alternate" scrolldelay="250" onmouseover="this.start();" onmouseout="this.stop();"> -->
              <small class="d-block text-uppercase text-nowrap">{{
                product.name
              }}</small>
              <!-- </marquee> -->
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  name: "FeaturedProducts",
  data() {
    return {
      loading: true,
      show: true
    };
  },
  computed: {
    products() {
      const categories_id = this.categories.map((category) => category._id);

     if(categories_id.length == 0){
       return this.$store.state.featured
     }


      return this.$store.state.featured.filter((product) =>
        categories_id.includes(product.category)
      );
    },
  },
  watch: {
    show(val){
      localStorage.setItem('showFeatured', val)
    }
  },
  mounted() {
    this.$http
      .get("product/featured")
      .then((res) => {
        this.loading = true;
        this.$store.commit("setFeatured", res.data.data);
      })
      .catch(() => {})
      .finally(() => {
        // this.loading = false
      });
      if(localStorage.getItem('showFeatured') == 'false'){
        this.show = false
      }
  },
  methods: {
    toggle(){
      this.show = !this.show
    },
    add({ code }) {
      this.$emit("code", code);
    },
  },
};
</script>

<style scoped>
li.btn {
  cursor: pointer;
}
</style>