<template>
  <div class="container-fluid container-app">
    <div class="row no-gutters align-items-end">
      <div class="col">
        <h2>Gastos</h2>
      </div>
      <div class="w-100"></div>
      <div class="col">
        <small class="text-muted">INGRESE PARA BUSCAR Y PRESIONE ENTER</small>
        <input
          placeholder="Buscar por nombre o descripcion"
          type="text"
          v-model="fakeFilter"
          @keyup.enter="filter = fakeFilter"
          class="form-control"
        />
      </div>
      <div class="ml-auto col-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <font-awesome-icon icon="calendar-alt" />
            </span>
            <date-picker
              valueType="format"
              v-model="dateFilter"
              type="month"
              input-class="form-control pl-4"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-1">
        <button class="btn btn-block btn-success" @click="load()">
          <div
            class="spinner-border spinner-border-sm text-light mb"
            role="status"
            v-if="loading"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <font-awesome-icon icon="redo" v-if="!loading" />
        </button>
      </div>
      <div class="col-lg-2">
        <button class="btn btn-block btn-info" @click="newExpense()">
          Ingresar
        </button>
      </div>
      <div class="w-100 py-2"></div>
      <div class="col-12">
        <b-tabs justified nav-class="font-weight-bold">
          <b-tab title="GASTOS">
            <expenses-list
              :data="expensesList"
              :loading="loading"
              @cancel="cancel"
              @edit="edit"
            />
          </b-tab>
          <b-tab title="BORRADOS">
            <expenses-list
              :data="expensesDeleted"
              :loading="loading"
              @edit="edit"
            />
          </b-tab>
          <b-tab title="ESTADISTICAS" v-if="false">
            <div class="row py-4">
              <div class="col-6">
                <h5>GASTOS POR SEMANAS</h5>
                <apexchart
                  width="100%"
                  height="320"
                  type="line"
                  :options="chartOptions"
                  :series="series"
                />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <b-modal
      ref="modalExpense"
      size="xl"
      title="Ingresar gasto"
      scrollable
      @ok="saveExpense()"
    >
      <router-link class="d-none" to="/expenses/listmode"
        >Modo lista</router-link
      >
      <div class="w-100 border-bottom d-none py-3"></div>
      <div class="row">
        <div class="col">
          <label for="">Nombre (obligatorio)</label>
          <input
            type="text"
            v-model="name"
            class="form-control"
            placeholder="Ej: Pago arriendo local, Abono pedido #33"
            :class="{
              'is-invalid': name.length < 3,
              'is-valid': name.length >= 3,
            }"
          />

          <div class="w-100 py-2"></div>

          <div v-if="$store.state.user.role == 'admin'">
            <label for="">Fecha (Solo admin)</label>
            <input type="date" v-model="date" class="form-control" />
            <small class="text-danger"
              >CAMBIAR SI LA FECHA ES DISTINTA A HOY</small
            >
          </div>

          <div class="w-100 py-2"></div>
          <label for="">Monto (obligatorio)</label>
      <input
        type="text"
        v-model.number="value"
        class="form-control"
        @focus="$event.target.select()"
        :class="{ 'is-invalid': value == 0, 'is-valid': value > 0 }"
      />
      

          <label for="">Medio de pago (obligatorio)</label>

          <select name="" id="" class="form-control" v-model="payment">
            <option value="Efectivo">Efectivo</option>
            <option value="Credito">Credito</option>
            <option value="Debito">Debito</option>
            <option value="Transferencia">Transferencia</option>
            <option value="Cheque">Cheque</option>
          </select>

          <div class="w-100 py-2"></div>

          <label for="">Estado de pago</label>
          <select name="" id="" class="form-control" v-model="status">
            <option value="Pagado">Pagado</option>
            <option value="No Pagado">No pagado</option>
          </select>

          <div class="w-100 py-2"></div>

          <label for="">Notas (opcional)</label>
          <textarea
            class="form-control"
            v-model="notes"
            cols="10"
            rows="5"
          ></textarea>
        </div>
        <div class="col d-none">
          <div class="">
            <h6>DETALLES</h6>
          </div>
          <div class="overflow-scroll">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row">
                  <div class="col">
                    <small>SELECCIONE PRODUCTO PARA AGREGAR</small>
                    <v-select
                      @input="addProduct()"
                      taggable
                      v-model="product"
                      class="py-1"
                      append-to-body
                      :options="getProducts"
                    ></v-select>
                  </div>
                </div>
              </li>
              <li
                class="list-group-item"
                :key="product._id"
                v-for="product in expense_products"
              >
                <div class="row align-items-end">
                  <div class="col">
                    <small class="text-muted">CODIGO: {{ product.code }}</small>
                    <div class="w-100"></div>
                    <strong class="text-uppercase">{{ product.name }}</strong>
                  </div>
                  <div class="col-2">
                    <small class="text-muted">CANTIDAD</small>
                    <input
                      type="number"
                      v-model.number="product.qty"
                      class="form-control form-control-sm"
                      :ref="`input-qty`"
                      @focus="$event.target.select()"
                    />
                  </div>
                  <div class="col-3">
                    <small class="text-muted">VALOR</small>
                    <input
                      type="number"
                      v-model.number="product.value"
                      class="form-control form-control-sm"
                      :ref="`input-value`"
                      @focus="$event.target.select()"
                    />
                  </div>
                  <div class="col-1">
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="remove(product)"
                    >
                      <font-awesome-icon icon="times" />
                    </button>
                  </div>
                </div>
                <div class="row mt-2 align-items-start" v-if="product._id">
                  <div class="col">
                    <b-checkbox v-model="product.updateStock" size="sm">ACTUALIZAR STOCK</b-checkbox>
                    <select class="form-control form-control-sm" v-if="product.updateStock">
                      <option :value="s._id" v-for="s in storages" :key="s._id">{{s.name}}</option>
                    </select>
                  </div>
                  <div class="col">
                    <b-checkbox v-model="product.updateCostValue" size="sm">ACTUALIZAR PRECIO COSTO</b-checkbox>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modalExpense"
      size="xs"
      ref="modal-edit"
      title="Editar gasto"
      @ok="updateExpense()"
    >
      <label for="">Nombre (obligatorio)</label>
      <input
        type="text"
        v-model="expense.name"
        class="form-control"
        :class="{
          'is-invalid': expense.name.length < 3,
          'is-valid': expense.name.length >= 3,
        }"
      />

      <div v-if="$store.state.user.role == 'admin'">
        <label for="">Fecha (Solo admin)</label>
        <input type="date" v-model="expense.date" class="form-control" />
      </div>

      <label for="">Medio de pago</label>
      <select name="" id="" class="form-control" v-model="expense.payment">
        <option value="Efectivo">Efectivo</option>
        <option value="Credito">Credito</option>
        <option value="Debito">Debito</option>
        <option value="Transferencia">Transferencia</option>
        <option value="Cheque">Cheque</option>
      </select>

      <label for="">Estado de pago</label>
      <select name="" id="" class="form-control" v-model="expense.status">
        <option value="Pagado">Pagado</option>
        <option value="No Pagado">No pagado</option>
      </select>

      <label for="">Notas (opcional)</label>
      <textarea
        class="form-control"
        v-model="expense.notes"
        cols="10"
        rows="5"
      ></textarea>
    </b-modal>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ExpensesList from "../components/expensesList.vue";
import {log} from '../utils'

export default {
  components: {
    ExpensesList,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      expenses: [],
      product: {},
      payment: "Efectivo",
      loading: true,
      value: 0,
      name: "",
      notes: "",
      date: null,
      fakeFilter: "",
      showDeleted: false,
      dateFilter: null,
      status: "Pagado",
      expense_products: [],
      expense: {
        name: "",
        status: "",
      },
      expensesDeleted: [],
      expensesStats: [],
      expensesStatsPrev: [],
    };
  },
  computed: {
    storages() {
      return this.$store.state.storages
    },
    products() {
      return this.$store.state.products;
    },
    getProducts() {
      if (!this.products) return [];
      return this.products.map((o) => ({
        ...o,
        label: `${o.code} - ${o.name}`,
      }));
    },
    series() {
      let years = this.expensesStats.map((year) => year.total);
      let years2 = this.expensesStatsPrev.map((year) => year.total);

      return [
        {
          name: "Total Gastos mes actual",
          data: years,
        },
        {
          name: "Total Gastos mes anterior",
          data: years2,
        },
        //  {
        //   name: "Total OT",
        //   data: otYears,
        // },
      ];
    },
    chartOptions() {
      let days = this.expensesStats.map((day) => {
        let startDay = this.$moment(day._id, "w").format("DD/MM");
        let endDay = this.$moment(day._id, "w").add(6, "days").format("DD/MM");
        return `${startDay} - ${endDay}`;
      });
      const self = this;
      return {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
          type: "line",
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#546E7A", "#2c97f3"],
        xaxis: {
          categories: days,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex}) {
            return (
              '<div class="arrow_box p-3">' +
              "<span class='font-weight-bold d-block'>Total gastos</span><span> $" +
              self.formatNumber(series[seriesIndex][dataPointIndex]) +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
    totalExpenses() {
      return this.expenses.reduce((total, expense) => {
        return total + expense.value;
      }, 0);
    },
    expensesList() {
      if (this.showDeleted) return this.expenses;
      else return this.expenses.filter((e) => e.deletedAt == null);
    },
  },
  mounted() {
    this.dateFilter = this.$moment().format("YYYY-MM");
    this.date = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
   
    remove(product) {
      let newList = this.expense_products.filter((p) => p.code != product.code);
      this.expense_products = newList;
    },
    addProduct() {
      let { _id, code, name, cost_value, label } = this.product;
      log({ product: this.product });
      let find = this.expense_products.find((p) => p.code == code);
      if (find) return this.$noty.error("Producto ya agregado");
      else
        this.expense_products.unshift({
          _id: _id || null,
          ref: _id || null,
          code: code || "0",
          name: name || label,
          qty: 0,
          value: cost_value || 0,
        });
      setTimeout(() => {
        this.$refs[`input-qty`][this.$refs[`input-qty`].length - 1].focus();
      }, 500);
      this.product = null;
    },
    updateExpense() {
      this.$http.post(`expenses/${this.expense._id}`, this.expense).then(
        () => {
          this.$refs["modal-edit"].hide();
          this.load();
        },
        (err) => {
          log(err);
        }
      );
    },
    edit(ex) {
      this.expense = ex;
      this.$refs["modal-edit"].show();
    },
    toggleDeleted() {
      this.showDeleted = !this.showDeleted;
    },
    cancel(id) {
      if (!id) this.$noty.error("No se pudo cancelar el gasto");
      this.$swal({
        icon: "warning",
        title: "Cancelar",
        text: "Ingrese un motivo!",
        content: "input",
        contentPlaceholder: "Ingrese un motivo",
      }).then((reason) => {
        // if (reason != null) {
        this.$http
          .delete(`expenses/${id}`, { body: { reason } })
          .then(() => {
            this.load();
            this.$noty.success("Gasto cancelado");
          })
          .catch(() => {
            this.$noty.error("Error al borrar");
          });
        //}
      });
    },
    saveExpense() {
      this.$http
        .post("expenses", {
          name: this.name,
          value: this.value,
          notes: this.notes,
          date: this.date,
          status: this.status,
          payment: this.payment,
          products: this.expense_products,
        })
        .then(() => {
          this.$noty.success("Gasto agregado correctamente");
          this.name = "";
          this.value = 0;
          this.notes = "";
          this.expense_products = [];
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al ingresar");
        });
    },
    newExpense() {
      this.$refs["modalExpense"].show();
    },
    load() {
      this.loading = true;
      let query = {};
      if (this.dateFilter) query = `date=${this.dateFilter}`;
      this.$http
        .get(`expenses/month?${query}`)
        .then((res) => {
          this.expenses = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });

      this.$http.get("expenses/month?deleted=1").then((res) => {
        this.expensesDeleted = res.data.data;
      });

      this.$http
        .get(`expenses/stats?date=${this.dateFilter}`)
        .then((res) => {
          this.expensesStats = res.data.data;
          let prevDate = this.$moment(this.dateFilter)
            .subtract(1, "month")
            .format("YYYY-MM");
          return this.$http.get(`expenses/stats?date=${prevDate}`);
        })
        .then((res) => {
          this.expensesStatsPrev = res.data.data;
        });
    },
  },
  watch: {
    dateFilter() {
      this.load();
    },
  },
};
</script>