<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-print-none">
        <div class="row">
          <div class="col">
            <h2>Pagos en SumUp</h2>
          </div>
          <div class="col-4 ml-auto">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="calendar-alt" />
                </span>
                <date-picker
                valueType="format"
                v-model="dateFilter"
                input-class="form-control d-inline-block"
              />
              </div>
              <div class="input-group-append">
                <button
                  class="btn btn-outline-info float-right"
                  @click="loadData"
                >
                  <font-awesome-icon icon="redo" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="col-12">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Pagos con maquina
            </a>
          </li>
          <li class="nav-item" v-if="info.tipEnabled">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Propinas
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 d-print-none">
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div class="row">
              <div class="col-12">
                <ul class="list-group">
                  <li class="list-group-item" v-if="loading == true">
                    <div class="text-center">
                      <div class="mt-3">
                        <b-spinner class="ml-auto"></b-spinner>
                        <br />
                        <strong>Cargando...</strong>
                      </div>
                    </div>
                  </li>
                  <li
                    class="list-group-item text-center"
                    v-if="payments.length == 0 && loading == false"
                  >
                    <span class="d-block py-5 font-weight-bold"
                      >No hay informacion</span
                    >
                  </li>
                  <li
                    class="list-group-item"
                    :class="{
                      active: payment.transaction_code == id,
                    }"
                    v-for="payment in payments"
                    @click="loadPayment(payment.transaction_code)"
                    :key="payment.transaction_code"
                    style="cursor: pointer"
                  >
                    <div class="row align-items-center">
                      <div class="col-4">
                        <strong>{{ payment.transaction_code }}</strong>
                        <br />
                        <small>{{ payment.user }}</small>
                      </div>
                      <div class="col font-weight-bold text-center">
                        {{ payment.card_type }}
                      </div>
                      <div class="col text-center">
                        <span
                          class="badge px-3 badge-pill"
                          :class="{
                            'badge-success': payment.status == 'SUCCESSFUL',
                          }"
                          >{{ payment.status }}</span
                        >
                      </div>
                      <div class="col font-weight-bold text-right">
                        ${{ formatNumber(payment.amount) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-3" v-if="false">
                <div class="row">
                  <div class="col-12">
                    <div v-if="pay.transaction_data && loadingPay == true">
                      <button
                        class="btn btn-secondary btn-block d-print-none"
                        @click="print()"
                        v-if="false"
                      >
                        IMPRIMIR
                      </button>
                      <table class="table mt-3" style="font-size: 12px">
                        <tr>
                          <th colspan="2" class="text-center">
                            COMPROBANTE DE VENTA <br />
                            <small>
                              <strong>EIEN SUSHI HUALPEN</strong>
                              <p>
                                Gastronomia japonesa Esteban Pineda E.I.R.L.
                                <br />
                                Bremen 860 Local C - Hualpen
                              </p>
                              Fecha:
                              {{
                                pay.transaction_data
                                  ? pay.transaction_data.timestamp
                                  : "" | moment("DD/MM/YYYY HH:mm:ss")
                              }}
                            </small>
                          </th>
                        </tr>
                        <tr>
                          <th>CODIGO</th>
                          <td>
                            {{
                              pay.transaction_data
                                ? pay.transaction_data.transaction_code
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>N° DE TARJETA</th>
                          <td>
                            {{
                              pay.transaction_data
                                ? `*********${pay.transaction_data.card.last_4_digits}`
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>TIPO</th>
                          <td>
                            {{
                              pay.transaction_data
                                ? pay.transaction_data.card.type
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>TOTAL</th>
                          <td colspan="2">
                            ${{
                              pay.transaction_data
                                ? pay.transaction_data.amount
                                : 0
                            }}
                          </td>
                        </tr>
                      </table>

                      <hr />
                      <h6 class="text-center">GRACIAS POR SU COMPRA!</h6>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div class="row">
              <div class="col">
                <ul class="list-group">
                  <li
                    class="list-group-item text-center"
                    v-if="sumups.length == 0"
                  >
                    <span class="d-block py-5 font-weight-bold"
                      >No hay informacion</span
                    >
                  </li>
                  <li
                    class="list-group-item"
                    v-for="sumup in sumups"
                    :key="sumup._id"
                  >
                    <div class="row align-items-center">
                      <div class="col-3 text-center">
                        <small class="font-weight-bold">{{
                          sumup.createdAt | moment("HH:mm:ss")
                        }}</small>
                        <span class="d-block border-bottom my-1"></span>
                        <small
                          class="d-block text-uppercase font-weight-bolder"
                          >{{
                            sumup.user ? sumup.user.name : "Desconocido"
                          }}</small
                        >
                      </div>
                      <div class="col">
                        <span class="d-block font-weight-bold text-uppercase">{{
                          sumup.ticket.clientName
                        }}</span>
                        <small style="font-size: 11px">{{
                          sumup.ticket.address
                        }}</small>
                      </div>
                      <div class="col-2 text-center">
                        <span
                          :class="{
                            'badge-success': sumup.status == 'success',
                            'badge-danger': sumup.status == 'failed',
                            'badge-warning': sumup.status == 'waiting',
                          }"
                          class="badge d-block text-uppercase px-2 py-2"
                          >{{ sumup.status }}</span
                        >
                      </div>
                      <div class="col-2 text-right" style="font-size: 13px">
                        <span class="d-block"
                          >${{ formatNumber(sumup.amount) }}</span
                        >
                        <span
                          class="d-block"
                          :class="{
                            'text-success': sumup.tip > 0,
                          }"
                          >+${{ formatNumber(sumup.tip) }}</span
                        >
                        <span
                          class="d-block"
                          :class="{
                            'text-danger': sumup.discount > 0,
                          }"
                          >-${{ formatNumber(sumup.discount) }}</span
                        >
                        <span class="d-block border-bottom"></span>
                        <span class="font-weight-bold"
                          >Total ${{ formatNumber(sumup.total) }}</span
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-4">
                <ul class="list-group">
                  <li
                    class="list-group-item text-center"
                    v-if="tips.length == 0"
                  >
                    <span class="d-block font-weight-bold py-5"
                      >Aun no hay propinas exitosas</span
                    >
                  </li>
                  <li
                    class="list-group-item"
                    :key="tip._id"
                    v-for="tip in tips"
                  >
                    <div class="row">
                      <div class="col-8 font-weight-bold">
                        {{ tip._id.name }}
                        <small class="d-block">Descuentos realizados:</small>
                      </div>
                      <div class="col-4 text-right font-weight-bold">
                        <span class="text-success d-block"
                          >${{ formatNumber(tip.total) }}</span
                        >
                        <small
                          :class="{
                            'text-danger font-weight-bold': tip.discounts > 0,
                          }"
                          >${{ formatNumber(tip.discounts) }}</small
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sumup",
  data() {
    return {
      payments: [],
      loading: true,
      loadingPay: false,
      pay: {},
      dateFilter: null,
      id: 0,
      sumups: [],
      tips: [],
    };
  },
  mounted() {
    if (this.$moment().hour() < 5)
      this.dateFilter = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    else this.dateFilter = this.$moment().format("YYYY-MM-DD");
    this.loadData();
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    getTransactionCode() {
      if (this.pay.transaction_data) {
        return this.pay.transaction_data.transaction_code;
      } else {
        return null;
      }
    },
  },
  methods: {
    print() {
      window.print();
    },
    loadPayment(id) {
      this.loadingPay = true;
      this.id = id;
      this.$http
        .get(`payments/${id}`)
        .then((res) => {
          if (res.data.data.transaction_data.status == "FAILED") {
            this.$noty.error("No se pueden cargar pagos fallidos");
            this.loadingPay = false;
            this.pay = {};
            return false;
          } else {
            this.pay = res.data.data;
            this.loadingPay = false;
          }
        })
        .catch(() => {
          this.$noty.error("No se pudo cargar el pago");
          this.loadingPay = false;
        });
    },
    loadData() {
      this.loading = true;
      this.payments = [];
      this.$http.get(`payments?date=${this.dateFilter}`).then((res) => {
        this.payments = res.data.data;
        this.loading = false;
      });

      this.$http
        .get(`sumup/all?date=${this.dateFilter}`)
        .then((res) => {
          this.sumups = res.data.data;
          return this.$http.get(`sumup/total?date=${this.dateFilter}`);
        })
        .then((res) => {
          this.tips = res.data.data;
        });
    },
  },
  watch: {
    dateFilter: function () {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.fab {
  font-size: 40px;
  line-height: 0.4 !important;
  padding: 7px 0px 0px 8px;
}
.selectable {
  cursor: pointer;
}
.loading tr.selectable {
  cursor: wait !important;
}
.less-padding th,
.less-padding td {
  padding: 5px 5px;
  font-size: 13px;
}
#pills-tab a {
  min-width: 230px;
  text-align: center;
  border-radius: 0px;
  margin-right: 10px;
}
#pills-tab a.active {
  color: #007bff;
  background: transparent;
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}
</style>
