var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mt-3"},[_c('b-spinner',{staticClass:"ml-auto"}),_c('strong',{staticClass:"d-block"},[_vm._v("Cargando...")])],1)])])]):(_vm.data && _vm.data.length == 0)?_c('ul',{staticClass:"list-group"},[_vm._m(0)]):_c('ul',{staticClass:"list-group"},_vm._l((_vm.data),function(expense){return _c('li',{key:expense._id,staticClass:"list-group-item list-group-item-action click",class:{
        'list-group-item-danger': expense.deletedAt,
        'list-group-item-info': expense.status == 'No pagado',
      },on:{"click":function($event){return _vm.showExpense(expense)}}},[_c('div',{staticClass:"row py-1 align-items-center"},[_c('div',{staticClass:"col-lg-1 col-4 text-center"},[_c('small',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm._f("moment")(expense.date,"dddd")))]),_c('div',{staticClass:"w-100"}),_c('small',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm._f("moment")(expense.date,"DD/MM/YY")))])]),_c('div',{staticClass:"col-8 col-lg-5"},[_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(expense.name))]),_c('div',{staticClass:"w-100"}),_c('small',{staticClass:"text-muted d-block text-uppercase"},[_vm._v(_vm._s(expense.notes || ""))])]),_c('div',{staticClass:"col-4 col-lg-2"},[_c('small',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(expense.creator && expense.creator.name))])]),_c('div',{staticClass:"col-lg-2 col-3 text-center"},[_c('div',{staticClass:"badge px-2 py-2 w-100",class:{
              'badge-success': expense.status == 'Pagado',
              'badge-danger': expense.status != 'Pagado',
            }},[_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(expense.status))])])]),_c('div',{staticClass:"col-2 text-right"},[_c('small',{staticClass:"text-uppercase text-muted d-block"},[_vm._v(_vm._s(expense.payment || "- - - -"))]),_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("$"+_vm._s(_vm.formatNumber(expense.value)))])]),_c('div',{staticClass:"col-1 d-none"},[(!expense.deletedAt)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})],1),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.edit(expense)}}},[_vm._v("\n                Editar\n              ")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.cancel(expense._id)}}},[_vm._v("\n                Eliminar\n              ")])])]):_vm._e()])])])}),0),_c('b-modal',{ref:"modal",attrs:{"title":"DETALLE","size":"md"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [(!_vm.expense.deletedAt)?_c('div',{staticClass:"row w-100 align-items-end justify-content-end no-gutters"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"mb-0"},[_vm._v("DETALLE")])]),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-block btn-outline-warning",on:{"click":function($event){return _vm.edit(_vm.expense)}}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1)]),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-block btn-outline-danger",on:{"click":function($event){return _vm.cancel(_vm.expense._id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)])]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert text-center",class:{
            'alert-success': _vm.expense.status == 'Pagado',
            'alert-danger': _vm.expense.status != 'Pagado',
          }},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":_vm.expense.status == 'Pagado' ? 'check-circle' : 'times'}}),_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.expense.status))])],1)]),_c('div',{staticClass:"col"},[_c('h3',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.expense.name))]),_c('div',{staticClass:"w-100"}),_c('span',{staticClass:"text-muted"},[_vm._v("\n          "+_vm._s(_vm._f("moment")(_vm.expense.date,"DD/MM/YYYY"))+"\n        ")]),_c('span',{staticClass:"text-muted ml-2"},[_vm._v("\n          "+_vm._s(_vm.expense.creator && _vm.expense.creator.name)+"\n        ")])]),_vm._l((_vm.expense.products),function(p){return _c('div',{key:p._id,staticClass:"col-12 mt-4"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('small',{staticClass:"text-muted"},[_vm._v("CODIGO: "+_vm._s(p.code))]),_c('div',{staticClass:"w-100"}),_vm._v("\n            "+_vm._s(p.qty)+" x "+_vm._s(p.name)+"\n          ")]),_c('div',{staticClass:"col-2"},[_vm._v("\n            "+_vm._s(p.value)+"\n          ")]),_c('div',{staticClass:"col-2"},[_c('strong',[_vm._v("$"+_vm._s(p.total))])])])])}),_c('div',{staticClass:"col-12 mt-4"},[_c('small',{staticClass:"text-muted"},[_vm._v("NOTAS")]),_c('div',{staticClass:"w-100"}),_vm._v("\n        "+_vm._s(_vm.expense.notes || " - - - - -")+"\n      ")]),_c('div',{staticClass:"col-12 mt-4"},[_c('h4',{staticClass:"text-right"},[_vm._v("$"+_vm._s(_vm._f("formatNumber")(_vm.expense.value)))])])],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"text-center py-5"},[_vm._v("No hay informacion")])])])])}]

export { render, staticRenderFns }