<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col">
        <small>ESPACIO ( {{getSize(totalUsed)}} / {{getSize(totalSpace)}} )</small>
        <div class="w-100"></div>
        <b-progress
          :value="totalUsed"
          :max="totalSpace"
          :variant="spaceVariant"
          class="mb-2"
        ></b-progress>
      </div>
      <div class="col-auto ml-auto">
        <button class="btn btn-primary px-4" @click="showModal()">SUBIR</button>
      </div>

      <div class="w-100 py-2"></div>
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" v-if="documents.length == 0">
            <h4 class="text-center py-4">No tienes documentos</h4>
          </li>
          <li class="list-group-item" v-for="doc in documents" :key="doc._id">
            <div class="row align-items-center">
              <div class="col-2">
                {{ doc.createdAt | moment("DD/MM/YYYY") }}
              </div>
              <div class="col">
                <strong>{{ doc.name }}</strong>
                <div class="w-100"></div>
                <small class="mr-4">TAMAÑO: {{ getSize(doc.size) }}</small>
                <small>TIPO: {{ doc.extname }}</small>
              </div>
              <div class="col">
                {{ doc.user && doc.user.name }}
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-outline-secondary btn-sm py-1"
                  @click="download(doc._id, doc)"
                >
                  DESCARGAR
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <b-modal title="Subir documento" ref="modal" @ok="upload()">
      <div class="row align-items-end">
        <div class="col-12">
          <small>NOMBRE</small>
          <input type="text" v-model="name" class="form-control" />
        </div>
        <div class="col-12">
          <small>ARCHIVO</small>
          <b-form-file
            :value="file"
            v-model="file"
            placeholder="Selecciona un archivo o arrastralo hasta aca..."
            drop-placeholder="Arrastra hasta aca..."
            accept=".pdf"
          ></b-form-file>
        </div>
        <div class="col-12">
          <small>TIPO</small>
          <select v-model="type" class="form-control">
            <option value="contrato">Contrato</option>
            <option value="anexo">Anexo</option>
            <option value="finiquito">Finiquito</option>
            <option value="certificado">Certificado</option>
            <option value="carta_aviso">Carta de aviso</option>
            <option value="carta_recomendacion">Carta de recomendación</option>
            <option value="carta_laboral">Carta laboral</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        <div class="col-12">
          <small>USUARIO</small>
          <select class="form-control" v-model="user">
            <option :value="user._id" v-for="user in users" :key="user._id">
              {{ user.name }}
            </option>
          </select>
        </div>
        <!-- <div class="col-auto ml-auto">
          <button class="btn btn-primary" @click="upload()">
            Subir documento
          </button>
        </div> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      documents: [],
      file: null,
      name: "",
      type: "",
      users: [],
      totalSpace: 2000000
    };
  },
  mounted() {
    this.load();
    this.loadUsers();
  },
  computed: {
    spaceVariant() {
      const {totalUsed, totalSpace} = this
      if(totalUsed == 0) return 'success'
      if(totalSpace / totalUsed > 1.5) return 'success'
      if(totalUsed > totalSpace) return 'danger'
      return 'warning'
    },
    totalUsed() {
      let total = 0;
      this.documents.forEach((doc) => {
        total += doc.size;
      });
      return total;
    }
  },
  methods: {
    getSize(size) {
      let mb = size / 1024 / 1024;
      if (mb < 1) return `${(mb * 1024).toFixed(2)} KB`;
      else return `${mb.toFixed(2)} MB`;
    },
    loadUsers() {
      this.$http
        .get("user")
        .then((res) => {
          this.users = res.data.data.filter((user) => user.active);
        })
        .catch(() => {
          this.$noty.error("No se pueden cargar los usuarios.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download(id, doc) {
      this.$http
        .get(`document/${id}/download`, { responseType: "blob" })
        .then((res) => {
          // download res.data.data
          //let contentType = res.headers['content-type']
          //let contentName = res.headers['content-disposition'].split('filename=')[1].split(';')[0]
          console.log(res.headers);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let {name, extname} = doc;

          if(!name.includes(extname)) name += extname
          link.setAttribute("download", `${name}`);
          document.body.appendChild(link);
          link.click();

          this.$bvToast.toast("Documento descargado", {
            title: "Exito",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      this.$http.get("document").then((res) => {
        this.documents = res.data.data;
      });
    },
    showModal() {
      this.$refs.modal.show();
    },
    upload() {
      if (!this.file) {
        this.$bvToast.toast("Selecciona un archivo", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("name", this.name);
      formData.append("type", this.type);

      this.$http.post("document/upload", formData).then((res) => {
        console.log(res);
        this.$bvToast.toast("Documento subido", {
          title: "Exito",
          variant: "success",
          solid: true,
        });
        this.load();
      });
    },
  },
  watch: {
    file(val) {
      if (!val) {
        this.name = "";
        return;
      }

      this.name = val.name;
    },
  },
};
</script>