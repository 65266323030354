<template>
  <div class="container-fluid py-3">
    <div class="row no-gutters">
      <div class="col">
        <h4>COLA SII</h4>
      </div>
      <div class="col-auto ml-auto">
        <router-link
          to="/admin/dte"
          class="px-2"
          exact
          exact-active-class="active text-white"
          >INTERCAMBIO</router-link
        >
        <router-link
          to="/admin/siibusiness"
          exact
          class="px-2"
          exact-active-class="active text-white"
          >EMPRESAS</router-link
        >
      </div>
      <div class="w-100"></div>
      <div class="col-12">
        <div class="row align-items-end">
          <div class="ml-auto col">
            <small class="d-block text-right pr-2" v-if="lastUpdate">
              Actualizado {{ $moment(lastUpdate).fromNow() }}
            </small>

            <div class="row no-gutters">
              <div class="col-2">
                <button
                  class="btn btn-outline-primary btn-block btn-sm"
                  :disabled="loading"
                  @click="send_email()"
                >
                  ENVIAR EMAILS SII
                  <small class="d-block">MANUAL</small>
                </button>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-outline-primary btn-block btn-sm"
                  :disabled="loading"
                  @click="get_status()"
                >
                  OBTENER STATUS
                  <small class="d-block">DTE'S</small>
                </button>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-outline-primary btn-block btn-sm"
                  :disabled="loading"
                  @click="get_status_39()"
                >
                  OBTENER STATUS
                  <small class="d-block">BOLETAS</small>
                </button>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-outline-success btn-block btn-sm"
                  :disabled="loading"
                  @click="run_queue()"
                >
                  ENVIAR
                  <small class="d-block">DTE'S</small>
                </button>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-outline-success btn-block btn-sm"
                  :disabled="loading"
                  @click="run_queue_39()"
                >
                  ENVIAR
                  <small class="d-block">BOLETAS</small>
                </button>
              </div>
              <div class="ml-auto col-2">
                <button
                  class="btn btn-success btn-block btn-sm h-100"
                  :disabled="loading"
                  @click="load()"
                >
                  <div
                    class="spinner-border spinner-border-sm text-light mb-1"
                    role="status"
                    v-if="loading"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <font-awesome-icon icon="sync" v-if="!loading" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="col-12 py-2 px-0">
          <div class="row">
            <div class="col py-0">
              <item-icon
                icon="clock"
                icon-class="text-secondary"
                title="Siguiente en cola"
                text-class="text-uppercase font-weight-bold text-center"
                :text="siiStats.next.name || '- - - - -'"
              />
            </div>
            <div class="col-4 py-0">
              <item-icon
                icon="clock"
                icon-class="text-secondary"
                title="DTEs siguiente"
                text-class="text-uppercase"
                :text="siiStats.totalNext"
              />
            </div>
            <div class="w-100"></div>
            <div class="col py-0">
              <item-icon
                icon="clock"
                icon-class="text-warning"
                title="En cola"
                :text="siiStats.inQueue"
              />
            </div>
            <div class="col py-0">
              <item-icon
                icon="clock"
                icon-class="text-primary"
                title="Esperando resultado"
                :text="siiStats.send"
              />
            </div>
            <div class="col py-0">
              <item-icon
                icon="cloud-upload-alt"
                size="2x"
                class="text-success"
                title="Enviados y procesados"
                :text="`${siiStats.today || 0}`"
                :subtitle="`TOTAL: ${siiStats.finish || 0}`"
                format
              />
            </div>

            <div class="w-100 py-0"></div>

            <div class="col py-0">
              <item-icon
                icon="cloud-upload-alt"
                size="2x"
                class="text-success1"
                title="Boletas en cola"
                :text="siiStats.dte39"
              />
            </div>

            <div class="col py-0">
              <item-icon
                icon="clock"
                size="2x"
                class="text-primary"
                title="DTE en cola"
                :text="siiStats.dte"
              />
            </div>
            <div class="col py-0">
              <item-icon
                icon="trash"
                size="2x"
                class="text-danger"
                title="Cancelados"
                :text="siiStats.cancelled"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row py-2 align-items-end">
            <div class="col">
              <small>DOCUMENTOS</small>
              <select class="form-control form-control-sm" v-model="dteType">
                <option value="all">BOLETAS / FACTURAS</option>
                <option value="33">Factura</option>
                <option value="39">Boleta</option>
              </select>
            </div>
            <div class="col">
              <small>ESTADO</small>
              <select class="form-control form-control-sm" v-model="status">
                <option value="all">TODOS</option>
                <option value="in_queue">En cola</option>
                <option value="send">ENVIADOS</option>
                <option value="finish">FINALIZADOS</option>
              </select>
            </div>
            <div class="col-2 ml-auto">
              <small class="text-muted">MOSTRAR</small>
              <select class="form-control form-control-sm" v-model="limit">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="300">300</option>
                <option value="500">500</option>
              </select>
            </div>
            <div class="col-4">
              <small class="text-muted">RESPONSE SII</small>
              <select class="form-control form-control-sm" v-model="response">
                <option value="null">Todos los estados</option>
                <option value="EPR">EPR</option>
                <option value="REC">REC</option>
                <option value="SOK">SOK</option>
                <option value="RSC">RSC</option>
                <option value="RCH">RCH</option>
              </select>
            </div>
            <div class="col">
              <small>ORDEN</small>
              <select class="form-control form-control-sm" v-model="order">
                <option value="1">Ascendente</option>
                <option value="-1">Descendente</option>
              </select>
            </div>
          </div>

          <div class="w-100 border-bottom"></div>

          <div
            class="custom_scroll"
            style="display: block; max-height: 530px; overflow-y: scroll"
          >
            <ul class="list-group px-0">
              <li class="list-group-item" v-if="siiqueue.length == 0">
                <h5 class="text-center py-4">No hay info</h5>
              </li>
              <li
                class="list-group-item list-group-item-action"
                :class="{
                  'list-group-item-warning':
                    q.business._id == next_queue._id && q.status == 'in_queue',
                  'list-group-item-primary': q.status == 'send',
                }"
                v-for="q in siiqueue"
                :key="q._id"
              >
                <div class="row no-gutters align-items-center">
                  <div
                    class="col-2 text-center font-weight-bold"
                    v-if="q.ticket"
                  >
                    <small class="d-block font-weight-bold">
                      {{ q.ticket.createdAt | moment("DD/MM/YY HH:mm:ss") }}
                    </small>
                    <small class="d-block text-muted">
                      ({{ $moment(q.ticket.createdAt).fromNow() }})
                    </small>
                    <div class="w-100"></div>
                    <span
                      class="badge px-4 py-1 border badge-pill text-uppercase"
                      :class="{
                        'border-success text-success': q.status == 'finish',
                        'border-warning text-warning': q.status == 'in_queue',
                        'border-primary': q.status == 'send',
                      }"
                    >
                      {{ q.status }}
                    </span>

                    <span
                      class="badge px-4 py-1 badge-pill text-uppercase"
                      v-if="g(q.response, 'estado')"
                      :class="{
                        'badge-success': g(q.response, 'estado') == 'EPR',
                        'badge-primary': g(q.response, 'estado') == 'REC',
                        'badge-danger':
                          g(q.response, 'estado') == 'RSC' ||
                          g(q.response, 'estado') == 'RFR',
                      }"
                    >
                      {{ g(q.response, "estado") || "" }}
                    </span>

                    <div class="w-100 mt-1"></div>

                    <small class="d-block">
                      TRACKID {{ g(q.response, "trackid") || "---------" }}
                      <!-- TRACKID: {{ q.trackid || "---------" }} -->
                    </small>
                  </div>

                  <div class="col-3 px-1 font-weight-bold">
                    <small class="d-block">
                      {{ q.business ? q.business.rut : "none" }}
                    </small>
                    <span class="text-uppercase">
                      {{ q.business ? q.business.name : "none" }}
                    </span>
                    <small class="d-block">
                      {{ q.business ? q.business.address : "none" }}
                    </small>
                  </div>

                  <div class="col font-weight-bold" v-if="q.ticket">
                    <div class="row">
                      <div class="col-12">
                        <small class="d-block">
                          {{
                            q.ticket.clientName == "ANON"
                              ? ""
                              : q.ticket.clientName
                          }}
                        </small>
                        <div v-if="q.ticket.receptor">
                          <small class="d-block">
                            {{ q.ticket.receptor.rut }}
                          </small>
                          <small class="d-block font-weight-bold">
                            {{ q.ticket.receptor.rs }}
                          </small>
                          <small class="d-block">
                            {{ q.ticket.receptor.activity }}
                          </small>
                          <small class="d-block font-italic">
                            {{ q.ticket.receptor.address }}
                          </small>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        v-for="p in q.ticket.products"
                        :key="p._id"
                      >
                        <small class="font-weight-bold">
                          {{ p.qty }} x {{ p.name }}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="col-2">
                    <small class="font-weight-bold"
                      >{{ q.ticket.dteType }} -
                      {{ getDTEName(q.ticket.dteType) }}</small
                    >
                    <div class="w-100"></div>
                    <small class="font-weight-bold">Folio </small>
                    <small class="mr-2">
                      {{ q.ticket ? q.ticket.folio : "" }}
                      {{ q.ot ? q.ot.folio : "" }}
                    </small>

                    <small class="font-weight-bold">Total </small>
                    <small>${{ formatNumber(q.ticket.total) }}</small>

                    <div class="w-100 border-bottom"></div>

                    <div class="row">
                      <div class="col-12" v-for="ev in q.events" :key="ev._id">
                        <small class="font-weight-bold">
                          {{ ev.createdAt | moment("HH:mm") }}
                        </small>
                        <small>{{ ev.name }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="ml-auto col-auto text-center btn-group">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      @click="showXML(q._id)"
                    >
                      <font-awesome-icon icon="file-excel"></font-awesome-icon>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="deleteQueue(q._id)"
                    >
                      <font-awesome-icon icon="trash"></font-awesome-icon>
                    </button>
                  </div>

                  <div class="col-12" v-if="g(q.response, 'detalle_rep_rech')">
                    <div
                      class="row mt-2 alert alert-warning align-items-center"
                      v-for="(e, i) in g(q.response, 'detalle_rep_rech')"
                      :key="i"
                    >
                      <div class="col">
                        <small class="d-block">TIPO</small>
                        <span class="font-weight-bold">{{
                          e.tipo || "---"
                        }}</span>
                      </div>
                      <div class="col">
                        <small class="d-block">FOLIO</small>
                        <span class="font-weight-bold">{{
                          e.folio || "---"
                        }}</span>
                      </div>
                      <div class="col">
                        <small class="d-block">ESTADO</small>
                        <span class="font-weight-bold">{{
                          e.estado || "---"
                        }}</span>
                      </div>
                      <div class="col-2">
                        <small class="d-block">DESCRIPCION</small>
                        <span class="font-weight-bold">{{
                          e.descripcion || "---"
                        }}</span>
                      </div>
                      <div class="col-6">
                        <small class="d-block">ERROR</small>
                        {{ e.error || "---" }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="viewxml" size="xl" title="Ver XML">
      <pre class="text-success">{{ xml }}</pre>
    </b-modal>

  </div>
</template>

<script>
import { getDTEName } from "../../utils";
import itemIcon from "../../components/itemIcon";

export default {
  components: {
    itemIcon,
  },
  data() {
    return {
      order: -1,
      status: "in_queue",
      dteType: "all",
      business: [],
      loading: true,
      lastUpdate: null,
      siiqueue: [],
      response: null,
      siiStats: {
        next: {
          name: "",
        },
      },
      timeout: null,
      userStats: {},
      limit: 10,
      logs: [],
      next_queue: {},
      cfs: [],
      xml: "",
      target: "",
    };
  },
  mounted() {
    this.load();
  },
  // destroyed() {
  //   clearTimeout(this.timeout);
  //   this.timeout = null;
  // },
  beforeDestroy() {
    clearTimeout(this.timeout);
    this.timeout = null;
  },
  computed: {
    timeProm() {
      let time = 0;
      this.siiqueue.forEach((obj) => {
        let [e1, e2] = obj.events;
        if (e1 && e2) {
          let d1 = this.$moment(e1.createdAt);
          let d2 = this.$moment(e2.createdAt);
          let min = d2.diff(d1, "seconds");
          if (time == 0) time = min;
          else time = (time + min) / 2;
        }
      });
      return (time / 60).toFixed(2);
    },
  },
  methods: {
    send_email() {
      this.$http
        .post("admin/send_email")
        .then(() => {
          this.$noty.success("Email enviado correctamente");
        })
        .catch(() => this.$noty.error("Error al enviar email"));
    },
    update() {
      console.log("update", Date.now());
      this.load();
    },
    getDTEName(id) {
      return getDTEName(id);
    },
    showXML(id) {
      //this.xml = xml;
      this.$http.get(`siiqueue/${id}`).then((res) => {
        this.xml = res.data.data.DTE;
        this.$refs.viewxml.show();
      });
      // this.$refs.viewxml.show();
    },
    deleteQueue(id) {
      this.$http
        .delete(`siiqueue/${id}`)
        .then(() => {
          this.$noty.success("Borrado correctamente");
          this.load();
        })
        .catch(() => this.$noty.error("Error al borrar"));
    },
    run_queue() {
      this.$http
        .post("run_queue")
        .then(() => {
          this.$noty.success("Cola ejecutada correctamente");
          this.load();
        })
        .catch(() => this.$noty.error("Error al ejecutar"));
    },
    run_queue_39() {
      this.$http
        .post("run_queue", {
          type: 39,
        })
        .then(() => {
          this.$noty.success("Cola ejecutada correctamente");
          this.load();
        })
        .catch(() => this.$noty.error("Error al ejecutar"));
    },
    get_status() {
      this.$http
        .post("get_status")
        .then(() => {
          this.$noty.success("Estado obtenido correctamente");
          this.load();
        })
        .catch(() => this.$noty.error("Error al obtener estado"));
    },
    get_status_39() {
      this.$http
        .post("get_status", {
          type: 39,
        })
        .then(() => {
          this.$noty.success("Estado obtenido correctamente");
          this.load();
        })
        .catch(() => this.$noty.error("Error al obtener estado"));
    },
    g(response, field) {
      try {
        return JSON.parse(response)[field];
      } catch (error) {
        return "";
      }
    },
    load() {
      if (this.lastUpdate) {
        let diff = Date.now() - this.lastUpdate;
        if (diff < 10000) {
          console.log("too fast");
          return;
        }
      }

      clearTimeout(this.timeout);
      this.loading = true;
      let query = {
        order: this.order,
      };

      //status=${this.status}&limit=${this.limit}
      if (this.response) query.status = this.response;
      query.limit = this.limit;
      if (this.dteType != "all") query.type = this.dteType;

      if (this.status != "all") query.status = this.status;

      const u = new URLSearchParams(query).toString();
      this.$http
        .get(`siiqueue?${u}`)

        .then((res) => {
          this.siiqueue = res.data.data;
          return this.$http.get(`siiqueue/stats`);
        })
        .then((res) => {
          this.siiStats = res.data.data;
          // return this.$http.get(`users/stats`);
        })
        // .then((res) => {
        //   this.userStats = res.data.data;
        //   return this.$http.get(`siiqueue/next`);
        //  })
        // .then((res) => {
        //   this.next_queue = res.data.data;
        // })
        .catch((err) => {
          if (err.code == 404) {
            clearTimeout(this.timeout);
            this.timeout = false;
          }
          // console.log("Error admin", err);
          // this.$noty.error("Error al obtener datos");
        })
        .finally(() => {
          //console.log({ a });
          this.loading = false;
          // if (typeof this.timeout != "boolean")
          // this.timeout = setTimeout(() => {
          //   this.load();
          // }, 20000);
        });
    },
  },
  watch: {
    dteType: function () {
      this.load();
    },
    status: function () {
      this.load();
    },
    limit: function () {
      this.load();
    },
  },
};
</script>