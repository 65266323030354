<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get("notification")
        .then((res) => {
          this.$store.commit("setNotifications", res.data.data);
        })
        .finally(() => {
          this.loading = false;
        });
      event.stopPropagation();
    },
    makeRead({ users, _id }) {
      if (this.isRead(users)) return console.log("Ya leida");
      this.loading = true;
      this.$http
        .post(`notification/${_id}`)
        .then(() => {
          return this.$http.get("notification");
        })
        .then((res) => {
          this.$store.commit("setNotifications", res.data.data);
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
      event.stopPropagation();
    },
    isRead(users) {
      let uid = this.$store.state.user._id;
      let u = users.find((u) => u.user == uid);
      if (u) return true;
      else return false;
    },
  },
  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
    notificationsUnread() {
      return this.notifications.reduce((t, c) => {
        if (!this.isRead(c.users)) t = t + 1;
        return t;
      }, 0);
    },
  },
};
</script>
<template>
  <div class="dropdown" role="group">
    <button
      class="btn btn-link btn-sm"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <font-awesome-icon icon="bell" class="text-light" size="1x" />
      <div class="badge badge-danger" v-if="notificationsUnread != 0">
        {{ notificationsUnread }}
      </div>
    </button>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdownMenuButton"
      style="width: 400px; max-height: 450px; overflow-y: scroll"
    >
      <div class="dropdown-item border-bottom" click="$event.stopPropagation()">
        <div class="row">
          <div class="col">
            <span class="d-block text-center text-uppercase">
              Notificaciones
            </span>
          </div>
          <div class="col-2">
            <button class="btn btn-sm" @click="load()" :disabled="loading">
              <font-awesome-icon icon="redo"></font-awesome-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="dropdown-item" v-if="notifications.length == 0">
        <div class="row">
          <div class="col">
            <small class="text-muted d-none"> Hace 2 minutos </small>
            <span class="d-block text-center"> No tienes notificaciones </span>
          </div>
        </div>
      </div>

      <b-overlay :show="loading">
        <div
          class="dropdown-item border-bottom pt-2"
          v-for="n in notifications"
          :key="n._id"
          @click="makeRead(n)"
        >
          <div class="row align-items-center position-relative">
            <div class="" style="position: absolute; top: 0; right: 0px">
              <button
                class="btn btn-sm btn-link d-none1"
                @click="makeRead(n._id)"
                :disabled="isRead(n.users)"
              >
                <font-awesome-icon
                  icon="check-circle"
                  :class="{
                    'text-muted': isRead(n.users),
                    'text-dark': !isRead(n.users),
                  }"
                />
              </button>
            </div>
            <div class="col-11">
              <h6 class="mb-0">
                <font-awesome-icon
                  :icon="isRead(n.users) ? 'dot-circle' : 'circle'"
                  size="1x"
                  class="mr-1"
                  :class="{
                    'text-danger ': n.priority == 3,
                    'text-primary': n.priority == 2,
                    'text-primary ': n.priority == 1,
                    'text-secondary ': n.priority == 0,
                  }"
                />
                {{ n.title }}
              </h6>
              <small class="text-muted">
                {{ $moment(n.createdAt).fromNow() }}
              </small>
              <div class="w-100"></div>
              <small style="white-space: pre-wrap">{{ n.content }}</small>
            </div>
            <div class="ml-auto float-right">
              <button class="btn btn-sm btn-link" v-if="false">Eliminar</button>
              <button
                class="btn btn-sm btn-link"
                v-for="(ac, i) in n.buttons"
                :key="i"
                @click="doAction(ac.action)"
              >
                {{ ac.title }}
              </button>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>