<template>
  <div class="row justify-content-md-center">
    <div class="col-12 col-md-6">
      <h3 class="text-center">ABRIR CAJA</h3>
      <table class="table">
        <tr>
          <th class="text-right">Cajero</th>
          <td>{{ user.name }}</td>
        </tr>
        <tr>
          <th class="text-right">Fecha</th>
          <td>{{ date | moment("DD-MM-YYYY - HH:mm") }}</td>
        </tr>
        <tr>
          <th class="text-right">Monto</th>
          <td>
            <input
              type="text"
              :class="{
                'is-invalid': amount <= 0,
                'is-valid': amount > 0
              }"
              @focus="$event.target.select()"
              class="form-control input-sm"
              v-model.number="amount"
              placeholder="Ej: 20000 (Sin puntos)"
              @keyup.enter="openCash()"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button
              class="btn btn-success btn-block"
              @click="openCash()"
              :disabled="amount <= 0 || loading"
            >
              Aceptar
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(),
      amount: 0,
      loading: false
    };
  },
  methods: {
    openCash() {
      if (this.amount <= 0)
        return this.$noty.error("Debe ingresar un monto valido!");
      this.loading = true;
      this.$http
        .post(
          "cash/open",
          { amount: this.amount }
        )
        .then(() => {
          this.$emit("openSuccess");
          this.$noty.success("Caja abierta!");
        })
        .catch(() => {
          this.$noty.error("Error al abrir la caja, intente nuevamente");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    amountState() {
      return this.amount != null;
    },
      user() {
      return this.$store.state.user;
    }
  },
  watch: {
    amount: function () {
      //this.amount = parseInt(val) || 0;
    },
  },
};
</script>