<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row py-2 align-items-center">
          <div class="col">
            <h4 class="mt-3">EMPRESAS {{ business.length }}</h4>
          </div>

          <div class="col px-0">
            <input
              type="text"
              v-model="businessFilter"
              class="form-control form-control-sm mt-3"
              placeholder="Buscar..."
            />
          </div>

          <div class="col-2">
            <button
              v-b-modal="'modal-add-business'"
              class="btn btn-outline-primary mt-3 btn-block btn-sm"
            >
              CREAR
            </button>
          </div>

          <div class="col-3">
            <small class="text-muted">ESTADO</small>
            <select class="form-control form-control-sm" v-model="benabled">
              <option value="">Todos los estados</option>
              <option value="1">Habilitada</option>
              <option value="0">Deshabilitada</option>
            </select>
          </div>
          <div class="col-1">
            <button
              class="btn btn-outline-success btn-block h-100"
              @click="load()"
            >
              <font-awesome-icon icon="redo" />
            </button>
          </div>
        </div>

        <div class="w-100"></div>
        <div class="custom_scroll" style="max-height: 65vh; overflow-y: scroll">
          <ul class="list-group">
            <li
              class="list-group-item list-group-item-action"
              v-for="b in businessList"
              :key="b._id"
              :class="{
                'list-group-item-danger': !b.enabled,
                'list-group-item-warning':
                  b.beEnabled && b.siiAmbient == 'CERT',
                active: b._id == bs._id,
              }"
              style="cursor: pointer"
              @click="loadbs(b)"
            >
              <div class="row align-items-center no-gutters">
                <div class="col-auto text-center1">
                  <img
                    :src="getLogo(b.logo)"
                    alt="Logo empresa"
                    class="img-fluid mx-2"
                    style="max-height: 90px; width: 60px"
                    v-if="b.logo"
                  />
                  <img
                    src="https://ticketapp.cl/logo.png"
                    class="img-fluid mx-2"
                    v-else
                    style="max-width: 60px; max-height: 80px"
                  />
                </div>
                <div class="col-4 pl-1">
                  <small class="">ID: {{ b._id }}</small>
                  <div class="w-100"></div>
                  <small class="font-weight-bold">{{ formatRUT(b.rut) }}</small>
                  <small class="ml-4">{{
                    b.createdAt | moment("DD/MM/YY")
                  }}</small>
                  <small class="ml-4">{{ b.custom_name }}</small>

                  <div class="w-100"></div>
                  <span class="d-block font-weight-bold">{{ b.name }}</span>
                  <small class="">{{ b.address }}</small>
                  <small class="ml-4">{{ b.phone || "" }}</small>
                </div>

                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.beEnabled,
                          'badge-danger': !b.beEnabled,
                        }"
                      >
                        FACTURACION
                        <div
                          class="badge mx-1"
                          v-if="b.beEnabled"
                          :class="{
                            'badge-warning': b.siiAmbient == 'CERT',
                            'badge-light': b.siiAmbient == 'PROD',
                          }"
                        >
                          {{ b.siiAmbient }}
                        </div>
                      </div>

                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.deliveryEnabled,
                          'badge-danger': !b.deliveryEnabled,
                        }"
                      >
                        DELIVERY
                      </div>

                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.otEnabled,
                          'badge-danger': !b.otEnabled,
                        }"
                      >
                        OT
                      </div>

                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.webEnabled,
                          'badge-danger': !b.webEnabled,
                        }"
                      >
                        WEB
                      </div>

                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.sumupEnabled,
                          'badge-danger': !b.sumupEnabled,
                        }"
                      >
                        SUMUP
                      </div>

                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.remotePrintEnabled,
                          'badge-danger': !b.remotePrintEnabled,
                        }"
                      >
                        PRINTER
                      </div>

                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.restaurantEnabled,
                          'badge-danger': !b.restaurantEnabled,
                        }"
                      >
                        RESTAURANT
                      </div>
                      <div
                        class="badge mx-1"
                        :class="{
                          'badge-success': b.contractsEnabled,
                          'badge-danger': !b.contractsEnabled,
                        }"
                      >
                        CONTRATOS
                      </div>
                    </div>

                    <div class="w-100 py-2"></div>

                    <div class="col-5" v-if="b.cert">
                      <small class="d-block">
                        <strong>CERT:</strong> {{ b.cert.subjectName }}
                      </small>
                      <small class="d-block">
                        <strong> EXPIRE:</strong>
                        {{ b.cert.expireDate | moment("DD/MM/YYYY") }} ({{
                          $moment(b.cert.expireDate).fromNow()
                        }})
                      </small>
                      <!-- <small class="text-muted ">{{ b.cert.filename }}</small>
                    <div class="w-100"></div>
                    <small class="text-muted">{{ b.cert.password }}</small> -->
                    </div>

                    <div class="col">
                      <div class="" v-if="b.cafs">
                        <div
                          class="row align-items-center border-bottom"
                          v-for="caf in b.cafs"
                          :key="`caf-${caf._id}`"
                        >
                          <div class="col-auto overflow-hidden">
                            <!-- <small>{{ caf.filename }} |s</small> -->
                            <div class="w-100"></div>
                            <small class="font-weight-bold mr-3"
                              >{{ caf.type }} -
                              {{ getDTEName(caf.type) }}</small
                            >
                            <small class="font-weight-bold"
                              >{{ caf.current }} / {{ caf.end }}</small
                            >
                          </div>
                          <div class="ml-auto col-2 text-center px-0">
                            <small class="font-weight-bold">{{
                              caf.end - caf.current
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal-edit-business"
      size="xl"
      style="max-width: 90% !important"
      :title="`EDITAR EMPRESA ${bs._id}`"
      scrollable
      @hide="closeModal()"
    >
      <div class="row align-items-center">
        <div class="col-3 text-center">
          <img
            :src="getLogo(bs.logo)"
            alt="Logo empresa"
            class="img-fluid"
            style="max-height: 150px"
            v-if="bs.logo"
          />
          <img
            src="https://ticketapp.cl/logo.png"
            class="img-fluid"
            v-else
            style="width: auto; max-width: 100px; max-height: 80px"
          />
        </div>
        <div class="col-9">
          <h4 class="d-inline-block">{{ bs.name }}</h4>
          <div class="w-100"></div>
          <span class="d-block font-weight-bold">
            {{ bs.giro || "" }}
          </span>
          <span class="d-block font-weight-bold">
            RUT: {{ bs.rut || "" }}
          </span>
          <span class="d-block font-weight-bold">
            Direccion: {{ bs.address || "" }}
          </span>
          <span class="d-block font-weight-bold">
            Ciudad: {{ bs.city || "" }}
          </span>
          <span class="d-block font-weight-bold">
            Telefono: {{ bs.phone || "Sin telefono" }}
          </span>
          <span class="d-block font-weight-bold">
            Creado: {{ bs.createdAt | moment("DD/MM/YYYY HH:mm") }}
          </span>
        </div>

        <div class="w-100 border-bottom my-4"></div>
        <div class="col-12">
          <b-tabs fill nav-class="font-weight-bold text-uppercase">
            <b-tab title="General">
              <div class="row p-4">
                <div class="col">
                  <small>HABILITAR EMPRESA</small>
                  <b-checkbox v-model="bs.enabled" switch @change="toggleBusiness(bs)" />
                </div>
                <div class="w-100"></div>
                <div class="col-2">
                  <small class="font-weight-bold">DELIVERY</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.deliveryEnabled"
                    @change="enableMod('deliveryEnabled', bs.deliveryEnabled)"
                    switch
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">SUMUP</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.sumupEnabled"
                    @change="enableMod('sumupEnabled', bs.sumupEnabled)"
                    switch
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">FACTURACION</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.beEnabled"
                    switch
                    @change="enableMod('beEnabled', bs.beEnabled)"
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">COTIZACIONES</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.quoteEnabled"
                    switch
                    @change="enableMod('quoteEnabled', bs.quoteEnabled)"
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">WEB</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.webEnabled"
                    switch
                    @change="enableMod('webEnabled', bs.webEnabled)"
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">OT</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.otEnabled"
                    switch
                    @change="enableMod('otEnabled', bs.otEnabled)"
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">IMPRESORA REMOTA</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.remotePrintEnabled"
                    switch
                    @change="
                      enableMod('remotePrintEnabled', bs.remotePrintEnabled)
                    "
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">RESTAURANT</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.restaurantEnabled"
                    switch
                    @change="
                      enableMod('restaurantEnabled', bs.restaurantEnabled)
                    "
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">REDES SOCIALES</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.socialNetworkEnabled"
                    switch
                    @change="
                      enableMod('socialNetworkEnabled', bs.socialNetworkEnabled)
                    "
                  />
                </div>
                <div class="col-2">
                  <small class="font-weight-bold">CONTRATOS</small>
                  <b-checkbox
                    size="md"
                    v-model="bs.contractsEnabled"
                    switch
                    @change="
                      enableMod('contractsEnabled', bs.contractsEnabled)
                    "
                  />
                </div>
              </div>
            </b-tab>
            <b-tab title="Usuarios">
              <ul class="list-group">
                <li
                  class="list-group-item list-group-item-action click"
                  v-for="user in users"
                  :key="user._id"
                  :class="{
                    'list-group-item-danger': !user.active,
                  }"
                >
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="d-block font-weight-bold">{{
                        user.name
                      }}</span>
                      <small>{{ user.email }}</small>
                    </div>
                    <div class="col-2 text-center">
                      <small class="text-muted">ROL</small>
                      <div class="w-100"></div>
                      <small class="d-block font-weight-bold text-uppercase">{{
                        user.role
                      }}</small>
                    </div>
                    <div class="col-2 text-center">
                      <small class="text-muted">CREADO</small>
                      <div class="w-100"></div>
                      <small class="d-block font-weight-bold">
                        {{ user.createdAt | moment("DD/MM/YY") }}
                      </small>
                    </div>
                    <div class="col">
                      <small class="text-muted">ULTIMA VEZ</small>
                      <div class="w-100"></div>
                      <small
                        class="d-block font-weight-bold"
                        v-if="user.lastConnection"
                      >
                        {{ user.lastConnection | moment("DD/MM/YY HH:mm") }}
                      </small>
                      <small class="d-block font-weight-bold" v-else
                        >NUNCA</small
                      >
                    </div>
                    <div class="col-1">
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        v-b-modal="'modal-edit-password'"
                      >
                        <font-awesome-icon icon="key"></font-awesome-icon>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Productos" v-if="false">
              <ul class="list-group" v-for="p in products" :key="p._id">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="d-block font-weight-bold">{{ p.name }}</span>
                      <small>{{ p.code }}</small>
                    </div>
                    <div class="col"></div>
                    <div class="col">
                      <span class="d-block font-weight-bold">
                        {{ p.createdAt | moment("DD/MM/YY") }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>
            <b-tab title="Categorias" v-if="false">
              <ul class="list-group">
                <li
                  class="list-group-item"
                  v-for="cat in categories"
                  :key="cat._id"
                  :class="{ 'list-group-item-danger': cat.deletedAt }"
                >
                  <div class="row align-items-center">
                    <div class="col-5 font-weight-bold">
                      {{ cat.name }}
                    </div>
                    <div class="col">
                      <small class="d-block">CREADO</small>
                      {{ cat.createdAt | moment("DD/MM/YY") }}
                    </div>
                    <div class="col">
                      <small class="d-block">ELIMINADO</small>
                      <span v-if="cat.deletedAt">{{
                        cat.deletedAt | moment("DD/MM/YY")
                      }}</span>
                      <span v-else>--/--/--</span>
                    </div>
                    <div class="col-2">
                      <button
                        class="btn btn-danger btn-block"
                        @click="deleteCategory(cat._id)"
                      >
                        <font-awesome-icon icon="trash" />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>

    <b-modal
      :id="'modal-add-business'"
      size="xl"
      style="max-width: 90% !important"
      title="AGREGAR NUEVA EMPRESA"
      @ok="saveBusiness()"
    >
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <label class="font-weight-bold">Nombre Usuario</label>
              <input type="text" class="form-control" v-model="name" />
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Correo</label>
              <input type="text" class="form-control" v-model="email" />
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Contraseña</label>
              <input type="text" class="form-control" v-model="password" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <label class="font-weight-bold">Nombre Empresa</label>
              <input type="text" class="form-control" v-model="bName" />
            </div>
            <div class="col-12">
              <label class="font-weight-bold">RUT</label>
              <input type="text" class="form-control" v-model="rut" />
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Giro</label>
              <input type="text" class="form-control" v-model="giro" />
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Direccion</label>
              <input type="text" class="form-control" v-model="address" />
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Telefono</label>
              <input type="text" class="form-control" v-model="phone" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getDTEName, formatRUT } from "../../utils";
export default {
  data() {
    return {
      businessFilter: "",
      benabled: 1,
      bs: {
        createdAt: Date.now(),
      },
      users: [],
      categories: [],
      name: "",
      email: "",
      password: "",
      bName: "",
      rut: "",
      giro: "",
      address: "",
      phone: "",
    };
  },
  mounted() {
    this.load();
    this.check();
  },
  computed: {
    business() {
      return this.$store.state.businesses;
    },
    products() {
      return [];
    },
    businessList() {
      if (this.businessFilter == "") return this.business;
      else {
        return this.business.filter((o) => {
          let bName = o.name.toLowerCase();
          let customName = o.custom_name.toLowerCase();
          let filterName = this.businessFilter.toLowerCase();

          if (bName.indexOf(filterName) != -1) return o;
          if (customName.indexOf(filterName) != -1) return o;
          if (o.rut && o.rut.indexOf(filterName) != -1) return o;
          if (o.phone && o.phone.indexOf(filterName) != -1) return o;
        });
      }
    },
  },
  methods: {
    closeModal() {
      console.log("close modal");
      this.$router.push({ query: {} });
    },
    enableMod(mod, status) {
      this.$http
        .post(`business/${this.bs._id}/mod`, {
          mod,
          status,
        })
        .then(() => {
          this.load();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    check() {
      if (this.$route.query.id) {
        let business = this.businessList.find((b) => {
          return b._id == this.$route.query.id;
        });
        this.bs = business;
        setTimeout(() => {
          this.$refs["modal-edit-business"].show();
        }, 500);
      }
    },
    loadbs(b) {
      this.$router.push({
        query: {
          id: b._id,
        },
      });
    },
    toggleBusiness(b) {
      this.$http
        .put(`business/${b._id}`, { enabled: b.enabled })
        .then(() => {
          this.load();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatRUT(rut) {
      return formatRUT(rut);
    },
    getDTEName(id) {
      return getDTEName(id);
    },
    load() {
      this.$http.get(`business?enabled=${this.benabled}`).then((res) => {
        this.$store.commit("setBusinesses", res.data.data);
      });
    },
    getBusinessData() {
      this.$http.get(`business/${this.bs._id}/users`).then((res) => {
        this.users = res.data.data;
      });
      // this.$http.get(`business/${this.bs._id}/categories`).then((res) => {
      //   this.categories = res.data.data;
      // });
    },
    deleteCategory(catid) {
      this.$http
        .delete(`category/${catid}`)
        .then(() => this.getBusinessData())
        .catch(() => this.$noty.error("Error al borrar"));
    },
    saveBusiness() {
      this.$http
        .post("business", {
          name: this.name,
          email: this.email,
          password: this.password,
          bName: this.bName,
          rut: this.rut,
          giro: this.giro,
          address: this.address,
          phone: this.phone,
        })
        .then(() => {
          this.$noty.success("Agregado correctamente");
        })
        .catch(() => {
          // console.log({ err });
          this.$noty.error("Error al agregar empresa");
        });
    },
  },
  watch: {
    $route: function () {
      console.log("change route");
      this.check();
    },
    benabled() {
      this.load();
    },
    bs: function () {
      this.getBusinessData();
    },
  },
};
</script>