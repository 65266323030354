<template>
  <div class="container-fluid container-app">
    <div class="row align-items-center">
      <div class="col">
        <h2>
          Mesas
          <small class="">({{ tablesTotal || 0 }})</small>
        </h2>
        <div class="w-100"></div>
        <small class="text-muted">MOSTRANDO {{ tables.length }}</small>
      </div>
      <div class="col-12 d-none1">
        <div class="row align-items-end no-gutters">
          <div class="col-md d-none">
            <small>BUSCAR | PRESIONE ENTER</small>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="search" />
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                v-model="fakeFilter"
                placeholder="Nombre, direccion, telefono, numero #123. Luego enter."
                @keyup.enter="filter = fakeFilter"
              />
            </div>
          </div>
          <div class="col-md-2 col-6 d-none">
            <div class="w-100 d-md-none d-sm-block py-1"></div>
            <div class="row no-gutters">
              <div class="col-6 d-none d-md-block">
                <button
                  class="btn btn-block"
                  @click="dateSubs()"
                  :disabled="loading"
                >
                  <small>ANT.</small>
                </button>
              </div>

              <div class="col-6 d-none d-md-block">
                <button
                  class="btn btn-block"
                  @click="dateAdd()"
                  :disabled="loading"
                >
                  <small>SIG.</small>
                </button>
              </div>
              <div class="w-100"></div>
              <div class="col">
                <date-picker
                  valueType="format"
                  v-model="dateFilter"
                  input-class=" form-control text-center"
                />
              </div>
            </div>
          </div>
          <div class="col-md-1 col-3"  v-if="user.role == 'admin'">
            <button
              class="btn btn-block btn-info rounded"
              @click="showConfig()"
             
              :disabled="loading"
            >
              <font-awesome-icon icon="cog" />
            </button>
          </div>
          <div class="col-md-1 col-3" v-if="config.reserveEnabled">
            <button
              class="btn btn-block btn-secondary rounded"
              @click="showReserves()"
              :disabled="loading"
            >
              <font-awesome-icon icon="calendar-alt" v-if="!loading" />
            </button>
          </div>
          <div class="col-md-1 col-3 d-none">
            <button
              class="btn btn-block btn-success rounded"
              @click="load()"
              :disabled="loading"
            >
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
                v-if="loading"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="redo" v-if="!loading" />
            </button>
          </div>
          <div class="col-md-1 col-3"  v-if="user.admin">
            <button
              class="btn btn-block btn-primary rounded"
              @click="addLocation()"
              :disabled="loading"
            >
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
                v-if="loading"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="plus" v-if="!loading" />
            </button>
          </div>
        </div>
      </div>

      <b-modal
        ref="modalLocation"
        title="Agregar ubicacion"
        size="md"
        hide-footer
      >
        <label for="">Ubicacion</label>
        <v-select
          v-model="table.location"
          class="py-1"
          :class="{
            'border border-danger': !table.location,
          }"
          append-to-body
          taggable
          :reduce="(country) => country.label || country"
          :options="getLocations"
        ></v-select>
        <small class="text-muted">Ej: Piso 1, Salon, etc</small>

        <div class="w-100 py-2"></div>

        <b-tabs justified>
          <b-tab title="Unica">
            <div class="form-group">
              <label for="">Nombre</label>
              <input type="text" class="form-control" v-model="table.name" />
              <small class="text-muted">Ej: Mesa 1</small>
            </div>
            <button @click="saveTable" class="btn btn-primary btn-block">
              Guardar
            </button>
          </b-tab>

          <b-tab title="Multiple">
            <div class="form-group">
              <label for="">Tag</label>
              <input type="text" class="form-control" v-model="tag" />
              <small class="text-muted">Ej: Mesa #</small>
            </div>
            <div class="row">
              <div class="col">
                <label for="">Inicial</label>
                <input type="number" class="form-control" v-model="start" />
                <small class="text-muted">Ej: 1</small>
              </div>
              <div class="col">
                <label for="">Final</label>
                <input type="number" class="form-control" v-model="end" />
                <small class="text-muted">Ej: 10</small>
              </div>
              <div class="col-12 mt-2">
                <button
                  @click="saveTableMultiple"
                  class="btn btn-primary btn-block"
                >
                  Guardar
                </button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-modal>

      <div class="w-100 py-4"></div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-tabs
          pills
          nav-wrapper-class="w-20 text-uppercase"
          active-nav-item-class="font-weight-bold"
          content-class="bgwhite p-4 mr-3"
        >
          <b-tab v-for="loc in tables" :key="loc._id">
            <template #title>
              <div class="row">
                <div class="col">
                  {{ `${loc._id}` }}
                  <span class="badge badge-info mx-2">
                    {{ `${getAvailable(loc)}/${loc.tables.length}` }}
                  </span>
                </div>
              </div>
            </template>
            <div class="row align-items-center">
              <div class="col">
                <h4 class="text-uppercase">{{ loc._id }}</h4>
              </div>

              <div class="col-auto text-uppercase">
                <small>{{ getPeople(loc) }} personas</small>
              </div>
              <div class="col-auto text-uppercase">
                <small>{{ getAvailable(loc) }} disponibles</small>
              </div>
              <div class="col-auto text-uppercase">
                <small> {{ loc.tables.length }} mesas</small>
              </div>
              <div class="col-auto d-none">
                <button class="btn btn-dark px-3" @click="editTable(loc._id)">
                  <small> editar</small>
                </button>
              </div>
              <div class="col-12">
                <div class="w-100 py-2"></div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 col-md-6 col-lg-3 col-xl-3 mb-1 p-1"
                v-for="table in loc.tables"
                :key="table._id"
              >
                <div
                  class="rounded position-relative h-100"
                  :class="{
                    'bg-danger text-white': table.status == 'occupied' && table.user._id != user._id,
                    'bg-success text-white': !table.user,
                    'bg-primary text-white':
                      table.user && table.user._id == user._id,
                  }"
                  @click="showTable(table._id)"
                  :style="{
                    cursor:
                      table.status == 'occupied' && table.user._id != user._id
                        ? 'not-allowed'
                        : 'pointer',
                  }"
                >
                  <div class="card-body p-2 px-2">
                    <div class="row">
                      <div class="col-12">
                        <span class="text-uppercase">{{ table.name }}</span>
                      </div>
                      <div class="col-12">
                        <font-awesome-icon
                          icon="user-circle"
                          class="mx-1"
                          size="sm"
                        ></font-awesome-icon>
                        <small class="text-uppercase mx-2">{{
                          (table.user && table.user.name) || "- - - - -"
                        }}</small>
                      </div>
                      <div class="col">
                        <font-awesome-icon
                          icon="users"
                          class="mx-1"
                          size="sm"
                        ></font-awesome-icon>
                        <small class="text-uppercase mx-2">{{
                          table.people || 0
                        }}</small>
                      </div>
                      <div class="col">
                        <font-awesome-icon
                          icon="cubes"
                          class="mx-1"
                          size="sm"
                        ></font-awesome-icon>
                        <small class="text-uppercase mx-2">{{
                          table.products || 0
                        }}</small>
                      </div>
                      <div class="col-md-5">
                        <font-awesome-icon
                          icon="clock"
                          class="mx-1"
                          size="sm"
                        ></font-awesome-icon>
                        <small class="text-uppercase mx-2">{{
                          table.startAt || "- - - -" | moment("HH:mm A")
                        }}</small>
                      </div>
                      <div
                        class="col-12"
                        v-for="reserve in table.reserves"
                        :key="reserve._id"
                      >
                        <font-awesome-icon
                          icon="calendar-alt"
                          class="mx-1"
                          size="sm"
                        ></font-awesome-icon>
                        <small class="text-uppercase mx-2">{{
                          reserve.date | moment("DD/MM HH:mm A")
                        }}</small>
                        <small class="text-uppercase mx-2">{{
                          reserve.name
                        }}</small>
                      </div>
                    </div>

                    <!-- <small
                      class="position-absolute text-uppercase"
                      style="top: 5px; right: 10px"
                      >{{ getStatus(table.status) }}
                      <span
                        style="
                          margin: 0px 3px;
                          width: 10px;
                          height: 10px;
                          display: inline-block;
                          border-radius: 100%;
                        "
                        :class="{
                          'bg-success': table.status == 'available',
                          'bg-warning': table.status == 'reserved',
                          'bg-danger': table.status == 'occupied',
                        }"
                      ></span> 
                    </small>-->
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="col-10">
        <div class="row">
          <div class="col-12 py-5 text-center" v-if="tables.length == 0">
            <h4 class="text-center">No tienes mesas</h4>
            <button
              class="btn btn-outline-success btn-sm px-5"
              @click="addLocation()"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>
          <div
            class="col-12 col-md-4 px-0"
            v-for="table in []"
            :key="`table-${table.number}`"
            @click="showTable(table._id)"
          >
            <div
              class="border p-3 m-1 bgwhite rounded click position-relative shadow-sm"
            >
              <strong class="h4 text-uppercase"
                >#{{ table.number }} - {{ getStatus(table.status) }}</strong
              >
              <div
                class="badge position-absolute"
                style="right: 20px"
                :class="{
                  'badge-success': table.status === 'open',
                  'badge-danger': table.status === 'close',
                  'badge-warning': table.status === 'pending',
                }"
              >
                <span style="width: 10px; height: 10px" class="d-block"></span>
              </div>
              <div class="w-100"></div>
              <font-awesome-icon
                icon="user-circle"
                class="mx-1"
              ></font-awesome-icon>
              <small class="text-uppercase mx-2">{{
                table.user || "- - - - -"
              }}</small>
              <div class="w-100"></div>
              <font-awesome-icon icon="cube" class="mx-1"></font-awesome-icon>
              <small class="text-uppercase mx-2">{{
                table.products.length
              }}</small>
              <div class="w-100"></div>
              <font-awesome-icon icon="clock" class="mx-1"></font-awesome-icon>
              <small class="text-uppercase mx-2">{{
                table.startedAt || "--:--" | moment("HH:mm")
              }}</small>
              <small class="text-uppercase font-italic" v-if="table.startedAt"
                >({{ table.startedAt || "" | moment("from") }})</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="reserves" title="Reservas" size="lg" hide-footer>
      <reserves />
    </b-modal>

    <b-modal ref="history" title="Historial" size="lg" hide-footer>
      
    </b-modal>

    <b-modal
      ref="config-modal"
      title="Configuracion"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <config @confirm="hideConfig" />
    </b-modal>
  </div>
</template>

<script>
import productOption from "../pdv/productOption";
import reserves from "./reserves";
import config from "./config";
//import bson from "bson";

export default {
  components: {
    productOption,
    reserves,
    config,
  },
  name: "tableIndex",
  watch: {
    selectedCategory() {
      setTimeout(() => {
        this.$refs.products.scrollTop = 0;
      }, 300);
    },
  },
  mounted() {
    if (!this.hasPermission(this.user, "tables")) return this.$router.push("/");

    this.loadTables();
    this.$store.dispatch("getProducts");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getTablesConfig");

    if (this.$route.query.table) {
      this.showTable(this.$route.query.table);
    }
  },
  data() {
    return {
      reserves: [],
      tag: "Mesa #",
      start: 1,
      end: 1,
      editName: false,
      printMode: 1,
      type: 1,
      progress: 10,
      selectedCategory: null,
      loadingTable: false,
      location: {
        name: "",
        tables: [],
      },
      table: {
        name: "Mesa #",
        min: 0,
        max: 0,
        location: "",
        products: [],
      },
      locations: [],
      dateFilter: null,
      fakeFilter: "",
      filter: "",
      loading: false,
      product: {},
      // tables: [
      //   // { products: [], status: "open", number: 1 },
      //   // { products: [], status: "open", number: 2 },
      //   // {
      //   //   products: [],
      //   //   status: "pending",
      //   //   number: 3,
      //   //   user: "admin",
      //   //   startedAt: Date.now(),
      //   // },
      //   // { products: [], status: "close", number: 4 },
      //   // { products: [], status: "open", number: 5 },
      // ],
    };
  },
  computed: {
    config() {
      return this.$store.state.tablesConfig;
    },
    tables() {
      return this.$store.state.tables;
    },
    tablesTotal() {
      return this.tables.reduce((a, b) => {
        return a + b.tables.length;
      }, 0);
    },
    getLocations() {
      if (!this.tables) return [];
      return this.tables.map((o) => ({
        label: `${o._id}`,
      }));
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    editTable() {
      
    },
    hideConfig() {
      this.$refs["config-modal"].hide();
    },
    showConfig() {
      this.$refs["config-modal"].show();
    },
    showReserves() {
      this.$refs["reserves"].show();
    },
    getPeople(loc) {
      return loc.tables.reduce((a, b) => {
        return a + (b.people || 0);
      }, 0);
    },
    getAvailable(loc) {
      return loc.tables.filter((t) => t.status == "available").length;
    },

    addLocation() {
      this.$refs["modalLocation"].show();

      setTimeout(() => {
        this.$refs["locationInput"].focus();
      }, 500);
    },
    saveTableMultiple() {
      this.$http
        .post("table/multiple", {
          tag: this.tag,
          start: this.start,
          end: this.end,
          location: this.table.location,
          min: 0,
          max: 0,
        })
        .then(() => {
          this.load();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveTable() {
      this.$http
        .post("table", this.table)
        .then(() => {
          this.load();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      this.loadTables();
    },
    loadTables() {
      this.$store.dispatch("getTables");
    },

    removeProduct(product, index) {
      this.table.products.splice(index, 1);
    },
    removeOption({ product, index }) {
      product.newOptions.splice(index, 1);
    },
    showOptions(product) {
      this.$refs["options"].show();
      this.product = product;
    },
    async addProduct(product) {
      let p = JSON.parse(JSON.stringify(product || this.product));
      // reset qty
      product.qty = 1;
      // delete p._id;
      delete p._id;
      p.createdAt = Date.now();

      if (p.newOptions && p.newOptions.length > 0) return this.showOptions(p);

      if (!p.options) p.options = [];
      if (!p.name) return this.$noty.error("Debe seleccionar un producto");
      //p._id = await bson.ObjectId()
      // let options = p.newOptions.map((o) => {
      //   console.log(o.ref);
      //   let values = o.ref.options.map((v) => {
      //     return {
      //       name: v._id.name,
      //       value: v._id.value,
      //     };
      //   });

      //   return {
      //     ...o,
      //     values,
      //     selected: o.selected ? o.selected : null,
      //   };
      // });
      //return false;
      this.pushProduct(p);
    },
    pushProduct(pr) {
      let p = pr || this.product;
      //this.table.products.unshift();
      this.$http
        .post(`table/${this.table._id}/product`, {
          product: p,
        })
        .then((res) => {
          this.table = res.data.data;
          this.product = {};
          this.$noty.success("Producto agregado");
          //this.updateTable();
        })
        .catch((err) => {
          console.log(err);
          this.$noty.error("Error al agregar producto");
        });
      //this.updateTable();
    },
    showTable(id) {
      //this.$refs.modal.show();
      this.editName = false;
      //this.loading = true;
      // this.table = {
      //   name: "",
      //   products: [],
      // };

      console.log('tables', this.tables)

      const table = this.tables.flatMap((t) => t.tables).find((t) => t._id == id);

      if(!table) {
        this.$store.dispatch("getTables");
        return this.$noty.error("Mesa no encontrada");
      }

      if(table.status == 'occupied' && table.user._id != this.user._id && this.user.role != 'admin') {
        return this.$noty.error("La mesa ya esta ocupada");
      }

      this.$router.push("/restaurant/" + id);

      // this.$http
      //   .get(`table/${id}`)
      //   .then((res) => {
      //     const table = res.data.data;

      //     if (
      //       table.status !== "available" &&
      //       table.user !== this.user._id &&
      //       this.user.role != "admin"
      //     ) {
      //       this.$noty.error("La mesa ya esta ocupada");
      //       return this.$refs["modal"].hide();
      //     }

      //     this.table = table;

      //     this.$refs.modal.show();

      //     this.$router.push({
      //       query: {
      //         table: this.table._id,
      //       },
      //     });
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });

      let timeout = setInterval(() => {
        this.progress = this.progress + 10;
        if (this.progress > 100) {
          this.loadingTable = false;
          this.progress = 10;
          return clearInterval(timeout);
        }
      }, 200);
    },
    addTable() {
      this.tables.push({
        products: [],
        status: "open",
        number: this.tables.length + 1,
      });
    },
    getStatus(status) {
      switch (status) {
        case "available":
          return "Disponible";
        case "reserved":
          return "Reservada";
        case "occupied":
          return "Ocupada";
        default:
          return "Desconocido";
      }
    },
  },
};
</script>