var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid contariner-app"},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"w-100 py-2"}),_c('div',{staticClass:"col"},[_c('h2',[_vm._v("TICKETS "+_vm._s(_vm.tickets.length)+" | $"+_vm._s(_vm._f("formatNumber")(_vm.total)))])]),_c('div',{staticClass:"ml-auto col-2"},[_c('small',[_vm._v("FECHA")]),_c('div',{staticClass:"w-100"}),_c('date-picker',{attrs:{"valueType":"format","input-class":'form-control form-control-sm'},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1),_c('div',{staticClass:"col-2"},[_c('small',[_vm._v("LIMITE")]),_c('div',{staticClass:"w-100"}),_c('b-form-spinbutton',{attrs:{"size":"sm","min":"50","step":"100","max":"50000000","disabled":_vm.loading},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-outline-success btn-block",on:{"click":function($event){return _vm.loadTickets()}}},[_vm._v("\n          Actualizar\n        ")])]),_c('div',{staticClass:"w-100 py-2"}),_c('div',{staticClass:"col"},[_c('ul',{staticClass:"list-group"},_vm._l((_vm.tickets),function(ticket){return _c('li',{key:ticket._id,staticClass:"list-group-item",class:{
              'list-group-item-danger': ticket.deletedAt,
            },on:{"click":function($event){return _vm.viewTicket(ticket)}}},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-11 mb-3 border-bottom"},[_c('span',{staticClass:"text-uppercase mr-5"},[_vm._v(_vm._s(ticket.business.name))]),(ticket.business.custom_name)?_c('span',{staticClass:"d-block1 text-uppercase"},[_vm._v(_vm._s(ticket.business.custom_name))]):_vm._e()]),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"col-2 text-center"},[_c('small',{staticClass:"font-weight-bold"},[_vm._v("#"+_vm._s(_vm._f("formatNumber")(ticket.number)))]),_c('small',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm._f("moment")(ticket.createdAt,"HH:mm:ss")))])]),_c('div',{staticClass:"col-4"},[_c('small',[_vm._v(_vm._s(_vm._f("uppercase")(ticket.clientName)))]),(ticket.type == 'Domicilio')?_c('small',{staticClass:"mx-4"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"building"}}),_vm._v("\n                  "+_vm._s(_vm._f("uppercase")(ticket.address)))],1):_vm._e()]),_c('div',{staticClass:"col-3"},[(ticket.webTicket)?_c('span',{staticClass:"badge badge-success px-3 mx-1"},[_vm._v("WEBTICKET")]):_vm._e(),_c('span',{staticClass:"badge px-3 mx-1",class:{
                    'badge-info': ticket.type === 'Retiro',
                    'badge-secondary': ticket.type === 'Domicilio',
                  }},[_vm._v(_vm._s(_vm._f("uppercase")(ticket.type)))]),_c('span',{staticClass:"badge px-3 mx-1",class:{
                    'badge-primary': ticket.documentType.indexOf('DTE') != -1,
                    'badge-warning': ticket.documentType === 'TICKET',
                  }},[_vm._v(_vm._s(ticket.documentType))])]),_c('div',{staticClass:"col-2 text-right"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v("$"+_vm._s(_vm._f("formatNumber")(ticket.total)))])]),_c('div',{staticClass:"col-10 py-2"},[_c('div',{staticClass:"w-100 border-top"})]),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"col-8"},_vm._l((ticket.products),function(p){return _c('div',{key:p._id,staticClass:"row border-bottom"},[_c('div',{staticClass:"col"},[_c('small',[_vm._v(_vm._s(p.qty)+" x "+_vm._s(_vm._f("uppercase")(p.name)))])]),_c('div',{staticClass:"col-2 text-right"},[_c('small',[_vm._v("$"+_vm._s(_vm._f("formatNumber")(p.total)))])])])}),0)])])}),0)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }