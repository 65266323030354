<template>
  <div class="container">
    <div class="row h-100 loading justify-content-center">
      <div class="col-12 col-md-8 align-self-center">
        <div class="text-center mb-4">
          <div
            class="spinner-grow"
            role="status"
            style="width: 5rem; height: 5rem"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-block text-center animated fadeInUp"
          v-for="(m, i) in items"
          :key="`item-${i}`"
        >
          <div v-if="i == 0" class="mt-4 border-bottom">
            <span style="font-size: 2em"
              >{{ m.text
              }}<span class="d-none" style="width: 50px">{{
                animated_doots
              }}</span></span
            >
          </div>
          <small v-else
            >{{ m.createdAt | moment("HH:mm:ss") }} -
            <span>{{ m.text }}</span></small
          >
        </div>
        <div class="w-100"></div>
        <div class="text-center mt-5" v-if="showButton">
          <button class="btn btn-outline-info" @click="reload()">
            Reintentar
          </button>

          <small class="d-block click text-muted mt-5" @click="goLogin()"
            >INICIAR SESION NUEVAMENTE</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
const pack = require("../../package");
// import storage from "electron-json-storage";
import { storage } from "../multi-utils";

export default {
  name: "Loading",
  data() {
    return {
      showButton: false,
      msg: [
        {
          text: "Espere un momento",
          createdAt: Date.now(),
        },
      ],
      animated_doots: "",
    };
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    items() {
      return this.msg.slice().reverse().slice(0, 8);
    },
  },
  mounted() {
    this.$store.commit("hideMenu");
    this.msg.push({
      text: "Sincronizando datos con el servidor",
      createdAt: Date.now(),
    });
    this.redirect();

    // set interval to animated doots "..."
    setInterval(() => {
      if (this.animated_doots.length < 3) {
        this.animated_doots += ".";
      } else {
        this.animated_doots = "";
      }
    }, 500);
  },
  methods: {
    goLogin() {
      localStorage.token = "";
      this.$router.push("/login");
    },
    reload() {
      this.msg.push({
        text: "Intentando nuevamente",
        createdAt: Date.now(),
      });

      this.redirect();
    },
    redirect() {
      this.msg.push({
        text: "Verificando datos",
        createdAt: Date.now(),
      });
      setTimeout(async () => {
        this.msg.push({
          text: "Cargando usuario",
          createdAt: Date.now(),
        });

        const user = await this.$store.dispatch("getAccount");

        this.msg.push({
          text: "Cargando negocio",
          createdAt: Date.now(),
        });

        const info = await this.$store.dispatch("getInfo");

        this.msg.push({
          text: "Cargando productos",
          createdAt: Date.now(),
        });

        await this.$store.dispatch("getProducts");

        // let featured = await this.$http.get("product/featured");
        // this.$store.commit("setFeatured", featured.data.data);

        if (info.beEnabled) {
          this.msg.push({
            text: "Cargando SII",
            createdAt: Date.now(),
          });
          let caf = await this.$http.get("sii/caf");

          this.$store.commit("setCaf", caf.data.data);
        }

        if (info.webEnabled) {
          this.msg.push({
            text: "Cargando Web",
            createdAt: Date.now(),
          });
          let webTickets = await this.$http.get("web/ticket");
          let webSettings = await this.$http.get("web/settings");

          this.$store.commit("setWebSettings", webSettings.data.data);
          this.$store.commit("setWebTickets", webTickets.data.data);
        }

        let noti = await this.$http.get("notification");
        this.$store.commit("setNotifications", noti.data.data);

        let storages = await this.$http.get("storage");
        this.$store.commit("setStorages", storages.data.data);

        let invoice = await this.$http.get("invoice");
        this.$store.commit("setInvoices", invoice.data.data);

        let udat = {
          ...user,
          logo: info.logo,
          times: 1,
        };

        storage.get("sessions", (err, val = []) => {
          // check is array and find function exists
          if (val && val.find) {
            let exists = val.find((u) => u.email == user.email);
            if (!exists) storage.set("sessions", [udat, ...val]);
            else {
              let nArray = val.filter((u) => u.email != user.email);
              storage.set("sessions", [udat, ...nArray]);
            }
          } else {
            storage.set("sessions", [udat]);
          }
        });

        // convert logo to base64
        if (info.logo) {
          this.msg.push({
            text: "Descargando logo",
            createdAt: Date.now(),
          });
          this.toDataURL(this.getLogo(info.logo), (dataUrl) => {
            this.$store.commit("setLogo", dataUrl);
            localStorage.logo = dataUrl;
          });
        }
        this.msg.push({
          text: "Todo listo !",
          createdAt: Date.now(),
        });

        setTimeout(() => {
          document.title = `${info.name.toUpperCase()}- ${
            pack.version
          } - www.ticketapp.cl`;
          this.$store.commit("toggleMenu");

          if (this.$route.query.redirect) {
            this.$router.push(`/${this.$route.query.redirect}`);
          } else {
            // redirect admin
            if (user.role == "admin") {
              this.$router.push("/new").catch(() => {});
            }
            // redirect driver
            if (user.role == "driver")
              this.$router.push("/map").catch(() => {});
            // redirect waiter
            if (user.role == "waiter")
              this.$router.push("/restaurant").catch(() => {});
            else this.$router.push("/new").catch(() => {});
          }
        }, 500);
      }, 500);

      setTimeout(() => {
        this.showButton = true;
      }, 25000);
    },
  },
};
</script>

<style scope>
.loading {
  min-height: 70vh;
}
</style>