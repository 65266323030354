<template>
  <div class="container-fluid">
    <div class="row py-5">
      <div class="col-12">
        <b-tabs
          vertical
          pills
          active-nav-item-class=""
          nav-wrapper-class="text-uppercase font-weight-bold"
        >
          <b-tab title="Pagos recibidos">
            <div class="row mb-4">
               <div class="col-2 py-2 ml-auto">
                <date-picker
                  valueType="format"
                  v-model="dateFilter"
                   type="month"
                  input-class=" form-control text-center"
                />
              </div>
              <div class="w-100"></div>
              <div class="col">
                <small class="text-right d-none"
                  >Proximo deposito desde: {{ nextDate }}</small
                >
                <div class="list-group-item py-3">
                  <div class="row text-center">
                    <div class="col">
                      <item-icon
                        title="Mes"
                        :text="selectedMonth"
                        textClass="text-uppercase text-primary"
                        icon="calendar-alt"
                        iconClass="text-muted"
                      />
                    </div>
                    <div class="col">
                      <item-icon
                        title="Cantidad"
                        :text="countPending"
                        icon="file-alt"
                        iconClass="text-muted"
                      />
                    </div>
                    <div class="col d-none">
                      <item-icon
                        title="Confirmados"
                        :text="countOk"
                        icon="file-alt"
                        iconClass="text-success"
                      />
                    </div>
                    <div class="col">
                      <item-icon
                        title="Monto total"
                        :text="totalPending"
                        textClass="text-success"
                        icon="money-bill-wave"
                        format
                        iconClass="text-success"
                      />
                    </div>
                    <div class="col d-none">
                      <h3 class="text-info">
                        ${{ (comision + getIva(comision)) | formatNumber }}
                      </h3>
                      <small>COMISION (2.95%)</small>
                    </div>
                    <div class="col d-none">
                      <h3 class="text-success">
                        ${{
                          (totalPending - (comision + getIva(comision)))
                            | formatNumber
                        }}
                      </h3>
                      <small>TE DEPOSITAMOS</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="order in orders"
                :key="order._id"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <small>FECHA</small>
                    <div class="w-100"></div>
                    <strong>{{ order.createdAt | moment("DD/MM/YY") }}</strong>
                  </div>

                  <div class="col-4">
                     <small>CLIENTE</small>
                    <div class="w-100"></div>
                    <strong>{{order.clientName}}</strong>
                  </div>

                   <div class="col">
                    <small>PEDIDO</small>
                    <div class="w-100"></div>
                    <strong>#{{ order.number }}</strong>
                  </div>

                  <div class="col">
                    <small>ID PAGO</small>
                    <div class="w-100"></div>
                    <strong>{{order.paymentId || '- - - -'}}</strong>
                  </div>
                  
                 
                  <div class="col-2 text-center">
                    <small>ESTADO</small>
                    <div class="w-100"></div>
                    <font-awesome-icon
                      class="text-success"
                      icon="check-circle"
                      size="lg"
                      v-if="order.paymentStatus == 'ok'"
                    />
                    <font-awesome-icon
                      v-else
                      class="text-danger"
                      icon="times"
                      size="lg"
                    />
                  </div>
                   <div class="col-2 text-right">
                    <small>TOTAL</small>
                    <div class="w-100"></div>
                    <strong>${{ order.total | formatNumber }}</strong>
                  </div>
                  <div class="col-2 text-right d-none">
                    <small>COMISION</small>
                    <div class="w-100"></div>
                    <strong class="text-info"
                      >-${{
                        (getComision(order.total) +
                          getIva(getComision(order.total)))
                          | formatNumber
                      }}</strong
                    >
                  </div>
                  
                  <div class="col-2 text-right d-none">
                    <small>RECIBES</small>
                    <div class="w-100"></div>
                    <strong class="text-success"
                      >${{
                        (order.total -
                          (getComision(order.total) +
                            getIva(getComision(order.total))))
                          | formatNumber
                      }}</strong
                    >
                  </div>
                </div>
              </li>
            </ul>
          </b-tab>

          <b-tab title="Preguntas" disabled>
            <div class="row">
              <div class="col">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>¿Cuando recibo los pagos?</strong>
                    <div class="w-100"></div>
                    <span
                      >Los pagos seran recibidos automaticamente los primeros
                      dias habiles de cada mes en la cuenta configurada al
                      momento de contratar.</span
                    >
                    <div class="w-100"></div>
                    <span class="text-muted"
                      >Para otras opciones de facturacion, contactar a
                      ventas.</span
                    >
                  </li>
                  <li class="list-group-item">
                    <strong
                      >Recibí un pago, pero el pedido fue cancelado.</strong
                    >
                    <div class="w-100"></div>
                    <span>
                      El dinero será depositado en tu cuenta con normalidad.
                      Deberás gestionar el reembolso del pedido, acordando con
                      el cliente de la mejor manera. Las comisiones serán
                      aplicadas.
                    </span>
                  </li>
                  <li class="list-group-item">
                    <strong>Si no vendo, ¿cuanto debo pagar?</strong>
                    <div class="w-100"></div>
                    <span>
                      Las comisiones por el uso del servicio (webpay) son
                      aplicadas solamente cuando vendes. El servicio de webpay
                      no tiene costo de mantención.
                    </span>
                  </li>
                  <li class="list-group-item">
                    <strong>¿La comisión es con IVA?</strong>
                    <div class="w-100"></div>
                    <span>
                      Las comisiones que se muestran, son con IVA incluido.
                    </span>
                  </li>
                  <li class="list-group-item">
                    <strong>Acerca de</strong>
                    <div class="w-100"></div>
                    <span
                      >Los pagos son procesados por Webpay mediante la
                      plataforma de flow.cl</span
                    >
                    <div class="w-100"></div>
                    <span></span>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
const COMISION_PERCENT = 0.0295;
import itemIcon from "../../components/itemIcon.vue";
export default {
  components: {
    itemIcon,
  },
  data() {
    return {
      orders: [],
      dateFilter: null
    };
  },
  computed: {
    selectedMonth() {
      return this.$moment(this.dateFilter).format('MMMM')
    },
    nextDate() {
      return this.$moment().endOf("week").add(1, "day").format("ddd DD MMMM");
    },
    comision() {
      return this.getComision(this.totalPending);
    },
    countPending() {
      return this.orders.length;
    },
    countOk() {
      return this.orders.filter(o => o.status == 'ok').length
    },
    totalPending() {
      return this.orders.reduce((total, order) => {
        return total + (order.total || 0);
      }, 0);
    },
  },
  mounted() {
    this.dateFilter = this.$moment().format('YYYY-MM')
    //this.load();
  },
  methods: {
    getIva(total) {
      return total * 0.19;
    },
    getComision(total) {
      return total * COMISION_PERCENT;
    },
    load() {
      this.$http.get(`web/payments?date=${this.dateFilter}`).then((res) => {
        this.orders = res.data.data;
      });
    },
  },
  watch: {
    dateFilter() {
      this.load()
    }
  }
};
</script>