<template>
  <div class="row">
    <div class="col-12">
      <span>Habilitar reservas</span>
      <b-form-checkbox v-model="config.reserveEnabled" switch></b-form-checkbox>
    </div>
    <div class="col-12">
      <span>Usar solo categorías</span>
      <v-select
        :options="categories"
        label="name"
        v-model="config.categories"
        multiple
        :reduce="c => c._id"
      ></v-select>
    </div>
    <div class="col-12">
      <span>Largo horizontal</span>
      <b-form-checkbox v-model="config.horizontal" switch></b-form-checkbox>
    </div>
    <div class="col-12 mt-5">
      <b-button @click="saveConfig" variant="primary">Guardar</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    config() {
      return this.$store.state.tablesConfig;
    }
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    saveConfig() {
      console.log(this.config);
      this.$http
        .put("table/config", this.config)
        .then(() => {
          //this.$router.push("/tables");
          this.$noty.success("Configuración guardada");
          this.$emit('confirm')
        })
        .catch(() => {
          this.$noty.error("Error al guardar la configuración");
        });
    },
    getConfig() {
      this.$store.dispatch("getTablesConfig");
    },
  },
};
</script>