<template>
  <div
    class="container-fluid p-0"
    style="max-width: 1600px; position: relative"
  >
    <div style="position: absolute; top: -15px; right: 20px; z-index: 999">
      <button
        class="btn btn-success btn-sm"
        @click="newTab()"
        v-if="hasPermission(user, 'pdv')"
      >
        <font-awesome-icon icon="plus" class="mr-1" />
        <span class="d-none d-md-inline-block">Abrir nuevo ticket</span>
      </button>
      <button
        class="btn btn-outline-info btn-sm text-uppercase font-weight-bold"
        @click="toggleMenu()"
      >
        {{ $store.state.showMenu ? "Ocultar" : "Ver" }} menu
      </button>
    </div>
    <div class="row no-gutters mt-0 animate__animated animate__fadeIn">
      <div class="col-3 px-3" v-if="openCash">
        <small
          >Caja abierta {{ cash.createdAt | moment("DD/MM/YY HH:mm") }}</small
        >
      </div>
      <div class="col-12">
        <b-tabs card v-model="currentTab">
          <!-- Render Tabs, supply a unique `key` to each tab -->
          <b-tab v-for="(tab, i) in tabs" :key="'dyn-tab-' + tab.number">
            <template v-slot:title>
              <button
                class="btn btn-sm text-info"
                @click="tab.show = !tab.show"
                style="margin-top: -2px"
              >
                <font-awesome-icon icon="pen" />
              </button>
              <strong class="text-uppercase"
                >{{ tab.title }} #{{ tab.number + 1 }}</strong
              >
              <button
                class="btn btn-sm text-danger"
                @click="closeTab(tab, i)"
                style="margin-top: -2px"
              >
                <font-awesome-icon icon="times" size="lg"></font-awesome-icon>
              </button>
              <div v-if="tab.show">
                <div class="input-group">
                  <input
                    class="form-control form-control-sm"
                    v-focus
                    type="text"
                    @focus="$event.target.select()"
                    v-model="tab.title"
                    @keyup.enter="tab.show = !tab.show"
                  />
                  <button
                    class="btn btn-success btn-sm"
                    @click="tab.show = !tab.show"
                  >
                    OK
                  </button>
                </div>
                <small class="d-block text-muted"> PRESIONE ENTER </small>
              </div>
            </template>
            <div v-if="loading">
              <div class="text-center">
                <div class="mt-3">
                  <b-spinner class="ml-auto"></b-spinner>
                  <strong class="d-block mt-2">Cargando...</strong>
                  <small>Espere un momento</small>
                </div>
              </div>
            </div>
            <div v-if="!loading">
              <div v-if="openCash">
                <component
                  :is="gui"
                  :index="i"
                  :tabID="tab.number"
                  @showTicket="showTicket"
                  @printTicket="printTicket"
                  :payload="tab.payload"
                  @updateTitle="updateTitle"
                  :current="currentTab"
                  @newTab="newTab"
                />

                <div class="mt-5">
                  <small class="click" @click="toggleGui()"
                    >CAMBIAR INTERFAZ DE VENTA -
                  </small>
                  <small class="text-danger"
                    >SE PERDERAN LOS CAMBIOS NO GUARDADOS</small
                  >
                </div>
              </div>
              <div v-else>
                <openCash @openSuccess="loadCash" />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import classic from "./pdv/classic";
import tpv from "./pdv/TPV.vue";
import openCash from "../components/openCash.vue";
export default {
  components: {
    classic,
    tpv,
    openCash,
  },
  data() {
    return {
      tabs: [],
      currentTab: null,
      loading: true,
      gui: "classic",
      open: false,
      cash: {},
      tabCounter: 0,
    };
  },
  mounted() {
    if (!this.hasPermission(this.user, "pdv")) return this.$router.push("/");

    this.deliveryEnabled = this.info.deliveryEnabled;

    if (this.info.openCashRequired) this.loadCash();
    else this.loading = false;
    //this.newTab();

    let newTickets = localStorage.getItem("newTickets");

    if (newTickets) {
      console.log("new tickets");
      newTickets = JSON.parse(newTickets);

      // if has tickets
      if (newTickets.length > 0) {
        newTickets.forEach((ticket) => {
          if (ticket && ticket.products) this.newTab({ payload: ticket });
        });
      }
      // new tickets length == 0
      else {
        this.newTab();
      }
    } // no new tickets
    else this.newTab();

    // this.newTab();

    if (this.$route.query.gui) {
      this.gui = this.$route.query.gui;
    } else if (localStorage.gui) {
      this.gui = localStorage.gui == "tpv" ? "tpv" : "classic";
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    info() {
      return this.$store.state.info;
    },
    openCashRequired() {
      return this.info.openCashRequired || false;
    },
    openCash() {
      if (this.openCashRequired == false) return true;
      return this.open;
    },
  },
  methods: {
    updateTitle(data) {
      console.log("update title", data);
      if (data && this.tabs[data.tabID])
        this.tabs[data.tabID].title = data.title;
    },
    loadCash() {
      // check if open
      this.$http
        .get("cash")
        .then((res) => {
          if (res.data.data && res.data.data._id) {
            this.loading = false;
            this.cash = res.data.data;
            localStorage.cash = JSON.stringify(res.data.data);
            this.open = true;
          } else {
            this.open = false;
          }
        })
        .catch((err) => {
          if (err.data.details == "no_data") {
            this.loading = false;
            this.open = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      if (localStorage.cash) this.open = true;
    },
    printTicket(args) {
      this.$emit("printTicket", args);
    },
    toggleGui() {
      this.gui = this.gui === "classic" ? "tpv" : "classic";
      localStorage.gui = this.gui;
    },
    showTicket() {
      this.$emit("showTicket");
    },
    toggleMenu() {
      this.$store.commit("toggleMenu");
    },
    closeTab(x, i) {
      if (this.tabs.length == 1) {
        this.newTab();
      }
      this.tabs.splice(i, 1);
      // for (let i = 0; i < this.tabs.length; i++) {
      //   if (this.tabs[i] === x) {
      //     this.tabs.splice(i, 1);
      //   }

      let newTickets = localStorage.getItem("newTickets");
      if (newTickets) {
        newTickets = JSON.parse(newTickets);
        newTickets.splice(i, 1);
        localStorage.setItem("newTickets", JSON.stringify(newTickets));
      }
      // }
      console.log({ tabCounter: this.tabCounter });
      if (this.tabs.length == 0) {
        this.newTab();
      }
    },
    newTab({ payload = null, number } = {}) {
      let num = number || this.tabCounter++;
      this.tabs.push({
        number: num,
        title: "Ticket",
        show: false,
        payload,
      });
      setTimeout(() => {
        this.currentTab = this.tabCounter;
      }, 300);
    },
  },
};
</script>
