<template>
  <div
    class="overflow-hidden"
    :class="{
      fetching: AppLoading,
    }"
    style="min-height: 100vh"
  >
    <div class="fixed-top" v-show="isLoading">
      <b-progress
        :value="progress"
        height="3px"
        variant="primary"
        class=""
        style="opacity: 0.6"
      ></b-progress>
    </div>
    <div
      style="position: fixed; right: 10px; top: 10px; z-index: 999"
      v-show="AppLoading"
    >
      <b-spinner
        style="width: 1.4rem; height: 1.4rem"
        label="Loading..."
        variant="primary"
      />
    </div>
    <div
      id="main-nav"
      class="animated fadeInUp border border-top fixed-bottom p-0 bg-dark text-white"
      v-if="logedState"
    >
      <div class="container-fluid">
        <div class="row no-gutters align-items-center">
          <div class="col-1 text-center d-none">
            <div class="align-items-center click" @click="showChat = !showChat">
              <font-awesome-icon
                icon="paper-plane"
                class="text-light"
                size="1x"
              />
            </div>
          </div>
          <div class="col-auto overflow-hidden">
            <small class="mr-3 d-none1">
              <font-awesome-icon
                class="rounded-circle bg-white"
                :icon="connected ? 'check-circle' : 'times'"
                :class="{
                  'text-success': connected,
                  'text-danger': !connected,
                }"
              />
              <!-- {{ connected ? "CONECTADO" : "DESCONECTADO" }} -->
            </small>
            <small class="text-uppercase">
              <span class="d-inline-block text-nowrap font-italic mr-3">{{
                user.name
              }}</span>
              <span
                class="ml-2 d-inline-block text-nowrap text-uppercase"
                style="max-width: 50%; overflow: hidden; max-height: 15px"
                >{{ info.custom_name || info.name }}</span
              >
            </small>
          </div>
          <div
            class="col-auto ml-auto"
            v-if="info.webEnabled && hasPermission(user, 'web')"
          >
            <webconfig />
          </div>
          <div
            class="col-auto ml-auto"
            v-if="hasPermission(user, 'notifications')"
          >
            <notifications />
          </div>

          <div class="col-auto ml-auto text-center">
            <div
              class="align-items-center click"
              @click="showSidebar = !showSidebar"
            >
              <font-awesome-icon icon="list" class="text-light" size="1x" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <payment-message v-if="user.role == 'admin'" />
    <div class="alert alert-danger" v-if="!connected">
      <strong class="d-block text-center">No tienes conexion</strong>
      <small class="d-block text-center"
        >Tienes problemas de conexion, revisa tu internet !</small
      >
    </div>
    <div class="alert alert-warning mb-0" v-if="status == 'offline'">
      <div class="row justify-content-center align-items-center">
        <div class="col-2 text-right">
          <font-awesome-icon icon="plug" size="2x" />
        </div>
        <div class="col-10 col-md-4">
          <strong class="d-block">Modo desconectado</strong>
          <small class="d-block">
            Tienes problemas de conexion con el servidor
          </small>
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand-sm navbar-light shadow animated fadeInDown"
      :class="{
        fadeOutUp: !logedState,
      }"
      style="{
        'margin-top':
          showPaymentMessage.total && dismissible == false ? '35px' : 0,
      }"
      v-show="logedState"
    >
      <mainmenu
        @showRemotePrintModal="showRemotePrintModal()"
        :printQueueEnabled="printQueueEnabled"
      />
    </nav>

    <div id="app" class="d-print-none px-0 mb-5">
      <router-view
        @update="update"
        @showTicket="showTicket"
        @showWebTicket="showWebTicket"
        @printTicket="printTicket3"
        @printOT="printOT"
        @logoutSocket="logoutSocket"
      ></router-view>
    </div>

    <b-modal id="modal-1" title="Cambiar contraseña" @ok="updatePassword()">
      <div class="form-group">
        <label for>Contraseña actual</label>
        <input
          type="password"
          class="form-control"
          v-model="currentPassword"
          placeholder="******"
          :class="{
            'is-invalid': currentPassword.length <= 5,
            'is-valid': currentPassword.length > 5,
          }"
        />
        <small class="text-muted">Ingrese su contraseña actual</small>
      </div>
      <div class="form-group">
        <label for>Nueva contraseña</label>
        <input
          type="password"
          class="form-control"
          placeholder="******"
          v-model="newPassword"
          :class="{
            'is-invalid': newPassword.length <= 5,
            'is-valid': newPassword.length > 5,
          }"
        />
        <small class="text-muted"
          >Ingrese su nueva contraseña (Minimo 6 digitos)</small
        >
      </div>
      <div class="form-group">
        <label for>Repita nueva contraña</label>
        <input
          type="password"
          class="form-control"
          placeholder="******"
          v-model="rePassword"
          :class="{
            'is-invalid': rePassword.length <= 5 || rePassword != newPassword,
            'is-valid': rePassword.length > 5 && newPassword == rePassword,
          }"
        />
        <small class="text-muted">Repita su nueva contraseña</small>
      </div>
    </b-modal>

    <b-modal
      ref="modalRejectwebTicket"
      id="modalRejectwebTicket"
      size="xs"
      title="Cancelar Ticket"
      @ok="reject()"
    >
      <div class="row">
        <div class="col-12">Motivo</div>
        <div class="col-12">
          <div class="form-group">
            <select v-model="reason" class="form-control">
              <option value="Local cerrado">Local cerrado</option>
              <option value="Direccion no valida">Direccion no valida</option>
              <option value="Pedido no valido">Pedido no valido</option>
              <option value="Pedido repetido">Pedido repetido</option>
              <option value="Cliente cancelo">Cliente cancelo</option>
              <option value="Alta demanda">Alta demanda</option>
              <option value="Sin stock">Sin Stock</option>
              <option value="Faltan insumos">Faltan insumos</option>
              <option value="Otro">Otro</option>
            </select>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalViewTicket"
      id="modalViewTicket"
      size="xl"
      title="Ver Ticket Web"
    >
      <div class="row">
        <div class="col-12">
          <div
            class="alert text-center font-weight-bold"
            :class="{
              'alert-danger': webTicket.status == 'Cancelado',
              'alert-success': webTicket.status == 'Aceptado',
              'alert-warning': webTicket.status == 'Pendiente',
            }"
          >
            {{ webTicket.status }} {{ webTicket.updatedAt | moment("HH:mm") }}
          </div>
        </div>
        <div class="col-12 mb-4">
          <h4 class="text-center">
            Pedido WEB #{{ webTicket.number }} | {{ webTicket.type }}
          </h4>

          <h5 class="text-center text-uppercase">
            {{ webTicket.createdAt | moment("dddd DD/MM/YY HH:mm") }}
          </h5>
          <span class="d-block text-center text-muted">
            ({{ $moment(webTicket.createdAt).fromNow() }})
          </span>
        </div>
        <div class="w-100"></div>
        <div class="col-12" v-if="webTicket.rating">
          <div class="w-50 mb-4 text-center" style="margin: 0 auto">
            <b-form-rating
              v-model="webTicket.rating.note"
              readonly
              show-value
              size="sm"
              variant="warning"
              show-value-max
            ></b-form-rating>

            <div class="w-100"></div>
            <small class="font-italic">{{ webTicket.rating.comment }}</small>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-lg-5 col-12 mb-3">
          <h6>Datos del cliente</h6>
          <ul class="list-group">
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <small class="text-muted d-block">NOMBRE</small>
                  <span class="font-weight-bold">{{
                    webTicket.clientName
                  }}</span>
                  <div class="w-100"></div>
                  <div v-if="webTicket.prevOrders > -1">
                    <div
                      class="badge badge-success"
                      v-if="webTicket.prevOrders < 2"
                    >
                      CLIENTE NUEVO
                    </div>
                    <div class="badge badge-info" v-else>
                      CLIENTE FRECUENTE ({{ webTicket.prevOrders || "0" }})
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <small class="text-muted d-block">TELEFONO</small>
                  <div
                    class="d-inline-block mr-2 text-success click"
                    @click="openWhatsApp(webTicket.phone)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                      />
                    </svg>
                    {{ webTicket.phone }}
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <small class="text-muted d-block">CORREO</small>
                  <span
                    class="font-weight-bold click"
                    @click="goClient(webTicket.email)"
                    >{{ webTicket.email }}</span
                  >
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="webTicket.rut">
              <div class="row">
                <div class="col">
                  <small class="text-muted d-block">RUT</small>
                  <span class="font-weight-bold">{{ webTicket.rut }}</span>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-4 font-weight-bold">Tipo</div>
                <div class="col">{{ webTicket.type }}</div>
              </div>
            </li>
            <li class="list-group-item" v-if="webTicket.type != 'Retiro'">
              <div class="row">
                <div class="col-4 font-weight-bold">Direccion</div>
                <div class="col">
                  {{ webTicket.address || "- - - - - - -" }}
                </div>
                <div class="col-12 mt-2" v-if="webTicket.type == 'Domicilio'">
                  <GmapMap
                    ref="mapRef"
                    :center="getGps(webTicket.gps)"
                    :zoom="14.7"
                    :options="{
                      mapTypeControl: false,
                      showTraffic: false,
                      streetViewControl: false,
                      fullscreenControl: true,
                    }"
                    map-type-id="roadmap"
                    class="map"
                    :style="{
                      height: '200px',
                    }"
                  >
                    <GmapMarker
                      :label="`${webTicket.clientName[0]}`"
                      :position="getGps(webTicket.gps)"
                    ></GmapMarker>
                  </GmapMap>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-4 font-weight-bold">Notas</div>
                <div class="col">{{ webTicket.notes || "- - - -" }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col">
          <h6>
            Productos ({{ webTicket.products ? webTicket.products.length : 0 }})
          </h6>

          <ul class="list-group custom_scroll" style="max-height: 60vh">
            <li
              class="list-group-item"
              v-for="(p, i) in webTicket.products"
              :key="`${p._id}-${i}`"
            >
              <div class="row">
                <div class="col-3">
                  <img :src="getImage(p.image)" class="img-fluid" />
                </div>
                <div class="col">
                  <span class="d-block font-weight-bold text-uppercase">
                    {{ p.qty }} x {{ p.name }}
                  </span>
                  <small class="text-muted">
                    CODIGO: {{ p.code }} | VALOR: ${{ formatNumber(p.value) }}|
                    DCTO: ${{ formatNumber(p.discount) }}
                  </small>
                </div>
                <div class="col-3 text-right ml-auto font-weight-bold">
                  ${{ formatNumber(p.total) }}
                </div>
                <div class="col-12">NOTAS: {{ p.details || "- - - -" }}</div>
                <div class="col-12" v-if="p.options">
                  <small>OPCIONES</small>
                  <div
                    class="row"
                    v-for="(op, i) in p.options"
                    :key="`${op._id}-${i}`"
                  >
                    <div class="col-5 font-weight-bold text-uppercase">
                      {{ op.label }}
                    </div>
                    <div class="col" v-if="op.values.length > 0">
                      <span class="mr-2" v-for="(o, i) in op.values" :key="i">{{
                        o.name
                      }}</span>
                    </div>
                    <div class="col" v-else>
                      {{ op.selected }}
                      <span v-if="op.extra" class="text-danger">
                        - ${{ op.extra }}</span
                      >
                    </div>
                    <div class="w-100 border-bottom"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="list-group">
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col font-weight-bold text-right">SUB TOTAL</div>
                <div class="col-5 ml-auto text-right">
                  ${{ formatNumber(webTicket.subtotal) }}
                </div>
              </div>
            </li>
            <li
              class="list-group-item list-group-item-info"
              v-if="webTicket.coupon"
            >
              <div class="row">
                <div class="col font-weight-bold text-right">
                  CUPON ({{ webTicket.couponCode }})
                </div>
                <div class="col-5 ml-auto text-right">
                  ${{ formatNumber(webTicket.discount) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col font-weight-bold text-right">MEDIO DE PAGO</div>
                <div class="col-5 ml-auto text-right text-uppercase">
                  {{ webTicket.payment }}
                </div>
              </div>
            </li>
            <li
              class="list-group-item list-group-item-dark"
              v-if="webTicket.type == 'Domicilio'"
            >
              <div class="row">
                <div class="col font-weight-bold text-right">ENVIO</div>
                <div class="col-5 ml-auto text-right">
                  ${{ formatNumber(webTicket.deliveryValue) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col font-weight-bold text-right">TOTAL</div>
                <div class="col-5 ml-auto text-right font-weight-bold">
                  ${{ formatNumber(webTicket.total) }}
                </div>
              </div>
            </li>
            <li
              class="list-group-item list-group-item-success"
              v-if="webTicket.tip"
            >
              <div class="row">
                <div class="col font-weight-bold text-right">PROPINA</div>
                <div class="col-5 ml-auto text-right font-weight-bold">
                  ${{ formatNumber(webTicket.tip) }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col font-weight-bold text-right">PAGA CON</div>
                <div
                  class="col-5 ml-auto text-right text-success font-weight-bold"
                >
                  ${{ formatNumber(webTicket.amount) }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col font-weight-bold text-right">VUELTO</div>
                <div
                  class="col-5 ml-auto text-right text-info font-weight-bold"
                >
                  ${{
                    formatNumber(
                      webTicket.amount - webTicket.total - webTicket.tip
                    )
                  }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="col-12 mt-4" v-if="webTicket.status == 'Pendiente'">
          <div class="row">
            <div class="col">
              <button
                class="btn btn-danger btn-block btn-lg py-3"
                v-b-modal.modalRejectwebTicket
              >
                RECHAZAR PEDIDO
              </button>
            </div>

            <div class="col">
              <button
                class="btn btn-success btn-block btn-lg py-3"
                @click="goWebTicketNew(webTicket)"
              >
                ACEPTAR PEDIDO
              </button>
            </div>
          </div>
        </div>
        <div class="w-100 py-2"></div>
        <div class="col-auto ml-auto">
          <small class="text-muted click" @click="goTickets(webTicket)"
            >VER TICKET DE VENTA</small
          >
        </div>
        <div class="col-auto text-right">
          <small class="text-muted"> ID: {{ webTicket._id }}</small>
        </div>
      </div>
    </b-modal>

    <!-- sidebar -->
    <b-sidebar
      backdrop
      shadow
      right
      v-model="showSidebar"
      header-class="bgwhite"
      body-class="bgwhite textwhite"
    >
      <div class="px-2">
        <div class="mx-3 mb-4">
          <div class="row no-gutters align-items-center">
            <div class="col-3 text-center">
              <b-avatar class="" size="lg"></b-avatar>
            </div>
            <div class="col pl-1 text-uppercase">
              <span class="mr-auto">{{ user.name }}</span>
              <div class="w-100"></div>
              <marquee
                scrollamount="1"
                behavior="alternate"
                direction="horizontal"
                ><small>{{ info.custom_name || info.name }}</small></marquee
              >
            </div>
          </div>
        </div>
        <router-link
          class="dropdown-item"
          :to="{ path: '/loading', params: { refresh: 1 } }"
        >
          <font-awesome-icon
            icon="database"
            class="mr-2 text-muted"
          ></font-awesome-icon>
          Sincronizar con el servidor
        </router-link>
        <a class="dropdown-item text-danger" @click="forceReload()" href="#">
          <font-awesome-icon
            icon="redo"
            class="mr-2 text-muted"
          ></font-awesome-icon>
          Forzar recarga
        </a>
        <a class="dropdown-item" href="#" target="_blank">
          <font-awesome-icon
            icon="window-maximize"
            class="mr-2 text-muted"
          ></font-awesome-icon>
          Abrir ventana extra
        </a>
        <div v-if="isElectron()">
          <div class="dropdown-divider"></div>
          <b-button
            class="dropdown-item"
            variant="none"
            @click="
              $event.stopPropagation();
              zoomIn();
            "
          >
            <font-awesome-icon
              icon="plus"
              class="text-muted mr-2"
            ></font-awesome-icon>
            Aumentar zoom
          </b-button>
          <b-button
            class="dropdown-item"
            @click="
              $event.stopPropagation();
              zoomOut();
            "
          >
            <font-awesome-icon
              icon="minus"
              class="mr-2 text-muted"
            ></font-awesome-icon>
            Reducir zoom
          </b-button>
          <b-button
            class="dropdown-item"
            @click="
              $event.stopPropagation();
              zoomReset();
            "
          >
            <font-awesome-icon
              icon="redo"
              class="mr-2 text-muted"
            ></font-awesome-icon>
            Restablecer zoom
          </b-button>
        </div>
        <div class="dropdown-divider"></div>
        <div v-if="user.admin">
          <router-link class="nav-link" to="/admin"
            ><font-awesome-icon
              icon="star"
              class="mr-2 text-warning"
            ></font-awesome-icon>
            Admin</router-link
          >
          <div class="dropdown-divider" v-if="user.admin"></div>
        </div>
        <router-link
          class="dropdown-item"
          to="/users"
          v-if="hasPermission(user, 'users')"
        >
          <div class="position-relative">
            <font-awesome-icon
              icon="users"
              class="mr-2 text-muted"
            ></font-awesome-icon>
            Usuarios <admin-badge />
          </div>
        </router-link>
        <router-link
          class="dropdown-item"
          to="/invoices"
          v-if="user.role == 'admin'"
        >
          <div class="position-relative">
            <font-awesome-icon
              icon="file-alt"
              class="mr-3 text-muted"
            ></font-awesome-icon>
            Mis Pagos
            <admin-badge />
          </div>
        </router-link>
        <router-link
          class="dropdown-item"
          to="/documents"
          v-if="user.role == 'admin'"
        >
          <div class="position-relative">
            <font-awesome-icon
              icon="file-alt"
              class="mr-3 text-muted"
            ></font-awesome-icon>
            Documentos
            <admin-badge />
          </div>
        </router-link>
        <router-link
          class="dropdown-item d-none1"
          to="/settings"
          v-if="user.role == 'admin' && hasPermission(user, 'settings')"
        >
          <div class="position-relative">
            <font-awesome-icon
              icon="cog"
              class="mr-2 text-muted"
            ></font-awesome-icon>
            Configuracion General
            <admin-badge />
          </div>
        </router-link>
        <div class="dropdown-divider"></div>
        <div class="px-3">
          <button
            class="btn btn-success btn-block"
            @click="
              doAction(
                'https://api.whatsapp.com/send?phone=56986470414&text=Hola, me comunico desde la App'
              )
            "
          >
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              focusable="false"
              role="img"
              aria-label="whatsapp"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="b-icon mr-1 bi"
            >
              <g>
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                ></path>
              </g>
            </svg>
            Soporte via WhatsApp
          </button>
        </div>
        <div class="dropdown-divider"></div>
        <div class="px-3">
          <b-form-checkbox switch v-model="darkMode">
            Modo oscuro
            <div class="badge badge-info">BETA</div>
          </b-form-checkbox>
        </div>
        <div class="px-3 mt-2">
          <b-button variant="primary" @click="fullscreen()" block>
            Pantalla completa
            <div class="badge badge-info">BETA</div>
          </b-button>
        </div>
        <div class="dropdown-divider"></div>
        <div class="px-3 mt-2 d-none">
          <b-button variant="primary" to="/tour" block>
            Recorrido
            <div class="badge badge-info">BETA</div>
          </b-button>
        </div>
        <div class="dropdown-divider"></div>
        <b-button class="dropdown-item" v-b-modal.modal-1>
          <font-awesome-icon
            icon="key"
            class="text-muted mr-2"
          ></font-awesome-icon>
          Cambiar mi contraseña
        </b-button>
        <button class="dropdown-item" @click="logout()">
          <font-awesome-icon
            icon="times"
            class="mr-2 text-muted"
          ></font-awesome-icon>
          Cerrar sesion
        </button>
        <div class="w-100"></div>
        <div class="mt-5">
          <div class="text-center" @click="doAction('http://www.ticketapp.cl')">
            <small class="click text-muted text-uppercase"
              >www.ticketapp.cl</small
            >
            <div class="w-100"></div>
            <small class="">v{{ AppVersion }}</small>
          </div>
        </div>
      </div>
    </b-sidebar>

    <b-modal backdrop ref="ticketModal" hide-header hide-footer>
      <div class="px-2">
        <div class="row no-gutters align-items-end mb-3">
          <div class="col-3 d-none1">
            <small class="text-muted">COPIAS</small>
            <select name="" id="" class="form-control" v-model="copiesTicket">
              <option :value="1">1</option>
              <option :value="2">2</option>
              <option :value="3">3</option>
              <option :value="4">4</option>
            </select>
          </div>
          <div class="col">
            <small class="text-muted">MODO</small>
            <select class="form-control" v-model="printMode">
              <option :value="1">Completa</option>
              <option :value="2">Simple</option>
              <option :value="3">Detalle</option>
              <option :value="4">Completa + Detalle</option>
              <option :value="5">Completa + Cambio</option>
              <option :value="6">Completa + simple + detalle</option>
            </select>
          </div>
          <div class="col-2" v-if="$store.state.ticket._id">
            <small>REMOTO</small>
            <button
              class="btn btn-secondary btn-block align-self-end"
              @click="
                printTicketRemote({
                  ticket: $store.state.ticket,
                  type: printMode,
                  copies: copiesTicket,
                })
              "
            >
              <font-awesome-icon icon="print" />
            </button>
          </div>
          <div class="col-2">
            <button
              class="btn btn-primary btn-block align-self-end"
              @click="
                printTicket3({
                  ticket: $store.state.ticket,
                  type: printMode,
                  copies: copiesTicket,
                })
              "
            >
              <font-awesome-icon icon="print" />
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-danger btn-block" @click="closeModal()">
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div id="ticket">
              <ticket
                :ticket="$store.state.ticket"
                :businessInfo="$store.state.info"
                :mode="printMode"
                v-for="t in copiesTicket"
                :key="`ticket-${t}`"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end sidebar -->

    <div id="ticket-hidden" class="d-none">
      <div v-for="(t, i) in copiesTicket" :key="`ticket-hidden-${t}`">
        <ticket
          :ticket="$store.state.ticket"
          :businessInfo="$store.state.info"
          :mode="printMode"
          :copies="copiesTicket"
        />
        <div
          class="pagebreak"
          style="page-break-before: always; clear: both; width: 100%"
          v-if="copiesTicket > 1 && i < copiesTicket - 1"
        ></div>
      </div>
    </div>
    <div id="ot-hidden" class="d-none">
      <div v-for="(t, i) in copiesOT" :key="`ot-hidden-${t}`">
        <ot :businessInfo="$store.state.info" />
        <div
          v-if="i < copiesOT - 1"
          class="pagebreak"
          style="page-break-before: always; clear: both; width: 100%"
        ></div>
      </div>
    </div>

    <b-modal ref="modalRemotePrint" title="Cola de impresion" hide-footer>
      <div class="row">
        <div class="col-12">
          <small>ULTIMA ACTUALIZACION</small>
          <div class="text-muted">
            {{ printUpdatedAt | moment("HH:mm") }} -
            {{ printUpdatedAt | moment("from", "now") }}
          </div>
        </div>

        <div class="col mt-4">
          <small>HABILITAR</small>
          <b-checkbox switch v-model="printQueueEnabled"
            >Ejecutar cola en este equipo</b-checkbox
          >
        </div>
        <div class="w-100 mt-4"></div>
        <div class="col-12">
          <small>IMPRESORA PREDETERMINADA</small>
          <select class="form-control" v-model="printQueuePrinter">
            <option :value="true">Usar por defecto</option>
            <option v-for="p in printers" :value="p.name" :key="p.name">
              {{ p.name }}
            </option>
          </select>

          <div class="row">
            <div class="col-4">
              <small>Tamaño letra</small>
              <select class="form-control" v-model="printQueueFont">
                <option :value="9">9</option>
                <option :value="10">10</option>
                <option :value="11">11</option>
                <option :value="12">12</option>
                <option :value="13">13</option>
                <option :value="14">14</option>
                <option :value="16">16</option>
              </select>
            </div>
            <div class="col-4">
              <small>Ancho ticket</small>
              <select class="form-control" v-model="printQueueWidth">
                <option :value="30">30%</option>
                <option :value="40">40%</option>
                <option :value="50">50%</option>
                <option :value="60">60%</option>
                <option :value="70">70%</option>
                <option :value="80">80%</option>
                <option :value="90">90%</option>
                <option :value="100">100%</option>
              </select>
            </div>
            <div class="col-4">
              <small>Margen</small>
              <select class="form-control" v-model="printQueueMarginLeft">
                <option :value="0">0</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4" v-if="info.restaurantEnabled">
          <small>IMPRESORA PREDETERMINADA MESAS</small>
          <select class="form-control" v-model="printerRestaurant">
            <option :value="true">Usar por defecto</option>
            <option v-for="p in printers" :value="p.name" :key="p.name">
              {{ p.name }}
            </option>
          </select>
        </div>

        <div class="col-12 mt-4">
          <b-tabs justified>
            <b-tab title="EN COLA">
              <small>DOCUMENTOS EN COLA</small>
              <div class="dropdown-item" v-if="printQueue.length == 0">
                <span class="text-muted">No hay pendientes</span>
              </div>
              <div
                class="dropdown-item"
                v-for="pq in printQueue"
                :key="`pq-${pq._id}`"
              >
                <font-awesome-icon
                  icon="clock"
                  class="text-warning"
                  v-if="pq.status == 'in_queue'"
                />
                <font-awesome-icon
                  class="text-success"
                  icon="check-circle"
                  v-else
                />
                <span class="pl-2"
                  >#{{ pq.createdAt | moment("HH:mm:ss") }}</span
                >
                <small class="mr-2">{{ pq.user && pq.user.name }}</small>
                <!-- <button
                  class="btn btn-link"
                  @click="printTicket(pq.ticket, pq.type, pq.copies)"
                >
                  <font-awesome-icon icon="print" class="text-primary" />
                </button> -->
              </div>
            </b-tab>
            <b-tab title="FINALIZADO">
              <button
                class="btn btn-primary btn-block"
                @click="fetchPrintAll()"
              >
                <font-awesome-icon icon="redo" />
                CARGAR
              </button>
              <div class="overflow-auto custom_scroll" style="max-height: 50vh">
                <div
                  class="dropdown-item"
                  v-for="pq in printQueueAll"
                  :key="`pq-${pq._id}`"
                >
                  <font-awesome-icon
                    icon="clock"
                    class="text-warning"
                    v-if="pq.status == 'in_queue'"
                  />
                  <font-awesome-icon
                    class="text-success"
                    icon="check-circle"
                    v-else
                  />
                  <span class="pl-2"
                    >#{{ pq.createdAt | moment("HH:mm:ss") }}</span
                  >
                  <div class="w-100"></div>
                  <small class="mr-2">{{ pq.user && pq.user.name }}</small>
                  <small>Modo: {{ getPrintMod(pq.mode) }}</small>
                  <!-- <button
                    class="btn btn-link"
                    @click="printTicket(pq.ticket, pq.type, pq.copies)"
                  >
                    <font-awesome-icon icon="print" class="text-primary" />
                  </button> -->
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import io from "socket.io-client";
import { ipcRenderer, storage, remote, webFrame } from "./multi-utils";
import ticket from "./components/ticket.vue";
import mainmenu from "./components/mainmenu.vue";
import adminBadge from "./components/admin-badge.vue";
import notifications from "./components/notifications.vue";
import paymentMessage from "./components/payment-message.vue";
import webconfig from "./components/webconfig.vue";
import ot from "./pages/OtPrint.vue";
//import rrss from "./pages/rrss";

export default {
  components: {
    ticket,
    ot,
    adminBadge,
    // rrss,
    mainmenu,
    notifications,
    paymentMessage,
    webconfig,
  },
  name: "app",
  data() {
    return {
      printQueueMarginLeft: 0,
      printQueueWidth: 90,
      printQueueFont: 9,
      printQueuePrinter: true,
      printerRestaurant: true,
      printers: [],
      darkMode: false,
      audioNoty: new Audio(`https://pedidos.ticketapp.cl/bells/bell5.wav`),
      currentPassword: "",
      newPassword: "",
      rePassword: "",
      socket: null,
      audio: null,
      loading: false,
      socketInit: false,
      showSidebar: false,
      showChat: false,
      appInit: false,
      reason: "",
      printTimeout: null,
      printQueueEnabled: false,
      printUpdatedAt: Date.now(),
      printMode: 1,
      copiesTicket: 1,
      copiesOT: 1,
      isLoading: true,
      progress: 10,
      businesses: [],
      showNotification: false,
    };
  },
  mounted() {
    //this.getScreenLock();
    const { redirect } = this.$route.query;
    const currentRoute = this.$route.path.substring(1);

    let qs = "";
    if (redirect) qs = "?redirect=" + redirect;
    else if (currentRoute !== "/") qs = "?redirect=" + currentRoute;
    if (!this.appInit) this.$router.push("/loading" + qs);
    // this.socket = io(`https://io.ticketapp.cl`);

    this.connectSocket();

    if (localStorage.getItem("darkMode"))
      this.darkMode = localStorage.getItem("darkMode") === "true";

    if (localStorage.getItem("printQueueEnabled"))
      this.printQueueEnabled =
        localStorage.getItem("printQueueEnabled") === "true";

    if (localStorage.getItem("printQueuePrinter"))
      this.printQueuePrinter = localStorage.getItem("printQueuePrinter");
    if (localStorage.getItem("printQueueMarginLeft"))
      this.printQueueMarginLeft = localStorage.getItem("printQueueMarginLeft");
    if (localStorage.getItem("printQueueWidth"))
      this.printQueueWidth = localStorage.getItem("printQueueWidth");
    if (localStorage.getItem("printQueueFont"))
      this.printQueueFont = localStorage.getItem("printQueueFont");

    if (localStorage.getItem("printerRestaurant"))
      this.printerRestaurant = localStorage.getItem("printerRestaurant");

    if (this.isElectron()) {
      try {
        const { webContents } = remote;
        const content = webContents.getAllWebContents();
        this.printers = content[0].getPrinters();
      } catch (error) {
        this.$noty.error("Error al obtener las impresoras del sistema");
      }

      storage.getMany(
        [
          "fontSize",
          "fontSize2",
          "marginLeft",
          "pageWidth",
          "gui",
          "autoHideMenu",
          "zoom",
          "printQueueEnabled",
        ],
        (err, data) => {
          if (err) return false;

          localStorage.fontSize = parseInt(data.fontSize) || 9;
          this.font = localStorage.fontSize;
          localStorage.fontSize2 = data.fontSize2 || 9;
          localStorage.pageWidth = data.pageWidth || 90;
          localStorage.marginLeft = data.marginLeft || 0;
          localStorage.gui = data.gui || "classic";
          localStorage.autoHideMenu = data.autoHideMenu;
          if (data.zoom != null || data.zoom != {}) {
            webFrame.setZoomFactor(data.zoom);
          }
        }
      );
    }

    setTimeout(() => {
      this.load();
      // if (this.info.webEnabled) {
      //   this.$http.get("web/ticket").then((res) => {
      //     this.$store.commit("setWebTickets", res.data.data);
      //   });
      // }
    }, 2000);

    const alertOnlineStatus = () => {
      this.$store.commit("setStatus", navigator.onLine);
      this.sendPending();
    };

    window.addEventListener("online", alertOnlineStatus);
    window.addEventListener("offline", alertOnlineStatus);

    alertOnlineStatus();

    window.addEventListener("keydown", (e) => {
      if (e.key == "F1") {
        this.$router.push("/new");
        e.preventDefault();
      }
      if (this.isElectron()) {
        if (e.key == "F5") {
          this.$router.push("/tickets");
          e.preventDefault();
        }
        if (e.key == "F6") {
          this.$router.push("/products");
          e.preventDefault();
        }
        // if (e.key == "F7") {
        //   this.$router.push("/map");
        //   e.preventDefault();
        // }
        // if (e.key == "F8") {
        //   this.$router.push("/ots");
        //   e.preventDefault();
        // }
        if (e.key === "F12") {
          remote.getCurrentWindow().toggleDevTools();
        }
      }
    });
  },
  beforeDestroy() {
    clearTimeout(this.printTimeout);
    this.socket.disconnect();
  },
  computed: {
    printQueue() {
      return this.$store.state.printQueue;
    },
    printQueueAll() {
      return this.$store.state.printQueueAll;
    },
    AppVersion() {
      const pack = require("../package");
      return pack.version || "- - - -";
    },
    AppLoading() {
      return this.$store.state.loading;
    },
    pageWidth() {
      return localStorage.pageWidth || 90;
    },
    logedState() {
      let { logged, showMenu } = this.$store.state;
      if (localStorage.token == "") return false;
      if (localStorage.token && logged && showMenu) return true;
      return false;
    },
    webTicket() {
      return this.$store.state.webTicket || {};
    },
    printQueuePending() {
      return this.printQueue.filter((p) => p.status == "in_queue");
    },
    settings() {
      return this.info && this.info.settings ? this.info.settings : {};
    },
    webSettings() {
      return this.$store.state.webSettings;
    },
    user() {
      return this.$store.state.user;
    },
    info() {
      return this.$store.state.info;
    },
    connected() {
      return this.$store.state.connected;
    },
    status() {
      return this.$store.state.status;
    },
    webTickets() {
      return this.$store.state.webTickets;
    },
    webTicketsPending() {
      return this.webTickets.reduce((t, w) => {
        if (w.status == "Pendiente") return t + 1;
        return t;
      }, 0);
    },
  },
  methods: {
    getPrintMod(mode) {
      switch (mode) {
        case 1:
          return "Completa";
        case 2:
          return "Simple";
        case 3:
          return "Detalle";
        case 4:
          return "Completa + Detalle";
        case 5:
          return "Completa + Cambio";
        default:
          return "Completa";
      }
    },
    showRemotePrintModal() {
      this.$refs["modalRemotePrint"].show();
    },
    async getScreenLock() {
      if ("wakeLock" in navigator) {
        let screenLock;
        try {
          screenLock = await navigator.wakeLock.request("screen");
          console.log({ screenLock });
        } catch (err) {
          console.log(err.name, err.message);
        }
        return screenLock;
      }
    },
    fullscreen() {
      let val = !document.fullscreenElement;
      if (val) {
        document.body.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    checkNotifications() {
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      } else if (
        Notification.permission === "granted" &&
        !this.showNotification
      ) {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        const notification = new Notification("Tienes un pedido web!", {
          body: "Revisa la lista de pedidos web",
          icon: this.getLogo(this.info.logo),
          requireInteraction: true,
        });

        this.showNotification = true;

        notification.onclick = (event) => {
          this.showNotification = false;
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.focus();
        };

        // …
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission();
      }
    },
    closeapp() {
      document.write("Reinicie la app...");
    },
    goClient(client) {
      this.$router.push(`/web/clients/?search=${client}`);
      this.$refs["modalViewTicket"].hide();
    },
    goTickets(ticket) {
      let date = this.$moment(ticket.createdAt).format("YYYY-MM-DD");
      this.$router.push(`/tickets?date=${date}&filter=${ticket.clientName}`);
      this.$refs["modalViewTicket"].hide();
    },

    forceReload() {
      document.location.reload();
    },

    closeModal() {
      this.$refs["ticketModal"].hide();
    },
    printTicketRemote(args) {
      let { ticket, type, copies } = args;
      this.$http
        .post("print", { ticket: ticket._id, type, copies })
        .then(() => {
          this.$noty.success("Enviado a imprimir en red");
        })
        .catch(() => {
          this.$noty.error("Error al imprimir en red. Intente nuevamente");
        });
    },
    printTicket3(args) {
      let { ticket, type, copies } = args;
      console.log("print ticket 3", { ticket, type, copies });

      let printers = args.printers || 1;
      //this.width = localStorage.pageWidth || 90;
      //this.margin = localStorage.marginLeft || 0;

      // callback function after print
      const print2 = (data) => {
        if (
          data.printer2 &&
          data.printer2.length > 0 &&
          (printers == 1 || printers == 3)
        ) {
          console.log("hay printer2", data);

          // this.font = data.fontSize2;
          // if (data.marginLeft2) this.margin = data.marginLeft2;
          // if (data.pageWidth2) this.width = data.pageWidth2;
          //this.printTicket(ticket, 3, copies, data.printer2, data);

          this.printTicket({
            ticket,
            mode: 3,
            copies,
            cfg: {
              fontSize: data.fontSize2,
              pageWidth: data.pageWidth2,
              marginLeft: data.marginLeft2,
            },
            printer: data.printer2,
          });
        }
      };

      storage.getMany(
        [
          "printer",
          "printer2",
          "fontSize",
          "fontSize2",
          "pageWidth2",
          "marginLeft2",
        ],
        (err, data) => {
          if (err)
            this.$noty.error(
              "Error leyendo parametros de impresion, Intente nuevamente"
            );
          //this.font = data.fontSize;

          console.log("printer data", data);
          if (printers == 1 || printers == 2) {
            //let printer = pdf ? "PDF" : data.printer;
            // this.printTicket(ticket, type, copies, data.printer, () => {
            //   // print secondary
            //   print2(data);
            // });
            this.printTicket({
              ticket,
              mode: type,
              copies,
              printer: data.printer,
              cfg: {
                fontSize: data.fontSize,
                pageWidth: data.pageWidth,
                marginLeft: data.marginLeft,
              },
              cb: () => {
                // print secondary
                console.log("print secondary");
                print2(data);
              },
            });
          }
          if (printers == 3) print2(data);
        }
      );
    },
    /**
     * @param {object} ticket - ticket to print
     * @param {number=1} [type] - 1: completa, 2: simple, 3: cocina (solo productos) 4: ticket cambio
     * @param {number=1} [copies] - number of copies
     * @param {string=default} [printer] - printer name
     * @param {function} [cb] - callback function after print
     * @returns {void}
     */
    printTicket(args) {
      const { ticket, mode = 1, copies = 1, printer, cfg, cb } = args;

      if (!ticket) return this.$noty.info("Debe seleccionar un ticket");

      this.printMode = mode;
      this.copiesTicket = copies;
      this.$store.commit("setTicket", ticket);

      // localstorage save settings
      let width = localStorage.pageWidth || 90;
      let margin = localStorage.marginLeft || 0;
      let fontSize = localStorage.fontSize || 9;

      // :style="{ width: `${pageWidth}%`, 'margin-left': `${marginLeft}px` }"
      if (cfg) {
        if (cfg.pageWidth) width = cfg.pageWidth;
        if (cfg.marginLeft) margin = cfg.marginLeft;
        if (cfg.fontSize) fontSize = cfg.fontSize;
      }

      setTimeout(() => {
        let tickethtml = document.querySelector("#ticket-hidden").innerHTML;
        let html = `<!DOCTYPE html>
        <html lang="es">
          <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <style>* { margin: 0; padding: 0; }</style>
            <title>T${ticket.dteType || 0}_${
          ticket.folio || ticket.number
        }</title>
          </head>
          <body>
            <div style="display:block;${`width: ${width}%; margin-left: ${margin}px; font-size: ${fontSize}px;`}">
            ${tickethtml}
            </div>
          </body>
        </html>`;

        //console.log({ html });

        let blob = new Blob([html], { type: "text/html" });
        let url = URL.createObjectURL(blob);
        if (this.isElectron()) {
          ipcRenderer
            .invoke("print", {
              printerName: printer,
              url,
              scaleFactor: 200,
              dpi: 200,
            })
            .then(() => {
              console.log("ipc print end");
              if (cb && typeof cb == "function") cb();
            });
        } else {
          var w = window.open(url);
          w.onload = function () {
            console.log("Document loaded");
            w.print();
          };

          w.onbeforeprint = function () {
            console.log("Before print");
            setTimeout(() => {
              w.close();
            }, 1000);
          };
          // console.log({w})
          // setTimeout(() => {

          // }, 1000);
        }
      }, 500);
    },
    printOT({ ot, copies }) {
      console.log("print from app", { ot, copies });
      this.copiesOT = parseInt(copies);
      if (!ot) return this.$noty.info("Debe seleccionar una orden de trabajo");

      this.$store.commit("setOT", ot);

      setTimeout(() => {
        let tickethtml = document.querySelector("#ot-hidden").innerHTML;
        let html = `<!DOCTYPE html>
        <html lang="es">
          <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </head>
          <body>
            ${tickethtml}
          </body>
        </html>`;

        let blob = new Blob([html], { type: "text/html" });
        let url = URL.createObjectURL(blob);
        if (this.isElectron()) {
          storage.get("printer", (err, data) => {
            if (err) this.$noty.error("Intente nuevamente");
            ipcRenderer.invoke("print", { printerName: data, url });
          });
        } else {
          var w = window.open(url);
          setTimeout(() => {
            w.print();
            //w.close();
          }, 1000);
        }
      }, 500);
    },
    reject() {
      if (this.reason == "")
        return this.$noty.error("Debe ingresar un motivo valido");
      this.$http
        .delete(`web/ticket/${this.webTicket._id}`, {
          body: {
            reason: this.reason,
          },
        })
        .then(() => {
          this.$refs["modalViewTicket"].hide();
          this.reason = "";
        })
        .catch(() => {
          this.$noty.error("Error al cancelar");
        })
        .finally(() => {
          this.loadWebTickets();
        });
    },
    showWebTicket() {
      this.$http.get(`web/ticket/${this.webTicket._id}`).then((res) => {
        //this.webTicket = res.data.data;
        this.$store.commit("setWebTicket", res.data.data);
        //this.$refs.modalViewTicket.show();
      });
      this.$refs.modalViewTicket.show();
    },
    goWebTicketNew(ticket) {
      this.$refs["modalViewTicket"].hide();
      this.$router.push({
        path: "/new",
        query: {
          wid: ticket._id,
          gui: "classic",
        },
      });
    },
    showTicket() {
      //this.showSidebar = true;
      this.printMode = 1;
      this.$refs["ticketModal"].show();
    },

    fetchPrintAll() {
      //if (!this.printQueueEnabled) return false;
      this.$http.get("print/all").then((res) => {
        this.$store.commit("setPrintQueueAll", res.data.data);
        //if (res.data.total > 0 && !this.printTimeout) this.runPrintQueue();
      });
    },
    fetchPrint() {
      if (!this.printQueueEnabled) return false;
      this.$http.get("print").then((res) => {
        this.printUpdatedAt = Date.now();
        this.$store.commit("setPrintQueue", res.data.data);
        if (res.data.total > 0 && !this.printTimeout) this.runPrintQueue();
      });
    },
    getNextPrintTicket() {
      if (this.printQueuePending == 0) return null;
      else return this.printQueuePending[0];
    },
    runPrintQueue() {
      if (!this.printQueueEnabled) return false;
      let printer = this.printQueuePrinter || 1;

      console.log("Runing queue print");
      let queue = this.getNextPrintTicket();
      if (queue) {
        this.$http
          .patch(`print/${queue._id}`)
          .then(() => {
            return this.fetchPrint();
          })
          .then(() => {
            console.log("Printing ticket", queue);
            let data = {
              ...queue.ticket,
            };

            if (queue.table) {
              console.log("table", queue.table);
              printer = this.printerRestaurant;
              //data = queue.table;
              data.number = 0;
              data.clientName = queue.table.name;
              data.status = "Por pagar";
              data.createdAt = queue.createdAt;
              data.number = queue.table.name;

              let products = [];

              if (queue.payload) {
                console.log("payload", queue.payload);
                const products_ids = queue.payload.products.filter(
                  (p) => !p.deletedAt
                );
                products = queue.table.products.filter((p) =>
                  products_ids.includes(p._id)
                );
              } else {
                console.log("set products");
                products = queue.table.products.filter((p) => !p.deletedAt);
              }

              if (products.length == 0)
                return console.log("Print: no hay productos");

              data.products = products
                // .filter((p) => {
                //   return !p.readyAt;
                // })
                .map((p) => {
                  return {
                    ...p,
                    total: p.qty * p.value,
                  };
                });

              let sub_total = data.products.reduce((t, p) => t + p.total, 0);
              
              data.tip = sub_total * 0.1;
              data.sub_total = sub_total;
              data.total = sub_total;
            }

            const cfg = {
              marginLeft: this.printQueueMarginLeft,
              pageWidth: this.printQueueWidth,
              fontSize: this.printQueueFont,
            };

            this.printTicket({
              ticket: data,
              mode: queue.type,
              copies: queue.copies || 1,
              printer,
              cfg,
              cb: () => {
                console.log("Printed ticket", queue);
              },
            });

            this.printTimeout = setTimeout(this.runPrintQueue, 3000);
          })
          .catch((err) => {
            console.log("Error al imprimir", err);
          });
      } else this.printTimeout = setTimeout(this.runPrintQueue, 3000);
    },
    // printTicket(ticket = null, type = 1, copies = 1, remote = false) {
    //   if (!ticket) ticket = this.ticket;
    //   if (remote)
    //     localStorage.ticketRemote = JSON.stringify({ ...ticket, remote });
    //   else localStorage.ticket = JSON.stringify({ ...ticket, remote });
    //   localStorage.info = JSON.stringify(this.$store.state.info);
    //   localStorage.copies = copies;
    //   localStorage.printMode = type;
    //   this.$store.commit("setTicket", { ...ticket, remote });
    //   if (remote) ipcRenderer.send("printTicketService");
    //   else ipcRenderer.send("printTicket");
    //   this.$noty.success("Ticket enviado a imprimir");
    // },
    connectSocket() {
      if (this.socketInit) return console.log("[SOCKET] - Ya conectado");

      let { name, custom_name, _id } = this.$store.state.info;

      if (!_id) {
        return console.log("No hay id de negocio");
      }

      if (!this.user.name) {
        return console.log("No hay usuario");
      }

      this.socket = io("https://io.ticketapp.cl", {
        query: {
          client: "windows",
          business: custom_name || name,
          business_id: _id,
          name: this.user.name,
          role: this.user.role,
          email: this.user.email,
          token: "123",
          app: this.isElectron() ? "electron" : "web",
        },
        //transports: ["websocket"],
        //upgrade: false,
      });

      this.socket.on("connect_error", (err) => {
        console.log(err instanceof Error); // true
        console.log(err.message); // not authorized
        console.log(err.data); // { content: "Please retry later" }
      });

      this.socket.on("disconnect", () => {
        console.log("[SOCKET] - disconnected");
      });

      this.socketInit = true;

      this.socket.on("connect", () => {
        console.log("[SOCKET] - Conected to server");
        this.socket.emit("join", this.info._id);
        console.log("[SOCKET] - Join room");
      });

      this.socket.on("notification", (args) => {
        let { webAlertVolume } = this.webSettings;
        this.audioNoty.volume = (webAlertVolume || 8) / 10;
        this.audioNoty.play();

        // show notification toast boostra
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("strong", args.message),
          h(
            "router-link",
            {
              class: ["ml-2", "font-weight-bold"],
              props: {
                to: `/rrss?page=${args.id}&chat=${args.senderId}&platform=${args.platform}`,
              },
            },
            "ver"
          ),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
          [
            h("strong", { class: "mr-2" }, args.title),
            h("small", { class: "ml-auto text-italics" }, "Recien"),
          ]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          variant: args.type,
          solid: true,
          noAutoHide: true,
        });

        if (args.action == "loadChats") {
          this.$http
            .get(
              `auth/facebook/pagechat?id=${args.id}&platform=${args.platform}`
            )
            .then((response) => {
              const data = response.data.data.data;
              this.$store.commit("setFbChats", data);
            });
        }
      });

      this.socket.on("newTicket", (data) => {
        const remember = (data && data.remember) || false;
        console.log("newTicketData", data);

        if (!this.hasPermission(this.user, "web")) return false;

        this.checkNotifications();

        this.$http.get("web/ticket?status=Pendiente").then((res) => {
          if (res.data.data.length > 0) {
            this.$store.commit("setWebTickets", res.data.data);
          }
        });

        console.log("new ticket");

        let { webSound, webAlertVolume, webNotification } = this.webSettings;
        if (webNotification == "one" && remember)
          return console.log("Suena una vez");
        if (webNotification == "none") return console.log("No usa sonido");
        this.audio = new Audio(
          `https://pedidos.ticketapp.cl/bells/${webSound}.wav`
        );
        this.audio.volume = (webAlertVolume || 8) / 10;
        this.audio.play();
      });

      this.socket.on("ping", () => {
        if (
          this.info.webEnabled &&
          this.webSettings &&
          this.webSettings.status == "app" &&
          this.hasPermission(this.user, "web")
        ) {
          this.$http.post("pong?ref=" + this.info.name, {
            id: this.info._id,
          });

          if (
            localStorage.lastPing &&
            new Date() - new Date(localStorage.lastPing) > 100000
          )
            this.$http.get("web/settings").then((res) => {
              this.$store.commit("setWebSettings", res.data.data);
            });
        } else {
          //console.log('no ping')
        }
        if (this.info.remotePrintEnabled) this.fetchPrint();

        if (
          localStorage.lastPing &&
          new Date() - new Date(localStorage.lastPing) > 100000
        )
          this.$http.get("notification").then((res) => {
            this.$store.commit("setNotifications", res.data.data);
          });

        localStorage.lastPing = new Date();
      });

      this.socket.on("update_data", (data) => {
        console.log("updating....", data);
        this.$store.dispatch("getTickets");
        this.$http.get("me").then((res) => {
          this.$store.commit("setUser", res.data.data);
        });
        if (this.info.webEnabled) {
          this.$http.get("web/ticket").then((res) => {
            this.$store.commit("setWebTickets", res.data.data);
          });
          this.$http.get("web/settings").then((res) => {
            this.$store.commit("setWebSettings", res.data.data);
          });
        }
      });

      this.socket.on("cmd", ({ cmd, ...args }) => {
        console.log("🚀 ~ file: App.vue:2452 ~ this.socket.on ~ data:", args);
        console.log("cmd", cmd);
        if (cmd == "logout") {
          const { email, to } = args;
          if (email == this.user.email || to == "all") {
            this.logout();
          }
        }

        if (cmd == "reload") this.forceReload();

        if (cmd == "closeapp") {
          console.log("close app");
          const { content } = args;
          document.body.innerHTML = content;
        }

        if (cmd == "run_print") {
          console.log("run print by socket");
          this.fetchPrint();
        }

        if (cmd == "newTicket" || cmd == "updateTicket") {
          this.$store.dispatch("getTickets");
        }

        if (cmd == "updateTable") {
          console.log("update table by socket");
          this.$store.dispatch("getTables");
          this.$store.dispatch("getTablesPending");
        }
      });
    },
    zoomReset() {
      webFrame.setZoomFactor(1);
      storage.set("zoom", webFrame.getZoomFactor());
    },
    zoomIn() {
      webFrame.setZoomFactor(webFrame.getZoomFactor() + 0.1);
      storage.set("zoom", webFrame.getZoomFactor());
    },
    zoomOut() {
      webFrame.setZoomFactor(webFrame.getZoomFactor() - 0.1);
      storage.set("zoom", webFrame.getZoomFactor());
    },

    getName(name) {
      if (name) {
        let n = name.split(" ");
        if (n.length > 0) return n[0];
      } else {
        return "Bienvenido";
      }
    },
    sendPending() {
      Promise.all(
        this.$store.state.pending_tickets.map((t) =>
          this.$http.post("ticket", t)
        )
      )
        .then(() => {
          this.$store.commit("setPending", []);
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Error al sincronizar",
            text: "Asegurese de no duplicar tickets e intente nuevamente",
          });
        });
    },
    loadWebTickets() {
      this.$http
        .get(`web/ticket`)
        .then((res) => {
          this.$store.commit("setWebTickets", res.data.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.loading = true;
      this.$http
        .get("me")
        .then((res) => {
          if (res.data.data) {
            this.$store.commit("setUser", res.data.data);
            //console.log("user", res.data.data);
            this.$http.get("notification").then((res) => {
              this.$store.commit("setNotifications", res.data.data);
            });
          } else {
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          // console.log("Error get me", err);
          if (err.status !== 0) {
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.loading = false;
        });
      this.$http.get("invoice").then((res) => {
        this.$store.commit("setInvoices", res.data.data);
      });
    },
    update() {
      this.load();
    },
    logoutSocket() {
      console.log("leaving socket....");
      this.socket.emit("leave", this.info._id);
      this.socket.disconnect();
      this.socketInit = false;
    },
    logout() {
      this.socket.emit("leave", this.info._id);
      this.socket.disconnect();
      localStorage.token = "";
      this.socketInit = false;
      this.$store.commit("login", false);
      this.$router.push("/login");
    },
    updatePassword() {
      const { currentPassword, newPassword, rePassword } = this;
      if (
        currentPassword.length < 2 ||
        newPassword.length < 6 ||
        rePassword.length < 6
      ) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Asegurese de rellenar todos los campos solicitados",
        }).then(() => this.$bvModal.show("modal-1"));
      } else if (rePassword != newPassword) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "La nueva contraseña y repetir contraseña no coinciden",
        }).then(() => this.$bvModal.show("modal-1"));
      } else {
        this.$http
          .post("me/password", {
            currentPassword,
            newPassword,
          })
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Exito",
              text: "Contraseña cambiada exitosamente",
            });
            this.currentPassword = "";
            this.newPassword = "";
            this.rePassword = "";
          })
          .catch((err) => {
            if (err.data && err.data.details == "password_not_match") {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "La contraseña actual no coincide",
              }).then(() => {
                this.currentPassword = "";
                this.$bvModal.show("modal-1");
              });
            } else {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Error al cambiar la contraseña. Intente nuevamente",
              }).then(() => this.$bvModal.show("modal-1"));
            }
            // console.error({ err });
          });
      }
    },
  },
  watch: {
    printQueueMarginLeft() {
      localStorage.setItem("printQueueMarginLeft", this.printQueueMarginLeft);
    },
    printQueueWidth() {
      localStorage.setItem("printQueueWidth", this.printQueueWidth);
    },
    printQueueFont() {
      localStorage.setItem("printQueueFont", this.printQueueFont);
    },
    copiesTicket() {
      console.log("copiesTicket", this.copiesTicket);
    },
    printerRestaurant() {
      //localStorage.printerRestaurant = this.printerRestaurant;
      localStorage.setItem("printerRestaurant", this.printerRestaurant);
    },
    printQueuePrinter() {
      //localStorage.printQueuePrinter = this.printQueuePrinter;
      localStorage.setItem("printQueuePrinter", this.printQueuePrinter);
    },
    darkMode() {
      localStorage.setItem("darkMode", this.darkMode);
      if (this.darkMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    },
    $route: function () {
      var self = this;
      self.isLoading = true;

      let timeout = setInterval(() => {
        this.progress = this.progress + 10;
        if (this.progress > 100) {
          this.isLoading = false;
          this.progress = 10;
          return clearInterval(timeout);
        }
      }, 200);
    },
    copies() {
      localStorage.copies = this.copies;
    },
    printMode() {
      // localStorage.printMode = this.printMode;
    },
    info() {
      this.connectSocket();
    },
    printQueueEnabled() {
      this.fetchPrint();
      localStorage.setItem("printQueueEnabled", this.printQueueEnabled);
    },
  },
};
</script>

<style>
:root {
  --bg-color: #ecf0f1;
  --text-color: #212f3d;
  --link-color: #8a8a8a;
  --link-hover-color: #0056b3;
  --active-color: #007bff;
  --active-color-hover: #036bda;
  --text-color-active: #ffffff;
  --link-active: #212f3d;
  --border-color: #dee2e6;
  --bg-white: white;
  --border-color: white;
  --text-danger: #721c24;
  --bg-danger: #f5c6cb;
  --bg-yellow: #f5ea86;
}
.dark-mode {
  /* --bg-color: #353b48; */
  --bg-color: #292d3e;
  --text-color: #ecf0f1;
  --link-color: #d6d6d6;
  --link-hover-color: #f8f9fa;
  --active-color: #2d3436;
  --active-color-hover: #404749;
  --link-active: #ffffff;
  --text-color-active: #ffffff;
  --border-color: #343a40;
  --bg-white: #2f3640;
  --border-color: #dee2e640;
  --text-danger: #f8d7da;
  --bg-danger: #6c3c41;
  --bg-yellow: #faeb6348;
}
::backdrop {
  background-color: var(--bg-color) !important;
}
body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}
.bg-yellow {
  background-color: var(--bg-yellow) !important;
}
.card {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
.border:not(
    .border-warning,
    .border-success,
    .border-danger,
    .border-info,
    .border-primary,
    .border-secondary,
    .border-dark,
    .border-light,
    .border-white
  ) {
  border: 1px solid var(--border-color) !important;
}
.table-hover .table-secondary {
  background-color: var(--bg-white) !important;
}
text.apexcharts-text {
  fill: var(--text-color) !important;
}
.arrow_box,
.apexcharts-tooltip,
.apexcharts-tooltip-title {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
.textwhite {
  color: var(--text-color) !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.vs__selected {
  background-color: var(--bg-white) !important;
  color: var(--text-color) !important;
}
.apexcharts-tooltip span {
  color: var(--text-color) !important;
}
.table {
  color: var(--text-color) !important;
}
.list-group-item-warning {
  color: #856404 !important;
  background-color: #ffeeba !important;
}
.list-group-item-dark {
  color: #1b1e21 !important;
  background-color: #c6c8ca !important;
}

.bglight,
.b-overlay .bg-light {
  background-color: var(--bg-color) !important;
}
.list-group-item:not(
    .list-group-item-dark,
    .list-group-item-warning,
    .list-group-item-danger,
    .list-group-item-success,
    .list-group-item-info,
    .list-group-item-primary,
    .list-group-item-secondary
  ) {
  background-color: var(--bg-white);
  color: var(--text-color);
}
.list-group-item.active {
  background-color: var(--active-color) !important;
  border-color: transparent !important;
  color: var(--text-color-active) !important;
}
.list-group-item.active:hover {
  background-color: var(--active-color-hover) !important;
  border-color: transparent !important;
  color: var(--text-color-active) !important;
}
.list-group-item-danger {
  color: var(--text-danger) !important;
  background-color: var(--bg-danger) !important;
}
.list-group-item .list-group-item-action {
  background-color: var(--bg-white) !important;
  color: var(--text-color) !important;
}
.list-group-item-action:hover {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
.modal-content {
  background-color: var(--bg-white) !important;
}
.bg-white,
.bgwhite,
.page-item.disabled .page-link {
  background-color: var(--bg-white) !important;
}
#main-nav {
  background-color: var(--bg-color);
  color: var(--text-color);
}
.text-through {
  text-decoration: line-through;
}
.dropdown-item {
  color: var(--link-color) !important;
}
.dropdown-item:hover {
  background-color: var(--bg-color) !important;
}
pre {
  color: var(--text-color) !important;
}
.fetching {
  cursor: progress !important;
}
.custom_scrollX {
  overflow-x: scroll;
}
.custom_scrollX::-webkit-scrollbar {
  height: 6px;
}
.custom_scrollX::-webkit-scrollbar-track {
  margin: 0 0px;
}
.custom_scrollX::-webkit-scrollbar-thumb {
  background: rgba(213, 215, 217, 1);
  border-radius: 10px;
  padding: 0 3px;
}
.custom_scrollX::-webkit-scrollbar-thumb:hover {
  background: #212f3dae;
  border-radius: 10px;
  padding: 0 3px;
}
.custom_scroll {
  overflow-y: scroll;
  padding-right: 5px;
}
.custom_scroll::-webkit-scrollbar {
  width: 8px;
}
.custom_scroll::-webkit-scrollbar-track {
  margin: 0 0px;
}
.custom_scroll::-webkit-scrollbar-thumb {
  background: rgba(213, 215, 217, 0.521);
  border-radius: 10px;
  padding: 0 3px;
}
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(213, 215, 217, 0.221);
  border-radius: 10px;
  padding: 0 3px;
}
.vs__dropdown-menu {
  z-index: 9999 !important;
}
.modal {
  margin-top: 0px !important;
}
.modal-full {
  padding-left: 0px !important;
}
/* .modal-xl {
  max-width: 1350px !important;
}
.modal-xl .modal-dialog {
  width: 98% !important;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  max-width: 1100px;
}

.modal-content {
  height: auto;
  min-height: 100% !important;
  border-radius: 0;
} */
.container-app {
  max-width: 1200px;
  margin: 0 auto;
}
.b-skeleton.center {
  margin: 0 auto;
  margin-bottom: 0.25rem;
}
@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}
body {
  background-color: var(--bg-color) !important;
  font-family: "Noto Sans", sans-serif !important;
  color: #2c3e50;
  color: var(--text-color) !important;
}
.container-fluid:not(.full) {
  max-width: 1500px;
}
nav.bg-light {
  background-color: var(--bg-color);
  font-weight: bolder !important;
}
.btn {
  text-transform: uppercase;
  font-family: "Be Vietnam";
  font-size: 0.95em !important;
  border-radius: 50px !important;
  text-align: _;
}
.btn-success,
.badge-success {
  background-color: #16a085 !important;
}
.btn-outline-success,
.border-success {
  border-color: #16a085 !important;
}
.btn-primary,
.badge-primary {
  background-color: #2980b9 !important;
}
.btn-warning {
  background-color: #f39c12 !important;
  color: white;
}
.btn-primary {
  background-color: #2980b9 !important;
  font-weight: bold;
}
.btn-outline-primary {
  border-color: #2980b9 !important;
}
.btn-group-toggle > .btn-secondary {
  background-color: white !important;
}
.btn-secondary:not(.option):not(.dropdown-item):not(.dropdown-toggle) {
  background-color: #8e44ad !important;
  border-color: #6f3588 !important;
}
.btn-info {
  background-color: #34495e !important;
  border-color: #2c3e50 !important;
  color: white !important;
}
.btn-danger {
  background-color: #e74c3c !important;
}
.btn-outline-secondary {
  border-color: #34495e !important;
  color: #34495e;
}
.btn-outline-secondary:hover {
  color: white !important;
}
input.has-valid {
  border-color: #1abc9c !important;
}
a {
  /* color: #2980b9 !important; */
}
#navBar {
  -webkit-app-region: drag;
  width: 100%;
  height: 25px;
  line-height: 30px;
  position: fixed;
  top: 0;
  z-index: 9999999;
  text-align: right;
  background-color: var(--bg-color);
  margin-bottom: 0px;
}
#navBar .btn {
  padding: 0px 10px;
  height: 28px;
  margin-top: -13px;
  font-size: 13px;
  -webkit-app-region: no-drag;
}
#navBar .btn:focus {
  box-shadow: none;
}
.btn,
input,
textarea,
select,
.input-group span {
  border-radius: 3px !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-tabs .nav-link.active,
.input-group-text {
  background-color: var(--bg-white) !important;
  color: var(--text-color) !important;
}
input,
textarea,
select {
  /* font-weight: bolder !important; */
  font-size: 15px !important;
}
.navbar-nav > li > a {
  color: var(--link-color) !important;
  position: relative;
  min-width: 60px;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
}
nav .nav-link {
  color: var(--link-color) !important;
}
nav .nav-link small {
  font-weight: bold !important;
}
nav a.router-link-exact-active.router-link-active,
.nav-item.router-link-active,
a.router-link-active {
  color: var(--link-active) !important;
  font-weight: bold;
}
nav a.router-link-exact-active.router-link-active::before,
nav .nav-link.router-link-active::before {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 2px;
  background: var(--link-active);
  width: 100%;
  left: 0;
}
.button.btn {
  margin-right: 5px;
}
nav * {
  font-family: "Be Vietnam", sans-serif;
}
#app * {
  font-family: "Noto Sans", sans-serif;
}
#app {
  color: var(--text-color);
  margin-top: 20px;
}
.router-link-active {
  color: rgba(0, 0, 0, 0.9) !important;
  font-weight: bold;
}
.fade-enter-active {
  transition: opacity 2s;
}
.fade-enter-active {
  transition: opacity 0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media print {
  .noty_layout {
    display: none;
  }
}
.b-form-spinbutton,
.b-form-file .custom-file-label,
input,
.input,
select,
textarea {
  background-color: var(--bg-white) !important;
  color: var(--text-color) !important;
}
::placeholder {
  color: var(--text-color) !important;
  opacity: 0.4 !important;
}
.click {
  cursor: pointer;
}
.mx-datepicker {
  width: 100% !important;
  display: block !important;
}

.modal-full .modal-dialog {
  width: 98% !important;
  max-width: 1400px !important;
  height: 100%;
  margin: 30px auto;
  padding: 0;
}

.modal-full .modal-content {
  height: auto;
  min-height: 95% !important;
  border-radius: 0;
}
@media (min-width: 692px) {
  .modal-lg,
  .modal-xxl {
    max-width: 1700px !important;
  }
}
</style>
