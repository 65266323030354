<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h4>Calculador de precios</h4>
      </div>
    </div>

    <div class="row py-3">
      <div class="col">
        <b-tabs pills justified>
          <b-tab title="Productos">
            <div class="row">
              <div class="col">
                <v-select
                  v-model="product"
                  class="style-chooser"
                  :options="getProducts"
                >
                  <template v-slot:option="option">
                    <span class="font-weight-bold">{{ option.code }} - </span>
                    <span class="">{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-auto ml-auto">
                <button class="btn btn-success" @click="addProduct()">
                  AGREGAR
                </button>
              </div>
            </div>
            <ul class="list-group">
              <li class="list-group-item" v-for="p in products" :key="p._id">
                <div class="row align-items-end">
                  <div class="col-12">
                    <small>NOMBRE | {{ p._id }}</small>
                    <input
                      type="text"
                      class="form-control form-control-lg fs-2"
                      v-model="p.name"
                    />
                  </div>
                  <div class="col">
                    <small>CALCULADO</small>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      v-model="p.cost_value_calculated"
                    />
                  </div>
                  <div class="col">
                    <small>COSTO</small>
                    <input
                      type="text"
                      class="form-control"
                      v-model="p.cost_value"
                    />
                  </div>
                  <div class="col">
                    <small>VENTA</small>
                    <input
                      type="text"
                      class="form-control text-right"
                      v-model="p.value"
                    />
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-danger" @click="remove(p)">
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>
                <div class="row py-2 bglight p-2 mx-1 mt-3">
                  <div class="col">
                    <small>Ingredientes</small>
                  </div>

                  <div class="w-100"></div>

                  <div class="col">
                    <v-select
                      v-model="ing"
                      class="style-chooser"
                      :options="getproductsNoSellable"
                    >
                    </v-select>
                  </div>
                  <div class="col-3">
                    <button
                      class="btn btn-success btn-block"
                      @click="add(ing, p)"
                    >
                      <font-awesome-icon icon="plus" />
                    </button>
                  </div>

                  <div class="w-100 py-2"></div>

                  <div
                    class="col-12 ml-auto"
                    v-for="ing in p.items"
                    :key="ing._id"
                  >
                    <div class="row no-gutters align-items-end">
                      <div class="col-5">
                        <small>NOMBRE | {{ ing._id }}</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="ing.name"
                        />
                      </div>
                      <div class="col">
                        <small>COSTO</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="ing.value"
                        />
                      </div>
                       <div class="col">
                        <small>UNIDAD</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="ing.unit"
                        />
                      </div>
                      <div class="col">
                        <small>CANTIDAD</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="ing.qty"
                        />
                      </div>
                      <div class="col">
                        <small>TOTAL</small>
                        <div class="form-control text-right">
                          {{ (ing.qty * ing.value) | formatNumber }}
                        </div>
                      </div>
                      <div class="col-auto">
                        <button class="btn text-danger" @click="removeIng(p, ing)">
                          <font-awesome-icon icon="times" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </b-tab>
          <b-tab title="Insumos">
            <ul class="list-group">
              <li
                class="list-group-item list-group-item-action"
                v-for="p in productsNoSellable"
                :key="p.id"
              >
                <div class="row align-items-end">
                  <div class="col-6">
                    <span class="text-uppercase font-weight-bold">{{ p.name }}</span>
                    <span class="d-block">{{p.unit}}</span>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col">
                        <small>CANTIDAD</small>
                        <b-form-input
                          type="number"
                          v-model="p.qty"
                          class="text-center"
                          placeholder="Cantidad"
                        ></b-form-input>
                      </div>
                      <div class="col">
                        <small>VALOR</small>
                        <b-form-input
                          type="number"
                          class="text-right"
                          v-model="p.value"
                          placeholder="Precio"
                        ></b-form-input>
                      </div>
                      <div class="col-auto align-self-end">
                        <button class="btn btn-outline-danger">
                          <font-awesome-icon icon="trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calculator",
  data() {
    return {
      productsNoSellable: [],
      products: [],
    };
  },
  mounted() {
    this.getProductsNoSellable();
    if (localStorage.getItem("productsCalculator"))
      this.products = JSON.parse(localStorage.getItem("productsCalculator"));
  },
  computed: {
    getproductsNoSellable() {
      return this.productsNoSellable.map((p) => {
        return {
          ...p,
          label: `${p.code} - ${p.name}`,
        };
      });
    },
    productList() {
      return this.$store.state.products;
    },
    getProducts() {
      return this.productList.map((p) => {
        return {
          ...p,
          label: `${p.code} - ${p.name}`,
        };
      });
    },
  },
  methods: {
    removeIng(product, ing) {
      product.items = product.items.filter((item) => item._id !== ing._id);
    },
    add(ing, p) {
      console.log('ing', ing)
      p.items.push({
        ...ing,
        qty: 1,
      });
    },
    remove(p) {
      this.products = this.products.filter((item) => item._id !== p._id);
    },
    addProduct() {
      this.products.unshift({
        ...this.product,
        cost_value_calculated: 0,
        items: [],
      });
    },
    getProductsNoSellable() {
      this.$http
        .get("product?salable=false")
        .then((res) => {
          this.productsNoSellable = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    products: {
      deep: true,
      handler() {
        this.products.forEach((p) => {
          let cost = 0;
          p.items.forEach((i) => {
            cost += i.value * i.qty;
          });
          p.cost_value_calculated = cost;
        });

        localStorage.setItem(
          "productsCalculator",
          JSON.stringify(this.products)
        );
      },
    },
  },
};
</script>