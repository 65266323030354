<template>
  <div class="container-fluid container-app">
    <div class="row align-items-end">
      <div class="col-12 col-md-4">
        <h2 class="mb-0">Productos ({{ productList.length || 0 }})</h2>
        <small
          >Mostrando {{ productList.length }} de {{ products.length }} productos
        </small>
      </div>
      <div class="col-12 col-md text-right">
        <router-link to="products/calculator" class="btn btn-light d-none">
          <font-awesome-icon icon="calculator" />
        </router-link>
        <button class="btn btn-link" @click="showModalProviders()">
          Proveedores
        </button>
        <router-link to="/coupons" class="btn btn-link"> Cupones </router-link>

        <router-link
          to="/products/report"
          class="btn btn-link d-none"
          v-if="hasPermission(user, 'product_report')"
        >
          Reporte
        </router-link>

        <button class="btn" @click="downloadExcel()">DESCARGAR EXCEL</button>

        <router-link to="/products/multiple" class="btn btn-link d-none">
          Ingreso multiple
        </router-link>

        <router-link to="/products/report" class="btn btn-link d-none">
          Movimientos de Stock
        </router-link>
        <!-- <button
          class="btn btn-link text-danger"
          :disabled="loading"
          @click="deleteAll()"
          v-if="user.role == 'admin'"
        >
          BORRAR TODOS
        </button> -->
        <button
          class="btn btn-link d-none1"
          :disabled="loading"
          @click="addAllToWeb()"
          v-if="user.role == 'admin' && info.webEnabled"
        >
          TODOS A LA WEB
        </button>
        <div class="w-100 py-1"></div>
        <button
          class="btn btn-outline-danger"
          @click="showModal()"
          v-if="user.role == 'admin'"
        >
          borrados
        </button>

        <button class="btn btn-info" @click="showModalCategories()">
          Categorias
        </button>
        <button class="btn btn-secondary" @click="showModalOptions()">
          Opciones
        </button>
        <button class="btn btn-primary px-4" @click="showNewModal()">
          Nuevo (F2)
        </button>
      </div>
      <div class="w-100"></div>
      <div class="col-12 mt-2 border-top pt-3">
        <div class="row align-items-end no-gutters">
          <div class="col-12 col-md-4">
            <small>BUSCAR POR NOMBRE O CODIGO (F3), ENTER PARA BUSCAR</small>
            <div class="input-group">
              <input
                type="text"
                v-model="filter"
                ref="searchInput"
                class="form-control"
                placeholder="Buscar producto"
                @keyup.enter="filterProducts()"
              />
              <button class="btn btn-light d-none" @click="showScannerModal()">
                <font-awesome-icon icon="camera" />
              </button>
            </div>
          </div>
          <div class="col-2" v-if="info.sessionsEnabled">
            <small class="d-block">Bodega</small>
            <select class="form-control" v-model="storage_selected">
              <option :value="null">Todas</option>
              <option :key="s._id" :value="s._id" v-for="s in storages">
                {{ s.name }}
              </option>
            </select>
          </div>
          <div class="col-3 col-md">
            <small class="d-block">PRECIO EDITABLE</small>
            <select class="form-control" v-model="editable">
              <option :value="null">Todos</option>
              <option :value="true">Si</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div class="col-3 col-md">
            <small class="d-block">PRECIO COSTO</small>
            <select class="form-control" v-model="cost_value">
              <option :value="null">Todos</option>
              <option :value="true">Si</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div class="col-3 col-md">
            <small class="d-block">ORDENAR</small>
            <select class="form-control" v-model="sort">
              <option value="code">codigo</option>
              <option value="code:desc">codigo desc</option>
              <option value="name">nombre</option>
              <option value="name:desc">nombre desc</option>
              <option value="stock">stock</option>
              <option value="stock:desc">stock desc.</option>
            </select>
          </div>
          <div class="col-3 col-md">
            <small class="d-block">CATEGORIA</small>
            <select class="form-control" v-model="selectedCategory">
              <option
                :value="null"
                v-if="category && category.length == 0"
                disabled
              >
                No tienes categorias
              </option>
              <option value="" v-else>Todas</option>
              <option value="null">Sin Categoria</option>
              <option :value="cat._id" v-for="cat in categories" :key="cat._id">
                {{ cat.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <small class="d-block">PROVEEDOR</small>
            <select class="form-control" v-model="selectedProvider">
              <option
                value="null"
                v-if="providers && providers.length == 0"
                disabled
              >
                No tienes proveedores
              </option>
              <option value="null" v-else>Todos</option>
              <option
                :value="provider._id"
                v-for="provider in providers"
                :key="provider._id"
              >
                {{ provider.label || provider.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <small class="d-block">MOSTRAR</small>
            <select class="form-control" v-model="limit">
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option :value="products.length">
                Todos ({{ products.length }})
              </option>
            </select>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-deleted"
        ref="modal-deleted"
        size="xl"
        title="Productos borrados"
      >
        <div class="row">
          <div class="col-12 mb-4">
            <small class="text-muted">Buscar por nombre o codigo</small>
            <div class="input-group">
              <input
                type="text"
                v-model="filterDeleted"
                class="form-control"
                placeholder="Buscar producto"
                @keyup.enter="loadDeletedProducts()"
              />
              <button class="btn btn-light" @click="loadDeletedProducts()">
                <font-awesome-icon icon="sync" />
              </button>
            </div>
          </div>
          <div class="col">
            <ul class="list-group">
              <li v-fi="deletedProducts.length == 0" class="list-group-item">
                <div class="row">
                  <div class="col text-center">
                    <strong>No hay productos borrados</strong>
                  </div>
                </div>
              </li>
              <li
                class="list-group-item"
                v-for="p in deletedProducts"
                :key="p._id"
              >
                <div class="row align-items-center">
                  <div class="col-1 d-none d-md-block col-md-1">
                    <img
                      class="img-fluid border"
                      loading="lazy"
                      :src="getImage(product.image || null)"
                    />
                  </div>
                  <div class="col">
                    <small class="text-muted">CODIGO: {{ p.code }}</small>
                    <div class="w-100"></div>
                    <strong class="text-uppercase">
                      {{ p.name }}
                    </strong>
                  </div>
                  <div class="col-2">
                    <small class="text-muted">BORRADO</small>
                    <div class="w-100"></div>
                    <strong class="text-uppercase">
                      {{ p.deletedAt | moment("DD/MM/YY") }}
                    </strong>
                  </div>
                  <div class="col-3">
                    <button
                      class="btn btn-block btn-outline-success"
                      @click="restore(p._id)"
                    >
                      RESTAURAR
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal"
        ref="modal"
        size="xl"
        title="Nuevo producto"
        no-close-on-backdrop
        @ok="saveProduct"
        ok-title="Guardar"
        :ok-disabled="saving"
        cancel-title="Cancelar"
      >
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Codigo</label>
              <input
                placeholder="Minimo 1 caracter"
                class="form-control text-uppercase"
                v-model="newProduct.code"
                ref="inputCode"
                id="inputCode"
                @blur="checkCode(newProduct)"
                v-focus
                :class="{
                  'is-invalid': newProduct.code.length == 0,
                  'is-valid': newProduct.code.length > 0,
                }"
              />
              <small class="text-muted">SIN ESPACIOS NI GUIONES</small>
              <b-tooltip target="inputCode" class="text-uppercase"
                >ESCANEE O INGRESE CODIGO UNICO</b-tooltip
              >
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>Nombre</label>
              <input
                class="form-control"
                v-model="newProduct.name"
                id="inputName"
                :class="{
                  'is-invalid': newProduct.name.length < 2,
                  'is-valid': newProduct.name.length >= 2,
                }"
                placeholder="Minimo 3 caracteres"
              />
              <small class="text-muted">EJ: LECHE ENTERA NESTLE 1 LITRO</small>
              <b-tooltip target="inputName" class="text-uppercase"
                >NOMBRE DE PRODUCTO DESCRIPTIVO</b-tooltip
              >
            </div>
          </div>

          <div class="col-lg-6">
            <label class="font-weight-bold">Categoria</label>
            <v-select
              v-model="newProduct.category"
              :reduce="(c) => c._id"
              class="py-1"
              :options="getCategories"
            ></v-select>
            <button
              class="btn btm-sm btn-link py-0 btn-block"
              @click="addNewCategoryModal"
            >
              <small>AGREGAR CATEGORIA</small>
            </button>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="font-weight-bold">Precio</label>
              <input
                class="form-control text-right"
                v-model.number="newProduct.value"
                id="inputValue"
                @focus="$event.target.select()"
                @keyup.enter="saveProduct()"
              />
              <b-tooltip target="inputValue" class="text-uppercase"
                >PRECIO DE VENTA CON IMPUESTOS INCLUIDOS</b-tooltip
              >
            </div>
          </div>

          <div class="w-100"></div>
        </div>

        <div class="form-group">
          <label>Descripcion</label>
          <textarea
            placeholder="(Opcional)"
            class="form-control"
            v-model="newProduct.description"
            @keyup.enter="saveProduct()"
            rows="2"
          ></textarea>
        </div>

        <div class="form-group">
          <label>Editar al guardar?</label>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="switchEdit"
              v-model="editOnSave"
            />
            <label class="custom-control-label" for="switchEdit">
              {{ editOnSave ? "SI" : "NO" }}
            </label>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modalProductImage"
        size="lg"
        title="Subir imagen de producto"
        @ok="saveImage()"
      >
        <div class="row">
          <div class="col-3">
            <label for="">Archivo</label>
          </div>
          <div class="col">
            <b-form-file
              v-model="productImage"
              placeholder="Selecciona un archivo o arrastralo hasta aca..."
              drop-placeholder="Arrastra hasta aca..."
              accept=".jpg, .png"
            ></b-form-file>
          </div>
        </div>
      </b-modal>

      <!-- Modal Component -->
      <b-modal
        id="modal-edit"
        size="xl"
        modal-class="modal-full"
        scrollable
        @ok="updateProduct()"
        cancel-title="Salir sin guardar"
        ok-title="Guardar y salir"
        @hide="loadProducts()"
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->

          <div class="row align-items-end w-100">
            <div class="col-12 col-lg-6">
              <h5>Editar Producto (COD: {{ product.code }})</h5>
            </div>

            <div class="col-auto ml-auto d-lg-block">
              <button
                class="btn btn-outline-success btn-sm btn-block"
                @click="copyProduct()"
              >
                <font-awesome-icon icon="copy"></font-awesome-icon>
              </button>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-outline-primary btn-sm btn-block"
                @click="updateProduct()"
              >
                <font-awesome-icon icon="save"></font-awesome-icon>
              </button>
            </div>
            <div class="col-auto">
              <!-- cerrar -->
              <button
                class="btn btn-outline-danger btn-sm btn-block"
                @click="closeModal()"
              >
                <font-awesome-icon icon="times"></font-awesome-icon>
              </button>
            </div>
          </div>
        </template>
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-12 mb-2">
              <small class="d-none1">
                CREADO: {{ product.createdAt | moment("DD/MM/YY") }}</small
              >
              <small class="mx-4">
                <span class="text-muted" v-if="product.updatedAt"
                  >actualizado {{ $moment(product.updatedAt).fromNow() }}</span
                >
              </small>
            </div>
            <div class="col-md-2 col-4 text-center">
              <!-- <img
                class="img-fluid border"
                loading="lazy"
                :src="getImage(product.image || null)"
              /> -->
              <img
                class="img-fluid p-0"
                :src="`${getImage(product.image || null)}?${$moment(
                  product.updatedAt
                )}`"
                v-if="product.image"
              />
              <img class="img-fluid p-0" :src="getImage(null)" v-else />
              <div class="btn-group">
                <button
                  class="btn btn-sm btn-link btn-block"
                  v-b-modal.modalProductImage
                >
                  <font-awesome-icon icon="pen" size="sm"></font-awesome-icon>
                  <div class="w-100"></div>
                  <small>Editar</small>
                </button>
                <button
                  class="btn btn-sm btn-link btn-block text-danger mt-0"
                  @click="removeImage()"
                  v-if="product.image"
                >
                  <font-awesome-icon icon="times" size="sm"></font-awesome-icon>
                  <div class="w-100"></div>
                  <small>Quitar</small>
                </button>
              </div>
            </div>
            <div class="col">
              <div class="row align-items-center">
                <div class="col-3 col-md-1 d-none d-md-block">
                  <button
                    class="btn"
                    @click="toggleFeatured(product)"
                    :id="`btn-featured-${product._id}`"
                  >
                    <font-awesome-icon
                      size="2x"
                      :class="{
                        'text-warning': product.featured,
                        'text-muted': !product.featured,
                      }"
                      icon="star"
                    />
                  </button>
                  <b-tooltip
                    :target="`btn-featured-${product._id}`"
                    placement="bottom"
                  >
                    Producto destacado, aparecerá en accesos rapidos
                  </b-tooltip>
                </div>
                <div class="col-md-3 col-12">
                  <div class="form-group">
                    <label class="font-weight-bold">Codigo</label>
                    <input
                      class="form-control"
                      v-model="product.code"
                      :class="{
                        'is-valid': product.code.length >= 1,
                        'is-invalid': product.code.length < 1,
                      }"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label class="font-weight-bold">Nombre</label>
                    <input
                      class="form-control"
                      v-model="product.name"
                      :class="{
                        'is-invalid': product.name.length < 2,
                        'is-valid': product.name.length >= 2,
                      }"
                    />
                    <!-- <small class="text-muted">MINIMO 4 CARACTERES</small> -->
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label class="font-weight-bold">Precio de venta</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">$</div>
                      </div>
                      <input
                        class="form-control text-right"
                        v-model.number="product.value"
                        @focus="$event.target.select()"
                        @keyup.enter="updateProduct()"
                      />
                    </div>
                  </div>
                </div>

                <div class="w-100"></div>

                <div class="col">
                  <small class="font-weight-bold">Descripcion (Opcional)</small>
                  <textarea
                    class="form-control"
                    v-model="product.description"
                    rows="4"
                    placeholder="(Opcional)"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <b-tabs
            nav-class="font-weight-bold text-uppercase mt-4"
            active-nav-item-class=""
            content-class="p-3 bglight"
            pills
            fill
            v-model="productTab"
          >
            <b-tab title="Informacion">
              <div class="row">
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Categoria</label>
                    <v-select
                      v-model="product.category"
                      :reduce="(c) => c._id"
                      class=""
                      @input="updateProduct()"
                      append-to-body
                      :options="getCategories"
                    ></v-select>
                    <!-- <select
                      class="custom-select"
                      v-model="product.category"
                      @change="updateProduct()"
                    >
                      <option :value="null" disabled>
                        Seleccione una categoria
                      </option>
                      <option
                        v-for="category in categories"
                        :value="category._id"
                        :key="category._id"
                      >
                        {{ category.name }}
                      </option>
                    </select> -->
                    <button
                      class="btn btm-sm btn-link pl-0"
                      @click="addNewCategoryModal"
                    >
                      <small>AGREGAR CATEGORIA</small>
                    </button>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Precio de venta</label>
                    <input
                      class="form-control text-right"
                      v-model.number="product.value"
                      @focus="$event.target.select()"
                    />
                    <small class="text-muted">
                      SOLO NUMEROS. CON IMPUESTOS
                    </small>
                    <small
                      class="click"
                      @click="
                        product.value = parseInt(product.cost_value * 1.4)
                      "
                      v-if="product.cost_value"
                      >PRECIO SUGERIDO SEGUN COSTO (40%) ${{
                        (product.cost_value * 1.4) | formatNumber
                      }}</small
                    >
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold"
                      >Precio costo (Opcional)</label
                    >
                    <input
                      class="form-control text-right"
                      v-model="product.cost_value"
                      @focus="$event.target.select()"
                    />
                    <small class="text-muted"
                      >NUMEROS SIN ESPACIOS NI PUNTOS</small
                    >
                  </div>
                </div>

                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold"
                      >Fecha Precio costo (Opcional)</label
                    >
                    <date-picker
                      valueType="format"
                      v-model="product.cost_value_date"
                      input-class=" form-control text-center"
                    />

                    <small class="text-muted"
                      >FECHA DEL CAMBIO PRECIO COSTO, DEJAR EN BLANCO</small
                    >
                  </div>
                </div>

                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Unidad de medida</label>
                    <select class="form-control" v-model="product.unit">
                      <option value="Unid">UN - Unidad</option>
                      <option value="mt">MT - Metros</option>
                      <option value="cm">CM - Centimetros</option>
                      <option value="kg">KG -Kilogramos</option>
                      <option value="gr">GR -Gramos</option>
                      <option value="lb">LB - Libra</option>
                      <option value="lt">LT - Litros</option>
                      <option value="ml">Ml - Mililitros</option>
                    </select>
                    <small>SE USA PARA IMPRESION</small>
                  </div>
                </div>
                <div class="col">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-4">
                      <div class="row no-gutters align-items-end">
                        <div class="col">
                          <b-form-spinbutton
                            size="sm"
                            v-model="width"
                            min="1"
                            max="5"
                          ></b-form-spinbutton>
                        </div>
                        <div class="col-12">
                          <button
                            class="btn btn-outline-info btn-block btn-sm"
                            @click="printCode()"
                          >
                            <font-awesome-icon icon="print" size="sm" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <barcode
                        :width="width"
                        height="50"
                        :value="product.code"
                        id="barcode"
                        class="w-100"
                        fontSize="12"
                        font="sans-serif"
                        :flat="true"
                        :text="`${product.code}`"
                      >
                        Error al generar el codigo
                      </barcode>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Venta">
              <div class="row">
                <div class="col-3 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Se vende</label>
                    <select class="form-control" v-model="product.salable">
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="text-muted">SE VENDE O ES INVENTARIO</small>
                  </div>
                </div>
                <div class="col-5 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Impuesto</label>
                    <select
                      class="form-control"
                      v-model="product.iva"
                      :disabled="!product.salable"
                    >
                      <option :value="true">
                        Afecto a IVA (19%) - IVA incluido en el precio
                      </option>
                      <option :value="false">Exento a IVA</option>
                    </select>
                    <small class="text-muted"
                      >IMPUESTO INCLUIDO EN EL PRECIO</small
                    >
                  </div>
                </div>
                <div class="col-4 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Precio editable</label>
                    <select
                      class="form-control"
                      :disabled="!product.salable"
                      v-model="product.editableValue"
                    >
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="text-muted">CAMBIAR PRECIO AL VENDER</small>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label class="font-weight-bold">Solo disponible en</label>
                    <!-- <select
                        class="form-control"
                        v-model="product.available_on"
                        multiple
                      >
                        <option value="pickup">Retiro</option>
                        <option value="delivery">Domicilio</option>
                        <option value="shipping">Envio</option>
                      </select> -->
                    <div class="row py-2 border border-white">
                      <div class="col-4">
                        <b-form-checkbox
                          v-model="product.available_on"
                          size="lg"
                          value="pickup"
                          >Retiro</b-form-checkbox
                        >
                      </div>
                      <div class="col-4">
                        <b-form-checkbox
                          v-model="product.available_on"
                          size="lg"
                          value="delivery"
                          >Domicilio</b-form-checkbox
                        >
                      </div>
                      <div class="col-4">
                        <b-form-checkbox
                          v-model="product.available_on"
                          size="lg"
                          value="shipping"
                          >Envio</b-form-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col">
                  <div class="form-group">
                    <label class="font-weight-bold">Destacado</label>
                    <div class="btn-group btn-block">
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-secondary': !product.featured,
                          'btn-secondary': product.featured,
                        }"
                        @click="product.featured = true"
                      >
                        SI
                      </button>
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-secondary': product.featured,
                          'btn-secondary': !product.featured,
                        }"
                        @click="product.featured = false"
                      >
                        NO
                      </button>
                    </div>
                    <select
                      class="custom-select d-none"
                      v-model="product.featured"
                    >
                      <option value="false">No mostrar</option>
                      <option value="true">Mostrar al crear un ticket</option>
                    </select>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col" v-if="info.webEnabled">
                  <div class="form-group">
                    <label class="font-weight-bold">Mostrar en la web</label>

                    <div class="btn-group btn-block">
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-secondary':
                            !product.web || !product.web.enabled,
                          'btn-secondary': product.web && product.web.enabled,
                        }"
                        @click="addProductToWeb(product)"
                      >
                        SI
                      </button>
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-secondary':
                            product.web && product.web.enabled,
                          'btn-secondary': !product.web || !product.web.enabled,
                        }"
                        @click="removeFromWeb(product)"
                      >
                        NO
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Historial">
              <b-tabs
                vertical
                pills
                nav-class="text-uppercase"
                active-nav-item-class="font-weight-bold"
              >
                <b-tab title="Cambios">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-if="product_history && product_history.length === 0"
                    >
                      <div class="row py-5">
                        <div class="col text-center">
                          <strong>No hay cambios</strong>
                        </div>
                      </div>
                    </li>

                    <li
                      class="list-group-item"
                      v-for="h in product_history"
                      :key="h._id"
                    >
                      <div class="row">
                        <div class="col-auto">
                          <strong>{{
                            h.createdAt | moment("DD-MM-YY")
                          }}</strong>
                        </div>
                        <div class="col">
                          <strong>{{ h.name }}</strong>
                          {{ h.description }}
                        </div>
                        <div class="col-2 text-right">
                          <strong>${{ h.cost_value | formatNumber }}</strong>
                        </div>
                        <div class="col-2 text-right">
                          <strong>${{ h.value | formatNumber }}</strong>
                        </div>
                      </div>
                    </li>
                  </ul>
                </b-tab>
                <b-tab title="Inventario">
                  <div
                    style="max-height: 60vh"
                    class="overflow-auto custom_scroll"
                  >
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="h in product_inventory"
                        :key="h._id"
                      >
                        <div class="row">
                          <div class="col-auto">
                            <strong>{{
                              h.createdAt | moment("DD-MM-YY")
                            }}</strong>
                          </div>
                          <div class="col">
                            <span
                              class="badge badge-danger w-100"
                              v-if="h.type == 'out'"
                            >
                              SALIDA
                            </span>

                            <span
                              class="badge badge-success w-100"
                              v-if="h.type == 'in'"
                            >
                              INGRESO
                            </span>

                            <span
                              class="badge badge-primary w-100"
                              v-if="h.type == 'calculate'"
                            >
                              CALCULADO
                            </span>

                            <span
                              class="badge badge-primary w-100"
                              v-if="h.ticket"
                            >
                              VENTA TICKET
                            </span>
                            <span class="badge badge-info w-100" v-if="h.ot">
                              ORDEN DE TRABAJO
                            </span>
                          </div>
                          <div class="col-2 text-right">
                            <small>CANTIDAD</small>
                            <div class="w-100"></div>
                            {{ h.qty }}
                          </div>
                          <div class="col-2 text-right">
                            <small>COSTO</small>
                            <div class="w-100"></div>
                            ${{ h.cost_value || 0 | formatNumber }}
                          </div>
                          <div class="col-2 text-right">
                            <small>VALOR</small>
                            <div class="w-100"></div>
                            ${{ h.value | formatNumber }}
                          </div>
                          <div class="col-2 text-right">
                            <small>TOTAL</small>
                            <div class="w-100"></div>
                            ${{ (h.value * h.qty) | formatNumber }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab title="Stock">
              <div class="row">
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">Habilitar Stock</label>
                    <div class="btn-group btn-block">
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-secondary': !product.stock,
                          'btn-secondary': product.stock,
                        }"
                        @click="enableStock()"
                      >
                        Si
                      </button>
                      <button
                        class="btn"
                        :class="{
                          'btn-outline-secondary': product.stock,
                          'btn-secondary': !product.stock,
                        }"
                        @click="disableStock()"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-4" v-if="product.stock">
                  <div class="form-group">
                    <label class="font-weight-bold">Cantidad global</label>
                    <div class="input-group">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="product.qty"
                      />
                      <button
                        class="btn btn-outline-info btn-sm"
                        @click="calculateStock()"
                        :id="`btn-calculate-${product._id}`"
                      >
                        <font-awesome-icon icon="redo"></font-awesome-icon>
                      </button>
                      <b-tooltip
                        :target="`btn-calculate-${product._id}`"
                        placement="bottom"
                      >
                        Actualizar stock global. Sumará la cantidad en base a
                        las bodegas
                      </b-tooltip>
                    </div>
                    <small class="text-muted">Stock global del producto</small>
                  </div>
                </div>

                <div class="col-6 col-md-4" v-if="product.stock">
                  <div class="form-group">
                    <label class="font-weight-bold">Alerta stock</label>
                    <div class="input-group">
                      <input
                        type="number"
                        class="form-control"
                        v-model.number="product.stockAlertAt"
                        @focus="$event.target.select()"
                      />
                    </div>
                    <small class="text-muted"
                      >Cantidad que considera baja para notificar</small
                    >
                  </div>
                </div>

                <div class="w-100"></div>

                <div class="col-12" v-if="product.stock">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-8 col-md-5">
                          <small class="text-uppercase"
                            >BODEGA. EJ: LOCAL</small
                          >
                          <select
                            class="form-control"
                            v-model="productStock.storage"
                            :class="{
                              'is-invalid': productStock.storage == null,
                              'is-valid': productStock.storage != null,
                            }"
                          >
                            <option disabled v-if="storages.length == 0">
                              No tienes bodegas, agrega una abajo !
                            </option>
                            <option
                              :value="st._id"
                              v-for="st in storages"
                              :key="st._id"
                            >
                              {{ st.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <small class="text-uppercase">cantidad</small>
                          <input
                            type="text"
                            :class="{
                              'is-invalid': productStock.qty < 0,
                              'is-valid': productStock.qty >= 0,
                            }"
                            class="form-control"
                            @focus="$event.target.select()"
                            v-model.number="productStock.qty"
                            @keyup.enter="saveProductStock()"
                            min="0"
                            id="stockQtyInput"
                          />
                          <small class="text-muted d-none">
                            (para quitar use monto negativo)</small
                          >
                          <b-tooltip target="stockQtyInput"
                            >Ingrese por cantidad y presiona enter para guadar.
                            (para quitar use monto negativo)</b-tooltip
                          >
                        </div>
                        <div class="col">
                          <small class="text-uppercase">guardar</small>
                          <button
                            class="btn btn-block btn-success"
                            :disabled="productStock.qty == ''"
                            @click="saveProductStock()"
                          >
                            AGREGAR STOCK
                          </button>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-12 col-md-5 mt-0">
                          <button
                            class="btn btn-sm btn-link btn-block"
                            v-b-modal.modalNewBodega
                          >
                            <small>AGREGAR NUEVA BODEGA</small>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li
                      class="list-group-item"
                      v-for="storage in product.storages"
                      :key="storage._id"
                    >
                      <div class="row">
                        <div class="col">
                          <small class="d-block">BODEGA</small>
                          <span class="font-weight-bold">{{
                            storage.storage.name
                          }}</span>
                        </div>
                        <div class="col">
                          <small class="d-block">CANTIDAD</small>
                          <span class="font-weight-bold">
                            {{ storage.qty }} {{ product.unit }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="Proveedor">
              <div class="row">
                <div class="col-12">
                  <div class="alert alert-info">
                    <span class="badge badge-danger mr-4">NUEVO</span>
                    <span class="text-uppercase font-weight-bold"
                      >Ahora puedes registrar tus proveedores y filtrar tus
                      productos</span
                    >
                  </div>
                </div>
                <!-- create a row v-if providores is length 0 -->
                <div
                  class="col-12"
                  v-if="product.providers && product.providers.length == 0"
                >
                  <div class="alert alert-warning text-center">
                    <span class="text-uppercase font-weight-bold"
                      >Sin informacion</span
                    >
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col">
                      <label class="font-weight-bold">Proveedor</label>
                      <v-select
                        :options="providers"
                        v-model="provider"
                        taggable
                        appendToBody
                        :placeholder="'Seleccione un proveedor'"
                        :no-data-text="'No hay proveedores'"
                        @input="addProvider"
                      />
                    </div>
                  </div>
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="{ ref: provider, ...data } in product.providers"
                      :key="data._id"
                    >
                      <div class="row align-items-center">
                        <div class="col">
                          <small class="text-muted">NOMBRE</small>
                          <div class="w-100"></div>
                          <strong>{{ provider.label }}</strong>
                        </div>
                        <div class="col">
                          <small class="text-muted">TELEFONO</small>
                          <div class="w-100"></div>
                          <strong>{{
                            provider.phone || "- - - - - - -"
                          }}</strong>
                        </div>
                        <div class="col">
                          <small class="text-muted">CORREO</small>
                          <div class="w-100"></div>
                          <strong>{{
                            provider.email || "- - - - - - -"
                          }}</strong>
                        </div>
                        <div class="ml-auto col-2">
                          <button
                            class="btn btn-sm btn-danger"
                            @click="removeProvider(provider)"
                          >
                            <font-awesome-icon icon="trash" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="Promos" class="d-none1">
              <div class="row">
                <div class="col-12">
                  <h4
                    class="text-center py-4"
                    v-if="product.promos && product.promos.length == 0"
                  >
                    No tienes promociones
                  </h4>
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(promo, pid) in product.promos"
                      :key="pid"
                    >
                      <div class="row align-items-center">
                        <div class="col-4 col-md">
                          <small class="d-block font-weight-bold">CREADO</small>
                          {{ promo.createdAt | moment("DD/MM/YY") }}
                        </div>
                        <div class="col-4 col-md">
                          <small class="d-block font-weight-bold"
                            >DESCONTAR</small
                          >
                          {{ promo.in }}{{ formatNumber(promo.discount) }} al
                          {{ promo.to }}
                        </div>
                        <div class="col-4 col-md">
                          <small class="d-block font-weight-bold">CUANDO</small>
                          {{ promo.when }} es {{ promo.is }} {{ promo.value }}
                        </div>
                        <div class="col-4 col-md">
                          <b-form-checkbox
                            v-model="promo.enabled"
                            name="check-button"
                            switch
                          >
                            Habilitar
                          </b-form-checkbox>
                        </div>
                        <div class="ml-auto col-2">
                          <button
                            class="btn btn-link text-danger btn-block btn-sm"
                            @click="removePromo(promo)"
                          >
                            <font-awesome-icon icon="trash"></font-awesome-icon>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="col-12 mt-4">
                  <div class="row align-items-end">
                    <div class="col-4 col-md">
                      <small>CUANDO</small>
                      <select class="form-control" v-model="promo.when">
                        <option value="qty">Cantidad</option>
                        <option value="minopcion">Opcion mas baja</option>
                        <option value="total" disabled>Total</option>
                        <option value="maxopcion" disabled>
                          Opcion mas grande
                        </option>
                      </select>
                    </div>
                    <div class="col-4 col-md">
                      <small>SEA</small>
                      <select class="form-control" v-model="promo.is">
                        <option value="lt">Menor</option>
                        <option value="lte">Menor o igual</option>
                        <option value="eq">Igual</option>
                        <option value="gte">Mayor o igual</option>
                        <option value="gt">Mayor</option>
                      </select>
                    </div>
                    <div class="col-4 col-md">
                      <small>A</small>
                      <input
                        @focus="$event.target.select()"
                        type="number"
                        class="form-control"
                        v-model.number="promo.value"
                      />
                    </div>
                    <div class="col-4 col-md">
                      <small>DESCONTAR</small>
                      <input
                        @focus="$event.target.select()"
                        type="number"
                        class="form-control"
                        v-model.number="promo.discount"
                      />
                    </div>
                    <div class="col-4 col-md">
                      <small>EN</small>
                      <select class="form-control" v-model="promo.in">
                        <option value="%">%</option>
                        <option value="$">$</option>
                      </select>
                    </div>
                    <div class="col-4 col-md">
                      <small>AL</small>
                      <select class="form-control" v-model="promo.to">
                        <option value="total">Total</option>
                        <option value="product">Producto</option>
                        <option value="option">Opcion</option>
                      </select>
                    </div>
                    <div class="col-12 col-md mt-2">
                      <button
                        class="btn btn-outline-success btn-block"
                        @click="addPromo()"
                        :disabled="
                          (products.promos && product.promos.length >= 2) ||
                          false
                        "
                      >
                        AGREGAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Opciones">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Opciones</label>
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        :key="option._id"
                        v-for="option in product.newOptions"
                      >
                        <div class="row align-items-center">
                          <div class="col-1 d-none">
                            <h3>{{ option.sort }}</h3>
                          </div>
                          <div class="col-5">
                            <strong class="text-uppercase">{{
                              option.ref.name
                            }}</strong>
                            <div class="w-100"></div>
                            <small class="text-muted">Nombre interno: </small>
                            <small>{{ option.ref.label }}</small>
                          </div>
                          <div class="col-4">
                            <small>MOSTRAR COMO</small>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="option.custom_label"
                                @blur="
                                  updateCustomLabel(
                                    option._id,
                                    option.custom_label
                                  )
                                "
                                @keyup.enter="
                                  updateCustomLabel(
                                    option._id,
                                    option.custom_label
                                  )
                                "
                              />
                              <button
                                class="btn btn-outline-success btn-sm"
                                @click="
                                  updateCustomLabel(
                                    option._id,
                                    option.custom_label
                                  )
                                "
                              >
                                OK
                              </button>
                            </div>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-2 text-uppercase">
                            {{ option.ref.type }}
                          </div>
                          <div class="col-2" v-if="option.ref.type == 'select'">
                            <b-form-checkbox
                              v-model="option.ref.multiple"
                              value="true"
                              disabled
                              class="d-inline-block"
                              unchecked-value="false"
                            >
                              Multiple
                            </b-form-checkbox>
                          </div>
                          <div
                            class="col-2"
                            v-if="
                              (option.ref.type == 'select' ||
                                option.ref.type == 'number') &&
                              option.ref.multiple
                            "
                          >
                            <strong class="mr-4"
                              >MIN: {{ option.ref.min }}</strong
                            >
                            <strong>MAX: {{ option.ref.max }}</strong>
                          </div>
                          <div class="ml-auto col-1">
                            <button
                              class="btn btn-sm btn-block btn-danger"
                              @click="
                                removeOptionFromProduct(product._id, option._id)
                              "
                            >
                              <font-awesome-icon icon="trash" />
                            </button>
                          </div>
                          <div class="col-12 mt-1">
                            <div class="">
                              <div
                                class="badge mr-1"
                                :class="{
                                  'badge-success badge-pill': op.enabled,
                                  'badge-danger text-light': !op.enabled,
                                }"
                                v-for="({ _id: op }, i) in option.ref.options"
                                :key="i"
                              >
                                <span class="font-weight-bold px-2">
                                  {{ op.name }}
                                </span>
                                <span class="mr-2">${{ op.value }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row align-items-end">
                          <div class="col">
                            <v-select
                              v-model="option"
                              append-to-body
                              :options="getOptions"
                            ></v-select>
                          </div>
                          <div class="col-3">
                            <button
                              class="btn btn-block btn-outline-secondary"
                              @click="addOptionToProduct()"
                              :disabled="!option._id"
                            >
                              AGREGAR
                            </button>
                          </div>
                          <div class="col-12">
                            <button
                              class="btn btn-link"
                              v-b-modal.modal-options
                            >
                              Administrar opciones
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Borrar">
              <div class="text-center">
                <h5>Esta seguro que desea borrar el producto?</h5>
                <div class="w-100 py-4"></div>
                <small>ESCRIBA EL CODIGO DEL PRODUCTO PARA CONFIRMAR</small>
                <input
                  type="text"
                  class="form-control w-50 mx-auto text-center"
                  v-model="deleteConfirm"
                />
                <div class="w-100 py-2"></div>
                <button
                  class="btn btn-danger w-50 btn-lg"
                  :disabled="deleteConfirm != product.code"
                  @click="deleteProduct(product._id)"
                >
                  Borrar producto
                </button>
                <div class="w-100"></div>
                <small
                  >EL CODIGO NO PODRA USARSE PARA CREAR OTRO PRODUCTO</small
                >
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </b-modal>

      <b-modal
        id="modalNewCategory"
        size="xs"
        ref="modalNewCategory"
        title="Nueva categoria"
        @ok="saveCategory()"
      >
        <label for="">Nombre</label>
        <input
          type="text"
          v-model="category_name"
          ref="category_name"
          class="form-control"
          :class="{
            'is-invalid': !category_name,
            'is-valid': category_name,
          }"
          @keyup.enter="saveCategory()"
        />
      </b-modal>

      <b-modal
        id="modalNewBodega"
        size="md"
        title="Administrar bodega"
        @ok="saveStorage()"
      >
        <label for="">Nombre</label>
        <input
          type="text"
          v-model="storage.name"
          class="form-control"
          v-focus
          @keyup.enter="saveCategory()"
        />
      </b-modal>
    </div>

    <ul class="list-group mt-4 pb-5">
      <li
        class="list-group-item pr-4 animate__animated animate__fadeIn"
        v-if="productList.length == 0 && loading == false"
      >
        <h4 class="text-center py-4">No tienes productos</h4>
      </li>
      <li class="list-group-item" v-if="loading && false">
        <div
          class="row align-items-center py-4"
          v-for="i in 5"
          :key="`skeleton-${i}`"
        >
          <div class="col-1">
            <b-skeleton-img></b-skeleton-img>
          </div>
          <div class="col-3">
            <b-skeleton animation="throb" width="100%"></b-skeleton>
            <div class="w-100 py-1"></div>
            <b-skeleton animation="throb" width="55%"></b-skeleton>
            <b-skeleton animation="throb" width="45%"></b-skeleton>
            <b-skeleton animation="throb" width="35%"></b-skeleton>
            <b-skeleton animation="throb" width="55%"></b-skeleton>
            <b-skeleton animation="throb" width="40%"></b-skeleton>
          </div>
          <div class="col-4">
            <b-skeleton
              class="center"
              animation="throb"
              width="20%"
            ></b-skeleton>
          </div>
          <div class="col-2">
            <b-skeleton
              class="center"
              animation="throb"
              width="50%"
            ></b-skeleton>
          </div>

          <div class="col-1 text-center">
            <b-skeleton
              class="center"
              animation="throb"
              width="30%"
            ></b-skeleton>
          </div>
        </div>
        <div class="text-center d-none">
          <div class="mt-3">
            <b-spinner class="ml-auto"></b-spinner>
            <strong class="d-block">Cargando...</strong>
          </div>
        </div>
      </li>
      <li
        class="list-group-item pr-4 click list-group-item-action animate__animated animate__fadeIn"
        v-for="(product, i) in productList"
        :key="`p${product._id}`"
        @click="editProduct(product._id, i, product)"
        :class="{
          'list-group-item-warning':
            product.stock && product.qty <= product.stockAlertAt,
          'list-group-item-danger': product.stock && product.qty <= 0,
          active: product._id == pid,
        }"
      >
        <div class="row h-100 align-items-center">
          <div class="col-2 d-none col-sm-2 col-md-2 col-xl-1 p-0 d-md-block">
            <img
              class="img-fluid p-0"
              :src="`${getImage(product.image || null)}?${$moment(
                product.updatedAt
              )}`"
              v-if="product.image"
            />
            <img class="img-fluid p-0" :src="getImage(null)" v-else />
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 col-md">
                <h5 class="mb-1 text-uppercase font-weight-bolder">
                  <span
                    class="px-1"
                    v-if="product.webproduct && product.webproduct.enabled"
                  >
                    <font-awesome-icon
                      class="text-primary bg-white rounded-circle border border-white"
                      icon="globe"
                    />
                  </span>
                  <span class="px-1" v-if="product.featured">
                    <font-awesome-icon class="text-warning" icon="star" />
                  </span>
                  {{ product.name }}
                </h5>
              </div>
              <div class="col-6 col-md-2">
                <span class="badge badge-primary py-2 w-100 text-uppercase">
                  {{ productCategory(product.category) }}
                </span>
              </div>
              <div class="ml-auto col-4 col-md-2 text-right">
                <h5 class="font-weight-bold py-2">
                  ${{ formatNumber(product.value) }}
                </h5>
              </div>
              <div class="w-100"></div>
              <div class="col-8 col-md-10">
                <small class="font-italic text-uppercase">{{
                  product.description || ""
                }}</small>
              </div>
            </div>

            <div class="border-bottom w-100 mb-2 pb-2"></div>

            <div class="row">
              <div class="col-3 d-none px-0">
                <img
                  class="img-fluid border px-0"
                  :src="getImage(product.image || null)"
                />
              </div>
              <div class="col-6 col-lg-3">
                <small class="d-block"
                  ><strong>CODIGO:</strong>
                  {{ product.code }}
                </small>
                <small class="d-block"
                  ><strong>CREADO:</strong>
                  {{ product.createdAt | moment("DD/MM/YY") }}
                </small>
                <small class="d-block"
                  ><strong>ACTUALIZADO:</strong>
                  {{ product.updatedAt | moment("DD/MM/YY") }}
                </small>
              </div>
              <div class="col-6 col-lg-3">
                <small class="d-block">
                  <strong>STOCK:</strong>
                  {{ product.stock ? "Habilitado" : "Deshabilitado" }}
                </small>
                <small class="d-block" v-if="product.stock">
                  <strong>CANTIDAD:</strong>
                  {{ product.qty | formatNumber }}
                </small>
                <small class="d-block" v-if="product.stock && product.storages">
                  <strong>BODEGAS:</strong>
                  {{ product.storages.length }}
                </small>
              </div>
              <div class="col-6 col-lg-3" v-if="product.cost_value">
                <small class="d-block"
                  ><strong>COSTO UNIDAD:</strong> ${{
                    product.cost_value | formatNumber
                  }}
                </small>
                <small class="d-block"
                  ><strong>TOTAL COSTO:</strong> ${{
                    (product.qty * product.cost_value) | formatNumber
                  }}
                </small>
                <small class="d-block"
                  ><strong>TOTAL VENTA: </strong> ${{
                    (product.value * product.qty) | formatNumber
                  }}
                </small>
              </div>
              <div class="col-6 col-lg-3" v-if="true">
                <small class="d-block"
                  ><strong>GANANCIA UNIDAD:</strong> ${{
                    (product.value - product.cost_value) | formatNumber
                  }}
                </small>
                <small class="d-block"
                  ><strong>TOTAL GANANCIA:</strong> ${{
                    ((product.value - product.cost_value) * product.qty)
                      | formatNumber
                  }}
                </small>
                <small class="d-none1"
                  ><strong>PORC. GANANCIA: </strong>
                  {{ getPorcentaje(product) }}%
                </small>
              </div>
            </div>
          </div>

          <div
            class="d-none d-md col-1 click"
            @click="editProduct(product._id, i)"
          >
            <font-awesome-icon icon="pen" />
            <div class="dropdown d-none" v-if="false">
              <button
                class="btn"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon icon="ellipsis-h" />
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  class="dropdown-item"
                  href="#"
                  @click="editProduct(product._id)"
                >
                  Editar
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- pagination -->
    <div
      class="overflow-auto"
      style="position: fixed; left: 0; bottom: 30px; width: 100%; z-index: 999"
    >
      <div class="container mb-0">
        <b-pagination-nav
          v-model="page"
          base-url="#"
          align="fill"
          :number-of-pages="products.length / limit + 1"
        ></b-pagination-nav>
      </div>
    </div>

    <!-- end pagination -->

    <manage-options ref="manageOptions" />

    <b-modal ref="modal-providers" title="Proveedores" size="xl">
      <div class="row">
        <div class="col-4 ml-auto">
          <div class="form-group">
            <button class="btn btn-success btn-block" @click="editProvider()">
              CREAR PROVEEDOR
            </button>
          </div>
        </div>
        <div class="col-12">
          <ul class="list-group">
            <!-- headers -->
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col">
                  <strong>Nombre</strong>
                </div>
                <div class="col">
                  <strong>Direccion</strong>
                </div>
                <div class="col">
                  <strong>Telefono</strong>
                </div>
                <div class="col">
                  <strong>Notas</strong>
                </div>
                <div class="col-2"></div>
              </div>
            </li>
            <li class="list-group-item" v-for="pro in providers" :key="pro._id">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">{{ pro.name }}</strong>
                  <small>{{ pro.email || "Sin correo" }}</small>
                </div>
                <div class="col">
                  <span>{{ pro.address || "Sin direccion" }}</span>
                </div>
                <div class="col">
                  <span>{{ pro.phone || "Sin telefono" }}</span>
                </div>
                <div class="col">
                  <span>{{ pro.notes || "" }}</span>
                </div>
                <div class="col-2 text-right">
                  <button
                    class="btn btn-sm btn-info mx-1"
                    @click="editProvider(pro)"
                  >
                    <font-awesome-icon icon="pen" />
                  </button>
                  <button
                    class="btn btn-sm btn-danger mx-1"
                    @click="deleteProvider(pro)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal ref="edit-provider" title="Editar proveedor" size="md" hide-footer>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="">Nombre (Obligatorio)</label>
            <input type="text" class="form-control" v-model="provider.name" :class="{
              'is-invalid': !provider.name
            }" />
          </div>
          <div class="form-group">
            <label for="">RUT</label>
            <input type="text" class="form-control" v-model="provider.rut" />
          </div>
          <div class="form-group">
            <label for="">Direccion</label>
            <input
              type="text"
              class="form-control"
              v-model="provider.address"
            />
          </div>
          <div class="form-group">
            <label for="">Telefono</label>
            <input type="text" class="form-control" v-model="provider.phone" />
          </div>
          <div class="form-group">
            <label for="">Correo</label>
            <input type="text" class="form-control" v-model="provider.email" />
          </div>
          <div class="form-group">
            <label for="">Notas</label>
            <textarea class="form-control" v-model="provider.notes"></textarea>
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-block" @click="saveProvider()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="scannerModal">
      <b-form-file
        v-model="productImageCode"
        placeholder="Selecciona un archivo o arrastralo hasta aca..."
        drop-placeholder="Arrastra hasta aca..."
        accept=".jpg, .png"
      ></b-form-file>
    </b-modal>

    <b-modal ref="modal-categories" title="Categorias" size="xl">
      <div class="row">
        <div class="col-lg-4">
          <button
            class="btn btm-sm btn-link py-0 btn-block"
            @click="addNewCategoryModal"
          >
            <small>AGREGAR CATEGORIA</small>
          </button>
          <ul class="list-group custom_scroll" style="max-height: 60vh">
            <li
              v-for="c in categories"
              :key="c._id"
              class="list-group-item list-group-item-action click"
              :class="{
                'active font-weight-bold': (category || {})._id === c._id,
              }"
              @click="selectCategory(c)"
            >
              {{ c.name }}
            </li>
          </ul>
        </div>
        <div class="col" v-if="category">
          <div class="row">
            <div class="col-12">
              <h3>Editar categoria</h3>
            </div>
            <div class="col-12">
              <b-form-input
                v-model="category.name"
                placeholder="Nombre de la categoria"
                :class="{
                  'is-invalid': !category.name,
                  'is-valid': category.name,
                }"
              ></b-form-input>
            </div>
            <div class="col-12 py-2">
              <b-form-input
                v-model="category.image_url"
                placeholder="Imagen de la categoria"
              ></b-form-input>
            </div>
            <div class="col-12 py-2">
              <b-form-textarea
                v-model="category.description"
                placeholder="Descripcion de la categoria"
                rows="3"
              ></b-form-textarea>
            </div>
            <div class="col-12 btn-group mt-4">
              <b-button
                variant="danger"
                @click="deleteCategory()"
                :disabled="!category.name"
              >
                Borrar
              </b-button>
              <b-button
                variant="primary"
                @click="updateCategory()"
                :disabled="!category.name"
              >
                Guardar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import { ipcRenderer, storage } from "../multi-utils";
import { log } from "../utils";
import manageOptions from "../components/modal/manageOptions.vue";

export default {
  name: "ProductList",
  components: {
    barcode: VueBarcode,
    manageOptions,
  },
  data() {
    return {
      page: 1,
      filterDeleted: "",
      cost_value: null,
      editable: null,
      saving: false,
      editOnSave: true,
      deleteConfirm: "",
      index: -1,
      product_inventory: [],
      productImageCode: null,
      product_history: {},
      category: null,
      selectedCategory: "",
      categories: [],
      loading: false,
      storages: [],
      storage_selected: null,
      newProduct: {
        name: "",
        code: "",
        iva: true,
        value: 0,
        image: "",
        featured: false,
        unit: "Unid",
        cost_value: null,
        category: null,
        stock: false,
        qty: 0,
        options: [],
      },
      filter: "",
      product: {
        name: "",
        code: "",
        image: "",
        newOptions: [],
        promos: [],
        providers: [],
      },
      option: {
        label: "",
        value: "",
      },
      pid: "",
      sort: "name",
      show_out: false,
      category_name: "",
      timeout: null,
      productImage: null,
      storage: {
        name: "",
      },
      productStock: {
        storage: null,
        qty: 0,
      },
      fakeFilter: "",
      limit: 30,
      values: [],
      productTab: null,
      width: 2,
      productList: [],
      promo: {
        when: "qty",
        is: "lt",
        value: 0,
        discount: 0,
        in: "%",
        to: "product",
      },
      deletedProducts: [],
      selectedProvider: null,
      provider: {
        _id: null,
        name: "",
      },
      providers: [],
    };
  },
  mounted() {
    if (!this.hasPermission(this.user, "products"))
      return this.$router.push("/");
    this.loadProducts();
    this.filterProducts();
    this.loadStorages();
    this.loadOptions();
    // this.loadDeletedProducts();
    this.loadProviders();
    window.addEventListener("keyup", this.keyDown);
    this.$nextTick(() => {
      this.$refs["searchInput"].focus();
    });
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyDown);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    products() {
      return this.$store.state.products;
    },
    getProducts() {
      if (!this.products) return [];
      return this.products.map((o) => ({
        ...o,
        label: `${o.code} - ${o.name}`,
      }));
    },
    getCategories() {
      if (!this.categories) return [];
      return this.categories.map((o) => ({
        ...o,
        label: `${o.name}`,
      }));
    },
    options() {
      return this.$store.state.options;
    },
    info() {
      return this.$store.state.info;
    },
    getOptions() {
      if (!this.options) return [];
      return this.options.map((o) => ({
        ...o,
        label: `${o.label} - ${o.name}`,
      }));
    },
  },
  methods: {
    async deleteProvider(provider) {
      const confirm = await this.$swal({
        title: "Estas seguro?",
        text: "Esta accion no se puede deshacer",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirm) return;

      this.$http
        .delete(`product/provider/${provider._id}`)
        .then(() => {
          this.$noty.success("Proveedor eliminado");
          this.loadProviders();
        })
        .catch(() => {
          this.$noty.error("Error al eliminar proveedor");
        });
    },
    saveProvider() {
      if (!this.provider || !this.provider.name)
        return this.$noty.error("Nombre del proveedor es requerido");

      if (this.provider._id) {
        this.$http
          .patch(`product/provider/${this.provider._id}`, this.provider)
          .then(() => {
            this.$noty.success("Proveedor actualizado");
            this.loadProviders();
            this.$refs["edit-provider"].hide();
          })
          .catch(() => {
            this.$noty.error("Error al actualizar proveedor");
          });
      } else {
        this.$http
          .post(`product/provider`, this.provider)
          .then(() => {
            this.$noty.success("Proveedor creado");
            this.loadProviders();
            this.$refs["edit-provider"].hide();
          })
          .catch(() => {
            this.$noty.error("Error al crear proveedor");
          });
      }
    },
    editProvider(provider) {
      this.provider = provider || {
        _id: null,
        name: "",
        address: "",
        phone: "",
        email: "",
        notes: "",
      };
      this.$refs["edit-provider"].show();
    },
    getPorcentaje(product) {
      let cost = product.cost_value;
      let value = product.value;
      let diff = value - cost;
      //console.log(product.name, diff, cost, value);
      let total = (diff * 100) / value;
      if (!cost || !value) return "--";
      if (cost == value) return 0;
      return total.toFixed(2);
    },
    closeModal() {
      this.$bvModal.hide("modal-edit");
    },
    downloadExcel() {
      console.log("downloading file");
      try {
        let products = this.productList;
        let csv = "CODIGO;NOMBRE;CATEGORIA;COSTO;PRECIO;STOCK;STOCK MINIMO\n";
        products.forEach((product) => {
          //let providers = product.providers && product.providers.map((p) => p.ref.name).join(", ");
          csv += `${product.code};${product.name};${this.productCategory(
            product.category
          )};${product.cost_value};${product.value};${product.qty};${
            product.stockAlertAt || "No aplica"
          }\n`;
        });
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        let date = this.$moment().format("DD-MM-YYYY");
        let name = "productos";
        if (this.selectedCategory)
          name += ` ${this.productCategory(this.selectedCategory)}`;
        link.setAttribute("download", `${name} ${date}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
      }
    },
    productCategory(category) {
      if (category && category._id) {
        return category.name;
      }
      const categoryFind = this.categories.find((c) => c._id === category);
      if (categoryFind && categoryFind.name) return categoryFind.name;
      if (!category) return "Sin Categoria";
    },
    updateCategory() {
      this.$http
        .put(`category/${this.category._id}`, this.category)
        .then(() => {
          this.$noty.success("Categoria actualizada");
          this.loadCategories();
        })
        .catch(() => {
          this.$noty.error("Error al actualizar categoria");
        });
    },
    deleteCategory() {
      this.$http
        .delete(`category/${this.category._id}`)
        .then(() => {
          this.$noty.success("Categoria eliminada");
          // clear values
          this.category = {
            name: "",
            description: "",
            _id: null,
          };
          this.loadCategories();
        })
        .catch(() => {
          this.$noty.error("Error al eliminar categoria");
        });
    },
    selectCategory(category) {
      this.category = category;
    },
    showModalCategories() {
      this.$refs["modal-categories"].show();
    },
    deleteAll() {
      this.$http
        .delete("product/deleteall")
        .then(() => {
          this.$noty.success("Productos eliminados");
          this.loadProducts();
        })
        .catch(() => {
          this.$noty.error("Error al eliminar productos");
        });
    },
    showScannerModal() {
      this.$refs["scannerModal"].show();
    },
    addAllToWeb() {
      this.loading = true;
      // confirm swal
      this.$swal({
        title: "Estas seguro?",
        text: "Esta accion agregara todos los productos al sitio web",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, agregar",
        cancelButtonText: "Cancelar",
        buttons: true,
      })
        .then((result) => {
          console.log(result);
          if (result) {
            let products = this.products.filter((p) => {
              if (p.webproduct && !p.webproduct.enabled) return true;
              if (p.webproduct == null) return true;
              return false;
            });

            let promises = products.map((p) => {
              return this.$http.post(`web/product`, p);
            });

            Promise.all(promises)
              .then(() => {
                this.$noty.success("Productos agregados a la web");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyProduct() {
      this.loading = true;
      this.$http
        .post(`product/${this.product._id}/copy`)
        .then((res) => {
          this.$noty.success("Producto copiado");
          this.editProduct(res.data.data._id);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleFeatured() {
      this.product.featured = !this.product.featured;
      this.updateProduct();
    },
    checkCode(p) {
      if (p.code == "") return false;
      this.$http.get(`product/check/${p.code}`).catch(() => {
        this.$noty.error("El codigo ingresado ya existe en el sistema");
        p.code = "";
        this.$refs["inputCode"].focus();
      });
    },

    keyDown(e) {
      if (e.key == "F3") {
        this.filter = "";
        this.$refs["searchInput"].focus();
        e.preventDefault();
      }
      if (e.key == "F2") {
        this.showNewModal();
        e.preventDefault();
      }
    },
    showNewModal() {
      this.$refs["modal"].show();
      setTimeout(() => {
        this.$refs["inputCode"].focus();
      }, 1000);
    },
    showModalProviders() {
      this.$refs["modal-providers"].show();
    },

    removeProvider(provider) {
      this.product.providers = this.product.providers.filter(
        (p) => p.ref._id != provider._id
      );
    },
    addProvider() {
      log({ provider: this.provider });
      this.loading = true;
      if (this.provider._id) {
        let find = this.product.providers.find(
          (p) => p.ref._id == this.provider._id
        );
        if (find) {
          this.$noty.error("El proveedor ya esta agregado");
          return;
        }
        this.$http
          .post(`product/${this.product._id}/provider`, {
            provider: this.provider._id,
          })
          .then(() => {
            this.editProduct(this.product._id);
            this.provider = {
              name: "",
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        log("provider", this.provider);
        this.$http
          .post("product/provider", {
            label: this.provider,
            name: this.provider,
            phone: "",
            email: "",
            notes: "",
          })
          .then((res) => {
            return this.$http.post(`product/${this.product._id}/provider`, {
              provider: res.data.data._id,
            });
          })
          .then(() => {
            this.loadProviders();
            this.editProduct(this.product._id);
            this.provider = "";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    addProviderToProduct() {},
    loadProviders() {
      this.$http
        .get("product/providers")
        .then((response) => {
          this.providers = response.data.data.map(p => {
            return {
              ...p,
              label: p.label || p.name
            }
          });
        })
        .catch((error) => {
          log(error);
        });
    },
    restore(id) {
      this.$http
        .post(`product/${id}/restore`)
        .then(() => {
          this.loadDeletedProducts();
          this.loadProducts();
          this.$noty.success("Producto restaurado");
        })
        .catch(() => {
          this.$noty.error("Error intente nuevamente");
        });
    },
    showModal() {
      this.filterDeleted = "";
      this.loadDeletedProducts();
      this.$refs["modal-deleted"].show();
    },
    loadDeletedProducts() {
      const params = new URLSearchParams();

      params.append("filter", this.filterDeleted);

      this.$http.get(`product/deleted?${params}`).then((res) => {
        this.deletedProducts = res.data.data;
      });
    },
    loadOptions() {
      this.loading = true;
      this.$http
        .get("option")
        .then((res) => {
          this.$store.commit("setOptions", res.data.data);
        })
        .finally(() => (this.loading = false));
    },
    showModalOptions() {
      this.$refs["manageOptions"].showModal();
    },

    removeImage() {
      this.$http
        .delete(`product/${this.product._id}/image`)
        .then(() => {
          this.updateProduct();
        })
        .catch(() => {
          this.$noty.error("Error al borrar la imagen");
        });
    },
    removeFromWeb() {
      this.loading = true;
      this.$http
        .delete(`web/product/` + this.product._id)
        .then(() => {
          this.loadProducts();
          this.editProduct(this.product._id);
        })
        .finally(() => (this.loading = false));
    },
    addProductToWeb() {
      this.loading = true;
      this.$http
        .post(`web/product`, this.product)
        .then(() => {
          this.loadProducts();
          this.editProduct(this.product._id);
        })
        .finally(() => (this.loading = false));
    },
    updateCustomLabel(id, label) {
      this.$http
        .post(`product/${this.product._id}/option/${id}`, {
          label,
        })
        .then(() => {
          this.$noty.success("Actualizado correctamente");
        });
    },

    removePromo(promo) {
      this.product.promos.splice(this.product.promos.indexOf(promo), 1);
    },
    addPromo() {
      this.product.promos.push({
        ...this.promo,
        enabled: true,
        createdAt: Date.now(),
      });
    },
    filterProducts() {
      this.loading = true;
      let filter = (p) => {
        console.log("filtering");
        p.words = 0;
        let words = this.filter.trim().toLowerCase().split(" ");
        let pwords = p.name.toLowerCase().split(" ");

        if (this.editable != null) {
          if (p.editableValue === this.editable) {
            p.words = p.words + 1000;
            return p;
          }
        }

        if (this.cost_value == true) {
          if (p.cost_value) {
            p.words = p.words + 1000;
            return p;
          }
        } else if (this.cost_value == false) {
          if (!p.cost_value) {
            p.words = p.words + 1000;
            return p;
          }
        }

        if (this.filter == "") {
          return null;
        }

        // check code
        if (this.filter.toLowerCase() == p.code.toString().toLowerCase()) {
          p.words = p.words + 500;
          return p;
        }
        // check words
        if (pwords.join(" ").indexOf(words.join(" ")) != -1) {
          p.words = p.words + 100;
          // return p;
        }

        if (pwords.some((w) => words.includes(w))) {
          pwords.forEach((pw) => {
            if (words.includes(pw)) p.words = p.words + 10;
            if (words.join(" ").indexOf(pw) != -1) p.words = p.words + 5;
            words.forEach((w) => {
              if (pwords.join(" ").indexOf(w) != -1) p.words = p.words + 5;
            });
          });
          return p;
        }
        if (p.words > words.length * 5) return p;
      };
      let sort = (a, b) => {
        if (a.words > b.words) return -1;
        if (a.words < b.words) return 1;
        return 0;
      };

      let products;

      if (this.filter != "" || this.editable != null || this.cost_value != null)
        products = this.products.filter(filter).sort(sort);
      else products = this.products;

      // use this.page
      this.productList = products.slice(
        (this.page - 1) * this.limit,
        this.page * this.limit
      );

      // if(this.productList.length == 1) {
      //   this.editProduct(this.productList[0]._id);
      // }

      this.loading = false;
      // if (this.productList.length == 1) {
      //   this.editProduct(this.productList[0]._id);
      // }
    },
    printCode() {
      var svgElement = document.getElementById("barcode").children[0];

      // Serialize the svg to string
      var svgString = new XMLSerializer().serializeToString(svgElement);

      // Remove any characters outside the Latin1 range
      var decoded = unescape(encodeURIComponent(svgString));

      // Now we can use btoa to convert the svg to base64
      var base64 = btoa(decoded);

      storage.get("printer", (err, data) => {
        ipcRenderer.send("print", {
          printerName: data,
          _id: this.product,
          url: base64,
        });
        this.$noty.success("Codigo enviado a imprimir");
      });
    },
    removeOptionFromProduct(pid, oid) {
      this.$http
        .delete(`product/${pid}/option`, { body: { oid: oid } })
        .then(() => this.editProduct(this.product._id));
    },
    addOptionToProduct() {
      this.$http
        .post(`product/${this.product._id}/option`, this.option)
        .then(() => {
          this.option = {};
          this.loadOptions();
          this.editProduct(this.product._id);
        });
    },

    enableStock() {
      this.product.stock = true;
      this.updateProduct();
    },
    disableStock() {
      this.product.stock = false;
      this.updateProduct();
    },
    calculateStock() {
      this.$http
        .patch(`product/${this.product._id}/stock`)
        .then(() => {
          this.editProduct(this.product._id);
          this.$noty.success("Stock actualizado");
        })
        .catch(() => {
          this.$swal({
            title: "Error",
            text: "Revise informacion e intente nuevamente",
            icon: "error",
          });
        });
    },
    saveProductStock() {
      if (this.productStock.storage == null) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Seleccione una bodega valida",
        });
      } else {
        this.$http
          .post(`product/${this.product._id}/stock`, this.productStock)
          .then(() => {
            // this.productStock.storage = null;
            this.productStock.qty = 0;
            this.editProduct(this.product._id);
          })
          .catch(() => {
            this.$swal({
              title: "Error",
              text: "Revise informacion e intente nuevamente",
              icon: "error",
            });
          });
      }
    },

    loadStorages() {
      this.$http.get("storage").then((res) => {
        this.storages = res.data.data;
        // set first storage
        if (!this.productStock.storage && res.data.data[0])
          this.productStock.storage = res.data.data[0]._id;
      });
    },
    saveStorage() {
      if (this.storage.name.length < 3) {
        this.$swal({
          title: "Error",
          text: "Debes ingresar un nombre valido (Min 4 caracteres)",
          icon: "error",
        });
        return false;
      } else {
        this.$http
          .post("storage", this.storage)
          .then(() => {
            this.loadStorages();
          })
          .catch(() => {
            this.$swal({
              title: "Error",
              text: "Revise informacion e intente nuevamente",
              icon: "error",
            });
          });
      }
    },
    saveImage() {
      if (this.productImage == null)
        return this.$noty.error("Debe adjuntar un archivo");
      this.loading = true;
      const formData = new FormData();
      formData.append("image", this.productImage);

      this.$http
        .post(`https://files.ticketapp.cl/${this.product._id}/image`, formData)
        .then(() => {
          // this.loadProducts();
          //this.editProduct(this.product._id);
          this.updateProduct();
          this.$noty.success("Informacion subida correctamente");
        })
        .catch((err) => {
          let message = "intente nuevamente";
          if (err.body && err.body.details) message = err.body.details;
          this.$noty.error("Error: " + message);
          this.file = null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveCategory() {
      if (this.category_name == "") {
        alert("Ingrese un nombre valido");
      } else {
        this.$http
          .post("category", {
            name: this.category_name,
          })
          .then((resC) => {
            this.$refs["modalNewCategory"].hide();
            this.category_name = "";
            this.$noty.success("Categoria agregada!");
            this.$http.get("category").then((res) => {
              this.categories = res.data.data;

              if (this.$refs["modal"].isVisible) {
                this.newProduct.category = resC.data.data._id;
              } else this.product.category = resC.data.data._id;
            });
          });
      }
    },
    addNewCategoryModal() {
      this.$bvModal.show("modalNewCategory");

      setTimeout(() => {
        this.$refs["category_name"].focus();
      }, 800);
    },

    addOptionNewProduct() {
      if (this.option.label.length == 0 || this.option.values.length == 0)
        return false;
      this.newProduct.options.push({
        label: this.option.label,
        values: this.option.values,
      });
      this.option = {
        label: "",
        values: "",
      };
    },
    removeOptionNewProduct(option) {
      this.newProduct.options.splice(
        this.newProduct.options.indexOf(option),
        1
      );
    },
    clearFilter() {
      this.filter = "";
      this.filterProducts();
    },
    loadProducts() {
      let url = `product?sort=${this.sort}&storage=${this.storage_selected}&provider=${this.selectedProvider}&category=${this.selectedCategory}`;
      this.$http
        .get(url)
        .then((res) => {
          this.$store.commit("setProducts", res.data.data);
        })
        .catch(() => {
          this.$noty.warning("Error al actualizar los productos");
        })
        .finally(() => {
          this.loading = false;
          this.filterProducts();
        });
      this.loadCategories();
    },
    loadCategories() {
      this.$http.get("category").then((res) => {
        this.categories = res.data.data;
      });
    },
    saveProduct() {
      this.saving = true;
      this.$http
        .post("product", this.newProduct)
        .then((res) => {
          this.loadProducts();
          this.newProduct = {
            name: "",
            code: "",
            description: "",
            value: 0,
            iva: true,
            category: null,
            unit: "Unid",
          };
          // this.$noty.success("Producto agregado correctamente");
          // todo edit product
          if (this.editOnSave) {
            this.editProduct(res.data.data._id);
            this.$refs["modal"].hide();
          }
        })
        .catch(() => {
          this.$noty.error("Error al guardar los datos");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteProduct(id) {
      this.$http
        .delete(`product/${id}`)
        .then(() => {
          this.loadProducts();
          this.$bvModal.hide("modal-edit");
        })
        .catch(() => {
          this.$noty.error("Error al borrar el producto");
        });
    },
    editProduct(id, i, data = null) {
      this.pid = id;
      this.index = i;
      if (data) {
        this.product = data;
        this.$bvModal.show("modal-edit");
      }
      event.preventDefault();
      this.$http
        .get(`product/${id}`)
        .then((res) => {
          this.product = res.data.data;
          if (!data) this.$bvModal.show("modal-edit");
        })
        .catch(() => {
          this.$noty.error("Error al editar el producto, intente nuevamente");
        });

      this.$http
        .get(`product/${id}/history`)
        .then((res) => {
          this.product_history = res.data.data;
        })
        .catch(() => {
          this.$noty.error("Error al cargar historial");
        });

      this.$http
        .get(`product/${id}/inventory`)
        .then((res) => {
          this.product_inventory = res.data.data;
        })
        .catch(() => {
          this.$noty.error("Error al cargar historial");
        });
    },
    updateProduct() {
      this.loading = true;
      const id = this.product._id;
      this.$http
        .patch(`product/${this.product._id}`, this.product)
        .then((res) => {
          this.product = res.data.data;
          this.$set(this.productList, this.index, res.data.data);
          this.loading = false;
          // this.loadProducts();
          this.$http
            .get(`product/${id}/history`)
            .then((res) => {
              this.product_history = res.data.data;
            })
            .catch(() => {
              this.$noty.error("Error al cargar historial");
            });

          this.$http
            .get(`product/${id}/inventory`)
            .then((res) => {
              this.product_inventory = res.data.data;
            })
            .catch(() => {
              this.$noty.error("Error al cargar historial");
            });
        })
        .catch(() => {
          this.$noty.error("Error al editar el producto, intente nuevamente");
        });
    },
    getCategory(category) {
      if (category) return category.name;
      else return "Sin categoria";
    },
  },
  watch: {
    page() {
      this.filterProducts();
    },
    selectedCategory() {
      this.loadProducts();
    },
    selectedProvider() {
      this.loadProducts();
    },
    storage_selected: function () {
      this.loadProducts();
    },
    productTab() {
      if (this.product._id) {
        // this.updateProduct();
        // this.$noty.info("Producto guardado");
      }
    },
    filter() {
      if (this.filter == "") {
        this.productList = this.products.slice(0, this.limit);
      }
    },
    limit() {
      this.filterProducts();
    },
    editable() {
      console.log("editable", this.editable);
      this.filterProducts();
    },
    cost_value() {
      console.log("cost_value", this.cost_value);
      this.filterProducts();
    },
    sort() {
      this.loadProducts();
    },
    "product.code": function (val) {
      this.product.code = val.split(" ").join("").toUpperCase();
    },
    // "newProduct.code": function (val) {
    //   this.newProduct.code = val.split(" ").join("").toUpperCase();
    //   clearTimeout(this.timeout);
    //   this.timeout = setTimeout(() => {
    //     if (val == "") return false;
    //     this.$http
    //       .get(`product/check/${val}`)
    //       .then(() => {})
    //       .catch(() => {
    //         this.$swal({
    //           icon: "error",
    //           text: `El codigo ingresado ya existe en el sistema`,
    //           title: "Error!",
    //         });
    //         this.newProduct.code = "";
    //       });
    //   }, 1500);
    // },
    // "product.image": function () {
    //   this.updateProduct();
    // },
    // "product.featured": function () {
    //   this.updateProduct();
    // },
  },
};
</script>