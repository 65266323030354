<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3>Administrar almanenamiento</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <item-icon icon="folder" title="Ruta" :text="path" />
      </div>
      <div class="col">
        <item-icon icon="folder" title="Usado" :text="totalUsed" />
      </div>
      <div class="col">
        <item-icon icon="folder" title="Total" :text="totalSpace" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" v-for="d in data" :key="d">
            <div class="row">
              <div class="col">
                {{d.filesystem}}
              </div>
              <div class="col">
               {{d.used}} /{{d.size}}
              </div>
              <div class="col">
                {{d.use}}
              </div>
              <div class="col">
                {{d.mounted}}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import itemIcon from "../../components/itemIcon.vue";

export default {
  components: { itemIcon },
  name: "Storage",
  data() {
    return {
      totalSpace: 0,
      totalUsed: 0,
      files: [],
      path: "/",
      data: {}
    };
  },
  methods: {
    load() {
      this.$http
        .get("admin/storage")
        .then((res) => {
          this.totalSpace = res.data.data.totalSpace;
          this.totalUsed = res.data.data.totalUsed;
          this.files = res.data.data.files;
          this.folders = res.data.data.folders;
          this.data = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>