<template>
  <div>
    <!-- Modal Component -->
    <b-modal
      ref="modal_sii"
      :id="'modal_sii'"
      size="md"
      scrollable
      hide-footer
      title="Generar documento"
      @ok="createDTE()"
    >
      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Seleccione los abonos a facturar"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              id="checkbox-group-2"
              :aria-describedby="ariaDescribedby"
              name="flavour-2"
              v-model="deposits"
            >
              <b-form-checkbox
                :disabled="p.deletedAt"
                class="d-block"
                :value="{ _id: p._id, amount: p.amount, payment: p.payment }"
                v-for="p in ot.deposits"
                :key="p._id"
              >
                <strong class="text-uppercase">{{ p.payment }}</strong> - ${{
                  formatNumber(p.amount)
                }}
                ({{ p.createdAt | moment("DD/MM/YY") }})
                <small class="d-block">{{
                  p.ticket ? "DOCUMENTO GENERADO" : ""
                }}</small>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="w-100 mt-2"></div>
        <div class="col text-right">
          <label for="">Total</label>
        </div>
        <div class="col-3 text-right font-weight-bold">
          ${{ formatNumber(total) }}
        </div>
        <div class="col-12" v-if="total > 0">
          <router-link
            :to="`/new?ot=${this.ot._id}&deposits=${JSON.stringify(
              this.deposits
            )}&otnumber=${this.ot.number}`"
            class="btn btn-outline-success btn-block"
            >GENERAR DOCUMENTOS SELECCIONADOS</router-link
          >
        </div>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal
      ref="modalextra"
      :id="'modal-extra'"
      size="xl"
      scrollable
      title="Agregar extra"
    >
      <div class="row">
        <div class="col-10">
          <input
            type="text"
            class="form-control"
            v-model="filter"
            placeholder="Nombre o codigo de producto"
            @keyup.enter="filterProducts()"
          />
          <small class="text-muted">Busca por codigo o por nombre</small>
        </div>
        <div class="col-2">
          <button
            class="btn btn-block btn-outline-primary"
            @click="filterProducts()"
          >
            BUSCAR
          </button>
        </div>
        <div class="col-12">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="p in productList"
              :key="p._id"
              :class="{
                'list-group-item-danger': p.qty <= 0 && p.stock,
              }"
            >
              <div class="row align-items-center">
                <div class="col-3">
                  <small class="">CODIGO: </small>
                  <small class="font-weight-bold">{{ p.code }}</small>
                  <div class="d-block"></div>
                  <div class="d-block font-weight-bold text-uppercase">
                    {{ p.name }}
                  </div>
                  <small v-if="p.description">{{ p.description }}</small>
                </div>
                <div class="col-1 text-center">
                  <small class="d-block">STOCK</small>
                  <span class="font-weight-bold">{{ p.qty }}</span>
                </div>
                <div class="col-2">
                  <small class="d-block">VALOR</small>
                  <span class="font-weight-bold"
                    >${{ formatNumber(p.value) }}</span
                  >
                </div>
                <div class="col-2">
                  <small class="d-block">CANTIDAD</small>
                  <input
                    type="number"
                    v-model="p.q"
                    class="form-control text-center"
                    @focus="$event.target.select()"
                  />
                </div>
                <div class="col-2" v-if="p.storages">
                  <small class="d-block">BODEGA</small>
                  <select v-model="p.storage" class="form-control">
                    <option
                      :value="s._id"
                      v-for="{ storage: s } in p.storages"
                      :key="s._id"
                    >
                      {{ s.name }}
                    </option>
                  </select>
                </div>
                <div class="ml-auto col-2">
                  <button
                    class="btn btn-sm btn-primary btn-block"
                    @click="addExtra(p)"
                  >
                    <font-awesome-icon icon="plus" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal
      ref="modaldeposit"
      :id="'modal-deposit'"
      size="lg"
      scrollable
      :title="`Agregar abono orden de trabajo N° ${ot.number}`"
      @ok="addDeposit()"
    >
      <div class="row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Total $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="ot.total"
              disabled
            />
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Saldo pendiente $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="ot.balance"
              disabled
            />
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Medio de pago</span>
            </div>
            <select name="" id="" class="form-control" v-model="payment">
              <option :value="p" v-for="p in payments" :key="p">{{ p }}</option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Monto $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model.number="deposit"
              @focus="$event.target.select()"
              :class="{
                'is-invalid': deposit == 0 || deposit > ot.balance,
                'is-valid': deposit > 0 && deposit <= ot.balance,
              }"
            />
          </div>
        </div>
      </div>
    </b-modal>


    <b-modal
      ref="modaldiscount"
      :id="'modal-discount'"
      size="lg"
      scrollable
      :title="`Agregar descuento orden de trabajo N° ${ot.number}`"
      @ok="addDiscount()"
    >
      <div class="row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">SubTotal $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="ot.sub_total"
              disabled
            />
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Descuento $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="ot.discount"
              @focus="$event.target.select()"
            />
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Saldo pendiente $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="ot.balance"
              disabled
            />
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Total $</span>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="ot.total"
              disabled
            />
          </div>
        </div>

      </div>
    </b-modal>

    <b-modal
      id="search-options"
      size="md"
      title="Opciones de busqueda"
      @ok="load()"
    >
      <div class="row align-items-center">
        <div class="col-3">
          <strong>Buscar por</strong>
        </div>
        <div class="col">
          <select v-model="searchMode" class="form-control">
            <option value="date">Fecha seleccionada {{ dateFilter }}</option>
            <option value="history">Historico (30 max)</option>
          </select>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalproduct"
      size="lg"
      title="Editar producto"
      scrollable
      @ok="updateProduct()"
      @close="view(ot._id)"
      @cancel="view(ot._id)"
    >
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="">Nombre</label>
            <input
              type="text"
              v-model="product.name"
              disabled
              class="form-control"
            />
          </div>
          <div class="form-group" v-if="product.storage">
            <label for="">Bodega</label>
            <input
              type="text"
              v-model="product.storage.name"
              disabled
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Valor</label>
            <input
              type="text"
              v-model="product.value"
              disabled
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Cantidad</label>
            <input
              type="text"
              @focus="$event.target.select()"
              v-model.number="product.qty"
              class="form-control"
            />
          </div>
          <div class="w-100 py-3"></div>
          <button
            class="btn btn-block btn-sm btn-outline-danger mt-5"
            @click="
              removeProduct(
                product._id,
                `${product.code} - ${product.name}`,
                product.qty
              )
            "
          >
            Eliminar
          </button>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">Descuento</label>

            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input
                type="text"
                @focus="$event.target.select()"
                v-model.number="product.discount"
                class="form-control"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  {{
                    (product.discount * 100) / (product.qty * product.value)
                  }}%
                </div>
              </div>
            </div>
            <b-form-input
              id="range-1"
              type="range"
              v-model.number="product.discount"
              min="0"
              :max="product.qty * product.value"
              :step="(product.qty * product.value) / 20"
            ></b-form-input>
          </div>
          <div class="form-group">
            <label for="">Total</label>
            <input
              type="text"
              v-model="product.total"
              disabled
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-group">
            <label for="">Notas (Opcional)</label>
            <textarea
              v-model="product.details"
              rows="5"
              placeholder="(Opcional)"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal
      ref="modaldescription"
      title="Editar descripcion"
      size="lg"
      scrollable
      @ok="updateOtDescription()"
    >
      <div class="row">
        <div class="col">
          <label for="">Descripcion</label>
          <textarea
            class="form-control d-none"
            cols="20"
            rows="5"
            v-model="ot.description"
          ></textarea>
          <vue-editor
            v-model="ot.description"
            :editorToolbar="customToolbar"
          ></vue-editor>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalinfo"
      title="Editar info"
      size="md"
      scrollable
      @ok="updateOtinfo()"
    >
      <div class="row">
        <div class="col-12">
          <label for="">Nombre</label>
          <input
            type="text"
            class="form-control"
            v-model="ot.name"
            :class="{
              'is-valid': ot.name.length >= 3,
              'is-invalid': ot.name.length < 3,
            }"
          />
        </div>
        <div class="col-12">
          <label for="">Telefono</label>
          <input
            type="text"
            class="form-control"
            v-model="ot.phone"
            :class="{
              'is-valid': ot.phone.length == 9,
              'is-invalid': ot.phone.length != 9,
            }"
          />
        </div>
        <div class="col-12">
          <label for="">Entrega</label>
          <date-picker
            valueType="format"
            v-model="ot.endDate"
            input-class="form-control pl-4"
          />
        </div>
        <div class="col-12 d-none">
          <label for="">Descuento</label>
          <input
            type="text"
            class="form-control"
            v-model.number="ot.discount"
          />
        </div>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal ref="modal" :id="'modal-view'" size="xl" scrollable>
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->

        <div class="row align-items-end w-100 no-gutters ml-0">
          <div class="col-12">
            <h5>
              {{
                `Orden de Trabajo N°${
                  ot.number
                } | Estado: ${ot.status.toUpperCase()}`
              }}
            </h5>
          </div>

          <div class="col-2">
            <small>COPIAS</small>
            <b-form-spinbutton
              size="sm"
              v-model.number="copies"
              min="1"
              max="5"
            ></b-form-spinbutton>
            <!-- <select
              name=""
              id=""
              class="form-control form-control-sm text-center"
              v-model="copies"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select> -->
          </div>

          <div class="col-1">
            <button
              class="btn btn-outline-primary btn-sm btn-block"
              @click="print()"
            >
              <font-awesome-icon icon="print"></font-awesome-icon>
            </button>
          </div>
          <div class="col-1 ml-auto"></div>
          <div class="col-2">
            <button
              class="btn btn-outline-success btn-sm btn-block"
              :disabled="ot.balance > 0"
              @click="finish()"
              :class="{ disabled: ot.balance > 0 }"
              v-if="ot.status == 'pendiente'"
            >
              Finalizar
            </button>
          </div>

          <div class="col-2">
            <b-button
              size="sm"
              block
              variant="outline-warning"
              @click="$refs.modal_sii.show()"
            >
              DTE
            </b-button>
          </div>
          <div class="col-2">
            <b-button
              size="sm"
              block
              variant="outline-danger"
              @click="cancel(ot._id)"
            >
              Cancelar
            </b-button>
          </div>

          <div class="col-12" v-if="ot.balance > 0">
            <div
              class="
                alert alert-warning
                mt-2
                d-block
                text-center text-uppercase
                font-weight-bold
              "
            >
              La orden de trabajo tiene saldo pendiente !
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col">
              <h6>Informacion</h6>
            </div>
            <div class="col-2 ml-auto">
              <button
                class="btn btn-sm btn-link btn-block"
                @click="$refs.modalinfo.toggle()"
                v-if="ot.status == 'pendiente'"
              >
                EDITAR
              </button>
            </div>
          </div>
          <ul class="list-group">
            <li class="list-group-item">
              <div class="row">
                <div class="col-2 font-weight-bold">NOMBRE</div>
                <div class="col">
                  {{ ot.name }}
                </div>
                <div class="col-2 font-weight-bold">TELEFONO</div>
                <div class="col">
                  {{ ot.phone }}
                  <div
                    class="d-inline ml-2 text-success click"
                    @click="openWhatsApp(ot.phone)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-2 font-weight-bold">CORREO</div>
                <div class="col">
                  {{ ot.email || "- - - - - - - -" }}
                </div>
                <div class="col-2 font-weight-bold">RUT</div>
                <div class="col">
                  {{ ot.rut || "- - - - - - - -" }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-2 font-weight-bold">CREADA</div>
                <div class="col" v-if="ot.createdAt">
                  {{ ot.createdAt | moment("dddd, DD/MM/YY") }}
                </div>
                <div class="col-2 font-weight-bold">ENTREGA</div>
                <div class="col" v-if="ot.endDate">
                  {{ ot.endDate | moment("dddd, DD/MM/YY") }}
                </div>
              </div>
            </li>
          </ul>

          <div class="row align-items-end">
            <div class="col">
              <h6 class="mt-4">Descripcion</h6>
            </div>
            <div class="col-2">
              <button
                class="btn btn-sm btn-link btn-block"
                @click="$refs.modaldescription.toggle()"
                v-if="ot.status == 'pendiente'"
              >
                EDITAR
              </button>
            </div>
            <div class="col-12">
              <div
                class="border px-2 py-2 d-block bglight"
                style="min-height: 100px"
              >
                <div v-html="ot.description"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4"></div>

        <div class="col-12 mt-4">
          <div class="row">
            
            <div class="col text-right" v-if="!ot.deletedAt">
              <button
                class="btn btn-sm btn-link"
                @click="$refs.modaldiscount.toggle()"
                v-if="ot.status == 'pendiente'"
              >
                AGREGAR DESCUENTO
              </button>
              <button
                class="btn btn-sm btn-link"
                @click="$refs.modalextra.toggle()"
                v-if="ot.status == 'pendiente'"
              >
                AGREGAR EXTRA
              </button>
              <button
                class="btn btn-sm btn-link"
                @click="$refs.modaldeposit.toggle()"
                v-if="ot.balance != 0"
              >
                AGREGAR ABONO
              </button>
            </div>
          </div>
          <div class="col-12">
              <h6>Detalles</h6>
            </div>
          <ul class="list-group">
            <li
              class="list-group-item list-group-item-action"
              v-for="p in ot.products"
              :key="p._id"
              :class="{
                click: ot.status == 'pendiente',
              }"
              @click="editProduct(p)"
            >
              <div class="row align-items-center">
                <div class="col">
                  <span class="font-weight-bold d-block text-uppercase">
                    {{ p.qty }} x {{ p.name }}
                  </span>
                  <small class="d-block">CODIGO: {{ p.code }}</small>
                  <small v-if="p.storage" class="d-block"
                    >BODEGA: {{ p.storage.name }}</small
                  >
                  <small class="d-block"
                    >VALOR: ${{ formatNumber(p.value) }}</small
                  >
                  <small class="d-block"
                    >DESCUENTO: ${{ formatNumber(p.discount) }}</small
                  >
                  <small v-if="p.details">{{ p.details }}</small>
                </div>
                <div class="col-3 font-weight-bold text-right">
                  ${{ formatNumber(p.total) }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center font-weight-bold">
                <div class="col-2 ml-auto">SUB-TOTAL</div>
                <div class="col-2 text-right">
                  ${{ formatNumber(ot.sub_total) }}
                </div>
              </div>
              <div class="row align-items-center font-weight-bold">
                <div class="col-2 ml-auto">DESCUENTO</div>
                <div class="col-2 text-right">
                  - ${{ formatNumber(ot.discount) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row align-items-center font-weight-bold">
                <div class="col-2 ml-auto">TOTAL</div>
                <div class="col-2 text-right">
                  ${{ formatNumber(ot.total) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-secondary">
              <div class="row align-items-center font-weight-bold">
                <div class="col-2 ml-auto">ABONOS</div>
                <div class="col-2 text-right">
                  ${{ formatNumber(ot.total - ot.balance) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-secondary">
              <div class="row align-items-center font-weight-bold">
                <div class="col-2 ml-auto">SALDO</div>
                <div class="col-2 text-right">
                  ${{ formatNumber(ot.balance) }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="col-12 mt-4">
          <h6>Abonos</h6>
          <ul class="list-group">
            <li class="list-group-item" v-if="ot.deposits.length == 0">
              <h6 class="text-center">No hay abonos</h6>
            </li>
            <li
              class="list-group-item"
              v-for="n in ot.deposits"
              :key="n._id"
              :class="{
                'list-group-item-danger': n.deletedAt,
              }"
            >
              <div class="row align-items-center">
                <div class="col-3 text-center">
                  <small class="d-block">
                    {{ n.createdAt | moment("DD/MM/YY HH:mm") }}
                  </small>
                  <small
                    class="btn-link text-danger click"
                    @click="deleteDeposit(n._id)"
                    v-if="!n.deletedAt && ot.status == 'pendiente'"
                    >Eliminar</small
                  >
                </div>
                <div class="col font-weight-bold">
                  {{ n.payment }}
                </div>
                <div class="col text-right">
                  <span class="d-block font-weight-bold">
                    ${{ formatNumber(n.amount) }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="col mt-4">
          <b-button v-b-toggle.collapse-1-inner block size="sm"
            >Ver historial</b-button
          >
          <b-collapse id="collapse-1-inner" class="mt-2">
            <ul class="list-group">
              <li class="list-group-item" v-if="ot.notes.length == 0">
                <h6 class="text-center">No hay notas</h6>
              </li>
              <li
                class="list-group-item"
                v-for="n in notes"
                :key="n._id"
                :class="{
                  'list-group-item-success':
                    n.content.toLowerCase().indexOf('abono') != -1,
                  'list-group-item-danger':
                    n.content.toLowerCase().indexOf('borrado') != -1 ||
                    n.content.toLowerCase().indexOf('eliminado') != -1,
                  'list-group-item-info':
                    n.content.toLowerCase().indexOf(' x ') != -1,
                }"
              >
                <div class="row align-items-center">
                  <div class="col-4 text-center">
                    <small class="d-block">
                      {{ n.createdAt | moment("DD/MM/YY HH:mm") }}
                    </small>
                    <small class="font-weight-bold">{{
                      n.user && n.user.name
                    }}</small>
                  </div>
                  <div class="col">
                    <span class="d-block">
                      {{ n.content }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </b-collapse>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      ot: {
        notes: [],
        deposits: [],
        name: "",
        phone: "",
        endDate: null,
      },
      loading: true,
      filter: "",
      qty: 1,
      deposit: 0,
      pending: false,
      payment: "Efectivo",
      product: {},
      searchMode: "date",
      copies: 1,
      productList: [],
      deposits: [],
      dateFilter: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  computed: {
    products() {
      return this.$store.state.products.map((p) => {
        let storage = "";
        if (p.storages && p.storages.length > 0)
          storage = p.storages[0].storage._id;
        return {
          ...p,
          q: 1,
          storage,
        };
      });
    },
    total() {
      return this.deposits.reduce((t, d) => {
        return t + d.amount;
      }, 0);
    },
    info() {
      return this.$store.state.info;
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    notes() {
      let notes = this.ot.notes
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return notes;
    },
    productFiltered() {
      if (this.filterProduct == "") {
        let products = [...this.products];
        return products.slice(0, 100);
      } else {
        const filter = (p) => {
          return (
            p.name.toLowerCase().indexOf(this.filterProduct.toLowerCase()) >
              -1 ||
            p.code.toString().indexOf(this.filterProduct.toLowerCase()) > -1
          );
        };
        return this.products.filter(filter);
      }
    },
    getOts() {
      if (this.filter == "" || this.searchMode == "history") return this.ots;
      else {
        const filter = (ot) => {
          return (
            ot.name.toLowerCase().indexOf(this.filter) > -1 ||
            ot.rut.toLowerCase().indexOf(this.filter) > -1
          );
        };
        return this.ots.filter(filter);
      }
    },
  },
  mounted() {
    if (this.$moment().hour() < 5)
      this.dateFilter = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    else this.dateFilter = this.$moment().format("YYYY-MM-DD");
    this.productList = this.products.slice(0, 100);
    if (localStorage.copies) this.copies = parseInt(localStorage.copies);
  },
  methods: {
    addDiscount() {
      this.$http
        .post(`ot/${this.ot._id}/discount`, {
          discount: this.ot.discount,
        })
        .then(() => {
          this.view(this.ot._id);
        });
    },
    filterProducts() {
      let filter = (p) => {
        p.words = 0;
        let words = this.filter.trim().toLowerCase().split(" ");
        let pwords = p.name.toLowerCase().split(" ");
        // check code
        if (this.filter.toLowerCase() == p.code.toString().toLowerCase()) {
          p.words = p.words + 500;
          return p;
        }
        // check words
        if (pwords.join(" ").indexOf(words.join(" ")) != -1) {
          p.words = p.words + 100;
          // return p;
        }

        if (pwords.some((w) => words.includes(w))) {
          pwords.forEach((pw) => {
            if (words.includes(pw)) p.words = p.words + 10;
            if (words.join(" ").indexOf(pw) != -1) p.words = p.words + 5;
            words.forEach((w) => {
              if (pwords.join(" ").indexOf(w) != -1) p.words = p.words + 5;
            });
          });
          return p;
        }
        if (p.words > words.length * 5) return p;
      };
      let sort = (a, b) => {
        if (a.words > b.words) return -1;
        if (a.words < b.words) return 1;
        return 0;
      };
      this.productList = this.products.filter(filter).sort(sort);
    },
    updateOtinfo() {
      this.$http
        .patch(`ot/${this.ot._id}/info`, {
          name: this.ot.name,
          phone: this.ot.phone,
          rut: this.ot.rut,
          endDate: this.ot.endDate,
        })
        .then(() => {
          this.$noty.success("Editado correctamente");
          this.view();
          this.load();
        })
        .catch(() =>
          this.$noty.error("Error al actualizar. intente nuevamente")
        );
    },
    updateOtDescription() {
      this.$http
        .patch(`ot/${this.ot._id}/description`, {
          description: this.ot.description,
        })
        .then(() => {
          this.$noty.success("Editado correctamente");
          this.view();
        })
        .catch(() =>
          this.$noty.error("Error al actualizar. intente nuevamente")
        );
    },
    updateProduct() {
      this.$http
        .patch(`ot/${this.ot._id}/product/${this.product._id}`, this.product)
        .then(() => this.view())
        .catch(() => this.$noty.error("Error al borrar. intente nuevamente"));
    },
    editProduct(p) {
      if (this.ot.status == "pendiente") {
        this.product = p;
        this.product.oldQty = p.qty;
        this.$refs.modalproduct.show();
      }
    },
    deleteDeposit(id) {
      this.$http
        .delete(`deposit/${id}`)
        .then(() => {
          this.view(this.ot._id);
        })
        .catch(() => this.$noty.error("Error al borrar. intente nuevamente"));
    },
    removeProduct(id, name, qty) {
      this.$http
        .delete(`ot/extra/${this.ot._id}`, { body: { id, name, qty } })
        .then(() => {
          this.$noty.success("Producto borrado correctamente");
          this.view();
          this.$refs.modalproduct.hide();
        })
        .catch(() => {
          this.$noty.error("Error al borrar. intente nuevamente");
        });
    },
    finish() {
      this.$http
        .post(`ot/${this.ot._id}/finish`)
        .then(() => {
          this.$noty.success("Orden de trabajo finalizada correctamente");
          this.$refs.modal.hide();
        })
        .catch(() => {
          this.$noty.error("Error al finalizar la orden de trabajo");
        });
    },
    addDeposit() {
      this.$http
        .post(`ot/${this.ot._id}/deposit`, {
          deposit: this.deposit,
          payment: this.payment,
        })
        .then(() => {
          this.$noty.success("Abono agregado correctamente");
          this.view(this.ot._id);
          this.deposit = 0;
          // this.$refs.deposit.hide();
        })
        .catch(() => {
          this.$noty.error("Error al guardar el abono. Intente nueamente");
        });
    },
    addExtra(product) {
      if (product.storages && product.storage == "") {
        this.$noty.error("Debe seleccionar una bodega");
        return false;
      }
      this.$http
        .post(`ot/${this.ot._id}/extra`, {
          name: product.name,
          code: product.code,
          value: product.value,
          qty: product.q,
          details: product.details,
          storage: product.storage,
        })
        .then(() => {
          this.$noty.success("Extra agregado correctamente!");
          // this.$refs.modal.hide();
          this.view(this.ot._id);
        });
    },
    print() {
      // ipcRenderer.send("printOT", this.ot._id);
      localStorage.OT = JSON.stringify(this.ot);
      localStorage.info = JSON.stringify(this.$store.state.info);
      localStorage.copies = this.copies;
      this.$store.commit("setOT", this.ot);
      // ipcRenderer.send("printOT", this.ot._id);

      this.$emit("printOT", {
        ot: this.ot,
        copies: this.copies,
      })
      
      this.$noty.success("Ticket enviado a imprimir");
    },
    cancel(id) {
      this.$refs.modal.hide();
      this.$swal({
        type: "question",
        title: "Esta seguro?",
        text: "Esta opcion no se puede deshacer. Ingrese un motivo",
        content: "input",
        buttons: ["Cancelar", "Borrar!"],
      }).then((result) => {
        if (result != null) {
          if (result == "")
            this.$noty.error(`ERROR: Debes ingresar un motivo valido`);
          else {
            this.$http
              .delete(`ot/${id}`, {
                body: {
                  reason: result,
                },
              })
              .then(() => {
                this.$noty.success("Orden de trabajo cancelada correctamente");
              })
              .catch(() => {
                this.$noty.error(
                  "No se pudo cancelar la Orden de trabajo seleccionada"
                );
              });
          }
        }
      });
    },
    view(id = this.ot._id, cb = () => {}) {
      this.loading = true;
      this.deposits = [];

      this.$http
        .get(`ot/${id}`)
        .then((res) => {
          this.ot = res.data.data;
          this.$store.commit("setOT", this.ot);
          localStorage.OT = JSON.stringify(this.ot);
          this.$refs.modal.show();
        })
        .catch(() => {
          this.$noty.error("Error al cargar la OT");
        })
        .finally(() => {
          this.loading = false;
          cb()
        });
    },
  },
  watch: {
    copies() {
      localStorage.copies = this.copies;
    },
    "product.qty": function () {
      let { product } = this;
      product.total =
        parseInt(product.value * product.qty) - parseInt(product.discount || 0);
    },
    "product.discount": function () {
      let { product } = this;
      product.total =
        parseInt(product.value * product.qty) - parseInt(product.discount);
    },
  },
};
</script>