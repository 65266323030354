<template>
  <div class="container-fluid contariner-app">
    <b-overlay :show="loading">
      <div class="row align-items-end">
        <div class="w-100 py-2"></div>
        <div class="col">
          <h2>TICKETS {{ tickets.length }} | ${{ total | formatNumber }}</h2>
        </div>
        <div class="ml-auto col-2">
          <small>FECHA</small>
          <div class="w-100"></div>
          <date-picker
            v-model="dateFilter"
            valueType="format"
            :input-class="'form-control form-control-sm'"
          ></date-picker>
        </div>
        <div class="col-2">
          <small>LIMITE</small>
          <div class="w-100"></div>
          <b-form-spinbutton
            size="sm"
            v-model="limit"
            min="50"
            step="100"
            max="50000000"
            :disabled="loading"
          ></b-form-spinbutton>
        </div>
        <div class="col-2">
          <button
            class="btn btn-outline-success btn-block"
            @click="loadTickets()"
          >
            Actualizar
          </button>
        </div>
        <!-- <div class="col-12">
          <apexchart
            width="100%"
            height="260"
            type="line"
            :options="chartDays"
          />
        </div> -->
        <div class="w-100 py-2"></div>
        <div class="col">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="ticket in tickets"
              @click="viewTicket(ticket)"
              :class="{
                'list-group-item-danger': ticket.deletedAt,
              }"
              :key="ticket._id"
            >
              <div class="row align-items-center justify-content-center">
                <div class="col-11 mb-3 border-bottom">
                  <span class="text-uppercase mr-5">{{
                    ticket.business.name
                  }}</span
                  ><span
                    class="d-block1 text-uppercase"
                    v-if="ticket.business.custom_name"
                    >{{ ticket.business.custom_name }}</span
                  >
                </div>
                <div class="w-100"></div>
                <div class="col-2 text-center">
                  <small class="font-weight-bold"
                    >#{{ ticket.number | formatNumber }}</small
                  >

                  <small class="mx-2">{{
                    ticket.createdAt | moment("HH:mm:ss")
                  }}</small>
                </div>
                <div class="col-4">
                  <small>{{ ticket.clientName | uppercase }}</small>
                  <small class="mx-4" v-if="ticket.type == 'Domicilio'">
                    <font-awesome-icon
                      icon="building"
                      class="mr-1"
                    ></font-awesome-icon>
                    {{ ticket.address | uppercase }}</small
                  >
                </div>
                <div class="col-3">
                  <span
                    class="badge badge-success px-3 mx-1"
                    v-if="ticket.webTicket"
                    >WEBTICKET</span
                  >
                  <span
                    class="badge px-3 mx-1"
                    :class="{
                      'badge-info': ticket.type === 'Retiro',
                      'badge-secondary': ticket.type === 'Domicilio',
                    }"
                    >{{ ticket.type | uppercase }}</span
                  >
                  <span
                    class="badge px-3 mx-1"
                    :class="{
                      'badge-primary': ticket.documentType.indexOf('DTE') != -1,
                      'badge-warning': ticket.documentType === 'TICKET',
                    }"
                    >{{ ticket.documentType }}</span
                  >
                </div>
                <div class="col-2 text-right">
                  <strong class="text-uppercase"
                    >${{ ticket.total | formatNumber }}</strong
                  >
                </div>
                <div class="col-10 py-2">
                  <div class="w-100 border-top"></div>
                </div>
                <div class="w-100"></div>
                <div class="col-8">
                  <div
                    class="row border-bottom"
                    v-for="p in ticket.products"
                    :key="p._id"
                  >
                    <div class="col">
                      <small>{{ p.qty }} x {{ p.name | uppercase }}</small>
                    </div>
                    <div class="col-2 text-right">
                      <small>${{ p.total | formatNumber }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      tickets: [],
      dateFilter: null,
      loading: true,
      limit: 50,
      lastUpdate: null,
    };
  },
  computed: {
    ticketPerMinute() {
      return this.tickets.reduce((acc, ticket) => {
        let index = acc.findIndex((t) => {
          return t.minute === this.$moment(`${ticket.createdAt}`).format("HH:mm");
        });
        if (index === -1) {
          acc.push({
            minute: this.$moment(`${ticket.createdAt}`).format("HH:mm"),
            total: ticket.total,
            products: ticket.products,
            tickets: [ticket],
          });
        } else {
          acc[index].total += ticket.total;
          acc[index].products = acc[index].products.concat(ticket.products);
          acc[index].tickets.push(ticket);
        }
        return acc;
      }, []);
    },
    chartDays() {
      // group by minutes
      let minutes = this.tickets
        .map((ticket) => {
          return this.$moment(`${ticket.createdAt}`).format("HH:mm");
        })
        .reduce((acc, curr) => {
          if (acc.indexOf(curr) === -1) {
            acc.push(curr);
          }
          return acc;
        }, [])
        .slice(0, 15);


      return {
        series: [
          {
            name: "TOTAL",
            data: this.ticketPerMinute.map((t) => {
              return t.total;
            }),
          },
          // {
          //   name: "EMPRESA",
          //   data: this.tickets.map((t) => {
          //     return t.length
          //   }),
          // }
          {
            name: 'CANTIDAD',
            data: this.ticketPerMinute.map((t) => {
              return t.tickets.length
            }),
          }
        ],
        markers: {
          size: 2,
        },
        chart: {
          id: "ticket-per-minute",
          toolbar: {
            show: false,
          },
          stacked: true,
          type: "bar",
        },
        colors: ["#18578d", "#f8b600"],
        xaxis: {
          categories: minutes,
        },
        stroke: {
          curve: "smooth",
        },
        legend: {
          position: "top",
          fontWeight: "bold",
        },
        // tooltip: {
        //   custom: function ({ series, seriesIndex, dataPointIndex }) {
        //     return (
        //       '<div class="arrow_box p-3" style="width: 200px; text-align:center">' +
        //       self.formatNumber(series[seriesIndex][dataPointIndex]) +
        //       "</span>" +
        //       "</div>"
        //     );
        //   },
        // },
      };
    },
    total() {
      return this.tickets.reduce((total, ticket) => {
        return total + ticket.total;
      }, 0);
    },
  },
  mounted() {
    // this.loadTickets();
    this.dateFilter = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
    viewTicket(ticket) {
      this.$store.commit("setTicket", ticket);
      this.$emit("showTicket", ticket);
    },
    update() {
      console.log("update", Date.now());

      this.loadTickets();
    },
    loadTickets() {
      if (this.lastUpdate) {
        let diff = Date.now() - this.lastUpdate;
        if (diff < 3000) {
          console.log("too fast");
          return;
        }
      }
      this.lastUpdate = Date.now();

      this.loading = true;
      this.$http
        .get(`admin/tickets?date=${this.dateFilter}&limit=${this.limit}`)
        .then((response) => {
          this.tickets = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dateFilter() {
      this.loadTickets();
    },
    limit() {
      //this.loadTickets();
    },
  },
};
</script>