<template>
  <div>
    <ul class="list-group" v-if="loading">
      <li class="list-group-item">
        <div class="text-center">
          <div class="mt-3">
            <b-spinner class="ml-auto"></b-spinner>
            <strong class="d-block">Cargando...</strong>
          </div>
        </div>
      </li>
    </ul>
    <ul class="list-group" v-else-if="data && data.length == 0">
      <li class="list-group-item">
        <div class="row">
          <div class="col">
            <h5 class="text-center py-5">No hay informacion</h5>
          </div>
        </div>
      </li>
    </ul>
    <ul class="list-group" v-else>
      <li
        class="list-group-item list-group-item-action click"
        v-for="expense in data"
        :key="expense._id"
        @click="showExpense(expense)"
        :class="{
          'list-group-item-danger': expense.deletedAt,
          'list-group-item-info': expense.status == 'No pagado',
        }"
      >
        <div class="row py-1 align-items-center">
          <div class="col-lg-1 col-4 text-center">
            <small class="text-uppercase">{{
              expense.date | moment("dddd")
            }}</small>
            <div class="w-100"></div>
            <small class="font-weight-bold text-uppercase">{{
              expense.date | moment("DD/MM/YY")
            }}</small>
          </div>
          <div class="col-8 col-lg-5">
            <span class="font-weight-bold text-uppercase">{{
              expense.name
            }}</span>
            <div class="w-100"></div>
            <small class="text-muted d-block text-uppercase">{{
              expense.notes || ""
            }}</small>
          </div>

          <div class="col-4 col-lg-2">
            <small class="font-weight-bold">{{
              expense.creator && expense.creator.name
            }}</small>
          </div>

          <div class="col-lg-2 col-3 text-center">
            <div
              class="badge px-2 py-2 w-100"
              :class="{
                'badge-success': expense.status == 'Pagado',
                'badge-danger': expense.status != 'Pagado',
              }"
            >
              <span class="font-weight-bold text-uppercase">{{
                expense.status
              }}</span>
            </div>
          </div>
          <div class="col-2 text-right">
            <small class="text-uppercase text-muted d-block">{{
              expense.payment || "- - - -"
            }}</small>
            <span class="font-weight-bold text-uppercase"
              >${{ formatNumber(expense.value) }}</span
            >
          </div>
          <div class="col-1 d-none">
            <div class="dropdown" v-if="!expense.deletedAt">
              <button
                class="btn"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon icon="ellipsis-h" />
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" @click="edit(expense)">
                  Editar
                </a>
                <a class="dropdown-item" href="#" @click="cancel(expense._id)">
                  Eliminar
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <b-modal title="DETALLE" ref="modal" size="md">
      <template #modal-header>
        <div
          class="row w-100 align-items-end justify-content-end no-gutters"
          v-if="!expense.deletedAt"
        >
          <div class="col">
            <h4 class="mb-0">DETALLE</h4>
          </div>
          <div class="col-2">
            <button
              class="btn btn-block btn-outline-warning"
              @click="edit(expense)"
            >
              <font-awesome-icon icon="pen" />
            </button>
          </div>
          <div class="col-2">
            <button
              class="btn btn-block btn-outline-danger"
              @click="cancel(expense._id)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12">
          <div
            class="alert text-center"
            :class="{
              'alert-success': expense.status == 'Pagado',
              'alert-danger': expense.status != 'Pagado',
            }"
          >
            <font-awesome-icon
              :icon="expense.status == 'Pagado' ? 'check-circle' : 'times'"
              class="mr-2"
            />
            <span class="font-weight-bold text-uppercase">{{
              expense.status
            }}</span>
          </div>
        </div>
        <div class="col">
          <h3 class="text-uppercase">{{ expense.name }}</h3>
          <div class="w-100"></div>
          <span class="text-muted">
            {{ expense.date | moment("DD/MM/YYYY") }}
          </span>
          <span class="text-muted ml-2">
            {{ expense.creator && expense.creator.name }}
          </span>
        </div>
        <div class="col-12 mt-4" v-for="p in expense.products" :key="p._id">
          <div class="row align-items-end">
            <div class="col">
              <small class="text-muted">CODIGO: {{ p.code }}</small>
              <div class="w-100"></div>
              {{ p.qty }} x {{ p.name }}
            </div>
            <div class="col-2">
              {{ p.value }}
            </div>
            <div class="col-2">
              <strong>${{ p.total }}</strong>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <small class="text-muted">NOTAS</small>
          <div class="w-100"></div>
          {{ expense.notes || " - - - - -" }}
        </div>

        <div class="col-12 mt-4">
          <h4 class="text-right">${{ expense.value | formatNumber }}</h4>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["data", "loading"],
  data() {
    return {
      expense: {},
    };
  },
  methods: {
    edit(expense) {
      this.$emit("edit", expense);
    },
    cancel(id) {
      this.$emit("cancel", id);
    },
    showExpense(expense) {
      this.expense = expense;
      this.$refs.modal.show();
    },
  },
};
</script>