<template>
  <div>
    <b-tabs>
      <b-tab :title="`Pedidos (${tickets.length})`">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="row align-items-end">
              <div class="col ml-auto">
                <h4>Pedidos web {{ tickets.length }}</h4>
              </div>
              <div class="col-2">
                <date-picker
                  valueType="format"
                  v-model="dateFilter"
                  input-class="form-control"
                />
              </div>
              <div class="col-2">
                <small>LIMITE</small>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model.number="limit"
                >
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                </select>
              </div>
              <div class="col-2 text-right">
                <button
                  class="btn btn-outline-success btn-block"
                  @click="load()"
                  :disabled="loading"
                >
                  <font-awesome-icon icon="sync"></font-awesome-icon>
                </button>
              </div>
              <div class="w-100"></div>
              <div class="col-12">
                <div class="row mt-2">
                  <div
                    class="col-4"
                    v-for="(t, i) in st.tickets"
                    :key="`st-${i}`"
                  >
                    <span class="badge badge-info w-100 py-2 my-1">
                      {{ t.total }} - {{ t.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul
          class="list-group animate__animated animate__fadeIn"
          v-if="!loading"
        >
          <li class="list-group-item" v-if="tickets.length == 0">
            <h4 class="my-5 text-center">No hay informacion</h4>
          </li>
          <li
            class="list-group-item list-group-item-action click"
            v-for="t in tickets"
            :class="{
              'list-group-item-warning': t.status == 'Pendiente',
              'list-group-item-danger1': t.status == 'Cancelado',
            }"
            :key="t._id"
            @click="showTicket(t)"
          >
            <div class="row align-items-center">
              <div class="col-1 text-center">
                <span class="font-weight-bold">#{{ t.number }}</span>
                <div class="w-100"></div>
                <small>{{ t.createdAt | moment("HH:mm") }}</small>
              </div>
              <div class="col">
                <small class="text-muted text-uppercase">{{
                  t.business.name
                }}</small>
                <div class="w-100"></div>
                <span class="font-weight-bold text-uppercase"
                  >{{ t.clientName }} ({{ t.phone }})</span
                >
                <div class="w-100"></div>
                <small class="font-italic">{{ t.email }}</small>
                <div class="w-100"></div>
                <span class="">
                  <font-awesome-icon icon="home" v-if="t.type == 'Domicilio'" />
                  <font-awesome-icon icon="hotel" v-if="t.type == 'Retiro'" />
                  <small class="ml-2" v-if="t.type == 'Retiro'">{{
                    t.type
                  }}</small>
                </span>
                <span class="" v-if="t.type == 'Domicilio' && t.address">
                  <small class="ml-2">{{ getAddress(t.address) }}</small>
                </span>
                <div class="w-100"></div>
                <small v-if="t.notes">NOTAS: {{ t.notes }}</small>
                <div class="w-100"></div>
                <small>IP: {{ t.ip }}</small>
              </div>
              <div class="col-2 text-center">
                <span
                  :class="{
                    badge: 1,
                    'badge-success': t.status == 'Aceptado',
                    'badge-danger': t.status == 'Cancelado',
                  }"
                >
                  {{ t.status }}
                  <span v-if="t.updatedAt">{{
                    t.updatedAt | moment("HH:mm")
                  }}</span>
                </span>
                <small class="d-block">{{ t.reason }}</small>
              </div>
              <div class="ml-auto col-2 text-right">
                <small class="font-weight-bold text-uppercase">{{
                  t.payment
                }}</small>
                <div class="w-100"></div>
                <span class="font-weight-bold d-block text-right">
                  ${{ formatNumber(t.total) }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </b-tab>
      <b-tab :title="`Clientes (${clients.length})`">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="client in clients"
            :key="client._id"
          >
            <div class="row align-items-center">
              <div class="col-1 text-center">
                <font-awesome-icon
                  icon="user-circle"
                  size="3x"
                ></font-awesome-icon>
              </div>
              <div class="col">
                <strong>{{ client.name }}</strong>
                <div class="w-100"></div>
                <small>{{ client.email }}</small>
              </div>
              <div class="col">
                <small>{{ client.phone }}</small>
              </div>
              <div class="col">
                <small>ULTIMA CONEXION</small>
                <div class="w-100"></div>
                <small>{{
                  client.lastConnection | moment("DD-MM-YY HH:mm")
                }}</small>
              </div>
              <div class="col">
                <b-checkbox
                  switch
                  v-model="client.active"
                  @change="updateClient(client)"
                ></b-checkbox>
              </div>
            </div>
          </li>
        </ul>
      </b-tab>
      <b-tab title="Stats">
        <div class="row">
          <div class="col">
            <label for="">Upload CSV</label>
            <b-form-file
              :value="file"
              v-model="file"
              placeholder="Selecciona un archivo o arrastralo hasta aca..."
              drop-placeholder="Arrastra hasta aca..."
              accept=".csv"
            ></b-form-file>
            <b-button @click="uploadCSV()" variant="primary">
              Subir CSV
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <small>EMPRESA</small>
            <div class="w-100"></div>
            <b-form-select
              v-model="selectedBusiness"
              :options="businesses"
              value-field="_id"
              text-field="name"
              @change="getAnalytics()"
            ></b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ul class="list-group">
              <li class="list-group-item" v-for="an in analytics" :key="an._id">
                <div class="row">
                  <div class="col-12">
                    <a :href="`//${ an.domain }${ an.url }`" target="_blank" rel="noopener noreferrer">
                      {{ an.domain }}{{ an.url }}
                    </a>
                  </div>
                  <div class="w-100 py-2"></div>
                  <div class="col-1">
                    {{ an.visits | formatNumber }}
                  </div>
                  <div class="col">
                    <strong>{{
                      (an.business && an.business.custom_name) ||
                      an.business.name
                    }}</strong>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tickets: [],
      ticket: {},
      loading: true,
      dateFilter: null,
      limit: 300,
      stats: {},
      st: 0,
      clients: [],
      file: null,
      analytics: [],
      selectedBusiness: null,
      lastUpdate: null,
    };
  },
  mounted() {
    this.dateFilter = this.$moment(this.$route.query.date).format("YYYY-MM-DD");
    // this.load();
    this.loadStats();
    //this.loadClients();
    this.getAnalytics();
  },
  computed: {
    businesses() {
      return this.$store.state.businesses.filter(b => b.webEnabled)
    }
  },
  methods: {
    update() {
      console.log("update", Date.now());
      this.load();
    },
    getAnalytics() {
      const q = `business=${this.selectedBusiness}`;
      this.$http.get(`admin/webtickets/analytics?${q}`).then((res) => {
        this.analytics = res.data.data;
      });
    },
    deletedStats() {
      this.$http.delete("admin/webtickets/csv").then(() => {
        this.$noty.success("Stats eliminados");
      });
    },
    uploadCSV() {
      const formData = new FormData();
      formData.append("file", this.file);

      this.$http.post("admin/webtickets/csv", formData).then(() => {
        this.$noty.success("CSV cargado");
      });
    },
    updateClient(client) {
      this.$http.put(`admin/webclients/${client._id}`, client).then(() => {
        this.$noty.success("Cliente actualizado");
      });
    },
    loadClients() {
      this.$http.get("admin/webclients").then((res) => {
        this.clients = res.data.data;
      });
    },
    loadStats() {
      this.$http.get("admin/webtickets/stats").then((res) => {
        this.stats = res.data.data;
      });
    },
    showTicket(t) {
      this.$store.commit("setWebTicket", t);
      this.$emit("showWebTicket");
    },
    getClass(type) {
      if (type == "error") return "danger";
      return type;
    },
    load() {
      if(this.lastUpdate){
        let diff = Date.now() - this.lastUpdate;
        if(diff < 3000){
          console.log('too fast')
          return;
        }
      }
      
      this.lastUpdate = Date.now();

      this.loading = true;
      this.$http
        .get(`admin/webtickets?date=${this.dateFilter}&limit=${this.limit}`)
        .then((res) => {
          this.tickets = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
      this.$http
        .get(
          `admin/webtickets/stats?date=${this.dateFilter}&limit=${this.limit}`
        )
        .then((res) => {
          this.st = res.data.data;
        });
    },
  },
  watch: {
    dateFilter: function () {
      this.load();
      this.$router.push({
        query: { date: this.dateFilter },
      });
    },
    limit: function () {
      this.load();
    },
  },
};
</script>