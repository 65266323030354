<script>
export default {
  data() {
    return {
      dismissible: false,
    };
  },
  methods: {},
  computed: {
    invoices() {
      return this.$store.state.invoices;
    },
    showPaymentMessage() {
      let data = {
        total: 0,
        pending: 0,
        class: "bg-danger text-white",
        message: "",
        closable: true
      };
      let invoices = this.invoices.filter((i) => i.status == "pendiente");
      let invicesOutDate = invoices.filter(
        (i) => this.$moment(i.expire).diff(this.$moment(), "days") < 0
      );
      let reducer = (total, current) => total + current.total;
      data.pending = invicesOutDate.reduce(reducer, 0);
      data.total = invoices.reduce(reducer, 0);

      if (data.pending > 0) {
        data.class = "bg-danger text-white";
        data.closable = false;
        data.message =
          "No se pudo procesar tu ultimo pago. Corte en tramite";
      } else if (data.total > 0) {
        data.class = "bg-primary text-white";
        data.message = "Tienes un saldo por vencer";
      }
      return data;
    },
  },
};
</script>

<template>
  <div
    class="py-2 fixed-top1 text-uppercase alert-dismissible fade show"
    v-if="showPaymentMessage.total && dismissible == false"
    :class="showPaymentMessage.class"
  >
    <small class="d-block text-center font-weight-bold"
      >{{ showPaymentMessage.message }}
      <router-link
        to="/invoices"
        class="text-white btn-link mx-4"
        v-if="user.role == 'admin'"
        >IR A PAGOS</router-link
      ></small
    >
    <button type="button" class="close p-0 px-2" v-if="showPaymentMessage.closable" @click="dismissible = true">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>