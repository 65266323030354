<template>
  <div>
    <div class="container-fluid py-4">
      <div class="row align-items-center">
        <div class="col">
          <h3>Admin Control</h3>
        </div>

        <div class="w-100 py-2"></div>

        <div class="col col-md-5">
          <div class="border p-3 h-100">
            <h5>Cambiar token</h5>
            <div class="input-group mt-4">
              <small>EMPRESA</small>
              <div class="w-100"></div>
              <v-select
                v-model="business"
                :reduce="(c) => c._id"
                class="style-chooser"
                name="business"
                autocomplete="off"
                :options="getBusiness"
              >
                <template v-slot:option="option">
                  <span
                    :class="{
                      'text-danger': !option.enabled,
                      'text-success': option.enabled,
                    }"
                    >{{ option.label }}</span
                  >
                </template>
              </v-select>
              <div class="w-100"></div>
              <select
                name="business"
                class="form-control d-none"
                v-model="business"
                :disabled="businesses.length == 0"
              >
                <option :value="null" disabled>Seleccione</option>
                <option
                  v-for="business in businesses"
                  :value="business._id"
                  :key="business._id"
                  :class="{
                    'text-danger': !business.enabled,
                  }"
                >
                  {{ business.custom_name || "" }} - {{ business.name }}
                </option>
              </select>
            </div>

            <div class="input-group mt-2">
              <small>USUARIO</small>
              <div class="w-100"></div>
              <input
                name="email"
                type="text"
                class="form-control"
                v-model="user.email"
                disabled
              />
            </div>

            <div class="input-group mt-2">
              <small>PASSWORD</small>
              <div class="w-100"></div>
              <input
                name="password"
                type="password"
                class="form-control"
                v-model="password"
                @keyup.enter="changeToken()"
              />
            </div>

            <div class="input-group mt-4">
              <button
                class="btn btn-success btn-block"
                @click="changeToken()"
                :disabled="loading"
              >
                <font-awesome-icon icon="sync" />
              </button>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="border p-3">
            <div class="row">
              <div class="col">ULTIMOS LOGINS</div>
              <div class="col-auto">
                <button
                  class="btn btn-sm btn-outline-primary"
                  @click="loadLogins()"
                >
                  <font-awesome-icon icon="sync" />
                </button>
              </div>
            </div>

            <div class="overflow-auto mt-2 custom_scroll" style="">
              <ul class="list-group" style="height: 27vh">
                <li class="list-group-item py-1" v-if="!logins.length">
                  <div class="row">
                    <div class="col">
                      <small class="d-block text-center">Cargando...</small>
                    </div>
                  </div>
                </li>

                <li
                  class="list-group-item py-1"
                  v-for="login in logins"
                  :key="login._id"
                >
                  <div class="row align-items-center">
                    <div class="col-auto text-center">
                      <small>
                        {{ login.createdAt | moment("HH:mm") }}
                      </small>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <small>
                            <strong class="text-uppercase"
                              >{{ login.user && login.user.name }}
                            </strong>
                          </small>
                          <div class="w-100"></div>
                          <small class="text-info">{{
                            login.business && login.business.name
                          }}</small>
                          <small class="text-info d-none">{{
                            login.remoteAddress
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="w-100 py-3"></div>

        <div class="col">
          <div class="row">
            <div class="col">
              <h3>System Setting</h3>
            </div>
            <div class="col-auto">
              <button class="btn btn-success" @click="loadsystemSettings()">
                <font-awesome-icon icon="sync" />
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <ul
                class="list-group-item overflow-auto custom_scroll"
                style="max-height: 45vh"
              >
                <li
                  class="list-group-item click p-0 overflow-hidden"
                  v-for="s in settings"
                  :key="s._id"
                  @click="editSystemSetting(s)"
                >
                  <div
                    class="col-auto position-absolute d-none"
                    style="right: 5px; top: -5px; z-index: 100"
                  >
                    <button class="btn btn-sm h-100">
                      <font-awesome-icon icon="pen" size="xs" />
                    </button>
                  </div>
                  <div class="row align-items-center bglight">
                    <div class="col-5 px-4 bgwhite">
                      <strong>{{ s.key }}</strong>
                      <div class="w-100"></div>
                      <small>{{ s.description }}</small>
                    </div>
                    <div
                      class="col px-4 h-100 text-wrap text-uppercase"
                      :class="{
                        'text-danger':
                          s.value == 'false' ||
                          s.value == '0' ||
                          s.value == 'null' ||
                          s.value < 1,
                        'text-success':
                          s.value == 1 || s.value == 'true' || s.value > 0,
                      }"
                    >
                      {{ s.value }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col">
              <div class="row no-gutters py-2">
                <div class="col-12">
                  <small>KEY</small>
                  <input type="text" class="form-control" v-model="key" />
                </div>
                <div class="col-12 my-2">
                  <small>DESCRIPTION</small>
                  <input
                    type="text"
                    class="form-control"
                    v-model="description"
                  />
                </div>
                <div class="col">
                  <small>VALUE</small>
                  <textarea class="form-control" v-model="value"></textarea>
                </div>
                <div class="col-12">
                  <button
                    class="btn btn-primary btn-block btn-sm mt-2"
                    @click="addSystemSetting()"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      business: null,
      loading: false,
      password: "",
      logins: [],
      settings: [],
      key: "",
      value: "",
      description: "",
    };
  },
  mounted() {
    this.load();
    this.business = this.info._id;
    this.loadLogins();
    this.loadsystemSettings();
  },
  computed: {
    businesses() {
      return this.$store.state.businesses;
    },
    info() {
      return this.$store.state.info;
    },
    user() {
      return this.$store.state.user;
    },
    getBusiness() {
      return this.businesses.map((b) => {
        return {
          _id: b._id,
          label: `${b.custom_name || ""} - ${b.name}`,
          enabled: b.enabled,
        };
      });
    },
  },
  methods: {
    emitReload(socket) {
      const { business_id } = socket;
      console.log("🚀 ~ file: socket.vue:117 ~ logout ~ socket:", socket);
      this.socket.emit("cmd", {
        to: business_id,
        payload: {
          cmd: "reload",
        },
      });
    },
    logout(socket) {
      const { business_id, email } = socket;
      console.log("🚀 ~ file: socket.vue:117 ~ logout ~ socket:", socket);
      this.socket.emit("cmd", {
        to: business_id,
        payload: {
          cmd: "logout",
          email,
        },
      });
    },
    closeapp(socket) {
      const { business_id } = socket;
      this.socket.emit("cmd", {
        to: business_id,
        payload: {
          cmd: "closeapp",
          content: '<h1 class="text-center">Reinicie la app</h1>',
        },
      });
    },
    emitUpdate(socket) {
      console.log("🚀 ~ file: socket.vue:112 ~ emitUpdate ~ socket:", socket);
      const { business_id } = socket;

      this.socket.emit("update_data", business_id);
    },
    loadUsers() {
      this.socket.emit("getUsers");
    },
    editSystemSetting(s) {
      this.key = s.key;
      this.description = s.description;
      this.value = s.value;
      //this.addSystemSetting();
    },
    addSystemSetting() {
      this.$http
        .post("systemSetting", {
          key: this.key,
          value: this.value,
          description: this.description,
        })
        .then(() => {
          this.loadsystemSettings();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadsystemSettings() {
      this.$http
        .get("systemSetting")
        .then((res) => {
          this.settings = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeToken() {
      this.loading = true;

      this.$http
        .post("auth/login", {
          bid: this.business,
          password: this.password,
          email: this.user.email,
        })
        .then((res) => {
          this.$bvToast.toast("Token cambiado", {
            title: "Éxito",
            variant: "success",
            solid: true,
          });
          this.$emit("logoutSocket");
          let token = res.data.token;
          localStorage.token = token;
          //this.$store.commit("setToken", token);
          this.$router.push("/loading?redirect=/admin");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Error al cambiar token", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadLogins() {
      this.$http.get("logins?limit=20").then((res) => {
        this.logins = res.data.data;
      });
    },
    load() {
      this.$http.get("business?enabled=1").then((res) => {
        //this.businesses = res.data.data;
        this.$store.commit("setBusinesses", res.data.data);
      });
    },
  },
};
</script>

<style>
.style-chooser {
  width: 100%;
  border: 1px solid #ced4da;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: var(--bg-white) !important;
  border: none;
  color: var(--text-color) !important;
  text-transform: uppercase;
  /* font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
  color: var(--text-color) !important;
}
</style>