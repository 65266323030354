<template>
  <div class="row">
    <div class="col-md-7 col-12 float-right">
      <div class="row justify-content-center">
        <div class="col-12 bgwhite cart" ref="cart">
          <div class="row">
            <div class="col-12" v-if="ticket.products.length == 0">
              <div class="text-center text-muted mt-4">
                <font-awesome-icon icon="shopping-cart" size="2x" />
                <h4>No hay articulos en el carro</h4>
              </div>
            </div>

            <div
              class="col-12 py-1 product-cart border-bottom"
              v-for="(p, i) in ticket.products"
              :key="`p-${i}`"
              @click="setProduct(p)"
              style="cursor: pointer"
              :class="{
                'border border-danger text-danger':
                  p.stockEnabled && (p.stockqty <= 0 || p.qty > p.stockqty),
                'bg-primary text-light': product._id == p._id,
              }"
            >
              <div class="row align-items-center no-gutters">
                <div
                  class="col-1 font-weight-bold text-uppercase text-center px-0"
                >
                  {{ p.qty }}
                  <small class="d-block">{{ p.unit }}</small>
                </div>
                <div class="col pl-2 font-weight-bold text-uppercase">
                  <span class="d-block">{{ p.name }}</span>
                  <small class="mr-3">
                    VALOR: ${{ formatNumber(p.value) }}
                  </small>
                  <small class="mr-3">CODIGO: {{ p.code }} </small>
                  <small class="mr-3" v-if="p.stockEnabled">
                    STOCK: {{ p.stockqty || 0 }}
                    <span v-if="p.storage"
                      >- {{ getStorageName(p.storages, p.storage) }}</span
                    >
                  </small>
                  <small class="mr-3"> DCTO: {{ p.discount || 0 }}% </small>
                  <div
                    class=""
                    :class="{
                      'd-none': product._id != p._id,
                      'd-block': product._id == p._id,
                    }"
                    v-if="p.stockEnabled && p.storages"
                  >
                    <select
                      v-model="p.storage"
                      class="custom-select custom-select-sm"
                    >
                      <option :value="null" v-if="p.storages.length < 1">
                        SIN STOCK
                      </option>
                      <option
                        :key="s._id"
                        :value="s.storage._id"
                        v-for="s in p.storages"
                      >
                        {{
                          `${s.storage.name} - ${s.qty} ${p.unit} disponibles` ||
                          "---"
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-3 text-right font-weight-bold">
                  ${{ formatNumber(p.total) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2 bg-secondary text-light">
          <div class="row py-2 d-none">
            <div class="col-4 ml-auto text-right">
              <h3>NUMPAD</h3>
            </div>
            <div class="col-4 text-right">
              <h3>{{ numpad }}</h3>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-4 ml-auto text-right">
              <h3>TOTAL</h3>
            </div>
            <div class="col-4 text-right total">
              <h3>${{ formatNumber(total) }}</h3>
            </div>
          </div>
        </div>

        <div class="w-100 mt-3"></div>

        <div class="col-12 px-0">
          <div class="row h-100 no-gutters">
            <div class="col-lg-4 col-3">
              <button
                :ref="'modal_payment' + tabID"
                class="btn btn-success btn-block py-5 h-100"
                :disabled="ticket.products.length == 0"
                @click="paymentModal()"
                id="paymentBtn"
              >
                <font-awesome-icon icon="arrow-circle-right" size="3x" />
                <h4 class="py-2 mt-2 font-weight-bold">PAGO</h4>
              </button>
            </div>

            <div class="col-6 px-0 col-md-5">
              <div class="row no-gutters btn-group h-100" id="numpad">
                <div class="col-4" v-for="i in 9" :key="i">
                  <div class="">
                    <button
                      class="btn btn-primary btn-block border-white"
                      @click="pad(i)"
                    >
                      <span class="font-weight-bold py-2 d-block">{{ i }}</span>
                    </button>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    class="btn btn-primary btn-block border-white"
                    @click="pad(0)"
                  >
                    <span class="font-weight-bold py-1 d-block">0</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-3" id="pad">
              <div class="row h-100 no-gutters">
                <div class="col col-md-6">
                  <button
                    :disabled="!product.code"
                    class="btn btn-block h-100"
                    :class="{
                      'btn-outline-info': mode != 'qty',
                      'btn-info': mode == 'qty',
                    }"
                    @click="toggleMode('qty')"
                  >
                    <small class="font-weight-bold d-block">{{mode == 'qty' ? 'OK' : 'CANT'}}</small>
                  </button>
                </div>

                <div class="col col-md-6">
                  <button
                    :disabled="!product.code || !product.editableValue"
                    class="btn btn-block h-100"
                    :class="{
                      'btn-outline-info': mode != 'value',
                      'btn-info': mode == 'value',
                    }"
                    @click="toggleMode('value')"
                  >
                    <small class="font-weight-bold d-block">{{mode == 'value' ? 'OK' : 'PRECIO'}}</small>
                  </button>
                </div>

                <div class="col col-md-6">
                  <button
                    class="btn py-2 btn-block h-100"
                    @click="toggleMode('desc')"
                    :class="{
                      'btn-outline-info': mode != 'desc',
                      'btn-info': mode == 'desc',
                    }"
                    :disabled="!product.code"
                  >
                    <span class="font-weight-bold d-block">
                      <small class="font-weight-bold d-block">{{mode == 'desc' ? 'OK' : '% DESC'}}</small>
                    </span>
                  </button>
                </div>

                <div class="col col-md-6">
                  <button
                    class="btn btn-outline-danger py-2 btn-block h-100"
                    :disabled="!product.code"
                    @click="delpad()"
                  >
                    <span class="font-weight-bold d-block">
                      <font-awesome-icon icon="backspace" />
                    </span>
                  </button>
                </div>

                <div class="col-12">
                  <button
                    class="btn btn-outline-danger py-2 btn-block h-100"
                    :disabled="!product.code"
                    id="delBtn"
                    @click="remove()"
                  >
                    <span class="font-weight-bold d-block">
                      <font-awesome-icon icon="trash" /> (DEL)
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-5 mt-3 float-left">
      <div class="row d-block d-md-none1">
        <div class="col-12 text-right" style="margin-top: -15px">
          <small class="click text-muted" @click="startTour()">
            <font-awesome-icon icon="question-circle" />
            COMO USAR</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col px-0 pb-2">
          <input
            type="text"
            class="
              form-control
              font-weight-bolder
              text-uppercase
              form-control-lg
              py-4
            "
            id="codeInput"
            v-focus
            placeholder="INGRESE CODIGO DE PRODUCTO Y PRESIONE ENTER PARA AGREGAR"
            v-model="code"
            :ref="'codeInput'"
            @keyup.enter="findAndAdd()"
            @focus="mode = ''"
          />
        </div>
        <div class="col-2 px-0 pb-2">
          <button
            class="btn btn-success btn-block h-100"
            id="addBtn"
            @click="findAndAdd()"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </div>
        <div class="col-lg-2 col-2 px-0 pb-2">
          <button
            class="btn btn-primary btn-block h-100"
            id="searchBtn"
            @click="findByName()"
          >
            <font-awesome-icon icon="search" />
          </button>
        </div>
        <div class="col-12">
          <small class="text-muted"
            >F1 CODIGO | ENTER AGREGAR | F3 BUSCAR</small
          >
        </div>
      </div>
      <div
        class="row product-list overflow-scroll custom_scroll"
        style="max-height: 60vh"
      >
        <div class="col-12 mb-1 px-0" v-if="filter">
          <span class="alert alert-info d-block">
            <strong>Mostrando resultados para</strong>
            '<span class="text-uppercase">{{ filter }}</span
            >'.
            <button
              class="btn btn-link"
              @click="
                filter = '';
                code = '';
              "
            >
              (F1) VER TODOS
            </button>
          </span>
        </div>
        <div
          class="col-12 p-2 border text-center py-5"
          v-if="productList.length == 0"
        >
          <span class="d-block fs-4 py-5">No tienes productos</span>
          <router-link
            to="/products"
            class="btn btn-primary"
            v-if="user.role == 'admin'"
            >Agregar productos</router-link
          >
        </div>
        <div
          class="col-lg-3 col-6 p-2 border product"
          v-for="(p, i) in productList"
          :key="i"
          @click="addProduct(p)"
        >
          <div class="row no-gutters">
            <div class="col-12 product-code">
              <span class="badge d-block mt-2 bg-dark text-light">
                {{ p.code }}
              </span>
            </div>

            <div class="col-12">
              <img
                loading="lazy"
                class="img-fluid px-2"
                :src="getImage(p.image)"
              />

              <div class="product-value text-right">
                <span class="badge bg-danger px-3 text-light">
                  ${{ formatNumber(p.value) }}
                </span>
              </div>
              <div style="position: absolute; top: 0px; z-index: 999">
                <font-awesome-icon
                  icon="star"
                  v-if="p.featured"
                  size="1x"
                  class="text-warning ml-1"
                ></font-awesome-icon>
              </div>
              <small
                class="d-block text-center font-weight-bold mt-2 text-uppercase"
              >
                {{ p.name }}
              </small>
            </div>
          </div>
        </div>
        <!-- load more -->
        <div class="col-12 text-center py-3" v-if="productList.length >= limit">
          <button
            class="btn btn-outline-primary"
            @click="limit += 80"
            v-if="productList.length >= limit"
          >
            Cargar mas
          </button>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal-payment"
      :id="'modal-payment' + tabID"
      size="xl"
      style="max-width: 100% !important"
      title="Informacion de pago"
      hide-footer
    >
      <div class="row">
        <div class="col-12 col-lg-6">
          <label class="font-weight-bold">Notas</label>
          <textarea
            class="form-control"
            id="notesInput"
            placeholder="(Opcional)"
            rows="3"
            v-model="ticket.notes"
          ></textarea>

          <div class="w-100 mt-2"></div>
          <h4>APLICAR DESCUENTO</h4>
          <input
            type="text"
            class="form-control"
            v-model="ticket.discount"
            @focus="$event.target.select()"
            id="discountInput"
          />
          <div class="row align-content-center no-gutters" id="discountBtn">
            <div class="col-2 col-md-1">
              <button
                class="btn btn-sm btn-outline-secondary btn-block"
                @click="applyDiscount(0)"
              >
                0%
              </button>
            </div>
            <div class="col-md-1 col-2" v-for="i in 20" :key="i">
              <button
                class="btn btn-sm btn-outline-secondary btn-block"
                @click="applyDiscount(i)"
              >
                <small>{{ i * 5 }}</small>
              </button>
            </div>
          </div>

          <div class="w-100 mt-2"></div>

          <h4>TIPO DE DOCUMENTO</h4>
          <div id="documentType">
            <button
              class="btn btn-block"
              :class="{
                'btn-outline-secondary': ticket.documentType != 'DTE',
                'btn-primary': ticket.documentType == 'DTE',
              }"
              @click="ticket.documentType = 'DTE'"
              v-if="info.beEnabled && hasPermission(this.user, 'pdv_dte')"
            >
              Boleta electronica SII
            </button>
            <button
              class="btn btn-block"
              :class="{
                'btn-outline-secondary': ticket.documentType != 'TICKET',
                'btn-primary': ticket.documentType == 'TICKET',
              }"
              @click="ticket.documentType = 'TICKET'"
            >
              Comprobante de venta
            </button>
          </div>
          <div class="w-100 mb-2"></div>
        </div>
        <div class="col-lg-6 col-12">
          <h4>MEDIO DE PAGO</h4>
          <small class="form-text text-muted"> Seleccione medio de pago </small>
          <div style="max-height: 220px; overflow-y: scroll">
            <div class="row no-gutters" id="payments">
              <div
                class="col-md-4 col-6"
                v-for="(payment, i) in payments"
                :key="`payment-${i}`"
              >
                <button
                  class="btn btn-block btn-sm"
                  :disabled1="total == 0 || total_payments >= total"
                  @click="setPayment(payment, 1)"
                  :class="{
                    'btn-outline-info': !paymentIn(payment),
                    'btn-info': paymentIn(payment),
                  }"
                >
                  {{ payment }}
                </button>
              </div>
            </div>
          </div>

          <div class="w-100 mt-4"></div>

          <h4>CANTIDAD DE DINERO</h4>

          <div class="row no-gutters">
            <div class="col-12">
              <div
                class="row align-items-center no-gutters mb-2 border py-2 px-3"
                v-for="(p, i) in ticket.payments"
                :ref="'paymentinput'"
                :key="i"
              >
                <div class="col font-weight-bold">
                  {{ p.name }}
                </div>
                <div class="col-5">
                  <input
                    type="number"
                    autocomplete="false"
                    class="form-control form-control-sm text-right"
                    v-model.number="p.amount"
                    @focus="$event.target.select()"
                  />
                </div>
                <div
                  class="col-1 text-danger text-center click font-weight-bold"
                >
                  <button
                    class="btn btn-sm btn-outline-danger btn-block"
                    @click="removePayment(p)"
                  >
                    <font-awesome-icon icon="times"></font-awesome-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="row">
                <div class="w-100 border-top"></div>
                <div class="col">
                  <h4 class="text-right font-weight-bold py-2">PAGOS:</h4>
                </div>
                <div class="col-4">
                  <h4 class="text-right font-weight-bold py-2">
                    ${{ formatNumber(total_payments) }}
                  </h4>
                </div>
                <div class="w-100 border-top"></div>
                <div class="col">
                  <h4 class="text-right font-weight-bold py-2">PEDIDO:</h4>
                </div>
                <div class="col-4">
                  <h4 class="text-right font-weight-bold py-2">
                    ${{ formatNumber(total) }}
                  </h4>
                </div>
                <div class="w-100 border-top"></div>
                <div class="col">
                  <h4 class="text-right font-weight-bold py-2">VUELTO:</h4>
                </div>
                <div class="col-4">
                  <h4 class="text-right font-weight-bold py-2">
                    ${{ formatNumber(total_payments - total) }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <input
            type="number"
            v-model.number="ticket.amount"
            @focus="$event.target.select()"
            class="form-control form-control-lg"
            v-focus
            v-if="false"
          />
        </div>
      </div>
      <div class="row mt-5 no-gutters" id="actions">
        <div class="col-6 col-md">
          <button
            class="btn py-4 btn-block btn-secondary btn-lg h-100"
            :loading="saving"
            @click="saveTicket()"
            :disabled="saving"
          >
            <div
              class="spinner-border spinner-border-sm text-light mb-1"
              role="status"
              v-if="saving"
            >
              <span class="sr-only">Loading...</span>
            </div>
            {{ saving ? `Guardando pedido...` : "Generar ticket" }}
          </button>
        </div>
        <div class="col-6 col-md">
          <button
            class="btn py-4 btn-block btn-success btn-lg h-100"
            :loading="saving"
            @click="saveTicket(true)"
            :disabled="saving"
          >
            <div
              class="spinner-border spinner-border-sm text-light mb-1"
              role="status"
              v-if="saving"
            >
              <span class="sr-only">Loading...</span>
            </div>
            {{
              saving ? `Guardando pedido...` : "(F4) Generar ticket e imprimir"
            }}
          </button>
        </div>
        <div class="col-6 col-md d-none">
          <button
            class="btn btn-lg btn-info btn-block h-100"
            @click="saveTicket(true, 5)"
            :disabled="saving"
          >
            <div
              class="spinner-border spinner-border-sm text-light mb-1"
              role="status"
              v-if="saving"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <font-awesome-icon icon="print" />
            {{ saving ? `Cargando...` : "Ticket + Cambio" }}
          </button>
          <small class="d-block text-muted text-center"
            >Ambas comandas en una</small
          >
        </div>
        <div class="col-6 col-md" v-if="info.remotePrintEnabled">
          <button
            class="btn py-4 btn-block btn-success btn-lg h-100"
            :loading="saving"
            @click="saveTicket(false, 1, true)"
            :disabled="saving"
          >
            <div
              class="spinner-border spinner-border-sm text-light mb-1"
              role="status"
              v-if="saving"
            >
              <span class="sr-only">Loading...</span>
            </div>
            {{ saving ? `Guardando pedido...` : "Imprimir remoto" }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ipcRenderer, storage } from "../../multi-utils";
import { useShepherd } from "vue-shepherd";

export default {
  props: ["tabID", "current"],
  data() {
    return {
      limit: 80,
      ticket: {
        products: [],
        amount: 0,
        clientName: "TICKETAPP TPV",
        address: "",
        hour: null,
        phone: "",
        type: "Retiro",
        print: true,
        notes: "",
        status: "Pagado",
        payment: null,
        discount: 0,
        tip: 0,
        documentType: "TICKET",
        payments: [],
      },
      product: {},
      mode: null,
      discount: 0,
      code: "",
      numpad: "",
      saving: false,
      filter: "",
    };
  },
  computed: {
    total_payments() {
      return this.ticket.payments.reduce((t, p) => t + p.amount, 0);
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    info() {
      return this.$store.state.info;
    },
    products() {
      //let ps = this.$store.state.products.filter((p) => !p.featured);
      return this.$store.state.products;
    },
    total() {
      return (
        this.ticket.products.reduce((acc, p) => acc + p.total, 0) -
        this.ticket.discount
      );
    },
    sub_total() {
      return this.ticket.products.reduce((acc, p) => acc + p.total, 0);
    },
    productList() {
      let products = []
      if (this.filter.length > 0) {
        let filterWords = this.filter.toLowerCase();
        const filter = (product) => {
          return (
            product.name.toLowerCase().indexOf(filterWords) > -1 ||
            product.code.toLowerCase().indexOf(filterWords) > -1
          );
        };
        products = this.products.filter(filter)
      } else {
        products = [...this.products].sort((a, b) => {
          // sort by featured
          if (a.featured && !b.featured) return -1;
          if (!a.featured && b.featured) return 1;
          // sort by name
          // if (a.name < b.name) return -1;
          // if (a.name > b.name) return 1;
          return 0;
        })
        
      }
      return products.slice(0, this.limit)
    },
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keys);
  },
  mounted() {
    this.loadProducts();
    console.log('autohide', localStorage.autoHideMenu)
    if (localStorage.autoHideMenu == "true") this.$store.commit("hideMenu");
    if (this.info.beEnabled && this.hasPermission(this.user, "pdv_dte")) this.ticket.documentType = "DTE";

    let user = this.$store.state.user;
    if (this.info.sessions) {
      let sessions = this.info.sessions.filter((s) => {
        let u = s.users.find((u) => u.user._id == user._id);
        if (u) return s;
      });
      let storage = "";
      if (sessions && sessions.length > 0) {
        sessions[0].storages.map((s) => {
          storage = s.storage._id;
          return s.storage;
        });
        this.$store.commit("setStorage", storage);
      }
    }
    this.focusInputCode();
    this.bindKeys();
  },
  methods: {
    startTour() {
      const tour = useShepherd({
        useModalOverlay: true,
        exitOnEsc: true,
        defaultStepOptions: {
          classes: "shadow-md",
          scrollTo: true,
        },
      });

      tour.addStep({
        title: "Bienvenido a la guía de TicketApp",
        text: "Esta es la pantalla de venta de TicketApp",
        attachTo: {
          element: "#nav-pdv",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.cancel();
            },
            classes: "btn btn-secondary",
            text: "Cancelar",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Productos",
        text: "Aquí puedes ver los productos disponibles",
        attachTo: {
          element: ".product-list",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Productos",
        text: `Ingresa el codigo de producto y presionar BUSCAR o ENTER`,
        attachTo: {
          element: "#codeInput",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Productos",
        text: "Puedes agregar productos al ticket haciendo click en ellos",
        attachTo: {
          element: ".product-list",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Ticket",
        text: "Aquí puedes ver los productos agregados al ticket",
        attachTo: {
          element: ".cart",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Monto total",
        text: "Aquí puedes ver el monto total del ticket, se actualiza automáticamente al agregar o eliminar productos",
        attachTo: {
          element: ".total",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Acciones",
        text: "Aquí puedes realizar acciones como: Eliminar productos, aplicar descuentos, cambiar cantidad, cambiar precio.",
        attachTo: {
          element: "#pad",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Ticket",
        text: "Puedes eliminar productos del ticket haciendo click en ellos",
        attachTo: {
          element: "#delBtn",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Pago",
        text: "Una vez ya tengas los productos que deseas comprar, puedes proceder a pagar",
        attachTo: {
          element: "#paymentBtn",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Descuento",
        text: "Aquí puedes aplicar un descuento al ticket",
        attachTo: {
          element: "#discountInput",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Descuento",
        text: "O puedes seleccionar un descuento predefinido",
        attachTo: {
          element: "#discountBtn",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      // tipo documento
      tour.addStep({
        title: "Tipo de documento",
        text: "Selecciona el tipo de documento",
        attachTo: {
          element: "#documentType",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      // payments
      tour.addStep({
        title: "Pagos",
        text: `
        <p>Selecciona el tipo de pago</p>
        Si la cantidad es mayor al total, la diferencia sera indicada como vuelto<br>
        Si la cantidad es menor al total, se podra indicar otro medio de pago para la diferencia<br>
        `,
        attachTo: {
          element: "#payments",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      // actions

      tour.addStep({
        title: "Acciones",
        text: "Aquí puedes guardar el ticket, imprimirlo o solo generarlo",
        attachTo: {
          element: "#actions",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Finalizar",
          },
        ],
      });

      tour.start();
    },
    getStorageName(arr, id) {
      let res = arr.find(({ storage }) => storage._id == id);
      return res ? res.storage.name : "- - - -";
    },
    focusInputCode() {
      if (this.current == this.tabID)
        setTimeout(() => {
          this.$refs["codeInput"].focus();
        }, 500);
    },
    paymentIn(p) {
      let pp = this.ticket.payments.map((_) => _.name);
      return pp.includes(p);
    },
    focusPaymentInput() {
      try {
        setTimeout(() => {
          // console.log(this.$refs)
          let index = this.$refs["paymentinput"].length - 1;
          this.$refs["paymentinput"][index].childNodes[1].childNodes[0].focus();
        }, 500);
      } catch (error) {
        console.log("error focus", error);
      }
    },
    removePayment(payment) {
      this.ticket.payments.splice(this.ticket.payments.indexOf(payment), 1);
    },
    setPayment(payment, focus = 0) {
      // if (this.total_payments >= this.total && this.ticket.payments.length <= 1) {
      //   this.ticket.payments = [];
      //   this.setPayment(payment);
      // } else {
      //   let amount = this.total - this.total_payments;
      //   this.ticket.payments.push({
      //     name: payment,
      //     amount,
      //     total: amount,
      //   });
      // }
      // if(focus) this.focusPaymentInput()
      // this.ticket.payment = payment;

      if (
        this.total_payments == this.total &&
        this.ticket.payments.length <= 1
      ) {
        this.ticket.payments = [];
        this.setPayment(payment);
      } else {
        if (this.total_payments == this.total) return false;
        let amount = this.total - this.total_payments;
        this.ticket.payments.push({
          name: payment,
          amount,
          total: amount,
        });
      }
      // this.ticket.payment = payment;
      if (focus) {
        this.focusPaymentInput();
      }
    },
    applyDiscount(i) {
      let discount = (this.sub_total / 100) * (i * 5);
      this.ticket.discount = parseInt(discount);
      this.ticket.payments = [];
      if (this.total > 0) this.setPayment(this.payments[0]);
    },
    paymentModal() {
      if (this.ticket.products == 0) {
        this.$noty.error("Debes ingresar al menos 1 producto");
        return;
      }
      this.$refs["modal-payment"].show();
      // this.ticket.amount = this.total;
      // if (this.ticket.payments.length == 0) this.setPayment(this.payments[0]);
      this.mode = "";
      this.focusPaymentInput();
    },
    bindKeys() {
      if (this.current == this.tabID) {
        window.addEventListener("keyup", this.keys);
      } else {
        window.removeEventListener("keyup", this.keys);
      }
    },
    printTicket2(ticket = null) {
      this.$store.commit("setTicket", ticket);
      let tickethtml = document.querySelector("#ticket-hidden").innerHTML;
      let html = `<!DOCTYPE html>
        <html lang="es">
          <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </head>
          <body>
            ${tickethtml}
          </body>
        </html>`;

      let blob = new Blob([html], { type: "text/html" });
      let url = URL.createObjectURL(blob);
      if (this.isElectron()) {
        storage.get("printer", (err, data) => {
          if (err) this.$noty.error("Intente nuevamente");
          ipcRenderer.send("print", { printerName: data, url });
        });
      } else {
        var w = window.open(url);
        setTimeout(() => {
          w.print();
          w.close();
        }, 1000);
      }
    },
    saveTicket(print = false, mode = 1, remote = false) {
      if (this.ticket.products.length == 0)
        return this.$noty.error("Debe ingresar al menos 1 producto");
      if (this.total < 0) return this.$noty.error("Total invalido");
      this.saving = true;

      this.$http
        .post("ticket", this.ticket)
        .then((res) => {
          return this.$http.get(`ticket/${res.data.data._id}`);
        })
        .then((res) => {
          this.$noty.success("Ticket ingresado correctamente");
          //localStorage.ticket = JSON.stringify(res.data.data);
          //localStorage.info = JSON.stringify(this.$store.state.info);
          localStorage.printMode = 1;
          //this.$store.commit("setTicket", res.data.data);
          if (print) {
            // ipcRenderer.send("printTicket");
            this.$emit("printTicket", { ticket: res.data.data, type: mode });
            // this.$emit("showTicket");
          }

          this.$refs["modal-payment"].hide();

          if (remote) {
            this.$http
              .post("print", { id: res.data.data._id })
              .then(() => {
                this.$noty.success("Enviado a imprimir en red");
              })
              .catch(() => {
                this.$noty.error(
                  "Error al imprimir en red. Intente nuevamente"
                );
              });
          }
          this.clearTicket();
          this.loadProducts();
          // this.loadInfo();
        })
        .catch((err) => {
          console.log({ err });
          this.$noty.error(
            "Error al guardar el ticket, verifique los datos y reintente."
          );
          this.saving = false;
        })
        .finally(() => {
          this.saving = false;
          this.code = "";
          this.filter = "";
          this.$refs["codeInput"].focus();
        });
    },
    clearTicket(show = false) {
      this.ticket = {
        products: [],
        amount: 0,
        clientName: "TICKETAPP TPV",
        createdAt: null,
        address: "",
        hour: null,
        phone: "",
        type: "Retiro",
        print: true,
        notes: "",
        status: "Pagado",
        payment: "",
        discount: 0,
        tip: 0,
        documentType: "TICKET",
        payments: [],
      };

      if (this.info.deliveryEnabled) {
        this.ticket.payment = "";
        this.ticket.status = "Por pagar";
      } else {
        // this.ticket.payment = "Efectivo";
        this.ticket.status = "Pagado";
      }

      //  this.setHour(0);
      if (this.info.beEnabled && this.hasPermission(this.user, 'pdv_dte')) this.ticket.documentType = "DTE";
      else this.ticket.documentType = "TICKET";

      if (show) this.$noty.info("Ticket limpio");
    },
    showPayment() {
      // if (this.current == this.tabID) this.$refs.modal.show();
      this.ticket.amount = this.total;
    },
    toggleMode(mode) {
      if (this.mode == mode) {
        this.mode = "";
        this.numpad = 0;
      } else {
        this.mode = mode;
        this.numpad = 0;
      }
      this.calculate();
    },
    findByName() {
      this.filter = this.code;
    },
    findAndAdd() {
      if (this.code.length == 0) {
        return this.$noty.error("Codigo no valido");
      }
      if (this.code.indexOf("*") == 0 && this.product) {
        let qty = this.code.split("*")[1];
        this.product.qty = parseFloat(qty);
        this.code = "";
        return;
      }
      if (this.code.indexOf("/") == 0 && this.product) {
        let qty = this.code.split("/")[1];
        this.product.value = parseInt(qty);
        this.code = "";
        return;
      }
      let p = this.products.find((pr) => {
        if (pr.code.toLowerCase() == this.code.toLowerCase()) return pr;
      });
      if (p) {
        this.addProduct(p);
      } else {
        this.$noty.error("Producto no encontrado");
      }
      this.code = "";
    },
    delpad() {
      this.numpad = parseInt(this.numpad / 10);
      this.calculate();
    },
    pad(i) {
      this.numpad = parseInt(`${this.numpad}${i}`);
      this.calculate();
    },
    calculate() {
      // console.log({numpad: this.numpad, type: typeof this.numpad})
      switch (this.mode) {
        case "qty":
          this.product.qty = this.numpad;
          this.product.total = this.numpad * this.product.value;
          break;
        case "value":
          this.product.value = this.numpad;
          this.product.total = this.product.value * this.product.qty;
          break;
        case "desc":
          if (this.numpad > 100) {
            return false;
          } else {
            // console.log('descontando....', this.product)
            this.product.discount = parseInt(this.numpad);
            let d =
              ((this.product.value * this.product.qty) / 100) * this.numpad;
            this.product.total = this.product.value * this.product.qty - d;
          }
          break;
        default:
          this.numpad = 0;
      }
    },
    setProduct(p) {
      if (p.code == this.product.code) this.product == {};
      else {
        this.product = p;
      }
    },
    remove() {
      this.mode = "";
      if (!this.product._id) return false;
      this.ticket.products.splice(
        this.ticket.products.indexOf(this.product),
        1
      );
      if (this.ticket.products.length > 0) {
        // get last product from ticket
        this.product = this.ticket.products[0];
      } else this.product = {};
      this.numpad = 0;
    },
    addProduct(p) {
      event && event.preventDefault();
      // let inputCode
      this.mode = "";
      let exists = this.ticket.products.find(
        (product) => p.code == product.code
      );
      if (exists && exists.originalValue == exists.value) {
        exists.qty = exists.qty + 1;
        exists.total = exists.value * exists.qty;
        // set product focus
        this.product = exists;
      } else {
        let np = {
          unit: "Unid",
          originalValue: p.value,
          ...p,
          qty: 1,
          total: p.value,
          stockEnabled: p.stock,
          discount: 0,
          stockqty: p.qty,
          storages: p.storages,
          storage: this.$store.state.storage,
        };
        // set default storage
        if (!np.storage) {
          np.storage =
            p.storages && p.storages.length > 0
              ? p.storages[0].storage._id
              : null;
        }
        this.ticket.products.unshift(np);
        this.product = np;
      }
      this.$refs["cart"].scrollTo(0, 0);
      this.numpad = 0;
    },
    loadProducts() {
      // this.$http.get("product?sort=featured").then(({ data }) => {
      //   this.$store.commit("setProducts", data.data);
      // });
      this.$store.dispatch("getProducts");
    },
    checkCondition(current, con, value) {
      if (con == "lt") return current < value;
      if (con == "lte") return current <= value;
      if (con == "eq") return current == value;
      if (con == "gt") return current > value;
      if (con == "gte") return current >= value;
    },
    keys(e) {
      if (e.key == "F1") {
        e.preventDefault();
        this.code = "";
        this.filter = "";
        this.$refs["codeInput"].focus();
      }

      if (e.key == "F2") {
        e.preventDefault();
        this.paymentModal();
      }

      if (e.key == "F3") {
        e.preventDefault();
        this.findByName();
      }

      if (e.key == "F4") {
        this.$refs["modal-payment"].hide();
        this.saveTicket(true);
      }

      if (this.$refs["modal-payment"].isVisible) {
        //console.log("es visible el modal");
        return false;
      }

      //console.log('no se freno xd')
      //if (e.key == "*") this.toggleMode("qty");
      //if (e.key == "/") this.toggleMode("value");
      //if (e.key == "-") this.toggleMode("desc");
      if (e.key == "Backspace") this.delpad();
      if (e.key == "Delete") this.remove();

      if (e.key == "Enter") {
        this.mode = "";
        this.calculate();
      }

      if (e.key == "1") this.pad(1);
      if (e.key == "2") this.pad(2);
      if (e.key == "3") this.pad(3);
      if (e.key == "4") this.pad(4);
      if (e.key == "5") this.pad(5);
      if (e.key == "6") this.pad(6);
      if (e.key == "7") this.pad(7);
      if (e.key == "8") this.pad(8);
      if (e.key == "9") this.pad(9);
      if (e.key == "0") this.pad(0);
    },
  },
  watch: {
    current: function () {
      this.bindKeys();
      this.focusInputCode();
    },
    "ticket.products": {
      deep: true,
      handler: function (products) {
        console.log('calculando ticket.products')
        let self = this;
        products.forEach(function (product) {
          // product.qty = parseInt(product.qty);
          // if (product.qty < 0)
          // product.qty = 1;

          if (product.promos) {
            product.promos.forEach(function (promo) {
              if (!promo.enabled) return false;
              if (promo.when == "qty") {
                if (self.checkCondition(product.qty, promo.is, promo.value)) {
                  if (promo.in == "%") product.discount = promo.discount;
                  if (promo.in == "$") {
                    product.discount = (promo.discount * 100) / product.value;
                  }
                } else {
                  product.discount = 0;
                }
              } else if (promo.when == "total") {
                if (product.total == promo.is) {
                  // to do
                }
              }
            });
          }

          product.discountAmount = Math.round(
            (product.value * product.qty * product.discount) / 100
          );
          product.total = Math.round(
            product.value * product.qty -
              (product.value * product.qty * product.discount) / 100
          );

          // product.total = Math.round(prodi)
        });

        this.ticket.amount = this.total;

        // reset discount if no products
        if (this.ticket.products.length == 0) {
          this.applyDiscount(0);
        }

        // reset payments if no products
        if (this.total == 0) {
          this.ticket.payments = [];
          return;
        }

        // reset payments if total is less than total payments
        if (this.ticket.payments.length == 0) this.setPayment(this.payments[0]);
        else if (this.ticket.payments.length == 1) {
          this.ticket.payments = [];
          this.setPayment(this.payments[0]);
        }
      },
    },
  },
};
</script>

<style scoped>
.modal .fade .show {
  max-width: 90% !important;
}
.cart {
  min-height: 37vh;
  max-height: 37vh;
  overflow-y: scroll;
}
.product-list {
  overflow-y: scroll;
  overflow-x: hidden;
}
.product-list .product-value {
  /* position: absolute; */
  margin-top: -25px;
  z-index: 999;
  bottom: 30px;
  right: -1px;
  width: 100%;
}
.product-list .product-code {
  position: absolute;
  z-index: 999;
  top: -5px;
  left: 3px;
  width: 100%;
  font-weight: bold;
}
.product-list .product {
  min-height: 90px;
  cursor: pointer;
  background-color: var(--bg-color) !important;
  display: block;
}

.product-list .product:active {
  background-color: #007bff !important;
  color: white !important;
}
.product-list .product:hover {
  background: "red";
}
</style>