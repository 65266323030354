<template>
  <div style="font-family: sans-serif">
    <table style="width: 100%">
      <tfoot
        style="text-align: right;"
      >
        <tr>
          <th colspan="1" width="70%">SUB TOTAL</th>
          <th>${{ formatNumber(ticket.sub_total) }}</th>
        </tr>
        <tr v-if="ticket.discount">
          <th colspan="1">DESCUENTO</th>
          <th>-${{ formatNumber(ticket.discount) }}</th>
        </tr>
        <tr v-if="ticket.neto">
          <th colspan="1">NETO</th>
          <th>${{ formatNumber(ticket.neto) }}</th>
        </tr>
        <tr v-if="ticket.exento">
          <th colspan="1">EXENTO</th>
          <th>${{ formatNumber(ticket.exento) }}</th>
        </tr>
        <tr v-if="ticket.iva">
          <th colspan="1">IVA (19%)</th>
          <th>${{ formatNumber(ticket.iva) }}</th>
        </tr>
        <tr v-if="ticket.tip">
          <th colspan="1">PROPINA ({{parseInt((ticket.tip * 100) / ticket.total)}}%)</th>
          <th>${{ formatNumber(ticket.tip) }}</th>
        </tr>
        <tr>
          <th colspan="1">TOTAL</th>
          <th>${{ formatNumber(ticket.total) }}</th>
        </tr>
        <tr v-if="ticket.tip">
          <th colspan="1">TOTAL + PROPINA</th>
          <th>${{ formatNumber(ticket.total + ticket.tip) }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
   props: ["ticket", "fontSize"],
};
</script>