<template>
  <div class="px-4 bgwhite border border-top-0">
    <div class="row py-4">
      <div class="col-3 border py-4">
        <h4>BUSCAR EN HISTORIAL</h4>

        <div class="form-group">
          <small class="font-weight-bold text-muted">NOMBRE</small>
          <div class="input-group">
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="name"
              @keyup.enter="search()"
              placeholder="Ej: Juan"
            />
            <button class="btn btn-outline-info btn-sm" @click="name = ''">
              <font-awesome-icon icon="eraser"></font-awesome-icon>
            </button>
          </div>
        </div>

        <div class="form-group">
          <small class="font-weight-bold text-muted">NUMERO DE OT</small>
          <div class="input-group">
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="number"
              @keyup.enter="search()"
              placeholder="Numero de OT Ej: 1111"
            />
            <button class="btn btn-outline-info btn-sm" @click="number = ''">
              <font-awesome-icon icon="eraser"></font-awesome-icon>
            </button>
          </div>
        </div>

        <div class="form-group">
          <small class="font-weight-bold text-muted">RUT</small>
          <div class="input-group">
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="rut"
              @keyup.enter="search()"
              placeholder="Ej: 12345678-9"
            />
            <button class="btn btn-outline-info btn-sm" @click="rut = ''">
              <font-awesome-icon icon="eraser"></font-awesome-icon>
            </button>
          </div>
        </div>

        <div class="form-group">
          <small class="font-weight-bold text-muted">TELEFONO</small>
          <div class="input-group">
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="phone"
              @keyup.enter="search()"
            />
            <button class="btn btn-outline-info btn-sm" @click="phone = ''">
              <font-awesome-icon icon="eraser"></font-awesome-icon>
            </button>
          </div>
        </div>

        <div class="form-group">
          <small class="font-weight-bold text-muted">DESCRIPCION</small>
          <div class="input-group">
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="description"
              @keyup.enter="search()"
            />
            <button
              class="btn btn-outline-info btn-sm"
              @click="description = ''"
            >
              <font-awesome-icon icon="eraser"></font-awesome-icon>
            </button>
          </div>
        </div>

        <div class="form-group">
          <button class="btn btn-block btn-success" @click="search()">
            BUSCAR
          </button>
        </div>
      </div>
      <b-overlay class="col" :show="loading">
        <div class="custom_scroll" style="max-height: 75vh; overflow: scroll">
          <ul class="list-group">
            <li
              class="list-group-item list-group-item-action click"
              :class="{ active: ot._id == otId }"
              v-for="ot in ots"
              :key="ot._id"
              @click="view(ot._id)"
            >
              <div class="row align-items-center">
                <div class="col-2 font-weight-bold">
                  #{{ ot.number }}

                  <small class="d-block">
                    {{ ot.createdAt | moment("DD/MM/YY HH:mm") }}</small
                  >
                </div>
                <div class="col-2 font-weight-bold">
                  <small class="d-block text-muted">RUT</small>
                  {{ ot.rut }}
                </div>
                <div class="col font-weight-bold">
                  <small class="d-block text-muted">NOMBRE</small>
                  {{ ot.name }}
                </div>
                <div class="col">
                  <small v-html="ot.description.slice(0, 40)"></small>
                </div>
                <div class="col-1">
                  <span
                    class="badge text-uppercase"
                    :class="{
                      'badge-success': ot.status == 'finalizada',
                      'badge-primary': ot.status == 'pendiente',
                      'badge-danger': ot.status == 'cancelada',
                    }"
                  >
                    {{ ot.status }}
                  </span>
                </div>
                <div class="col-2 text-right font-weight-bold">
                  <small class="d-block text-muted">TOTAL</small>
                  ${{ formatNumber(ot.total) }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="ots.length == 0">
              <h5 class="text-center py-5">No hay informacion</h5>
            </li>
          </ul>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      ots: [],
      filter: "",
      otId: 0,
      name: "",
      number: "",
      rut: "",
      phone: "",
      description: "",
    };
  },
  methods: {
    view(id) {
      this.$emit('showOT', id);
    },
    search() {
      this.loading = true;
      this.$http
        .get(
          `ot?searchmode=history&number=${this.number}&phone=${this.phone}&rut=${this.rut}&name=${this.name}&description=${this.description}`
        )
        .then((res) => {
          this.ots = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.$noty.error("Error al buscar. Verifique parametros e intente.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>