<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h2>SII BUSINESS {{ business.length }}</h2>
      </div>
      <div class="col-auto">
        <b-button variant="primary" @click="load()">Cargar</b-button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="e in business"
            :key="e._id"

          >
            <div class="row align-items-center">
              <div class="col-1 text-center">
                <small> {{ e.createdAt | moment("DD/MM/YY HH:mm") }}</small>
                <div class="w-100"></div>
              </div>
              <div class="col">
                <small class="d-block" v-if="e">
                  {{e.rut}}</small
                >
                <div class="w-100"></div>
                <small class="font-weight-bold">
                  {{e.rs}}
                </small>
                <div class="w-100"></div>
              </div>  
              <div class="col-12" v-for="a in e.address" :key="a">
                {{a}}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminSiiBusiness",
  data() {
    return {
      loading: false,
      business: [],
    };
  },
  mounted() {
    if (!this.$store.state.user.admin) {
      this.$router.push("/");
    }
    this.load();
  },
  methods: {
    load() {
      this.$http
        .get("admin/sii/business")
        .then((res) => {
          this.business = res.data.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>