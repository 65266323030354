<template>
  <div class="container-fluid container-app px-4">
    <div class="row">
      <div class="col-12 pb-2">
        <b-nav card-header tabs justified>
          <!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
          <b-nav-item to="/web/" exact exact-active-class="active"
            >PEDIDOS {{ $store.state.webTickets.length }}</b-nav-item
          >
          <b-nav-item
            to="/web/clients"
            exact
            exact-active-class="active"
            v-if="user.role == 'admin'"
            ><div class="position-relative">
              <admin-badge />
              CLIENTES
            </div></b-nav-item
          >
          <b-nav-item
            to="/web/stats"
            class="d-none"
            exact
            exact-active-class="active"
            >STATS</b-nav-item
          >
          <b-nav-item
            to="/web/webpay"
            exact
            v-if="webpayEnabled && user.role == 'admin'"
            exact-active-class="active"
            >PAGOS</b-nav-item
          >
           <b-nav-item
            to="/web/schedule"
            exact
            class="d-none"
            v-if="user.role == 'admin'"
            exact-active-class="active"
            >AGENDA</b-nav-item
          >
          <b-nav-item
            to="/web/pages"
            exact
            exact-active-class="active"
            v-if="user.role == 'admin'"
          >
            <div class="position-relative">
              <admin-badge />
              PAGINAS
            </div>
          </b-nav-item>
          <b-nav-item
            class="d-none"
            to="/web/menu"
            exact
            exact-active-class="active"
            >MENUS</b-nav-item
          >
          <b-nav-item
            to="/web/products"
            exact
            exact-active-class="active"
            v-if="hasPermission(user, 'web_products')"
            >PRODUCTOS</b-nav-item
          >
          <b-nav-item
            to="/web/config"
            exact
            exact-active-class="active"
            v-if="user.role == 'admin'"
          >
            <div class="position-relative">
              <admin-badge />
              CONFIGURACION
            </div>
          </b-nav-item>
        </b-nav>
      </div>
      <div class="col-12 bgwhite pb-4 mx-1 border border-top-0">
        <router-view class="" @showWebTicket="showWebTicket" />
      </div>
    </div>
  </div>
</template>

<script>
import adminBadge from "../../components/admin-badge.vue";
export default {
  components: { adminBadge },
  mounted() {
    //this.loadSettings();
  },
  data() {
    return {
      // webpayEnabled: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    webpayEnabled() {
      const {webSettings} = this.$store.state;
      if (!webSettings) return false;

      const { webpayEnabled } = webSettings;
      if (webpayEnabled === undefined) return false;
      
      return webpayEnabled;
    },
  },
  methods: {
    showWebTicket() {
      this.$emit("showWebTicket");
    },
  },
};
</script>