<template>
  <div class="" style="padding: 0px 0px">
    <div style="text-transform: uppercase; font-family: sans-serif !important">
      <div style="padding: 0px 0px; margin: 0; width: 100%; max-width: 1150px">
        <div v-if="printMode == 3">
          <h3 style="font-family: sans-serif">DETALLE PEDIDO</h3>
        </div>

        <header-ticket
          :businessInfo="businessInfo"
          :ticket="ticket"
          :hideInfo="printMode == 3"
          :printMode="printMode"
        />

        <h2
          class="text-center"
          style="text-align: center; font-family: sans-serif"
          v-if="businessInfo.deliveryEnabled && hourPosition == 'top'"
        >
          {{ ticket.hour }}
        </h2>

        <products-ticket
          :ticket="ticket"
          v-if="printMode != 2"
          :hideValues="printMode == 3"
        />

        <div v-if="showPrintMode(1, 2, 4, 6)">
          <products-footer :ticket="ticket" />
        </div>

        <payments-ticket :ticket="ticket" v-if="showPrintMode(1, 2, 4, 6)" />

        <clientinfo-simple :ticket="ticket" v-if="showPrintMode(3)" />

        <clientinfo
          :ticket="ticket"
          :businessInfo="businessInfo"
          v-if="showPrintMode(1, 4, 6)"
        />

        <h2
          class="text-center"
          style="text-align: center; font-family: sans-serif"
          v-if="
            businessInfo.deliveryEnabled &&
            printMode != 2 &&
            hourPosition == 'bottom'
          "
        >
          {{ ticket.hour }}
        </h2>

        <span
          :style="{
            'font-size': `${fontSize}px`,
          }"
          class="text-center"
          style="text-align: center; display: block"
          v-if="businessInfo.custom_footer && printMode != 2"
        >
          {{ businessInfo.custom_footer | uppercase }}
        </span>

        <div
          v-if="ticket.isDTE && printMode != 3 && ticket.dteType != 'QUOTE'"
          class="text-center"
          style="
            margin: 20px auto;
            width: 100%;
            max-width: 7cm;
            display: inline-block;
          "
        >
          <img
            :src="TED"
            alt="TED"
            v-if="TED && TED != '' && status == 'offline'"
            style="width: 100%; margin: 0 auto; display: block"
          />

          <img
            v-else
            :src="`https://api.ticketapp.cl/ted?bcid=pdf417&text=${TEDURL}&height=45`"
            alt="TED"
            style="width: 100%; margin: 0 auto; display: block"
          />
          <span
            style="
              text-align: center;
              font-size: 8px;
              font-family: sans-serif;
              display: block;
            "
          >
            Timbre electronico SII RES. {{ businessInfo.siiResCode }} del
            {{ businessInfo.siiResDate | moment("DD/MM/YY") }} - Verifique en
            www.sii.cl
          </span>
        </div>

        <div
          v-if="
            ticket.dteType == 33 &&
            ticket.receptor &&
            ticket.receptor.fmaPago == 1
          "
          style="
            padding: 5px;
            display: inline-block;
            max-width: 8cm;
            width: 100%;
          "
          :style="{ 'font-size': `${fontSize - 1}px` }"
        >
          <div style="width: 100%; border: 2px solid black; padding: 5px">
            <div style="width: 100%; display: block">
              <div style="border-bottom: 2px solid black">
                <strong>Nombre</strong>
              </div>
            </div>
            <div style="width: 45%; display: inline-block; padding-right: 5px">
              <div style="border-bottom: 2px solid black">
                <strong>RUT</strong>
              </div>
              <div style="border-bottom: 2px solid black">
                <strong>Fecha</strong>
              </div>
            </div>
            <div style="width: 45%; display: inline-block">
              <div style="border-bottom: 2px solid black">
                <strong>Firma</strong>
              </div>
              <div style="border-bottom: 2px solid black">
                <strong>Recinto</strong>
              </div>
            </div>
          </div>

          <small
            :style="{ 'font-size': `${fontSize - 4}px` }"
            style="text-align: center"
            >El acuse de recibo que se declara en este acto, de acuerdo a lo
            dispuesto en la letra b) del Art. 4°, y la letra c) del Art. 5° de
            la Ley 19.983, acredita que la entrega de mercaderías o servicio (s)
            prestado (s) ha (n) sido recibido (s).</small
          >
          <div style="text-align: center">
            <strong>CEDIBLE</strong>
          </div>
        </div>

        <span
          style="
            text-align: center;
            font-size: 9px;
            margin-top: 5px;
            display: block;
            font-weight: bold;
            font-family: sans-serif;
          "
        >
          WWW.TICKETAPP.CL
        </span>

        <!-- print mode 4 -->

        <div style="margin-top: 50px" v-if="showPrintMode(4)">
          <div
            class="pagebreak"
            style="page-break-before: always; clear: both; width: 100%"
          ></div>
          <div>
            <h3 style="font-family: sans-serif">DETALLE PEDIDO</h3>
          </div>

          <header-ticket
            :businessInfo="businessInfo"
            :ticket="ticket"
            :hideInfo="true"
          />

          <!-- <div>
            <small>
              Atendido por:
              {{ ticket.user_sell ? ticket.user_sell.name : "Administrador" }}
            </small>
          </div> -->

          <h2
            class="text-center"
            style="text-align: center; font-family: sans-serif"
            v-if="businessInfo.deliveryEnabled && hourPosition == 'top'"
          >
            {{ ticket.hour }}
          </h2>

          <products-ticket :ticket="ticket" hideValues />

          <clientinfo-simple :ticket="ticket" />

          <h2
            class="text-center"
            style="text-align: center; font-family: sans-serif"
            v-if="businessInfo.deliveryEnabled && hourPosition == 'bottom'"
          >
            {{ ticket.hour }}
          </h2>

          <span
            style="
              text-align: center;
              font-size: 9px;
              margin-top: 5px;
              display: block;
              font-weight: bold;
              font-family: sans-serif;
            "
          >
            WWW.TICKETAPP.CL
          </span>
        </div>

        <div style="margin-top: 50px" v-if="printMode == 5">
          <div
            class="pagebreak"
            style="page-break-before: always; clear: both; width: 100%"
          ></div>
          <div>
            <h3 style="font-family: sans-serif">TICKET DE CAMBIO</h3>
          </div>

          <header-ticket
            :businessInfo="businessInfo"
            :ticket="ticket"
            :hideInfo="false"
            :printMode="3"
          />

          <products-ticket :ticket="ticket" :hideValues="true" />

          <img
            :src="`https://api.ticketapp.cl/ted?bcid=code128&text=TICKET|${ticket.number}&height=10`"
            alt="TED"
            style="width: 100%; margin: 20px auto; max-width: 300px"
          />

          <span
            style="
              text-align: center;
              font-size: 9px;
              margin-top: 5px;
              display: block;
              font-weight: bold;
              font-family: sans-serif;
            "
          >
            WWW.TICKETAPP.CL
          </span>
        </div>
        <!-- print mode 6 -->
        <div style="" v-if="printMode == 6">
          <div
            class="pagebreak"
            style="page-break-before: always; clear: both; width: 100%"
          ></div>
          <div>
            <h3 style="font-family: sans-serif">DETALLE PEDIDO</h3>

            <div>
              <products-ticket :ticket="ticket" />
            </div>

            <clientinfo-simple :ticket="ticket" />

            <h2
              class="text-center"
              style="text-align: center; font-family: sans-serif"
              v-if="
                businessInfo.deliveryEnabled &&
                printMode != 2 &&
                hourPosition == 'bottom'
              "
            >
              {{ ticket.hour }}
            </h2>
          </div>

          <div
            class="pagebreak"
            style="page-break-before: always; clear: both; width: 100%"
          ></div>

          <header-ticket
            :businessInfo="businessInfo"
            :ticket="ticket"
            :hideInfo="printMode == 3"
            :printMode="printMode"
          />

          <h2
            class="text-center"
            style="text-align: center; font-family: sans-serif"
            v-if="businessInfo.deliveryEnabled && hourPosition == 'top'"
          >
            {{ ticket.hour }}
          </h2>

          <div v-if="showPrintMode(1, 2, 4, 6)">
            <products-footer :ticket="ticket" />
          </div>

          <payments-ticket :ticket="ticket" v-if="showPrintMode(1, 2, 4, 6)" />

          <clientinfo-simple :ticket="ticket" />
          <!-- 
          <h2
            class="text-center"
            style="text-align: center; font-family: sans-serif"
            v-if="businessInfo.deliveryEnabled && hourPosition == 'bottom'"
          >
            {{ ticket.hour }}
          </h2> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import headerLogo from "./print/header-logo.vue";
import headerTicket from "./print/header-ticket.vue";
import productsTicket from "./print/products-ticket.vue";
import productsFooter from "./print/products-footer.vue";
import paymentsTicket from "./print/payments-ticket.vue";
import clientinfoSimple from "./print/clientinfo-simple.vue";
import clientinfo from "./print/clientinfo.vue";
import bwipjs from "bwip-js";

export default {
  props: [
    "ticket",
    "businessInfo",
    "font",
    "mode",
    "margin",
    "width",
    "copies",
  ],
  components: {
    // headerLogo,
    headerTicket,
    productsTicket,
    productsFooter,
    clientinfoSimple,
    paymentsTicket,
    clientinfo,
  },
  data() {
    return {
      TED: false,
      tedImage: "",
      info: {
        logo: "",
      },
      printMode: 1,
      worker: null,
      tedScale: 1,
    };
  },
  mounted() {
    // register worker for rendering TED

    if (this.ticket.TED) {
      // var canvas = document.createElement("canvas");
      this.renderTED();
      // call renderTED from render-ted.js in a service worker in the background
    }
  },
  computed: {
    TEDURL() {
      return encodeURIComponent(this.ticket.TED);
    },
    hourPosition() {
      if (localStorage.hourPosition) return localStorage.hourPosition;
      return "bottom";
    },
    pageWidth() {
      if (this.width) return this.width;
      if (localStorage.pageWidth) return localStorage.pageWidth;
      return 90;
    },
    marginLeft() {
      if (this.margin) return this.margin;
      if (localStorage.marginLeft) return localStorage.marginLeft;
      return 0;
    },
    fontSize() {
      return (this.font && parseInt(this.font)) || 11;
    },
    logo() {
      return localStorage.logo || "";
    },
    status() {
      return this.$store.state.status || "offline";
    },
  },
  methods: {
    showPrintMode(...modes) {
      return modes.includes(this.printMode);
    },
    renderTED() {
      let $canvas = document.createElement("canvas");
      if (this.status == "offline") {
        let result = bwipjs.toCanvas($canvas, {
          bcid: "pdf417", // Barcode type
          text: this.ticket.TED || "", // Text to encode
          scale: this.tedScale,
        });

        let data = result.toDataURL("image/png");
        this.TED = data;
      }
    },
    getTotal(ticket) {
      var neto = parseInt(ticket.total);
      var discount = parseInt(ticket.discount);
      return neto - discount;
    },
    getVuelto() {
      if (this.ticket.amount == 0) return 0;
      else return this.ticket.amount - this.ticket.total;
    },
    print() {
      window.print();
    },
  },
  watch: {
    "localStorage.printMode": function () {
      this.printMode = parseInt(localStorage.printMode);
    },
    mode() {
      this.printMode = parseInt(this.mode);
    },
    ticket: function () {
      console.log("ticket change");
      //if (localStorage.fontSize) this.fontSize = localStorage.fontSize;
      //console.log("pre font", this.font);
      //if (this.font) console.log("font param", this.font);
      if (localStorage.printMode && !this.mode)
        this.printMode = parseInt(localStorage.printMode);
      //if (this.mode) this.printMode = parseInt(this.mode);

      if (this.ticket && this.ticket.TED) {
        console.time("render-ted");

        this.renderTED();
        console.log(console.timeEnd("render-ted"));
        // const render = (data) => bwipjs.toCanvas(data)

        // this.worker.postMessage({
        //   data: this.ticket.TED,
        //  toCanvas: render,
        // });
      }
    },
  },
};
</script>

<style scoped>
body,
html {
  margin: 0 !important;
  padding: 0 !important;
}
pre {
  white-space: pre-wrap;
  font-size: 14px;
}
.product-name {
  font-size: 14px;
}
.products thead th,
.products tfoot th {
  padding: 5px 0px;
}
.products tbody td {
  padding: 6px 0px;
}
.info {
  font-size: 14px;
}
.info tr,
.info td,
.info th {
  padding: 7px 10px;
}
@media print {
  pre {
    border: 0px !important;
    margin-bottom: 2px;
  }
  .products thead th,
  .products tfoot th {
    padding: 5px 2px;
  }
  .products th,
  .products tbody td {
    padding: 3px 4px;
  }
}
</style>
