<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h2>EMAIL QUEUE {{ emailQueue.length }}</h2>
      </div>
      <div class="col-auto">
        <b-button variant="primary" @click="load()">Cargar</b-button>
      </div>
    </div>

    <div class="row">
        <div class="col">
        <small>TIPO</small>
        <select v-model="type"  class="form-control">
          <option value="null">Todos</option>
          <option value="SII">SII</option>
        </select>
      </div>
      <div class="col">
        <small>LIMITE</small>
        <select v-model="limit" class="form-control">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="e in emailQueue"
            :key="e._id"
            :class="{
              'list-group-item-success': e.response,
              'list-group-item-danger': !e.response && e.attempt > 2,
            }"
          >
            <div class="row align-items-center">
              <div class="col-1 text-center">
                <small> {{ e.createdAt | moment("DD/MM/YY HH:mm") }}</small>
                <div class="w-100"></div>
                {{ e.attempt }}
              </div>
              <div class="col">
                <small class="d-block" v-if="e.response">
                  {{ message(e.response, "messageId") }}</small
                >
                <div class="w-100"></div>
                <small class="font-weight-bold">
                  {{
                    e.business && (e.business.custom_name || e.business.name)
                  }}
                </small>
                <div class="w-100"></div>
                <span>{{ e.subject }}</span>
                <span class="mx-2"> &lt;{{ e.to }}&gt;</span>
              </div>

              <div class="col ml-auto d-none1">
                <small class="d-block">
                  <strong>TEMPLATE:</strong>
                  {{ e.template }}</small
                >
                <small class="d-block">
                  <strong>TIPO:</strong>
                  {{ e.type }}</small
                >
              </div>

              <div class="col ml-auto d-none1" v-if="e.response">
                <small class="d-block">
                  <strong>RESPONSE:</strong>
                  {{ message(e.response, "response") }}</small
                >

                <small class="d-block">
                  <strong>ACEPTADO:</strong>
                  {{ message(e.response, "accepted") }}</small
                >
                <small class="d-block"
                  ><strong>RECHAZADO:</strong>
                  {{ message(e.response, "rejected") }}</small
                >
              </div>

              <div
                class="col-11 ml-auto"
                v-if="e.attachments && e.attachments.length > 0"
              >
                <small class="d-block">Adjuntos</small>
                <small> {{ e.attachments.map((a) => a.path) }}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminEmailQueue",
  data() {
    return {
      loading: false,
      emailQueue: [],
      type: null,
      limit: 50,
    };
  },
  mounted() {
    if (!this.$store.state.user.admin) {
      this.$router.push("/");
    }
    this.load();
  },
  methods: {
    message(response, key) {
      response = JSON.parse(response);
      if (response && response[key]) {
        return response[key];
      }
      return "";
    },
    load() {
      let q = {
        limit: this.limit,
        type: this.type,
      }
      q = Object.keys(q)
        .map((k) => `${k}=${q[k]}`)
        .join("&");

      this.$http
        .get(`admin/email/queue?${q}`)
        .then((res) => {
          this.emailQueue = res.data.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>