<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto ml-auto py-2">
        <button class="btn btn-primary" @click="create">Crear</button>
      </div>
      <div class="w-100"></div>
      <div class="col">
        <b-overlay :show="loading">
          <ul class="list-group">
            <li class="list-group-item" v-if="documents.length == 0">
              <h4 class="text-center py-4">No tienes documentos</h4>
            </li>
            <li
              class="list-group-item list-group-item-action"
              :class="{ 'list-group-item-info': !doc.editable }"
              v-for="doc in documents"
              :key="doc._id"
            >
              <div class="row align-items-center">
                <div class="col-auto">
                  <font-awesome-icon :icon="['fas', 'file-alt']" />
                </div>
                <div class="col">
                  <small class="d-block">NOMBRE</small>
                  <strong>
                    {{ doc.name }}
                  </strong>
                </div>
                <div class="col">
                  <small class="d-block">TIPO</small>
                  <strong>
                    {{ doc.type }}
                  </strong>
                </div>
                <div class="col">
                  <small class="d-block">CARPETA</small>
                  <strong>
                    {{ doc.folder || "/" }}
                  </strong>
                </div>
                <div class="col-auto">
                  <button class="btn btn-link btn-sm" @click="view(doc)">
                    <font-awesome-icon :icon="['fas', 'eye']" />
                  </button>
                  <button
                    class="btn btn-link btn-sm"
                    @click="edit(doc)"
                  
                  >
                    <font-awesome-icon :icon="['fas', 'pen']" />
                  </button>
                  <button class="btn btn-link btn-sm" @click="clone(doc)">
                    <font-awesome-icon :icon="['fas', 'copy']" />
                  </button>
                  <button
                    class="btn btn-link text-danger btn-sm d-none"
                    @click="clone(doc)"
                  >
                    <font-awesome-icon :icon="['fas', 'trash']" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </b-overlay>
      </div>
    </div>

    <b-modal
      title="Editar"
      ref="document-modal"
      size="lg"
      @ok="save"
      scrollable
    >
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="name">Nombre</label>
            <input type="text" class="form-control" v-model="document.name" />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="type">Tipo</label>
            <select class="form-control" v-model="document.type">
              <option value="contrato">Contrato</option>
              <option value="anexo">Anexo</option>
              <option value="finiquito">Finiquito</option>
              <option value="certificado">Certificado</option>
              <option value="carta_aviso">Carta de aviso</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="folder">Carpeta</label>
            <input type="text" class="form-control" v-model="document.folder" />
          </div>
        </div>

        <div class="col-12">
          <vue-editor v-model="document.content"></vue-editor>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import jsPDF from "jspdf";
import { parseTemplate } from "../../utils";

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      loading: true,
      documents: [],
      document: {
        name: "",
        content: "",
        type: "",
        folder: "",
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    clone(doc) {
      this.document = {
        name: `${doc.name}`.replace(/\[(.*)] /g, ""),
        content: doc.content,
        type: doc.type,
        folder: doc.folder,
      };
      this.$refs["document-modal"].show();
    },
    edit(doc) {
      this.document = doc;
      this.$refs["document-modal"].show();
    },
    view(data) {
      this.loading = true;
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      let self = this;
      // add to heading tag style center
      let content = data.content;

      let payload = {
        fecha_de_hoy: this.$moment().format("DD/MM/YYYY"),
        fecha_contrato: this.$moment().format("DD/MM/YYYY"),
        dia_de_pago: 5,
        empresa: {
          nombre: this.info.name,
          rut: this.info.rut,
          direccion: this.info.address,
          comuna: this.info.commune,
          ciudad: this.info.city,
          representante: {
            nombre: this.info.subjectName,
            rut: this.info.subjectRut,
            direccion: this.info.address,
          },
        },
         finiquito: {
            fecha: this.$moment().format("DD/MM/YYYY"),
            razon: "Renuncia",
          },
        empleado: {
          fecha_contrato: this.$moment().format("DD/MM/YYYY"),
          nombre_completo: "Juan Perez",
          rut: "12.345.678-9",
          direccion: "Calle Uno 234",
          comuna: "Santiago",
          ciudad: "Santiago",
          sueldo: 420000,
          nombre_del_cargo: "Desarrollador",
          area: "Desarrollo",
          horas_semanales: 45,
          rol: "Full Time",
        },
      };

      content = parseTemplate(content, payload);

      doc.html(
        `
      <div style="width:950px;text-align:justify;font-family: sans-serif">${content}</div>
      `,
        {
          callback: function (doc) {
            self.loading = false;
            doc.output("dataurlnewwindow");
          },
          margin: 10,
          autoPaging: true,
          width: 800,
          html2canvas: {
            scale: 0.2,
          },
        }
      );
    },
    save() {
      if (!this.document._id) {
        let data = {
          name: this.document.name,
          content: this.document.content,
          type: this.document.type,
          folder: this.document.folder,
        };
        this.$http.post("document/template", data).then(() => {
          this.$refs["document-modal"].hide();
          this.load();
        });
      } else {
        this.$http
          .post(`document/template/${this.document._id}`, this.document)
          .then(() => {
            this.$refs["document-modal"].hide();
            this.load();
          });
      }
    },
    create() {
      this.$refs["document-modal"].show();
    },
    load() {
      this.loading = true;
      this.$http
        .get("document/template")
        .then((response) => {
          this.documents = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>