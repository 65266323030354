<template>
  <div class="container-fluid container-app">
    <div class="row">
      <b-overlay :show="loading" style="width: 100%">
        <div class="col">
          <b-tabs fill nav-class="font-weight-bold text-uppercase">
            <b-tab title="General" :active="loaded" :disabled="!loaded">
              <table class="table">
                <tr>
                  <th class="table-active" width="40%">
                    <span>Nombre o razón social</span>
                    <small class="d-block text-muted"
                      >Aparecerá en el ticket</small
                    >
                  </th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      v-model="name"
                      :disabled="beEnabled"
                    />
                  </td>
                </tr>
                <tr>
                  <th class="table-active" width="40%">
                    <span>Nombre de fantasia</span>
                    <small class="d-block text-muted">
                      Aparecerá en el ticket
                    </small>
                  </th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="(Opcional)"
                      v-model="custom_name"
                    />
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>Logo (Opcional)</span>
                    <small class="d-block text-muted"
                      >Logo imprimible en tickets</small
                    >
                  </th>
                  <td>
                    <b-form-file
                      :value="null"
                      v-model="file"
                      placeholder="Selecciona un archivo o arrastralo hasta aca..."
                      drop-placeholder="Arrastra hasta aca..."
                      accept=".jpg, .png"
                    ></b-form-file>
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>RUT</span>
                    <small class="d-block text-muted"
                      >Aparecerá en el ticket</small
                    >
                  </th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      v-model="rut"
                      :disabled="beEnabled"
                    />
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>Giro</span>
                    <small class="d-block text-muted"
                      >Necesario para la boleta electronica</small
                    >
                  </th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      v-model="giro"
                      :disabled="beEnabled"
                    />
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>Direccion</span>
                    <small class="d-block text-muted"
                      >Aparecerá en el ticket</small
                    >
                  </th>
                  <td>
                    <input type="text" class="form-control" v-model="address" />
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>GPS</span>
                    <small class="d-block text-muted"
                      >Aparecerá en el ticket</small
                    >
                  </th>
                  <td>
                    <div class="row">
                      <div class="col">
                        <input
                          type="text"
                          class="form-control"
                          v-model="location.lat"
                        />
                      </div>
                      <div class="col">
                        <input
                          type="text"
                          class="form-control"
                          v-model="location.lng"
                        />
                      </div>
                      <div class="col-auto" v-if="user.admin">
                        <button
                          class="btn btn-outline-secondary btn-block"
                          @click="getCords()"
                        >
                          <font-awesome-icon icon="sync" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>Comuna</span>
                    <small class="d-block text-muted"
                      >Informacion importante</small
                    >
                  </th>
                  <td>
                    <input type="text" class="form-control" v-model="commune" />
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>Ciudad</span>
                    <small class="d-block text-muted"
                      >Informacion importante</small
                    >
                  </th>
                  <td>
                    <input type="text" class="form-control" v-model="city" />
                  </td>
                </tr>
                <tr>
                  <th class="table-active">
                    <span>Telefono</span>
                    <small class="d-block text-muted"
                      >Aparecerá en el ticket</small
                    >
                  </th>
                  <td>
                    <input type="text" class="form-control" v-model="phone" />
                  </td>
                </tr>
                <tr class="d-none">
                  <th class="table-active">
                    <span>Habilitar delivery</span>
                    <small class="d-block text-muted"
                      >Habilitar mapa y modulos de entrega</small
                    >
                  </th>
                  <td>
                    <select class="form-control" v-model="deliveryEnabled">
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </td>
                </tr>
                <tr v-if="deliveryEnabled">
                  <th class="table-active">
                    <span>(Mapa) Tiempo de entrega de pedido</span>
                    <small class="d-block text-muted"
                      >Ocultar pedidos entregados despues de x minutos</small
                    >
                  </th>
                  <td>
                    <select class="form-control" v-model="mapTimeout">
                      <option :value="1">1 minuto</option>
                      <option :value="5">5 minutos</option>
                      <option :value="10">10 minutos</option>
                      <option :value="15">15 minutos</option>
                      <option :value="30">30 minutos</option>
                      <option :value="60">1 hora</option>
                    </select>
                  </td>
                </tr>
                <tr class="d-none">
                  <th class="table-active">
                    <span>Habilitar OT</span>
                    <small class="d-block text-muted">
                      Usar Ordenes de trabajo
                    </small>
                  </th>
                  <td>
                    <select class="form-control" v-model="otEnabled">
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </td>
                </tr>
                <tr class="d-none">
                  <th class="table-active">
                    <span>Habilitar Impresion remota (BETA)</span>
                    <small class="d-block text-muted">
                      Imprimir en otro dispositivo
                    </small>
                  </th>
                  <td>
                    <select
                      class="form-control"
                      disabled
                      v-model="remotePrintEnabled"
                    >
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </td>
                </tr>
                <tr class="d-none">
                  <th class="table-active">
                    <span>Habilitar SumUp</span>
                    <small class="d-block text-muted">
                      Para poder cobrar en la app y ver transacciones
                    </small>
                  </th>
                  <td>
                    <select class="form-control" v-model="sumupEnabled">
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </td>
                </tr>
              </table>
            </b-tab>
            <b-tab title="SumUp" v-if="sumupEnabled">
              <a
                href="https://me.sumup.com/es-cl/account"
                target="_blank"
                v-if="sumupEnabled"
              >
                <small> Datos desde aca </small>
              </a>
              <table class="table table-bordered" v-if="sumupEnabled">
                <tbody>
                  <tr>
                    <th class="table-active" width="40%">
                      <span>Email</span>
                      <small class="d-block text-muted">
                        Correo de la cuenta administradora
                      </small>
                    </th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="sumupemail"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Contraseña</span>
                      <small class="d-block text-muted">
                        Contraseña de la cuenta administradora
                      </small>
                    </th>
                    <td>
                      <input
                        type="password"
                        class="form-control"
                        v-model="sumuppassword"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Clave de cliente secreta</span>
                      <small class="d-block text-muted"> Client secret </small>
                    </th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="sumupclientsecret"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>ID de cliente</span>
                      <small class="d-block text-muted"> Client ID </small>
                    </th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="sumupclientid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>ID de Mercado</span>
                      <small class="d-block text-muted"> Mercado ID </small>
                    </th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="sumupmid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Clave de afiliado</span>
                      <small class="d-block text-muted"> Afiliate key </small>
                    </th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="sumupAffiliateKey"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="SII" :disabled="!loaded">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="table-active" width="40%">
                      <span>Facturacion electronica</span>
                      <small class="d-block text-muted">
                        Usar ticket formato documento electronico
                      </small>
                    </th>
                    <td>
                      <select
                        class="form-control"
                        :disabled="!user.admin"
                        v-model="beEnabled"
                      >
                        <option :value="true">Habilitar</option>
                        <option :value="false">Deshabilitar</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Facturacion Maestra</span>
                      <small class="d-block text-muted">
                        Usar facturacion maestra (varios locales)
                      </small>
                    </th>
                    <td>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="beParent"
                          :disabled="!user.admin"
                        />
                        <button class="btn btn-info">BUSCAR</button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Ambiente SII</span>
                      <small class="d-block text-muted">
                        Seleccione el ambiente a trabajar
                      </small>
                    </th>
                    <td>
                      <select
                        class="form-control"
                        v-model="siiAmbient"
                        :disabled="!user.admin"
                      >
                        <option value="CERT">
                          Certificacion (ambiente de pruebas)
                        </option>
                        <option value="PROD">Produccion</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Numero de resolucion excenta</span>
                      <small class="d-block text-muted">
                        Informacion entragada por el SII
                      </small>
                    </th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="siiResCode"
                        :disabled="!user.admin"
                      />
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Fecha de resolucion</span>
                      <small class="d-block text-muted">
                        Informacion entragada por el SII
                      </small>
                    </th>
                    <td>
                      <input
                        type="date"
                        class="form-control"
                        v-model="siiResDate"
                        :disabled="!user.admin"
                      />
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Actividades economicas</span>
                      <div class="w-100"></div>
                      <button
                        class="btn btn-link btn-xs px-0 d-none"
                        @click="getActs()"
                      >
                        OBTENER INFORMACION DEL SII
                      </button>
                      <div class="w-100"></div>
                      <small class="text-danger">*Debes tener RUT</small>
                    </th>
                    <td>
                      <div v-if="siiActeco.length == 0">
                        <button
                          class="btn btn-link btn-xs px-0"
                          @click="getActs()"
                        >
                          OBTENER INFORMACION DEL SII
                        </button>
                      </div>
                      <div v-if="siiActeco.length > 0">
                        <button
                          class="btn btn-link btn-xs px-0 float-right"
                          @click="siiActeco = []"
                        >
                          BORRAR
                        </button>
                      </div>
                      <div
                        class="row px-0 w-100"
                        v-for="act in siiActeco"
                        :key="act.code"
                      >
                        <div class="col-2 mb-1 pr-0">
                          <input
                            type="text"
                            class="form-control form-control-sm text-center"
                            v-model="act.code"
                            disabled
                          />
                        </div>
                        <div class="col">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="act.name"
                            disabled
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Certificado digital</span>
                      <small class="d-block text-muted">
                        Necesaria para comunicarse con el sii
                      </small>
                    </th>
                    <td>
                      <button
                        class="btn btn-block btn-primary"
                        v-b-modal.modalCert
                      >
                        ADMINISTRAR CERTIFICADO
                      </button>
                    </td>
                  </tr>
                  <tr v-if="beEnabled">
                    <th class="table-active">
                      <span>Archivos CAF</span>
                      <small class="d-block text-muted">
                        Timbraje electronico en www.sii.cl
                      </small>
                    </th>
                    <td>
                      <button class="btn btn-block btn-info" v-b-modal.modalCaf>
                        ADMINISTRAR TIMBRAJE
                      </button>
                    </td>
                  </tr>
                  <tr v-if="beEnabled && false">
                    <th class="table-active">
                      <span>Auto enviar boletas</span>
                      <small class="d-block text-muted">
                        Enviar boletas al sii de forma automatica
                      </small>
                    </th>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control"
                        disabled
                        v-model.number="siiSend"
                      >
                        <option :value="false">Envio manual</option>
                        <option :value="true">Enviar automatico</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="beEnabled && false">
                    <th class="table-active">
                      <span>Auto enviar consumo de folios</span>
                      <small class="d-block text-muted">
                        Defina una hora para enviar los folios usados del dia
                        anterior
                      </small>
                    </th>
                    <td>
                      <select
                        class="form-control"
                        v-model.number="siiAutoSend"
                        disabled
                      >
                        <option value="-1">Envio manual (desactivado)</option>
                        <option value="0">Enviar a las 00:00 hrs</option>
                        <option value="1">Enviar a las 01:00 hrs</option>
                        <option value="2">Enviar a las 02:00 hrs</option>
                        <option value="3">Enviar a las 03:00 hrs</option>
                        <option value="4">Enviar a las 04:00 hrs</option>
                        <option value="5">Enviar a las 05:00 hrs</option>
                        <option value="6">Enviar a las 06:00 hrs</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="Venta">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="table-active" width="40%">
                      <span>Interfaz de venta</span>
                      <small class="d-block text-muted">
                        Elije el la forma de vender
                      </small>
                    </th>
                    <td>
                      <select class="form-control" v-model="gui">
                        <option value="classic">Clasica</option>
                        <option value="tpv">TPV</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Auto-ocultar menu</span>
                      <small class="d-block text-muted">
                        Ocultar el menu al vender
                      </small>
                    </th>
                    <td>
                      <select class="form-control" v-model="autoHideMenu">
                        <option :value="true">Si</option>
                        <option :value="false">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Habilitar propinas</span>
                      <small class="d-block text-muted">
                        Al momento de crear el ticket y en la app
                      </small>
                    </th>
                    <td>
                      <select class="form-control" v-model="tipEnabled">
                        <option :value="true">Si</option>
                        <option :value="false">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Habilitar apertura de caja</span>
                      <small class="d-block text-muted"> </small>
                    </th>
                    <td>
                      <select class="form-control" v-model="openCashRequired">
                        <option :value="true">Si</option>
                        <option :value="false">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <th class="table-active">
                      <span>Habilitar web</span>
                      <small class="d-block text-muted">
                        Publica tus productos y recibe pedidos
                      </small>
                    </th>
                    <td>
                      <select
                        class="form-control"
                        v-model="webEnabled"
                        disabled
                      >
                        <option :value="true">Si</option>
                        <option :value="false">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Medios de pago</span>
                      <small class="d-block text-muted">
                        Crea tus medios de pago
                      </small>
                    </th>
                    <td>
                      <div class="d-block" v-if="payments">
                        <div class="row">
                          <div
                            class="col-4"
                            v-for="(p, i) in payments.split(',')"
                            :key="`p-${i}`"
                          >
                            <button
                              disabled
                              class="btn btn-block btn-outline-primary font-weight-bold"
                            >
                              {{ p }}
                            </button>
                            <small
                              class="d-block text-danger text-center click"
                              @click="removePayment(p)"
                              >ELIMINAR</small
                            >
                          </div>
                        </div>
                      </div>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ej: Efectivo"
                          v-model="payment"
                          @keyup.enter="addPayment()"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-success"
                            @click="addPayment()"
                            :disabled="payment.length < 2"
                          >
                            AGREGAR
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Multi sucursal</span>
                      <small class="d-block text-muted">
                        Separa por: caja / bodegas
                      </small>
                    </th>
                    <td>
                      <div class="btn-group btn-block">
                        <button
                          class="btn"
                          @click="sessionsEnabled = true"
                          :class="{
                            'btn-secondary': sessionsEnabled,
                            'btn-outline-secondary': !sessionsEnabled,
                          }"
                        >
                          Habilitar
                        </button>
                        <button
                          class="btn"
                          @click="sessionsEnabled = false"
                          :class="{
                            'btn-secondary': !sessionsEnabled,
                            'btn-outline-secondary': sessionsEnabled,
                          }"
                        >
                          Deshabilitar
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="sessionsEnabled">
                    <th class="table-active">
                      <span>Sesiones</span>
                      <small class="d-block text-muted">
                        Configura cada sesion/caja
                      </small>
                    </th>
                    <td>
                      <ul class="list-group">
                        <li
                          class="list-group-item list-group-item-action click"
                          v-for="s in sessions"
                          :key="s._id"
                          @click="editSession(s)"
                        >
                          <div class="row">
                            <div class="col">
                              <small class="d-block">NOMBRE</small>
                              <strong>{{ s.name }}</strong>
                            </div>
                            <div class="col">
                              <small class="d-block">USUARIOS</small>
                              <strong
                                v-for="{ user } in s.users"
                                :key="user._id"
                                >{{ user.name }}
                                <small>{{ user.email }}</small>
                              </strong>
                            </div>
                            <div class="col">
                              <small class="d-block">BODEGAS</small>
                              <strong
                                v-for="{ storage } in s.storages"
                                :key="storage._id"
                                >{{ storage.name }}</strong
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                      <button
                        class="btn btn-block btn-sm"
                        v-b-modal.modalSesion
                      >
                        NUEVA SESION
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="Otro">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="table-active" width="40%">
                      <span>Formato de hora</span>
                      <small class="d-block text-muted">
                        Formato de hora en el sistema
                      </small>
                    </th>
                    <td>
                      <select class="form-control" v-model="timeFormat">
                        <option value="12">12 horas</option>
                        <option value="24">24 horas</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active" width="40%">
                      <span>Dia de pago</span>
                      <small class="d-block text-muted">
                        Dia de pago para contratos
                      </small>
                    </th>
                    <td>
                      <input
                        class="form-control"
                        type="number"
                        v-model="payDay"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Nombre Representante legal</span>
                      <small class="d-block text-muted">
                        Ocultar el menu al vender
                      </small>
                    </th>
                    <td>
                      <input
                        class="form-control"
                        type="text"
                        v-model="subjectName"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Rut Representante legal</span>
                      <small class="d-block text-muted">
                        Al momento de crear el ticket y en la app
                      </small>
                    </th>
                    <td>
                      <input
                        class="form-control"
                        type="text"
                        v-model="subjectRut"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="Impresion">
              <table class="table table-bordered">
                <tbody>
                  <tr v-if="isElectron()">
                    <th class="table-active" width="30%">
                      <span>Impresora</span>
                      <small class="d-block text-muted">
                        Impresora con la que trabajaras
                      </small>
                    </th>
                    <td>
                      <select class="form-control" v-model="printer">
                        <option :value="{}" disabled>
                          Seleccione una impresora instalada
                        </option>
                        <option
                          v-for="(print, i) in printers"
                          :key="`${print.name}-${i}`"
                        >
                          {{ print.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active" width="30%">
                      <span>Tamaño de letra</span>
                      <small class="d-block text-muted">
                        Tamaño letra de los tickets impresos
                      </small>
                    </th>
                    <td>
                      <select class="form-control" v-model.number="fontSize">
                        <option :value="9">9 (predeterminado)</option>
                        <option :value="10">10</option>
                        <option :value="11">11</option>
                        <option :value="12">12</option>
                        <option :value="13">13</option>
                        <option :value="14">14</option>
                        <option :value="16">16</option>
                        <option :value="18">18</option>
                        <option :value="20">20</option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <th class="table-active">Ancho del ticket</th>
                    <td>
                      <select class="form-control" v-model.number="pageWidth">
                        <option :value="30">30%</option>
                        <option :value="40">40%</option>
                        <option :value="50">50%</option>
                        <option :value="60">60%</option>
                        <option :value="70">70%</option>
                        <option :value="80">80%</option>
                        <option :value="90">90% (predeterminado)</option>
                        <option :value="95">95%</option>
                        <option :value="100">100%</option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <th class="table-active">Margen del ticket</th>
                    <td>
                      <select class="form-control" v-model.number="marginLeft">
                        <!-- <option value="-10">-10 (EXPERIMENTAL)</option>
                        <option value="-5">-5 (EXPERIMENTAL)</option> -->
                        <option :value="0">0 (predeterminado)</option>
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="isElectron()">
                    <th class="table-active" width="20%">
                      <span>Impresora 2</span>
                      <small class="d-block text-muted">
                        Impresora secundaria
                      </small>
                    </th>
                    <td>
                      <select
                        class="form-control"
                        v-model="printer2"
                        :disabled="!isElectron()"
                      >
                        <option :value="null">
                          No usar impresora secundaria
                        </option>
                        <option
                          v-for="(print, i) in printers"
                          :key="`${print.name}-${i}`"
                        >
                          {{ print.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="isElectron()">
                    <th class="table-active">
                      <span>Tamaño de letra</span>
                      <small class="d-block text-muted">
                        Tamaño letra de los tickets impresos
                      </small>
                    </th>
                    <td>
                      <select
                        class="form-control"
                        v-model.number="fontSize2"
                        :disabled="!printer2"
                      >
                        <option :value="9">9 (predeterminado)</option>
                        <option :value="10">10</option>
                        <option :value="11">11</option>
                        <option :value="12">12</option>
                        <option :value="13">13</option>
                        <option :value="14">14</option>
                        <option :value="16">16</option>
                        <option :value="18">18</option>
                        <option :value="20">20</option>
                      </select>
                    </td>
                  </tr>

                  <tr v-if="isElectron()">
                    <th class="table-active">Ancho del ticket 2</th>
                    <td>
                      <select class="form-control" v-model.number="pageWidth2">
                        <option :value="30">30%</option>
                        <option :value="40">40%</option>
                        <option :value="50">50%</option>
                        <option :value="60">60%</option>
                        <option :value="70">70%</option>
                        <option :value="80">80%</option>
                        <option :value="90">90% (predeterminado)</option>
                        <option :value="95">95%</option>
                        <option :value="100">100%</option>
                      </select>
                    </td>
                  </tr>

                  <tr v-if="isElectron()">
                    <th class="table-active">Margen del ticket 2</th>
                    <td>
                      <select class="form-control" v-model.number="marginLeft2">
                        <!-- <option value="-10">-10 (EXPERIMENTAL)</option>
                        <option value="-5">-5 (EXPERIMENTAL)</option> -->
                        <option :value="0">0 (predeterminado)</option>
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">Imprimir logo?</th>
                    <td>
                      <select class="form-control" v-model="printLogo">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">Posicion hora ticket</th>
                    <td>
                      <select class="form-control" v-model="hourPosition">
                        <option value="top">Superior</option>
                        <option value="bottom">Inferior</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Encabezado ticket</span>
                      <small class="d-block text-muted">
                        Aparecerá despues del telefono
                      </small>
                    </th>
                    <td>
                      <textarea
                        class="form-control"
                        rows="5"
                        v-model="custom_header"
                        placeholder="Instagram, Facebook, Pagina web, etc."
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Pie de ticket</span>
                      <small class="d-block text-muted">
                        Aparecerá al final del ticket
                      </small>
                    </th>
                    <td>
                      <textarea
                        class="form-control"
                        rows="5"
                        v-model="custom_footer"
                        placeholder="Gracias por su compra"
                      ></textarea>
                    </td>
                  </tr>
                  <tr v-if="otEnabled">
                    <th class="table-active">
                      <span>Pie de OT</span>
                      <small class="d-block text-muted">
                        Aparecerá al final de la OT
                      </small>
                    </th>
                    <td>
                      <vue-editor
                        v-model="custom_footer_ot"
                        :editorToolbar="customToolbar"
                      ></vue-editor>
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      <span>Imprimir Ticket de prueba</span>
                      <small class="d-block text-muted">
                        Imprimir ticket de prueba
                      </small>
                    </th>
                    <td>
                      <button
                        class="btn btn-secondary btn-block"
                        @click="printTicket()"
                      >
                        IMPRIMIR PRUEBA
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="Respaldar" :disabled="!loaded">
              <table class="table table-bordered" v-if="true">
                <tbody>
                  <tr>
                    <th class="table-active" width="40%">
                      <span>Productos</span>
                      <small class="d-block text-muted">
                        Respalda tu inventario de productos o sube un archivo
                      </small>
                    </th>
                    <td>
                      <small class="text-muted">Descargar</small>
                      <div class="btn-group btn-block">
                        <button
                          class="btn btn-success"
                          @click="downloadProducts()"
                        >
                          <font-awesome-icon icon="file-csv" /> EXCEL
                        </button>
                        <button
                          class="btn btn-info"
                          @click="downloadProductsJSON()"
                        >
                          <font-awesome-icon icon="file-csv" /> JSON
                        </button>
                        <button class="btn btn-secondary d-none">
                          <font-awesome-icon icon="database" /> DB JSON
                        </button>
                      </div>
                      <small class="text-muted d-block">Subir</small>
                      <div class="btn-group btn-block">
                        <button class="btn btn-success d-none">
                          <font-awesome-icon icon="file-excel" /> Excel CSV
                        </button>
                        <button class="btn btn-secondary d-none">
                          <font-awesome-icon icon="database" /> DB JSON
                        </button>

                        <b-form-file
                          :value="null"
                          v-model="dbProducts"
                          placeholder="Selecciona un archivo o arrastralo hasta aca..."
                          drop-placeholder="Arrastra hasta aca..."
                          accept=".json"
                        ></b-form-file>
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          @click="uploadProducts()"
                        >
                          Subir
                        </button>

                        <div class="dropdown d-none">
                          <button
                            class="btn btn-outline-secondary"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <font-awesome-icon icon="file-upload" />
                            Subir respaldo
                          </button>

                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a class="dropdown-item">
                              <font-awesome-icon icon="file-excel" /> Excel
                            </a>
                            <a class="dropdown-item">
                              <font-awesome-icon icon="file-csv" /> Excel CSV
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
          </b-tabs>
        </div>
      </b-overlay>
    </div>
    <div class="row">
      <div class="col">
        <button
          class="btn btn-success btn-block"
          :disabled="loading || !loaded"
          @click="save()"
        >
          Guardar
        </button>
      </div>
    </div>

    <b-modal
      ref="modalEditSession"
      id="modalEditSession"
      size="lg"
      title="Editar Sesion"
      @ok="updateSession()"
    >
      <div class="row">
        <div class="col">
          <label class="font-weight-bold">Nombre (Ej: Caja 1)</label>
          <input
            type="text"
            class="form-control"
            v-model="session.name"
            placeholder="Ej: Caja 1"
          />
        </div>
        <div class="col">
          <label class="font-weight-bold">Bodegas disponibles</label>
          <v-select
            v-model="session.storages"
            :options="storages"
            multiple
          ></v-select>
        </div>
        <div class="col-12">
          <label class="font-weight-bold">Usuarios</label>
          <v-select
            v-model="session.users"
            :options="users"
            multiple
          ></v-select>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modalSesion"
      size="lg"
      title="Nueva Sesion"
      @ok="saveSesion()"
      no-close-on-backdrop
    >
      <div class="row">
        <div class="col">
          <label class="font-weight-bold">Nombre (Ej: Caja 1)</label>
          <input
            type="text"
            class="form-control"
            v-model="session.name"
            placeholder="Ej: Caja 1"
          />
        </div>
        <div class="col">
          <label class="font-weight-bold">Bodegas disponibles</label>
          <v-select
            v-model="session.storages"
            :options="storages"
            multiple
          ></v-select>
        </div>
        <div class="col-12">
          <label class="font-weight-bold">Usuarios</label>
          <v-select
            v-model="session.users"
            :options="users"
            multiple
          ></v-select>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modalCafUpload"
      size="xl"
      title="Subir Timbraje electronico"
      @ok="saveCaf()"
    >
      <div class="row">
        <div class="col-12">
          <label for="">Archivo</label>
        </div>
        <div class="col">
          <b-form-file
            :value="[]"
            v-model="cafFile"
            placeholder="Selecciona un archivo o arrastralo hasta aca..."
            drop-placeholder="Arrastra hasta aca..."
            accept=".xml"
          ></b-form-file>
        </div>
      </div>
    </b-modal>

    <b-modal id="modalCaf" size="xl" title="Administrar timbraje electronico">
      <div class="row">
        <div class="col-12">
          <button
            class="btn btn-outline-success btn-block btn-sm"
            v-b-modal.modalCafUpload
          >
            SUBIR CAF
          </button>
        </div>
        <div class="col-12 mt-4">
          <h4>Listado de timbrajes</h4>
          <ul class="list-group">
            <li class="list-group-item" v-if="cafs.length == 0">
              <h4 class="text-center">No tienes archivos</h4>
            </li>
            <li
              class="list-group-item"
              :class="{
                'list-group-item-danger': caf.end <= caf.current,
              }"
              v-for="caf in cafs"
              :key="caf._id"
            >
              <div class="row align-items-center">
                <div class="col-12">
                  <span class="d-block font-weight-bold text-uppercase"
                    >{{ getDTEName(caf.type) }} - {{ caf.type }}</span
                  >
                  <span class="mr-5">{{ caf.rs }}</span>

                  <small>{{ caf.rut }}</small>
                  <div class="w-100"></div>
                  <small>{{ caf.createdAt | moment("DD/MM/YYYY") }}</small>
                </div>
                <div class="col">
                  <span class="font-weight-bold">RANGO: </span
                  ><span>{{ caf.start }}-{{ caf.end }}</span>
                  <div class="w-100"></div>
                  <span class="font-weight-bold">PROX. FOLIO: </span
                  ><span class="mr-4">{{ caf.current }}</span>
                  <span class="font-italic"
                    >({{ caf.end - caf.current + 1 }} disponibles)</span
                  >
                  <div class="w-100"></div>
                </div>
                <div class="col-2 col-lg-1">
                  <button
                    class="btn btn-outline-danger btn-sm"
                    @click="removeCaf(caf._id)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal id="modalCert" size="lg" title="Certificado digital">
      <div class="row align-items-center mb-5" v-if="cert && cert._id">
        <div class="col-12 mb-2">
          <h5>Informacion</h5>
          <div class="w-100 border-bottom"></div>
        </div>
        <div class="col-2">
          <span class="font-weight-bold">Nombre</span>
        </div>
        <div class="col-5">
          <span class="text-uppercase">{{ cert.subjectName }}</span>
        </div>
        <div class="col-1">
          <span class="font-weight-bold">RUT</span>
        </div>
        <div class="col-4">
          <span class="text-uppercase" v-if="cert.subjectSerialNumber">
            {{ formatRUT(cert.subjectSerialNumber) }}
          </span>
          <div v-if="!cert.subjectSerialNumber">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="subjectSerialNumber"
              />
              <button
                class="btn btn-secondary"
                @click="setSerialSubject(cert._id)"
              >
                <font-awesome-icon icon="save" />
              </button>
            </div>
            <small class="d-block text-danger"
              >Necesaria para firmar. Ej: 1234567-K</small
            >
          </div>
        </div>

        <div class="w-100 mt-2"></div>

        <div class="col-2">
          <span class="font-weight-bold">Correo</span>
        </div>
        <div class="col-5 custom_scrollX">
          <span class="text-uppercase d-block">{{ cert.subjectEmail }}</span>
        </div>
        <div class="col-1">
          <span class="font-weight-bold">Pais</span>
        </div>
        <div class="col-4">{{ cert.subjectCountry }}</div>

        <div class="w-100 mt-2"></div>

        <div class="col-2">
          <span class="font-weight-bold">Emision</span>
        </div>
        <div class="col-5">
          <span class="text-uppercase">
            {{ cert.emisionDate | moment("DD/MM/YYYY") }}
          </span>
        </div>
        <div class="col-1">
          <span class="font-weight-bold">Expira</span>
        </div>
        <div class="col-4">{{ cert.expireDate | moment("DD/MM/YYYY") }}</div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <h5>Subir archivo</h5>
          <div class="w-100 border-bottom"></div>
        </div>
        <div class="col-3">
          <label for="">Archivo</label>
        </div>
        <div class="col">
          <b-form-file
            :value="[]"
            v-model="certFile"
            placeholder="Selecciona un archivo o arrastralo hasta aca..."
            drop-placeholder="Arrastra hasta aca..."
            accept=".pfx, .p12"
          ></b-form-file>
          <small class="d-block text-muted"
            >Formatos aceptados: .pfx .p12</small
          >
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-3">
          <label for="">Clave del certificado</label>
        </div>
        <div class="col">
          <div class="input-group">
            <input
              v-model="certPassword"
              type="text"
              class="form-control"
              placeholder="*****"
              v-if="viewPw"
            />
            <input
              v-model="certPassword"
              type="password"
              class="form-control"
              placeholder="*****"
              v-if="!viewPw"
            />
            <button
              class="btn"
              :class="{
                'btn-outline-secondary': !viewPw,
                'btn-secondary': viewPw,
              }"
              @click="viewPw = !viewPw"
            >
              <font-awesome-icon icon="eye" />
            </button>
          </div>
          <small class="d-block text-muted"
            >(Opcional) Solo si tu certificado tiene clave</small
          >
        </div>
        <div class="col-12 mt-3">
          <button
            :disabled="certFile == null"
            class="btn btn-block"
            :class="{
              'btn-outline-success': certFile == null,
              'btn-success': certFile != null,
            }"
            @click="saveCert"
          >
            SUBIR CERTIFICADO
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { storage, remote, ipcRenderer } from "../multi-utils";
// import storage from "electron-json-storage";
const path = require("path");
const fs = require("fs");
const Excel = require("exceljs");
import { VueEditor } from "vue2-editor";
import { formatRUT, log, getDTEName } from "../utils";

export default {
  components: {
    VueEditor,
  },
  name: "Settings",
  data() {
    return {
      timeFormat: "24",
      location: {
        lat: 0,
        lng: 0,
      },
      name: "",
      address: "",
      rut: "",
      phone: "",
      printers: [],
      printer: null,
      printer2: null,
      sumupemail: null,
      sumuppassword: null,
      sumupclientsecret: null,
      sumupclientid: null,
      sumupmid: null,
      fontSize: 9,
      fontSize2: 9,
      pageWidth: "90",
      marginLeft: 0,
      pageWidth2: "90",
      marginLeft2: 0,
      sumupAffiliateKey: null,
      sumupEnabled: true,
      otEnabled: true,
      deliveryEnabled: true,
      file: "",
      tipEnabled: false,
      logo: "",
      mapTimeout: 30,
      commune: "",
      city: "",
      beEnabled: false,
      siiResDate: null,
      siiResCode: null,
      siiAmbient: "CERT",
      siiActeco: [],
      giro: "",
      cafFile: null,
      certFile: null,
      certPassword: "",
      viewPw: false,
      cets: [],
      cert: {},
      cafs: [],
      subjectSerialNumber: null,
      siiAutoSend: -1,
      dbProducts: "",
      siiSend: true,
      gui: "classic",
      autoHideMenu: false,
      loading: true,
      loaded: false,
      payments: "",
      custom_name: "",
      sessionsEnabled: false,
      session: {
        name: "",
        storages: [],
        users: [],
      },
      storages: [],
      users: [],
      payment: "",
      webEnabled: false,
      custom_header: "",
      custom_footer: "",
      custom_footer_ot: "",
      printLogo: false,
      customToolbar: [["bold", "italic", "underline"]],
      remotePrintEnabled: false,
      openCashRequired: false,
      hourPosition: "bottom",
      payDay: -1,
      subjectName: "",
      subjectRut: "",
    };
  },
  methods: {
    getCords() {
      this.$http
        .post("w/cords", {
          address: this.address,
          zone: {
            name: this.commune,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.location = response.data.data.location;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActs() {
      let rut = this.rut;
      this.$http.get(`siidata?rut=${rut}`).then((response) => {
        console.log(response.data.data);
        //const { rs, activities, address, email } = response.data.data;
        this.siiActeco = response.data.data.activities.map((a) => {
          return {
            code: a.code,
            name: a.name,
          };
        });
      });
    },
    formatRUT(rut) {
      return formatRUT(rut);
    },
    printTicket() {
      let ticket = {
        number: 1,
        createdAt: Date.now(),
        type: "Retiro",
        clientName: "JUAN SEGURA",
        phone: "912345678",
        total: 999999,
        sub_total: 999999,
        status: "Por pagar",
        products: [
          {
            name: "Producto 1",
            code: "P1",
            value: 999999,
            qty: 1,
            total: 999999,
          },
          {
            name: "Producto 2",
            code: "P2",
            value: 999999,
            qty: 1,
            discount: 100,
            total: 0,
          },
        ],
        notes: "Avisar cuando este listo",
      };
      this.$emit("printTicket", { ticket });
    },
    getDTEName(id) {
      return getDTEName(id);
    },
    addPayment() {
      let spacer = "";
      if (this.payments != "" || this.payments != null) spacer = ",";
      if (this.payments == null) this.payments = this.payment;
      else this.payments += `${spacer}${this.payment}`;
      this.payment = "";
    },
    removePayment(p) {
      let ps = this.payments.split(",");
      this.payments = ps.filter((pa) => pa != p).join();
    },
    editSession(session) {
      this.session = {
        name: session.name,
        storages: session.storages.map((s) => ({
          _id: s.storage._id,
          label: s.storage.name,
        })),
        users: session.users.map((s) => ({
          _id: s.user._id,
          label: `${s.user.name} - ${s.user.email}`,
        })),
      };
      this.$refs.modalEditSession.show();
    },
    saveSesion() {
      if (this.session.name.length < 3) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Debe ingresar un nombre valido para la sesion (3 caracteres min)",
        });
      } else if (this.session.storages.length == 0) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Debe ingresar al menos 1 bodega",
        });
      } else if (this.session.users.length == 0) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Debe ingresar al menos 1 usuario",
        });
      } else {
        let data = {
          name: this.session.name,
          storages: this.session.storages.map((s) => ({ storage: s._id })),
          users: this.session.users.map((s) => ({ user: s._id })),
        };
        this.$http
          .post("session", data)
          .then(() => {
            this.load();
          })
          .catch(() => {
            this.$noty.error("Error al guardar");
          });
      }
    },
    uploadProducts() {
      if (this.dbProducts == null)
        return this.$noty.error("Debe adjuntar un archivo");
      const formData = new FormData();
      formData.append("products", this.dbProducts);

      this.$http
        .post("product/upload", formData)
        .then(() => {
          this.$noty.success("Informacion subida correctamente");
        })
        .catch((err) => {
          let message = "intente nuevamente";
          if (err.body && err.body.details) message = err.body.details;
          this.$noty.error("Error: " + message);
          this.file = null;
        });
    },
    // @writeFile
    writeFile(file, filedata) {
      let d = [];

      filedata.forEach((obj) => {
        d.push({
          ...obj,
          options: "",
        });
      });

      // A new Excel Work Book
      var workbook = new Excel.Workbook();

      // Some information about the Excel Work Book.
      workbook.creator = "TicketApp";
      workbook.lastModifiedBy = "";
      workbook.created = new Date();
      workbook.modified = new Date();

      // Create a sheet
      var sheet = workbook.addWorksheet("Productos");
      // A table header
      sheet.columns = [
        { header: "Codigo", key: "code", width: 20, outlineLevel: 1 },
        { header: "Nombre", key: "name", width: 30 },
        { header: "Descripcion", key: "description", width: 50 },
        { header: "Precio costo", key: "cost_value", width: 15 },
        { header: "Precio", key: "value", width: 15 },
        { header: "Cantidad", key: "qty" },
        { header: "Total Precio costo", key: "total_cost", width: 30 },
        { header: "Total Precio venta", key: "total", width: 30 },
        { header: "Ganancias", key: "profits", width: 30 },
      ];

      sheet.getCell("A1").fill = {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "FF0000FF" },
      };

      // Add rows in the above header
      filedata.forEach((p) => {
        let total_cost = p.qty * p.cost_value || 0;
        let total = p.qty * p.value;
        sheet.addRow({
          code: p.code,
          name: p.name,
          description: p.description,
          cost_value: p.cost_value,
          value: p.value,
          qty: p.qty,
          total_cost,
          total,
          profits: total - total_cost,
        });
      });

      // Save Excel on Hard Disk
      if (file) {
        console.log("🚀 ~ file: settings.vue:1687 ~ file", file);
        workbook.xlsx
          .writeBuffer()
          .then(function (buff) {
            // Success Message
            fs.writeFileSync(
              file.filePath.toString(),
              buff,
              "binary",
              function (err) {
                console.log("🚀 ~ file: settings.vue:1688 ~ err:", err);
                if (err) {
                  throw err;
                }
              }
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        workbook.xlsx.writeBuffer().then(function (buff) {
          // Success Message
          let $el = document.createElement("a");
          $el.setAttribute(
            "href",
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              buff.toString("base64")
          );
          $el.setAttribute("download", "products.xlsx");
          $el.style.display = "none";
          document.body.appendChild($el);
          $el.click();
          document.body.removeChild($el);
        });
      }
    },
    downloadProducts() {
      this.$http.get("product/json").then((res) => {
        let data = res.data.data;
        if (this.isElectron()) {
          const dialog = remote.dialog;
          const writeFile = this.writeFile;
          dialog
            .showSaveDialog({
              title: "Guardar",
              defaultPath: path.join(__dirname, "../products.xlsx"),
              // defaultPath: path.join(__dirname, '../assets/'),
              buttonLabel: "Guardar",
              // Restricting the user to only Text Files.
              properties: [],
            })
            .then((file) => {
              writeFile(file, data);
            })
            .catch((err) => {
              console.log(
                "🚀 ~ file: settings.vue:1727 ~ this.$http.get ~ err:",
                err
              );
              this.$noty.error("Error, intente nuevamente");
            });
        } else {
          this.writeFile(null, data);
        }
      });
    },
    downloadProductsJSON() {
      const dialog = remote.dialog;
      this.$http.get("product/json").then((res) => {
        dialog
          .showSaveDialog({
            title: "Guardar",
            defaultPath: path.join(__dirname, "../products.json"),
            // defaultPath: path.join(__dirname, '../assets/'),
            buttonLabel: "Guardar",
            // Restricting the user to only Text Files.
            properties: [],
          })
          .then((file) => {
            let filedata = res.data.data;
            // Stating whether dialog operation was cancelled or not.
            if (!file.canceled) {
              // Creating and Writing to the sample.txt file
              fs.writeFile(
                file.filePath.toString(),
                JSON.stringify(filedata),
                function (err) {
                  if (err) throw err;
                }
              );
            }
          })
          .catch(() => {
            this.$noty.error("Error, intente nuevamente");
          });
      });
    },
    setSerialSubject(certId) {
      this.$http
        .patch(`sii/cert/${certId}`, {
          subjectSerialNumber: this.subjectSerialNumber,
        })
        .then(() => {
          this.$noty.success("Guardado correctamente");
          this.loadCert();
          this.subjectSerialNumber = "";
        })
        .catch(() => {
          this.$noty.error("Error al guardar");
        });
    },
    removeCaf(id) {
      this.$http.delete(`sii/caf/${id}`).then(() => {
        this.$noty.success("Archivo borrado correctamente");
        this.loadCaf();
      });
    },
    saveCaf() {
      if (this.cafFile == null)
        return this.$noty.error("Debe adjuntar un archivo");
      const formData = new FormData();
      formData.append("caf", this.cafFile);

      this.$http
        .post("sii/caf/upload", formData)
        .then(() => {
          this.loadCaf();
          this.$noty.success("Informacion subida correctamente");
        })
        .catch((err) => {
          let message = "intente nuevamente";
          if (err.body && err.body.details) message = err.body.details;
          this.$noty.error("Error: " + message);
          this.file = null;
        });
    },
    saveCert() {
      if (this.certFile == null)
        return this.$noty.error("Debe adjuntar un archivo");
      const formData = new FormData();
      formData.append("cert", this.certFile);
      formData.append("password", this.certPassword);
      this.$http
        .post("sii/cert/upload", formData)
        .then(() => {
          this.loadCert();
          this.certFile = null;
          this.certPassword = "";
          this.$noty.success("Informacion subida correctamente");
        })
        .catch((err) => {
          let message = "intente nuevamente";
          if (err.body && err.body.details) message = err.body.details;
          this.$noty.error("Error: " + message);
          this.file = null;
        });
    },
    save() {
      this.$http
        .post("info", {
          name: this.name,
          address: this.address,
          rut: this.rut,
          phone: this.phone,
          sumupemail: this.sumupemail,
          sumuppassword: this.sumuppassword,
          sumupclientsecret: this.sumupclientsecret,
          sumupclientid: this.sumupclientid,
          sumupmid: this.sumupmid,
          sumupAffiliateKey: this.sumupAffiliateKey,
          sumupEnabled: this.sumupEnabled,
          deliveryEnabled: this.deliveryEnabled,
          otEnabled: this.otEnabled,
          tipEnabled: this.tipEnabled,
          logo: this.logo,
          mapTimeout: this.mapTimeout,
          commune: this.commune,
          city: this.city,
          beEnabled: this.beEnabled,
          siiResDate: this.siiResDate,
          siiResCode: this.siiResCode,
          siiAmbient: this.siiAmbient,
          giro: this.giro,
          siiAutoSend: this.siiAutoSend,
          siiSend: this.siiSend,
          custom_name: this.custom_name,
          sessionsEnabled: this.sessionsEnabled,
          payments: this.payments || "",
          webEnabled: this.webEnabled,
          custom_header: this.custom_header,
          custom_footer: this.custom_footer,
          custom_footer_ot: this.custom_footer_ot,
          printLogo: this.printLogo,
          remotePrintEnabled: this.remotePrintEnabled,
          openCashRequired: this.openCashRequired,
          siiActeco: this.siiActeco,
          location: this.location,
          payDay: this.payDay,
          subjectName: this.subjectName,
          subjectRut: this.subjectRut,
          timeFormat: this.timeFormat,
        })
        .then(() => {
          this.load();
          this.$noty.success("Informacion actualizada");
          this.$emit("update");
          if (this.file != "") {
            // .location.replace(document.location.origin);
          }
        })
        .catch(() => {
          this.$noty.error("Error al guardar la info");
        });
    },
    load() {
      this.loading = true;
      this.$http
        .get("info")
        .then((res) => {
          if (res.data.data) {
            this.loaded = true;
            this.$store.commit("setInfo", res.data.data);
            this.name = res.data.data.name;
            this.address = res.data.data.address;
            this.rut = res.data.data.rut;
            this.phone = res.data.data.phone;
            this.sumupemail = res.data.data.sumupemail;
            this.sumuppassword = res.data.data.sumuppassword;
            this.sumupclientsecret = res.data.data.sumupclientsecret;
            this.sumupclientid = res.data.data.sumupclientid;
            this.sumupmid = res.data.data.sumupmid;
            this.sumupAffiliateKey = res.data.data.sumupAffiliateKey;
            this.deliveryEnabled = res.data.data.deliveryEnabled;
            this.otEnabled = res.data.data.otEnabled;
            this.sumupEnabled = res.data.data.sumupEnabled;
            this.tipEnabled = res.data.data.tipEnabled;
            this.logo = res.data.data.logo;
            this.mapTimeout = res.data.data.mapTimeout;
            this.commune = res.data.data.commune;
            this.city = res.data.data.city;
            this.beEnabled = res.data.data.beEnabled;
            if (res.data.data.siiResDate)
              this.siiResDate = this.$moment(res.data.data.siiResDate).format(
                "YYYY-MM-DD"
              );
            this.siiResCode = res.data.data.siiResCode;
            this.siiAmbient = res.data.data.siiAmbient;
            this.giro = res.data.data.giro;
            this.siiAutoSend = res.data.data.siiAutoSend;
            this.siiSend = res.data.data.siiSend;
            this.payments = res.data.data.payments;
            this.custom_name = res.data.data.custom_name;
            this.sessionsEnabled = res.data.data.sessionsEnabled;
            this.sessions = res.data.data.sessions;
            this.webEnabled = res.data.data.webEnabled;
            this.custom_header = res.data.data.custom_header;
            this.custom_footer = res.data.data.custom_footer;
            this.custom_footer_ot = res.data.data.custom_footer_ot;
            this.printLogo = res.data.data.printLogo;
            this.remotePrintEnabled = res.data.data.remotePrintEnabled;
            this.openCashRequired = res.data.data.openCashRequired;
            this.siiActeco = res.data.data.siiActeco;
            this.location = res.data.data.location;
            this.payDay = res.data.data.payDay;
            this.subjectName = res.data.data.subjectName;
            this.subjectRut = res.data.data.subjectRut;
            this.timeFormat = res.data.data.timeFormat;
          }
        })
        .catch(() => {
          this.loaded = false;
          this.$noty.error("Error al cargar los datos");
        })
        .finally(() => {
          this.loading = false;
        });
      this.$http.get("storage").then((res) => {
        this.storages = res.data.data.map((s) => {
          return {
            _id: s._id,
            label: s.name,
          };
        });
      });

      this.$http.get("user").then((res) => {
        this.users = res.data.data.map((s) => {
          return {
            _id: s._id,
            label: `${s.name} - ${s.email}`,
          };
        });
      });
    },
    loadCaf() {
      this.$http.get("sii/caf").then((res) => (this.cafs = res.data.data));
    },
    loadCert() {
      this.$http.get("sii/cert").then((res) => (this.cert = res.data.data));
    },
  },
  mounted() {
    if (!this.hasPermission(this.user, "settings"))
      return this.$router.push("/");
    this.load();
    this.loadCert();
    this.loadCaf();

    if (this.isElectron()) {
      try {
        // const { webContents } = remote;
        // console.log(remote)
        // const content = webContents.getAllWebContents();
        // this.printers = content[0].getPrinters();
        // remote.ipcRenderer.send("getPrinters")

        ipcRenderer.invoke("getPrinters").then((arg) => {
          console.log("get printers", arg);
          this.printers = arg;
        });
      } catch (error) {
        console.log("error printers", error);
        this.$noty.error("Error al obtener las impresoras del sistema");
      }
    }
    storage.get("printer", (err, data) => {
      this.printer = data;
    });

    storage.get("printer2", (err, data) => {
      this.printer2 = data;
    });

    if (localStorage.fontSize) this.fontSize = parseInt(localStorage.fontSize);
    if (localStorage.fontSize2) this.fontSize2 = localStorage.fontSize2;
    if (localStorage.pageWidth) this.pageWidth = localStorage.pageWidth;
    if (localStorage.marginLeft) this.marginLeft = localStorage.marginLeft;
    if (localStorage.pageWidth2) this.pageWidth2 = localStorage.pageWidth2;
    if (localStorage.marginLeft2) this.marginLeft2 = localStorage.marginLeft2;
    if (localStorage.gui) this.gui = localStorage.gui;
    if (localStorage.hourPosition)
      this.hourPosition = localStorage.hourPosition;
    // if (localStorage.autoHideMenu)
    //   this.autoHideMenu = localStorage.autoHideMenu;
  },
  watch: {
    printer: function (val) {
      storage.set("printer", val);
    },
    printer2: function (val) {
      storage.set("printer2", val);
    },
    fontSize: function (val) {
      storage.set("fontSize", val);
      //localStorage.fontSize = val;
      localStorage.setItem("fontSize", val);
    },
    fontSize2: function (val) {
      storage.set("fontSize2", val);
      //localStorage.fontSize2 = val;
      localStorage.setItem("fontSize2", val);
    },
    pageWidth: function (val) {
      storage.set("pageWidth", val);
      //localStorage.pageWidth = val;
      localStorage.setItem("pageWidth", val);
    },
    marginLeft: function (val) {
      storage.set("marginLeft", val);
      //localStorage.marginLeft = val;
      localStorage.setItem("marginLeft", val);
    },
    pageWidth2: function (val) {
      storage.set("pageWidth2", val);
      //localStorage.pageWidth2 = val;
      localStorage.setItem("pageWidth2", val);
    },
    marginLeft2: function (val) {
      storage.set("marginLeft2", val);
      //localStorage.marginLeft2 = val;
      localStorage.setItem("marginLeft2", val);
    },
    autoHideMenu: function (val) {
      storage.set("autoHideMenu", val);
      //localStorage.autoHideMenu = val;
      localStorage.setItem("autoHideMenu", val);
    },
    hourPosition: function (val) {
      storage.set("hourPosition", val);
      //localStorage.hourPosition = val;
      localStorage.setItem("hourPosition", val);
    },
    gui: function (val) {
      storage.set("gui", val);
      //localStorage.gui = val;
      localStorage.setItem("gui", val);
    },
    file() {
      const formData = new FormData();
      formData.append("logo", this.file);
      this.$http
        .post("https://static.ticketapp.cl/upload", formData)
        .then((res) => {
          this.logo = res.data.file;
        })
        .catch((err) => {
          log(err);
          this.$noty.error("Error al subir el logo intente nuevamente");
          this.file = null;
        });
    },
  },
};
</script>

<style>
</style>
