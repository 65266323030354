<template>
  <div class="row align-items-end p-2">
    <div class="col-1 px-2">
      <button
        class="btn btn-sm btn-outline-info btn-block"
        @click="$emit('moveUp')"
      >
        <font-awesome-icon icon="arrow-up"></font-awesome-icon>
      </button>
      <button
        class="btn btn-sm btn-outline-info btn-block"
        @click="$emit('moveDown')"
      >
        <font-awesome-icon icon="arrow-down"></font-awesome-icon>
      </button>
    </div>
    <div class="col-11">
      <small>COMPONENTE</small>
      <div class="w-100"></div>
      <select
        class="form-control fs-3"
        v-model="c.component"
        :class="{
          'is-invalid': c.component == null,
          'is-valid': c.component != '',
        }"
      >
        <optgroup
          v-for="(group, i) in components"
          :key="`group-${i}`"
          :label="group.name"
        >
          <option
            v-for="(item, j) in group.items"
            :key="`item-${j}`"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="col">
      <small>ID REF</small>
      <div class="w-100"></div>
      <input type="text" class="form-control" v-model="c.ref" />
    </div>
    <div class="col"  v-if="!toState">
      <small>LINK AL HACER CLICK</small>
      <div class="w-100"></div>
      <input
        type="text"
        class="form-control"
        v-model="c.to"
       
        :disabled="toState"
      />
    </div>
    <div class="col" v-if="!srcState">
      <small>ORIGEN</small>
      <div class="w-100"></div>
      <input
        type="text"
        class="form-control"
        v-model="c.src"
      />
    </div>
    <div class="col">
      <small>CSS</small>
      <div class="w-100"></div>
      <input type="text" class="form-control" v-model="c.css" />
    </div>
    <div class="col">
      <small>PARAMS</small>
      <div class="w-100"></div>
      <input
        type="text"
        class="form-control"
        disabled1="paramsState"
        v-model="c.params"
      />
    </div>

    <div class="col-1">
      <small>QUITAR</small>
      <button
        class="btn btn-block btn-outline-danger"
        @click="emitRemove()"
      >
        <font-awesome-icon icon="trash"></font-awesome-icon>
      </button>
    </div>
    <div class="w-100"></div>
    <div class="col">
      <small>CONTENIDO</small>
      <div class="w-100"></div>
      <textarea
        v-model="c.content"
        class="form-control"
        cols="30"
        rows="2"
      ></textarea>
    </div>
    <div class="w-100 py-2"></div>
    <div class="col-12 bg-dark text-white">
      <div class="btn btn-secondary mx-3" @click="c.items.push({ items: [] })">
        AGREGAR COMPONENTE HIJO
      </div>

      <button
        class="btn btn-info"
        v-if="c.items.length > 0"
        @click="showChildren = !showChildren"
      >
        {{ showChildren ? "Ocultar" : "Mostrar" }} children
      </button>

      <div class="badge badge-info mx-2 px-2 py-1" v-if="c.items.length > 0">
        {{ c.items.length }} componentes
      </div>
    </div>

    <div class="w-100"></div>
    <div
      class="col-1 h-100 py-3"
      style="background: #cacaca"
      v-if="c.items.length > 0"
    ></div>

    <div
      class="col-11 ml-auto border border-left p-2"
      style="border-left: 5px solid #cacaca !important"
      v-show="showChildren"
      v-for="(c2, i) in c.items"
      :key="`subitem-${c._id}-${c2._id || i}`"
    >
      <componentBuilder
        :data="c2"
        :index="i"
        :parent="c"
        @moveUp="moveComponent(i, 'up')"
        @moveDown="moveComponent(i, 'down')"
        @remove="removeComponent(i, c.items)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "componentBuilder",
  props: ["data", "index"],
  computed: {
    toState() {
      return (
        this.c.component !== "btnlink" &&
        this.c.component !== "custom-image" &&
        this.c.component !== "redirect"
      );
    },
    srcState() {
      return (
        this.c.component !== "custom-image" &&
        this.c.component !== "embed-video"
      );
    },
    paramsState() {
      return this.c.component !== "single-product";
    },
  },
  data() {
    return {
      current: null,
      showChildren: true,
      c: {
        items: [],
      },
      components: [
        {
          name: "Diseño",
          items: [
            { value: "row", name: "Fila" },
            { value: "column", name: "Columna completa" },
            { value: "column6", name: "Columna 1/2" },
            { value: "column4", name: "Columnas 1/3" },
            { value: "column3", name: "Columna 1/4" },
          ],
        },
        //
        {
          name: "Texto",
          items: [
            { value: "h1", name: "Encabezado 1" },
            { value: "h2", name: "Encabezado 2" },
            { value: "h3", name: "Encabezado 3" },
            { value: "h4", name: "Encabezado 4" },
            { value: "h5", name: "Encabezado 5" },
            //
            { value: "span", name: "Texto" },
            { value: "small", name: "Texto pequeño" },
            { value: "p", name: "Parrafo" },
          ],
        },
        //
        {
          name: "Listas",
          items: [
            { value: "ul", name: "Lista" },
            { value: "li", name: "Item de lista" },
            { value: "ol", name: "Lista ordenada" },
          ],
        },
        //
        {
          name: "Media",
          items: [
            { value: "custom-image", name: "Imagen" },
            { value: "embed-video", name: "Video incrustado" },
            { value: "btnlink", name: "Botton Link" },
            { value: "redirect", name: "Redireccionar" },
          ],
        },
        //
        {
          name: "Componentes",
          items: [
            { value: "cart", name: "Carro / Pedido" },
            { value: "feedback", name: "Comentarios clientes" },
            { value: "single-product", name: "Producto" },
            { value: "category", name: "Categoria" },
            { value: "onlycategory", name: "Productos Categoria" },
            { value: "products-menu", name: "Procuctos Menu" },
            { value: "product-list", name: "Productos tienda" },

            { value: "delivery", name: "Delivery" },
            { value: "last-products", name: "Ultimos productos" },
            { value: "most-products", name: "Productos mas vendidos" },
            { value: "instagram-feed", name: "Feed de instagram" },
          ],
        },
      ],
    };
  },
  methods: {
    moveComponent(from, toIndex) {
      let array = this.c.items;
      let to = toIndex == "up" ? from - 1 : from + 1;
      const item = array[from];
      array.splice(from, 1);
      array.splice(to, 0, item);
      this.c.items = array;
      setTimeout(() => {
        this.$forceUpdate();
      }, 500);
    },
    removeComponent(index, arr) {
      arr.splice(index, 1);
    },
    emitRemove() {
      this.$emit("remove", this.index);
    },
  },
  mounted() {
    this.c = this.data;
  },
};
</script>