<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3>INGRESO MODO MULTIPLE</h3>
      </div>
    </div>

    <div class="w-100 py-4"></div>

    <ul class="list-group">
      <li class="list-group-item" v-for="expense in expenses" :key="expense._id">
        <div class="row align-items-end">
          <div class="col-3">
            <small class="text-muted">NOMBRE</small>
            <input type="text" class="form-control" v-model="expense.name" />
          </div>
          <div class="col-4">
            <small class="text-muted">DESCRIPCION</small>
            <input
              type="text"
              class="form-control"
              v-model="expense.description"
              placeholder="(Opcional)"
            />
          </div>
          
          <div class="col-2">
            <small class="text-muted">ESTADO</small>
            <select name="" id="" class="form-control">
              <option value="Por pagar">Por pagar</option>
              <option value="Pagado">Pagado</option>
            </select>
          </div>
          <div class="col-2">
            <small class="text-muted">TOTAL</small>
            <div class="input-group">
              <div class="input-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input
                type="text"
                class="form-control text-right"
                @focus="$event.target.select()"
                v-model.number="expense.value"
              />
            </div>
          </div>
          <div class="col">
            <button class="btn btn-block btn-outline-danger">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
          </div>
          <div class="col-12">
            <small class="text-muted">PRODUCTOS</small>
            <v-select
              v-model="expense.products"
              :options="getProducts"
              multiple
            ></v-select>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <button class="btn btn-block btn-outline-secondary" @click="add()">
          NUEVA LINEA
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expenses: [
        {
          name: "",
          description: "",
          total: 0,
          products: []
        },
      ],
    };
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
     getProducts() {
      if (!this.products) return [];
      return this.products.map((o) => ({
        ...o,
        label: `${o.code} - ${o.name}`,
      }));
    },
  },
  methods: {
    
    add() {
      this.expenses.push({
        name: "",
        description: "",
        total: 0,
        products: []
      });
    },
  },
};
</script>