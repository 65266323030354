<template>
  <div class="container">
    <div class="row">
      <div class="col-8">
        <h2>Informes</h2>
      </div>
      <div class="col-4">
        <div class="row no-gutters">
          <div class="col">
            <date-picker
              valueType="format"
              v-model="dateFilter"
              input-class="form-control pl-4"
            />
          </div>
          <div class="col">
            <button class="btn btn-outline-success btn-block" @click="load()">
              ACTUALIZAR
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <b-tabs fill nav-class="font-weight-bold text-uppercase">
          <b-tab title="Tickets"></b-tab>
          <b-tab title="Medios de pago"></b-tab>
          <b-tab title="Ordenes de trabajo"></b-tab>
          <b-tab :title="`Movimientos de inventario (${inventories.length})`" active>
            <div class="row">
              <div class="col-12">
                <div class="alert alert-info font-weight-bold mb-0">
                  Movimientos de productos con stock habilitado
                </div>
              </div>
              <div class="col-12">
                <ul class="list-group">
                  <li
                    class="list-group-item list-group-item-action"
                    :class="{
                      'list-group-item-danger': inv.deletedAt
                    }"
                    v-for="inv in inventories"
                    :key="inv._id"
                  >
                    <div class="row align-items-center">
                      <div class="col-2 text-center">
                        <h4
                          :class="{
                            'text-success': inv.type == 'in',
                          }"
                        >
                          {{ inv.qty }}
                        </h4>
                        <div class="w-100"></div>
                        <span class="badge badge-info" v-if="inv.type == 'out'">
                          SALIDA
                        </span>
                        <span
                          class="badge badge-success"
                          v-if="inv.type == 'in'"
                        >
                          ENTRADA
                        </span>
                      </div>
                      <div class="col">
                        <small class="font-weight-bold">CODIGO:</small>
                        <small>{{ inv.code }}</small>
                        <span class="mr-4"></span>
                        <small>{{
                          inv.createdAt | moment("DD/MM/YY HH:mm")
                        }}</small>
                        <div class="w-100"></div>
                        <span class="text-uppercase font-weight-bold">
                          {{ inv.name }}
                        </span>
                        <div class="w-100"></div>
                        <small class="text-uppercase">
                          DESDE:
                          {{ inv.storage ? inv.storage.name : "No bodega" }}
                        </small>
                        <div class="w-100"></div>
                        <small class="text-uppercase">
                          REF:
                          {{ inv.ticket ? `Ticket ${formatNumber(inv.ticket.number)}` : "" }}
                          {{ inv.ot ? `OT ${formatNumber(inv.ot.number)}` : "" }}
                        </small>
                      </div>
                      <div class="col-3 text-right">
                        <h4>${{ formatNumber(inv.value) }}</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.dateFilter = this.$moment().format("YYYY-MM-DD");
    this.load();
  },
  data() {
    return {
      dateFilter: null,
      inventories: [],
    };
  },
  methods: {
    load() {
      this.loadInventories();
    },
    loadInventories() {
      this.$http.get(`inventory?date=${this.dateFilter}`).then((res) => {
        this.inventories = res.data.data;
      });
    },
  },
};
</script>