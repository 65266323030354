<template>
  <div class="container-app container-fluid">
    <div class="row align-items-end mt-3">
      <div class="col">
        <h3>
          Servicios Impuestos Internos -
          <span class="text-uppercase">{{
            dateFilter | moment("MMMM YYYY")
          }}</span>
        </h3>
      </div>
      <div class="col-auto ml-auto">
        <router-link to="/sii/pdf">PDF</router-link>
      </div>
      <div class="col-auto" v-if="info.siiAmbient == 'CERT'">
        <router-link to="/sii/tool">TOOL</router-link>
      </div>

      <div class="w-100"></div>

      <div class="ml-auto col-2">
        <small>FECHA</small>
        <date-picker
          valueType="format"
          v-model="dateFilter"
          type="month"
          input-class="form-control pl-4"
        />
      </div>
      <div class="col-1">
        <button
          class="btn btn-outline-success btn-block d-none"
          v-b-modal.newcf
        >
          <font-awesome-icon icon="plus" />
        </button>
        <button class="btn btn-outline-secondary btn-block" @click="reload()">
          <font-awesome-icon icon="redo" />
        </button>
      </div>

      <div class="w-100 py-2"></div>

      <div class="col-12">
        <b-tabs
          nav-class="text-uppercase"
          pills
          content-class="py-5 mt-2 border bgwhite"
        >
          <b-tab title="Inicio">
            <sii-home :date="dateFilter" />
          </b-tab>
          <b-tab title="Compra" active>
            <sii-compras :date="dateFilter" ref="compras" />
          </b-tab>

          <b-tab title="Venta">
            <sii-ventas
              :date="dateFilter"
              ref="ventas"
              @showTicket="showTicket"
            />
          </b-tab>

          <b-tab title="Consumo de folios" v-if="false">
            <sii-cf :date="dateFilter" ref="cf" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import SiiHome from "./sii/home.vue";
import SiiVentas from "./sii/ventas.vue";
import SiiCompras from "./sii/compras.vue";
import SiiCf from "./sii/cf.vue";
export default {
  components: {
    SiiHome,
    SiiVentas,
    SiiCompras,
    SiiCf,
  },
  mounted() {
    if (!this.hasPermission(this.user, "sii"))
      return this.$router.push("/");
    this.dateFilter = this.$moment().format("YYYY-MM");
    //this.load();
    //this.loadCert();
  },
  data() {
    return {
      tickets: 0,
      cfs: [],
      cert: {},
      loading: true,
      dateFilter: null,
      folioStart: null,
      folioEnd: null,
      secuence: null,
      xml: "",
      year: 0,
    };
  },
  methods: {
    showTicket(ticket) {
      this.$emit("showTicket", ticket);
    },
    reload() {
      this.$refs["ventas"].load();
      this.$refs["compras"].load();
    },
    load() {},
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    getDate() {
      return `${this.year}-${this.m}-01`;
    },
    month() {
      return this.$moment().format("MMMM");
    },
  },
  watch: {
    dateFilter() {
      this.$router.push({ query: { date: this.dateFilter } });
      this.reload();
    },
  },
};
</script>