<template>
  <b-overlay :show="loading">
    <div class="row">
      <div class="col-12 py-4">
        <h3>ARCHIVOS {{ files.length }}</h3>
        <div class="row">
          <div class="col-10">
            <b-form-file
              v-model="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>
          <div class="col-2">
            <button @click="load" class="btn btn-primary btn-block">
              ACTUALIZAR
            </button>
          </div>
        </div>
        <div v-for="folder in folders" :key="folder._id">
          <div class="row mt-3 px-1">
            <div class="col-12">
              <h4>Carpeta: {{ folder._id }}</h4>
            </div>
            <div
              class="col-3 bgwhite border px-1"
              v-for="(file, index) in folder.files"
              :key="index"
            >
              <div class="row px-2 py-2 overflow-hidden h-100">
                <div class="col-12">
                  <div
                    class=""
                    :style="{
                      width: '100%',
                      height: '280px',
                      'background-image': `url(https://static.ticketapp.cl/uploads/meta/${file.small})`,
                      'background-size': 'contain',
                      'background-position': 'center',
                      'background-repeat': 'no-repeat',
                      'z-index': '0',
                    }"
                  ></div>
                </div>
                <div class="col">
                  <strong class="w-100 d-block text-wrap"
                    >{{ file.originalName }}
                  </strong>
                  <small
                    >{{ file.createdAt | moment("DD-MM-YYYY") }} -
                    {{ sizeInMb(file.size) }} MB !</small
                  >
                  <div class="w-100"></div>
                  <div class="row">
                    <div class="col-12 mb-2" v-if="!modal">
                      <small>CARPETA - {{ file.folder }}</small>
                      <select
                        v-model="file.folder"
                        @change="updateFile(file)"
                        v-if="!file.other"
                        class="form-control form-control-sm"
                      >
                        <option v-for="folder in folders" :key="folder._id">
                          {{ folder._id }}
                        </option>
                        <option v-if="other" :value="other">{{other}}</option>
                      </select>
                      <small class="click d-block" @click="file.other = true">Crear nueva carpeta</small>
                      <input
                        v-if="file.other"
                        v-model="file.folder"
                        @change="updateFile(file)"
                        class="form-control"
                        placeholder="Nueva carpeta"
                      />
                    </div>
                    <div class="col" v-if="!modal">
                      <button
                        @click="
                          copyClipboard(
                            `https://static.ticketapp.cl/uploads/meta/${file.name}`
                          )
                        "
                        class="btn btn-sm btn-primary btn-block"
                      >
                        COPIAR link
                      </button>
                    </div>
                    <div class="col-12" v-if="modal">
                      <button
                        @click="
                          selected(
                            `https://static.ticketapp.cl/uploads/meta/${file.name}`
                          )
                        "
                        class="btn btn-sm btn-primary btn-block"
                      >
                        USAR
                      </button>
                    </div>
                    <div class="col-6" v-if="!modal">
                      <b-button
                        block
                        variant="danger"
                        size="sm"
                        @click="remove(file)"
                        >Delete</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      other: "",
      files: [],
      file: [],
      loading: true,
      folders: []
    };
  },
  methods: {
    updateFile(file) {
      this.$http.put(`files/${file._id}`, file).then(() => {
        this.load();
      });
    },
    sizeInMb(size) {
      return (size / 1024 / 1024).toFixed(2);
    },
    selected(link) {
      this.$emit("selected", link);
    },
    copyClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$noty.success("Link copied to clipboard");
      });
    },
    load() {
      this.loading = true;
      this.$http
        .get("files")
        .then((response) => {
          this.folders = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(file) {
      const confirm = window.confirm("Are you sure?");
      if (!confirm) return;
      this.$http.delete(`files/${file._id}`).then(() => {
        this.load();
      });
    },
  },
  mounted() {
    this.load();
  },
  watch: {
    file: {
      async handler() {
        let formData = new FormData();
        if (!this.file) return;
        formData.append("image", this.file);

        try {
          this.loading = true;
          let response = await this.$http.post(
            "https://static.ticketapp.cl/auth/facebook/upload_image",
            formData
          );
          //this.instagramLink = `https://dev.rowen.ticketapp.cl/uploads/meta/${response.data.data.url}`;
          this.load();
        } catch (error) {
          console.log({ error, res: error.data });
          this.loading = false;
          if (error.data && error.data.details) {
            this.$noty.error(error.data.details);
          } else {
            this.$noty.error("Error al subir imagen");
          }
        }
      },
      deep: true,
    },
  },
};
</script>