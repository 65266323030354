<template>
  <div style="text-align: center; margin: 15px 0px">
    <img
      :src="src"
      width="90%"
      style="
        max-width: 300px;
        margin: 0 auto;
        max-height: 90px;
        object-fit: contain;
      "
    />
  </div>
</template>

<script>
export default {
  props: ["src"],
};
</script>