<template>
  <div>
    <table
      class="products"
      style="margin-top: 20px; width: 100%; font-family: sans-serif"
    >
      <tbody>
        <tr
          v-for="(product, i) in ticket.products"
          :key="`product-${product._id}-${i}`"
        >
          <td colspan="2">
            <div style="font-size: 100%; margin-bottom: 0px">
              <strong style="width: 68%; display: inline-block">
                {{ product.qty || 1 }}
                <span v-if="product.unit != 'Unid'">{{ product.unit }} </span>-
                {{ product.name | uppercase }}
              </strong>
              <strong
                v-if="!hideValues"
                style="
                  width: 30.5%;
                  display: inline-block;
                  float: right;
                  text-align: right;
                "
              >
                ${{ formatNumber(product.total) }}
              </strong>
            </div>
            <small style="display: inline-block; margin-right: 5px">
              <strong>COD:</strong> {{ product.code }}
            </small>
            <small style="display: none; margin-right: 5px">
              <strong>CANT:</strong> {{ product.qty }}
            </small>
            <small
              style="display: inline-block; margin-right: 5px"
              v-if="!hideValues"
            >
              <strong>VALOR</strong>: ${{ product.value | formatNumber }}
            </small>
            <small
              style="display: inline-block; margin-right: 5px"
              v-if="product.discount > 0 && !hideValues"
            >
              <strong>DESCUENTO</strong>: {{ product.discount | fixed }}% (-${{
                formatNumber(
                  ((product.value * product.qty) / 100) * product.discount
                )
              }})
            </small>
            <span
              v-for="(option, i) in product.options"
              :key="i"
              style="display: block; margin-bottom: 5px"
              :style="{
                'font-size': `80%`,
                color: getSelectedPrint(option) ? 'white' : '',
                background: getSelectedPrint(option) ? 'black' : '',
              }"
            >
              <span v-if="option.label">{{ option.label }}: </span>
              <span
                style="font-weight: bold; white-space: pre-linep"
                v-if="!option.ref"
                >{{ getSelected(option.selected) }}</span
              >
              <span
                style="font-weight: bold; white-space: pre-line"
                v-else-if="option.values && option.values.length > 0"
                >{{
                  option.values
                    ? option.values
                        .map((o) => {
                          if (o.value) return `${o.name} ($${o.value})`;
                          else return `${o.name}`;
                        })
                        .join(" ")
                        .replace("undefined", "- - - -")
                    : ""
                }}</span
              >
              <span
                style="font-weight: bold; white-space: pre-line; color: "
                v-else
                >{{ option.selected ? option.selected : "- - - - -" }}
                {{
                  option.selected && option.selected.value
                    ? `$${option.selected.value}`
                    : ""
                }}</span
              >
            </span>
            <strong>
              <pre
                v-if="product.details"
                style="
                  padding: 0px 5px;
                  margin-top: 2px;
                  white-space: pre-line;
                  font-family: sans-serif;
                "
              >
- {{ product.details }}</pre
              >
            </strong>
          </td>
        </tr>
      </tbody>
      <tfoot style="text-align: right" v-if="false">
        <tr>
          <th colspan="1">SUB TOTAL</th>
          <th>${{ formatNumber(ticket.sub_total) }}</th>
        </tr>
        <tr v-if="ticket.discount">
          <th colspan="1">DESCUENTO</th>
          <th>-${{ formatNumber(ticket.discount) }}</th>
        </tr>
        <tr v-if="ticket.neto">
          <th colspan="1">NETO</th>
          <th>${{ formatNumber(ticket.neto) }}</th>
        </tr>
        <tr v-if="ticket.exento">
          <th colspan="1">EXENTO</th>
          <th>${{ formatNumber(ticket.exento) }}</th>
        </tr>
        <tr v-if="ticket.iva">
          <th colspan="1">IVA (19%)</th>
          <th>${{ formatNumber(ticket.iva) }}</th>
        </tr>
        <tr>
          <th colspan="1">Total</th>
          <th>${{ formatNumber(ticket.total) }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  //props: ["ticket", "fontSize", "showValues"],
  props: {
    ticket: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fontSize: {
      type: Number,
      default: 10,
    },
    hideValues: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getSelected(option) {
      console.log({ option });
      //option.selected ? option.selected : "- - - - -"
      if (option) {
        if (option.value) return `${option.selected} ($${option.value})`;
        else return `${option.selected}`;
      } else return "- - - - -";
    },
    getSelectedPrint(option) {
      if (!option) return "no";
      if (!option.values) return "no values";
      console.log({option})

      if(option.printStrong) return option.printStrong;

      const find = option.values.find((o) => o.printStrong);
      if (find) return find.printStrong;
    },
  },
};
</script>