const state = {
  user: {
    role: null,
    permissions: []
  },
  logged: false,
  showMenu: true,
  token: '',
  connected: true,
  info: localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : {name: ''},
  products: [],
  tickets: [],
  ticket: {},
  OT: {},
  status: 'offline',
  featured: [],
  pending_tickets: [],
  copyTicket: {},
  cafs: [],
  folio: 0,
  storage: null,
  notifications: [],
  webTickets: [],
  logo: '',
  ots: [],
  printQueue: [],
  webTicket: {
    createdAt: null
  },
  redirect: '',
  options: [],
  loading: false,
  webSettings: {},
  storages: [],
  default_storage: null, 
  invoices: [],
  fb_chats: [],
  ig_chats: [],
  businesses: [],
  categories: [],
  printQueueAll: [],
  tables: [],
  tablesPending: [],
  lastTicket: {
    id: null,
    table: null,
    createdAt: null,
    updatedAt: null,
    products: []
  },
  users: [],
  tablesConfig: {
    
  },
  clients: []
}


const mutations = {
  setClients(state, clients) {
    state.clients = clients
  },
  setTablesConfig(state, config) {
    state.tablesConfig = config
  },
  setUsers(state, users) {
    state.users = users
  },
  setLastTicket(state, ticket) {
    state.lastTicket = ticket
  },
  setTablesPending(state, tables) {
    state.tablesPending = tables
  },
  setTables(state, tables) {
    state.tables = tables
  },
  setPrintQueueAll(state, queue) {
    state.printQueueAll = queue
  },
  setCategories(state, categories) {
    state.categories = categories
  },
  setBusinesses(state, businesses) {
    state.businesses = businesses
  },
  setFbChats(state, chats) {
    state.fb_chats = chats
  },
  setIgChats(state, chats) {
    state.ig_chats = chats
  },
  setWebTickets(state, newWebTickets) {
    state.webTickets = newWebTickets
  },
  setUser(state, newUser) {
    state.user = newUser
  },
  login(state, status) {
    state.logged = status
  },
  toggleMenu(state) {
    state.showMenu = !state.showMenu
  },
  token(state, newToken) {
    state.token = newToken
  },
  hideMenu(state) {
    state.showMenu = false
  },
  setInfo(state, info) {
    state.info = info
    localStorage.setItem('info', JSON.stringify(info))
  },
  setProducts(state, products) {
    state.products = products
  },
  setTickets(state, tickets) {
    state.tickets = tickets
  },
  setTicket(state, ticket) {
    state.ticket = ticket
  },
  setStatus(state, status) {
    state.status = status
    //router.app.$emit('update')
  },
  setFeatured(state, products) {
    state.featured = products
    // router.app.$emit('update')
  },
  setPending(state, tickets) {
    state.pending_tickets = tickets
  },
  setCopyTicket(state, ticket) {
    state.copyTicket = ticket
  },
  setCaf(state, cafs) {
    state.cafs = cafs
  },
  setFolio(state, folio) {
    state.folio = folio
  },
  setStorage(state, storage) {
    state.storage = storage
  },
  setLogo(state, logo) {
    state.logo = logo
  },
  setOT(state, OT) {
    state.OT = OT
  },
  setOts(state, ots) {
    state.ots = ots
  },
  setNotifications(state, data) {
    state.notifications = data
  },
  setPrintQueue(state, queue) {
    state.printQueue = queue
  },
  addPrintQueue(state, ticket) {
    state.printQueue.push(ticket)
  },
  setWebTicket(state, ticket) {
    state.webTicket = ticket
  },
  setRedirect(state, url) {
    state.redirect = url
  },
  setOptions(state, options) {
    state.options = options
  },
  setLoading(state, status) {
    state.loading = status
  },
  setWebSettings(state, websettings) {
    state.webSettings = websettings
  },
  setStorages(state, storages) {
    state.storages = storages
  },
  setDefaultStorage(state, storage) {
    state.default_storage = storage
  },
  setInvoices(state, invoices) {
    state.invoices = invoices
  }
}


module.exports = { state, mutations }