import { render, staticRenderFns } from "./multiple.vue?vue&type=template&id=13e44b22&"
import script from "./multiple.vue?vue&type=script&lang=js&"
export * from "./multiple.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports