<template>
  <div class="container-fluid py-2">
    <div class="row">
      <div class="col-12 text-center" v-if="pages.length == 0">
        <h4 class="py-4">No tienes paginas</h4>
      </div>
    </div>
    <div class="row py-2">
      <div class="col-2 ml-auto d-none">
        <button
          class="btn btn-outline-primary btn-block"
          @click="showAddPageModal()"
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          ARCHIVOS
        </button>
      </div>
      <div class="col-lg-2 col-5 ml-auto">
        <button
          class="btn btn-outline-primary btn-block"
          @click="showAddPageModal()"
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          CREAR
        </button>
      </div>
    </div>
    <ul class="list-group">
      <li
        class="list-group-item click list-group-item-action"
        v-for="page in pages"
        :key="page._id"
      >
        <div class="row align-items-center">
          <div class="col-lg-1 col-4 text-center">
            <small class="d-block">ORDEN</small>
            {{ page.order }}
          </div>
          <div class="col">
            <small class="d-block">TITULO</small>
            <span class="text-uppercase font-weight-bold">
              {{ page.title }}
            </span>
          </div>
          <div class="col-12 col-md-4">
            <small class="d-block">LINK/SLUG</small>
            <span class="">/{{ page.slug }} </span>
          </div>
          <div class="col-6 col-md-2">
            <small class="d-block">CREADA</small>
            <small class="text-uppercase font-weight-bold">
              {{ page.createdAt | moment("DD/MM/YY") }}
            </small>
          </div>
          <div class="col-4 col-md-2">
            <small class="d-block">PUBLICADA</small>
            <b-form-checkbox
              value="public"
              unchecked-value="draft"
              switch
              v-model="page.status"
              @change="changeStatusPage(page)"
            />
          </div>
          <div class="col-lg-1 col-12">
            <button
              class="btn btn-outline-primary btn-block"
              @click="editPage(page)"
            >
              <font-awesome-icon icon="pen" />
            </button>
          </div>
        </div>
      </li>
    </ul>

    <b-modal
      ref="modalEditPage"
      size="xxl"
      title="Editar pagina"
      scrollable
      content-class="modal-full"
      dialog-class="modal-full"
      @ok="updatePage()"
    >
      <div class="row align-items-top">
        <div class="col">
          <small>TITULO</small>
          <div class="input-group">
            <input
              type="text"
              v-model="page.title"
              class="form-control"
              placeholder="ej: Galeria"
            />
          </div>
        </div>
        <div class="col-4">
          <small>URL (Ej: menu)</small>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">/</div>
            </div>
            <input
              type="text"
              v-model="page.slug"
              class="form-control"
              placeholder="ej: galeria"
            />
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-3">
          <small>MOSTRAR TITULO</small>
          <b-form-checkbox
            v-model="page.showTitle"
            size="lg"
            name="check-button"
            switch
          ></b-form-checkbox>
        </div>
        <div class="col-3">
          <small>PUBLICADA </small>

          <!-- <select class="form-control" v-model="page.status">
            <option value="draft">Borrador</option>
            <option value="public">Publicada</option>
          </select> -->
          <b-form-checkbox
            value="public"
            unchecked-value="draft"
            switch
            size="lg"
            v-model="page.status"
          />
        </div>
        <div class="col-3">
          <small>CONTENEDOR</small>
          <b-form-checkbox
            value="true"
            unchecked-value="false"
            switch
            size="lg"
            v-model="page.container"
          />
        </div>
        <div class="col-3">
          <small>SLIDER</small>
          <b-form-checkbox
            value="true"
            unchecked-value="false"
            switch
            size="lg"
            v-model="page.slider"
          />
        </div>
        <div class="col-3">
          <small>Icono </small>

          <select class="form-control" v-model="page.icon">
            <option :value="null">Ninguno</option>
            <option value="house">Inicio</option>
            <option value="cart">Carro</option>
            <option value="list">Lista</option>
            <option value="card-list">Lista 2</option>
            <option value="box">Caja</option>
            <option value="box-seam">Caja 2</option>
          </select>
        </div>
        <div class="col">
          <small>Orden </small>

          <input
            type="text"
            v-model="page.order"
            class="form-control"
            @focus="$event.target.select()"
          />
        </div>

        <div class="w-100"></div>
        <div class="col">
          <small>CONTENIDO</small>
          <b-tabs  content-class="py-2">
            <b-tab title="Editor texto" v-if="false">
              <vue-editor
                v-model="page.content"
                useCustomImageHandler
                image-added="addImage"
                :editorToolbar="customToolbar"
              ></vue-editor>
            </b-tab>
            <b-tab active title="Componentes">
              <div class="row">
                <div class="col">
                  <div class="sticky-top">
                    <div
                      class="btn btn-secondary"
                      @click="page.components.unshift({ items: [] })"
                    >
                      AGREGAR COMPONENTE AL INICIO
                    </div>
                    <div
                      class="btn btn-secondary"
                      @click="page.components.push({ items: [] })"
                    >
                      AGREGAR COMPONENTE AL FINAL
                    </div>
                  </div>
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(c, i) in page.components"
                      :key="`component-${c._id}-${i}`"
                    >
                      <component-builder
                        :data="c"
                        :index="i"
                       
                        @moveUp="moveComponent(i, 'up')"
                        @moveDown="moveComponent(i, 'down')"
                        @remove="removeComponent(i)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="HTML (Avanzado)" v-if="false">
              <div class="btn-group btn-block">
                <button @click="html = page.content" class="btn btn-primary">
                  Cargar HTML
                </button>
                <button @click="setContent()" class="btn btn-success">
                  Enviar al editor
                </button>
              </div>
              <textarea
                class="form-control"
                v-model="html"
                rows="30"
              ></textarea>
            </b-tab>
            <b-tab title="Sliders" v-show="page.slider">
              <div class="row">
                <div class="col">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <button class="btn w-50" @click="addSlider()">
                        AGREGAR
                      </button>
                      <button class="btn w-50" @click="showModalUploadImage()">
                        SUBIR IMAGEN
                      </button>
                    </li>
                    <li
                      class="list-group-item"
                      v-for="(slider, i) in page.sliders"
                      :key="`slider-${i}`"
                    >
                      <div class="row">
                        <div class="col-3">
                          <img :src="slider.src" alt="" class="img-fluid" />
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <small>TITULO</small>
                              <input
                                type="text"
                                class="form-control"
                                v-model="slider.caption"
                              />
                            </div>
                            <div class="col">
                              <small>TEXTO</small>
                              <input
                                type="text"
                                class="form-control"
                                v-model="slider.text"
                              />
                            </div>
                            <div class="w-100"></div>
                            <div class="col">
                              <small>ORIGEN DE IMAGEN</small>
                              <input
                                type="text"
                                class="form-control"
                                v-model="slider.src"
                              />
                            </div>
                            <div class="col">
                              <small>AL HACER CLICK</small>
                              <input
                                type="text"
                                class="form-control"
                                v-model="slider.to"
                              />
                            </div>
                            <div class="col">
                              <small>ORDEN</small>
                              <input
                                type="text"
                                class="form-control"
                                v-model.number="slider.order"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-1">
                          <button
                            class="btn btn-danger"
                            @click="removeSlider(i)"
                          >
                            <font-awesome-icon icon="trash" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="col-12 py-2">
          <button class="btn btn-block btn-success" @click="updatePage()">
            GUARDAR
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalPage"
      id="modalViewTicket"
      size="xl"
      title="Agregar pagina"
      @ok="savePage()"
    >
      <div class="row">
        <div class="col">
          <small>TITULO</small>
          <input
            type="text"
            v-model="page.title"
            class="form-control"
            placeholder="ej: Galeria"
            @blur="checkSlug()"
          />
        </div>
        <div class="col">
          <small>URL (Ej: menu)</small>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">/</div>
            </div>
            <input
              type="text"
              v-model="page.slug"
              class="form-control"
              placeholder="ej: galeria"
            />
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modalImageUpload"
      ref="modalImageUpload"
      size="xl"
      title="Subir imagen"
      @ok="uploadImage()"
    >
      <div class="row">
        <div class="col-12">
          <label for="">Archivo</label>
        </div>
        <div class="col">
          <b-form-file
            v-model="file"
            placeholder="Selecciona un archivo o arrastralo hasta aca..."
            drop-placeholder="Arrastra hasta aca..."
            accept=".png, .jpg, .jpeg"
          ></b-form-file>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import componentBuilder from "../../components/componentBuilder";
export default {
  components: {
    VueEditor,
    componentBuilder,
  },
  mounted() {
    this.loadPages();
  },
  data() {
    return {
      current: null,
      pages: [],
      customToolbar: [],
      html: "",
      page: {
        title: "",
        slug: "",
        content: "",
      },
      file: null,
    };
  },
  methods: {
    moveComponent(from, toIndex) {
      let array = this.page.components;
      let to = toIndex == "up" ? from - 1 : from + 1;
      const item = array[from];
      array.splice(from, 1);
      array.splice(to, 0, item);
      this.page.components = array;
      setTimeout(() => {
        this.$forceUpdate();
      }, 500);
    },
    removeComponent(index) {
      this.page.components.splice(index, 1);
    },
    removeSlider(index) {
      this.page.sliders.splice(index, 1);
    },
    showModalUploadImage() {
      this.$refs["modalImageUpload"].show();
    },
    addSlider() {
      this.page.sliders.push({
        src: "",
        order: 0,
      });
    },
    changeStatusPage(page) {
      this.page = page;
      this.updatePage();
      this.$noty.info("Cambiando estado de la pagina");
    },
    setContent() {
      this.page.content = this.html;
    },
    uploadImage() {
      const formData = new FormData();
      formData.append("image", this.file);
      this.$http
        .post("https://static.ticketapp.cl/web/upload_image", formData)
        .then((res) => {
          console.log("uploadImage", res);
          let url = `https://static3.ticketapp.cl/uploads/web/${res.data.data.url}`;
          navigator.clipboard.writeText(url);
          this.$noty.success(
            "Imagen subida correctamente, copiado al portapapeles"
          );
        })
        .catch((err) => {
          let message = "intente nuevamente";
          if (err.body && err.body.details) message = err.body.details;
          this.$noty.error("Error: " + message);
        });
    },
    // addImage(file, Editor, cursorLocation, resetUploader) {
    //   const formData = new FormData();
    //   formData.append("image", file);

    //   this.$http
    //     .post("http://67.205.178.111:3000/web/upload_image", formData)
    //     .then((res) => {
    //       Editor.insertEmbed(cursorLocation, "image", res.data.data.url);
    //       resetUploader();
    //       this.$noty.success("Imagen subida correctamente");
    //     })
    //     .catch((err) => {
    //       let message = "intente nuevamente";
    //       if (err.body && err.body.details) message = err.body.details;
    //       this.$noty.error("Error: " + message);
    //     });
    // },

    updatePage() {
      this.$http
        .patch(`web/page/${this.page._id}`, this.page)
        .then((res) => {
          this.loadPages();
          this.page = res.data.data;
        })
        .catch(() => {
          this.$noty.error("Error al editar la pagina");
        });
    },
    editPage(page) {
      this.page = page;
      // this.html = page.content;
      this.$refs.modalEditPage.show();
    },
    showAddPageModal() {
      this.page = {
        title: "",
        slug: "",
        content: "",
      };
      this.$refs.modalPage.show();
    },
    savePage() {
      this.$http
        .post("web/page", this.page)
        .then(() => {
          this.loadPages();
          this.$noty.success("Pagina agregada");
        })
        .catch(() => {
          this.$noty.error("Error al agregar");
        });
    },
    loadPages() {
      this.page = {
        title: "",
        slug: "",
        content: "",
      };
      this.$http
        .get("web/page")
        .then((res) => {
          this.pages = res.data.data;
        })
        .catch(() => {
          this.$noty.error("Error al cargar las paginas");
        });
    },
  },
};
</script>