<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-4">
        <h3>Notificaciones</h3>
      </div>
      <div class="ml-auto col-3 btn-group">
        <button class="btn btn-secondary btn-sm" @click="load()">
          ACTUALIZAR
        </button>
        <button
          class="btn btn-primary btn-sm"
          @click="$refs.sendnotification.show()"
        >
          ENVIAR
        </button>
      </div>
    </div>
    <div class="w-100"></div>

    <div class="col-12">
      <ul class="list-group">
        <li class="list-group-item" v-for="n in notifications" :key="n._id">
          <div class="row align-items-center">
            <div class="col-1 text-center">
              <small>{{ n.createdAt | moment("DD/MM/HH") }}</small>
              <div class="w-100"></div>
              <small>{{ n.createdAt | moment("HH:mm") }}</small>
            </div>
            <div class="col">
              <small class="text-muted">{{
                n.business ? n.business.name : "----"
              }}</small>
              <div class="w-100"></div>
              <span
                class="badge mr-2 badge-pill font-weight-bold"
                :class="{
                  'badge-secondary': n.priority == 0,
                  'badge-info': n.priority == 1,
                  'badge-warning': n.priority == 2,
                  'badge-danger': n.priority == 3,
                }"
                >!</span
              >
              <span class="text-uppercase font-weight-bold">{{ n.title }}</span>
              <div class="w-100"></div>
              <small>{{ n.content }}</small>
              <hr />
              <router-link
                class="btn-link btn-sm"
                :to="a.action"
                v-for="(a, i) in n.buttons"
                :key="i"
                >{{ a.title }} ({{ a.action }})</router-link
              >
            </div>

            <div class="col-1 text-center">
              <small class="text-muted">VISTO</small>
              <div class="w-100"></div>
              {{ n.users.length }}
            </div>

            <div class="col-1">
              <button>x</button>
            </div>
            <div class="w-100 py-2"></div>
            <div class="col-11 ml-auto">
              <div class="badge badge-light mx-3" v-for="user in n.users" :key="user._id">
                <div class="row">
                  <div class="col-auto" style="font-size: 1.3em">
                    <small class="mx-3">{{
                      user.viewAt | moment("DD/MM/YY HH:mm")
                    }}</small>
                    <small>{{ user.user.name }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <b-modal
      ref="sendnotification"
      size="lg"
      title="Enviar notificacion"
      @ok="sendNoty()"
    >
      <div class="row">
        <div class="col-6">
          <small>TITULO</small>
          <input type="text" v-model="noty.title" class="form-control" />

          <small>CONTENIDO</small>
          <b-form-textarea
            id="textarea"
            v-model="noty.content"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>

          <small>PRIORIDAD</small>
          <select class="form-control" v-model="noty.priority">
            <option value="0">Baja</option>
            <option value="1">Normal</option>
            <option value="2">Media</option>
            <option value="3">Alta</option>
          </select>

          <div class="row">
            <div class="col-6">
              <small>BUTTONS</small>
            </div>
            <div class="col-6">
              <button class="btn btn-link btn-xs" @click="addButton()">
                AGREGAR
              </button>
            </div>
          </div>

          <div class="row" v-for="(b, i) in noty.buttons" :key="i">
            <div class="col">
              <small>ACTION TITLE</small>
              <input type="text" v-model="b.title" class="form-control" />
            </div>

            <div class="col">
              <small>ACTION LINK</small>
              <input type="text" v-model="b.action" class="form-control" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div style="max-height: 50vh; overflow-y: scroll">
            <b-form-group>
              <template #label>
                <small>DESITNO</small><br />
                <b-form-checkbox
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  aria-describedby="flavours"
                  aria-controls="flavours"
                  @change="toggleAll"
                >
                  {{ allSelected ? "Un-select All" : "Select All" }}
                </b-form-checkbox>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="flavors"
                  v-model="selected"
                  :options="business"
                  size="sm"
                  value-field="_id"
                  text-field="name"
                  :aria-describedby="ariaDescribedby"
                  class="ml-4"
                  aria-label="Individual flavours"
                  stacked
                >
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifications: [],
      noty: {
        title: "",
        content: "",
        priority: 0,
        buttons: [],
      },
      business: [],
      selected: [],
      allSelected: false,
      indeterminate: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    sendNoty() {
      this.$http
        .post("notification", { ...this.noty, selected: this.selected })
        .then(() => {
          // this.$noty.success("Notificacion enviada");
          this.noty = {
            title: "",
            content: "",
            priority: 0,
            actions: [],
            selected: [],
          };
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al enviar");
        });
    },
    toggleAll(checked) {
      this.selected = checked ? this.business.map((b) => b._id) : [];
      console.log(this.selected);
    },
    addButton() {
      this.noty.buttons.push({
        title: "",
        action: "",
      });
    },
    load() {
      this.$http.get("business").then((res) => {
        this.business = res.data.data;
      });
      this.$http.get("manage_notification").then((res) => {
        this.notifications = res.data.data;
      });
    },
  },
  watch: {
    selected(newValue) {
      console.log(newValue);
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.business.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
};
</script>