<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Raffle</h1>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Raffle</h5>
                <p class="card-text">Raffle</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      raffles: [],
      raffle: {},
      loading: false,
    }
  }
}
</script>