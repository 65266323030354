<template>
  <div style="font-family: sans-serif">
    <table
        style="width: 100%; margin-top: 20px"
        v-if="ticket.payments && ticket.payments.length > 0"
      >
        <tfoot
          style="text-align: left"
        >
          <tr>
            <th>
              <small>MEDIO DE PAGO</small>
            </th>
            <th style="text-align: right">
              <small>MONTO</small>
            </th>
            <th style="text-align: right">
              <small>VUELTO</small>
            </th>
          </tr>
          <tr v-for="(p, i) in ticket.payments" :key="i">
            <th>
              <span>{{ p.name | uppercase }}</span>
            </th>
            <th style="text-align: right">
              ${{ formatNumber(p.amount) }} 
            </th>
            <th style="text-align: right">
              ${{
                formatNumber(p.amount - p.total)
              }}
            </th>
          </tr>
        </tfoot>
      </table>
  </div>
</template>

<script>
export default {
  props: ["ticket", "fontSize"],
};
</script>