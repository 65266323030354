 <template>
  <div class="container-fluid container-app">
    <div class="row py-0">
      <div class="col-12">
        <div class="row align-items-end">
          <!-- <div class="col-4 d-none">
            <button
              class="btn btn-outline-primary btn-block btn-lg"
              @click="connectUberEats()"
            >
              CONECTAR UBEREATS
            </button>
          </div> -->

          <div class="col-4 d-none">
            <button
              class="btn btn-outline-primary btn-block btn-lg"
              @click="connectInstagram()"
            >
              CONECTAR INSTAGRAM
            </button>
          </div>
          <div class="col">
            <small>Seleccione una pagina</small>

            <select class="form-control" v-model="selectedPage">
              <option value="">Seleccione una pagina</option>
              <option
                v-for="account in accounts"
                :value="account.fb_id"
                :key="account._id"
              >
                {{ account.name }}
              </option>
            </select>
          </div>
          <div class="col-3">
            <button
              class="btn btn-outline-primary btn-block btn-lg"
              @click="connectFacebook()"
            >
              CONECTAR
            </button>
          </div>
          <div class="col-2">
            <button
              @click="loadPage"
              class="btn btn-outline-primary btn-block btn-lg"
            >
              ACTUALIZAR
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-6 mt-2" v-if="user && false">
              <div class="row align-items-center">
                <div class="col-2" v-if="user.picture">
                  <img
                    :src="user.picture.data.url"
                    :alt="user.name"
                    class="img-fluid w-100"
                  />
                </div>
                <div class="col">
                  <h3>{{ user.name }}</h3>
                  <div class="w-100"></div>
                  <small>{{ user.email }}</small>
                </div>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="ml-auto col-5">
              <div class="row justify-content-center">
                <div class="col-12 mt-0">
                  <ul class="list-group" v-if="false">
                    <li
                      class="list-group-item list-group-item-action click"
                      v-for="account in accounts"
                      :key="account.id"
                      :class="{
                        active: account.id === page.id,
                      }"
                      :id="account.id"
                      @click="loadPage(account.id, account.access_token)"
                    >
                      <div class="row align-items-center">
                        <div class="col-1" v-if="account.picture">
                          <img
                            :src="account.picture.data.url"
                            :alt="account.name"
                            class="img-fluid w-100"
                          />
                        </div>
                        <div class="col">
                          <small>{{ account.id }}</small>
                          <div class="w-100"></div>
                          <strong>{{ account.name }}</strong>
                          <div class="w-100"></div>
                          {{ account.category }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="w-100 my-2"></div>
            <div class="col-12">
              <b-tabs
                v-if="page.id"
                justified
                content-class="mt-2"
                active-nav-item-class="font-weight-bold"
              >
                <b-tab title="Bandeja de entrada">
                  <b-tabs
                    pills
                    content-class="mt-2"
                    active-nav-item-class="bg-secondary"
                  >
                    <b-tab title="Facebook">
                      <messenger
                        platform="messenger"
                        :pagedata="page"
                        :page="page.id"
                        :visible="visible"
                      />
                    </b-tab>
                    <b-tab title="Instagram">
                      <messenger
                        platform="instagram"
                        :pagedata="page"
                        :page="page.id"
                        :visible="visible"
                      />
                    </b-tab>
                  </b-tabs>
                </b-tab>
                <b-tab title="Facebook" :disabled="!page.id">
                  <div class="col-12">
                    <div class="mt-2">
                      <div class="list-group-item">
                        <div class="row text-white align-items-center">
                          <div
                            class="col-12"
                            v-if="page && page.cover"
                            style="margin-bottom: -180px"
                          >
                            <div
                              class="img-cover"
                              :style="{
                                // `background-size: cover;filter: blur(1px); position: relative; height: 350px; overflow: hidden;background: linear-gradient(0deg, black 20%, transparent),url('${page.cover.source}');background-position: ${page.cover.offset_x || 50}% ${page.cover.offset_y || 50}%`
                                height: '350px',
                                backgroundSize: 'cover',
                                background: `linear-gradient(0deg, black 20%, transparent),url('${page.cover.source}')`,
                                backgroundPosition: `${
                                  page.cover.offset_x || 50
                                }% ${page.cover.offset_y || 50}%`,
                              }"
                            ></div>
                          </div>
                          <div class="col-2 mt-2 text-right">
                            <div class="img">
                              <img
                                :src="page.picture.data.url"
                                :alt="page.name"
                                v-if="page.picture"
                                class="img-fluid border border-dark rounded-circle"
                                style="
                                  border-width: 5px !important;

                                  bottom: 0px;
                                  top: 0;
                                  left: 0%;

                                  max-width: 130px;
                                "
                              />
                            </div>
                          </div>
                          <div class="pl-4 col mt-2 col-5">
                            <a
                              :href="`//facebook.com/${page.id}`"
                              target="_blank"
                            >
                              <h3 class="text-uppercase">{{ page.name }}</h3>
                            </a>
                            <div class="w-100"></div>
                            <small>{{ page.category }}</small>
                            <div class="w-100"></div>
                            <small>{{ page.about }}</small>
                          </div>

                          <div class="col">
                            <small>Rating</small>
                            <div class="w-100"></div>
                            <strong class="h4">{{
                              page.rating_count | formatNumber
                            }}</strong>
                          </div>

                          <div class="col">
                            <small>Seguidores</small>
                            <div class="w-100"></div>
                            <strong class="h4">{{
                              page.followers_count | formatNumber
                            }}</strong>
                          </div>

                          <div class="col">
                            <small>Fans</small>
                            <div class="w-100"></div>
                            <strong class="h4">{{
                              page.fan_count | formatNumber
                            }}</strong>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 80px">
                          <div class="col-3">
                            <div
                              class="img-fluid"
                              :style="{
                                height: '450px',
                                maxWidth: '320px',
                                width: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #ccc',
                                background: `url(${pictureUrl}) center center / cover no-repeat`,
                              }"
                            />
                            <button
                              class="btn btn-secondary btn-block mt-2"
                              @click="showFilesModalFacebook"
                            >
                              SELECCIONAR
                            </button>
                          </div>
                          <div class="col">
                            <small>CONTENIDO</small>
                            <textarea
                              class="form-control"
                              rows="5"
                              v-model="textPost"
                            ></textarea>
                            <small>LINK/URL</small>
                            <input
                              type="text"
                              class="form-control"
                              v-model="linkPost"
                            />
                            <small>PICTURE/URL</small>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="pictureUrl"
                                placeholder="URL de la imagen"
                              />
                              <input
                                type="text"
                                class="form-control"
                                v-model="pictureName"
                                placeholder="Nombre de la imagen"
                              />
                              <input
                                type="text"
                                class="form-control"
                                v-model="pictureDescription"
                                placeholder="Descripción de la imagen"
                              />
                            </div>
                            <button
                              class="btn btn-block btn-primary mt-3"
                              @click="publish()"
                            >
                              PUBLISH FACEBOOK
                            </button>
                          </div>

                          <div class="col-12">
                            <small>STORIES</small>
                            <div class="row">
                              <div
                                class="col-2"
                                v-for="story in storiesFB"
                                :key="story.id"
                              >
                                <div
                                  class="row align-items-center justify-items-center"
                                >
                                  <div class="col-12 position-relative w-100">
                                    <div
                                      :style="`height: 260px; background: url(${story.media.image.src}) center center / cover no-repeat; filter: blur(2px);background-size: 150%`"
                                      class="d-block w-100 position-absolute"
                                    ></div>
                                    <div
                                      class="rounded rounded-circle overflow-hidden border border-primary"
                                      style="border-width: 5px !important"
                                    >
                                      <img
                                        :src="story.media.image.src"
                                        :alt="story.caption"
                                        class="img-fluid mx-auto"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-12 py-1">
                                    <div class="text-center">
                                      {{
                                        story.timestamp | moment("DD/MM HH:mm")
                                      }}
                                    </div>
                                  </div>
                                  <div class="col-12 d-none1">
                                    <div class="row no-gutters h-100">
                                      <div
                                        class="col text-center px-0"
                                        v-for="stat in story.insights.data"
                                        :key="stat.name"
                                      >
                                        <b-button
                                          v-b-tooltip.hover
                                          v-for="value in stat.values"
                                          :title="stat.description"
                                          variant="light"
                                          :key="value.value"
                                        >
                                          <font-awesome-icon
                                            icon="eye"
                                            v-if="stat.name == 'impressions'"
                                            class="text-muted mx-2"
                                          ></font-awesome-icon>
                                          <font-awesome-icon
                                            icon="users"
                                            v-if="stat.name == 'reach'"
                                            class="text-muted mx-2"
                                          ></font-awesome-icon>
                                          <font-awesome-icon
                                            icon="heart"
                                            v-if="
                                              stat.name == 'total_interactions'
                                            "
                                            class="text-muted mx-2"
                                          ></font-awesome-icon>
                                          <span class="d-block">
                                            {{ value.value }}
                                          </span>
                                        </b-button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 mt-3">
                            <small>ULTIMOS POSTS</small>
                            <ul class="list-group" v-if="page.feed">
                              <li
                                class="list-group-item"
                                v-for="post in page.feed.data"
                                :key="post.id"
                              >
                                <div class="row">
                                  <div class="col-10">
                                    <small>{{
                                      post.created_time | moment("DD/MM/YY")
                                    }}</small>
                                    <small class="font-italic ml-4"
                                      >({{
                                        post.created_time | moment("from")
                                      }})</small
                                    >
                                  </div>
                                  <div class="col">
                                    <button
                                      class="btn btn-link btn-sm"
                                      @click="copyPost(post)"
                                    >
                                      <small class="text-primary">COPIAR</small>
                                    </button>
                                    <button
                                      class="btn btn-link btn-sm"
                                      @click="deletePost(post.id)"
                                    >
                                      <small class="text-danger"
                                        >ELIMINAR</small
                                      >
                                    </button>
                                  </div>
                                  <div class="w-100"></div>
                                  <div class="col-2" v-if="post.full_picture">
                                    <img
                                      :src="post.full_picture"
                                      alt="Photo post"
                                      class="img-fluid"
                                    />
                                    <!-- {{post}} -->
                                  </div>
                                  <div class="col">
                                    <strong>{{ post.story }}</strong>
                                    <pre style="white-space: break-spaces">{{
                                      post.message
                                    }}</pre>
                                    <a
                                      target="_blank"
                                      :href="post.permalink_url"
                                    >
                                      <small>Ver en facebook</small>
                                    </a>
                                    <div class="row mt-4">
                                      <div
                                        class="col-12"
                                        v-if="post.attachments"
                                      >
                                        <div
                                          class="row border"
                                          v-for="p in post.attachments.data"
                                          :key="p.title"
                                        >
                                          <div class="col-1" v-if="p.media">
                                            <img
                                              :src="p.media.image.src"
                                              class="img-fluid"
                                              alt=""
                                            />
                                          </div>
                                          <div class="col">
                                            <a :href="p.url" target="_blank">{{
                                              p.title
                                            }}</a>
                                            <small class="d-block">{{
                                              p.description
                                            }}</small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="row mt-5"
                                      v-if="post.insights.data"
                                    >
                                      <div
                                        class="col-3"
                                        v-for="ins in post.insights.data"
                                        :key="ins.name"
                                      >
                                        <b-button
                                          v-b-tooltip.hover
                                          v-for="value in ins.values"
                                          :title="value.description"
                                          variant="light"
                                          :key="`${ins.name}-${value.value}`"
                                        >
                                          {{ value }}
                                        </b-button>
                                        <!-- <strong>{{ ins.name }}</strong>

                                        <small v-for="val in ins.values">
                                          {{ val.value }}</small
                                        > -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab
                  title="Instagram"
                  :disabled="!page.id"
                  v-if="instagram_account"
                >
                  <div class="col-12 bgwhite">
                    <div class="row px-4 py-4">
                      <div class="col-2">
                        <img
                          :src="instagram_account.profile_picture_url"
                          :alt="instagram_account.name"
                          class="img-fluid rounded-circle border border-dark"
                        />
                      </div>
                      <div class="col-6 border-bottom mb-4 py-2">
                        <a
                          :href="`//instagram.com/${instagram_account.username}`"
                          class="d-block"
                          target="_blank"
                        >
                          <strong class="h3 text-uppercase"
                            >@{{ instagram_account.username }}</strong
                          >
                        </a>
                        <strong class="h5">{{ instagram_account.name }}</strong>
                        <pre>{{ instagram_account.biography || "" }}</pre>

                        <div class="w-100"></div>

                        {{ instagram_account.insights }}
                      </div>

                      <div class="col">
                        <small>Posts</small>
                        <div class="w-100"></div>
                        <strong class="h4">{{
                          instagram_account.media_count | formatNumber
                        }}</strong>
                      </div>

                      <div class="col">
                        <small>Seguidores</small>
                        <div class="w-100"></div>
                        <strong class="h4">{{
                          instagram_account.followers_count | formatNumber
                        }}</strong>
                      </div>

                      <div class="col">
                        <small>Seguidos</small>
                        <div class="w-100"></div>
                        <strong class="h4">{{
                          instagram_account.follows_count | formatNumber
                        }}</strong>
                      </div>

                      <div class="w-100 py-4"></div>

                      <div class="col-3">
                        <small>VISTA PREVIA</small>
                        <div
                          class="img-fluid"
                          :style="{
                            height: '430px',
                            'max-width': '320px',
                            width: '100%',
                            'background-color': '#ccc',
                            border: '1px solid #ccc',
                            background: `url(${instagramLink}) center center / cover no-repeat`,
                          }"
                        />
                        <!-- <input
                          type="text"
                          class="form-control"
                          name="instagram-photo"
                          accept="image/*"
                          v-model="instagramLink"
                        /> -->
                        <!-- <input
                          type="file"
                          class="form-control"
                          name="instagram-photo"
                          accept="image/*"
                          @change="setInstagramPhoto"
                        /> -->
                        <button
                          class="btn btn-secondary btn-block mt-2"
                          @click="showFilesModalInstagram"
                        >
                          SELECCIONAR
                        </button>
                      </div>

                      <div class="col">
                        <div class="form-group">
                          <small>PUBLICAR EN</small>
                          <select
                            v-model="instagramTarget"
                            class="form-control fs-2"
                          >
                            <option value="feed">Feed</option>
                            <option value="story">Story</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            v-model="instagramCaption"
                            placeholder="Escribe un mensaje..."
                            cols="30"
                            rows="10"
                            :disabled="instagramTarget == 'story'"
                          ></textarea>
                        </div>
                        <div class="form-group">
                          <small>COLABORADORES</small>
                          <input
                            type="text"
                            class="form-control"
                            v-model="instagramCollaborators"
                          />
                        </div>
                        <!-- <div class="form-group">
                          <small>PROGRAMAR</small>
                          <date-picker
                            valueType="format"
                            v-model="date"
                            :disabled-date="minDate"
                            type="datetime"
                            input-class=" form-control"
                          />
                        </div> -->
                        <div class="form-group">
                          <button
                            class="btn btn-block btn-success py-3"
                            @click="publishInstagram()"
                          >
                            PUBLISH IMAGE
                          </button>
                        </div>
                      </div>

                      <div class="w-100 py-4"></div>
                    </div>

                    <!-- ig story -->
                    <div
                      class="row"
                      v-if="page.instagram_business_account && stories"
                    >
                      <div class="col-12">
                        <small class="text-uppercase">Historias</small>
                      </div>
                      <div
                        class="col-2"
                        v-for="story in stories"
                        :key="story.id"
                      >
                        <div
                          class="row align-items-center justify-items-center"
                        >
                          <div class="col-12 position-relative w-100">
                            <!-- <div
                              :style="`height: 260px; background: url(${story.media_url}) center center  no-repeat; filter: blur(2px);background-size: 150%`"
                              class="d-block w-100 position-absolute"
                            ></div> -->
                            <div
                              class="rounded rounded-circle overflow-hidden border border-primary"
                              style="border-width: 5px !important"
                            >
                              <img
                                :src="story.media_url"
                                :alt="story.caption"
                                class="img-fluid mx-auto"
                              />
                            </div>
                          </div>
                          <div class="col-12 py-1">
                            <div class="text-center">
                              {{ story.timestamp | moment("DD/MM HH:mm") }}
                            </div>
                          </div>
                          <div class="col-12 d-none1">
                            <div class="row no-gutters h-100">
                              <div
                                class="col text-center px-0"
                                v-for="stat in story.insights.data"
                                :key="stat.name"
                              >
                                <b-button
                                  v-b-tooltip.hover
                                  v-for="value in stat.values"
                                  :title="stat.description"
                                  variant="light"
                                  :key="`${stat.name}-${value.value}`"
                                >
                                  <font-awesome-icon
                                    icon="eye"
                                    v-if="stat.name == 'impressions'"
                                    class="text-muted mx-2"
                                  ></font-awesome-icon>
                                  <font-awesome-icon
                                    icon="users"
                                    v-if="stat.name == 'reach'"
                                    class="text-muted mx-2"
                                  ></font-awesome-icon>
                                  <font-awesome-icon
                                    icon="heart"
                                    v-if="stat.name == 'total_interactions'"
                                    class="text-muted mx-2"
                                  ></font-awesome-icon>
                                  <span class="d-block">
                                    {{ value.value }}
                                  </span>
                                </b-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="w-100 py-4"></div>

                    <!-- ig posts -->
                    <div
                      class="row"
                      v-if="
                        page.instagram_business_account &&
                        page.instagram_business_account.media
                      "
                    >
                      <div
                        class="col-3 py-2"
                        v-for="post in page.instagram_business_account.media
                          .data"
                        :key="post.id"
                      >
                        <div class="row position-relative">
                          <div class="col-12">
                            <div
                              :style="`height: 260px; background: url(${post.media_url}) center center / cover no-repeat`"
                            >
                              <!-- <img
                                :src="post.media_url"
                                :alt="post.caption"
                                class="img-fluid img-thumbnail"
                              /> -->
                            </div>
                          </div>

                          <div class="col-12 mt-2">
                            <div class="row bglight no-gutters px-3 py-2 h-100">
                              <div class="col-4 text-center">
                                <font-awesome-icon
                                  icon="heart"
                                  class="text-danger mx-2"
                                ></font-awesome-icon>

                                <small>{{
                                  post.like_count | formatNumber
                                }}</small>
                              </div>
                              <div class="col-4 text-center">
                                <font-awesome-icon
                                  icon="comments"
                                  class="text-muted mx-2"
                                ></font-awesome-icon>

                                <small>{{
                                  post.comments_count | formatNumber
                                }}</small>
                              </div>
                              <div
                                class="col-4 text-center"
                                v-if="post.insights.data"
                              >
                                <font-awesome-icon
                                  icon="eye"
                                  class="text-muted mx-2"
                                ></font-awesome-icon>

                                <small>
                                  {{
                                    post.insights.data[0].values[0].value
                                      | formatNumber
                                  }}</small
                                >
                              </div>

                              <div class="col-12">
                                <small class="mr-3 text-uppercase">{{
                                  post.timestamp | moment("dddd DD/MM")
                                }}</small>
                                <small class="font-italic">{{
                                  post.timestamp | moment("from")
                                }}</small>
                              </div>

                              <div
                                class="col-12 mb-1 py-1 d-none1 text-center1"
                                v-if="post.caption"
                              >
                                <small>{{ post.caption.slice(0, 90) }}</small
                                ><small v-if="post.caption.length > 90"
                                  >...</small
                                >
                              </div>
                              <div class="row m-0 p-0 w-100">
                                <div class="col-6 p-1">
                                  <button
                                    class="btn btn-info btn-block btn-sm"
                                    @click="addStory(post)"
                                  >
                                    <font-awesome-icon
                                      icon="share-alt"
                                      class="text-white"
                                    ></font-awesome-icon>
                                    STORY
                                  </button>
                                </div>
                                <div class="col-3 p-1">
                                  <button
                                    class="btn btn-info btn-block btn-sm"
                                    @click="copyPostInstagram(post)"
                                  >
                                    <font-awesome-icon
                                      icon="copy"
                                      class="text-white"
                                    ></font-awesome-icon>
                                  </button>
                                </div>
                                <div class="col-3 p-1">
                                  <button
                                    @click="doAction(post.permalink)"
                                    class="btn btn-info btn-block btn-sm"
                                    target="_blank"
                                  >
                                    <font-awesome-icon
                                      icon="external-link-alt"
                                      class="text-white"
                                    ></font-awesome-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-12 d-none1 position-absolute text-right px-5"
                            style="top: 20px"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title="Calendario">
                  <calendar :page="selectedPage" />
                </b-tab>
                <b-tab title="Archivos">
                  <files />
                </b-tab>
              </b-tabs>
              <div v-else class="alert alert-warning" role="alert">
                Seleccione una pagina
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-modal
      id="files-modal-ig"
      size="xl"
      title="Seleccionar archivo"
      hide-footer
    >
      <files @selected="setInstagramLink" modal="true" />
    </b-modal>
    <b-modal
      id="files-modal-fb"
      size="xl"
      title="Seleccionar archivo"
      hide-footer
    >
      <files @selected="setFacebookLink" modal="true" />
    </b-modal>
  </div>
</template>

<script>
import messenger from "../../components/messenger.vue";
import files from "./files.vue";
import { doAction } from "../../utils";
import calendar from "./calendar.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    messenger,
    files,
    calendar,
  },
  watch: {
    // router.query.page
    $route() {
      if (this.$route.query.page) {
        console.log("load page route");
        this.loadPage();
      }
    },
    selectedPage() {
      console.log("selected page changed");
      localStorage.selectedPage = this.selectedPage;
      this.$router.push({
        query: {
          page: this.selectedPage,
        },
      });
    },
  },
  async mounted() {
    this.check();
    this.load();
    await this.loadFaceboookSdk(document, "script", "facebook-jssdk");
    await this.initFacebook();
    // if (localStorage.accounts)
    //   this.accounts = JSON.parse(localStorage.accounts);
    // if (localStorage.user) this.user = JSON.parse(localStorage.user);
    // if (localStorage.page) this.page = JSON.parse(localStorage.page);
    if (localStorage.selectedPage)
      this.selectedPage = localStorage.selectedPage;
    this.loadMe();
    //this.date = this.$moment().format("YYYY-MM-DD HH:mm");
  },
  data() {
    return {
      instagramCollaborators: "",
      storiesFB: [],
      stories: [],
      date: null,
      instagramTarget: "feed",
      instagramLink: "",
      selectedPage: "",
      accountId: null,
      loading: true,
      //user: {},
      accounts: [],
      page: {
        id: null,
        instagram_business_account: {
          id: null,
        },
      },
      textPost: "",
      linkPost: "",
      pictureUrl: "",
      pictureName: "",
      pictureDescription: "",
      instagramPhoto: null,
      instagramCaption: "",
      reader: new FileReader(),
      instagramPhotoSrc: "",
    };
  },
  computed: {
    instagram_account() {
      // if (this.page.instagram_accounts) {
      //   return this.page.instagram_accounts.data[0];
      // }
      return this.page.instagram_business_account;
      //return {};
    },
    access_token() {
      return localStorage.access_token;
    },
    getMessages() {
      return this.messages.data;
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    minDate(date) {
      return this.$moment().diff(date, "days") > 0;
    },
    showFilesModalFacebook() {
      this.$bvModal.show("files-modal-fb");
    },
    setFacebookLink(link) {
      this.pictureUrl = link;
      this.$bvModal.hide("files-modal-fb");
    },
    setInstagramLink(link) {
      this.instagramLink = link;
      this.$bvModal.hide("files-modal-ig");
    },
    showFilesModalInstagram() {
      this.$bvModal.show("files-modal-ig");
    },
    doAction,
    addStory(post) {
      this.loading = true;
      this.$http
        .post("auth/facebook/instagram", {
          image_url: post.media_url,
          access_token: this.page.access_token,
          page_id: this.page.id,
          id: this.page.instagram_business_account.id,
          target: "story",
        })
        .then(() => {
          this.$noty.success("Historia publicada");
        })
        .catch((error) => {
          console.log({ error });
          this.$noty.error("Error al subir a historia");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    check() {
      if (!this.hasPermission(this.$store.state.user, "rrss")) {
        console.log("no permission");
        return this.$router.push("/");
      }
    },
    setInstagramPhoto(data) {
      let [file] = data.target.files;
      console.log({ file });
      this.instagramPhoto = file;

      this.reader.onload = (e) => {
        this.instagramPhotoSrc = e.target.result;
        console.log("reader", e.target.result);
      };

      this.reader.readAsDataURL(this.instagramPhoto);
    },
    copyPost(post) {
      console.log({ post });
      this.textPost = post.message;
      this.pictureUrl = post.full_picture;
    },
    copyPostInstagram(post) {
      console.log({ post });
      this.instagramCaption = post.caption;
      this.instagramLink = post.media_url;
    },
    subscribeWelcome() {
      this.$http
        .post(`auth/facebook/suscribe_welcome?id=${this.page.id}`, {})
        .then((response) => {
          console.log("facebook/suscribe_welcome", response);
          this.$noty.success("Suscripción exitosa");
        });
    },
    subscribePage() {
      this.$http
        .post(`auth/facebook/suscribe?id=${this.page.id}`, {})
        .then((response) => {
          console.log("facebook/suscribe", response);
          this.$noty.success("Suscripción exitosa");
        });
    },

    deletePost(id) {
      this.loading = true;
      this.$http
        .delete(`auth/facebook/post?id=${id}&fb_id=${this.page.id}`)
        .then((response) => {
          this.loading = false;
          this.$noty.success("Post eliminado");
          console.log("post deleted", response);
          this.loadPage();
        });
    },
    async publishInstagram() {
      console.log("publish");

      if (this.instagramLink == "") {
        this.$noty.error("Selecciona una imagen");
        return;
      }

      this.loading = true;

      this.$http
        .post("auth/facebook/instagram", {
          image_url: this.instagramLink,
          caption: this.instagramCaption,
          access_token: this.page.access_token,
          page_id: this.page.id,
          id: this.page.instagram_business_account.id,
          target: this.instagramTarget,
          collaborators: this.instagramCollaborators,
        })
        .then((response) => {
          this.instagramCaption = "";
          this.instagramLink = "";
          this.loadPage();
          console.log(response);
        })
        .catch((error) => {
          console.log({ error });
          this.$noty.error("Error al subir imagen");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    publish() {
      console.log("publish");
      this.loading = true;

      this.$http
        .post(`auth/facebook/feed?fb_id=${this.selectedPage}`, {
          message: this.textPost,
          link: this.linkPost,
          page_id: this.page.id,
          picture: this.pictureUrl,
          name: this.pictureName,
          description: this.pictureDescription,
        })
        .then((response) => {
          this.loading = false;
          this.textPost = "";
          this.linkPost = "";
          this.pictureUrl = "";
          this.loadPage();
          console.log(response);
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
          this.$noty.error("Error al publicar");
        });

      // window.FB.api(
      //   `/${this.page.id}/feed`,
      //   "POST",
      //   {
      //     message: this.textPost,
      //     link: this.linkPost,
      //     access_token: this.page.access_token,
      //     call_to_action: {
      //       type: "BOOK_TRAVEL",
      //       value: {
      //         //link: webDomain,
      //         app_destination: "MESSENGER",
      //       },
      //     },
      //   },
      //   (response) => {
      //     this.loading = false;
      //     this.textPost = "";
      //     this.linkPost = "";
      //     this.loadPage();
      //     console.log(response);
      //   }
      // );
    },
    loadInstagramStory() {
      this.loading = true;
      const { instagram_business_account } = this.page;
      if (!instagram_business_account) return;

      this.$http
        .get(
          `meta/instagram/${instagram_business_account.id}/stories?page_id=${this.page.id}`
        )
        .then((response) => {
          this.loading = false;
          this.stories = response.data.data;
          console.log("instagram story", response);
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
        });
    },
    loadFacebookStory() {
      this.loading = true;
      this.$http
        .get(`meta/facebook/${this.page.id}/stories`)
        .then((response) => {
          this.loading = false;
          this.storiesFB = response.data.data;
          console.log("facebook story", response);
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
        });
    },
    loadPage() {
      let id = this.selectedPage;
      if (!id) return;
      this.loading = true;
      this.$http
        .get(`auth/facebook/page/${id}`)
        .then((response) => {
          const data = response.data.data;
          this.loading = false;
          console.log("page response", data);
          this.page = data;
          //this.loadPageChat(id);
          this.loadInstagramStory();
          //this.loadFacebookStory();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async initFacebook() {
      console.log("init fb....");
      window.fbAsyncInit = function () {
        console.log("init fb loaded");
        window.FB.init({
          appId: "1099107870959242", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          xfbml: true,
          version: "v19.0",
        });
        window.FB.AppEvents.logPageView();
      };
    },
    async loadFaceboookSdk(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      //js.src = "//connect.facebook.net/en_US/sdk/debug.js";
      fjs.parentNode.insertBefore(js, fjs);
      setTimeout(this.initFacebook, 4000);
    },
    loadMe() {
      this.$http.get(`auth/facebook/connect`);

      this.$http.get(`auth/facebook/me`).then((res) => {
        const data = res.data.data;
        this.accounts = data;
        // this.user = {
        //   name: data.name,
        //   email: data.email,
        //   picture: data.picture,
        // };
        //localStorage.accounts = JSON.stringify(this.accounts);
        //localStorage.user = JSON.stringify(this.user);
        if (data.length == 0) {
          this.selectedPage = null;
        }
        this.loadPage();
      });
    },
    connectFacebook() {
      this.loading = true;

      // window.open(
      //   `https://www.facebook.com/v19.0/dialog/oauth?client_id=1099107870959242&redirect_uri=https%3A%2F%2Fhome.rowen.ticketapp.cl%2F&config_id=1098869044597136&response_type=code`
      // , "_blank");

      // return false

      // FB.getLoginStatus(function (response) {
      //   console.log("loginstatus", response);
      // });
      window.FB.api(
        "/me/accounts?access_token=" + localStorage.access_token,
        function (response) {
          console.log("me", response);
          // get feed from first page
          // window.FB.api(
          //   `/${response.data[0].id}/feed`,
          //   "GET",
          //   {
          //     fields: "id,message,created_time,full_picture,permalink_url",
          //   },
          //   (response) => {
          //     console.log("feed", response);
          //   }
          // );
        }
      );
      window.FB.login(
        (response) => {
          console.log("fblogin", response);
          window.FB.getLoginStatus(function (response2) {
            console.log("loginstatus", response2);
          });

          if (response.status === "connected") {
            console.log("connected");
            let access_token = response.authResponse.accessToken;

            this.$http
              .get(`auth/facebook/token?access_token=${access_token}`)
              .then(({ data }) => {
                console.log(data);
                this.loading = false;
                localStorage.access_token = data.data.access_token;
                this.loading = false;
                this.loadMe();
              });
          } else if (response.authResponse) {
            alert("You are logged in &amp; cookie set!");
            // Now you can redirect the user or do an AJAX request to
            // a PHP script that grabs the signed request from the cookie.

            this.$http
              .get(
                `auth/facebook/token?access_token=${response.authResponse.code}`
              )
              .then(({ data }) => {
                console.log("token", data);
                this.loading = false;
                localStorage.access_token = data.data.access_token;
                this.loading = false;
                // this.loadMe();
              });
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
          this.loading = false;
        },
        {
          config_id: "263458713482058", //"221939290944826",
          //   response_type: "code",
          //   override_default_response_type: true,
        }
      );
    },
    connectUberEats() {
      // send post data
      let client_id = "bDLgJWTwJgKIiPX915YbOF_wtWTNiI3M";
      let redirect_uri = "https://dev.rowen.ticketapp.cl/auth/uber/callback";
      // order.store
      let scopes = "eats.order";
      console.log(encodeURIComponent(scopes));

      let params = new URLSearchParams();
      params.append("client_id", client_id);
      params.append("redirect_uri", redirect_uri);
      // params.append("response_type", "code");
      params.append("grant_type", "client_credentials");
      params.append("scope", scopes);

      let url = `https://login.uber.com/oauth/v2/token?${params.toString()}`;

      window.open(url, "_blank");
    },
    connectInstagram() {
      // send post data
      let url = `https://api.instagram.com/oauth/authorize/?client_id=5815002971923003&redirect_uri=https%3A%2F%2Fdev.rowen.ticketapp.cl%2Fauth%2Finstagram%2Fcallback&scope=user_profile,user_media&response_type=code`;

      window.open(url, "_blank");
    },
    load() {
      // get token from instagram
      this.loading = false;
    },
  },
};
</script>