<template>
  <div class="row no-gutters py-4">
    <div class="col pr-3">
      <GmapMap
        ref="mapRef"
        :center="centerMap"
        :zoom="11"
        :options="{
          mapTypeControl: false,
          showTraffic: false,
          streetViewControl: false,
          fullscreenControl: false,
          draggable: true,
        }"
        map-type-id="roadmap"
        class="map"
        :style="{
          height: '75vh',
        }"
      >
        <GmapMarker
          v-for="location in locations"
          :key="location._id"
          :ref="`marker_${location._id}`"
          :label="`${location.user && location.user.name[0].toUpperCase()}`"
          :position="{
            lat: location.latitude,
            lng: location.longitude,
          }"
        ></GmapMarker>
      </GmapMap>
    </div>
    <div class="col-4">
      <div class="row align-items-center">
        <div class="col">
          <small class="text-center">MAPA {{ locations.length }}</small>
        </div>
        <div class="col-3">
          <button class="btn btn-success btn-block btn-sm"  @click="getLocations()">
            <font-awesome-icon icon="sync" />
          </button>
        </div>
      </div>
      <div style="max-height: 70vh" class="overflow-auto custom_scroll mt-2">
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-action click"
            v-for="location in locations"
            :key="location._id"
            @click="focus(location)"
            :class="{
              active: location._id === current._id,
            }"
          >
            <div class="row position-relative">
              <div class="col position-absolute" style="top: 0">
                <small class="font-italic text-right d-block">{{
                  $moment().from(location.updatedAt, true)
                }}</small>
              </div>
              <div class="col-2">
                <img
                  :src="getLogo(location.business.logo)"
                  alt=""
                  v-if="location.business"
                  class="img-fluid"
                />
              </div>
              <div class="col">
                <strong class="text-uppercase">{{
                  location.user && location.user.name
                }}</strong>
              </div>
              <div class="col-12">
                <small>{{ location.business && location.business.name }}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locations: [],
      current: {},
    };
  },
  mounted() {
    this.getLocations();
  },
  computed: {
    centerMap() {
      // if (this.focus) {
      //   const { latitude, longitude } = this.focus;
      //   return {
      //     lat: latitude,
      //     lng: longitude,
      //   };
      // } else
      return { lat: -36.8006383, lng: -73.0564978 };
    },
  },
  methods: {
    focus(location) {
      this.current = location;
      //const [marker] = this.$refs[`marker_${location._id}`];
      //console.log({marker})
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.setCenter({
          lat: location.latitude,
          lng: location.longitude,
        });
        map.setZoom(17);
      });
    },
    getLocations() {
      this.$http.get("admin/location").then((res) => {
        this.locations = res.data.data;
      });
    },
  },
};
</script>