<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2>Menu</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="row">
              <div class="col">
                
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [],
    };
  },
  methods: {},
};
</script>