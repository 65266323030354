<template>
  <div style="font-family: sans-serif">
    <table
      style="margin-top: 20px; width: 100%;"
    >
      <tr v-if="businessInfo.deliveryEnabled && ticket.clientName != 'anon'">
        <td colspan="2">
          <strong>PEDIDO PARA {{ ticket.type | uppercase }}</strong> <br />
          <span class="">
            {{ ticket.clientName | uppercase }}
          </span>
        </td>
      </tr>
      <tr v-if="businessInfo.deliveryEnabled && ticket.phone">
        <td colspan="2">
          <strong>TELEFONO: {{ ticket.phone || "" }}</strong>
        </td>
      </tr>
      <tr v-if="ticket.type == 'Domicilio'">
        <td colspan="2">
          <strong>DIRECCION: </strong> <br />
          <span >{{ ticket.address  | uppercase}}</span>
        </td>
      </tr>
      <tr v-if="businessInfo.deliveryEnabled">
        <td colspan="2">
          <strong>ESTADO PAGO</strong>
          <br />
          {{ ticket.status | uppercase }}
          <span v-if="!ticket.payments">{{ ticket.payment || "" }}</span>
        </td>
      </tr>
      <tr v-if="businessInfo.deliveryEnabled && ticket.payment">
        <td colspan="2">
          <strong>Paga con</strong> <br />
          <span v-if="ticket.payment == 'Efectivo'"
            >${{ formatNumber(ticket.amount || 0) }}</span
          >
          <span v-else>{{ ticket.payment || `$0` }}</span>
        </td>
      </tr>
      <tr v-if="ticket.notes">
        <td colspan="2">
           <pre
            style="
              margin: 3px 3px;
              white-space: pre-wrap;
            "
            :style="{
              'font-weight': 'bold',
              'font-family': 'sans-serif'
            }"
            >{{ ticket.notes }}</pre
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["ticket", "fontSize", "businessInfo"],
};
</script>