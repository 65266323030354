<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
         <!-- <h4>Stats</h4>
        <div class="mb-2">
          <item-icon title="documentos emitidos" icon="file-alt" />
        </div>
        <div class="mb-2">
          <item-icon title="Boletas emitidos" icon="file-alt" />
        </div>
        <div class="mb-2">
          <item-icon title="Facturas emitidos" icon="file-alt" />
        </div>

        <div class="w-100 py-2"></div> -->

        <h4 class="mb-3">Certificado digital</h4>
        <div class="card">
          <div class="bglight p-3">
            <small class="d-block">{{
              cert.subjectSerialNumber | formatRut
            }}</small>
            <h5 class="card-title font-weight-bold mb-1">
              {{ cert.subjectName }}
            </h5>
            <div class="row align-items-center">
              <div class="col-10">
                <small class="font-italic">
                  Vence {{ cert.expireDate | moment("from") }}</small
                >
                <div class="w-100"></div>
                <small>{{ cert.subjectEmail }}</small>
                <small class="d-block text-muted"
                  >Archivo: {{ cert.filename }}</small
                >
              </div>
              <div class="w-100 py-1"></div>
              <div class="col-4">
                <small class="mr-2">EMITIDO</small>
                <small class="font-weight-bold">
                  {{ cert.emisionDate | moment("DD/MM/YYYY") }}
                </small>
              </div>
              <div class="col-4">
                <small class="mr-2">VENCE</small>
                <small class="font-weight-bold">
                  {{ cert.expireDate | moment("DD/MM/YYYY") }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <h4 class="mb-3">Timbraje electronico</h4>
        <ul class="list-group">
          <li class="list-group-item" v-if="cafs.length == 0">
            <h4 class="text-center">No tienes archivos</h4>
          </li>
          <li
            class="list-group-item"
            :class="{
              'list-group-item-danger': caf.end <= caf.current,
            }"
            v-for="caf in cafs"
            :key="caf._id"
          >
            <div class="row align-items-center">
              <div
                class="col-1 text-center position-absolute"
                style="opacity: 0.1; right: 30px"
              >
                <font-awesome-icon
                  icon="file-alt"
                  size="3x"
                  class="text-muted"
                ></font-awesome-icon>
              </div>
              <div class="col-12">
                <span class="d-block font-weight-bold text-uppercase"
                  >{{ getDTEName(caf.type) }} ({{ caf.type }})</span
                >
                <span class="mr-2 d-none">{{ caf.rs }}</span>

                <small class="d-none">{{ caf.rut | formatRut }}</small>
                <div class="w-100"></div>
                <small
                class="mr-2"
                  ><strong>EMITIDO:</strong> {{ caf.createdAt | moment("DD/MM/YYYY") }}</small
                >
                <small
                  ><strong>RANGO:</strong> {{ caf.start | formatNumber }} -
                  {{ caf.end | formatNumber }}</small
                >
              </div>
              <div class="col-auto">           
                <small class="font-weight-bold">PROX. FOLIO: </small>
                <small class="mr-3">{{ caf.current }}</small>
             
                <small class="font-weight-bold">DISPONIBLES: </small>
                <small class="mr-3">{{ (caf.end - caf.current) | formatNumber }}</small>
              
                <small class="font-weight-bold">USADOS: </small>
                <small>{{ (caf.current - caf.start) | formatNumber }}</small>
              </div>
              <div class="col-2 col-lg-1 d-none">
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="removeCaf(caf._id)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getDTEName } from "../../utils";
//import ItemIcon from "../../components/itemIcon.vue";

export default {
  // components: {
  //   ItemIcon,
  // },
  name: "",
  data() {
    return {
      cert: {},
    };
  },
  mounted() {
    this.loadCert();
  },
  computed: {
    cafs() {
      return this.$store.state.cafs;
    },
  },
  methods: {
    getDTEName,
    loadCert() {
      this.$http
        .get("sii/cert")
        .then(({ data }) => {
          this.cert = data.data;
        })
        .catch(() => {
          this.$noty.error("Error al cargar el certificado");
        });
    },
  },
};
</script>