<template>
  <div class="container-app container-fluid">
    <b-overlay :show="loading">
      <b-tabs pills justified nav-class="font-weight-bold" content-class="py-3">
        <b-tab title="INFORME POR DIAS" v-if="false">
          <report :show-info="false" />
        </b-tab>
        <b-tab title="INFORME MENSUAL">
          <div class="row py-3">
            <div class="ml-auto col-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <font-awesome-icon icon="calendar-alt" />
                  </span>
                  <date-picker
                    valueType="format"
                    v-model="dateFilter"
                    type="month"
                    input-class="form-control pl-4"
                  />
                </div>
              </div>
            </div>
            <div class="col-1">
              <button class="btn btn-block btn-success" @click="load()">
                <div
                  class="spinner-border spinner-border-sm text-light mb"
                  role="status"
                  v-if="loading"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <font-awesome-icon icon="redo" v-if="!loading" />
              </button>
            </div>
          </div>
          <div class="row justify-content-center mt-4">
            <div class="col-12">
              <h2 class="text-uppercase text-center py-4 font-weight-bold">
                INFORME MES DE {{ dateFilter | moment("MMMM YYYY") }}
              </h2>
            </div>
            <div class="col-6 col-md-4 col-lg-4" v-if="bestdays.length > 0">
              <item-icon
                title="Mejor dia del mes"
                :subtitle="`${$moment(bestdays[0]._id).format('ddd DD/MM/YY')}`"
                :text="bestdays[0].total"
                icon="star"
                iconClass="text-warning"
                textClass="text-primary"
                format
              />
            </div>
            <div class="col-6 col-md-4 col-lg-4" v-if="baddays.length > 0">
              <item-icon
                title="Peor dia del mes"
                :subtitle="`${$moment(baddays[0]._id).format('ddd DD/MM/YY')}`"
                :text="baddays[0].total"
                icon="caret-down"
                iconClass="text-danger"
                textClass="text-danger"
                format
              />
            </div>

            <div class="col-6 col-md-4 col-lg-4">
              <item-icon
                title="Total del mes"
                :text="totalMonth"
                icon="money-bill-wave"
                iconClass="text-success"
                textClass="text-success"
                format
              />
            </div>

            <div class="col-6 col-md-4 col-lg-4">
              <item-icon
                title="Total descuentos"
                :text="totalDiscounts"
                icon="money-bill-wave"
                iconClass="text-success"
                textClass="text-success"
                format
              />
            </div>

            <div class="w-100 py-4"></div>
            <small class="text-center"
              >Dias {{ daysOfMonth.length }} /
              {{ $moment(dateFilter).daysInMonth() }}</small
            >
            <small class="px-2 text-danger"
              >({{
                daysOfMonth.length - $moment(dateFilter).daysInMonth()
              }}
              dias)</small
            >

            <div class="col-12">
              <apexchart
                width="100%"
                height="320"
                type="line"
                :options="chartDays"
              />
            </div>

            <div class="col">
              <h4>Categorias ${{totalCategory | formatNumber}}</h4>
              <div style="max-height: 60vh" class="overflow-auto custom_scroll">
                <b-table striped hover class="my-4" :items="categories">
                  <template #cell(_id)="data">
                    <span class="text-uppercase">{{ data.item._id.name }}</span>
                  </template>
                  <template #cell(total)="data">
                    <strong class="text-right d-block"
                      >${{ data.item.total | formatNumber }}</strong
                    >
                  </template>
                </b-table>
              </div>
            </div>

            <div class="col">
              <h4>
                <span class="text-success">
                  <font-awesome-icon icon="caret-up" />
                </span>
                Mejores dias del mes
              </h4>
              <b-table striped hover class="my-4" :items="bestdays">
                <template #cell(_id)="data">
                  <span class="text-uppercase">{{
                    data.item._id | moment("dddd DD")
                  }}</span>
                </template>
                <template #cell(total)="data">
                  <strong class="text-right d-block"
                    >${{ data.item.total | formatNumber }}</strong
                  >
                </template>
              </b-table>
            </div>

            <div class="col">
              <h4>
                <span class="text-danger">
                  <font-awesome-icon icon="caret-down" />
                </span>
                Peores dias del mes
              </h4>
              <b-table striped hover class="my-4" :items="baddays">
                <template #cell(_id)="data">
                  <span class="text-uppercase">{{
                    data.item._id | moment("dddd DD")
                  }}</span>
                </template>
                <template #cell(total)="data">
                  <strong class="text-right d-block"
                    >${{ data.item.total | formatNumber }}</strong
                  >
                </template>
              </b-table>
            </div>

            <div class="w-100"></div>
            <div class="col py-3">
              <div class="row align-items-end">
                <div class="col">
                  <h4 class="mb-0 d-inline-block">
                    <div class="badge badge-success">SALE</div>
                    Productos mas vendidos
                  </h4>
                  <small class="px-4">TOTAL {{ bestsellers.length }}</small>
                </div>
                <div class="ml-auto col">
                  <small class="text-right d-block">Click para ordenar</small>
                </div>
              </div>

              <div style="max-height: 65vh" class="overflow-auto custom_scroll">
                <b-table
                  striped
                  hover
                  class="my-4"
                  :items="bestsellers"
                  :fields="fieldsBestSellers"
                >
                  <template #cell(totalSales)="data">
                    {{ data.item.totalSales | formatNumber }}
                  </template>
                  <template #cell(total)="data">
                    <strong class="text-right d-block"
                      >${{ data.item.total | formatNumber }}</strong
                    >
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="GENERAL" nav-class="font-weight-bold">
          <div class="row">
            <div class="col-12">
              <div class="row no-gutters">
                <div class="col-6 col-md-4 col-lg-3">
                  <item-icon
                    title="Mejor Dia"
                    :subtitle="`${$moment(bestday._id).format('ddd DD/MM/YY')}`"
                    :text="bestday.total"
                    icon="star"
                    iconClass="text-warning"
                    textClass="text-primary"
                    format
                  />
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                  <item-icon
                    title="Ventas Hoy"
                    :text="totalToday"
                    iconClass="text-success"
                    icon="money-bill-wave"
                    textClass="text-primary"
                    format
                  />
                </div>

                <div class="col-6 col-md-4 col-lg-3">
                  <item-icon
                    title="Ventas Semana"
                    :subtitle="`Semana ${$moment().week()} -
                    ${$moment().weeksInYear()}`"
                    :text="currentWeek"
                    iconClass="text-secondary"
                    icon="chart-line"
                    textClass="text-secondary"
                    format
                  />
                  <small class="d-block text-center"> </small>
                </div>

                <div class="col-6 col-md-4 col-lg-3">
                  <item-icon
                    title="Ventas Mes"
                    :subtitle="`${$moment().format('MMMM')}`"
                    :text="currentMonth"
                    iconClass="text-secondary"
                    icon="calendar-alt"
                    textClass="text-primary"
                    format
                  />
                </div>

                <div class="col-6 col-md-4 col-lg-3">
                  <item-icon
                    title="Ventas Año"
                    :text="currentYear"
                    :subtitle="`${$moment().format('YYYY')}`"
                    iconClass="text-secondary"
                    icon="calendar-alt"
                    textClass="text-success font-weight-bold"
                    format
                  />
                </div>

                <div class="col-6 col-md-4 col-lg-3 d-none1">
                  <item-icon
                    title="Ventas Año Anterior"
                    :subtitle="`${$moment()
                      .subtract(1, 'year')
                      .format('YYYY')}`"
                    :text="lastYear"
                    iconClass="text-secondary"
                    icon="calendar-alt"
                    textClass="text-warning"
                    format
                  />
                  <small class="d-block text-center">
                    {{ Date.now() | moment("MMMM") }}
                  </small>
                </div>
              </div>
            </div>

            <div class="w-100 mt-4"></div>

            <div class="col-6">
              <h3>Ventas en los ultimos años</h3>
              <hr />
              <apexchart
                width="100%"
                height="320"
                type="line"
                :options="chartOptionsYear"
                :series="seriesYear"
              />
            </div>
            <div class="col-6">
              <h3>Ventas en los ultimos meses</h3>
              <hr />
              <apexchart
                width="100%"
                height="320"
                type="line"
                :options="chartOptionsMonth"
                :series="seriesMonth"
              />
            </div>
            <div class="col-12">
              <h3>Ventas en los ultimas semanas</h3>
              <hr />
              <apexchart
                width="100%"
                height="320"
                type="line"
                :options="chartOptionsWeek"
                :series="seriesWeek"
              />
            </div>
            <div class="col-6 d-none">
              <h3>Ventas en los ultimos dias</h3>
              <hr />
              <apexchart
                width="100%"
                height="320"
                type="line"
                :options="chartOptions"
                :series="series"
              />
            </div>
            <div class="col-4" v-if="false">
              <h3>Estadisticas</h3>

              <ul class="list-group">
                <li class="list-group-item"></li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col">
                      <span>Ultimos 10 dias</span>
                      <small class="d-block"
                        >({{ bestday._id | moment("DD-MM-YYYY") }})</small
                      >
                    </div>
                    <div class="col text-right">
                      <span class="font-weight-bold"
                        >$ {{ formatNumber(bestday.total || "- - -") }}</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
              <table class="table table-bordered table-hover">
                <tbody>
                  <tr>
                    <th class="table-active">
                      Mejor dia
                      <span v-if="bestday._id">
                        ({{ bestday._id | moment("DD-MM-YYYY") }})
                      </span>
                    </th>
                    <td class="text-right"></td>
                  </tr>
                  <tr>
                    <th class="table-active">Ventas ultimos 10 dias</th>
                    <td class="text-right">
                      $ {{ formatNumber(week.total || 0) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      Ventas del ultimo mes -
                      {{ getLastMonthDate() | moment("MMMM") }}
                    </th>
                    <td class="text-right">
                      $ {{ formatNumber(getLastMonth() || 0) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">
                      Ventas mes anterior -
                      {{ getPastMonthDate() | moment("MMMM") }}
                    </th>
                    <td class="text-right">
                      $ {{ formatNumber(getPastMonth() || 0) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="table-active">Ultimos 3 meses</th>
                    <td class="text-right">
                      $ {{ formatNumber(getTotalMonth() || 0) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-12" v-if="options.length > 0 && true">
              <span class="h2 text-uppercase my-3 d-block"
                >Opciones mas pedidas de este mes</span
              >
              <div class="row no-gutters">
                <div
                  class="col-2 border py-4 px-3 bg-white"
                  v-for="(o, i) in options"
                  :key="i"
                >
                  <h1
                    style="
                      opacity: 0.3;
                      position: absolute;
                      font-size: 4em;
                      bottom: 10px;
                      right: 10px;
                    "
                  >
                    {{ o.total }}
                  </h1>
                  <small>CODIGO: {{ o._id.code }}</small>
                  <div class="font-weight-bold text-uppercase h4">
                    {{ o._id.name }}
                  </div>

                  <div class="row text-uppercase">
                    <div class="col-12">
                      <small class="font-weight-bold">
                        {{ o._id.option.label }}:
                      </small>
                    </div>
                    <div class="col">
                      <small v-for="(s, i) in o._id.option.selected" :key="i">{{
                        s
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4" v-if="false">
              <h4>Productos mas vendidos</h4>
              <ul class="list-group">
                <li class="list-group-item" v-if="bestsellers.length == 0">
                  <h5 class="text-center py-4">No hay informacion</h5>
                </li>
                <li
                  class="list-group-item bg-light"
                  v-for="(product, i) in bestsellers"
                  :key="i"
                >
                  <div class="row align-items-center">
                    <div class="col text-center">
                      <span class="font-weight-bold">{{
                        product.totalSales || 0
                      }}</span>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col-12">
                          <span class="font-weight-bold"
                            >({{ product._id.code }})</span
                          >
                        </div>
                        <div class="col-12">
                          <span>{{ product._id.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12">
              <hr />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import Report from "../components/report.vue";
import VueApexCharts from "vue-apexcharts";
import itemIcon from "../components/itemIcon.vue";

export default {
  name: "Stats",
  components: {
    apexchart: VueApexCharts,
    itemIcon,
    Report,
  },
  watch: {
    dateFilter() {
      this.load();
    },
  },
  data() {
    return {
      totalCategory: 0,
      loading: true,
      dateFilter: null,
      lastdays: [],
      tickets: [],
      bestdays: [],
      baddays: [],
      daysOfMonth: [],
      categories: [],
      month: {
        total: 0,
      },
      ticketsToday: [],
      bestsellers: [],
      months: [],
      weeks: [],
      bestday: {
        total: 0,
        _id: null,
      },
      year: [],
      options: [],
      otLastdays: [],
      fieldsBestSellers: [
        {
          key: "_id.code",
          label: "Codigo",
          sortable: true,
        },
        {
          key: "_id.name",
          label: "Nombre",
          sortable: true,
          class: "font-weight-bold text-uppercase",
        },
        {
          key: "_id.value",
          label: "Precio",
          sortable: true,
          formatter: (val) => `$${this.formatNumber(val)}`,
          class: "text-right",
        },
        {
          key: "totalSales",
          label: "Cantidad",
          sortable: true,
          class: "text-center",
        },
        {
          key: "total",
          label: "Total",
          variant: "secondary",
          formatter: (val) => `$${this.formatNumber(val)}`,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    format() {
      return 1;
    },
    load() {
      this.loading = true;
      Promise.all([
        this.$http.get(
          `statistics/bestseller?date=${this.dateFilter}&limit=100`
        ),
        this.$http.get(`statistics/bestdays?date=${this.dateFilter}`),
        this.$http.get(`statistics/bestdays?date=${this.dateFilter}&sort=1`),
        this.$http.get(`statistics/days?date=${this.dateFilter}&sort=1`),
        this.$http.get(`statistics/categories?date=${this.dateFilter}`),
      ])
        .then(([bestSellerRes, bestDaysRes, badDaysRes, daysRes, catRes]) => {
          this.bestsellers = bestSellerRes.data.data;
          this.bestdays = bestDaysRes.data.data;
          this.baddays = badDaysRes.data.data;
          this.daysOfMonth = daysRes.data.data;
          this.categories = catRes.data.data;
          this.totalCategory = this.categories.reduce((a, b) => a + b.total, 0)
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPastMonthDate() {
      return this.months[1] ? this.months[1]._id : null;
    },
    getLastMonthDate() {
      return this.months[0] ? this.months[0]._id : null;
    },
    getLastMonth() {
      return this.months[0] ? this.months[0].total : 0;
    },
    getTotalMonth() {
      const month = this.months;
      let result = 0;
      for (let i = 0; i < month.length; i++) {
        result = month[i].total + result;
        if (i == 2) break;
      }
      // if (month.length >= 2) {
      //   result = month[0].total + month[1].total + month[2].total;
      // }
      return result;
    },
    getPastMonth() {
      return this.months[1] ? this.months[1].total : 0;
    },
    getDay(number) {
      return this.$moment().dayOfYear(number).format("D");
    },
  },
  computed: {
    totalDiscounts() {
      return this.daysOfMonth.reduce((a, b) => a + b.discount, 0);
    },
    totalMonth() {
      if (this.daysOfMonth && this.daysOfMonth.length == 0) return 0;
      return this.daysOfMonth.reduce((a, b) => a + b.total, 0);
    },
    getBestSellers() {
      return this.bestsellers.map((item) => {
        return {
          codigo: item._id.code,
          producto: item._id.name,
          cantidad: this.formatNumber(item.totalSales),
          total: `$${this.formatNumber(item.total)}`,
        };
      });
    },
    currentWeek() {
      return this.weeks.reduce((c, d) => {
        if (this.$moment(d._id, "YYYY-MM-DD").week() == this.$moment().week())
          return c + d.total;
        else return c;
      }, 0);
    },
    currentMonth() {
      console.log({ months: this.months });

      let Cmonth = this.months.find(
        (m) => m._id == this.$moment().format("Y-MM")
      );
      if (Cmonth) return Cmonth.total;
      else return 0;

      // return this.months.reduce((c, d) => {
      //   if (this.$moment(d._id, "YYYY-MM").month() == month) return c + d.total;
      //   else return c;
      // }, 0);
      //return days
    },
    currentYear() {
      let y = this.year.find((y) => y._id == this.$moment().format("Y"));
      if (y) return y.total;
      else return 0;
    },
    lastYear() {
      let y = this.year.find(
        (y) => y._id == this.$moment().subtract(1, "year").format("Y")
      );
      if (y) return y.total;
      else return 0;
    },
    totalToday() {
      return this.ticketsToday.reduce((c, t) => {
        if (t.deletedAt == null) return c + t.total;
        return c;
      }, 0);
    },
    chartDays() {
      let days = this.daysOfMonth.map((day) =>
        this.$moment(day._id).format("dd, DD/MM")
      );

      const self = this;
      return {
        series: [
          {
            name: "Ventas",
            data: this.daysOfMonth.map((day) => day.total),
          },
        ],
        markers: {
          size: 1,
        },
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
          type: "area",
        },
        colors: ["#546E7A"],
        xaxis: {
          categories: days,
        },
        stroke: {
          curve: "smooth",
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex }) {
            return (
              '<div class="arrow_box p-3" style="width: 200px; text-align:center">' +
              "<span class='font-weight-bold d-block'>Total ventas</span><span> $" +
              self.formatNumber(series[seriesIndex][dataPointIndex]) +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
    chartOptions() {
      let days = this.lastdays.map((day) =>
        this.$moment(day._id).format("dddd, DD/MM")
      );

      const self = this;
      return {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
          type: "area",
        },
        colors: ["#546E7A"],
        xaxis: {
          categories: days,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex }) {
            return (
              '<div class="arrow_box p-3">' +
              "<span class='font-weight-bold d-block'>Total ventas</span><span> $" +
              self.formatNumber(series[seriesIndex][dataPointIndex]) +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
    chartOptionsYear() {
      let days = this.year.map((day) => {
        return this.$moment(day._id, "YYYY").format("YYYY");
      });
      const self = this;
      return {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },

          type: "area",
        },
        colors: ["#546E7A"],
        xaxis: {
          categories: days,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex }) {
            return (
              '<div class="arrow_box p-3">' +
              "<span class='font-weight-bold d-block'>Total ventas</span><span> $" +
              self.formatNumber(series[seriesIndex][dataPointIndex]) +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
    chartOptionsMonth() {
      console.log(this.months);
      let ms = this.months.filter((m) => {
        if (m._id) return m;
      });

      console.log({ ms });

      let months = ms.map((month) => {
        if (!month) return "0000, 00";
        return this.$moment(month._id, "YYYY-MM").format("MMM, YY");
      });

      const self = this;
      return {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },

          type: "area",
        },
        colors: ["#546E7A"],
        xaxis: {
          categories: months,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex }) {
            return (
              '<div class="arrow_box p-3">' +
              "<span class='font-weight-bold '>Total</span><span> $" +
              self.formatNumber(series[seriesIndex][dataPointIndex]) +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
    chartOptionsWeek() {
      let weeks = this.weeks.map(
        (week) =>
          `${this.$moment()
            .week(week._id.week)
            .format("DD MMM")} al ${this.$moment()
            .week(week._id.week)
            .add(7, "days")
            .format("DD MMM")}`
      );
      const self = this;
      return {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },

          // type: "area",
        },
        colors: ["#546E7A"],
        xaxis: {
          categories: weeks,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex}) {
            return (
              '<div class="arrow_box p-3">' +
              "<span class='font-weight-bold'>Total</span><span> $" +
              self.formatNumber(series[seriesIndex][dataPointIndex]) +
              "</span>" +
              "</div>"
            );
          },
        },
      };
    },
    series() {
      let days = this.lastdays.map((day) => day.total);
      return [
        {
          name: "Total Ventas",
          data: days,
        },
      ];
    },
    seriesMonth() {
      let months = this.months.map((month) => month.total);

      return [
        {
          name: "Total Ventas",
          data: months,
        },
      ];
    },
    seriesYear() {
      let years = this.year.map((year) => year.total);
      return [
        {
          name: "Total Ventas",
          data: years,
        },
      ];
    },
    seriesWeek() {
      let weeks = this.weeks.map((week) => week.total);

      return [
        {
          name: "Total Ventas",
          data: weeks,
        },
      ];
    },
    week() {
      const totalReduce = (sum, item) => item.total + sum;
      let total = this.lastdays.reduce(totalReduce, 0);

      const countReduce = (sum, item) => item.count + sum;
      let count = this.lastdays.reduce(countReduce, 0);

      return {
        total: total,
        count: count,
        card: 0,
      };
    },
  },
  mounted() {
    this.dateFilter = this.$moment().format("YYYY-MM");

    // this.$http.get("statistics/options").then((res) => {
    //   this.options = res.data.data;
    // });
    //

    let dateFilter = null;
    if (this.$moment().hour() < 5)
      dateFilter = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    else dateFilter = this.$moment().format("YYYY-MM-DD");
    // load tickets today
    this.$http.get(`ticket?date=${dateFilter}`).then((res) => {
      this.ticketsToday = res.data.data;
    });

    this.$http.get("statistics/year").then((res) => {
      this.year = res.data.data;
    });
    this.$http.get("statistics/weeks").then((res) => {
      this.weeks = res.data.data;
    });
    // by month

    Promise.all([
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(13, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(12, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(11, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(10, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(9, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(8, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(7, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(6, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(5, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(4, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(3, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(2, "month")
          .format("YYYY-MM")}`
      ),
      this.$http.get(
        `statistics/month?date=${this.$moment()
          .subtract(1, "month")
          .format("YYYY-MM")}`
      ),
    ]).then((res) => {
      this.months = res.map((r) => {
        console.log({ r });
        return r.data.data;
      });
      //this.months = res.data.data;
    });

    this.$http.get("statistics/bestday").then(({ data }) => {
      this.bestday = data.data;
    });
  },
};
</script>
