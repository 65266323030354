<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <h2>
          <font-awesome-icon icon="users" /> Usuarios ({{ getUsers.length }})
        </h2>
      </div>
      <div class="col-auto ml-auto d-none">
        <router-link to="/documents">ADMINISTRAR DOCUMENTOS</router-link>
      </div>
      <div class="w-100"></div>
      <div class="ml-auto col-12">
        <div class="btn-group btn-block">
          <button
            class="btn btn-outline-info"
            v-if="hideDisabled"
            @click="hideDisabled = false"
          >
            Mostrar desactivados
          </button>
          <button
            class="btn btn-info"
            v-if="!hideDisabled"
            @click="hideDisabled = true"
          >
            Ocultar desactivados
          </button>
          <button class="btn btn-primary float-right" @click="showModalUser()">
            Nuevo usuario
          </button>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-12">
        <small class="text-muted"> Buscar </small>
        <input
          v-model="fakeFilter"
          placeholder="Buscar usuario"
          class="form-control"
          @keyup.enter="filterUsers()"
        />
      </div>
      <div class="col mt-4">
        <ul class="list-group" v-if="loading">
          <li
            class="list-group-item"
            v-for="i in getUsers.length || 6"
            :key="i"
          >
            <div class="row align-items-center py-2">
              <div class="col">
                <b-skeleton width="20%" />
                <b-skeleton width="60%" />
              </div>
              <div class="col">
                <b-skeleton width="20%" />
                <b-skeleton width="50%" />
              </div>
              <div class="col-2">
                <b-skeleton width="20%" />
                <b-skeleton width="50%" />
              </div>
              <div class="col-1">
                <b-skeleton width="70%" />
                <b-skeleton width="40%" />
              </div>
              <div class="col-1">
                <b-skeleton width="50%" style="margin: 0 auto" />
              </div>
            </div>
          </li>
        </ul>
        <ul class="list-group" v-else>
          <li
            class="list-group-item list-group-item-action click"
            v-for="us in getUsers"
            :class="{
              'list-group-item-danger': !us.active,
              active: us._id === edituser._id,
            }"
            :key="us._id"
            @click="editUser(us)"
          >
            <div class="row align-items-center">
              <div class="col-1 px-0 text-center text-uppercase d-none">
                <b-checkbox
                  v-model="us.active"
                  switch
                  size="lg"
                  :disabled="us.email == $store.state.user.email"
                  @change="changeStatus(us)"
                >
                </b-checkbox>
              </div>
              <div class="col-1 text-center p-0">
                <font-awesome-icon
                  icon="user-circle"
                  size="3x"
                  :class="{
                    'text-pink': us.gender == 'F',
                    'text-primary': us.gender == 'M',
                  }"
                />
                <small
                  class="py-2 d-block font-weight-bolder text-uppercase"
                  :class="{
                    'text-danger': us.role == 'admin',
                    'text-warning': us.role == 'cash',
                    'text-info': us.role == 'driver',
                  }"
                  >{{ us.role }}</small
                >
              </div>
              <div class="col font-weight-bold">
                <small class="mr-4">RUT: {{ us.rut || "no registra" }}</small>

                <small class="d-block1 font-italic">{{
                  getUserConnection(us)
                }}</small>
                <div class="w-100"></div>
                <strong class="text-uppercase">{{ us.name }}</strong>
                <div class="w-100"></div>
                <small>{{ us.email }}</small>
              </div>
              <div class="col font-weight-bold">
                <small class="d-block">CARGO</small>
                <strong>{{ us.position || "- - - -" }}</strong>
              </div>

              <div class="col-1 text-center d-none">
                <b-button variant="link" size="md" @click="editUser(us)">
                  <font-awesome-icon icon="pen" />
                </b-button>
                <div class="dropdown d-none">
                  <button
                    class="btn"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <font-awesome-icon icon="ellipsis-h" />
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button class="dropdown-item" @click="editUser(us)">
                      Editar
                    </button>
                    <button
                      class="dropdown-item d-none"
                      @click="disable(us)"
                      v-if="us.active && us.email != $store.state.user.email"
                    >
                      Desactivar
                    </button>
                    <button
                      @click="enable(us)"
                      class="dropdown-item d-none"
                      v-if="!us.active"
                    >
                      Activar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Modal Component -->
    <b-modal
      id="modal-newUser"
      size="lg"
      title="Nuevo usuario"
      @ok="saveUser()"
    >
      <div class="form-group">
        <label>Nombre</label>
        <input
          class="form-control"
          v-model="user.name"
          placeholder="Ej: Juan Perez"
          :class="{
            'is-invalid': nameIsValid(user.name),
            'is-valid': !nameIsValid(user.name),
          }"
        />
        <small class="text-muted">Minimo 3 caracteres</small>
      </div>

      <div class="form-group">
        <label>Correo</label>
        <input
          class="form-control"
          placeholder="Ej: nombre@correo.cl"
          v-model="user.email"
          :class="{
            'is-invalid': emailIsValid(user.email),
            'is-valid': !emailIsValid(user.email),
          }"
        />
        <small class="text-muted">Correo valido para iniciar sesion</small>
      </div>

      <div class="form-group">
        <label>Rol</label>
        <select
          class="form-control"
          v-model="user.role"
          :class="{
            'is-invalid': !validRole(user.role),
            'is-valid': validRole(user.role),
          }"
        >
          <option :value="null" disabled>Seleccione</option>
          <option v-for="role in roles" :value="role.id" :key="role.id">
            {{ role.name }}
          </option>
        </select>
        <small class="text-muted">Rol del usuario</small>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Contraseña</label>
            <input
              class="form-control"
              v-model="user.password"
              :class="{
                'is-invalid': user.password.length <= 5,
                'is-valid': user.password.length > 5,
              }"
            />
            <small class="text-muted">Minimo 6 caracteres</small>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Repita Contraseña</label>
            <input
              class="form-control"
              v-model="user.repassword"
              :class="{
                'is-invalid':
                  user.password != user.repassword || user.repassword == '',
                'is-valid':
                  user.password == user.repassword && user.repassword != '',
              }"
            />
            <small class="text-danger" v-if="user.password != user.repassword"
              >Las contraseñas deben ser iguales</small
            >
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal
      id="modal-edit"
      size="xl"
      ref="modal-edit"
      title="Editar Usuario"
      @ok="updateUser()"
    >
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->

        <div class="row align-items-end w-100">
          <div class="col">
            <h5>Editar Usuario</h5>
          </div>
          <div class="col-2">
            <button
              class="btn btn-outline-primary btn-sm btn-block"
              @click="updateUser()"
            >
              Guardar
            </button>
          </div>
        </div>
      </template>
      <div class="row align-items-center">
        <div class="col-2 text-center">
          <font-awesome-icon
            icon="user-circle"
            size="5x"
            :class="{
              'text-pink': edituser.gender == 'F',
              'text-primary': edituser.gender == 'M',
            }"
          />
          <div class="w-100 py-2"></div>
          <b-checkbox
            v-model="edituser.active"
            switch
            size="lg"
            :disabled="edituser.email == $store.state.user.email"
            @change="changeStatus(edituser)"
          >
          </b-checkbox>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Nombre</label>
                <input
                  class="form-control"
                  v-model="edituser.name"
                  :class="{
                    'is-invalid': nameIsValid(edituser.name),
                    'is-valid': !nameIsValid(edituser.name),
                  }"
                />
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label>RUT</label>
                <input
                  class="form-control"
                  v-model="edituser.rut"
                  placeholder="Opcional"
                />
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label>Correo</label>
                <input
                  class="form-control bg-light"
                  v-model="edituser.email"
                  disabled
                  readonly
                />
              </div>
            </div>

            <div class="w-100"></div>

            <div class="col">
              <div class="form-group">
                <label>Cargo</label>
                <input class="form-control" v-model="edituser.position" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Area</label>
                <input class="form-control" v-model="edituser.area" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs content-class="p-2" class="mt-2">
        <b-tab title="Informacion">
          <div class="row">
            <div class="col">
              <label>Sexo</label>
              <select class="form-control" v-model="edituser.gender">
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
            <div class="col">
              <label>Estado civil</label>
              <select class="form-control" v-model="edituser.state">
                <option value="soltero">Soltero</option>
                <option value="casado">Casado</option>
                <option value="viudo">Viudo</option>
                <option value="divorciado">Divorciado</option>
                <option value="acuerdo_union_civil">
                  Acuerdo de union civil
                </option>
              </select>
            </div>
            <div class="w-100 py-2"></div>
            <div class="col">
              <label>Telefono</label>
              <input class="form-control" v-model="edituser.phone" />
            </div>
            <div class="col">
              <label>Fecha de nacimiento</label>
              <b-datepicker v-model="edituser.birthDate"></b-datepicker>
            </div>
            <div class="w-100 py-2"></div>
            <div class="col">
              <label>Direccion</label>
              <input
                class="form-control"
                type="text"
                v-model="edituser.address"
              />
            </div>
            <div class="col">
              <label>Comuna</label>
              <input
                class="form-control"
                type="text"
                v-model="edituser.commune"
              />
            </div>
            <div class="col">
              <label>Ciudad</label>
              <input class="form-control" type="text" v-model="edituser.city" />
            </div>
          </div>
        </b-tab>
        <b-tab title="Contrato" v-if="info.contractsEnabled">
          <div class="row">
            <div class="col">
              <label>Fecha contratacion</label>
              <b-datepicker v-model="edituser.hireDate"></b-datepicker>
            </div>
            <div class="col">
              <label>Sueldo base</label>
              <input
                class="form-control"
                v-model.number="edituser.baseSalary"
              />
            </div>
            <div class="w-100 py-2"></div>

            <div class="col">
              <label>Modo de trabajo</label>
              <select class="form-control" v-model="edituser.workMode">
                <option value="full_time">Full time</option>
                <option value="part_time">Part time</option>
              </select>
            </div>
            <div class="col">
              <label>Horas semanales</label>
              <input class="form-control" v-model.number="edituser.workHours" />
            </div>
            <div class="w-100 py-2"></div>

            <div class="col">
              <label>Prevision</label>
              <select class="form-control" v-model="edituser.planCotizacion">
                <option value="">AFP</option>
                <option value="">No Cotiza</option>
                <option value="">IPS (ex-inp)</option>
              </select>
            </div>

            <div class="col">
              <label>Fondo de cotizacion</label>
              <select class="form-control" v-model="edituser.companyCotizacion">
                <option value="capital">Capital</option>
                <option value="cuprum">Cuprum</option>
                <option value="habitad">Habitad</option>
                <option value="modelo">Modelo</option>
                <option value="planvital">PlanVital</option>
                <option value="provida">Provida</option>
                <option value="uno">Uno</option>
              </select>
            </div>

            <div class="w-100 py-2"></div>
            <div class="col">
              <label>Salud</label>
              <select class="form-control" v-model="edituser.healthCompany">
                <option value="fonasa">Fonasa</option>
                <option value="banmedica">Banmedica</option>
                <option value="colmena">Colmena</option>
                <option value="consalud">Consalud</option>
                <option value="cruz_blanca">Cruz Blanca</option>
                <option value="nueva_masvida">Nueva MasVida</option>
                <option value="vida_tres">Vida tres</option>
                <option value="banco_estado">Banco estado</option>
                <option value="isapre_codelco">
                  ISALUID Isapre de Codelco
                </option>
                <option value="cruz_del_norte">Cruz del Norte</option>
                <option value="esencial">Esencial</option>
                <option value="no_cotiza">No cotiza salud</option>
              </select>
            </div>

            <div class="col">
              <label for="">Plan Isapre UF*</label>
              <input
                class="form-control"
                v-model.number="edituser.isaprePlan"
              />
            </div>

            <div class="col">
              <label for="">AFC</label>
              <select name="" class="form-control" v-model="edituser.afc">
                <option value="afc_normal">Menos de 11 años</option>
                <option value="afc_reducido">Mas de 11 años</option>
                <option value="no_cotiza">No cotiza</option>
              </select>
            </div>

            <div class="w-100 py-2"></div>

            <div class="col-3">
              <label for="">Jubilacion</label>
              <b-checkbox v-model="edituser.jubilado"></b-checkbox>
            </div>

            <div class="col-3" v-if="edituser.jubilado">
              <label for="">Regimen jubilacion</label>
              <select
                name=""
                class="form-control"
                v-model="edituser.planJubilado"
              >
                <option value="afp">AFP</option>
                <option value="ips">IPS (EX-INP)</option>
              </select>
            </div>
          </div>
        </b-tab>
        <b-tab title="Ausencias" v-if="info.contractsEnabled">
          <div class="row">
            <div class="col">
              <label>Fecha contratacion</label>
              <b-datepicker v-model="edituser.hireDate"></b-datepicker>
            </div>
            <div class="col">
              <label>Sueldo base</label>
              <input
                class="form-control"
                v-model.number="edituser.baseSalary"
              />
            </div>
            <div class="w-100 py-2"></div>

            <div class="col">
              <label>Modo de trabajo</label>
              <select class="form-control" v-model="edituser.workMode">
                <option value="full_time">Full time</option>
                <option value="part_time">Part time</option>
              </select>
            </div>
            <div class="col">
              <label>Horas semanales</label>
              <input class="form-control" v-model.number="edituser.workHours" />
            </div>
            <div class="w-100 py-2"></div>

            <div class="col">
              <label>Prevision</label>
              <select class="form-control" v-model="edituser.planCotizacion">
                <option value="">AFP</option>
                <option value="">No Cotiza</option>
                <option value="">IPS (ex-inp)</option>
              </select>
            </div>

            <div class="col">
              <label>Fondo de cotizacion</label>
              <select class="form-control" v-model="edituser.companyCotizacion">
                <option value="capital">Capital</option>
                <option value="cuprum">Cuprum</option>
                <option value="habitad">Habitad</option>
                <option value="modelo">Modelo</option>
                <option value="planvital">PlanVital</option>
                <option value="provida">Provida</option>
                <option value="uno">Uno</option>
              </select>
            </div>

            <div class="w-100 py-2"></div>
            <div class="col">
              <label>Salud</label>
              <select class="form-control" v-model="edituser.healthCompany">
                <option value="fonasa">Fonasa</option>
                <option value="banmedica">Banmedica</option>
                <option value="colmena">Colmena</option>
                <option value="consalud">Consalud</option>
                <option value="cruz_blanca">Cruz Blanca</option>
                <option value="nueva_masvida">Nueva MasVida</option>
                <option value="vida_tres">Vida tres</option>
                <option value="banco_estado">Banco estado</option>
                <option value="isapre_codelco">
                  ISALUID Isapre de Codelco
                </option>
                <option value="cruz_del_norte">Cruz del Norte</option>
                <option value="esencial">Esencial</option>
                <option value="no_cotiza">No cotiza salud</option>
              </select>
            </div>

            <div class="col">
              <label for="">Plan Isapre UF*</label>
              <input
                class="form-control"
                v-model.number="edituser.isaprePlan"
              />
            </div>

            <div class="col">
              <label for="">AFC</label>
              <select name="" class="form-control" v-model="edituser.afc">
                <option value="afc_normal">Menos de 11 años</option>
                <option value="afc_reducido">Mas de 11 años</option>
                <option value="no_cotiza">No cotiza</option>
              </select>
            </div>

            <div class="w-100 py-2"></div>

            <div class="col-3">
              <label for="">Jubilacion</label>
              <b-checkbox v-model="edituser.jubilado"></b-checkbox>
            </div>

            <div class="col-3" v-if="edituser.jubilado">
              <label for="">Regimen jubilacion</label>
              <select
                name=""
                class="form-control"
                v-model="edituser.planJubilado"
              >
                <option value="afp">AFP</option>
                <option value="ips">IPS (EX-INP)</option>
              </select>
            </div>
          </div>
        </b-tab>
        <b-tab title="Documentos" v-if="info.contractsEnabled">
          <div class="row">
            <div class="col">
              <select
                class="form-control"
                v-model="content"
                :class="{
                  'is-invalid': !content,
                  'is-valid': content,
                }"
              >
                <option
                  :value="tem.content"
                  v-for="tem in templates"
                  :key="tem._id"
                >
                  {{ tem.name }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <button
                class="btn btn-success btn-block"
                @click="generateDocument()"
              >
                Generar
              </button>
            </div>
            <div class="col-3">
              <button class="btn btn-primary btn-block">Subir</button>
            </div>

            <div class="col-12 py-3 d-none1">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col">
                      <small>NOMBRE</small>
                      <div class="w-100"></div>
                      contrato_{{ edituser.rut }}_{{
                        Date.now() | moment("DD-MM-YY")
                      }}.pdf
                    </div>
                    <div class="col-2">
                      <small>CREADO</small>
                      <div class="w-100"></div>
                      {{ Date.now() | moment("DD-MM-YY") }}
                    </div>
                    <div class="col-3 btn-group">
                      <button class="btn btn-primary btn-sm">
                        <font-awesome-icon icon="eye" />
                      </button>
                      <button class="btn btn-danger btn-sm">
                        <font-awesome-icon icon="trash" />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-tab>
        <b-tab title="Permisos">
          <div class="form-group">
            <label>Rol</label>
            <select class="form-control" v-model="edituser.role">
              <option v-for="role in roles" :value="role.id" :key="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <h4>Modulos</h4>
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="p in edituser.permissions"
                :key="p._id"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <span
                      :class="{
                        'text-success': p.value,
                        'text-danger': !p.value,
                      }"
                    >
                      <font-awesome-icon
                        :icon="p.value ? 'check-circle' : 'times-circle'"
                      />
                    </span>
                  </div>
                  <div class="col">
                    <strong>{{ getName(p.name) }}</strong>
                  </div>

                  <div class="col-auto">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="removePermission(p._id)"
                    >
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row">
              <div class="col">
                <select name="" id="" class="form-control" v-model="permission">
                  <optgroup label="Ventas">
                    <option value="pdv">Punto de venta</option>
                    <option value="pdv_dte">Punto de venta - DTE</option>
                    <option value="pdv_user">Punto de venta - Editar usuario</option>
                    <option value="pdv_coupon">Punto de venta - Cupones</option>
                    <option value="ticket_list">Ventas</option>
                    <option value="ticket_excel">Tickets en excel</option>
                    <option value="ticket_list_change_date">
                      Ventas - Cambiar Fecha
                    </option>
                    <option value="quote">Cotizaciones</option>
                    <option value="sii">Facturacion SII</option>
                    <option value="report">Reporte</option>
                  </optgroup>

                  <optgroup label="Mesas">
                    <option value="tables">Mesas</option>
                    <option value="tables_create">Agregar Mesas</option>
                    <option value="tables_edit_qty">Cambiar cantidad</option>
                    <option value="tables_editname">Cambiar nombre</option>
                    <option value="tables_delete_product">
                      Eliminar productos
                    </option>
                    <option value="tables_close">Cerrar mesas</option>
                  </optgroup>

                  <option value="visor">Visor</option>

                  <option value="products">Productos</option>
                  <option value="expenses">Gastos</option>
                  <optgroup label="Web">
                    <option value="web">Webtickets</option>
                    <option value="web_produts">Productos Web</option>
                  </optgroup>
                  <option value="expenses">Gastos</option>
                  <option value="rrss">Redes sociales</option>
                  <option value="users">Usuarios</option>
                  <option value="remote_print">Impresora remota</option>
                  <option value="notifications">Notificaciones</option>
                  <option value="settings">Configuracion</option>
                </select>
              </div>
              <div class="col-4">
                <select name="" id="" class="form-control" v-model="value">
                  <option :value="true">PERMITIR</option>
                  <option :value="false">DENEGAR</option>
                </select>
              </div>
              <div class="col-auto">
                <button class="btn btn-primary" @click="addPermission()">
                  <font-awesome-icon icon="plus" />
                </button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Contraseña" v-if="edituser.role != 'admin'">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Contraseña</label>
                <input
                  class="form-control"
                  v-model="edituser.password"
                  :class="{
                    'is-invalid':
                      !edituser.password || edituser.password.length <= 5,
                    'is-valid':
                      edituser.password && edituser.password.length > 5,
                  }"
                />
                <small class="text-muted">Minimo 6 caracteres</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Repita Contraseña</label>
                <input
                  class="form-control"
                  v-model="edituser.repassword"
                  :class="{
                    'is-invalid':
                      edituser.password != edituser.repassword ||
                      edituser.repassword == '',
                    'is-valid':
                      edituser.password == edituser.repassword &&
                      edituser.repassword != '',
                  }"
                />
                <small
                  class="text-danger"
                  v-if="edituser.password != edituser.repassword"
                  >Las contraseñas deben ser iguales</small
                >
              </div>
            </div>
            <div class="col-12">
              <div class="alert alert-info">
                Dejar en blanco si no se cambiara la contraseña
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import { parseTemplate } from "../utils";

export default {
  name: "Users",
  data() {
    return {
      fakeFilter: "",
      filter: "",
      documentType: "",
      loading: true,
      hideDisabled: true,
      users: [],
      user: {
        name: "",
        email: "",
        role: null,
        repassword: "",
        password: "",
      },
      edituser: {
        name: "",
        email: "",
        role: "",
        repassword: "",
        password: "",
        permissions: [],
      },
      roles: [
        { id: "admin", name: "Administrador" },
        { id: "cash", name: "Cajero" },
        { id: "waiter", name: "Mesero" },
        { id: "driver", name: "Repartidor" },
      ],
      permission: null,
      value: true,
      templates: [],
      content: "",
    };
  },
  mounted() {
    if (!this.hasPermission(this.userLogged, "users"))
      return this.$router.push("/");
    this.load();
    this.loadTemplates();
  },
  computed: {
    userLogged() {
      return this.$store.state.user;
    },
    getUsers() {
      const filter = (user) => {
        let flag = false;
        if (user.active == !this.hideDisabled) return flag;

        if (this.filter === "") return true;

        console.log("filter", this.fakeFilter);
        if (this.filter !== "") {
          if (user.name.toLowerCase().includes(this.filter.toLowerCase()))
            flag = true;
        }
        return flag;
      };

      return this.users.filter(filter);
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    filterUsers() {
      this.filter = this.fakeFilter;
    },
    getName(name) {
      const obj = {
        users: "Usuarios",
        rrss: "Redes sociales",
        settings: "Configuracion",
        expenses: "Gastos",
        products: "Productos",
        pdv_dte: "Punto de venta - DTE",
        pdv: "Punto de venta",
        tables: "Mesas",
        pdv_coupon: "Punto de venta - Cupones",
        tables_editname: "Mesas - Cambiar nombre",
        remote_print: "Impresora remota",
      };

      return obj[name] || name;
    },
    loadTemplates() {
      this.$http
        .get("document/template")
        .then((response) => {
          this.templates = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateDocument() {
      try {
        if (!this.content) return this.$noty.error("Seleccione un documento");
        let user = this.edituser;
        let payload = {
          fecha_de_hoy: this.$moment().format("DD/MM/YYYY"),
          fecha_contrato: this.$moment(user.hireDate).format("DD/MM/YYYY"),
          dia_de_pago: this.info.payDay,
          empresa: {
            nombre: this.info.name,
            rut: this.info.rut,
            direccion: this.info.address,
            comuna: this.info.commune,
            ciudad: this.info.city,
            representante: {
              nombre: this.info.subjectName,
              rut: this.info.subjectRut,
              direccion: this.info.address,
            },
          },
          finiquito: {
            fecha: this.$moment().format("DD/MM/YYYY"),
          },
          empleado: {
            fecha_contrato: this.$moment(user.hireDate).format("DD/MM/YYYY"),
            nombre_completo: user.name,
            rut: user.rut,
            direccion: user.address,
            comuna: user.commune,
            ciudad: user.city,
            sueldo: `$${this.formatNumber(user.baseSalary)}`,
            nombre_del_cargo: user.position,
            area: user.area,
            horas_semanales: user.workHours,
            rol:
              user.workMode == "full_time" ? "Jornada Completa" : "Part Time",
          },
          bonos: [],
        };
        var doc = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "a4",
        });
        let content = parseTemplate(this.content, payload);

        doc.html(
          `
      <div style="width:950px;text-align:justify; font-family: sans-serif">${content}</div>
      `,
          {
            callback: function (doc) {
              self.loading = false;
              doc.output("dataurlnewwindow");
            },
            margin: 10,
            autoPaging: true,
            width: 800,
            html2canvas: {
              scale: 0.2,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    removePermission(id) {
      this.edituser.permissions = this.edituser.permissions.filter(
        (p) => p._id != id
      );
    },
    addPermission() {
      if (this.permission == null)
        return this.$noty.error("Seleccione un permiso");
      if (this.edituser.permissions.includes(this.permission)) return;
      this.edituser.permissions.push({
        name: this.permission,
        value: this.value,
      });
    },
    changeStatus(user) {
      console.log("change user status");
      if (user.active) this.enable(user);
      else this.disable(user);
    },
    nameIsValid(name = "") {
      if (name.length < 3) return true;
      return false;
    },
    emailIsValid(email = "") {
      // eslint-disable-next-line
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(String(email).toLowerCase());
    },
    validPassword(password = "") {
      if (password.length <= 8) return true;
      return false;
    },
    validRole(role = "") {
      if (role !== null) return true;
      return false;
    },
    enable(user) {
      this.$http
        .post(`user/enable`, { id: user._id })
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.$noty.error("No se pudo activar, intente nuevamente");
        });
    },
    disable(user) {
      this.$http
        .delete(`user/${user._id}`)
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.$noty.error("No se pudo desactivar, intente nuevamente");
        });
    },
    updateUser() {
      this.$http
        .patch(`user/${this.edituser._id}`, this.edituser)
        .then((res) => {
          this.edituser = res.data.data;
          delete this.edituser.password;
          // this.edituser = {
          //   name: "",
          //   role: "",
          //   email: "",
          //   password: "",
          //   repassword: "",
          //   rut: "",
          //   permissions: [],
          // };
          this.$noty.success("Usuario editado correctamente");
          this.load();
        })
        .catch(() => {
          this.$noty.error("No se pudo editar el usuario.");
        });
    },
    getUserConnection(user) {
      if (user.lastConnection) {
        return this.$moment(user.lastConnection).fromNow();
      } else {
        return "Nunca";
      }
    },
    load() {
      this.loading = true;
      this.$http
        .get("user")
        .then((res) => {
          this.users = res.data.data;
        })
        .catch(() => {
          this.$noty.error("No se pueden cargar los usuarios.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalUser() {
      this.$bvModal.show("modal-newUser");
    },
    editUser(user) {
      this.$http
        .get(`user/${user._id}`)
        .then((res) => {
          this.edituser = {
            password: "",
            repassword: "",
            rut: "",
            ...res.data.data,
          };
          this.$bvModal.show("modal-edit");
        })
        .catch(() => {
          this.$noty.error("Error al editar usuario<br>Intente nuevamente");
        });
    },
    saveUser() {
      this.loading = true;
      this.$http
        .post("auth/register", this.user)
        .then(() => {
          this.user.name = "";
          this.user.email = "";
          this.user.role = "";
          this.user.password = "";
          this.load();
          this.$noty.success("Usuario guardado exitosamente");
        })
        .catch(() => {
          this.$noty.error("Error al guardar el usuario");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    fakeFilter() {
      if (this.fakeFilter === "") this.filter = "";
    },
  },
};
</script>
