<template>
  <div>
    <div class="row mb-1 align-items-end" v-for="(p, i) in config.printers" :key="`printer-${i}`">
      <div class="col">
        <small class="d-block text-muted">Impresora</small>
        <select class="form-control text-center" v-model="p.printer">
          <option value="1">Por defecto</option>
          <option
            :value="printer.id"
            v-for="printer in printers"
            :key="printer.id"
          >
            {{ printer.name }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <small class="d-block text-muted">Modo</small>
        <select class="form-control text-center" v-model="p.mode">
          <option value="1">Completa</option>
          <option value="2">Simple</option>
          <option value="3">Detalle</option>
          <option value="4">Completa + Detalle</option>
        </select>
      </div>

      <div class="col-2 col-md-2 d-none2">
        <small class="d-block text-muted">Copias</small>
        <select
          v-model.number="p.copies"
          class="form-control form-control-xl text-center"
          style="min-height: 70%"
        >
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="3">3</option>
          <option :value="4">4</option>
          <option :value="5">5</option>
        </select>
      </div>
      <div class="col-1">
        <button class="btn btn-danger btn-block" @click="config.printers.splice(i, 1)">
          <font-awesome-icon icon="trash" />
        </button>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-4">
        <button class="btn btn-info btn-block" @click="addPrinter()">
          <i class="fas fa-times"></i> Agregar otra impresora
        </button>
      </div>
      <div class="col-8">
        <button class="btn btn-success btn-block" @click="print()">
          <i class="fas fa-times"></i> Imprimir
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ipcRenderer } from "../../multi-utils";
export default {
  name: "advancedPrint",
  data() {
    return {
      mode: 1,
      copies: 1,
      ticket: {},
      printer: 1,
      printers: [],
      config: {
        printers: [{
          printer: 1,
          mode: 1,
          copies: 1,
        }],
      },
    };
  },
  methods: {
    printTicket(mode, copies) {
      const ticket = this.ticket;
      this.$emit("printTicket", { ticket, mode, copies });
    },
    addPrinter() {
      this.config.printers.push({
        printer: 1,
        mode: 1,
        copies: 1,
      });
    },
  },
  mounted() {
    // get printers
    if (this.isElectron()) {
      ipcRenderer.invoke("getPrinters").then((arg) => {
        console.log("get printers", arg);
        this.printers = arg;
      });
    } else {
      console.log("no electron");
    }
  },
};
</script>
