<template>
  <div style="font-family: sans-serif">
    <table style="width: 100%">
      <tfoot style="text-align: left">
        <tr>
          <th>
            <strong>PEDIDO PARA {{ ticket.type }}</strong> <br />
            <span class="text-uppercase" v-if="!checkAnon()">
              {{ ticket.clientName }}
            </span>
            <span v-else>- - - -</span>
            <span v-if="ticket.phone">
               {{ `/ ${ticket.phone}`}}
            </span>
          </th>
        </tr>
        <tr v-if="ticket.notes">
          <td colspan="2">
            <strong>Notas</strong>
            <pre
              style="margin: 0px 3px; white-space: pre-wrap"
              >{{ ticket.notes }}</pre
            >
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: ["ticket", "fontSize"],
  methods: {
    checkAnon() {
      if(this.ticket.clientName.toLowerCase() === "anon") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>