<template>
  <div>
    <ul class="list-group rounded-0">
      <li class="list-group-item list-group-item-dark">
        <div class="row no-gutters">
          <div :class="`col-${h.col}`" v-for="h in headers" :key="h.label">
            <small>{{ h.label }}</small>
          </div>
        </div>
      </li>
    </ul>
    <ul class="list-group custom_scroll" style="max-height: 80vh">
      <li class="list-group-item py-1" v-for="d in dataList" :key="d._id">
        <div class="row">
          <div :class="`col-${h.col}`" v-for="h in headers" :key="h.label">
            <small v-if="h.type == 'date'">{{
              d[h.key] | moment("DD/MM")
            }}</small>
            <small v-else>{{ d[h.key] }}</small>
          </div>
        </div>
      </li>
    </ul>

    <b-pagination
      v-model="page"
      :total-rows="content.length"
      :per-page="perPage"
      class="mt-3"
      align="fill"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    content: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 20
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.content.length / this.perPage);
    },
    dataList() {
      return this.content.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    }
  }
};
</script>