<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h4>RESUMEN REGISTRO DE COMPRAS</h4>
        <span>
          En esta sección se muestran los Documentos Tributarios Electrónicos
          (DTE) y no Electrónicos que conforman la Información de Compras
          válida, la cual se utiliza para la determinación impositiva y es
          considerada como el registro oficial del Contribuyente y respaldo de
          su contabilidad.
        </span>
      </div>
    </div>

    <div class="row mt-5" v-show="!$route.query.type">
      <div class="col-12">
        <div class="row mb-2">
          <div class="col-6">
            <h5>Resúmenes por tipo de documento</h5>
          </div>
          <div class="ml-auto col-auto">
            <button class="btn btn-primary" @click="showUpload()">
              SUBIR CSV
            </button>
          </div>
          <div class="ml-auto col-auto">
            <button class="btn btn-primary" @click="addDocument()">
              AGREGAR DOCUMENTO ELECTRONICO NO RECIBIDO
            </button>
          </div>
        </div>
        <ul class="list-group">
          <li class="list-group-item list-group-item-primary font-weight-bold">
            <div class="row no-gutters align-items-center">
              <div class="col-3">
                <small>Tipo Documento</small>
              </div>
              <div class="col-1 text-center">
                <small>Total Docs</small>
              </div>
              <div class="col-1 text-right">
                <small>Total Exento</small>
              </div>
              <div class="col-1 text-right">
                <small>Monto Neto</small>
              </div>
              <div class="col-1 text-right">
                <small> IVA <br />Recuperable</small>
              </div>
              <div class="col-1 text-right">
                <small>IVA <br />Uso Comun</small>
              </div>
              <div class="col-1 text-right">
                <small>IVA No Recuperable</small>
              </div>
              <div class="col text-right">
                <small class="font-weight-bold">Monto Total</small>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="books.length == 0">
            <h4 class="py-5 text-center">No tienes informacion</h4>
          </li>
          <li
            class="list-group-item list-group-item-action click"
            v-for="book in books"
            @click="getBook(book._id)"
            :key="book._id"
          >
            <div class="row no-gutters">
              <div class="col-3">
                {{ getDTEName(book._id) }} ({{ book._id }})
              </div>
              <div class="col-1 text-center">
                {{ book.docs.length }}
              </div>
              <div class="col-1 text-right">
                ${{ book.exento | formatNumber }}
              </div>
              <div class="col-1 text-right">
                ${{ book.neto | formatNumber }}
              </div>
              <div class="col-1 text-right">${{ book.iva | formatNumber }}</div>
              <div class="col-1 text-right">
                ${{ book.ivaUsoComun | formatNumber }}
              </div>
              <div class="col-1 text-right">
                ${{ book.ivaNoRec | formatNumber }}
              </div>
              <div class="col text-right">${{ book.total | formatNumber }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-5" v-show="$route.query.type">
      <div class="col-2">
        <button class="btn btn-primary btn-block btn-sm" @click="clearBook()">
          VOLVER
        </button>
      </div>
       <div class="col-2">
        <button class="btn btn-success btn-block btn-sm" @click="loadBook()">
          ACTUALIZAR
        </button>
      </div>
      <div class="col-12 mt-2">
        <h5>Registro</h5>
        <h5>
          Tipo de documento: {{ getDTEName($route.query.type) }} ({{
            $route.query.type
          }})
        </h5>
        <strong>Total documentos: {{ book.docs.length }}</strong>
      </div>
      <div class="col-12">
        <b-overlay :show="loading || loadingBooks">
          <ul class="list-group">
            <li class="list-group-item list-group-item-dark d-none">
              <div class="row align-items-center">
                <div class="col-1">
                  <strong>Tipo compra</strong>
                </div>
                <div class="col-2">
                  <strong>RUT Proveedor</strong>
                </div>
                <div class="col text-right">
                  <strong>Folio</strong>
                </div>
                <div class="col-1">
                  <strong>Fecha Doc</strong>
                </div>
                <div class="col">
                  <strong>Fecha Recepcion</strong>
                </div>
                <div class="col">
                  <strong>Monto Neto</strong>
                </div>
                <div class="col">
                  <strong>IVA Recuperable</strong>
                </div>
                <div class="col">
                  <strong>Otros Impuestos</strong>
                </div>
                <div class="col">
                  <strong>IVA No Recuperable</strong>
                </div>
                <div class="col">
                  <strong>Monto Total</strong>
                </div>
              </div>
            </li>
            <li
              class="list-group-item list-group-item-action"
              v-for="(doc, i) in book.docs"
              :key="doc"
              :class="{
                'list-group-item-warning': doc.status == '1received',
              }"
            >
              <div class="row align-items-center">
                <div class="col-auto">
                  <small>TIPO</small>
                  <div class="w-100"></div>
                  <small class="text-uppercase">{{ getTransactionName(doc.tpoTranCompra) }}</small>
                </div>
                <div class="col text-center">
                  <small>FOLIO</small>
                  <div class="w-100"></div>
                  <span class="click text-primary font-weight-bold">{{
                    doc.folio
                  }}</span>
                </div>
                <div class="col-4 px-0">
                  <small
                    :id="`rut-${doc.rutEmisor}-${i}`"
                    class="click text-primary"
                    >{{ doc.rutEmisor }}</small
                  >
                  <div class="w-100"></div>
                  <strong>{{ doc.rznSoc || "No encontrado" }}</strong>
                  <!-- </b-tooltip> -->
                </div>

                <div class="col-1 border-left">
                  <small>FECHA</small>
                  <div class="w-100"></div>
                  <small>{{ doc.fchEmis | moment("DD/MM/YY") }}</small>
                </div>
                <div class="col border-left">
                  <small>RECIBIDO</small>
                  <div class="w-100"></div>
                  <small>{{ doc.createdAt | moment("DD/MM/YY") }}</small>
                </div>
                <div class="col border-left">
                  <small>ESTADO</small>
                  <div class="w-100"></div>
                  <span
                    :class="{
                      'badge-info': doc.status == 'received',
                      'badge-success': doc.status == 'accepted',
                    }"
                    class="badge px-4 p-2 text-uppercase"
                    >{{ getStatus(doc.status) }}
                    <small>{{doc.acuse}}</small>
                    </span
                  >
                </div>
                <div class="col">
                  <button
                    class="btn btn-sm btn-primary btn-block btn-sm"
                    @click="viewDocument(doc._id)"
                  >
                    <small>VER</small>
                  </button>
                  <div class="btn-group btn-block" v-if="doc.fmaPago != 1">
                    <button
                      class="btn btn-sm btn-outline-success btn-sm"
                      
                      @click="acceptDoc(doc, 'ACD')"
                      :id="`btn-accept-${doc.rutEmisor}-${i}`"
                    >
                      <small>ACD</small>
                    </button>
                    <b-tooltip
                      :target="`btn-accept-${doc.rutEmisor}-${i}`"
                      title="ACEPTAR DOCUMENTO"
                      placement="top"
                    ></b-tooltip>
                    <button
                      class="btn btn-sm btn-outline-success btn-sm"
                     
                      @click="acceptDoc(doc, 'ERM')"
                      :id="`btn-accept2-${doc.rutEmisor}-${i}`"
                    >
                      <small>ERM</small>
                    </button>
                    <b-tooltip
                      :target="`btn-accept2-${doc.rutEmisor}-${i}`"
                      title="ACUSE RECIBO MERCADERIAS"
                      placement="top"
                    ></b-tooltip>
                  </div>
                </div>
                <div class="w-100 mt-2 border border-bottom"></div>
              </div>
              <div class="row py-2">
                <div class="col"></div>
                <div class="col text-right">
                  <small>FORMA DE PAGO</small>
                  <div class="w-100"></div>
                  <strong>{{ getFmaPago(doc.fmaPago) }}</strong>
                </div>
                <div class="col text-right border-left">
                  <small>MONTO NETO</small>
                  <div class="w-100"></div>
                  <span>${{ doc.mntNeto | formatNumber }}</span>
                </div>
                <div class="col text-right border-left">
                  <small>MONTO IVA</small>
                  <div class="w-100"></div>
                  ${{ doc.mntIva | formatNumber }}
                </div>
                <div class="col text-right border-left">
                  <small>MONTO EXENTO</small>
                  <div class="w-100"></div>
                  ${{ doc.mntExe | formatNumber }}
                </div>
                <div class="col text-right border-left">
                  <small>OTROS IMP</small>
                  <div class="w-100"></div>
                  ${{ doc.otrosImp || 0 }}
                </div>
                <div class="col text-right border-left bglight">
                  <small>MONTO TOTAL</small>
                  <div class="w-100"></div>
                  <strong class="">${{ doc.mntTotal | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row text-right">
                <div class="col-6 mx-4"></div>
                <div class="col font-weight-bold">
                  ${{ book.neto | formatNumber }}
                </div>
                <div class="col font-weight-bold">
                  ${{ book.iva | formatNumber }}
                </div>
                <div class="col font-weight-bold">${{ 0 | formatNumber }}</div>
                <div class="col font-weight-bold">${{ 0 | formatNumber }}</div>
                <div class="col font-weight-bold">
                  ${{ book.total | formatNumber }}
                </div>
              </div>
            </li>
          </ul>
        </b-overlay>
      </div>
    </div>

    <b-modal
      title="Agregar documento electronico no recibido"
      ref="addDocumentModal"
      size="xl"
      @ok="saveDocument()"
    >
      <div class="row">
        <div class="col-12">
          <h4>DOCUMENTO EN DETALLE DE COMPRA</h4>
          <p>
            En esta página un usuario autorizado puede ingresar/revisar el
            documento seleccionado, que está asignado a un Detalle de COMPRA. El
            usuario puede agregar documentos de COMPRA manuales para
            complementar la información.
          </p>
        </div>

        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">RUT EMPRESA</label>
            <input
              type="text"
              class="form-control"
              :value="info.rut"
              disabled
            />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">OPERACION</label>
            <input type="text" class="form-control" value="COMPRA" disabled />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">PERIODO</label>
            <input
              type="text"
              class="form-control"
              v-model="document.book"
              disabled
            />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Fecha de emision</label>
            <input
              type="date"
              class="form-control"
              v-model="document.fchEmis"
              :class="{
                'is-invalid': document.fchEmis === null,
                'is-valid': document.fchEmis !== null,
              }"
            />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Tipo de documento</label>
            <select
              class="form-control"
              v-model="document.dteType"
              :class="{
                'is-invalid': document.dteType === 0,
                'is-valid': document.dteType !== 0,
              }"
            >
              <option
                :key="key"
                :value="key"
                v-for="(dte, key) in getDTEList()"
              >
                {{ key }} - {{ dte }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Folio</label>
            <input
              type="text"
              class="form-control"
              @focus="$event.target.select()"
              v-model="document.folio"
              :class="{
                'is-invalid': document.folio === 0,
                'is-valid': document.folio !== 0,
              }"
            />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Rut Contraparte Doc</label>
            <input
              type="text"
              class="form-control"
              @focus="$event.target.select()"
              v-model="document.rutEmisor"
              @blur="getInfoRut()"
              :class="{
                'is-invalid':
                  document.rutEmisor === null || document.rutEmisor.length <= 8,
                'is-valid':
                  document.rutEmisor !== null && document.rutEmisor.length > 8,
              }"
            />
            <small>Sin puntos. EJ: 60321654-1</small>
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Razon social</label>
            <input type="text" class="form-control" v-model="document.rznSoc" />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Monto exento</label>
            <input type="text" class="form-control" v-model="document.mntExe" />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Monto neto</label>
            <input
              type="text"
              class="form-control"
              v-model="document.mntNeto"
            />
          </div>
        </div>
        <div class="col-md-3 mt-3">
          <div class="form-group">
            <label for="" class="font-weight-bold">Monto IVA</label>
            <input type="text" class="form-control" v-model="document.mntIva" />
          </div>
        </div>
        <div class="col-12 mt-3">
          <b-button v-b-toggle.collapse-2 block variant="primary" class="m-1"
            >Otros datos documento</b-button
          >
          <b-collapse id="collapse-2" class="border p-3 bg-light">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="" class="font-weight-bold">Tipo de compra</label>
                  <select class="form-control" v-model="document.tpoTranCompra">
                    <option value="1">Del giro</option>
                    <option value="2">Supermercados</option>
                    <option value="3">Bienes raices</option>
                    <option value="4">Activo fijo</option>
                    <option value="5">IVA uso comun</option>
                    <option value="6">IVA no recuperable</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Tipo de impuesto</label
                  >
                  <select class="form-control" disabled>
                    <option value="">IVA</option>
                    <option value="">Ley 18.211</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="" class="font-weight-bold">Numero interno</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="document.numeroInterno"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Cod sucursal SII</label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="" class="font-weight-bold">Nacionalidad</label>
                  <input type="text" class="form-control" disabled />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="col-12 mt-3">
          <b-button v-b-toggle.collapse-3 block variant="primary" class="m-1"
            >Otros montos</b-button
          >
          <b-collapse id="collapse-3" class="border p-3 bg-light">
            <div class="row">
              <div class="col-md">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Monto IVA uso comun</label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Monto Activo fijo</label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Monto IVA Activo Fijo</label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="w-100"></div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Monto Otro Impuesto Sin Crédito</label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="" class="font-weight-bold"
                    >Tabacos - Elaborados</label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-modal>

    <b-modal ref="viewDocument" size="xl">
      <div class="row">
        <div class="col-4 ml-auto d-none">
          <button @click="getEmisorData()">Actualizar datos emisor</button>
        </div>
        <div class="w-100"></div>
        <div class="col">
          <strong>RUT EMISOR:</strong> {{ document.rutEmisor | formatRut }}
          <div class="w-100"></div>
          <strong>RAZON SOCIAL:</strong> {{ document.rznSoc }}
          <div class="w-100"></div>
          <strong>GIRO:</strong> {{ document.giroEmis }}
          <div class="w-100"></div>
          <strong>DIRECCION:</strong> {{ document.dirOrigen }}
          <div class="w-100"></div>
          <strong>FECHA:</strong> {{ document.fchEmis }}
        </div>

        <div class="col-5 text-center">
          <div
            class="border border-dark py-3 text-uppercase"
            style="border-width: 3px !important"
          >
            <strong
              >{{ getDTEName(document.dteType) }} ({{
                document.dteType
              }})</strong
            >
            <br />
            <strong>N°</strong> {{ document.folio }}
          </div>
          S.I.I {{ document.ciudadOrigen }}
        </div>

        <div class="w-100 py-2"></div>

        <div class="col">
          <h5>Detalles</h5>

          <div class="w-100"></div>

          <ul class="list-group">
            <li
              class="list-group-item list-group-item-action"
              v-for="d in document.detalles"
              :key="d.nroLinDet"
            >
              <div class="row align-items-center">
                <div class="col-1 text-center">
                  {{ d.qtyItem }}
                </div>
                <div class="col font-weight-bold">
                  {{ d.nmbItem | decode }}
                  <div class="w-100"></div>
                  <small>{{d.dscItem}}</small>
                </div>
                <div class="ml-auto col-2 text-right">
                  ${{ d.prcItem | formatNumber }}
                </div>
                <div class="ml-auto col-2 text-right">
                  <strong>${{ d.montoItem | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-2 text-right ml-auto">
                  <strong>NETO</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntNeto | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row" v-if="document.mntExe">
                <div class="col-2 text-right ml-auto">
                  <strong>EXENTO</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntExe | formatNumber }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-2 text-right ml-auto">
                  <strong>IVA (19%)</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntIva | formatNumber }}</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-2 text-right ml-auto">
                  <strong>TOTAL</strong>
                </div>
                <div class="col-2 text-right">
                  <strong>${{ document.mntTotal | formatNumber }}</strong>
                </div>
              </div>
            </li>
          </ul>

          <div class="col-4 mt-3 text-center">
            <img
              :src="`https://api.ticketapp.cl/ted?bcid=pdf417&text=${TEDURL}&height=45`"
              alt="TED"
              style="width: 100%; margin: 0 auto; display: block"
            />
            <small class="text-center">TIMBRE ELECTRONICO SII</small>
          </div>
        </div>
        <div class="col-12 my-4 overflow-scroll" v-if="document.xml">
          <b-button v-b-toggle.collapse-3 block variant="primary" class="m-1"
            >VER XML</b-button
          >
          <b-collapse id="collapse-3">
            <pre class="text-secondary border p-2 bg-light">{{
              document.xml
            }}</pre>
          </b-collapse>
        </div>
      </div>
    </b-modal>

    <b-modal ref="upload" title="Subir CSV">
      <div class="row">
        <div class="col">
          <b-form-file
            v-model="file"
            accept=".csv"
            placeholder="Seleccione un archivo CSV"
            drop-placeholder="Arrastre el archivo CSV aquí..."
          ></b-form-file>

          <div class="w-100 py-2"></div>

          <select
            class="form-control"
            v-model="dteType"
            :class="{
              'is-invalid': dteType === 0,
              'is-valid': dteType !== 0,
            }"
          >
            <option :key="key" :value="key" v-for="(dte, key) in getDTEList()">
              {{ key }} - {{ dte }}
            </option>
          </select>
          <div class="w-100 py-2"></div>

          <b-button
            variant="primary"
            class="mt-2"
            @click="upload"
            :disabled="!file"
          >
            <b-spinner small v-if="loading"></b-spinner>
            <span v-else>Subir</span>
          </b-button>
        </div>

        <div class="col-12 mt-4"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getDTEName, getDTEList, getTransactionName } from "../../utils";
export default {
  name: "SiiCompras",
  watch: {
    "$route.query.type": {
      handler: function (val) {
        this.loadBook();
      },
      immediate: true,
    },
    file() {
      const { name } = this.file;
      const rut = name.split("_")[1];
      const book = name.split("_")[2].split(".")[0];
      const dteType = name.split("_")[3].substr(0, 2);
      console.log({
        rut,
        book,
        dteType,
      });
    },
  },
  data() {
    return {
      loadingBooks: false,
      dteType: 0,
      loading: false,
      file: null,
      document: {
        fchEmis: null,
        dteType: 0,
        book: this.$moment().format("YYYYMM"),
        folio: 0,
        rutEmisor: null,
        rznSoc: null,
        mntExe: 0,
        mntNeto: 0,
        mntIva: 0,
        tpoTranCompra: 1,
        numeroInterno: "",
      },
      book: {
        docs: [],
      },
      books: [
        // {
        //   _id: 1,
        //   docType: 39,
        //   docs: [1, 2, 3, 4, 5, 6],
        //   exento: 0,
        //   neto: 3831085,
        //   IVA: 727910,
        //   IVARec: 0,
        //   IVAComun: 0,
        //   total: 4565271,
        // },
        // {
        //   _id: 1,
        //   docType: 33,
        //   docs: [1, 2, 3],
        //   exento: 0,
        //   neto: 383108,
        //   IVA: 72791,
        //   IVARec: 0,
        //   IVAComun: 0,
        //   total: 456527,
        // },
      ],
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    TEDURL() {
      let xml = this.document.xml;
      if (!xml) return;
      let [TED] = xml.match(/<TED(.|\s)*?<\/TED>/g);
      TED = TED.replace(/[\n\r\s]/g, "");
      console.log({ TED });
      return encodeURIComponent(TED);
    },
  },
  methods: {
    getFmaPago(fmaPago) {
      const obj = {
        1: "Contado",
        2: "Crédito",
        3: "Sin costo",
      };
      return obj[fmaPago] || fmaPago;
    },
    btnState(doc) {
      if(doc.status == 'accepted') return true
      if (doc.fmaPago != 2) return true;
      return this.diff(doc.createdAt) > 8;
    },
    getStatus(status) {
      const obj = {
        received: "Recibido",
        accepted: "Aceptado",
        rejected: "Rechazado",
      };
      return obj[status] || status;
    },
    acceptDoc(doc, accion) {
      let { rutEmisor, folio, dteType } = doc;

      const [rut, dv] = rutEmisor.split("-");

      //return console.log(doc)
      this.$http
        .post(`sii/webservice`, {
          rutEmisor: rut,
          dvEmisor: dv,
          folio,
          tipoDoc: dteType,
          accionDoc: accion,
        })
        .then(() => {
          this.loadBook();
        });
    },
    diff(date) {
      let d = this.$moment().diff(date, "days");
      console.log({ d });
      return d;
    },
    replyDocument(id) {
      this.$http
        .post(`sii/document/${id}/reply`)
        .then(() => {
          this.viewDocument(id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBook(id) {
      this.$router.push({
        query: {
          ...this.$route.query,
          type: id,
        },
      });
    },
    getEmisorData() {
      this.$http
        .get(`siidata?rut=${this.document.rutEmisor}`)
        .then((response) => {
          return this.$http.post(`sii/document/${this.document._id}`, {
            rznSoc: response.data.data.rs,
            giroEmis: response.data.data.activities[0].name,
          });
        })
        .then(() => {
          this.viewDocument(this.document._id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    upload() {
      const file = this.file;
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = async () => {
        const csv = reader.result;
        const lines = csv.split("\n");
        const result = [];
        const headers = lines[0].split(";");

        console.log(lines);

        //let docs = lines.shift();

        for (let line of lines) {
          const obj = {};
          const currentline = line.split(";");

          for (let i = 0; i < headers.length; i++) {
            obj[headers[i]] = currentline[i];
          }
          result.push(obj);
        }

        console.log(result);

        let newDocs = result.map((doc) => {
          return {
            fchEmis: this.$moment(doc["Fecha Docto."], "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            ),
            dteType: this.dteType,
            book: this.$moment().format("YYYYMM"),
            folio: doc["Folio"],
            rutEmisor: doc["RUT Emisor"],
            rznSoc: doc["Razon Social"],
            mntExe: doc["Monto Exento"],
            mntNeto: doc["Monto Neto"],
            mntIva: doc["Monto IVA"],
            tpoTranCompra: 1,
            numeroInterno: "",
          };
          //this.saveDocument();
        });

        console.log(newDocs);

        for (let doc of newDocs) {
          console.log(doc);
          if (doc.mntNeto) {
            let { data } = this.$http.get(
              `siidata?rut=${this.document.rutEmisor}`
            );
            if (data) {
              doc.rznSoc = data.data.rs;
              doc.giroEmis = data.data.activities[0].name;
            }
            await this.$http.post("sii/document", doc).then(() => {
              console.log("Guardado");
            });
          }
        }
      };
    },
    showUpload() {
      this.$refs["upload"].show();
    },
    viewDocument(id) {
      this.loading = true;
      this.$http
        .get(`sii/document/${id}`)
        .then((res) => {
          this.document = res.data.data;
          this.$refs["viewDocument"].show();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInfoRut() {
      this.$http
        .get(`siidata?rut=${this.document.rutEmisor}`)
        .then((response) => {
          console.log(response.data.data);
          const { rs } = response.data.data;
          this.document.rznSoc = rs;
        });
    },
    saveDocument() {
      this.$http
        .post("sii/document", this.document)
        .then(() => {
          this.$noty.success("Libro guardado");
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al guardar libro");
        });
    },
    addDocument() {
      this.$refs["addDocumentModal"].show();
    },
    clearBook() {
      this.$router.push({
        query: { ...this.$route.query, type: "" },
      });
    },
    loadBook() {
      let { date } = this.$route.query;
      this.loadingBooks = true;
      let type = this.$route.query.type;
      if (!type) return;
      this.$http
        .get(`sii/book/${type}?date=${date}`)
        .then((res) => {
          this.book.docs = res.data.data;
          this.$router.push({
            query: { ...this.$route.query, type: book._id },
          });
        })
        .finally(() => {
          this.loadingBooks = false;
        });
    },
    load() {
      let { date } = this.$route.query;
      this.$http
        .get(`sii/book?date=${date}`)
        .then((res) => {
          this.books = res.data.data;
        })
        .catch(() => {
          this.$noty.error("Error al cargar libro");
        });
    },
    getDTEName,
    getDTEList,
    getTransactionName,
  },
};
</script>