<template>
  <div>
    <date-picker v-model="date" valueType="format" />
    <b-table striped hover :items="reserves" :fields="fields">
      <template #empty="scope">
        <h4>{{ scope.emptyText }}</h4>
      </template>
    </b-table>
  </div>
</template>

<script>
import dataTable from "../../components/data-table.vue";

export default {
  components: {
    dataTable,
  },
  mounted() {
    //this.load();
    this.date = this.$moment().format("YYYY-MM-DD");
  },
  watch: {
    date() {
      this.load();
    },
  },
  methods: {
    load() {
      this.$http.get(`table/reserves?date=${this.date}`).then((response) => {
        this.reserves = response.data.data;
      });
    },
  },
  data() {
    return {
      date: null,
      reserves: [],
      fields: [
        {
          key: "table.name",
          label: "Mesa",
        },
        {
          key: "table.location",
          label: "Piso",
        },
        {
          key: "date",
          label: "Hora",
          formatter: (val) => this.$moment(val).format("HH:mm"),
        },
        {
          key: "name",
          label: "Nombre",
        },
        {
          key: "phone",
          label: "Teléfono",
        },
        {
          key: "details",
          label: "Detalles",
        },
      ],
    };
  },
};
</script>