<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <input type="text" class="form-control" placeholder="Buscar" v-model="filter" />
      </div>
      <div class="col">
        <select class="form-control">
          <option value="">Todos</option>
          <option value="">Hoy</option>
          <option value="">Ayer</option>
          <option value="">Esta semana</option>
          <option value="">Este mes</option>
          <option value="">Este año</option>
        </select>
      </div>
      <div class="col">
        <select class="form-control">
          <option value="">Todos</option>
          <option value="">Administrador</option>
          <option value="">Usuario</option>
        </select>
      </div>
      <div class="col">
        <button class="btn btn-primary" @click="load()">Buscar</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="list-group-item">
      <li class="list-group-item list-group-item-action" v-for="log in logins" :key="log._id">
        <div class="row align-items-center">
          <div class="col-1 text-center">
            <small class="font-weight-bold">{{ log.createdAt | moment("DD/MM/YY") }}</small>
            <div class="w-100"></div>
            <small class="text-muted">{{ log.createdAt | moment("HH:mm") }}</small>
          </div>
          <div class="col-4 text-uppercase">
            <small class="text-muted">{{ log.business.name }}</small>
            <div class="w-100"></div>
            <span class="font-weight-bold">{{ log.user.name }}</span>
            <div class="w-100"></div>
            <small>{{log.user.email}}</small>
          </div>
          <div class="col-2 px-0 text-center">
            <small class="ml-3">USER ID: {{log.user._id}}</small>
            <div class="w-100"></div>
            <small class="ml-3">IP: {{log.remoteAddress}}</small>
          </div>
          <div class="col-2 px-0 text-center">
            <small class="ml-3">{{log.details}}</small>
          </div>
          <div class="col">
            {{log.info}}
          </div>
          <div class="col" v-if="log.info && log.info.length > 3">
            <small class=""
              ><strong>{{ getInfo(log.info).cpus }}</strong></small
            >
            <small class=""> x {{ getInfo(log.info).cpuName }}</small>
            <div class="w-100"></div>
            <small class="mr-2"
              ><strong>RAM:</strong>
              {{
                parseFloat(getInfo(log.info).totalMemory / 1000000000).toFixed(
                  2
                )
              }}
              | {{ getInfo(log.info).totalMemory }}</small
            >
          </div>
        </div>
      </li>
    </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: '',
      logins: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      
    },
    getInfo(info = '') {
      if (!info) return "no info";
      else {
        let i = JSON.parse(info);
        if (!i) return {};
        return {
          cpus: i.cpus.length,
          cpuName: i.cpus[0].model,
          totalMemory: i.totalmem,
          freeMemory: i.freemem,
        };
      }
    },
    load() {
      this.$http.get(`logins?filter=${this.filter}`).then((res) => {
        this.logins = res.data.data;
      });
    },
  },
};
</script>