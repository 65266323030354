<template>
  <div class="container">
    <div class="row py-2">
      <div class="col">
        <h2>Calificaciones {{ ratings.length }}</h2>
      </div>
      <div class="col-auto ml-auto">
        <button @click="getRatings()" class="btn btn-primary">
          <font-awesome-icon :icon="['fas', 'sync-alt']" />
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="rating in ratings"
            :key="rating._id"
          >
            <div class="row align-items-center">
              <div class="col-auto text-center">
                <font-awesome-icon
                  :icon="['fas', 'star']"
                  class="text-warning"
                />
                {{ rating.rating.note }}
                <div class="w-100"></div>
                <small> #{{ rating.number }}</small>
              </div>
              <div class="col">
                <small>{{ rating.business.name }}</small>
                <div class="w-100"></div>
                <strong>{{ rating.clientName }} - {{ rating.email }}</strong>
                <div class="w-100"></div>
                <small class="font-italic">{{ rating.rating.comment }}</small>
              </div>
              <div class="col-5 d-none1">
                <small class="d-block py-1 text-uppercase" v-for="r in rating.events" :key="r._id">
                  <strong class="mr-2">{{
                    r.createdAt | moment("DD/MM/YY HH:mm")
                  }}</strong>
                  {{ r.value }}
                </small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ratings: [],
      loading: true,
    };
  },
  mounted() {
    this.getRatings();
  },
  methods: {
    async getRatings() {
      this.loading = true;
      const { data } = await this.$http.get("admin/rating");
      this.ratings = data.data;
      this.loading = false;
    },
  },
};
</script>