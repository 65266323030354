<template>
  <div class="container-fluid">
    <b-overlay :show="loading" class="h-100">
      <div class="row mb-3 d-none1">
        <!-- <div class="col">
          <button class="btn btn-block btn-outline-primary" @click="add()">
            Agregar
          </button>
        </div> -->
        <div class="col">
          <button
            class="btn btn-block btn-outline-primary"
            @click="loadPosts()"
          >
            Actualizar
          </button>
        </div>
      </div>
      <div class="row align-items-center mt-2">
        <div class="col">
          <button class="btn btn-block btn-outline-info" @click="subsDate()">
            Atras
          </button>
        </div>
        <div class="col-8">
          <h2 class="text-uppercase text-center py-4">
            <strong>{{ date | moment("MMMM YYYY") }}</strong>
          </h2>
        </div>

        <div class="col">
          <button class="btn btn-block btn-outline-info" @click="addDate()">
            Siguiente
          </button>
        </div>
      </div>

      <div class="row pt-0 text-center bgwhite font-weight-bold text-uppercase">
        <div class="col-12" v-for="(week, i) in calendar" :key="i">
          <div class="row">
            <div
              class="col py-2 px-0 border text-right"
              style="min-height: 150px; border-color: #cacaca"
              v-for="(day, j) in week.days"
              :key="j"
              :class="{
                'border-danger bglight':
                  day.date.format('DD/MM/YY') == $moment().format('DD/MM/YY'),
                'bg-secondary border-secondary text-light':
                  day.date.format('MM') != date.format('MM'),
                'bglight border-white':
                  day.date.format('MM') == date.format('MM') &&
                  day.date.format('DD/MM/YY') != $moment().format('DD/MM/YY'),
              }"
            >
              <div class="row no-gutters click" @click="addDay(day.date)">
                <div class="col-1 px-2">
                  <span
                    class="badge"
                    :class="{
                      'd-none': day.date.format('MM') != date.format('MM'),
                      'badge-danger': day.events.length > 0,
                      'badge-info': day.events.length == 0,
                    }"
                  >
                    {{ day.events.length }}
                  </span>
                </div>
                <div class="col px-2">
                  <small
                    :class="{
                      'text-danger font-weight-bold':
                        $moment(day.date).isoWeekday() == 7,
                      'text-muted': $moment(day.date).isoWeekday() != 7,
                      'd-none': day.date.format('MM') != date.format('MM'),
                    }"
                    >{{ day.date | moment("ddd") }}</small
                  >
                  <span style="font-size: 20px" class="font-weight-bold">
                    {{ day.date | moment("DD") }}
                  </span>
                </div>
              </div>
              <div class="w-100 mt-1"></div>
              <div class="row no-gutters">
                <div class="col px-0">
                  <span
                    class="badge click mr-1"
                    :class="{
                      'badge-warning': event.status == 'pending',
                      'badge-success': event.status == 'published',
                      'badge-danger': event.attempts == 3,
                      'd-none': day.date.format('MM') != date.format('MM'),
                    }"
                    v-for="event in day.events"
                    :key="event._id"
                    @click="view(event._id)"
                  >
                    <div
                      style="width: 50px; height: 50px; margin: auto"
                      v-if="event.pictureUrl"
                      :style="{
                        'background-image': `url(${event.pictureUrl})`,
                        'background-size': 'cover',
                      }"
                    ></div>
                    <small class="mt-2 d-block font-weight-bold">{{
                      event.post_type
                    }}</small>
                    <small class="d-block pt-1" v-if="event.toDate">{{
                      event.toDate | moment("HH:mm")
                    }}</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal ref="modal" size="xl" hide-footer>
      <div class="row">
        <div class="col-6">
          <small>VISTA PREVIA</small>
          <div
            class="img-fluid"
            :style="{
              height: '430px',
              'max-width': '550px',
              width: '100%',
              'background-color': '#ccc',
              border: '1px solid #ccc',
              background: `url(${link}) center center / contain no-repeat`,
            }"
          />
          <button @click="addFiles" class="btn btn-primary btn-block mt-3">
            SELECCIONAR
          </button>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <small>PAGINA</small>
              <select class="form-control" v-model="page_id">
                <option value="">Selecciona</option>
                <option
                  :value="page.fb_id"
                  v-for="page in pages"
                  :key="page._id"
                >
                  {{ page.name }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <small>TIPO DE PUBLICACION</small>
              <select class="form-control" v-model="post_type">
                <option value="fb_post">Facebook Post</option>
                <option value="fb_story">Facebook Story</option>
                <option value="ig_story">Instagram Story</option>
                <option value="ig_post">Instagram Post</option>
              </select>
            </div>
            <div class="col-12">
              <small>CONTENIDO</small>
              <textarea
                class="form-control"
                rows="5"
                v-model="content"
              ></textarea>
            </div>
            <div class="col-6">
              <small>FECHA</small>
              <date-picker
                valueType="format"
                v-model="datePost"
                :disabled-date="minDate"
                type="date"
                input-class=" form-control"
              />
            </div>
            <div class="col-6">
              <small>HORA</small>
              <date-picker
                valueType="format"
                v-model="timePost"
                :time-picker-options="{
                  start: '00:00',
                  step: '00:10',
                  end: '23:30',
                  format: 'HH:mm',
                }"
                :disabled-date="minDate"
                type="time"
                input-class=" form-control"
              />
            </div>
            <div class="col-12 mt-3">
              <div class="row mb-2" v-if="editPost._id">
                <div class="col">
                  <button
                    @click="copy()"
                    class="btn btn-warning btn-lg py-2 btn-block"
                  >
                    Copiar
                  </button>
                </div>
                <div class="col">
                  <button
                    @click="remove()"
                    :disabled1="editPost.status !== 'pending'"
                    class="btn btn-danger btn-lg py-2 btn-block"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
              <button
                @click="update()"
                v-if="editPost._id"
                :disabled="editPost.status !== 'pending'"
                class="btn btn-success btn-lg py-3 btn-block"
              >
                Actualizar
              </button>
              <button
                v-else
                @click="publish()"
                class="btn btn-success btn-lg py-3 btn-block"
              >
                Publicar
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2"></div>
      </div>
    </b-modal>

    <b-modal ref="modal-files" size="xl" hide-footer>
      <files :modal="true" @selected="setLink" />
    </b-modal>
  </div>
</template>

<script>
import files from "./files.vue";
export default {
  props: {
    page: {
      type: String,
      default: "",
    },
  },
  components: {
    files,
  },
  mounted() {
    this.date = this.$moment();
    // this.checkOT();
    this.loadCalendar();
    this.loadPages();
    this.datePost = this.$moment().format("YYYY-MM-DD");
  },
  data() {
    return {
      link: "",
      pages: [],
      calendar: [],
      date: Date.now(),
      startDay: null,
      endDay: null,
      pending: [],
      loading: false,
      datePost: null,
      timePost: null,
      content: "",
      post_type: "fb_post",
      page_id: "",
      posts: [],
      editPost: {},
    };
  },
  methods: {
    addDay(date) {
      this.datePost = this.$moment(date).format("YYYY-MM-DD");
      this.$refs.modal.show();
    },
    remove() {
      this.$http.delete(`meta/post/${this.editPost._id}`).then((res) => {
        this.$refs.modal.hide();
        this.$noty.success("Publicacion eliminada");
        this.loadPosts();
        this.editPost = {};
      });
    },
    copy() {
      this.editPost = {};
    },
    update() {
      this.$http
        .put(`meta/post/${this.editPost._id}`, {
          page_id: this.page_id,
          post_type: this.post_type,
          content: this.content,
          date: this.datePost,
          time: this.timePost,
          pictureUrl: this.link,
          //target: this.target,
        })
        .then((res) => {
          this.$refs.modal.hide();
          this.$noty.success("Publicacion actualizada");
          this.loadPosts();
          this.editPost = {};
        });
    },
    publish() {
      this.$http
        .post(`meta/post`, {
          page_id: this.page_id,
          post_type: this.post_type,
          content: this.content,
          date: this.datePost,
          time: this.timePost,
          pictureUrl: this.link,
          //target: this.target,
        })
        .then((res) => {
          //this.$refs.modal.hide();
          this.$noty.success("Publicacion creada");
          this.loadPosts();
        });
    },
    minDate(date) {
      return this.$moment().diff(date, "days") > 0;
    },
    setLink(link) {
      this.link = link;
      this.$refs["modal-files"].hide();
    },
    loadPages() {
      this.$http.get(`auth/facebook/me`).then((res) => {
        this.pages = res.data.data;
      });
    },
    addFiles() {
      this.$refs["modal-files"].show();
    },
    add() {
      this.editPost = {};
      this.$refs.modal.show();
      this.loadPages();
    },
    subsDate() {
      this.date = this.$moment(this.date).subtract(1, "month");
    },
    addDate() {
      this.date = this.$moment(this.date).add(1, "month");
    },
    getEvents() {
      return [];
    },
    view(id) {
      this.loading = true;
      this.$http
        .get(`meta/post/${id}`)
        .then((res) => {
          const post = res.data.data;
          this.editPost = post;
          this.page_id = post.page_id;
          this.post_type = post.post_type;
          this.content = post.content;
          this.datePost = this.$moment(post.toDate).format("YYYY-MM-DD");
          this.timePost = this.$moment(post.toDate).format("HH:mm:ss");
          this.link = post.pictureUrl;
          this.$refs.modal.show();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async loadPosts() {
      try {
        const month = this.$moment(this.date).format("YYYY-MM");
        const posts = await this.$http.get(`meta/post?date=${month}&page_id=${this.page}`);
        this.posts = posts.data.data;
      } catch (error) {
        console.log(error.data.message, error.data.details);
      }
    },
    async loadCalendar() {
      //this.loadPosts();
      this.loading = true;
      this.calendar = [];
      this.startDay = this.date.clone().startOf("month").startOf("week");
      this.endDay = this.date.clone().endOf("month").endOf("week");

      let startWeek = this.$moment(this.date).startOf("month").week();
      let endWeek = this.$moment(this.date).endOf("month").week();

      if (endWeek < startWeek) {
        startWeek = 0;
      }

      for (var week = startWeek; week <= endWeek; week++) {
        this.calendar.push({
          week: week,
          days: Array(7)
            .fill(0)
            .map((n, i) => {
              let date = this.$moment(this.date)
                .week(week)
                .startOf("week")
                .clone()
                .add(n + i, "day");

              const posts = this.posts.filter((o) => {
                return (
                  this.$moment(o.toDate).format("DD/MM/YYYY") ==
                  date.format("DD/MM/YYYY")
                );
              });
              return {
                date: date,
                events: posts,
              };
            }),
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  computed: {
    monthName() {
      return this.$moment().month();
    },
    month() {
      return this.$moment().month();
    },
    weeks() {
      return this.$moment().weeks();
    },
  },
  watch: {
    page() {
      this.loadPosts();
    },
    date() {
      this.loadPosts();
    },
    datePost() {
      console.log("datePost", this.timePost);
      if (!this.timePost) {
        this.timePost = this.$moment(this.datePost, "HH:mm")
          .hour(12)
          .minutes(0)
          .format("HH:mm:ss");
      }
    },
    posts() {
      this.loadCalendar();
    },
  },
};
</script>