var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row no-gutters py-4"},[_c('div',{staticClass:"col pr-3"},[_c('GmapMap',{ref:"mapRef",staticClass:"map",style:({
        height: '75vh',
      }),attrs:{"center":_vm.centerMap,"zoom":11,"options":{
        mapTypeControl: false,
        showTraffic: false,
        streetViewControl: false,
        fullscreenControl: false,
        draggable: true,
      },"map-type-id":"roadmap"}},_vm._l((_vm.locations),function(location){return _c('GmapMarker',{key:location._id,ref:("marker_" + (location._id)),refInFor:true,attrs:{"label":("" + (location.user && location.user.name[0].toUpperCase())),"position":{
          lat: location.latitude,
          lng: location.longitude,
        }}})}),1)],1),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('small',{staticClass:"text-center"},[_vm._v("MAPA "+_vm._s(_vm.locations.length))])]),_c('div',{staticClass:"col-3"},[_c('button',{staticClass:"btn btn-success btn-block btn-sm",on:{"click":function($event){return _vm.getLocations()}}},[_c('font-awesome-icon',{attrs:{"icon":"sync"}})],1)])]),_c('div',{staticClass:"overflow-auto custom_scroll mt-2",staticStyle:{"max-height":"70vh"}},[_c('ul',{staticClass:"list-group"},_vm._l((_vm.locations),function(location){return _c('li',{key:location._id,staticClass:"list-group-item list-group-item-action click",class:{
            active: location._id === _vm.current._id,
          },on:{"click":function($event){return _vm.focus(location)}}},[_c('div',{staticClass:"row position-relative"},[_c('div',{staticClass:"col position-absolute",staticStyle:{"top":"0"}},[_c('small',{staticClass:"font-italic text-right d-block"},[_vm._v(_vm._s(_vm.$moment().from(location.updatedAt, true)))])]),_c('div',{staticClass:"col-2"},[(location.business)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getLogo(location.business.logo),"alt":""}}):_vm._e()]),_c('div',{staticClass:"col"},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(location.user && location.user.name))])]),_c('div',{staticClass:"col-12"},[_c('small',[_vm._v(_vm._s(location.business && location.business.name))])])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }