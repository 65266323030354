<template>
  <div>
    <div class="container-fluid px-0">
      <div class="row no-gutters">
        <div class="col-12 col-md-auto px-2 mt-2">
          <b-nav justified tabs>
            <!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
            <b-nav-item to="/admin" exact exact-active-class="active text-white"
              >HOME</b-nav-item
            >
            <b-nav-item
              to="/admin/storage"
              class="d-none"
              exact
              exact-active-class="active text-white"
              >ESPACIO</b-nav-item
            >
            <b-nav-item
              to="/admin/emailqueue"
              exact
              exact-active-class="active text-white"
              >EMAIL</b-nav-item
            >
            <b-nav-item
              to="/admin/socket"
              exact
              exact-active-class="active text-white"
              >SOCKETS</b-nav-item
            >
            <b-nav-item
              to="/admin/invoices"
              exact
              exact-active-class="active text-white"
              >PAGOS</b-nav-item
            >
            <b-nav-item
              to="/admin/ticket"
              exact
              exact-active-class="active text-white"
              >VENTAS</b-nav-item
            >
            <b-nav-item
              to="/admin/sii"
              exact
              exact-active-class="active text-white"
              >SII</b-nav-item
            >

            <b-nav-item
              to="/admin/map"
              exact
              exact-active-class="active text-white"
              >MAPA</b-nav-item
            >
            <b-nav-item
              to="/admin/business"
              exact
              exact-active-class="active text-white"
              >EMPRESAS</b-nav-item
            >
            <b-nav-item to="/admin/webtickets" active-class="active text-white"
              >WEB</b-nav-item
            >
            <b-nav-item to="/admin/rating" active-class="active text-white"
              >RATING</b-nav-item
            >
            <b-nav-item
              to="/admin/noty"
              exact
              exact-active-class="active text-white"
              >NOTIS</b-nav-item
            >
            <b-nav-item
              class="d-none"
              to="/admin/cfs"
              exact
              exact-active-class="active text-white "
              >CF</b-nav-item
            >
            <b-nav-item
              to="/admin/logs"
              exact
              exact-active-class="active text-white"
              >LOGS</b-nav-item
            >
            <b-nav-item
              to="/admin/logins"
              exact
              exact-active-class="active text-white"
              >LOGINS</b-nav-item
            >
            <b-nav-item
              to="/admin/print"
              exact
              class="d-none"
              exact-active-class="active text-white"
              >PRINTS</b-nav-item
            >
          </b-nav>
        </div>
        <div class="col-12 mt-2">
          <router-view
            class=""
            @showWebTicket="showWebTicket"
            @logoutSocket="logoutSocket"
            @updateSocket="updateSocket"
            @showTicket="showTicket"
            @cmd="cmd"
            :sockets="sockets"
            ref="routerView"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";

export default {
  data() {
    return {
      socket: null,
      sockets: [],
      logs: [],
    };
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  mounted() {
    if (!this.$store.state.user.admin) {
      this.$router.push("/");
    }
    let { name, custom_name, _id } = this.$store.state.info;
    this.socket = io("https://io.ticketapp.cl", {
      query: {
        client: navigator.platform,
        business: custom_name || name,
        business_id: _id,
        name: this.user.name + "@admin",
        email: this.user.email,
        token: "123",
        app: "admin",
      },
      //transports: ["websocket"],
      upgrade: false,
    });

    this.socket.on("connect", () => {
      this.socket.emit("getUsers");
      console.log("connected");
      this.socket.emit("join", "root", () => {
        console.log("ROOT");
      });
    });

    this.socket.on("update_data", (data) => {
      console.log(data);
    });

    this.socket.on("users", (users) => {
      //console.log(users);
      this.sockets = users.reverse();

      this.bids = users.reduce((acc, cur) => {
        if (cur.business_id == "root") return acc;
        if (!acc.includes(cur.business_id)) acc.push(cur.business_id);
        return acc;
      }, []);

      // this.bids.forEach((bid) => {
      //   this.socket.emit("join", bid, () => {
      //     console.log("JOIN", bid);
      //   });
      // });

      this.socket.on("cmd", (data) => {
        console.log(data);
        this.logs.unshift({
          ...data,
          createdAt: new Date(),
        });
        this.$refs["routerView"].update && this.$refs["routerView"].update();
      });


      this.socket.on("newWebTicket", (ticket) => {
        console.log("newWebTicket", ticket);
        this.$refs["routerView"].update && this.$refs["routerView"].update();
      });
    });
  },
  methods: {
    showWebTicket() {
      this.$emit("showWebTicket");
    },
    showTicket(ticket) {
      this.$emit("showTicket", ticket);
    },
    logoutSocket() {
      console.log("logoutSocket from admin index");
      this.$emit("logoutSocket");
    },
    updateSocket(socket) {
      console.log("updateSocket from admin index");
      this.socket.emit("update_data", socket.business_id);
    },
    cmd(data) {
      this.socket.emit("cmd", {
        to: data.to,
        payload: data.payload,
      });
    },
  },
};
</script>