<template>
  <div>
    <div class="container">
      <div class="row bg-white p-4">
        <div class="col-12 text-center">
          <h2>Informe de Stock</h2>
          <h6>Tu informe de productos con stock bajo a la fecha</h6>
        </div>
        <div class="w-100 my-2"></div>
        <div class="col-12 text-right">
          <h5>Total productos {{ products.length }}</h5>
        </div>
        <div class="w-100 my-2"></div>
        <div class="col-12">
          <ul class="list-group">
            <li class="list-group-item" v-for="p in products" :key="p._id">
              <div class="row align-items-center">
                <div class="col">
                  <small class="text-muted mr-2">CODIGO: {{p.code}}</small> 
                  <small class="text-muted mr-2">VALOR: ${{formatNumber(p.value)}}</small>
                  <small class="text-muted mr-2">COSTO: ${{formatNumber(p.cost_value)}}</small>
                  <div class="w-100"></div>
                  <strong class="text-uppercase">{{p.name}}</strong>
                </div>
                <div class="col-1 text-center">
                  <small>STOCK</small>
                  <div class="w-100"></div>
                  <strong>{{p.qty}}</strong>
                </div>
                <div class="col-1 text-center">
                  <small>ALERTA</small>
                  <div class="w-100"></div>
                  <strong>{{p.stockAlertAt}}</strong>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      products: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let { data } = await this.$http.get("product/stock");
      this.products = data.data;
    },
  },
};
</script>