<template>
  <div class="row">
    <div class="col-12">
      <button class="btn text-danger btn-sm p-0 mr-2" @click="remove()">
        <font-awesome-icon icon="trash" size="sm"></font-awesome-icon>
      </button>
      <span class="font-weight-bold text-uppercase">
        {{ customLabel || option.name }}:
        {{ getSelected(option.selected) || "----" }}
      </span>

      <small
        class="ml-2 text-uppercase text-muted"
        v-if="option.min || option.max"
      >
        Min: {{ option.min }} - Max: {{ option.max }}
      </small>
    </div>
    <div
      class="col-12 col-md-12"
      v-if="option.type == 'select' && option.options.length > 8"
    >
      <div class="row align-items-end no-gutters">
        <div class="col">
          <input
            type="text"
            v-model="option.filter"
            class="form-control form-control-sm"
            placeholder="Buscar"
          />
        </div>
        <div class="col-4" v-if="option.multiple">
          <small class="d-block text-center">CAMBIAR MAX</small>
          <div class="row no-gutters align-items-end">
            <div class="col">
              <button
                type="button"
                @click="option.max = option.max - 1"
                class="btn btn-block btn-sm btn-outline-secondary"
              >
                -
              </button>
            </div>
            <div class="col">
              <input
                v-model.number="option.max"
                type="text"
                class="form-control form-control-sm d-block text-center"
              />
            </div>
            <div class="col">
              <button
                type="button"
                @click="option.max = option.max + 1"
                class="btn btn-block btn-sm btn-outline-success"
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div class="col-3" v-if="false">
          <div class="input-group">
            <div class="input-group-addon">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
            </div>
            <input
              type="text"
              class="form-control text-center"
              v-model="option.selected.value"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-100"></div>
    <div class="col mt-2">
      <div class="row no-gutters" v-if="option.type != 'number'">
        <div
          class="col-6 col-md-3"
          v-for="{ _id: val, i } in getOptionsWithFilter(option)"
          :key="val._id"
        >
          <b-dropdown
            v-if="val.optionGroup"
            :id="`dropdown-${i}`"
            :text="`${val.name}`"
            :menu-class="{
              'text-uppercase': 1,
            }"
            :toggle-class="{
              'text-uppercase h-100': 1,
            }"
            size="sm"
            block
            class="h-100 text-uppercase bg-light"
            :variant="containSelected(option.selected, val.optionGroup)"
          >
            <template #button-content>
              <small class="float-left mt-1">{{ val.name }}</small>
            </template>
            <b-dropdown-item
              :key="`option-${i}`"
              class="h-100 text-left"
              :class="{
                'text-danger': !s._id.enabled,
              }"
              v-for="(s, i) in val.optionGroup.options"
              @click="setNewOption(option, s._id, product)"
              :disabled="!s._id.enabled"
            >
              {{ s._id.name }} ${{ s._id.value }}
              <div class="w-100"></div>
              <small v-if="!s._id.enabled" class="text-danger text-uppercase"
                >(No disponible)</small
              >
            </b-dropdown-item>
          </b-dropdown>
          <!-- no multiple -->
          <button
            class="btn btn-block text-left h-100 option"
            @click="setNewOption(option, val, product)"
            v-else
            :class="{
              'btn-outline-secondary': option.selected != val,
              'btn-secondary font-weight-bold': option.selected == val,
              'btn-secondary text-white font-weight-bold': isInNew(option, val),
            }"
          >
            <small
              :class="{
                'text-danger font-weight-bold': !val.enabled,
              }"
              >{{ val.name }}</small
            >
            <div class="badge" style="position: absolute; right: 3px; top: 3px">
              ${{ formatNumber(val.value) }}
            </div>
          </button>
        </div>
      </div>
      <!-- number -->
      <div class="input-group" v-else>
        <div class="d-none">
          <div class="input-group-prepend">
            <button class="btn btn-outline-danger" @click="option.selected--">
              -
            </button>
          </div>
          <input
            type="number"
            v-model.number="option.selected"
            @focus="$event.target.select()"
            value="0"
            class="form-control text-center"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-success" @click="option.selected++">
              +
            </button>
          </div>
        </div>
        <b-form-spinbutton
          size="md"
          v-model="option.selected"
          :min="option.min"
          :max="option.max"
        ></b-form-spinbutton>
        <div class="w-100"></div>
        <div class="btn-group btn-block">
          <button
            class="btn btn-sm"
            :key="`op-${option._id}-${i}`"
            v-for="i in option.max"
            @click="option.selected = i"
          >
            <small class="text-muted">{{ i }}</small>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    customLabel: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    remove() {
      const { product, index } = this;
      this.$emit("remove", { product, index });
    },
  },
};
</script>