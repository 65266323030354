<template>
  <div class="container-fluid container-app pt-3">
    <div class="row mb-3 align-items-end">
      <div class="col-12 col-md">
        <small class="d-block"
          >BUSCAR POR NOMBRE, DIRECCION, #NUMERO, TELEFONO</small
        >
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Escriba y presione enter para buscar"
            v-model="fakeFilter"
            @keyup.enter="filter = fakeFilter"
          />
        </div>
      </div>
      <div class="ml-auto col-12 col-md-2">
        <small class="d-block">Fecha</small>
        <date-picker
          valueType="format"
          v-model="dateFilter"
          input-class="form-control pl-4"
        />
      </div>

      <div class="ml-auto col-9 col-md-2">
        <small class="d-block">MOSTRAR</small>
        <select name="" v-model="showTickets" class="form-control">
          <option value="Pendiente">Pendiente</option>
          <option value="Aceptado">Aceptado</option>
          <option value="Cancelado">Cancelado</option>
          <option value="all">Todos</option>
        </select>
      </div>
      <div class="col-3 col-md-2">
        <button class="btn btn-success btn-block" @click="loadTickets()">
          <div
            class="spinner-border spinner-border-sm text-light mb"
            role="status"
            v-if="loading"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <font-awesome-icon icon="redo" v-if="!loading" />
        </button>
      </div>
    </div>

    <ul class="list-group">
      <li class="list-group-item" v-if="tickets.length == 0">
        <h4 class="my-5 text-center">No hay informacion</h4>
      </li>
      <li
        class="list-group-item list-group-item-action click"
        v-for="t in filterTickets"
        :class="{
          'list-group-item-warning': t.status == 'Pendiente',
          'list-group-item-danger1': t.status == 'Cancelado',
          active: t._id == tid,
        }"
        :key="t._id"
        :ref="`webticket-${t._id}`"
        @click="showTicket(t)"
      >
        <div class="row align-items-center">
          <div class="col-md-1 col-3 text-center">
            <span class="font-weight-bold">#{{ t.number }}</span>
            <div class="w-100"></div>
            <small>{{ t.createdAt | moment("HH:mm") }}</small>
            <div class="div-100"></div>
            <font-awesome-icon icon="bell" v-if="t.suscription" />
          </div>
          <div class="col-md col-9">
            <span class="font-weight-bold text-uppercase"
              >{{ t.clientName }} ({{ t.phone }})</span
            >
            <div class="w-100"></div>
            <span class="">
              <font-awesome-icon icon="home" v-if="t.type == 'Domicilio'" />
              <font-awesome-icon icon="truck" v-if="t.type == 'Envio'" />
              <font-awesome-icon icon="hotel" v-if="t.type == 'Retiro'" />
              <small class="ml-2" v-if="t.type == 'Retiro'">{{ t.type }}</small>
            </span>
            <span class="" v-if="t.type == 'Envio' && t.address">
              <small class="ml-2">{{ getAddress(t.address) }}</small>
            </span>
            <span class="" v-if="t.type == 'Domicilio' && t.address">
              <small class="ml-2">{{ getAddress(t.address) }}</small>
            </span>
            <div class="w-100"></div>
            <small v-if="t.notes">NOTAS: {{ t.notes }}</small>
            <div class="w-100"></div>

            <div v-if="t.date > 0" class="font-italic">
              <font-awesome-icon icon="calendar-alt" class="mr-2" />
              <small
                >Programado:
                {{ t.toDate | moment("dddd, DD [de] MMMM") }}</small
              >
            </div>
            <div v-if="t.time != null" class="font-italic">
              <div class="w-100"></div>
              <font-awesome-icon icon="clock" class="mr-2" />
              <small
                ><span v-if="t.time == -1">Lo antes posible</span>
                <span v-else>A las: {{ t.toDate | moment("HH:mm") }}</span>
              </small>
            </div>
          </div>
          <div class="col-2 text-center d-none1">
            <div v-if="t.rating">
              <b-form-rating
                v-model="t.rating.note"
                readonly
                show-value
                size="sm"
                variant="warning"
                show-value-max
              ></b-form-rating>

              <div class="w-100"></div>
              <small class="font-italic"
                >{{ t.rating.comment.slice(0, 40) }}

                <small v-if="t.rating.comment.length > 40">...</small>
              </small>
            </div>
          </div>
          <div class="col-12 col-md-2 text-center">
            <span
              :class="{
                badge: 1,
                'badge-success': t.status == 'Aceptado',
                'badge-danger': t.status == 'Cancelado',
              }"
            >
              {{ t.status }}
              <span v-if="t.updatedAt">{{
                t.updatedAt | moment("HH:mm")
              }}</span>
              <div class="w-100"></div>
            </span>
            <div class="w-100"></div>
            <small class="font-italic" v-if="t.updatedAt"
              >{{ $moment(t.updatedAt).diff(t.createdAt, "minutes") }} min de
              espera
            </small>
            <small v-if="t.status == 'Cancelado'" class="d-block">{{
              t.reason
            }}</small>
          </div>
          <div class="ml-auto col-12 col-md-2 text-uppercase text-right">
            <small
              class=""
              :class="{
                'text-success': t.paymentStatus == 'ok',
                'text-warning': t.paymentStatus != 'ok',
              }"
              >{{ getPaymentStatus(t.paymentStatus) }}</small
            >
            <small v-if="t.paymentTotal" class="font-weight-bold"
              >${{ t.paymentTotal | formatNumber }}</small
            >
            <div class="w-100"></div>
            <small class="font-weight-bold"
              >{{ t.payment }}
              <span class="text-success" v-if="t.amount > t.total"
                >${{ t.amount | formatNumber }}</span
              ></small
            >
            <div class="w-100 border-top"></div>
            <span class="font-weight-bold d-block text-right">
              ${{ formatNumber(t.total) }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateFilter: null,
      tid: null,
      ticket: {
        createdAt: Date.now(),
      },
      reason: "",
      fakeFilter: "",
      filter: "",
      showTickets: "Pendiente",
      limit: 100,
      loading: false,
    };
  },
  mounted() {
    this.check();
    // this.loadTickets();
  },
  methods: {
    scroll() {
      this.$nextTick(() => {
        let tid = this.$route.query.wid;
        let [ref] = this.$refs["webticket-" + tid];
        console.log({ ref });
        if (ref)
          ref.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
      });
    },
    check() {
      if (this.$route.query.wid) this.showTickets = "all";
      if (this.$route.query.date) {
        this.dateFilter = this.$moment(
          this.$route.query.date,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
        this.scroll();
      } else {
        // get first ticket
        const [first] = this.tickets.filter((t) => t.status == "Pendiente");
        if (first) {
          //this.dateFilter = this.$moment(first.createdAt).format("YYYY-MM-DD");
          //this.showTickets = first.status;
          //this.loadTickets();
        } // else {
        //   this.dateFilter = this.$moment().format("YYYY-MM-DD");
        //   this.showTickets = "all";
        // }
      }
      if (this.$route.query.wid) this.tid = this.$route.query.wid;
    },
    getPaymentStatus(status) {
      if (status == "ok") return "Pagado en linea";
      if (status == "pending") return "Pendiente";
      return status;
    },
    goTicketNew(ticket) {
      this.$router.push("/new?wid=" + ticket._id);
    },
    showTicket(t) {
      this.tid = t._id;
      if (t) this.$store.commit("setWebTicket", t);
      this.$emit("showWebTicket");
    },

    loadTickets() {
      this.loading = true;
      let status = "";
      // console.log('showTickets', this.showTickets)
      if (this.showTickets != "all") {
        status = "&status=" + this.showTickets;
      }
      this.$http
        .get(`web/ticket?date=${this.dateFilter}${status}`)
        .then((res) => {
          this.$store.commit("setWebTickets", res.data.data);

          if (this.$route.query.wid) {
            // scroll
            this.scroll();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    filterTickets() {
      if (!this.filter) return this.tickets;
      return this.tickets.filter((t) => {
        if (`#${t.number}` == this.filter) return t;
        if (t.clientName.toLowerCase().indexOf(this.filter) != -1) return t;
        if (t.phone.indexOf(this.filter) != -1) return t;
        if (t.address.toLowerCase().indexOf(this.filter) != -1) return t;
      });
    },
    user() {
      return this.$store.state.user;
    },
    info() {
      return this.$store.state.info;
    },
    tickets() {
      return this.$store.state.webTickets;
    },
    // productList() {
    //   return this.$store.state.products.map((p) => {
    //     return {
    //       code: p.code,
    //       label: `${p.code} - ${p.name}`,
    //     };
    //   });
    // },
  },
  watch: {
    $route() {
      console.log("change route");
      this.check();
    },
    dateFilter() {
      this.loadTickets();
    },
    showTickets() {
      this.loadTickets();
    },
    // product() {
    //   if (this.products.find((p) => p.code == this.product.code)) {
    //     this.$swal({
    //       icon: "error",
    //       text: "El producto ya existe",
    //       title: "Error",
    //     });
    //   }
    // },
  },
};
</script>