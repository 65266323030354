<template>
  <div class="px-4 bgwhite border border-top-0">
    <div class="row py-4">
      <div class="col-7">
        <h2>
          Ordenes de trabajo
          <small>({{ getOts.length || 0 }})</small>
        </h2>
      </div>

      <div class="col-12 ml-auto">
        <div class="row">
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="search" />
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                v-model="fakeFilter"
                @keyup.enter="filter = fakeFilter"
                placeholder="Buscar por nombre, numero de ot usando #. Luego presione enter"
              />
              <div class="input-group-append d-none">
                <button
                  class="btn btn-outline-info btn-sm px-3"
                  v-b-modal.search-options
                >
                  <font-awesome-icon icon="cog" />
                </button>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row no-gutters">
              <div class="col-8" v-if="showfilter">
                <div class="input-group">
                  <date-picker
                    v-model="dateFilter"
                    valueType="format"
                    input-class="form-control"
                  />
                </div>
              </div>
              <div class="col">
                <button class="btn btn-block btn-success" @click="load()">
                  <div
                    class="spinner-border spinner-border-sm text-light mb"
                    role="status"
                    v-if="loading"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <font-awesome-icon icon="redo" v-if="!loading" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-right">
        <small class="mr-4">SALDOS: ${{ getOtsPendingTotal | formatNumber }}</small>
        <small >TOTAL: ${{ getOtsTotal | formatNumber }}</small>
      </div>

      <div class="col-12 mt-2">
        <b-overlay :show="loading">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-if="getOts.length == 0 && ots.length > 0"
            >
              <h5 class="text-center py-5">
                No hay informacion con los filtros indicados
              </h5>
            </li>
            <li
              class="list-group-item list-group-item-action click"
              :class="{ active: ot._id == otId }"
              v-for="ot in getOts"
              :key="ot._id"
              @click="view(ot._id)"
            >
              <div class="row align-items-center no-gutters">
                <div class="col-1 font-weight-bold">
                  #{{ ot.number }}

                  <small class="d-block">
                    {{ ot.createdAt | moment("DD/MM/YY") }}</small
                  >
                </div>
                <div class="col-3 pl-2 overflow-hidden">
                  <small class="font-weight-bold">{{
                    ot.rut || "- - - - - -"
                  }}</small>
                  <div class="w-100"></div>
                  <span class="text-uppercase font-weight-bold">{{
                    ot.name
                  }}</span>
                </div>
                <div class="col-2 text-center">
                  <small class="d-block text-muted">ENTREGA</small>
                  <small class="text-uppercase font-weight-bold">{{
                    ot.endDate | moment("ddd DD/MM/YY")
                  }}</small>
                </div>
                <div class="col text-justify pr-4">
                  <div class="align-self-end h-100">
                    <small
                      class="d-block"
                      v-html="getDescription(ot.description)"
                    ></small>
                    <small class="d-block" v-if="!ot.description"
                      >Sin descripcion.</small
                    >
                  </div>
                </div>
                <div class="col-1">
                  <span
                    class="badge text-uppercase py-2 px-3"
                    :class="{
                      'badge-success': ot.status == 'finalizada',
                      'badge-primary': ot.status == 'pendiente',
                      'badge-danger': ot.status == 'cancelada',
                    }"
                  >
                    {{ ot.status }}
                    <span class="d-block" v-if="ot.finishAt">{{
                      ot.finishAt | moment("DD/MM/YY")
                    }}</span>
                  </span>
                </div>
                <div class="col-1 col-md-1 text-right font-weight-bold">
                  <small class="d-block text-muted">SALDO</small>
                  <span class="text-info">${{ formatNumber(ot.balance) }}</span>
                </div>
                <div class="col-1 text-right font-weight-bold">
                  <small class="d-block text-muted">TOTAL</small>
                  ${{ formatNumber(ot.total) }}
                </div>
              </div>
            </li>

            <li class="list-group-item" v-if="ots.length == 0">
              <h4 class="text-center py-4">
                No hay informacion <span v-if="pending">pendiente</span>
              </h4>
            </li>
          </ul>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["status", "showfilter", "tabID"],
  data() {
    return {
      ots: [],
      ot: {
        notes: [],
        deposits: [],
        name: "",
        phone: "",
        endDate: null,
      },
      info: {},
      dateFilter: null,
      filter: "",
      fakeFilter: "",
      loading: true,
      filterProduct: "",
      qty: 1,
      deposit: 0,
      pending: false,
      payment: "Efectivo",
      product: {},
      searchMode: "date",
      otId: "",
    };
  },
  computed: {
    getOtsTotal() {
      return this.ots.reduce((a, b) => {
        return (a += b.total);
      }, 0);
    },
    getOtsPendingTotal() {
      return this.ots.reduce((a, b) => {
        return (a += (b.balance || 0));
      }, 0);
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    notes() {
      let notes = this.ot.notes
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return notes;
    },
    productFiltered() {
      if (this.filterProduct == "") return this.products;
      else {
        const filter = (p) => {
          return (
            p.name.toLowerCase().indexOf(this.filterProduct) > -1 ||
            p.code.toLowerCase().indexOf(this.filterProduct) > -1
          );
        };
        return this.products.filter(filter);
      }
    },
    getOts() {
      if (this.filter == "" || this.searchMode == "history") return this.ots;
      else {
        const filter = (ot) => {
          if (`#${ot.number}` == this.filter) return ot;
          return (
            ot.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 ||
            ot.rut.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
          );
        };
        return this.ots.filter(filter);
      }
    },
  },
  mounted() {
    if (this.$moment().hour() < 5)
      this.dateFilter = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    else this.dateFilter = this.$moment().format("YYYY-MM-DD");
    this.load();
    this.$http.get("info").then((res) => (this.info = res.data.data));
  },
  methods: {
    getDescription(data) {
      // return data as 40 characters
      if (data) {
        if (data.length > 80) return data.substring(0, 80) + "...";
        else return data;
      }
    },
    printOT(ot) {
      this.$store.commit("setOT", ot);
      this.$emit("printOT", ot);
    },
    createDTE() {
      this.$http
        .post(`ot/${this.ot._id}/dte`)
        .then(() => {
          this.$bvModal.hide("modal_sii");
          this.view();
        })
        .catch(() => {
          this.view();
          this.$noty.error("Error !");
        });
    },
    updateOtinfo() {
      this.$http
        .patch(`ot/${this.ot._id}/info`, {
          name: this.ot.name,
          phone: this.ot.phone,
          rut: this.ot.rut,
          endDate: this.ot.endDate,
        })
        .then(() => {
          this.$noty.success("Editado correctamente");
          this.view();
          this.load();
        })
        .catch(() =>
          this.$noty.error("Error al actualizar. intente nuevamente")
        );
    },
    updateOtDescription() {
      this.$http
        .patch(`ot/${this.ot._id}/description`, {
          description: this.ot.description,
        })
        .then(() => {
          this.$noty.success("Editado correctamente");
          this.view();
        })
        .catch(() =>
          this.$noty.error("Error al actualizar. intente nuevamente")
        );
    },
    updateProduct() {
      this.$http
        .patch(`ot/${this.ot._id}/product/${this.product._id}`, this.product)
        .then(() => this.view())
        .catch(() => this.$noty.error("Error al borrar. intente nuevamente"));
    },
    editProduct(p) {
      if (this.ot.status == "pendiente") {
        this.product = p;
        this.product.oldQty = p.qty;
        this.$bvModal.show("modal-product");
      }
    },
    deleteDeposit(id) {
      this.$http
        .delete(`deposit/${id}`)
        .then(() => {
          this.view(this.ot._id);
        })
        .catch(() => this.$noty.error("Error al borrar. intente nuevamente"));
    },
    removeProduct(id, name, qty) {
      this.$http
        .delete(`ot/extra/${this.ot._id}`, { body: { id, name, qty } })
        .then(() => {
          this.load();
          this.$noty.success("Producto borrado correctamente");
          this.view();
          this.$refs.modalproduct.hide();
        })
        .catch(() => this.$noty.error("Error al borrar. intente nuevamente"));
    },
    viewPending() {
      this.pending = !this.pending;
      this.load();
    },
    finish() {
      this.$http
        .post(`ot/${this.ot._id}/finish`)
        .then(() => {
          this.$noty.success("Orden de trabajo finalizada correctamente");
          this.$refs.modal.hide();
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al finalizar la orden de trabajo");
        });
    },
    addDeposit() {
      this.$http
        .post(`ot/${this.ot._id}/deposit`, {
          deposit: this.deposit,
          payment: this.payment,
        })
        .then(() => {
          this.$noty.success("Abono agregado correctamente");
          this.load();
          this.view(this.ot._id);
          this.deposit = 0;
          this.$refs.deposit.hide();
        })
        .catch(() =>
          this.$noty.error("Error al guardar el abono. Intente nueamente")
        );
    },
    addExtra(product) {
      if (product.storages && product.storage == "") {
        this.$noty.error("Debe seleccionar una bodega");
        return false;
      }
      this.$http
        .post(`ot/${this.ot._id}/extra`, {
          name: product.name,
          code: product.code,
          value: product.value,
          qty: product.q,
          details: product.details,
          storage: product.storage,
        })
        .then(() => {
          this.$noty.success("Extra agregado correctamente!");
          // this.$refs.modal.hide();
          this.load();
          this.view(this.ot._id);
        });
    },
    load() {
      this.loading = true;
      if (this.status) {
        this.$http.get(`ot?status=${this.status}`).then((res) => {
          this.ots = res.data.data;
          this.loading = false;
        });
      } else {
        this.$http
          .get(
            `ot?date=${this.dateFilter}&searchmode=${this.searchMode}&filter=${this.filter}`
          )
          .then((res) => {
            this.ots = res.data.data;
            this.loading = false;
          });
      }
    },
    cancel(id) {
      this.$refs.modal.hide();
      this.$swal({
        type: "question",
        title: "Esta seguro?",
        text: "Esta opcion no se puede deshacer. Ingrese un motivo",
        content: "input",
        buttons: ["Cancelar", "Borrar!"],
      }).then((result) => {
        if (result != null) {
          if (result == "")
            this.$noty.error(`ERROR: Debes ingresar un motivo valido`);
          else {
            this.$http
              .delete(`ot/${id}`, {
                body: {
                  reason: result,
                },
              })
              .then(() => {
                this.load();
                this.$noty.success("Orden de trabajo cancelada correctamente");
              })
              .catch(() => {
                this.$noty.error(
                  "No se pudo cancelar la Orden de trabajo seleccionada"
                );
              });
          }
        }
      });
    },
    view(id = this.ot._id) {
      this.otId = id;
      this.$emit("showOT", id);
    },
  },
  watch: {
    fakeFilter() {
      if (this.fakeFilter == "") this.filter = "";
    },
    filter() {
      if (this.filter == "" && this.searchMode == "history") {
        this.searchMode = "date";
        this.load();
      }
    },
    dateFilter() {
      this.load();
    },
    "product.qty": function () {
      let { product } = this;
      product.total =
        parseInt(product.value * product.qty) - parseInt(product.discount || 0);
    },
    "product.discount": function () {
      let { product } = this;
      product.total =
        parseInt(product.value * product.qty) - parseInt(product.discount);
    },
  },
};
</script>