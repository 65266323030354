var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"products",staticStyle:{"margin-top":"20px","width":"100%","font-family":"sans-serif"}},[_c('tbody',_vm._l((_vm.ticket.products),function(product,i){return _c('tr',{key:("product-" + (product._id) + "-" + i)},[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticStyle:{"font-size":"100%","margin-bottom":"0px"}},[_c('strong',{staticStyle:{"width":"68%","display":"inline-block"}},[_vm._v("\n                "+_vm._s(product.qty || 1)+"\n                "),(product.unit != 'Unid')?_c('span',[_vm._v(_vm._s(product.unit)+" ")]):_vm._e(),_vm._v("-\n                "+_vm._s(_vm._f("uppercase")(product.name))+"\n              ")]),(!_vm.hideValues)?_c('strong',{staticStyle:{"width":"30.5%","display":"inline-block","float":"right","text-align":"right"}},[_vm._v("\n                $"+_vm._s(_vm.formatNumber(product.total))+"\n              ")]):_vm._e()]),_c('small',{staticStyle:{"display":"inline-block","margin-right":"5px"}},[_c('strong',[_vm._v("COD:")]),_vm._v(" "+_vm._s(product.code)+"\n            ")]),_c('small',{staticStyle:{"display":"none","margin-right":"5px"}},[_c('strong',[_vm._v("CANT:")]),_vm._v(" "+_vm._s(product.qty)+"\n            ")]),(!_vm.hideValues)?_c('small',{staticStyle:{"display":"inline-block","margin-right":"5px"}},[_c('strong',[_vm._v("VALOR")]),_vm._v(": $"+_vm._s(_vm._f("formatNumber")(product.value))+"\n            ")]):_vm._e(),(product.discount > 0 && !_vm.hideValues)?_c('small',{staticStyle:{"display":"inline-block","margin-right":"5px"}},[_c('strong',[_vm._v("DESCUENTO")]),_vm._v(": "+_vm._s(_vm._f("fixed")(product.discount))+"% (-$"+_vm._s(_vm.formatNumber(
                  ((product.value * product.qty) / 100) * product.discount
                ))+")\n            ")]):_vm._e(),_vm._l((product.options),function(option,i){return _c('span',{key:i,staticStyle:{"display":"block","margin-bottom":"5px"},style:({
                'font-size': "80%",
                color: _vm.getSelectedPrint(option) ? 'white' : '',
                background: _vm.getSelectedPrint(option) ? 'black' : '',
              })},[(option.label)?_c('span',[_vm._v(_vm._s(option.label)+": ")]):_vm._e(),(!option.ref)?_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre-linep"}},[_vm._v(_vm._s(_vm.getSelected(option.selected)))]):(option.values && option.values.length > 0)?_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre-line"}},[_vm._v(_vm._s(option.values
                    ? option.values
                        .map(function (o) {
                          if (o.value) { return ((o.name) + " ($" + (o.value) + ")"); }
                          else { return ("" + (o.name)); }
                        })
                        .join(" ")
                        .replace("undefined", "- - - -")
                    : ""))]):_c('span',{staticStyle:{"font-weight":"bold","white-space":"pre-line","color":""}},[_vm._v(_vm._s(option.selected ? option.selected : "- - - - -")+"\n                "+_vm._s(option.selected && option.selected.value
                    ? ("$" + (option.selected.value))
                    : ""))])])}),_c('strong',[(product.details)?_c('pre',{staticStyle:{"padding":"0px 5px","margin-top":"2px","white-space":"pre-line","font-family":"sans-serif"}},[_vm._v("- "+_vm._s(product.details))]):_vm._e()])],2)])}),0),(false)?_c('tfoot',{staticStyle:{"text-align":"right"}},[_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v("SUB TOTAL")]),_c('th',[_vm._v("$"+_vm._s(_vm.formatNumber(_vm.ticket.sub_total)))])]),(_vm.ticket.discount)?_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v("DESCUENTO")]),_c('th',[_vm._v("-$"+_vm._s(_vm.formatNumber(_vm.ticket.discount)))])]):_vm._e(),(_vm.ticket.neto)?_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v("NETO")]),_c('th',[_vm._v("$"+_vm._s(_vm.formatNumber(_vm.ticket.neto)))])]):_vm._e(),(_vm.ticket.exento)?_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v("EXENTO")]),_c('th',[_vm._v("$"+_vm._s(_vm.formatNumber(_vm.ticket.exento)))])]):_vm._e(),(_vm.ticket.iva)?_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v("IVA (19%)")]),_c('th',[_vm._v("$"+_vm._s(_vm.formatNumber(_vm.ticket.iva)))])]):_vm._e(),_c('tr',[_c('th',{attrs:{"colspan":"1"}},[_vm._v("Total")]),_c('th',[_vm._v("$"+_vm._s(_vm.formatNumber(_vm.ticket.total)))])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }