<template>
  <div>
   <b-overlay :show="loading" class="h-100">
     <div class="row align-items-center mt-2">
      <div class="col">
        <button class="btn btn-block btn-outline-info" @click="subsDate()">
          Atras
        </button>
      </div>
      <div class="col-8">
        <h2 class="text-uppercase text-center py-4">
          <strong>{{ date | moment("MMMM YYYY") }}</strong>
        </h2>
      </div>

      <div class="col">
        <button class="btn btn-block btn-outline-info" @click="addDate()">
          Siguiente
        </button>
      </div>
    </div>
    <div class="row pt-0 text-center bgwhite font-weight-bold text-uppercase">
      <div class="col-12" v-for="(week, i) in calendar" :key="i">
        <div class="row">
          <div
            class="col py-2 border text-right"
            style="min-height: 150px"
            v-for="(day, j) in week.days"
            :key="j"
            :class="{
              'border-danger bglight':
                day.date.format('DD/MM/YY') == $moment().format('DD/MM/YY'),
              'bg-secondary border-secondary text-light':
                day.date.format('MM') != date.format('MM'),
            }"
          >
            <div class="row no-gutters">
              <div class="col-1">
                <span
                  class="badge"
                  :class="{
                    'd-none': day.date.format('MM') != date.format('MM'),
                    'badge-danger': day.events.length > 0,
                    'badge-info': day.events.length == 0,
                  }"
                >
                  {{ day.events.length }}
                </span>
              </div>
              <div class="col">
                <small
                  :class="{
                    'text-danger font-weight-bold':
                      $moment(day.date).isoWeekday() == 7,
                    'text-muted': $moment(day.date).isoWeekday() != 7,
                    'd-none': day.date.format('MM') != date.format('MM'),
                  }"
                  >{{ day.date | moment("ddd") }}</small
                >
                <span style="font-size: 20px" class="font-weight-bold">
                  {{ day.date | moment("DD") }}
                </span>
              </div>
            </div>
            <div class="w-100 mt-1"></div>
            <span
              class="badge badge-info click mr-1"
              :class="{
                'd-none': day.date.format('MM') != date.format('MM'),
              }"
              v-for="event in day.events"
              :key="event._id"
              @click="view(event._id)"
            >
              #{{ event.number }}
            </span>
          </div>
        </div>
      </div>
    </div>
   </b-overlay>
    
  </div>
</template>

<script>

export default {

  mounted() {
    this.date = this.$moment();
    // this.checkOT();
  },
  data() {
    return {
      calendar: [],
      date: Date.now(),
      startDay: null,
      endDay: null,
      pending: [],
      loading: false
    };
  },
  methods: {
    printOT(ot) {
      this.$store.commit('setOT', ot);
      this.$emit('printOT', ot);
    },
    subsDate() {
      this.date = this.$moment(this.date).subtract(1, "month");
    },
    addDate() {
      this.date = this.$moment(this.date).add(1, "month");
    },
    getEvents() {
      return [];
    },
    view(id) {
      this.$emit('showOT', id);
    },
    checkOT() {
      this.loading = true
      this.calendar = [];
      this.startDay = this.date.clone().startOf("month").startOf("week");
      this.endDay = this.date.clone().endOf("month").endOf("week");

      let startWeek = this.$moment(this.date).startOf("month").week();
      let endWeek = this.$moment(this.date).endOf("month").week();

      if (endWeek < startWeek) {
        startWeek = 0;
      }

      this.$http.get("ot?status=pendiente").then((res) => {
        this.pending = res.data.data;

        for (var week = startWeek; week <= endWeek; week++) {
          this.calendar.push({
            week: week,
            days: Array(7)
              .fill(0)
              .map((n, i) => {
                let date = this.$moment(this.date)
                  .week(week)
                  .startOf("week")
                  .clone()
                  .add(n + i, "day");
                return {
                  date: date,
                  events: this.pending.filter((o) => {
                    return (
                      this.$moment(o.endDate).format("DD/MM/YYYY") ==
                      date.format("DD/MM/YYYY")
                    );
                  }),
                };
              }),
          });
        }
        return true
      }).finally(() => {
        this.loading = false
      })
    },
  },
  computed: {
    monthName() {
      return this.$moment().month();
    },
    month() {
      return this.$moment().month();
    },
    weeks() {
      return this.$moment().weeks();
    },
  },
  watch: {
    date() {
      this.checkOT();
    },
  },
};
</script>