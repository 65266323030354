<template>
  <div class="container-fluid">
    <div class="row bh-white py-3">
      <div class="col-lg-12">
        <b-overlay :show="loading">
          <b-tabs
            vertical
            pills
            active-nav-item-class=""
            nav-wrapper-class="w-25 text-uppercase font-weight-bold"
          >
            <div class="col-12 text-right py-2">
              Vista previa:
              <button
                class="btn btn-link"
                @click="
                  doAction(`https://pedidos.ticketapp.cl/${info.webName}`)
                "
              >
                pedidos.ticketapp.cl/{{ info.webName }}
              </button>
              <button
                class="btn btn-link"
                v-if="info.webDomain"
                @click="doAction(`https://${info.webDomain}`)"
              >
                {{ info.webDomain }}
              </button>
            </div>
            <b-tab title="General">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Retiros en local</span>
                    </div>
                    <div class="col">
                      <small>HABILITAR</small>
                      <b-form-checkbox
                        v-model="pickupEnabled"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>MOSTRAR</small>
                      <b-form-checkbox
                        v-model="showPickup"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>TIEMPO DE ESPERA EN MIN</small>
                      <b-form-spinbutton
                        size="sm"
                        v-model="pickupWaitTime"
                        min="0"
                        step="5"
                        max="120"
                      ></b-form-spinbutton>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Despachos</span>
                    </div>
                    <div class="col">
                      <small>HABILITAR</small>
                      <b-form-checkbox
                        v-model="deliveryEnabled"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>MOSTRAR</small>
                      <b-form-checkbox
                        v-model="showDelivery"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>TIEMPO DE ESPERA EN MIN</small>
                      <b-form-spinbutton
                        size="sm"
                        v-model="deliveryWaitTime"
                        min="0"
                        step="5"
                        max="120"
                      ></b-form-spinbutton>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Agendar</span>
                    </div>
                    <div class="col">
                      <small>HABILITAR</small>
                      <b-form-checkbox
                        v-model="scheduleEnabled"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>MOSTRAR</small>
                      <b-form-checkbox
                        v-model="showSchedule"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col" v-if="false">
                      <small>Max dias</small>
                      <b-form-spinbutton
                        size="sm"
                        v-model="deliveryWaitTime"
                        min="0"
                        step="5"
                        max="120"
                      ></b-form-spinbutton>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Envios shipping</span>
                    </div>
                    <div class="col">
                      <small>HABILITAR</small>
                      <b-form-checkbox
                        v-model="shippingEnabled"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>MOSTRAR</small>
                      <b-form-checkbox
                        v-model="showShipping"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Propinas</span>
                    </div>
                    <div class="col">
                      <small>HABILITAR</small>
                      <b-form-checkbox
                        v-model="tipEnabled"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                    <div class="col">
                      <small>MOSTRAR</small>
                      <b-form-checkbox
                        v-model="showTip"
                        size="lg"
                        switch
                      ></b-form-checkbox>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Estado</span>
                      <div class="w-100"></div>
                      <small class="text-muted"
                        >Disponibilidad de pedidos</small
                      >
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <button
                            class="btn btn-block"
                            :class="{
                              'btn-outline-danger': webStatus != 'offline',
                              'btn-danger': webStatus == 'offline',
                            }"
                            @click="webStatus = 'offline'"
                          >
                            <small>No disponible</small>
                          </button>
                        </div>
                        <div class="col">
                          <button
                            class="btn btn-block"
                            :class="{
                              'btn-outline-secondary': webStatus != 'app',
                              'btn-secondary': webStatus == 'app',
                            }"
                            @click="webStatus = 'app'"
                          >
                            <small>con la app</small>
                          </button>
                        </div>
                        <div class="w-100 py-2"></div>
                        <div class="col">
                          <button
                            class="btn btn-block"
                            :class="{
                              'btn-outline-success': webStatus != 'always',
                              'btn-success': webStatus == 'always',
                            }"
                            @click="webStatus = 'always'"
                          >
                            <small>Siempre</small>
                          </button>
                        </div>
                        <div class="col">
                          <button
                            class="btn btn-block"
                            :class="{
                              'btn-outline-info': webStatus != 'schedule',
                              'btn-info': webStatus == 'schedule',
                            }"
                            @click="webStatus = 'schedule'"
                          >
                            <small>Horario</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Cerrar temporalmente</span>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <b-form-spinbutton
                            size="sm"
                            v-model="closedUntilTime"
                            min="0"
                            step="5"
                            max="18000"
                          ></b-form-spinbutton>
                          <div class="btn-group">
                            <button
                              class="btn btn-sm btn-link"
                              @click="closedUntilTime = 60"
                            >
                              60"
                            </button>
                            <button
                              class="btn btn-sm btn-link"
                              @click="closedUntilTime = 120"
                            >
                              120"
                            </button>
                            <button
                              class="btn btn-sm btn-link"
                              @click="closedUntilTime = 180"
                            >
                              180"
                            </button>
                            <button
                              class="btn btn-sm btn-link"
                              @click="closedUntilTime = 240"
                            >
                              240"
                            </button>
                            <button
                              class="btn btn-sm btn-link"
                              @click="closeTomorrow()"
                            >
                              SIG. DIA
                            </button>
                            <button
                              class="btn btn-sm btn-link text-danger"
                              @click="closedUntilTime = 0"
                            >
                              <font-awesome-icon icon="trash" />
                            </button>
                          </div>
                        </div>
                        <div class="col-5 text-center">
                          <small>CERRADO HASTA: </small>
                          <div v-if="closedUntil">
                            {{ closedUntil | moment("dddd DD/MM HH:mm") }}
                          </div>
                          <div v-else>DESACTIVADO</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item d-none">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Modo Alta demanda</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Activar modo</small>
                    </div>
                    <div class="col">
                      {{ highDemand || "Desactivado" }}
                    </div>
                    <div class="col-4">
                      <span class="font-weight-bold">Cerrado hasta</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Activar modo</small>
                    </div>
                    <div class="col">
                      {{ closedUntil || "- - - -" }}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Mensaje de la pagina</span>
                      <div class="w-100"></div>
                      <small class="text-muted"></small>
                    </div>
                    <div class="col">
                      <div class="input-group">
                        <input
                          type="text"
                          v-model="customMessage"
                          class="form-control"
                        />
                        <button
                          class="btn btn-sm btn-link text-danger m-0 p-0 px-2"
                          @click="customMessage = ``"
                        >
                          <font-awesome-icon icon="trash" />
                        </button>
                      </div>
                    </div>
                    <div class="col-2">
                      <select class="form-control" v-model="bgCustomMessage">
                        <option value="light">Blanco</option>
                        <option value="dark">Oscuro</option>
                        <option value="primary">Primario</option>
                        <option value="secondary">Secundario</option>
                        <option value="success">Verde</option>
                        <option value="danger">Rojo</option>
                        <option value="warning">Amarillo</option>
                        <option value="info">Celeste</option>
                        <option value="pink">Rosa</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <div class="btn btn-group">
                        <button
                          class="btn btn-sm btn-link m-0 p-0 px-2"
                          @click="
                            customMessage = `Ya estamos atendiendo hoy ${$moment().format(
                              'dddd'
                            )}`
                          "
                        >
                          <small>
                            Ya estamos atendiendo hoy
                            {{ $moment().format("dddd") }}
                          </small>
                        </button>
                        <button
                          class="btn btn-sm btn-link m-0 p-0 px-2"
                          @click="
                            customMessage = `Estamos con alta demanda de pedidos`
                          "
                        >
                          <small>Estamos con alta demanda de pedidos</small>
                        </button>
                        <button
                          class="btn btn-sm btn-link m-0 p-0 px-2"
                          @click="
                            customMessage = `Cerrado por hoy ${$moment().format(
                              'dddd'
                            )}`
                          "
                        >
                          <small
                            >Cerrado por hoy
                            {{ $moment().format("dddd") }}</small
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col d-none">
                      <small>Tiempo de espera</small>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="clock"></font-awesome-icon>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="waitTime"
                        />
                        <button
                          class="btn btn-outline-danger"
                          @click="waitTime = ''"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </button>
                      </div>
                      <small class="text-muted"
                        >Dejar en blanco para no mostrar</small
                      >
                    </div>
                    <div class="col">
                      <small>Tiempo de reparto aproximado en ruta</small>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <font-awesome-icon icon="clock"></font-awesome-icon>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control d-none"
                          v-model="deliveryTime"
                        />
                        <b-form-spinbutton
                          v-model="deliveryTime"
                          step="5"
                          min="0"
                        />
                        <button
                          class="btn btn-outline-danger"
                          @click="deliveryTime = 0"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </button>
                      </div>
                      <small class="text-muted"
                        >Se usa para aproximar las horas de entrega para el
                        cliente. Ej: Para 15 seria "Su pedido llegara entre
                        12:00 - 12:15"</small
                      >
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Minimo para reparto</small>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"> $ </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="minDelivery"
                        />
                        <button
                          class="btn btn-outline-danger"
                          @click="minDelivery = 0"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </button>
                        <div class="w-100"></div>
                        <small class="text-muted"
                          >Cantidad minima del pedido. Cantidades inferiores no
                          podran realizar pedidos</small
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Horario">
              <button
                class="btn btn-block btn-outline-primary mb-2"
                @click="loadDefaultShedule()"
                v-if="days.length == 0"
              >
                CARGAR DIAS
              </button>
              <ul class="list-group">
                <li
                  class="list-group-item"
                  v-for="day in days"
                  :class="{
                    active: currentDay == day,
                    'list-group-item-danger':
                      (!day.closeAt && !day.openAt) || day.openAt > day.closeAt,
                    'list-group-item-warning': !day.closeAt || !day.openAt,
                  }"
                  :key="day.name"
                >
                  <div class="row align-items-center">
                    <div class="col-2 text-center">
                      <strong>{{ day.name }}</strong>
                      <small class="d-block" v-if="!day.closeAt && !day.openAt"
                        >CERRADO</small
                      >
                      <small class="d-block" v-if="day.openAt > day.closeAt"
                        >INGRESE HORA VALIDA</small
                      >
                    </div>
                    <div class="col">
                      <small>ABRE A LAS</small>
                      <b-form-timepicker
                        v-model="day.openAt"
                        locale="en"
                        reset-button
                      ></b-form-timepicker>
                    </div>
                    <div class="col">
                      <small>CIERRA A LAS</small>
                      <b-form-timepicker
                        v-model="day.closeAt"
                        locale="en"
                        reset-button
                      ></b-form-timepicker>
                    </div>
                    <div class="col-2 text-center">
                      <button class="btn btn-outline-info btn-sm btn-block">
                        <small class="click" @click="copy(day)">COPIAR</small>
                      </button>

                      <div class="w-100 mb-1"></div>
                      <button
                        class="btn btn-info btn-sm btn-block"
                        :disabled="!currentDay"
                      >
                        <small class="click" @click="paste(day)">PEGAR</small>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Alertas">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Repetir</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Avisos sobre pedidos</small>
                    </div>
                    <div class="col">
                      <select class="form-control" v-model="webNotification">
                        <option value="none">Sin sonido</option>
                        <option value="one">Sonar una vez</option>
                        <option value="repeat">
                          Repetir hasta aceptar/rechazar
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Sonido</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Avisos sobre pedidos</small>
                    </div>
                    <div class="col">
                      <select
                        class="form-control"
                        v-model="webSound"
                        :disabled="!playable"
                      >
                        <option value="bell1">Clasico</option>
                        <option value="bell2">Sonido 2</option>
                        <option value="bell3">Sonido 3</option>
                        <option value="bell4">Sonido 4</option>
                        <option value="bell5">Sonido 5</option>
                        <option value="arch">Sonido 6</option>
                        <option value="beep">Beep</option>
                        <option value="elevator">Elevador</option>
                        <option value="fairy">Fairy</option>
                        <option value="japanese">Japanese</option>
                      </select>
                    </div>
                    <div class="col-2">
                      <button
                        class="btn btn-block btn-success"
                        @click="playSound()"
                        :disabled="!playable"
                      >
                        <font-awesome-icon icon="play" />
                      </button>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Volumen Alertas</span>
                      <div class="w-100"></div>
                      <small class="text-muted"></small>
                    </div>
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <div class="col">
                          <b-form-input
                            type="range"
                            v-model="webAlertVolume"
                            min="0"
                            step="0.1"
                            max="10"
                          ></b-form-input>
                        </div>
                        <div class="col-3">
                          <span class="input-group-text text-center">
                            {{ webAlertVolume * 10 }} %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold"
                        >Email para nuevos pedidos</span
                      >
                      <div class="w-100"></div>
                      <small class="text-muted"></small>
                    </div>
                    <div class="col">
                      <div class="row no-gutters align-items-center">
                        <div class="col">
                          <input
                            type="text"
                            class="form-control"
                            v-model="notificationEmail"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Dominio">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Nombre web</span>
                      <div class="w-100"></div>
                      <small class="text-muted">pedidos.ticketapp.cl/</small>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        v-model="webName"
                        :disabled="user.role != 'admin' || false"
                        placeholder="(Opcional)"
                      />
                      <small class="text-muted"
                        >Ej: {{ getSuggestWebName() }}</small
                      >
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Dominio</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Debes tener dominio</small>
                    </div>
                    <div class="col">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="webDomain"
                          :disabled="user.role != 'admin' || false"
                          placeholder="(Opcional)"
                        />
                        <div class="input-group-append" v-show="webDomain">
                          <button
                            class="btn btn-primary btn-block"
                            @click="doAction(`https://${webDomain}`)"
                          >
                            VER
                          </button>
                        </div>
                      </div>
                      <small class="text-muted"
                        >Ej: milocal.cl - pedir.milocal.cl
                      </small>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Codigo QR</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Debes tener dominio</small>
                    </div>
                    <div class="col">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <span class="badge badge-info">BETA</span>
                        </span>
                        <button
                          class="btn btn-primary btn-block"
                          @click="viewQR()"
                        >
                          GENERAR CODIGO QR
                        </button>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <span class="font-weight-bold">Descripcion SEO</span>
                      <div class="w-100"></div>
                      <small class="text-muted">Debes tener dominio</small>
                    </div>
                    <div class="col">
                      <textarea
                        v-model="description"
                        class="form-control"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Diseño">
              <div class="row">
                <div class="col">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <strong>COLORES</strong>
                        </div>
                        <div class="col">
                          <small class="text-uppercase">Parte Superior</small>
                          <select v-model="headerColor" class="form-control">
                            <option value="white">Blanco</option>
                            <option value="light">Claro</option>
                            <option value="light-dark">Oscuro</option>
                            <option value="dark">Negro</option>
                          </select>
                        </div>

                        <div class="col">
                          <small class="text-uppercase">Fondo</small>
                          <select v-model="bodyColor" class="form-control">
                            <option value="bg-white">Blanco</option>
                            <option value="light">Claro</option>
                            <option value="light-dark">Oscuro</option>
                            <option value="dark">Negro</option>
                            <option value="img-1">Food</option>
                            <option value="img-2">Beanstalk</option>
                            <option value="img-3">Office</option>
                            <option value="img-4">doble bubble dark</option>
                            <option value="img-5">Memphis</option>
                            <option value="img-lined_paper">LinedPapper</option>
                            <option value="img-green_cup">GreenCup</option>
                            <option value="img-hip-square">HipSquare</option>
                            <option value="img-lined_paper">LinedPapper</option>
                            <option value="img-christmas">christmas</option>
                            <option value="img-christmas-black">
                              christmas black
                            </option>
                          </select>
                        </div>

                        <div class="col">
                          <small class="text-uppercase">Parte inferior</small>
                          <select v-model="footerColor" class="form-control">
                            <option value="white">Blanco</option>
                            <option value="light">Claro</option>
                            <option value="light-dark">Oscuro</option>
                            <option value="dark">Negro</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item d-none">
                      <div class="row">
                        <div class="col-12">
                          <strong>COLORES</strong>
                        </div>
                        <div class="col">
                          <small class="text-uppercase">PRIMARIO</small>
                          <input
                            type="text"
                            v-model="primaryColor"
                            class="form-control"
                          />
                        </div>

                        <div class="col">
                          <small class="text-uppercase">SECONDARIO</small>
                          <input
                            type="text"
                            v-model="secondaryColor"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <b-button v-b-toggle.collapse-1 variant="primary" block
                        >CUSTOM CSS (AVANZADO)</b-button
                      >
                      <b-collapse id="collapse-1" class="mt-2">
                        <div class="row">
                          <div class="col">
                            <strong>Custom CSS</strong>
                            <small class="ml-2 text-danger">AVANZADO</small>
                            <textarea
                              class="form-control"
                              v-model="customCss"
                              rows="10"
                            ></textarea>
                          </div>
                        </div>
                      </b-collapse>
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab>

            <b-tab title="Medios de pagos">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="text-uppercase font-weight-bold">
                        <span class="badge badge-warning">NUEVO</span>
                        Pago en linea - Webpay
                      </h5>
                      <small
                        >Recibe pagos en linea con tarjetas de debito y
                        credito</small
                      >
                    </div>
                    <div class="col-2">
                      <b-checkbox
                        switch
                        size="lg"
                        v-model="webpayEnabled"
                        disabled
                      ></b-checkbox>
                      <small class="text-muted">Contacta para contratar</small>
                    </div>
                    <div class="col-4">
                      <input
                        type="password"
                        v-model="flowToken"
                        class="form-control"
                        placeholder="Token"
                      />
                      <small class="text-muted"
                        >Token secreto de mercado pago</small
                      >
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="text-uppercase font-weight-bold">
                        <span class="badge badge-warning">NUEVO</span>
                        Pago en linea - MercadoPago
                      </h5>
                      <small
                        >Recibe pagos en linea con tarjetas de debito y
                        credito</small
                      >
                    </div>
                    <div class="col-2">
                      <b-checkbox
                        switch
                        size="lg"
                        v-model="mercadoPagoEnabled"
                      ></b-checkbox>
                      <small class="text-muted">Contacta para contratar</small>
                    </div>
                    <div class="col-4">
                      <input
                        type="password"
                        v-model="mercadoPagoToken"
                        class="form-control"
                        placeholder="Token"
                      />
                      <small class="text-muted"
                        >Token secreto de mercado pago</small
                      >
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="payments.length == 0">
                  <h4 class="text-center">No tienes pagos</h4>
                </li>
                <li class="list-group-item" v-for="(p, i) in payments" :key="i">
                  <div class="row align-items">
                    <div class="col-1 text-center">
                      <b-form-checkbox
                        v-model="p.enabled"
                        switch
                        size="lg"
                        button-variant="success"
                      >
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <h5 class="text-uppercase font-weight-bold">
                            {{ p.name }}
                          </h5>
                        </div>
                        <div class="col-2" v-if="user.role == 'admin'">
                          <button
                            @click="removePayment(p)"
                            class="btn btn-link text-danger btn-block"
                          >
                            <font-awesome-icon icon="trash"></font-awesome-icon>
                          </button>
                        </div>
                        <div class="w-100 border-top my-2"></div>
                        <div class="col">
                          <b-form-checkbox v-model="p.needAmount">
                            <small>SOLICITAR MONTO</small>
                          </b-form-checkbox>
                        </div>

                        <div class="col-12">
                          <small>DETALLES DE PAGO</small>
                          <textarea
                            class="form-control"
                            v-model="p.details"
                            placeholder="Instrucciones de pago, cuenta bancaria, correo"
                            :disabled="user.role != 'admin'"
                            rows="3"
                          ></textarea>
                          <div class="w-100"></div>
                          <b-form-checkbox v-model="p.showDetailsOnConfirm">
                            <small
                              >MOSTRAR DETALLES DE PAGO SOLO AL CONFIRMAR</small
                            >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="w-100 py-3"></div>
              <div class="input-group">
                <small class="text-mute">Seleccione un medio de pago</small>
                <div class="w-100"></div>
                <select class="form-control" v-model="payment">
                  <option disabled value="">Seleccione</option>
                  <option :value="p" v-for="(p, i) in getpayments" :key="i">
                    {{ p }}
                  </option>
                </select>
                <button
                  class="btn btn-primary"
                  @click="addPayment()"
                  :disabled="!payment"
                >
                  AGREGAR
                </button>
              </div>
            </b-tab>

            <b-tab title="Categorias" v-if="false">
              <ul class="list-group"></ul>
            </b-tab>

            <b-tab title="Productos">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Orden categorias</small>
                      <button
                        class="btn btn-block btn-outline-primary"
                        @click="showCategoryModal()"
                        :disabled="user.role != 'admin'"
                      >
                        EDITAR
                      </button>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Productos sin stock</small>
                      <select
                        v-model="productOutStock"
                        :disabled="user.role != 'admin'"
                        class="form-control"
                      >
                        <option :value="false">No mostrar</option>
                        <option :value="true">Mostrar</option>
                        <!-- <option value="value">Codigo</option> -->
                      </select>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Cupones de descuento</small>
                      <select
                        v-model="couponEnabled"
                        :disabled="user.role != 'admin'"
                        class="form-control"
                      >
                        <option :value="false">Deshabilitar</option>
                        <option :value="true">Habilitar</option>
                        <!-- <option value="value">Codigo</option> -->
                      </select>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Productos sin imagen</small>
                      <select
                        v-model="productImage"
                        :disabled="user.role != 'admin'"
                        class="form-control"
                      >
                        <option value="always">Mostrar</option>
                        <option value="isvalid">Mostrar, solo si posee</option>
                        <option value="hide">No mostrar</option>
                        <!-- <option value="value">Codigo</option> -->
                      </select>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Orden productos</small>
                      <select
                        v-model="productCategorySort"
                        :disabled="user.role != 'admin'"
                        class="form-control"
                      >
                        <option value="name">Nombre</option>
                        <option value="value">Precio</option>
                        <option value="value">Codigo</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <b-checkbox
                        v-model="showMostSoldProducts"
                        switch
                        size="lg"
                        :disabled="user.role != 'admin'"
                      >
                        <small>Mostrar productos mas vendidos</small>
                      </b-checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <b-checkbox
                        v-model="showRecomended"
                        switch
                        size="lg"
                        :disabled="user.role != 'admin'"
                      >
                        <small>Mostrar productos recomendados</small>
                      </b-checkbox>
                    </div>
                    <div class="col-7">
                      <v-select
                        @input="addProduct()"
                        taggable
                        v-model="recomendedList"
                        class="py-1"
                        multiple
                        append-to-body
                        :options="getProducts"
                      ></v-select>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Zonas de reparto">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <small>Zonas de reparto</small>
                      <div class="w-100"></div>
                      <button
                        class="btn btn-outline-primary btn-block"
                        :disabled="user.role != 'admin'"
                        @click="openDeliverySettings()"
                      >
                        CONFIGURAR
                      </button>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <small>Respaldar zonas de reparto</small>
                      <div class="w-100"></div>
                      <!-- <pre
                        class="form-control"
                        style="height: 200px; overflow: auto"
                      >
                        {{ paths }}
                      </pre> -->
                    </div>
                    <div class="col-6">
                      <small>EXPORTAR</small>
                      <button @click="exportZones()" class="btn btn-outline-primary btn-block">
                        Exportar
                      </button>
                    </div>
                    <div class="col-6">
                      <small>IMPORTAR</small>
                      <div class="input-group">
                        <input
                        type="file"
                        accept=".json"
                        @change="importZonesFile($event)"
                        class="form-control"
                      />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>

            <b-tab title="Redes sociales">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row align-items-start">
                    <div class="col-3">
                      <strong>Whatsapp</strong>
                    </div>
                    <div class="col">
                      <input
                        type="number"
                        class="form-control"
                        v-model.number="whatsapp"
                      />
                      <small
                        >Solo numeros (56912345678), mostrara un boton
                        flotante.</small
                      >
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-start">
                    <div class="col-3">
                      <strong>Link Facebook</strong>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        v-model="facebook"
                      />
                      <small
                        >Slug de tu pagina de Facebook. Ej: ticketapp.cl</small
                      >
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-start">
                    <div class="col-3">
                      <strong>Link Instagram</strong>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        v-model="instagram"
                      />
                      <small
                        >tu nombre de usuario sin @. Ej: ticketapp.cl</small
                      >
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <strong>Habilitar pixel</strong>
                    </div>
                    <div class="col">
                      <b-checkbox
                        switch
                        size="lg"
                        v-model="facebookPixelEnabled"
                      ></b-checkbox>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <strong>Identificador del pixel</strong>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        v-model="facebookPixelCode"
                      />
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <strong>Email del pixel</strong>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        v-model="facebookPixelEmail"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>

      <div class="col-lg-6">
        <ul class="list-group">
          <li class="list-group-item d-none">
            <div class="row align-items-center">
              <div class="col">
                <small>Zonas de reparto</small>
                <div class="w-100"></div>
                <button
                  class="btn btn-outline-primary btn-block"
                  :disabled="user.role != 'admin'"
                  @click="openDeliverySettings()"
                >
                  CONFIGURAR
                </button>
              </div>
            </div>
          </li>
          <li class="list-group-item d-none">
            <div class="row align-items-center">
              <div class="col">
                <small>Comunas de reparto</small>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="info"
                      >CONFIGURAR</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <ul class="list-group">
                        <li class="list-group-item" v-if="comunnes.length == 0">
                          <h4 class="text-center">No tienes comunas</h4>
                        </li>
                        <li
                          class="list-group-item"
                          v-for="(c, i) in comunnes"
                          :key="i"
                        >
                          <div class="row align-items-end">
                            <div class="col">
                              <h5 class="text-uppercase font-weight-bold">
                                {{ c.name }}
                              </h5>
                            </div>
                            <div class="col-2" v-if="user.role == 'admin'">
                              <button
                                @click="removePayment(p)"
                                class="btn btn-link text-danger btn-block"
                              >
                                <font-awesome-icon
                                  icon="trash"
                                ></font-awesome-icon>
                              </button>
                            </div>
                            <div class="w-100"></div>
                            <div class="col">
                              <b-form-checkbox v-model="c.enabled" switch>
                                <small>HABILITAR</small>
                              </b-form-checkbox>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="w-100"></div>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="comunne"
                        />
                        <button class="btn btn-primary" @click="addCommune()">
                          AGREGAR
                        </button>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <div class="w-100"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 mt-4">
        <button
          class="btn btn-success btn-block"
          :disabled="loading"
          @click="saveSettings()"
        >
          GUARDAR
        </button>
      </div>
    </div>

    <b-modal ref="modalQR" title="QR" scrollable hide-footer hide-header>
      <div class="card border-0">
        <div id="qrfull">
          <div id="qr">
            <vue-qr
              v-if="showLogo"
              class="img-fluid"
              :correctLevel="3"
              :text="getDomainText()"
              :size="800"
              :colorDark="colorDark"
              :colorLight="colorLight"
              :autoColor="true"
              :backgroundColor="bgColor"
              :logoSrc="getLogoSrc()"
              id="qr-img"
            ></vue-qr>
            <vue-qr
              v-else
              class="img-fluid"
              :correctLevel="3"
              :text="getDomainText()"
              :size="800"
              :colorDark="colorDark"
              :colorLight="colorLight"
              :autoColor="true"
              :backgroundColor="bgColor"
              id="qr-img"
            ></vue-qr>
          </div>
          <div class="card-body text-center d-none">
            <h5
              class="card-title font-weight-bold text-uppercase"
              v-html="getText"
            ></h5>
            <p class="card-text text-muted text-uppercase">
              {{ webDomain || `pedidos.ticketapp.cl/${webName}` }}
            </p>
          </div>
        </div>
        <div class="w-100 py-2"></div>
        <div class="row">
          <div class="col-12">
            <small class="text-muted"
              >RECUERDA USAR COMBINACIONES DE COLORES LEGIBLES</small
            >
          </div>
          <div class="col">
            <small>FONDO</small>
            <select name="" id="" class="form-control" v-model="bgColor">
              <option value="black">Negro</option>
              <option value="white">Blanco</option>
              <option value="#2c3e50">Gris</option>
              <option value="#f1c40f">Amarillo</option>
              <option value="#f39c12">Naranjo</option>
              <option value="#e74c3c">Rojo</option>
              <option value="#27ae60">Verde</option>
            </select>
          </div>
          <div class="col">
            <small>CLAROS</small>
            <select name="" id="" class="form-control" v-model="colorLight">
              <option value="white">Blanco</option>
              <option value="black">Negro</option>
              <option value="#2c3e50">Gris</option>
              <option value="#f1c40f">Amarillo</option>
              <option value="#f39c12">Naranjo</option>
              <option value="#e74c3c">Rojo</option>
              <option value="#27ae60">Verde</option>
            </select>
          </div>
          <div class="col">
            <small>OSCUROS</small>
            <select name="" id="" class="form-control" v-model="colorDark">
              <option value="white">Blanco</option>
              <option value="black">Negro</option>
              <option value="#2c3e50">Azul Marino</option>
              <option value="#f1c40f">Amarillo</option>
              <option value="#f39c12">Naranjo</option>
              <option value="#e74c3c">Rojo</option>
              <option value="#27ae60">Verde</option>
            </select>
          </div>
          <div class="col">
            <small>LOGO</small>
            <select name="" id="" class="form-control" v-model="showLogo">
              <option :value="true">Si</option>
              <option :value="false">No</option>
            </select>
          </div>
          <div class="w-100 py-1"></div>
          <div class="col d-none">
            <textarea
              class="form-control"
              cols="30"
              rows="5"
              v-model="text"
            ></textarea>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button class="btn btn-primary btn-block" @click="downloadQR()">
              Descargar
            </button>
          </div>
          <div class="col d-none">
            <button
              class="btn btn-secondary btn-block"
              @click="downloadQR(true)"
            >
              QR COMPLETO
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="categoryModal"
      title="Ordenar categorias"
      scrollable
      @ok="updateCategory()"
    >
      <div class="row">
        <div class="col">
          <small class="text-muted">Arrastra en el orden deseado</small>
          <draggable
            v-model="categories"
            class="list-group"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <div
              v-for="(element, i) in categories"
              class="list-group-item click text-uppercase font-weight-bold text-center"
              :key="element.id"
            >
              {{ i + 1 }} - {{ element.name }}
            </div>
          </draggable>
        </div>
      </div>
    </b-modal>

    <!-- Modals --->
    <b-modal
      ref="modalDeliverySettings"
      title="Configurar Reparto"
      size="xxl"
      ok-title="Guardar"
      @ok="saveZones()"
    >
      <div class="row overflow-hidden">
        <div class="col-12">
          <small
            >PARA CREAR, POSICIONE EL CENTRO DEL MAPA Y LUEGO PRESIONE</small
          >
          <button class="btn btn-primary btn-block" @click="addZone()">
            NUEVA ZONA
          </button>
        </div>
        <div class="col-7 mt-2">
          <gmap-map
            :center="point"
            :zoom="11"
            ref="mapRef"
            @bounds_changed="boundsChanged"
            style="width: 100%; min-height: 60vh"
          >
            <component
              :is="path.type == 'km' ? 'gmap-circle' : 'gmap-polygon'"
              v-for="(path, i) in paths"
              :key="`path-${i}`"
              :paths="path.paths"
              :editable="editPath.paths == path.paths"
              :draggable="editPath.paths == path.paths"
              :center="path.center || point"
              @paths_changed="updateEdited($event)"
              @center_changed="updateCenter($event, path)"
              :radius="path.radius"
              :options="{
                strokeColor: path.color,
              }"
            />
          </gmap-map>
        </div>
        <div class="col">
          <div
            style="max-height: 60vh; overflow-y: scroll"
            class="overflow custom_scroll"
          >
            <ul class="list-group mt-2">
              <li
                class="list-group-item"
                :id="`path-${path._id}`"
                @click="setEditPath(path)"
                v-for="path in paths"
                :key="`path-${path._id}`"
                :class="{
                  active: editPath == path,
                }"
              >
                <div class="row align-items-center click">
                  <div class="col-auto bg-light">
                    <button
                      class="btn btn-link btn-sm"
                      @click="moveFirst(path)"
                    >
                      <font-awesome-icon icon="star"></font-awesome-icon>
                    </button>
                    <button class="btn btn-link btn-sm" @click="moveUp(path)">
                      <font-awesome-icon icon="arrow-up"></font-awesome-icon>
                    </button>
                    <button class="btn btn-link btn-sm" @click="moveDown(path)">
                      <font-awesome-icon icon="arrow-down"></font-awesome-icon>
                    </button>
                  </div>

                  <div class="col-auto align-center pr-0">
                    <div
                      :style="{
                        color: path.color,
                      }"
                    >
                      <font-awesome-icon
                        icon="circle"
                        size="2x"
                      ></font-awesome-icon>
                    </div>
                  </div>
                  <div class="col">
                    <small>NOMBRE</small>
                    <input
                      type="text"
                      class="form-control fs-4"
                      v-model="path.name"
                      :readonly="editPath != path"
                    />
                  </div>
                </div>
                <div class="row" v-show="editPath == path">
                  <div class="col">
                    <small>COLOR</small>
                    <select class="form-control" v-model="path.color">
                      <option value="green">Verde</option>
                      <option value="red">Rojo</option>
                      <option value="blue">Azul</option>
                      <option value="black">Negro</option>
                      <option value="white">Blanco</option>
                      <option value="orange">Naranjo</option>
                      <option value="yellow">Amarillo</option>
                      <option value="purple">Morado</option>
                      <option value="pink">Rosado</option>
                      <option value="gray">Gris</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <small>Tipo cobro</small>
                    <select class="form-control" v-model="path.type">
                      <option value="zone">Zona</option>
                      <option value="km">KM</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <small>Componente</small>
                    <select class="form-control" v-model="path.component">
                      <option value="polygon">Zona</option>
                      <option value="km">KM</option>
                      <option value="banned">Zona bloqueada</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <small>Radio en metros (MTS)</small>
                    <input
                      type="number"
                      class="form-control"
                      :disabled="path.component !== 'km'"
                      v-model.number="path.radius"
                    />
                  </div>
                  <div class="col-6" v-if="path.type != 'km'">
                    <small>VALOR ENVIO</small>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        type="number"
                        @focus="$event.target.select()"
                        class="form-control"
                        v-model="path.value"
                      />
                    </div>
                  </div>
                  <div class="col-12" v-else>
                    <button
                      class="btn btn-primary btn-block"
                      @click="pushKm(path)"
                    >
                      Agregar
                    </button>
                    <div
                      class="row align-items-end"
                      v-for="(km, i) in path.kms"
                      :key="km._id || i"
                    >
                      <div class="col">
                        <small>DESDE</small>
                        <input
                          type="text"
                          class="form-control"
                          @focus="$event.target.select()"
                          v-model="km.start"
                        />
                      </div>
                      <div class="col">
                        <small>HASTA</small>
                        <input
                          type="text"
                          class="form-control"
                          @focus="$event.target.select()"
                          v-model="km.end"
                        />
                      </div>
                      <div class="col">
                        <small>VALOR</small>
                        <input
                          type="text"
                          class="form-control"
                          @focus="$event.target.select()"
                          v-model="km.value"
                        />
                      </div>
                      <div class="col-2">
                        <button
                          class="btn btn-danger btn-block"
                          @click="removeKm(path, km)"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <small>MINIMO PARA REPARTO</small>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        type="number"
                        @focus="$event.target.select()"
                        class="form-control"
                        v-model="path.minDelivery"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <small>QUITAR ZONA</small>
                    <div class="input-group">
                      <button
                        class="btn btn-danger btn-block"
                        @click="removeZone(path)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueQr from "vue-qr";
// import html2canvas from "html2canvas";
import draggable from "vuedraggable";
import { getCenter } from "geolib";

export default {
  components: {
    VueQr,
    draggable,
  },
  mounted() {
    if (this.user.role != "admin") return this.$router.push("/web");
    this.loadSettings();
    this.loadCategory();

    this.webDelivery = this.info.webDelivery;
    this.webLocal = this.info.webLocal;
    this.webLocal = this.info.webLocal;
    this.bankAccount = this.info.bankAccount;
    this.webName = this.info.webName;
    this.webStatus = this.info.webStatus;
    this.webNotification = this.info.webNotification;
    this.webDomain = this.info.webDomain;
    // this.loadDefaultShedule();
  },
  computed: {
    getProducts() {
      if (!this.products) return [];
      return this.products.map((o) => ({
        ref: o._id,
        label: `${o.code} - ${o.name}`,
      }));
    },
    products() {
      return this.$store.state.products.filter((p) => p.webproduct);
    },
    getText() {
      if (this.text == null) return "";
      return this.text.replace(/\n/g, "<br>");
    },
    user() {
      return this.$store.state.user;
    },
    info() {
      return this.$store.state.info;
    },
    tickets() {
      return this.$store.state.webTickets;
    },
    getpayments() {
      let payments = [];
      if (this.info.payments) {
        let selectedPayments = this.payments.map((p) => p.name);
        payments = this.info.payments.split(",");
        payments = payments.filter((p) => !selectedPayments.includes(p));
        return payments;
      } else return [];
    },
  },
  data() {
    return {
      tipEnabled: false,
      showTip: false,
      couponEnabled: false,
      productImage: "always",
      notificationEmail: "",
      shippingEnabled: false,
      showShipping: false,
      productOutStock: 0,
      showSchedule: false,
      scheduleEnabled: false,
      showPickup: true,
      description: "",
      showDelivery: 1,
      showLocal: 1,
      showTables: 0,
      closedUntilTime: 0,
      closedUntil: null,
      highDemand: null,
      showMostSoldProducts: false,
      headerColor: "light",
      footerColor: "dark",
      primaryColor: "",
      bodyColor: "",
      secondaryColor: "",
      deliveryEnabled: false,
      pickupEnabled: false,
      bankAccount: "",
      webStatus: "always",
      webName: "",
      webDomain: "",
      waitTime: "",
      deliveryTime: "",
      payments: [],
      payment: "",
      style: "",
      categories: [],
      webNotification: "",
      webSound: "",
      productCategorySort: "name",
      // MAP
      edited: [],
      paths: [],
      point: { lat: -36.8001887, lng: -73.004514 },
      editPath: {},
      lastPoint: null,
      minDelivery: 0,
      webAlertVolume: 8,
      comunne: "",
      comunnes: [],
      colorLight: "white",
      colorDark: "#2c3e50",
      bgColor: "white",
      showLogo: true,
      text: "Escanea nuestro codigo QR",
      clients: [],
      pickupWaitTime: 0,
      deliveryWaitTime: 0,
      days: [],
      facebookPixelEnabled: false,
      facebookPixelCode: "",
      facebookPixelEmail: "",
      webpayEnabled: false,
      flowEnabled: false,
      flowToken: "",
      mercadoPagoEnabled: false,
      mercadoPagoToken: "",
      customMessage: "",
      bgCustomMessage: "",
      instagram: "",
      facebook: "",
      whatsapp: "",
      customCss: "",
      loading: true,
      playable: true,
      currentDay: null,
      showRecomended: false,
      recomendedList: [],
    };
  },
  methods: {
    importZonesFile(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = JSON.parse(e.target.result);
        this.paths = data;
        this.$noty.success("Zonas importadas");
      };
      reader.readAsText(file);
    },
    importZones() {},
    exportZones() {
      let data = JSON.stringify(this.paths);
      let blob = new Blob([data], { type: "text/plain" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      const date = this.$moment().format("YYYY-MM-DD_hhmm");
      const name = (this.info.custom_name|| this.info.name).replace(/ /g, "-").toLowerCase();
      a.download = `zonas-${name}-${date}.json`;
      document.body.appendChild(a);
      a.click();
    },
    removeZone(path) {
      const confirm = window.confirm("¿Desea eliminar esta zona?");
      if (!confirm) return;

      this.paths = this.paths.filter((p) => p != path);
    },
    removeKm(path, km) {
      let index = path.kms.indexOf(km);
      path.kms.splice(index, 1);
    },
    pushKm(path) {
      if (path.kms == null) path.kms = [];
      path.kms.push({ start: 0, end: 0, value: 0 });
    },
    moveFirst(path) {
      let index = this.paths.indexOf(path);
      if (index > 0) {
        this.paths.splice(index, 1);
        this.paths.splice(0, 0, path);
      }
    },
    moveDown(path) {
      let index = this.paths.indexOf(path);
      if (index < this.paths.length - 1) {
        this.paths.splice(index, 1);
        this.paths.splice(index + 1, 0, path);
      }
    },
    moveUp(path) {
      let index = this.paths.indexOf(path);
      if (index > 0) {
        this.paths.splice(index, 1);
        this.paths.splice(index - 1, 0, path);
      }
    },
    updateCenter(center, path) {
      path.center = center;
    },
    addProduct() {
      console.log(this.recomendedList);
    },
    closeTomorrow() {
      let nextDay = this.$moment().add(1, "day").startOf("day");
      this.closedUntilTime = this.$moment(nextDay).diff(
        Date.now(),
        "minutes",
        true
      );
    },
    loadDefaultShedule() {
      this.days = [
        { name: "Lunes", openAt: null, closeAt: null, n: 1 },
        { name: "Martes", openAt: null, closeAt: null, n: 2 },
        { name: "Miercoles", openAt: null, closeAt: null, n: 3 },
        { name: "Jueves", openAt: null, closeAt: null, n: 4 },
        { name: "Viernes", openAt: null, closeAt: null, n: 5 },
        { name: "Sabado", openAt: null, closeAt: null, n: 6 },
        { name: "Domingo", openAt: null, closeAt: null, n: 0 },
      ];
    },
    showHighDemandModal() {
      this.$refs["highdemand-modal"].show();
    },
    copy(day) {
      this.currentDay = day;
      this.$noty.info("Copiado");
    },
    paste(day) {
      day.openAt = this.currentDay.openAt;
      day.closeAt = this.currentDay.closeAt;
      day.updatedAt = Date.now();
      this.$noty.info("Pegado");
      //this.$forceUpdate();
    },
    playSound() {
      this.playable = false;
      try {
        this.audio = new Audio(
          `https://pedidos.ticketapp.cl/bells/${this.webSound}.wav`
        );
        this.audio.volume = this.webAlertVolume / 10;
        this.audio.play();
        this.audio.addEventListener("ended", () => {
          console.log("audio stop");
          this.playable = true;
        });
      } catch (error) {
        this.playable = true;
      }
    },
    async setEditPath(path) {
      this.editPath = path;
      let zones = path.paths.map((z) => ({
        latitude: z.lat,
        longitude: z.lng,
      }));

      let center = getCenter(zones);

      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({
          lat: center.latitude,
          lng: center.longitude,
        });
      });

      //await this.$nextTick();

      window.scrollTo({
        top: document.getElementById(`path-${path._id}`).offsetTop - 100,
        behavior: "smooth",
      });
      // if (center) this.point = { lat: center.latitude, lng: center.longitude };
    },
    addCommune() {
      this.comunnes.push({
        name: this.comunne,
        enabled: true,
      });
      this.comunne = "";
    },
    openDeliverySettings() {
      this.$refs.modalDeliverySettings.show();
      setTimeout(() => {
        if (this.paths && this.paths.length > 0) {
          this.setEditPath(this.paths[0]);
        }
      }, 1000);
    },
    checkSlug() {
      if (this.page.slug == "")
        this.page.slug = this.page.title.toLowerCase().replace(/ /g, "-");
    },
    updateEdited(mvcArray) {
      let paths = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        let path = [];
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      this.editPath.paths = paths[0];
    },

    removePayment(payment) {
      this.payments = this.payments.filter((p) => {
        if (p.name != payment.name) return p;
      });
    },
    saveZones() {
      console.log(this.paths);
      this.zones = this.paths;
      this.saveSettings();
    },
    boundsChanged(data) {
      if (data) {
        let bounds = data[Object.keys(data)[0]];
        let bounds2 = data[Object.keys(data)[1]];
        let p1 = bounds[Object.keys(bounds)[0]];
        let p2 = bounds[Object.keys(bounds)[1]];
        let p3 = bounds2[Object.keys(bounds)[0]];
        let p4 = bounds2[Object.keys(bounds)[1]];
        this.lastPoint = { lat: (p1 + p2) / 2, lng: (p3 + p4) / 2 };
      }
    },
    deleteZone(zone) {
      this.paths.splice(this.paths.indexOf(zone), 1);
      // this.edited = this.paths;
    },
    addZone() {
      let n = this.paths.length + 1;
      let z = {
        paths: [
          { lat: this.lastPoint.lat, lng: this.lastPoint.lng },
          { lat: this.lastPoint.lat, lng: this.lastPoint.lng + 0.011 },
          { lat: this.lastPoint.lat + 0.011, lng: this.lastPoint.lng + 0.011 },
          { lat: this.lastPoint.lat + 0.011, lng: this.lastPoint.lng },
        ],
        name: "Zona " + n,
        value: 0,
        color: "green",
      };
      this.paths.push(z);
      this.editPath = z;
      //  [
      //     { lat: 1.38, lng: 103.8 },
      //     { lat: 1.38, lng: 103.81 },
      //     { lat: 1.39, lng: 103.81 },
      //     { lat: 1.39, lng: 103.8 },
      //   ],
    },
    downloadQR() {
      let $qr = document.getElementById("qr-img");
      let src = $qr.src;
      // save image as file.png
      var a = document.createElement("a");
      a.href = src;
      a.download = "qr.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    getLogoSrc() {
      if (this.showLogo) return this.getLogo(this.info.logo);
      return false;
    },
    getDomainText() {
      if (this.webDomain) return `https://${this.webDomain}`;
      return `https://pedidos.ticketapp.cl/${this.webName}`;
    },
    viewQR() {
      this.$refs["modalQR"].show();
    },
    updateCategory() {
      let cat = this.categories.map((c, i) => {
        return {
          ...c,
          webSort: i,
        };
      });
      this.$http.patch("category", cat).then(() => {
        this.$noty.success("Categorias editadas correctamente");
        this.loadCategory();
      });
    },
    loadCategory() {
      this.$http.get("category?sort=webSort").then((res) => {
        this.categories = res.data.data;
      });
    },
    addPayment() {
      this.payments.push({
        name: this.payment,
        enabled: true,
      });
      this.payment = "";
    },
    showCategoryModal() {
      this.loadCategory();
      this.$refs.categoryModal.show();
    },
    loadSettings() {
      this.loading = true;
      this.$http
        .get("web/settings")
        .then((res) => {
          if (!res.data.data) return false;
          this.$store.commit("setWebSettings", res.data.data);
          this.pickupEnabled = res.data.data.pickupEnabled;
          this.deliveryEnabled = res.data.data.deliveryEnabled;
          this.webStatus = res.data.data.status;
          this.webNotification = res.data.data.webNotification;
          this.webSound = res.data.data.webSound || "bell1";
          this.webAlertVolume = res.data.data.webAlertVolume || "50";
          this.headerColor = res.data.data.headerColor;
          this.bodyColor = res.data.data.bodyColor;
          this.footerColor = res.data.data.footerColor;
          this.waitTime = res.data.data.waitTime;
          this.payments = res.data.data.payments;
          this.style = res.data.data.style;
          this.productCategorySort = res.data.data.productCategorySort;
          this.paths = res.data.data.zones;
          this.minDelivery = res.data.data.minDelivery;
          this.deliveryTime = res.data.data.deliveryTime;
          this.comunnes = res.data.data.comunnes;
          this.facebookPixelEnabled = res.data.data.facebookPixelEnabled;
          this.facebookPixelCode = res.data.data.facebookPixelCode;
          this.facebookPixelEmail = res.data.data.facebookPixelEmail;
          this.customMessage = res.data.data.customMessage;
          this.instagram = res.data.data.instagram;
          this.facebook = res.data.data.facebook;
          this.deliveryWaitTime = res.data.data.deliveryWaitTime;
          this.pickupWaitTime = res.data.data.pickupWaitTime;
          this.bgCustomMessage = res.data.data.bgCustomMessage;
          this.whatsapp = res.data.data.whatsapp;
          this.customCss = JSON.parse(res.data.data.customCss);
          this.showMostSoldProducts = res.data.data.showMostSoldProducts;
          this.days = res.data.data.days;
          this.highDemand = res.data.data.highDemand;
          let diff = this.$moment(res.data.data.closedUntil).diff(
            Date.now(),
            "minutes"
          );
          if (diff > 0) {
            this.closedUntil = res.data.data.closedUntil;
            this.closedUntilTime = diff;
          } else {
            this.closedUntil = null;
            this.closedUntilTime = 0;
          }

          this.showRecomended = res.data.data.showRecomended;
          this.recomendedList = res.data.data.recomendedList;
          this.showPickup = res.data.data.showPickup;
          this.showDelivery = res.data.data.showDelivery;
          this.description = res.data.data.description;
          this.showSchedule = res.data.data.showSchedule;
          this.scheduleEnabled = res.data.data.scheduleEnabled;
          this.productOutStock = res.data.data.productOutStock;
          this.shippingEnabled = res.data.data.shippingEnabled;
          this.showShipping = res.data.data.showShipping;
          this.notificationEmail = res.data.data.notificationEmail;
          this.productImage = res.data.data.productImage;
          this.couponEnabled = res.data.data.couponEnabled;
          this.mercadoPagoEnabled = res.data.data.mercadoPagoEnabled;
          this.mercadoPagoToken = res.data.data.mercadoPagoToken;
          this.flowEnabled = res.data.data.flowEnabled;
          this.flowToken = res.data.data.flowToken;
          this.tipEnabled = res.data.data.tipEnabled;
          this.showTip = res.data.data.showTip;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveSettings() {
      this.loading = true;
      this.$http
        .post("web/settings", {
          couponEnabled: this.couponEnabled,
          productImage: this.productImage,
          pickupEnabled: this.pickupEnabled,
          deliveryEnabled: this.deliveryEnabled,
          bankAccount: this.bankAccount,
          webName: this.webName,
          webStatus: this.webStatus,
          webNotification: this.webNotification,
          webSound: this.webSound,
          webAlertVolume: this.webAlertVolume,
          waitTime: this.waitTime,
          headerColor: this.headerColor,
          bodyColor: this.bodyColor,
          footerColor: this.footerColor,
          payments: this.payments,
          style: this.style,
          productCategorySort: this.productCategorySort,
          webDomain: this.webDomain,
          zones: this.paths,
          minDelivery: this.minDelivery,
          deliveryTime: this.deliveryTime,
          comunnes: this.comunnes,
          facebookPixelEnabled: this.facebookPixelEnabled,
          facebookPixelCode: this.facebookPixelCode,
          facebookPixelEmail: this.facebookPixelEmail,
          customMessage: this.customMessage,
          facebook: this.facebook,
          instagram: this.instagram,
          deliveryWaitTime: this.deliveryWaitTime,
          pickupWaitTime: this.pickupWaitTime,
          bgCustomMessage: this.bgCustomMessage,
          whatsapp: this.whatsapp,
          customCss: JSON.stringify(this.customCss),
          showMostSoldProducts: this.showMostSoldProducts,
          days: this.days,
          highDemand: this.highDemand,
          closedUntilTime: this.closedUntilTime,
          showRecomended: this.showRecomended,
          recomendedList: this.recomendedList,
          showPickup: this.showPickup,
          showDelivery: this.showDelivery,
          description: this.description,
          scheduleEnabled: this.scheduleEnabled,
          showSchedule: this.showSchedule,
          productOutStock: this.productOutStock,
          shippingEnabled: this.shippingEnabled,
          showShipping: this.showShipping,
          notificationEmail: this.notificationEmail,
          mercadoPagoEnabled: this.mercadoPagoEnabled,
          mercadoPagoToken: this.mercadoPagoToken,
          flowEnabled: this.flowEnabled,
          flowToken: this.flowToken,
          tipEnabled: this.tipEnabled,
          showTip: this.showTip,
        })
        .then(() => {
          this.loadSettings();
          return this.$http.get("info");
        })
        .then((res) => {
          this.$noty.success("Datos actualizados");
          this.$store.commit("setInfo", res.data.data);
        })
        .catch(() => {
          this.$noty.error("Error al actualizar");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSuggestWebName() {
      let name = this.info.custom_name || this.info.name;
      return name.replace(/ /g, "-").toLowerCase();
    },
  },
};
</script>agre