<template>
  <div class="container-fluid container-app">
    <div class="row align-items-center">
      <div class="col">
        <h2>
          {{ this.name }}
          <small class="">({{ getTickets.length || 0 }})</small>
          <small class="text-danger ml-2">
            ({{ getTicketsCanceled || 0 }})
          </small>
        </h2>
        <div class="w-100"></div>
        <small class="text-muted"
          >MOSTRANDO {{ getTickets.length }} de {{ tickets.length }}</small
        >
      </div>
      <div class="col-md-8 col-12 ml-auto text-right d-md-block text-uppercase">
        <small>
          <router-link to="/nstats" class="mr-4 d-none"> Informes </router-link>
          <button
            class="btn btn-link mx-2"
            @click="downloadExcel()"
            v-if="hasPermission(user, 'ticket_excel')"
          >
            EXPORTAR EXCEL
          </button>
          <router-link
            to="/stats"
            class="mr-4"
            v-if="hasPermission(user, 'stats')"
          >
            Estadisticas
          </router-link>
          <router-link
            to="/sumup"
            class=""
            v-if="info.sumupEnabled && hasPermission(user, 'sumup')"
          >
            SumUP
          </router-link>
          <router-link
            to="/sii"
            class="d-none1 ml-4"
            v-if="info.beEnabled && hasPermission(user, 'sii')"
          >
            Facturacion SII
          </router-link>
          <router-link
            to="/tickets/quote"
            v-if="info.quoteEnabled && hasPermission(user, 'quote')"
            class="ml-4"
          >
            Cotizaciones
          </router-link>
        </small>
      </div>
      <div class="col-12">
        <div class="row align-items-end no-gutters">
          <div class="col-md">
            <small>BUSCAR | PRESIONE ENTER</small>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="fakeFilter"
                placeholder="Nombre, direccion, telefono, numero #123. Luego enter."
                @keyup.enter="filter = fakeFilter"
              />
              <div class="input-group-append d-none">
                <button
                  class="btn btn-outline-info btn-sm px-3"
                  v-b-modal.search-options
                >
                  <font-awesome-icon icon="cog" />
                </button>
              </div>
            </div>
          </div>
          <div
            class="col-md-2"
            v-if="hasPermission(user, 'ticket_list_change_date')"
          >
            <small>MODO</small>
            <select class="form-control" v-model="searchMode">
              <option value="date">Fecha seleccionada</option>
              <option value="week">Semana</option>
              <option value="month">Mes</option>
              <option value="history">Historial</option>
            </select>
          </div>
          <div
            class="col-md-2 col-6"
            v-if="hasPermission(user, 'ticket_list_change_date')"
          >
            <div class="w-100 d-md-none d-sm-block py-1"></div>
            <div class="row no-gutters">
              <div class="col-6 d-none d-md-block">
                <button
                  class="btn btn-block"
                  @click="dateSubs()"
                  :disabled="loading"
                >
                  <small>ANT.</small>
                </button>
              </div>

              <div class="col-6 d-none d-md-block">
                <button
                  class="btn btn-block"
                  @click="dateAdd()"
                  :disabled="loading"
                >
                  <small>SIG.</small>
                </button>
              </div>
              <div class="w-100"></div>
              <div class="col">
                <date-picker
                  valueType="format"
                  v-model="dateFilter"
                  :type="getDateFilterType"
                  input-class=" form-control text-center"
                />
              </div>
            </div>
          </div>
          <div class="col-md-1 col-3">
            <button
              class="btn btn-block btn-success rounded"
              @click="load()"
              :disabled="loading"
            >
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
                v-if="loading"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="redo" v-if="!loading" />
            </button>
          </div>
          <div
            class="col-md-2 col-3 text-center"
            v-if="hasPermission(user, 'report')"
          >
            <router-link
              :to="`/tickets/report2?date=${dateFilter}`"
              class="btn btn-info btn-block rounded"
            >
              <font-awesome-icon icon="file-excel" />
              <span class="d-none d-md-inline-block ml-2">INFORME</span>
            </router-link>
          </div>
          <div class="w-100"></div>
          <div class="col-md-2 col-6" v-if="info.deliveryEnabled">
            <small class="text-muted">TIPO ENTREGA</small>
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="money-bill-wave" />
                </span>
              </div> -->
              <select class="form-control" v-model="typeFilter">
                <option :value="null">Todos</option>
                <option value="Retiro">Retiro</option>
                <option value="Domicilio">Domicilio</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 col-6" v-if="info.deliveryEnabled">
            <small class="text-muted">ESTADO PAGO</small>
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="money-bill-wave" />
                </span>
              </div> -->
              <select class="form-control" v-model="filterStatus">
                <option :value="null">Todos</option>
                <option value="Por pagar">Por Pagar</option>
                <option value="Pagado">Pagados</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 col-6" v-if="info.deliveryEnabled">
            <small class="text-muted">ESTADO ENTREGA</small>
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="money-bill-wave" />
                </span>
              </div> -->
              <select class="form-control" v-model="filterStatusDelivery">
                <option :value="null">Todos</option>
                <option value="Entregado">Entregado</option>
                <option value="Esperando">Esperando</option>
              </select>
            </div>
          </div>
          <div class="col-md-2 col-6">
            <small class="text-muted">MEDIO PAGO</small>
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="money-bill-wave" />
                </span>
              </div> -->
              <select class="form-control" v-model="paymentFilter">
                <option :value="null">Todos</option>
                <option :value="p" :key="`payment-${p}`" v-for="p in payments">
                  {{ p }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2 col-6" v-if="user.role == 'admin'">
            <small class="text-muted">USUARIO</small>
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="money-bill-wave" />
                </span>
              </div> -->
              <select class="form-control" v-model="userFilter">
                <option :value="null">Todos</option>
                <option
                  :value="p._id"
                  :key="`user-${i}-${p._id}`"
                  v-for="(p, i) in users"
                >
                  {{ p.name }} - {{ p.total }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2 col-6" v-if="user.role == 'admin'">
            <small class="text-muted">LIMITE</small>
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="money-bill-wave" />
                </span>
              </div> -->
              <select class="form-control" v-model="limit">
                <option :value="null">Todos ({{this.tickets.length}})</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="40">40</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
                <option :value="150">150</option>
                <option :value="200">200</option>
                <option :value="500">500</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <ul class="list-group" v-if="loading && getTickets.length == 0">
          <li class="list-group-item" v-if="loading">
            <div class="row align-items-center py-4">
              <div class="col-12 py-4 text-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="list-group" v-else>
          <li class="list-group-item text-center" v-if="getTickets.length == 0">
            <span class="d-block py-5 font-weight-bold">
              No hay informacion
            </span>
          </li>
          <li
            v-for="t in getTickets"
            :key="t._id"
            @click="loadTicket(t)"
            @contextmenu="view(t)"
            :class="{
              active: ticket._id == t._id,
              'list-group-item-warning': !t._id,
              'list-group-item-danger': t.deletedAt != null,
            }"
            class="list-group-item list-group-item-action click animated_fadeIn"
          >
            <div class="row align-items-center h-100">
              <div class="col-6 col-md-3 pl-3">
                <span class="text-uppercase d-block" v-if="t.dteType == 33">
                  <small class="d-block font-weight-bold">{{
                    t.receptor ? t.receptor.rut : ""
                  }}</small>
                  {{ t.receptor ? t.receptor.rs : "" }}
                </span>
                <span
                  class="text-uppercase font-weight-bold"
                  v-if="t.clientName != 'anon'"
                >
                  {{ t.clientName }}
                  <div class="w-100"></div>
                  <small class="badge badge-success" v-if="t.webTicket"
                    >Pedido Web</small
                  >
                </span>
                <span
                  class="text-uppercase font-weight-bold"
                  v-if="t.clientName == 'anon' && t.dteType != 33"
                >
                  - - - - - - -
                </span>
                <div class="d-block">
                  <font-awesome-icon
                    icon="user-circle"
                    size="sm"
                    class="mr-1"
                  />

                  <small class="text-uppercase">{{
                    t.user_sell ? t.user_sell.name : "Admin"
                  }}</small>
                </div>
                <div class="d-block">
                  <font-awesome-icon
                    icon="calendar-alt"
                    size="sm"
                    class="mr-1"
                  />
                  <small class="text-uppercase">{{
                    t.createdAt | moment("DD/MM HH:mm")
                  }}</small>
                </div>
                <div class="d-md-none">
                  <span class="d-inline-block mr-2">
                    <font-awesome-icon
                      icon="clock"
                      size="sm"
                      class="text-muted"
                    />
                  </span>
                  <small class="d-inline-block">
                    {{ timeFormated(t.createdAt) }}

                    <span v-if="info.deliveryEnabled">
                      {{ t.hour ? `- ${t.hour}` : "" }}
                    </span>
                  </small>
                  <small
                    class="d-block font-italic"
                    v-if="info.deliveryEnabled && t.hour"
                  >
                    ({{ getDiff(t) }}
                    minutos)
                  </small>
                </div>
                <div
                  class="badge"
                  :class="{
                    'badge-danger': t.siiStatus == 'NULLED',
                    'badge-light border-success': t.siiStatus !== 'NULLED',
                  }"
                  v-if="t.isDTE"
                >
                  <small class="font-weight-bold">
                    <span class="d-inline-block mr-1">
                      <div v-if="!t.siiResponse">
                        <small class="font-weight-bold">
                          <font-awesome-icon
                            class="text-warning"
                            icon="clock"
                          />
                        </small>
                      </div>
                      <div v-if="t.siiResponse">
                        <div v-if="JSON.parse(t.siiResponse).estado == 'EPR'">
                          <small class="font-weight-bold">
                            <font-awesome-icon
                              class="text-success"
                              icon="check-circle"
                            />
                          </small>
                        </div>
                        <div v-if="JSON.parse(t.siiResponse).estado == 'REC'">
                          <small class="font-weight-bold">
                            <font-awesome-icon
                              class="text-warning"
                              icon="check-circle"
                            />
                          </small>
                        </div>
                        <div v-if="JSON.parse(t.siiResponse).estado == 'RSC'">
                          <small class="font-weight-bold">
                            <font-awesome-icon
                              class="text-danger"
                              icon="times-circle"
                            />
                          </small>
                        </div>
                      </div>
                    </span>
                    <span class="d-inline-block"
                      >{{ getDTEName(t.dteType) }}
                      {{ formatNumber(t.folio) }}</span
                    >
                    <span
                      class="d-block text-italic"
                      v-if="t.siiStatus == 'NULLED'"
                      >Folio Anulado</span
                    >
                  </small>
                </div>
                <div class="badge badge-light" v-else>
                  <span>
                    Comprobante {{ formatNumber(t.number) || "----" }}
                  </span>
                </div>
                <div class="w-100"></div>
                <div class="d-block text-uppercase" v-if="info.deliveryEnabled">
                  <span class="overflow-hidden w-100">
                    <div class="row no-gutters">
                      <div class="col-2 col-sm-1 col-md-2 col-lg-1">
                        <font-awesome-icon
                          :icon="t.type == 'Retiro' ? 'hotel' : 'home'"
                          size="sm"
                        />
                      </div>
                      <div class="col-10 overflow-hidden">
                        <small v-if="t.type == 'Retiro'">{{ t.type }}</small>
                        <small
                          class="text-nowrap"
                          v-if="t.type == 'Domicilio'"
                          >{{ t.address }}</small
                        >
                      </div>
                    </div>
                  </span>
                </div>
                <span class="click" v-if="t.phone">
                  <div
                    class="d-inline-block mr-2 text-success click"
                    @click="openWhatsApp(t.phone)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                      />
                    </svg>
                  </div>
                  <font-awesome-icon icon="phone" v-if="t.phone" />
                  <small class="ml-2">{{ t.phone }}</small>
                </span>

                <div v-if="t.notes">
                  <small class="font-weight-bold">Notas: </small>
                  <small class="">{{ t.notes }}</small>
                </div>
              </div>
              <div class="col-2 d-none d-md-block">
                <div class="text-center">
                  <span class="d-block">
                    <font-awesome-icon icon="clock" class="text-muted" />
                  </span>
                  <span class="d-inline-block">
                    {{ timeFormated(t.createdAt) }}

                    <span v-if="info.deliveryEnabled">
                      {{ t.hour ? `- ${t.hour}` : "" }}
                    </span>
                  </span>
                  <div class="w-100"></div>
                  <small v-if="info.deliveryEnabled && t.hour">
                    ({{ getDiff(t) }}
                    min)
                  </small>
                </div>
              </div>
              <div class="d-none d-md-block col-md-4 col-5">
                <span class="d-block font-weight-bold" v-if="t.payments">
                  <small
                    class="d-block text-uppercase w-100 bg-secondary text-light p-1 rounded text-wrap mb-1"
                    v-for="(p, i) in t.payments"
                    :key="i"
                  >
                    <div class="row text-center align-items-center">
                      <div class="col-12 col-lg-8">
                        {{ p.name }}
                      </div>
                      <div class="col-12 col-lg-4">
                        <strong class="text-dark rounded bg-light px-2 mx-2">
                          ${{ p.amount | formatNumber }}</strong
                        >
                      </div>
                    </div>
                  </small>
                </span>

                <span
                  class="d-block font-weight-bold text-center"
                  v-if="t.payments && t.payments.length == 0"
                >
                  {{ t.payment || "" }}
                </span>

                <div class="d-none d-md-block" v-if="info.deliveryEnabled">
                  <div>
                    <small
                      :class="{
                        'bg-info': t.delivery.status == 'Esperando',
                        'bg-success': t.delivery.status == 'Entregado',
                        'bg-warning text-dark':
                          t.delivery.status == 'En camino',
                        'bg-secondary': t.delivery.status == 'A bordo',
                      }"
                      class="w-100 d-block bg-secondary text-light p-1 text-center text-uppercase rounded"
                      >{{ t.delivery ? t.delivery.status : "" }}
                      {{ getShortName(t.delivery_user) }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-2 text-center">
                <div class="d-block d-md-none">
                  <!-- payments in mobile payments -->
                  <span class="d-block font-weight-bold py-2" v-if="t.payments">
                    <small
                      class="d-block text-uppercase w-100 bg-secondary text-light p-1 rounded text-wrap"
                      v-for="(p, i) in t.payments"
                      :key="i"
                    >
                      <div class="row text-center">
                        <div class="col">
                          {{ p.name }}
                        </div>
                        <div class="col">
                          <strong class="text-dark rounded bg-light px-2 mx-2">
                            ${{ p.amount | formatNumber }}</strong
                          >
                        </div>
                      </div>
                    </small>
                  </span>
                </div>
                <span
                  :class="{
                    'text-success': t.status == 'Pagado',
                    'text-warning': t.status != 'Pagado',
                  }"
                  class="font-weight-bold text-uppercase"
                >
                  {{ t.status }}
                </span>
                <div class="w-100"></div>
                <small
                  v-if="t.discount"
                  class="text-muted mb-0"
                  style="text-decoration: line-through"
                >
                  ${{ formatNumber(t.sub_total) }}
                </small>
                <span class="d-block font-weight-bold" style="font-size: 1.4em">
                  ${{ formatNumber(t.total) }}
                </span>
                <small
                  class="font-weight-bold badge badge-danger"
                  v-if="t.discount"
                >
                  -{{ discountToPercent(t.discount, t.sub_total) }}%
                </small>
                <span
                  class="badge badge-warning mt-1 badge-pill px-3"
                  v-if="
                    t.amount > 0 &&
                    t.amount != t.total &&
                    t.payment == 'Efectivo'
                  "
                >
                  Vuelto ${{ formatNumber(t.amount - t.total) }}
                </span>
                <small
                  class="d-block1 font-weight-bold d-none1"
                  v-if="tipEnabled && t.tip"
                >
                  <font-awesome-icon icon="star" class="text-warning" /> ${{
                    formatNumber(t.tip)
                  }}
                </small>
              </div>
              <div class="col-2 col-md-1 ml-auto text-center h-100">
                <div class="dropdown h-100">
                  <button
                    class="btn h-100 btn-block"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <font-awesome-icon icon="ellipsis-v" />
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right bgwhite"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      class="dropdown-item"
                      :class="{
                        disabled: editStatus(t.createdAt),
                      }"
                      @click="editPayment(t)"
                      v-if="!t.deletedAt && hasPermission(user, 'ticket_edit')"
                      :disabled="editStatus(t.createdAt)"
                    >
                      Editar
                      <small
                        class="text-danger d-block"
                        v-if="editStatus(t.createdAt)"
                        >Max. 2 dias</small
                      >
                    </button>
                    <button
                      class="dropdown-item"
                      :class="{
                        disabled: editStatus(t.createdAt),
                      }"
                      @click="setReady(t._id)"
                      v-if="
                        !t.deletedAt &&
                        hasPermission(user, 'ticket_edit') &&
                        t.delivery.status != 'Entregado'
                      "
                      :disabled="editStatus(t.createdAt)"
                    >
                      Marcar entregado
                      <small
                        class="text-danger d-block"
                        v-if="editStatus(t.createdAt)"
                        >Max. 2 dias</small
                      >
                    </button>
                    <button class="dropdown-item" @click="view(t)">
                      Ver ticket
                    </button>
                    <button
                      class="dropdown-item d-none1"
                      @click="openWhatsApp(t.phone, `Hola ${t.clientName}`)"
                      v-if="t.phone"
                    >
                      Abrir WhatsApp
                    </button>
                    <button
                      class="dropdown-item d-none1"
                      @click="resend(t)"
                      v-show="t.isDTE && t.siiStatus != 'RSC1'"
                    >
                      Reenviar al SII
                    </button>
                    <button
                      class="dropdown-item d-none"
                      @click="showMap(t.address)"
                      v-if="t.address"
                    >
                      Ver en Maps
                    </button>
                    <button
                      class="dropdown-item"
                      @click="resend(t)"
                      v-if="
                        !t.isDTE &&
                        !t.deletedAt &&
                        info.beEnabled &&
                        hasPermission(user, 'pdv')
                      "
                      :disabled="$moment().diff(t.createdAt, 'days') > 1"
                    >
                      Cambiar a Boleta
                    </button>
                    <button
                      class="dropdown-item d-none"
                      @click="modify(t)"
                      v-if="t.isDTE && !t.deletedAt && info.beEnabled"
                      :disabled="$moment().diff(t.createdAt, 'days') < 1"
                    >
                      Nota de crédito/debito
                    </button>
                    <button
                      class="dropdown-item"
                      v-if="info.remotePrintEnabled"
                      @click="printTicketRemote(t, 1, 1)"
                    >
                      Imprimir remoto (Completa)
                    </button>
                    <button
                      class="dropdown-item"
                      v-if="info.remotePrintEnabled"
                      @click="printTicketRemote(t, 3, 1)"
                    >
                      Imprimir remoto (Detalle)
                    </button>

                    <button class="dropdown-item" @click="printTicket(t, 1, 1)">
                      Imprimir
                    </button>
                    <!-- <button
                      class="dropdown-item"
                      v-b-modal.print
                      v-if="isElectron()"
                    > -->
                    <button class="dropdown-item" v-b-modal.print>
                      Imprimir avanzado
                    </button>
                    <button
                      class="dropdown-item"
                      @click="copy(t)"
                      v-if="hasPermission(user, 'pdv')"
                    >
                      Copiar
                    </button>
                    <a
                      class="dropdown-item"
                      href="#"
                      v-if="ticketEditable(t) && hasPermission(user, 'pdv')"
                      @click="cancel(t._id, t)"
                      >Cancelar</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-12 py-0 animated fadeInUp1"
                v-if="t._id == ticket._id"
              >
                <!-- <b-collapse
                  :id="`ticket-${t._id}`"
                  class="mt-4"
                  accordion="my-accordion"
                > -->
                <div class="row justify-content-center">
                  <div class="col-12 col-md-5">
                    <small
                      class="text-center text-uppercase d-block border-bottom font-weight-bold"
                    >
                      Detalles
                    </small>

                    <small
                      class="d-block"
                      v-for="(product, i) in t.products"
                      :key="`${product._id}-${i}`"
                    >
                      <div class="row px-4">
                        <div class="col">
                          <span class="d-block">
                            {{ product.qty || 1 }} -
                            <span class="text-uppercase">
                              {{ product.name }}
                            </span>
                            <small class="d-block ml-3">{{
                              product.details
                            }}</small>
                          </span>
                        </div>
                        <div class="col-3 text-center">
                          ${{ formatNumber(product.value) }}
                        </div>
                        <div class="col-3 text-right font-weight-bold">
                          ${{ formatNumber(product.total) }}
                        </div>
                      </div>
                    </small>
                  </div>
                  <div class="col-md-4">
                    <small
                      class="text-center text-uppercase d-block border-bottom font-weight-bold"
                    >
                      Historial
                    </small>
                    <div class="row">
                      <div
                        class="col-12"
                        v-if="t.events && t.events.length == 0"
                      >
                        <small class="text-center d-block">
                          Sin historial
                        </small>
                      </div>
                      <div
                        class="col-12"
                        v-for="(event, i) in t.events"
                        :key="i"
                      >
                        <small class="font-weight-bold">{{
                          event.createdAt | moment("DD/MM HH:mm")
                        }}</small>
                        <small class="text-uppercase">
                          - {{ event.user ? `${event.user.name}:` : "" }}
                          {{ event.value }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-3"
                    v-if="t.siiResponse && t.siiResponse.length > 0"
                  >
                    <small
                      class="text-center text-uppercase d-block border-bottom"
                    >
                      SII
                    </small>
                    <div class="row">
                      <div class="col">
                        <small class="d-block">
                          Estado:
                          {{ JSON.parse(t.siiResponse).estado || "---" }}
                        </small>
                        <small class="d-block">
                          Emisor:
                          {{ JSON.parse(t.siiResponse).rut_emisor || "---" }}
                        </small>
                        <small class="d-block">
                          Envia:
                          {{ JSON.parse(t.siiResponse).rut_envia || "---" }}
                        </small>
                        <small class="d-block">
                          Fecha:
                          {{
                            JSON.parse(t.siiResponse).fecha_recepcion || "---"
                          }}
                        </small>
                        <small
                          class="d-block"
                          v-for="(e, i) in JSON.parse(t.siiResponse)
                            .detalle_rep_rech"
                          :key="i"
                        >
                          {{ e }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </b-collapse> -->
              </div>
            </div>
            <div class="row" v-if="t.deletedAt">
              <div class="col-12 mt-2">
                <div class="alert alert-danger text-center font-weight-bold">
                  Cancelado: {{ t.reason || "-----" }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="w-100 py-5"></div>

    <!-- pagination -->
    <div
      class="overflow-auto"
      style="position: fixed; left: 0; bottom: 30px; width: 100%; z-index: 999"
    >
      <div class="container mb-0">
        <b-pagination-nav
          v-model="page"
          base-url="#"
          align="fill"
          :number-of-pages="numberOfPages"
        ></b-pagination-nav>
      </div>
    </div>

    <!-- end pagination -->

    <!-- modals -->
    <b-modal id="print" size="xs" title="Imprimir">
      <div class="row">
        <div class="col-6 my-2">
          <button
            class="btn btn-block btn-outline-success"
            @click="printTicket(ticket)"
          >
            Ticket completo
          </button>
          <small class="text-muted text-center d-block"
            >TODA LA INFORMACION</small
          >
        </div>
        <div class="col-6 my-2">
          <button
            class="btn btn-block btn-outline-success"
            @click="printTicket(ticket, 2)"
          >
            Ticket simple
          </button>
          <small class="text-muted text-center d-block"
            >SOLO TOTAL SIN DETALLES</small
          >
        </div>
        <div class="col-6 my-2" v-if="info.deliveryEnabled">
          <button
            class="btn btn-block btn-outline-success"
            @click="printTicket(ticket, 3)"
          >
            Ticket detalle
          </button>
          <small class="text-muted text-center d-block">SOLO DETALLES</small>
        </div>
        <div class="col-6 my-2" v-if="info.deliveryEnabled">
          <button
            class="btn btn-block btn-outline-success"
            @click="printTicket(ticket, 4)"
          >
            Ticket + detalle
          </button>
          <small class="text-muted text-center d-block"
            >TICKET COMPLETO + TICKET DETALLES</small
          >
        </div>
        <div class="col-6 my-2 d-none1">
          <button
            class="btn btn-block btn-outline-success"
            @click="printTicket(ticket, 5)"
          >
            Ticket + Ticket Cambio
          </button>
        </div>
        <div class="col-6 my-2 d-none1">
          <button
            class="btn btn-block btn-outline-success"
            @click="printTicket(ticket, 6)"
          >
            Ticket + Detalle + Simple
          </button>
        </div>

        <div class="col-12 mt-5">
          <select
            v-model="printers"
            class="form-control form-control-lg"
            style="height: 73%"
          >
            <option value="1">Usar todas</option>
            <option value="2">Solo primaria</option>
            <option value="3">Solo secundaria</option>
          </select>
          <small class="d-block text-muted text-center">Impresoras</small>
        </div>
        <div class="col-12 mt-5 d-none">
          <select
            v-model="copiesTicket"
            class="form-control form-control-lg"
            style="height: 73%"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <small class="d-block text-muted text-center">COPIAS</small>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="changePayment"
      size="xl"
      title="Editar informacion"
      @ok="saveInfo()"
    >
      <div class="row">
        <div class="col-5" v-if="info.deliveryEnabled">
          <label for="">Nombre</label>
          <input
            type="text"
            class="form-control text-uppercase"
            v-model="ticket.clientName"
          />

          <label for="">Telefono</label>
          <input type="text" class="form-control" v-model="ticket.phone" />

          <label for="">Tipo</label>
          <b-form-radio-group
            block
            v-model="ticket.type"
            :options="['Retiro', 'Domicilio']"
            button-variant="outline-primary"
            class="w-100"
            buttons
          ></b-form-radio-group>

          <div v-if="ticket.type == 'Domicilio'">
            <label for="">Direccion</label>
            <input type="text" class="form-control" v-model="ticket.address" />
          </div>

          <label for="">Hora</label>
          <input type="text" class="form-control" v-model="ticket.hour" />

          <label for="">Notas</label>
          <textarea
            class="form-control"
            v-model="ticket.notes"
            rows="3"
          ></textarea>
        </div>

        <div class="col">
          <label for="">Estado del pago</label>
          <div class="btn-group w-100 mb-2">
            <button
              class="btn"
              :class="{
                'btn-warning': ticket.status == 'Por pagar',
                'btn-outline-warning': ticket.status != 'Por pagar',
              }"
              @click="ticket.status = 'Por pagar'"
            >
              Por pagar
            </button>
            <button
              class="btn"
              :class="{
                'btn-success': ticket.status == 'Pagado',
                'btn-outline-success': ticket.status != 'Pagado',
              }"
              @click="ticket.status = 'Pagado'"
            >
              Pagado
            </button>
          </div>

          <label for="">Medio de pago</label>

          <div class="row no-gutters">
            <div class="col-4">
              <div
                class="custom_scroll"
                style="max-height: 220px; overflow-y: scroll"
              >
                <button
                  class="btn btn-block btn-outline-info btn-sm"
                  v-for="(payment, i) in payments"
                  :key="`payment-${i}`"
                  :disabled="total == 0 || total_payments >= total"
                  @click="setPayment(payment)"
                >
                  {{ payment }}
                </button>
              </div>
            </div>
            <div class="col pt-2">
              <div class="row no-gutters">
                <div class="col-12">
                  <div
                    class="row align-items-center no-gutters mb-2 border py-2 px-3"
                    v-for="(p, i) in ticket.payments"
                    :key="i"
                  >
                    <div class="col font-weight-bold">
                      {{ p.name }}
                    </div>
                    <div class="col-5">
                      <input
                        type="number"
                        class="form-control form-control-sm text-right"
                        v-model.number="p.amount"
                        @focus="$event.target.select()"
                      />
                    </div>
                    <div
                      class="col-1 text-danger text-center click font-weight-bold"
                    >
                      <button
                        class="btn btn-sm btn-outline-danger"
                        @click="removePayment(p)"
                      >
                        <font-awesome-icon icon="times"></font-awesome-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="row">
                    <div class="w-100 border-top"></div>
                    <div class="col">
                      <h4 class="text-right font-weight-bold py-2">
                        TOTAL PAGOS:
                      </h4>
                    </div>
                    <div class="col-4">
                      <h4 class="text-right font-weight-bold py-2">
                        ${{ formatNumber(total_payments) }}
                      </h4>
                    </div>
                    <div class="w-100 border-top"></div>
                    <div class="col">
                      <h4 class="text-right font-weight-bold py-2">VUELTO:</h4>
                    </div>
                    <div class="col-4">
                      <h4 class="text-right font-weight-bold py-2">
                        ${{ formatNumber(total_payments - total) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="ticket.payment == 'Efectivo' && false">
            <label for="">Monto</label>
            <input
              type="text"
              class="form-control"
              v-model.number="ticket.amount"
            />
            <label for="">Vuelto</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                type="text"
                class="form-control"
                disabled
                :value="formatNumber(ticket.amount - ticket.total)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="search-options"
      size="md"
      title="Opciones de busqueda"
      ok-title="Buscar"
      @ok="load()"
    >
      <div class="row align-items-center">
        <div class="col-3">
          <strong>Buscar por</strong>
        </div>
        <div class="col">
          <select v-model="searchMode" class="form-control">
            <option value="date">Fecha seleccionada {{ dateFilter }}</option>
            <option value="history">Historico (30 max)</option>
          </select>
        </div>
      </div>
    </b-modal>

    <!-- end modals -->
  </div>
</template>

<script>
import { ipcRenderer, storage } from "../multi-utils";
import { getDTEName, clearTicket } from "../utils";
const Excel = require("exceljs");

export default {
  name: "TicketList",
  data() {
    return {
      limit: 40,
      filterStatusDelivery: null,
      userFilter: null,
      typeFilter: null,
      paymentFilter: null,
      showSidebar: false,
      page: 1,
      name: "Ventas",
      dateFilter: null,
      ticket: {
        discount: 0,
        products: [],
        payments: [],
      },
      statusCash: false,
      business: {},
      loading: true,
      fakeFilter: "",
      filter: "",
      showStatus: 1,
      tipEnabled: false,
      deliveryEnabled: false,
      payment: "",
      payment_status: "",
      amount: 0,
      viewticket: {},
      cfs: [],
      ticketIndex: -1,
      searchMode: "date",
      cancelable: false,
      value: 10,
      printerMode: "",
      copiesTicket: 1,
      filterStatus: null,
      printers: 2,
    };
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyPress);
  },
  mounted() {
    if (!this.hasPermission(this.user, "ticket_list"))
      return this.$router.push("/");

    if (localStorage.getItem("copiesTicket"))
      this.copiesTicket = localStorage.getItem("copiesTicket");

    if(localStorage.getItem("limit"))
      this.limit = localStorage.getItem("limit");

    if (this.$moment().hour() < 3)
      this.dateFilter = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    else if (this.$route.query.date) this.dateFilter = this.$route.query.date;
    else this.dateFilter = this.$moment().format("YYYY-MM-DD");
    // this.load();
    storage.get("printerMode", (err, data) => {
      this.printerMode = data;
    });
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
      this.fakeFilter = this.$route.query.filter;
    }
    if (this.$route.query.searchMode) {
      this.searchMode = this.$route.query.searchMode;
    }
    window.addEventListener("keyup", this.keyPress);
  },
  methods: {
    setReady(tid) {
      this.$http
        .post(`ticket/${tid}/delivery`, {
          status: "Entregado",
        })
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.$noty.error("Ha ocurrido un error al actualizar el ticket");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDiff(t) {
      if (this.info.timeFormat == "24") {
        return this.$moment(`${t.date} ${t.hour}`, "YYYY-MM-DD HH:mm")
          .set({ second: 30 })
          .diff(t.createdAt, "minutes");
      } else {
        let f = this.$moment(t.createdAt).format("A");
        return this.$moment(`${t.date} ${t.hour} ${f}`, "YYYY-MM-DD hh:mm A")
          .set({ second: 30 })
          .diff(t.createdAt, "minutes");
      }
    },
    timeFormated(time) {
      if (this.info.timeFormat == "24") {
        return this.$moment(time).format("HH:mm");
      } else {
        return this.$moment(time).format("hh:mm");
      }
    },
    groupBy(arr, key) {
      return arr.reduce((result, item) => {
        const groupKey = item[key];
        if (!result[groupKey]) {
          result[groupKey] = [];
          result[groupKey] = item;
        } else {
          result[groupKey].qty = result[groupKey].qty + item.qty;
        }
        return result;
      }, {});
    },
    downloadExcel() {
      var workbook = new Excel.Workbook();

      // Some information about the Excel Work Book.
      workbook.creator = "TicketApp";
      workbook.lastModifiedBy = "";
      workbook.created = new Date();
      workbook.modified = new Date();

      var sheet = workbook.addWorksheet("Ventas");

      sheet.columns = [
        { header: "Cliente", key: "clientName", width: 20 },
        { header: "Telefono", key: "phone", width: 20 },
        { header: "Tipo", key: "type", width: 12 },
        { header: "Direccion", key: "address", width: 20 },
        { header: "Hora", key: "hour", width: 9 },
        { header: "Cajero", key: "user", width: 20 },
        { header: "Notas", key: "notes", width: 20 },
        { header: "Medio de pago", key: "payment", width: 20 },
        { header: "Estado de pago", key: "payment_status", width: 15 },
        { header: "Total", key: "total", width: 13 },
      ];

      var sheetOnlyDetails = workbook.addWorksheet(`Solo detalles`);

      sheetOnlyDetails.columns = [
        { headers: "Orden", key: "number", width: 20 },
        { headers: "Codigo", key: "code", width: 20 },
        { header: "Producto", key: "name", width: 40 },
        { header: "Cantidad", key: "qty", width: 20 },
        { header: "Valor", key: "value", width: 20 },
        { header: "Total", key: "total", width: 20 },
      ];

      var sheetOnlyDetailsTotal = workbook.addWorksheet(`Total detalles`);

      sheetOnlyDetailsTotal.columns = [
        { headers: "Codigo", key: "code", width: 20 },
        { header: "Producto", key: "name", width: 40 },
        { header: "Cantidad", key: "qty", width: 20 },
        { header: "Valor", key: "value", width: 20 },
      ];

      //sheetOnlyDetails.getColumn("B").width = 40;
      let products = [];

      this.getTickets.forEach((t) => {
        sheet.addRow({
          clientName: t.clientName == "anon" ? "Anonimo" : t.clientName,
          phone: t.phone || "Sin telefono",
          type: t.type,
          address: t.address || "- - -",
          payment: t.payments.map((p) => p.name).join(","),
          user: t.user_sell.name,
          payment_status: t.status,
          hour: t.hour || "- - -",
          notes: t.notes || "- - -",
          total: t.total,
        });

        t.products.forEach((p) => {
          //sheetOnlyDetails.addRow();
          products.push({
            number: t.number,
            code: p.code || "- - -",
            name: p.name,
            qty: p.qty,
            value: p.value,
            total: p.total,
          });
        });

        // add details in new sheet
        let name = `${t.clientName == "anon" ? "#" : t.clientName} ${t.number}`;
        var sheet2 = workbook.addWorksheet(name);

        // info of ticket

        // set width cell a1
        sheet2.getColumn("A").width = 20;
        sheet2.getColumn("B").width = 30;
        sheet2.getCell("A1").value = "Cliente";
        sheet2.getCell("A1").style = { font: { bold: true } };
        sheet2.getCell("B1").value =
          t.clientName == "anon" ? "Anonimo" : t.clientName;

        sheet2.getCell("A2").value = "Telefono";
        sheet2.getCell("A2").style = { font: { bold: true } };
        sheet2.getCell("B2").value = t.phone || "Sin telefono";

        sheet2.getCell("A3").value = "Tipo";
        sheet2.getCell("A3").style = { font: { bold: true } };
        sheet2.getCell("B3").value = t.type;

        sheet2.getCell("A4").value = "Direccion";
        sheet2.getCell("A4").style = { font: { bold: true } };
        sheet2.getCell("B4").value = t.address || "- - -";

        sheet2.getCell("A5").value = "Hora";
        sheet2.getCell("A5").style = { font: { bold: true } };
        sheet2.getCell("B5").value = t.hour || "- - -";

        sheet2.getCell("A6").value = "Notas";
        sheet2.getCell("A6").style = { font: { bold: true } };
        sheet2.getCell("B6").value = t.notes || "- - -";

        sheet2.getCell("A7").value = "Medio de pago";
        sheet2.getCell("A7").style = { font: { bold: true } };
        sheet2.getCell("B7").value = t.payments.map((p) => p.name).join(",");

        sheet2.getCell("A8").value = "Estado de pago";
        sheet2.getCell("A8").style = { font: { bold: true } };
        sheet2.getCell("B8").value = t.status;

        sheet2.getCell("A9").value = "Total";
        sheet2.getCell("A9").style = { font: { bold: true } };
        sheet2.getCell("B9").value = t.total;

        sheet2.getCell("A11").value = "Detalle";
        sheet2.getCell("C11").value = "Cantidad";
        sheet2.getCell("D11").value = "Valor";
        sheet2.getCell("E11").value = "Total";

        let index = 12;
        t.products.forEach((p, i) => {
          sheet2.getCell(`A${i + index}`).value = p.name;
          sheet2.mergeCells(`A${i + index}:B${i + index}`);
          sheet2.getCell(`C${i + index}`).value = p.qty;
          sheet2.getCell(`D${i + index}`).value = p.value;
          sheet2.getCell(`E${i + index}`).value = p.total;
        });

        // t.products.forEach((p) => {
        //   sheet2.addRow({
        //     name: p.name,
        //     qty: p.qty,
        //     value: p.value,
        //     total: p.total,
        //   })
        // })
      });

      products.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name < b.name ? -1 : 1;
      });

      sheetOnlyDetails.addRows(products);

      let productsGroupByCode = Object.values(this.groupBy(products, "code"));
      console.log(productsGroupByCode);
      sheetOnlyDetailsTotal.addRows(productsGroupByCode);

      // sheetOnlyDetailsTotal.addRows(productsGroupByCode);

      workbook.xlsx.writeBuffer().then((buff) => {
        let $el = document.createElement("a");
        $el.setAttribute(
          "href",
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            buff.toString("base64")
        );
        let date = this.$moment().format("DD-MM-YYYY");
        $el.setAttribute("download", `ventas-${date}.xlsx`);
        $el.style.display = "none";
        document.body.appendChild($el);
        $el.click();
        document.body.removeChild($el);
      });
    },
    ticketEditable(t) {
      if (this.user.admin) return true;
      if (!t.deletedAt && this.$moment().diff(t.createdAt, "days") < 1)
        return true;
      return false;
    },
    editStatus(date) {
      let user = this.$store.state.user;
      if (user && user.admin) return false;
      if (this.$moment().diff(date, "days") > 2) return true;
    },
    modify() {
      //thi
    },
    getDTEName(id) {
      return getDTEName(id);
    },
    getShortName(user = {}) {
      const { name } = user;
      if (!name) return "";
      const [firstName] = name.split(" ");
      return firstName;
    },
    printTicketRemote(t, type) {
      this.$http
        .post("print", { ticket: t._id, type })
        .then(() => {
          this.$noty.success("Enviado a imprimir en red");
        })
        .catch(() => {
          this.$noty.error("Error al imprimir en red. Intente nuevamente");
        });
    },
    discountToPercent(amount, total) {
      return ((amount * 100) / total).toFixed(2);
    },
    copy(ticket) {
      //this.$store.commit("setCopyTicket", ticket);
      let ticketClear = clearTicket();
      //this.table.status = "available";
      //this.$refs.modal.hide();

      // let products = ticket.products.map((p) => {
      //   return {
      //     ...p,
      //     options: [],
      //     total: p.value * p.qty,
      //     discount: 0,
      //     iva: true,
      //   };
      // });



      let newTickets = localStorage.getItem("newTickets")
        ? JSON.parse(localStorage.getItem("newTickets"))
        : [];
      newTickets.push({
        ...ticketClear,
        ...ticket,
      });
      localStorage.setItem("newTickets", JSON.stringify(newTickets));
      this.$router.push(`/new`);
    },
    dateAdd() {
      this.$store.commit("setTickets", []);
      this.dateFilter = this.$moment(this.dateFilter)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    dateSubs() {
      this.$store.commit("setTickets", []);
      this.dateFilter = this.$moment(this.dateFilter)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    resend(ticket) {
      this.$noty.info("Enviando...");
      this.loading = true;
      this.$http
        .post(`ticket/${ticket._id}/resend`)
        .then(() => {
          this.$noty.success("Enviado al SII");
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al actualizar intente nuevamente");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendCFToday() {
      this.$http
        .post(`cf?date=${this.dateFilter}`)
        .then(() => {
          this.$noty.success("Enviado al SII");
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al enviar, intente nuevamente");
        });
    },
    updateCF(trackid) {
      this.$http
        .get(`cf/${trackid}`)
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al actualizar intente nuevamente");
        });
    },
    saveInfo() {
      const ticket = this.ticket;
      this.$http
        .post(`ticket/${ticket._id}/info`, {
          payments: ticket.payments,
          status: ticket.status,
          amount: ticket.amount,
          hour: ticket.hour,
          clientName: ticket.clientName,
          phone: ticket.phone,
          type: ticket.type,
          address: ticket.address,
          notes: ticket.notes,
        })
        .then(() => {
          this.$noty.success("Ticket actualizado!");
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al actualizar el pago");
        });
    },
    editPayment(ticket) {
      this.ticket = ticket;
      this.$bvModal.show("changePayment");
    },
    view(ticket = null) {
      // localStorage.printMode = 1;
      this.ticket = ticket;
      this.$store.commit("setTicket", ticket || this.ticket);
      this.$emit("showTicket");
      event.preventDefault();
    },
    printTicket(ticket = null, type = 1, copies = 1) {
      this.$emit("printTicket", {
        ticket,
        type,
        copies,
        printers: this.printers,
      });
    },
    updateStatusCash(val) {
      this.statusCash = val;
    },
    keyPress(e) {
      // if (e.key == "ArrowRight") this.dateAdd();
      // if (e.key == "ArrowLeft") this.dateSubs();
      if (e.key == "ArrowDown") {
        e.preventDefault();
        if (this.ticketIndex >= 2) window.scroll(0, window.scrollY + 100);
        let ticket = this.tickets[this.ticketIndex + 1];
        if (ticket) {
          this.ticket = ticket;
          this.$store.commit("setTicket", ticket);
          this.ticketIndex = this.ticketIndex + 1;
          //this.$root.$emit("bv::toggle::collapse", `ticket-${ticket._id}`);
        }
      }
      if (e.key == "ArrowUp") {
        e.preventDefault();
        if (this.ticketIndex < this.tickets.length - 2)
          window.scroll(0, window.scrollY - 85);
        // if (this.ticketIndex < this.tickets.length) return false;
        // else {
        let ticket = this.tickets[this.ticketIndex - 1];
        if (ticket) {
          this.ticket = ticket;
          this.$store.commit("setTicket", ticket);
          this.ticketIndex = this.ticketIndex - 1;
          //this.$root.$emit("bv::toggle::collapse", `ticket-${ticket._id}`);
        }
        // }
      }
      // if (
      //   e.key == "Enter" &&
      //   Object.keys(this.ticket).length > 0 //&&
      //   //!this._cancel
      // )
      //   this.view();
      if (e.code == "Space" && Object.keys(this.ticket).length > 0) {
        this.$root.$emit("bv::toggle::collapse", `ticket-${this.ticket._id}`);
        e.preventDefault();
      }
      // this.$noty.info("Press down");
      // e.preventDefault();
    },
    setStatus(id, status) {
      this.$http
        .post(`ticket/${id}`, { status })
        .then(() => {
          this.$noty.success("Ticket actualizado");
          this.load();
        })
        .catch(() => {
          this.$noty.error("No se pudo actualizar el ticket");
        });
    },
    down() {
      this.$noty.info("Tu press down key");
    },
    loadTicket(ticket) {
      this.$root.$emit("bv::hide::collapse", `ticket-${this.ticket._id}`);
      if (!ticket._id) {
        // this.ticket = {};
        return false;
      }
      this.ticket = ticket;
      this.$store.commit("setTicket", ticket);
      this.ticketIndex = this.tickets.indexOf(ticket) || 0;
      this.$root.$emit("bv::toggle::collapse", `ticket-${ticket._id}`);
    },
    load() {
      this.loading = true;
      this.statusCash = false;
      //this.ticket = {};
      this.ticketIndex = -1;

      this.filter = this.fakeFilter;

      // let url = `ticket?date=${this.dateFilter}&searchmode=${this.searchMode}&filter=${this.filter}`;
      // this.$http
      //   .get(url)
      //   .then((res) => {
      //     this.$store.commit("setTickets", res.data.data);
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
      this.$store
        .dispatch("getTickets", {
          date: this.dateFilter,
          searchmode: this.searchMode,
          filter: this.filter,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });

      // this.$http.get("info").then((res) => {
      //   this.deliveryEnabled = res.data.data.deliveryEnabled;
      //   this.tipEnabled = res.data.data.tipEnabled;
      //   this.$store.commit("setInfo", res.data.data);
      // });

      // this.$http.get("notification").then((res) => {
      //   this.$store.commit("setNotifications", res.data.data);
      // });

      // if (this.info.webEnabled) {
      //   this.$http.get("web/ticket").then((res) => {
      //     this.$store.commit("setWebTickets", res.data.data);
      //   });
      // }
    },
    cancel(id, ticket) {
      this.$swal({
        type: "warning",
        title: "Cancelar",
        text: "Ingrese un motivo! (Obligatorio. min 3)",
        content: "input",
      }).then((reason) => {
        if (reason != null && reason != "" && reason.length > 3) {
          ticket.deletedAt = Date.now();
          this.$http
            .delete("ticket", { body: { id, reason } })
            .then(() => {
              this.$root.$emit("bv::toggle::collapse", `ticket-${id}`);
              this.load();
              this.$noty.success("Ticket cancelado");
            })
            .catch(() => {
              this.$noty.error("Error al cancelar");
            })
            .finally(() => {
              this._cancel = false;
            });
        } else {
          this.$noty.error("Ingrese un motivo valido");
        }
      });
    },
    showMap(address) {
      ipcRenderer.send("showMap", address);
    },
    removePayment(payment) {
      this.ticket.payments.splice(this.ticket.payments.indexOf(payment), 1);
    },
    setPayment(payment) {
      let amount = this.total - this.total_payments;
      this.ticket.payments.push({
        name: payment,
        amount,
        total: amount,
      });
      // this.ticket.payment = payment;
    },
  },
  computed: {
    numberOfPages() {
     if(this.getTickets.length < this.limit && this.page == 1) return 1;
      return Math.ceil(this.tickets.length / this.limit);
    },
    getDateFilterType() {
      if (this.searchMode == "month") return "month";
      else if (this.searchMode == "week") return "week";
      else return "date";
    },
    users() {
      const userList = [];
      const users = this.tickets
        .map((t) => {
          return t.user_sell;
        })
        .reduce((acc, user) => {
          if (!acc[user._id]) {
            acc[user._id] = user;
            acc[user._id].total = 0;
          }
          acc[user._id].total = acc[user._id].total + 1;
          return acc;
        }, {});
      return users;
    },
    total() {
      if (
        this.ticket.products.length == 0 ||
        this.ticket.products == "undefined"
      )
        return 0;
      var total = this.ticket.products.reduce((total, product) => {
        return total + product.total;
      }, 0);
      total = total - this.ticket.discount;
      return Math.round(total);
    },
    total_payments() {
      if (!this.ticket.payments) return 0;
      return this.ticket.payments.reduce((t, p) => t + p.amount, 0);
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    info() {
      return this.$store.state.info;
    },
    tickets() {
      return [
        ...this.$store.state.pending_tickets,
        ...this.$store.state.tickets,
      ];
    },
    getTicketsCanceled() {
      return this.tickets.reduce((acc, t) => {
        if (t.deletedAt) acc = acc + 1;
        return acc;
      }, 0);
    },
    getTickets() {
      // if (this.filter == "" && this.searchMode == "history") {
      //   return this.tickets;
      // }
      // else {
      let {
        filter,
        filterStatus,
        paymentFilter,
        typeFilter,
        userFilter,
        filterStatusDelivery,
      } = this;
      filter = this.filter.toLowerCase();

      const filterTickets = (ticket) => {
        // console.log({
        //   filterStatus: this.filterStatus,
        //   status: ticket.status,
        //   _if: ticket.status == this.filterStatus,
        // });
        let isReturn = false;

        if (`#${ticket.number}` == filter) isReturn = true;
        if (`TICKET_${ticket.number}` == filter) isReturn = true;
        if (`#${ticket.folio}` == filter) isReturn = true;

        // #DTE-33
        if (`/DTE-${ticket.dteType}` == `${filter}`) isReturn = true;
        //if(ticket.status == this.filterStatus) isReturn = true;
        // check if filterStatus equal to ticket status
        if (filterStatus != null && filterStatus == ticket.status) {
          isReturn = true;
        }

        if (typeFilter != null && typeFilter == ticket.type) {
          isReturn = true;
        }

        const payments = ticket.payments.map((p) => p.name);

        // if (paymentFilter != null) {
        //   if (payments.includes(paymentFilter)) {
        //     isReturn = true;
        //   }
        // }

        if (userFilter != null) {
          if (ticket.user == userFilter && paymentFilter == null) {
            isReturn = true;
          } else {
            if (payments.includes(paymentFilter) && ticket.user == userFilter) {
              isReturn = true;
            }
          }
        } else {
          if (payments.includes(paymentFilter)) {
            isReturn = true;
          }
        }

        if (filterStatusDelivery != null) {
          if (
            filterStatusDelivery == "Entregado" &&
            ticket.delivery.status == "Entregado"
          ) {
            isReturn = true;
          }
          if (
            filterStatusDelivery == "Esperando" &&
            ticket.delivery.status == "Esperando"
          ) {
            isReturn = true;
          }
        }

        if (filter.length > 0) {
          if (
            ticket.clientName.toLowerCase().indexOf(filter) > -1 ||
            ticket.address.toLowerCase().indexOf(filter) > -1 ||
            ticket.phone.toLowerCase().indexOf(filter) > -1
          )
            isReturn = true;
        }
        //isReturn = false;
        console.log("filter");
        return isReturn;
      };

      const limit = this.limit || this.tickets.length;

      if (
        filter ||
        filterStatus ||
        paymentFilter ||
        typeFilter ||
        userFilter ||
        filterStatusDelivery
      ) {
        return this.tickets
          .filter(filterTickets)
          .slice((this.page - 1) * limit, this.page * limit);
      } else {
        return this.tickets.slice(
          (this.page - 1) * limit,
          this.page * limit
        );
      }
    },
    // total() {
    //   return this.tickets.reduce((t, ticket) => {
    //     if (ticket.deletedAt == "" || ticket.deletedAt == null)
    //       return t + ticket.total;
    //     else return t;
    //   }, 0);
    // },
    date() {
      return new Date();
    },
    prevDay() {
      if (this.date.getDate() < 11) {
        return `0${this.date.getDate() - 1}`;
      }
      return this.date.getDate() - 1;
    },
    day() {
      if (this.date.getDate() < 10) {
        return `0${this.date.getDate()}`;
      }
      return this.date.getDate();
    },
    month() {
      let month = this.date.getMonth();
      if (month < 9) {
        return `0${month + 1}`;
      } else {
        return month + 1;
      }
    },
    year() {
      return this.date.getFullYear();
    },
  },
  watch: {
    page() {
      window.scroll(0, 0);
    },
    copiesTicket() {
      localStorage.copiesTicket = this.copiesTicket;
    },
    fakeFilter() {
      if (this.fakeFilter == "") this.filter = "";
    },
    filter() {
      // if (this.filter == "" && this.searchMode == "history") {
      //   this.searchMode = "date";
      //   this.load();
      // }
    },
    searchMode() {
      this.$router.push({
        query: {
          ...this.$route.query,
          searchMode: this.searchMode,
        },
      }).catch(() => {});
    },
    dateFilter: function () {
      this.$router.push({
        query: {
          ...this.$route.query,
          date: this.dateFilter,
        },
      }).catch(() => {});
      this.load();
    },
    showStatus: function (val) {
      this.$cookies.set("showStatus", val);
    },
    limit() {
      localStorage.setItem("limit", this.limit);
    }
  },
};
</script>

<style scoped>
.clickeable {
  cursor: pointer;
}
.ticket-scroll {
  padding: 5px;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  padding-right: 5px;
}
.ticket-scroll:hover {
  scrollbar-width: thin;
}
</style>
