<template>
  <div class="container">
    <div class="row justify-content-center">
      
      <div class="col-7 col-sm-8 col-xs-12">
        <h3 class="text-center">Recuperar contraseña</h3>
        <hr>

        <div class="alert alert-success mt-2" role="alert" v-if="send">
          Si el correo es valido, recibira un codigo que debe ingresar aca abajo
        </div>

        <label class="mt-1">Email</label>
        <input 
          type="text" 
          class="form-control" 
          placeholder="correo@dominio.cl"
          v-focus
          @keyup.enter="sendEmail()"
          :disabled="send"
          v-model="email">

        <label 
          class="mt-2" 
          v-if="send">Codigo de recuperacion</label>
        <input 
          type="text" 
          class="form-control" 
          placeholder="Ingrese codigo"
          v-focus
          v-if="send"
          v-model="code">
        
        <label 
          class="mt-2" 
          v-if="isValidCode">Ingrese nueva contraseña</label>
        <input 
          type="password" 
          class="form-control" 
          placeholder="********"
          v-focus
          v-if="isValidCode"
          v-model="newPassword">

        <label 
          class="mt-2" 
          v-if="isValidCode">Repita nueva contraseña</label>
        <input 
          type="password" 
          class="form-control" 
          placeholder="********"
          v-if="isValidCode"
          v-model="newPassword2">

        <button
          type="button"
          class="btn btn-primary mt-4 btn-block"
          :disabled="email == ''"
          @click="sendEmail()"
          v-if="!send">Enviar
        </button>

        <div class="alert alert-danger mt-3" role="alert" v-if="newPassword.length < 0 || newPassword != newPassword2">
          Las contraseñas no coinciden
        </div>

        <button
          type="button"
          v-if="send"
          :disabled="newPassword.length < 0 || newPassword != newPassword2"
          class="btn btn-success mt-4 btn-block"
          @click="changePassword()"
          >Cambiar contraseña
        </button>
        <router-link to="/" class="btn btn-link btn-block">Volver</router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Recover',
  data() {
    return {
      send: false,
      email: '',
      code: '',
      newPassword: '',
      newPassword2: ''
    }
  },
  computed: {
    isValidCode() {
      return this.code.length > 3
    }
  },
   methods: {
    changePassword() {
      this.$http.post('auth/password', {
        code: this.code,
        email: this.email,
        newPassword: this.newPassword,
      })
      .then(() => {
        this.$noty.success('Cambio de contraseña exitoso!')
        this.$router.push('/')
      })
      .catch(() => {
        this.$noty.error('Ha ocurrido un error.')
      })
    },
    sendEmail() {
      this.$http.post('auth/recover', {
        email: this.email
      })
        .then(() => {
          this.send = true
          this.showCode = true
          this.$noty.success('Correo enviado')
        })
        .catch(() => {
          this.$noty.error('Error al enviar el correo, intente nuevamente')
        })
    }
  }
}
</script>