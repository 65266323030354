<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-3 ml-auto px-4">
            <button
              class="btn btn-success btn-block"
              :disabled="loading"
              @click="load()"
            >
              <b-spinner small v-show="loading"></b-spinner>
              ACTUALIZAR
            </button>
          </div>
          <div class="col-4 py-2">
            <date-picker
              valueType="format"
              v-model="dateFilter"
              type="month"
              input-class="form-control pl-4"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row py-3">
          <div class="col text-center">
            <div class="border py-4">
              <h1 class="animated fadeIn">
                <font-awesome-icon icon="clock" size="lg" />
                <div class="w-100 py-1"></div>
                {{ stats.total || 0 }}
              </h1>
              <small class="text-uppercase">TOTAL ESTE MES</small>
            </div>
          </div>
          <div class="col text-center">
            <div class="border py-4">
              <h1 class="text-success animated fadeIn">
                <font-awesome-icon icon="user-check" size="lg" />
                <div class="w-100 py-1"></div>
                {{ stats.acepted || 0 }}
              </h1>
              <small class="text-uppercase">TOTAL ACEPTADOS</small>
            </div>
          </div>
          <div class="col text-center">
            <div class="border py-4">
              <h1 class="text-danger animated fadeIn">
                <font-awesome-icon icon="user-times" size="lg" />
                <div class="w-100 py-1"></div>
                {{ stats.rejected || 0 }}
              </h1>
              <small class="text-uppercase">TOTAL RECHAZADOS</small>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-12 py-3 px-4">
        <h4 class="font-weight-bold mb-2">MEDIOS DE PAGOS</h4>
        <div class="row">
          <div
            class="col-4 px-1 mb-2"
            v-for="(payment, i) in stats.payments"
            :key="`payment-${i}`"
          >
            <div class="row no-gutters">
              <div class="col border bg-light px-3 py-3">
                <h5 class="font-weight-bolder">
                  ${{ payment.total | formatNumber }}
                </h5>
                <div class="w-100"></div>
                <span class="text-uppercase"
                  >{{ payment._id }} ({{ payment.items }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-12 py-3">
        <h4 class="font-weight-bold mb-2">POR SEMANA</h4>
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="week in stats.weeks"
            :key="week._id"
          >
            <div class="row">
              <div class="col">
                <small>DESDE</small>
                <div class="w-100"></div>
                <strong>{{
                  $moment()
                    .week(week._id)
                    .startOf("isoWeek")
                    .format("ddd DD/MM")
                }}</strong>
                <br />
                <strong>{{
                  $moment().week(week._id).startOf("week").format("ddd DD/MM")
                }}</strong>
              </div>
              <div class="col">
                <small>HASTA</small>
                <div class="w-100"></div>
                <strong>{{
                  $moment().week(week._id).endOf("isoWeek").format("ddd DD/MM")
                }}</strong>
              </div>
              <div class="col text-center">
                <small>CANTIDAD</small>
                <div class="w-100"></div>
                <strong>{{ week.items }}</strong>
              </div>
              <div class="col text-right">
                <small>TOTAL EN PESOS</small>
                <div class="w-100"></div>
                <strong>${{ week.total | formatNumber }}</strong>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 ml-auto text-right">
                <strong>${{ stats.aceptedAmount.total | formatNumber }}</strong>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="w-100"></div>
      <div class="col">
        {{ stats }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stats: {
        aceptedAmount: {},
      },
      loading: true,
      dateFilter: null,
    };
  },
  mounted() {
    this.dateFilter = this.$moment().format();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(`web/stats?date=${this.dateFilter}`)
        .then((res) => {
          this.stats = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dateFilter() {
      this.load();
    },
  },
};
</script>