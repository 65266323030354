
import {
  faUniversity,
  faUsers,
  faStickyNote,
  faHome,
  faHotel,
  faMapMarkedAlt,
  faClock,
  faUserClock,
  faCreditCard,
  faTimes,
  faMoneyBillWave,
  faCarSide,
  faStopwatch,
  faUserCheck,
  faUserTimes,
  faRedo,
  faTrash,
  faPen,
  faWindowClose,
  faWindowMaximize,
  faWindowMinimize,
  faEllipsisH,
  faEllipsisV,
  faQuestion,
  faStar,
  faCalendarAlt,
  faSearch,
  faEye,
  faPlus,
  faPhone,
  faChartLine,
  faSave,
  faFileDownload,
  faFileUpload,
  faCheckCircle,
  faDatabase,
  faFileExcel,
  faFileCsv,
  faFileExport,
  faTimesCircle,
  faShoppingCart,
  faArrowCircleRight,
  faBackspace,
  faBuilding,
  faCloudUploadAlt,
  faBell,
  faPlug,
  faArrowLeft,
  faArrowRight,
  faCog,
  faUserCircle,
  faKey,
  faSpinner,
  faPrint,
  faEraser,
  faCircle,
  faDotCircle,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faTicketAlt,
  faCashRegister,
  faFileAlt,
  faCube,
  faCubes,
  faMap,
  faTv,
  faLaptop,
  faShareAlt,
  faQrcode,
  faGlobe,
  faCaretDown,
  faCaretUp,
  faQuestionCircle,
  faExclamationCircle,
  faPlay,
  faPlayCircle,
  faArrowUp,
  faArrowDown,
  faTruck,
  faHeart,
  faComments,
  faExternalLinkAlt,
  faPaperPlane,
  faSitemap,
  faSync,
  faSyncAlt,
  faCamera,
  faCalculator,
  faCopy,
  faListUl,
  faList,
  faPiggyBank
} from '@fortawesome/free-solid-svg-icons'

const icons = [
  faUniversity,
  faPiggyBank,
  faListUl,
  faList,
  faCopy,
  faCalculator,
  faPaperPlane,
  faExternalLinkAlt,
  faComments,
  faHeart,
  faTruck,
  faCaretDown,
  faCaretUp,
  faGlobe,
  faUsers,
  faTicketAlt,
  faStickyNote,
  faHome,
  faHotel,
  faMapMarkedAlt,
  faClock,
  faUserClock,
  faCreditCard,
  faMoneyBillWave,
  faCarSide,
  faStopwatch,
  faUserCheck,
  faUsers,
  faRedo,
  faTrash,
  faPen,
  faTimes,
  faWindowClose,
  faWindowMaximize,
  faWindowMinimize,
  faEllipsisH,
  faEllipsisV,
  faQuestion,
  faStar,
  faCalendarAlt,
  faSearch,
  faEye,
  faPlus,
  faPhone,
  faChartLine,
  faSave,
  faFileDownload,
  faFileUpload,
  faCheckCircle,
  faDatabase,
  faFileExcel,
  faFileCsv,
  faFileExport,
  faTimesCircle,
  faShoppingCart,
  faArrowCircleRight,
  faBackspace,
  faBuilding,
  faCloudUploadAlt,
  faBell,
  faPlug,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faCog,
  faUserCircle,
  faKey,
  faSpinner,
  faPrint,
  faEraser,
  faCircle,
  faDotCircle,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faCashRegister,
  faUserTimes,
  faFileAlt,
  faCube,
  faCubes,
  faChartLine,
  faMap,
  faTv,
  faLaptop,
  faShareAlt,
  faQrcode,
  faQuestionCircle,
  faExclamationCircle,
  faPlay,
  faPlayCircle,
  faSitemap,
  faSync,
  faSyncAlt,
  faCamera
]

export default icons