<template>
  <div class="container-fluid">
    <b-overlay :show="loading">
      <div class="row align-items-end">
        <div class="col-12">
          <h4>Cupones ({{ coupons.length }})</h4>
        </div>
        <div class="col">
          <small>BUSCAR</small>
          <input
            type="text"
            class="form-control"
            placeholder="Buscar cupon"
            v-model="fakesearch"
            @keyup.enter="search = fakesearch"
          />
        </div>
        <div class="col-lg-2">
          <button
            class="btn btn-primary btn-block"
            @click="showCreateCouponModal()"
          >
            CREAR CUPON
          </button>
        </div>
        <!-- <div class="col-lg-2 ml-auto d-none1">
          <button
            class="btn btn-outline-secondary btn-block"
            :disabled="loading"
            @click="loadCoupons()"
          >
            ACTUALIZAR
          </button>
        </div> -->
        <div class="w-100 py-2"></div>
        <div class="col h-100 overflow-scroll">
          <ul class="list-group">
            <li class="list-group-item" v-if="coupons.length == 0">
              <h4 class="text-center">No tienes cupones</h4>
            </li>
            <li
              class="list-group-item"
              v-for="c in couponList"
              :key="c._id"
              :class="{
                'list-group-item-warning': !c.enabled,
                'list-group-item-danger': c.qty == c.used,
                'active text-white': c._id == coupon._id,
              }"
            >
              <div class="row align-items-center">
                <div class="col-1 text-center">
                  <small>{{ c.createdAt | moment("DD/MM/YY") }}</small>
                  <!-- habilitado switch -->
                  <b-checkbox
                    switch
                    size="md"
                    v-model="c.enabled"
                    @input="updateCoupon(c)"
                  ></b-checkbox>
                  
                </div>
                <div class="col-12 col-lg-4">
                  <small class="text-uppercase">{{ c.name }}</small>
                  <div class="w-100"></div>
                  <strong class="text-uppercase">{{ c.code }}</strong>
                </div>
                <div class="col-2 text-center">
                  <small>Expira</small>
                  <div class="w-100"></div>
                  <strong>{{ c.expires | moment("DD/MM/YY") || '--/--/--' }}</strong>
                </div>
                <div class="col-lg-2 col-4 text-center">
                  <small> USADOS / CREADOS</small>
                  <div class="w-100"></div>
                  <strong>{{ c.used }} / {{ c.qty }} </strong>
                </div>
                <div class="col-lg-2 col-4">
                  <small>MONTO</small>
                  <div class="w-100"></div>
                  <strong class="text-uppercase">{{
                    getType(c.type, c.amount)
                  }}</strong>
                  <strong class="text-uppercase"> al {{ c.to }}</strong>
                </div>

                <div class="col-2 d-none">
                  <small>SOLO / EXENTOS</small>
                  <div class="w-100"></div>
                  <strong class="text-uppercase"
                    >{{ c.only.length }} / {{ c.exclude.length }}</strong
                  >
                </div>
                <div class="col-1">
                  <button class="btn btn-link" @click="editCoupon(c)">
                    <font-awesome-icon icon="pen" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-overlay>

    <!-- Modal Component -->
    <b-modal
      id="modal-create-coupon"
      ref="modal-create-coupon"
      size="lg"
      title="Cupon"
      @ok="saveCoupon()"
    >
      <div class="row">
        <div class="col">
          <div class="form-group bgwhite border p-2">
            <label for="">Codigo del cupon</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="50OFFDIC22"
                v-model="coupon.code"
                :disabled="coupon.used > 0"
                :class="{
                  'is-invalid':
                    coupon.code == '' ||
                    !coupon.code.match(/^[A-Z0-9]+$/) ||
                    coupon.code.length < 4,
                  'is-valid': coupon.code != '' && coupon.code.length >= 4,
                }"
                ref="input-coupon-code"
                @input="coupon.code = $event.target.value.toUpperCase()"
              />
            </div>
            <small>DEBE SER UNICO, SIN ESPACIOS</small>

            <div v-if="coupon.used == 0">
<b-checkbox class="" size="sm" v-model="generateWithBName"
              >INCLUIR NOMBRE AL GENERAR</b-checkbox
            >

            <button
              class="btn btn-outline-primary btn-sm"
              
              @click="generateCode()"
            >
              GENERAR
            </button>
            </div>
            
          </div>

          <div class="form-group">
            <label for="">Nombre descriptivo</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': coupon.name == '',
                'is-valid': coupon.name != '',
              }"
              placeholder="Codigo 50% navidad 2022"
              v-model="coupon.name"
            />
          </div>

          <div class="form-group">
            <label for="">Descontar en</label>
            <select class="form-control" v-model="coupon.type">
              <option value="amount">PESOS</option>
              <option value="percent">Porcentaje</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Monto a descontar</label>
            <input
              type="text"
              class="form-control"
              placeholder="5000"
              @focus="$event.target.select()"
              :class="{
                'is-invalid': coupon.amount == 0,
                'is-valid': coupon.amount > 0,
              }"
              v-model.number="coupon.amount"
            />
          </div>

          <div class="form-group">
            <label for="">Descontar al</label>
            <select class="form-control" v-model="coupon.to">
              <option value="total">Total del pedido</option>
              <option value="delivery">Solo Delivery</option>
              <option value="subtotal">Sub total (no incluye delivery)</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div>
            <label for="">Solo para</label>
            <v-select
              v-model="coupon.only"
              taggable
              class="py-1"
              multiple
              append-to-body
              :options="getProducts"
            ></v-select>
          </div>
          <div>
            <label for="">Excluir</label>
            <v-select
              v-model="coupon.exclude"
              taggable
              class="py-1"
              append-to-body
              multiple
              :options="getProducts"
            ></v-select>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="">Cantidad</label>
                <input
                  type="text"
                  class="form-control"
                  @focus="$event.target.select()"
                  placeholder="5000"
                  v-model.number="coupon.qty"
                  :class="{
                    'is-invalid': coupon.qty == 0 || coupon.qty < coupon.used,
                    'is-valid': coupon.qty > 0,
                  }"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">Usados</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  placeholder="0"
                  v-model.number="coupon.used"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Expira</label>
            <date-picker
              v-model="coupon.expires"
              value-type="format"
              :input-props="{ class: 'form-control' }"
            ></date-picker>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="">Minimo de compra</label>
                <input
                  type="number"
                  v-model.number="coupon.min"
                  class="form-control text-right"
                  @focus="$event.target.select()"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">Maximo de compra</label>
                <input
                  type="number"
                  v-model.number="coupon.max"
                  class="form-control text-right"
                  @focus="$event.target.select()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      generateWithBName: true,
      fakesearch: "",
      search: "",
      loading: false,
      coupons: [],
      coupon: {},
    };
  },
  mounted() {
    this.loadCoupons();
    this.clearCoupon();
  },
  computed: {
    couponList() {
      return this.coupons.filter((c) => {
        return (
          c.code.toLowerCase().includes(this.search.toLowerCase()) ||
          c.name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getProducts() {
      if (!this.products) return [];
      return this.products.map((o) => ({
        ...o,
        label: `${o.code} - ${o.name} $${this.formatNumber(o.value)}`,
      }));
    },
    products() {
      return this.$store.state.products;
    },
  },
  methods: {
    generateCode() {
      //
      let code = Math.random().toString(36).substring(2, 20).toUpperCase();
      code = code.replace(/O/g, "0");
      let bName =
        this.$store.state.info.custom_name || this.$store.state.info.name;
      bName = bName.replace(/ /g, "").toUpperCase();
      if(this.generateWithBName){
        code = `${bName.substring(0, 10)}${code.substring(0, 5)}`;
      }
      else code = `${code.substring(0, 10)}`;

      this.coupon.code = code;
    },
    clearCoupon() {
      this.coupon = {
        code: "",
        name: "",
        type: "amount",
        amount: 0,
        to: "total",
        only: [],
        exclude: [],
        qty: 0,
        used: 0,
        expires: null,
      };
    },
    editCoupon(coupon) {
      this.coupon = coupon;
      this.$refs["modal-create-coupon"].show();
    },
    updateCoupon(c) {
      this.loading = true;
      this.$http
        .patch(`coupon/${c._id}`, c)
        .then(() => {
          this.$noty.success("Actualizado");
          this.clearCoupon();
        })
        .catch((err) => {
          console.log("Error cupon", err);
        })
        .finally(() => {
          this.loadCoupons();
        });
    },
    getType(type, amount) {
      if (type == "amount") {
        return "$" + amount;
      } else {
        return amount + "%";
      }
    },
    showCreateCouponModal() {
      this.clearCoupon();
      this.$refs["modal-create-coupon"].show();
      setTimeout(() => {
        this.$refs["input-coupon-code"].focus();
      }, 500);
    },
    showModalCoupon() {
      this.loadCoupons();
      this.$refs["modal-coupon"].show();
    },
    saveCoupon() {
      if(this.coupon.amount > 100 && this.coupon.type == 'percent'){
        this.$noty.error('El porcentaje no puede ser mayor a 100%');
        return;
      }

      if (this.coupon._id) {
        this.updateCoupon(this.coupon);
        return;
      } else {
        if (this.coupon.code == "" || this.coupon.name == "") {
          this.$noty.error("El codigo y el nombre son obligatorios");
          return;
        }

        if (this.coupon.amount == 0) {
          this.$noty.error("El monto debe ser mayor a 0");
          return;
        }

        if (this.coupon.qty == 0) {
          this.$noty.error("La cantidad debe ser mayor a 0");
          return;
        }

        this.$http.post("coupon", this.coupon).then(() => {
          this.$noty.success("Cupon agregado");
          this.loadCoupons();
        }).catch((err) => {
          this.$refs["modal-create-coupon"].show();
          console.log(err)
          this.$noty.error(err.data.details);
        })
      }
    },
    loadCoupons() {
      this.loading = true;
      this.$http
        .get("coupon")
        .then((res) => {
          this.coupons = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>