<template>
  <div class="container-fluid py-3">
    <div
      class="row"
      style="postition: sticky; top: 80px; display: inline-block"
    >
      <div class="col">
        <h4>PRODUCTOS WEB</h4>
        <small
          >TOTAL {{ products.length }} | MOSTRANDO
          {{ productList.length }}</small
        >
      </div>
    </div>
    <div class="row mb no-gutters align-items-start">
      <div class="w-100"></div>
      <div class="col">
        <input
          type="text"
          class="form-control"
          @keyup.enter="filterProducts()"
          placeholder="Buscar..."
          v-model="productFilter"
        />
      </div>
      <div class="col-1">
        <button
          class="btn btn-outline-primary btn-block"
          @click="filterProducts()"
        >
          <font-awesome-icon icon="search"></font-awesome-icon>
        </button>
      </div>
      <div class="ml-auto col-1">
        <select name="" v-model.number="enabled" class="form-control">
          <option :value="null">Todos</option>
          <option value="1">Habilitados</option>
          <option value="0">Deshabilitados</option>
        </select>
        <small class="d-block">HABILITADO</small>
      </div>
      <div class="ml-auto col-1">
        <select name="" v-model.number="limit" class="form-control">
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">100</option>
          <option value="0">Todos</option>
        </select>
        <small class="d-block">LIMITE</small>
      </div>
      <div class="col-2">
        <select class="custom-select" v-model="productCategory">
          <option :value="null">Todas las categorias</option>
          <option
            v-for="category in categories"
            :value="category._id"
            :key="category._id"
          >
            {{ category.name }}
          </option>
        </select>
        <small>CATEGORIA</small>
      </div>
      <div class="col-3">
        <button
          class="btn btn-block btn-outline-secondary"
          @click="showModalOptions()"
        >
          Opciones de productos
        </button>
      </div>
      <div class="w-100 mb-3"></div>
      <div class="col">
        <ul class="list-group" v-if="products.length == 0 && loading">
          <li class="list-group-item" v-for="i in 6" :key="`skeleton-${i}`">
            <div class="row align-items-center py-3">
              <div class="col-1 text-center">
                <b-skeleton width="20%" class="center" />
              </div>
              <div class="col-1">
                <b-skeleton-img />
              </div>
              <div class="col">
                <b-skeleton width="30%" />
                <b-skeleton width="60%" />
              </div>
              <div class="col-2 text-right">
                <b-skeleton width="30%" style="margin-left: auto" />
              </div>
              <div class="col d-none">
                <b-skeleton width="40%" />
              </div>
              <div class="col-1 text-center">
                <b-skeleton width="15%" />
              </div>
            </div>
          </li>
        </ul>
        <div v-else>
          <b-overlay :show="loading">
            <ul class="list-group">
              <li class="list-group-item" v-if="productList.length == 0">
                <h5 class="text-center py-4">No tienes productos agregados</h5>
              </li>
              <li class="list-group-item" v-for="p in productList" :key="p._id">
                <div class="row align-items-center">
                  <div class="col-1 text-center">
                    <!-- <font-awesome-icon
                  v-if="p.enabled"
                  class="text-success"
                  icon="check-circle"
                  size="lg"
                ></font-awesome-icon>
                <font-awesome-icon
                  v-else
                  class="text-danger"
                  icon="times-circle"
                  size="lg"
                ></font-awesome-icon> -->
                    <b-checkbox
                      switch
                      @change="changeStatus(p)"
                      size="lg"
                      v-model="p.enabled"
                    ></b-checkbox>
                  </div>
                  <div class="col-1">
                    <img
                      class="img-fluid border"
                      loading="lazy"
                      :src="getImage(p.ref.image || null)"
                    />
                  </div>
                  <div class="col">
                    <small class="">CODIGO: {{ p.ref.code }}</small>
                    <small class="ml-4"
                      >CREADO: {{ p.createdAt | moment("DD/MM/YY") }}</small
                    >
                    <div class="w-100"></div>
                    <span class="font-weight-bold text-uppercase">{{
                      p.name
                    }}</span>
                  </div>

                  <div class="col-2 text-center">
                    <div v-if="p.ref.category">
                      <span class="badge badge-secondary w-100 py-2">{{
                        p.ref.category.name
                      }}</span>
                    </div>
                  </div>

                  <div class="col-2 text-center">
                    <div v-if="p.ref.stock">
                      <font-awesome-icon
                        class="text-success"
                        icon="check-circle"
                        size="sm"
                      ></font-awesome-icon>
                      <small class="d-block1 mx-1">STOCK</small>
                      <small v-if="p.ref.stock" class="mr-1 font-weight-bold">{{
                        p.ref.qty
                      }}</small>
                    </div>
                  </div>
                  <div class="col-1 px-0 text-center">
                    <small class="text-muted d-block">DISPONIBILIDAD</small>
                    <small>{{ getAvailable(p.ref) }}</small>
                  </div>
                  <div class="col-2 text-right">
                    <small class="d-block">VALOR</small>
                    <span class="font-weight-bold">
                      ${{ formatNumber(p.ref.value) }}
                    </span>
                  </div>
                  <div class="col-1 text-center d-none">
                    <div class="dropdown">
                      <button
                        class="btn"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <font-awesome-icon icon="ellipsis-h" />
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          class="dropdown-item d-none1"
                          @click="editProduct(p._id)"
                        >
                          Editar
                        </button>
                        <button
                          class="dropdown-item"
                          @click="setEnabled(p._id, !p.enabled)"
                        >
                          {{ p.enabled ? "Deshabilitar" : "Habilitar" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </b-overlay>
        </div>
      </div>
    </div>
    <b-modal
      ref="modalEditProduct"
      id="modalEditProduct"
      size="md"
      title="Editar producto web"
      @ok="updateProduct()"
    >
      <div class="row">
        <div class="col-12">
          <div class="alert alert-info text-center">
            Para editar nombre de producto, valor, imagen, stock. Editar en
            modulo
            <router-link to="/products">Productos</router-link>
          </div>
        </div>
        <div class="col-12">
          <small class="d-block">CODIGO</small>
          <span class="font-weight-bold">{{ product.code }}</span>
        </div>
        <div class="col">
          <small class="d-block">NOMBRE</small>
          <span class="font-weight-bold">{{ product.name }}</span>
        </div>
        <div class="col-12">
          <small class="d-block">VALOR WEB</small>
          <input
            type="text"
            @focus="$event.target.select()"
            class="form-control text-right"
            v-model.number="product.value"
          />
        </div>
      </div>
    </b-modal>
    <manage-options ref="manageOptions" />
  </div>
</template>

<script>
import manageOptions from "../../components/modal/manageOptions.vue";
export default {
  components: {
    manageOptions,
  },
  mounted() {
    this.loadProducts();

    this.$http.get("category").then((res) => {
      this.categories = res.data.data;
    });
  },
  data() {
    return {
      productList: [],
      productFilter: "",
      products: [],
      product: {},
      loading: true,
      limit: 100,
      productCategory: null,
      categories: [],
      enabled: null,
    };
  },
  watch: {
    limit() {
      this.loadProducts();
    },
    productCategory() {
      this.loadProducts();
    },
    enabled() {
      this.loadProducts();
    },
  },
  methods: {
    getAvailable(product) {
      const { available_on } = product;
      if(!available_on || available_on.length == 0) return '- - - -'
      else {
        const data = {
          pickup: 'Retiro',
          delivery: 'Despacho',
          shipping: 'Envío',
        }
        return available_on.map(a => data[a]).join(', ')
      }
    },
    changeStatus(product) {
      this.setEnabled(product._id, product.enabled);
    },
    showModalOptions() {
      this.$refs["manageOptions"].showModal();
    },
    filterProducts() {
      let filter = (p) => {
        p.words = 0;
        let words = this.productFilter.trim().toLowerCase().split(" ");
        let pwords = p.name.toLowerCase().split(" ");
        // check code
        if (
          this.productFilter.toLowerCase() == p.code.toString().toLowerCase()
        ) {
          p.words = p.words + 500;
          return p;
        }
        // check words
        if (pwords.join(" ").indexOf(words.join(" ")) != -1) {
          //console.log(p.name);
          p.words = p.words + 100;
          // return p;
        }

        if (pwords.some((w) => words.includes(w))) {
          pwords.forEach((pw) => {
            if (words.includes(pw)) p.words = p.words + 10;
            if (words.join(" ").indexOf(pw) != -1) p.words = p.words + 5;
            words.forEach((w) => {
              if (pwords.join(" ").indexOf(w) != -1) p.words = p.words + 5;
            });
          });
          return p;
        }
        if (p.words > words.length * 5) return p;
      };
      let sort = (a, b) => {
        if (a.words > b.words) return -1;
        if (a.words < b.words) return 1;
        return 0;
      };
      if (this.productFilter == "") this.productList = this.products;
      else this.productList = this.products.filter(filter).sort(sort);
    },
    updateProduct() {
      this.$http.patch(`web/product/${this.product._id}`, this.product);
    },
    editProduct(id) {
      this.$http.get(`web/product/${id}`).then((res) => {
        this.product = res.data.data;
        this.$refs.modalEditProduct.show();
      });
    },
    setEnabled(id, val) {
      this.loading = true;
      this.$http.patch(`web/product/${id}/status`, { status: val }).then(() => {
        this.$noty.success("Producto web actualizado");
        this.loadProducts();
      });
    },
    loadProducts() {
      this.loading = true;
      this.$http
        .get(
          `web/product?category=${this.productCategory}&limit=${this.limit}&enabled=${this.enabled}`
        )
        .then((res) => {
          this.products = res.data.data;
          this.filterProducts();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addProduct() {
      this.$http.post(`web/product`, this.product).then(() => {
        this.loadProducts();
        this.product = {};
      });
    },
    setProduct(product) {
      if (!product) {
        this.product = {};
        return false;
      }
      let p = this.$store.state.products.find((p) => p.code == product.code);
      this.product = p;
    },
  },
};
</script>