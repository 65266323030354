<template>
  <div class="px-3 py-2 m-1 bgwhite border shadow" :class="styles">
    <div class="row aligm-items-center no-gutters">
      <div class="col">
        <div class="row align-items-center">
          <h1
            style="
              opacity: 0.25;
              position: absolute;
              font-size: 1.7em;
              top: 10px;
              right: 10px;
            "
             v-if="getIcon"
          >
            <font-awesome-icon
              class="align-self-center"
              :icon="getIcon"
              :class="getIconClass"
              :size="getIconSize"
            />
          </h1>
          <h1
            style="
              opacity: 0.8;
              position: absolute;
              font-size: 1em;
              bottom: 0px;
              right: 0px;
            "
            class="overflow-hidden"
             v-if="subtitle"
          >
            <small class="text-uppercase">{{subtitle}}</small>
            
          </h1>
          <div class="col-auto text-center" v-if="getIcon">
            <font-awesome-icon
              class="align-self-center"
              :icon="getIcon"
              :class="getIconClass"
              :size="getIconSize"
            />
          </div>
          <div class="col" :class="{
            'px-1': getIcon
          }">
            <small class="font-weight-bold text-uppercase">{{ title }}</small>
          </div>
        </div>
        <div class="w-100 py-1"></div>
        <div v-if="!notext">
          <h4 :class="getTextClass" v-if="getType">
            <span v-if="prefix">{{prefix}}</span>{{ getText || 0 | formatNumber }}
          </h4>
          <h4 :class="getTextClass" v-else><span v-if="prefix">{{prefix}}</span>{{ getText || " - - - " }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemIcon",
  props: {
    styles: String,
    prefix: String,
    text: [String, Number],
    format: Boolean,
    icon: String,
    title: String,
    subtitle: String,
    iconClass: String,
    iconSize: Number,
    textClass: String,
    notext: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    getText() {
      return this.text;
    },
    getType() {
      if (!this.format) return false;
      return this.format;
    },
    getIcon() {
      return this.icon;
    },
    getTitle() {
      return this.title || "Hola";
    },
    getIconClass() {
      return this.iconClass;
    },
    getIconSize() {
      if (!this.iconSize) return "1x";
      return this.iconSize;
    },
    getTextClass() {
      return this.textClass || "";
    },
  },
};
</script>