<template>
  <div>
    <b-overlay :show="loading">
      <div class="row">
        <div
          class="col-md-3 col-12 mb-2 border-bottom"
          style="height: 60vh; overflow-y: scroll; scroll-behavior: smooth"
        >
          <div class="mb-2">
            <button
              class="btn btn-success btn-block"
              @click="loadPageChat(page)"
            >
              <font-awesome-icon icon="redo"></font-awesome-icon>
            </button>
          </div>
          <ul class="list-group">
            <li class="list-group-item" v-if="chats.length == 0">
              <h5 class="text-center py-5">No hay chats</h5>
            </li>
            <li
              class="list-group-item list-group-item-action click"
              v-for="con in chats"
              :class="{
                'active text-white': con.id == chat.id,
              }"
              :key="con.id"
              @click="setChat(con)"
            >
              <div class="row align-items-center">
                <div class="col-3 m-0 text-center p-0 d-none">
                  <!-- <img
                                :src="`https://graph.facebook.com/${con.participants.data[0].id}/picture?redirect=true&access_token=${page.access_token}`"
                                alt=""
                                class="
                                  img-fluid
                                  rounded-circle
                                  border border-dark
                                  w-100
                                  h-100
                                "
                              /> -->
                  <font-awesome-icon icon="user-circle" size="4x" />
                </div>
                <div class="col">
                  <div class="row position-relative">
                    <div
                      class="col-12"
                      v-for="par in con.participants.data"
                      :key="par.id"
                      :class="{
                        'd-none': par.id == page,
                        //par.id == pagedata.instagram_business_account.id,
                      }"
                    >
                      <strong>{{ par.name || par.username }}</strong>
                    </div>

                    <!-- <div
                                  class="col-2 ml-auto text-center d-none"
                                  v-if="con.message_count"
                                >
                                  <div class="badge badge-secondary">
                                    {{ con.message_count }}
                                  </div>
                                </div> -->
                    <div
                      class="col-2 position-absolute"
                      v-if="con.unread_count > 0"
                      style="top: 0; right: 0"
                    >
                      <div class="badge badge-danger">
                        {{ con.unread_count }}
                      </div>
                    </div>
                    <div class="col-auto">
                      <small class="font-italic">{{
                        con.updated_time | moment("from")
                      }}</small>
                    </div>
                    <div class="col-12" v-if="con.messages">
                      <font-awesome-icon
                        :icon="
                          con.messages.data[0].from.id != page
                            ? 'chevron-left'
                            : 'chevron-right'
                        "
                        size="xs"
                        class="mr-2"
                      ></font-awesome-icon>
                      <small class="font-italic text-right">{{
                        con.messages.data[0].message.slice(0, 40)
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <div
            class="chat bgwhite"
            style="height: 50vh; overflow-y: scroll"
            ref="chat"
          >
            <ul
              class="list-group"
              style="display: flex; flex-direction: column-reverse"
              v-if="chat.messages && chat.messages.data"
            >
              <li
                class="list-group-item px-4"
                v-for="msg in chat.messages.data"
                :key="msg.id"
                :class="{
                  'text-right  bglight rounded':
                    msg.from.id == page ||
                    msg.from.id == pagedata.instagram_business_account.id,
                }"
              >
                <div class="row">
                  <div class="col">
                    <div class="w-100">
                      <strong class="">{{
                        msg.from.name || msg.from.username
                      }}</strong>
                      <div class="w-100"></div>
                      <small class="mr-4">{{
                        msg.created_time | moment("HH:mm")
                      }}</small>
                      <small class="font-italic">{{
                        msg.created_time | moment("from")
                      }}</small>
                    </div>
                    <div class="w-100 py-2"></div>
                    <span>{{ msg.message }}</span>
                    <div v-if="msg.sticker">
                      <img
                        :src="msg.sticker"
                        alt=""
                        class="img-fluid"
                        style="max-width: 120px"
                      />
                    </div>
                    <div v-if="msg.attachments">
                      <div
                        v-for="att in msg.attachments.data"
                        :key="att.id"
                        class=""
                      >
                        <div
                          v-if="att.generic_template"
                          class="border border-primary bg-primary text-white p-2 row"
                          :class="{
                            'float-right': msg.from.id == page,
                          }"
                          style="max-width: 350px; font-size: 12px"
                        >
                          <div class="col">
                            <strong>{{ att.generic_template.title }}</strong>
                            <p>{{ att.generic_template.subtitle }}</p>
                          </div>
                          <div
                            class="col-lg-5"
                            v-if="att.generic_template.media_url"
                          >
                            <img
                              :src="att.generic_template.media_url"
                              alt=""
                              class="img-fluid"
                              style="max-width: 120px"
                            />
                          </div>
                        </div>
                        <div
                          v-if="att.mime_type == 'image/jpeg'"
                          class="border border-primary bg-primary text-white p-2"
                          :class="{
                            'float-right': msg.from.id == page,
                          }"
                          style="width: auto; max-width: 450px"
                        >
                          <div class="col-lg" v-if="att.image_data.preview_url">
                            <a :href="att.image_data.url" target="_blank">
                              <img
                                :src="att.image_data.preview_url"
                                alt=""
                                class="img-fluid"
                                style="max-width: 120px"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="msg.shares">
                      <span
                        v-for="share in msg.shares.data"
                        class="border px-3 py-1 rounded bg-info text-white"
                        :class="{
                          'd-none': !share.name,
                        }"
                        :key="share.id"
                        >{{ share.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="chat-controls mt-3">
            <div class="row align-items-end">
              <div class="col pr-0">
                <small
                  class="text-danger w-100"
                  v-if="$moment().diff(chat.updated_time, 'hours') > 24"
                >
                  No puedes iniciar conversaciones luego de 24 horas
                </small>
                <input
                  type="text"
                  class="form-control"
                  ref="chatInput"
                  v-model="chatText"
                  :disabled="
                    sending || $moment().diff(chat.updated_time, 'hours') > 24
                  "
                  @keyup.enter="sendMessage()"
                  placeholder="Escribe tu mensaje"
                  @focus="scroll()"
                />
              </div>

              <div class="col-auto d-none">
                <button class="btn btn-success" @click="sendSeen()">
                  <font-awesome-icon icon="eye"></font-awesome-icon>
                </button>
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-primary"
                  @click="sendMessage()"
                  :disabled="sending"
                >
                  <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <small>ACCIONES RAPIDAS</small>
          <button class="btn btn-block btn-primary" @click="sendHello()">
            SALUDAR
          </button>

          <div class="w-100"></div>

          <div class="row mb-3">
            <div class="col-12">
              <small>ENVIAR PRODUCTO</small>
            </div>
            <div class="col">
              <v-select
                v-model="product"
                class="py-1"
                append-to-body
                :options="getProducts"
                width="300px"
              ></v-select>
            </div>
            <div class="col-4 col-md-12">
              <button
                class="btn btn-block btn-outline-secondary h-100"
                @click="sendProduct()"
              >
                ENVIAR
              </button>
            </div>
          </div>

          <button
            class="btn btn-block btn-secondary"
            @click="sendMenu()"
            v-if="platform == 'messenger'"
          >
            ENVIAR MENU
          </button>
          <button class="btn btn-block btn-secondary" @click="sendType()">
            RETIRO/DOMICILIO
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>


<script>
export default {
  name: "messenger",
  props: {
    pagedata: {
      type: Object,
      default: () => {},
    },
    platform: {
      type: String,
      default: "messenger",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      default: "",
    },
  },
  watch: {
    page() {
      console.log("watch page watch", this.page);
      this.loadPageChat();
    },
    visible() {
      console.log("messenger visible", this.visible);
      this.scroll();
    },
    pagedata: {
      inmediate: true,
      handler() {
        console.log("page data watch", this.pagedata);
        this.loadPageChat();
      },
    },
  },
  mounted() {
    console.log("page data", this.pagedata);
  },
  activated() {
    console.log("activate");
  },
  data() {
    return {
      product: {},
      chatText: "",
      chat: {
        messages: {
          data: [],
        },
      },
      loading: true,
      sending: false,
    };
  },
  methods: {
    scroll() {
      this.$refs["chat"].scrollTop = this.$refs["chat"].scrollHeight;
    },
    sendSeen() {
      this.sending = true;
      let recipient = this.findRecipient(this.chat.participants.data);
      this.$http
        .post("auth/facebook/seen", {
          recipient: recipient.id,
          fb_id: this.page,
        })
        .then(() => {
          this.chatText = "";
          //cb();
        })
        .finally(() => {
          this.sending = false;
        });
    },
    postMessage(message, cb = function () {}) {
      this.sending = true;
      let recipient = this.findRecipient(this.chat.participants.data);
      this.$http
        .post("auth/facebook/message", {
          recipient: recipient.id,
          fb_id: this.page,
          message: message,
        })
        .then(() => {
          this.chatText = "";
          this.setChat(this.chat);
          cb();
        })
        .finally(() => {
          this.sending = false;
        });
    },
    sendType() {
      this.postMessage({
        attachment: {
          type: "template",
          payload: {
            template_type: "button",
            text: "¿Como quieres recibir tu pedido?",
            buttons: [
              {
                type: "postback",
                title: "Retiro en tienda",
                payload: "retiro",
              },
              {
                type: "postback",
                title: "Domicilio",
                payload: "domicilio",
              },
            ],
          },
        },
      });
    },
    sendMenu() {
      let info = this.info;
      let web =
        info.webDomain || `https://pedidos.ticketapp.cl/${info.webName}`;

      this.postMessage({
        attachment: {
          type: "template",
          payload: {
            template_type: "button",
            text: "Te envio nuestro menú actualizado, tambien puedes agendar tu pedido directamente desde la pagina",
            buttons: [
              {
                type: "web_url",
                url: web,
                title: "Ver Menu",
              },
            ],
          },
        },
      });
    },
    sendProduct() {
      let product = this.product;

      let props = {};

      let info = this.info;
      let web =
        info.webDomain || `https://pedidos.ticketapp.cl/${info.webName}`;

      if (product.webproduct) {
        props.default_action = {
          type: "web_url",
          url: web + "?pid=" + product._id,
        };
      }

      if (product.image) {
        props.image_url = "https://static3.ticketapp.cl/" + product.image;
      }

      this.postMessage(
        {
          attachment: {
            type: "template",
            payload: {
              template_type: "generic",
              elements: [
                {
                  title: `${product.name} - $${this.formatNumber(
                    product.value
                  )}`,
                  subtitle: product.description || product.name,
                  ...props,
                },
              ],
            },
          },
        },
        () => {
          this.product = {};
        }
      );
    },
    sendHello() {
      let recipient = this.findRecipient(this.chat.participants.data);
      this.postMessage({
        text: `Hola, ${
          recipient.name || recipient.username
        }. En que te puedo ayudar?`,
      });
    },
    findRecipient(data) {
      console.log("find recipient", data, this.page);
      return data.find((p) => {
        return (
          p.id != this.page &&
          p.id != this.pagedata.instagram_business_account.id
        );
      });
    },
    sendMessage() {
      this.postMessage({
        text: this.chatText,
      });
    },
    setChat(chat) {
      console.log({ page: this.page });
      this.loading = true
      this.scroll();
      // window.FB.api(
      //   `/${chat.id}`,
      //   "GET",
      //   {
      //     fields:
      //       "updated_time,messages{message,from,created_time,shares{name}},participants",
      //     access_token: this.pagedata.access_token,
      //   },
      //   (response) => {

      //     this.chat = response;
      //     this.$refs["chatInput"].focus();
      //     setTimeout(() => {
      //       this.$refs["chatInput"].focus();
      //       this.scroll();
      //     }, 1000);
      //   }
      // );
      this.$http
        .get(
          `auth/facebook/pagechat/${chat.id}?platform=${this.platform}&page=${this.page}`
        )
        .then((response) => {
          this.chat = response.data.data;
          this.$refs["chatInput"].focus();
          setTimeout(() => {
            this.$refs["chatInput"].focus();
            this.scroll();
          }, 1000);
        })
        .catch((err) => {
          console.log('error set chat', err);
          this.$noty.error("Error al cargar chat");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadPageChat() {
      console.log("load page chat", this.page);
      this.loading = true;

      this.$http
        .get(`auth/facebook/pagechat?id=${this.page}&platform=${this.platform}`)
        .then((response) => {
          const data = response.data.data.data;

          if (this.platform == "messenger")
            this.$store.commit("setFbChats", data);
          else this.$store.commit("setIgChats", data);
          if (data.length > 0) {
            this.setChat(data[0]);
          } 
          // else {
          //   this.chat = {
          //     messages: {
          //       data: [],
          //     },
          //   };
          // }
        })
        .catch((err) => {
          console.log('error load chat', err);
          this.loading = false;
          this.$noty.error("Error al cargar chats");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    selectedPage() {
      return localStorage.selectedPage;
    },
    chats() {
      if (this.platform == "messenger") return this.$store.state.fb_chats;
      else return this.$store.state.ig_chats;
    },
    getProducts() {
      if (!this.products) return [];
      return this.products.map((o) => ({
        ...o,
        label: `${o.code} - ${o.name} $${this.formatNumber(o.value)}`,
      }));
    },
    products() {
      return this.$store.state.products;
    },
    info() {
      return this.$store.state.info;
    },
  },
};
</script>