<template>
  <div class="container-fluid">
    <div class="row align-items-end">
      <div class="col">
        <h4>SOCKETS CONECTADOS {{ sockets.length }}</h4>
        <div class="w-100"></div>
        <small class="mr-4">BOTS {{ socketsBOT }}</small>
        <small class="mr-4">USUARIOS {{ sockets.length - socketsBOT }}</small>
        <small class="mr-4">EMPRESAS {{ bids.length }}</small>
      </div>
      <div class="col-4 mt-3 ml-auto">
        <button class="btn btn-block btn-outline-primary" @click="loadUsers()">
          ACTUALIZAR
        </button>
      </div>
      <div class="col-12">
        <small>LOGS</small>
        <div class="w-100"></div>
        <div class="row" v-for="(log, i) in logs" :key="`log-${i}`">
          <div class="col">
            {{ log.createdAt | moment("DD/MM/YY HH:mm") }}
          </div>
          <div class="col font-weight-bold">
            {{ log.cmd }}
          </div>
           <div class="col">
            {{ log.data.business }}
          </div>
        </div>
      </div>
      <div class="col-12 py-3">
        <div class="list-group">
          <div
            v-for="(socket, index) in sockets"
            :key="index"
            class="list-group-item list-group-item-action"
            :class="{
              active: socket.app == 'admin',
              'text-success': socket.app == 'server bot',
            }"
          >
            <div class="row no-gutters align-items-center">
              <div class="col-1 text-center">
                <!-- <font-awesome-icon
                  icon="dot-circle"
                  class="text-success"
                ></font-awesome-icon> -->

                <small class="mx-2">{{
                  socket.lastSeen | moment("DD/MM")
                }}</small>
                <div class="w-100"></div>
                <small class="mx-2">{{
                  socket.lastSeen | moment("HH:mm")
                }}</small>
              </div>
              <div class="col">
                <strong class="text-uppercase text-muted">{{
                  socket.name
                }}</strong>
                <div class="w-100"></div>
                <small>{{ socket.email }}</small>
                <div class="w-100"></div>
                <strong class="text-uppercase"> {{ socket.business }}</strong>
                <div class="w-100"></div>
                <small class="text-muted">BID: {{ socket.business_id }}</small>
              </div>
              <div class="col-3 text-uppercase">
                <strong>{{ socket.client }}</strong> - {{ socket.app }}
                <div class="w-100"></div>
                <small>{{ socket.address }}</small>
              </div>
              <div class="col-auto text-uppercase">
                <b-dropdown>
                  <b-dropdown-item @click="$emit('updateSocket', socket)">
                    <font-awesome-icon icon="redo" class="mr-1" />
                    Actualizar
                  </b-dropdown-item>
                  <b-dropdown-item @click="cmd('logout', socket)">
                    <font-awesome-icon icon="times" class="mr-1" />
                    Cerrar sesión
                  </b-dropdown-item>
                  <b-dropdown-item @click="cmd('reload', socket)">
                    <font-awesome-icon icon="redo" class="mr-1" />
                    Enviar reload
                  </b-dropdown-item>
                  <b-dropdown-item @click="logout(socket)">
                    <font-awesome-icon icon="times" class="mr-1" />
                    Cerrar sesión
                  </b-dropdown-item>
                  <b-dropdown-item @click="closeapp(socket)">
                    <font-awesome-icon icon="times" class="mr-1" />
                    Cerrar app
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    sockets: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      logs: [],
      showCustomPage: false,
      bids: [],
    };
  },
  mounted() {
   

   
  },
  
  computed: {
    socketsBOT() {
      if (!this.sockets) return 0;
      return this.sockets.filter((s) => s.app == "server bot").length;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    cmd(cmd, socket) {

      // socket.emit('cmd', {
      //   to: req.business,
      //   payload: {
      //     cmd: 'updateTable',
      //     data: data
      //   }
      // })

      this.$emit("cmd", {
        to: socket.business_id,
        payload: {
          email: socket.email,
          cmd: cmd,
          data: socket,
        },
      });
    },
  },
};
</script>