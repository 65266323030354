<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-auto">
        <h2>DTE {{ dtes.length }}</h2>
      </div>
      <div class="col-auto">
        <button @click="read()" class="btn btn-primary">
          <font-awesome-icon icon="sync" /> READ
        </button>
      </div>
      <div class="col-auto ml-auto">
        <date-picker
          valueType="format"
          v-model="dateFilter"
          input-class="form-control"
        />
      </div>
      <div class="col-auto">
        <button @click="load()" class="btn btn-primary">
          <font-awesome-icon icon="sync" />
        </button>
      </div>
      <div class="w-100 py-2"></div>
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" v-for="dte in dtes" :key="dte.id">
            <div class="row align-items-center">
              <div class="col">
                <small class="text-uppercase">#{{ dte.folio }}</small>
                <small class="mx-4">
                  {{ dte.dteType }} - {{ getDTEName(dte.dteType) }}
                </small>

                <div class="w-100"></div>
                <strong>{{ dte.business.name }}</strong>
              </div>
              <div class="col">
                <small class="text-uppercase">{{
                  dte.rutEmisor | formatRut
                }}</small>
                <small class="mx-4">
                  {{ dte.createdAt | moment("DD-MM-YY") }}</small
                >
                <div class="w-100"></div>
                <strong> {{ dte.rznSoc }}</strong>
              </div>
              <div class="col-2 text-right">
                <small>${{ dte.mntIva | formatNumber }}</small>
                <div class="w-100"></div>
                <strong>${{ dte.mntTotal | formatNumber }}</strong>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getDTEName, getDTEList } from "../../utils";
export default {
  data() {
    return {
      // ...
      dateFilter: null,
      dtes: [],
    };
  },
  mounted() {
    //this.load();
    this.dateFilter = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
    read() {
      this.$noty.info("Leyendo datos...");
      this.$http.post("sii/inbox").then(() => {
        this.$noty.success("Actualizado!");
        this.load();
      });
    },
    load() {
      this.$http.get(`admin/dte?date=${this.dateFilter}`).then((response) => {
        this.dtes = response.data.data;
      });
    },
    getDTEName,
    getDTEList,
  },
  watch: {
    dateFilter: function () {
      this.load();
    },
  },
};
</script>