<template>
  <div class="">
    <div style="position: absolute; right: 20px; top: 10px">
      <small class="click text-muted" @click="startTour()">
        <font-awesome-icon icon="question-circle" />
        COMO USAR</small
      >
    </div>
    <div class="row no-gutters" v-if="open">
      <div class="col-12">
        <div class="row no-gutters">
          <div class="col-12 col-md-4">
            <div class="btn-group btn-block">
              <button
                class="btn btn-primary float-right"
                id="btnSearch"
                @click="showSearch()"
              >
                BUSCAR
              </button>
              <button
                class="btn btn-outline-secondary float-right"
                @click="clearTicket(true)"
              >
                LIMPIAR TICKET
              </button>
            </div>
          </div>
          <div class="col-12 col-md">
            <div class="input-group">
              <input
                type="text"
                placeholder="Ingrese codigo del producto y presione enter"
                v-model="code"
                class="form-control text-uppercase"
                v-focus
                autocomplete="off"
                id="inputCode"
                ref="inputCode"
                v-on:keyup.enter="addProduct()"
                enterkeyhint="go"
              />
              <button
                class="btn btn-success"
                @click="addProduct()"
                :disabled="code == ''"
              >
                <font-awesome-icon icon="search" />
              </button>
              <button
                class="btn btn-primary"
                @click="createProduct()"
                v-if="user.role == 'admin'"
              >
                <font-awesome-icon icon="plus" />
                <admin-badge
                  style="top: -10px; right: 0px; position: aboslute"
                />
              </button>
              <button
                class="btn btn-primary"
                @click="loadWebTicketFromCode()"
                v-if="user.role == 'admin' && info.webEnabled"
              >
                <font-awesome-icon icon="globe" />
                <admin-badge
                  style="top: -10px; right: 0px; position: aboslute"
                />
              </button>
            </div>
            <small class="text-muted"
              >PRESIONE F1 PARA ESCRIBIR CODIGO | PRESIONE ENTER PARA
              INGRESAR</small
            >
          </div>
        </div>
        <hr />

        <div class="row">
          <div class="col-12">
            <featured @code="addCode" />
          </div>
          <div class="col-12 mt-2 cart" v-if="ticket.products.length == 0">
            <h4 class="text-center py-5 py-5">Vacio</h4>
          </div>

          <div
            class="mt-3 col-12 cart"
            v-for="(product, i) in ticket.products"
            :key="i"
          >
            <div
              class="row no-gutters p-2 bgwhite border px-4 py-3"
              :class="{
                'bg-warning':
                  product.qty > product.stockqty && product.stockEnabled,
              }"
            >
              <div class="col-md-1 col-12">
                <div class="row">
                  <div class="col-4 col-md-12">
                    <button
                      class="btn btn-sm btn-outline-danger btn-block h-100"
                      @click="remove(product)"
                      alt="Borrar producto"
                    >
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                  <div class="col col-md-12">
                    <input
                      v-model.number="product.qty"
                      type="text"
                      class="form-control d-block text-center"
                      @focus="$event.target.select()"
                    />
                    <div class="btn-group btn-block" role="group">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        @click="changeQty(product, 'subs')"
                      >
                        -
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success"
                        @click="changeQty(product, 'add')"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div class="w-100 py-2 d-md-none"></div>
              </div>
              <div class="pl-2 col-12 col-md">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-2 px-4 d-none d-lg-block">
                        <img
                          :src="getImage(product.image)"
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="col">
                        <h5 class="text-uppercase font-weight-bold mb-0">
                          {{ product.name }}
                        </h5>
                        <small>{{ product.description }}</small>
                      </div>
                    </div>
                    <small class=""> CODIGO: {{ product.code }} </small>
                    <small class="ml-3">
                      VALOR ORIGINAL: ${{
                        formatNumber(product.original_value)
                      }}
                    </small>
                    <small class="ml-3">
                      VALOR: ${{ formatNumber(product.value) }}
                    </small>
                    <small class="ml-3" v-if="info.beEnabled">
                      IVA: {{ product.iva ? "AFECTO" : "EXENTO" }}
                    </small>
                    <small class="ml-3" v-if="product.stockEnabled">
                      EN STOCK: {{ product.stockqty || "- - - -" }}
                    </small>
                    <div
                      class="d-block"
                      v-if="product.stockEnabled && product.storages"
                    >
                      <select
                        v-model="product.storage"
                        class="custom-select custom-select-sm"
                      >
                        <option
                          :value="null"
                          v-if="product.storages.length < 1"
                        >
                          SIN STOCK
                        </option>
                        <option
                          :key="s._id"
                          :value="s.storage._id"
                          v-for="s in product.storages"
                        >
                          {{
                            `${s.storage.name} - ${s.qty} ${product.unit} disponibles` ||
                            "---"
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-4 text-right">
                    <h4 class="font-weight-bold pt-4">
                      <span
                        class="badge badge-danger"
                        v-if="product.discount && product.discount > 0"
                        >-{{ product.discount | fixed(2) }}%</span
                      >
                      ${{ formatNumber(product.total) }}
                    </h4>
                    <h6 v-if="product.discount" class="text-muted text-through">
                      ${{
                        formatNumber(product.original_value || product.value)
                      }}
                    </h6>
                    <small
                      v-if="product.editableValue == true"
                      class="d-block btn-link click"
                      @click="showChangeValue = `${product.code}_${i}`"
                      >Editar</small
                    >
                    <div
                      class="input-group"
                      v-if="showChangeValue == `${product.code}_${i}`"
                    >
                      <span class="input-group-text">$</span>
                      <input
                        type="number"
                        v-model.number="newValue"
                        class="form-control"
                        v-focus
                        @focus="$event.target.select()"
                        @keyup.enter="changeValue(product, newValue)"
                      />
                      <button
                        class="btn btn-sm btn-outline-success"
                        @click="changeValue(product, newValue)"
                      >
                        OK
                      </button>
                      <small class="d-block text-muted"
                        >PRESIONE ENTER PARA ACEPTAR</small
                      >
                    </div>

                    <div class="dropdown dropleft">
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        %
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div class="row">
                          <div class="col">
                            <button
                              @click="product.discount = 0"
                              class="dropdown-item"
                            >
                              {{ 0 }}%
                            </button>
                          </div>
                          <div class="col-6 col-md-4" v-for="i in 20" :key="i">
                            <button
                              @click="product.discount = i * 5"
                              class="dropdown-item"
                            >
                              {{ i * 5 }}%
                            </button>
                          </div>
                          <div class="col-12 px-4 mt-2">
                            <b-form-input
                              id="range-1"
                              v-model.number="product.discount"
                              type="range"
                              min="0"
                              max="100"
                              step="1"
                            ></b-form-input>
                            <div class="w-100"></div>
                            <div class="row">
                              <div class="col">
                                <small>ENVIAR AL SII</small>
                              </div>
                              <div class="col">
                                <input
                                  type="radio"
                                  v-model="product.discontIn"
                                  value="$"
                                />
                                $
                              </div>
                              <div class="col">
                                <input
                                  type="radio"
                                  v-model="product.discontIn"
                                  value="%"
                                />
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button
                  v-b-toggle="`collapse-${i}-inner`"
                  size="sm"
                  variant="outline-primary"
                  class="ml-0 w-90"
                  v-if="product.newOptions && product.newOptions.length > 0"
                >
                  Mostrar/Ocultar opciones
                </b-button>
                <b-collapse visible :id="`collapse-${i}-inner`" class="mt-2">
                  <div class="mt-2 px-2" v-if="product.newOptions">
                    <div
                      class="py-1"
                      v-for="(
                        { ref: option, custom_label }, i
                      ) in product.newOptions"
                      :key="`p-${i}`"
                    >
                      <product-option
                        :index="i"
                        @remove="removeOption"
                        :product="product"
                        :customLabel="custom_label"
                        :option="option"
                      >
                        {{ custom_label || option ? option.label : "- - -" }}
                      </product-option>
                    </div>
                  </div>
                </b-collapse>

                <div class="row px-2">
                  <div class="col-12">
                    <small class="text-muted">Notas del producto</small>
                    <textarea
                      class="form-control"
                      v-model="product.details"
                      rows="2"
                      placeholder="Comentarios o notas adicionales"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="ml-auto col-12 col-md-2 text-right d-none">
                <span class="d-block"
                  >${{ formatNumber(product.value) }} x {{ product.qty || 0 }}
                  {{ product.unit || "Unid" }}</span
                >
                <span class="d-block"
                  >Descuento: -${{ product.discountAmount }} |
                  {{ product.discount }}%</span
                >
                <b-form-input
                  id="range-1"
                  v-model="product.discount"
                  type="range"
                  min="0"
                  max="100"
                  step="5"
                ></b-form-input>
                <hr />
                <h5 class="text-uppercase">
                  ${{ formatNumber(product.total) }}
                </h5>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="row">
              <div class="col col-md-5 ml-auto">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="dropdown" id="discountBtn">
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        %
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div class="row no-gutters">
                          <div class="col">
                            <button
                              @click="applyDiscount(0)"
                              class="dropdown-item"
                            >
                              {{ 0 }}%
                            </button>
                          </div>
                          <div class="col-6" v-for="i in 20" :key="i">
                            <button
                              @click="applyDiscount(i)"
                              class="dropdown-item"
                            >
                              {{ i * 5 }}%
                            </button>
                          </div>
                          <div class="col-12">
                            <input
                              type="text"
                              class="form-control"
                              v-model.number="ticketDiscount"
                            />
                            <button
                              class="btn btn-success"
                              @click="applyDiscount(ticketDiscount)"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="input-group-text">Descuento global $</span>
                  </div>
                  <input
                    class="form-control text-right"
                    v-model.number="ticket.discount"
                    id="discountInput"
                    @focus="$event.target.select()"
                  />
                  <div class="input-group-append"></div>
                </div>
              </div>
              <div class="w-100 d-block"></div>

              <div class="w-100 mt-2"></div>

              <div class="col col-md-4 ml-auto" v-if="info.tipEnabled">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-dark" @click="setTip(10)">
                      <font-awesome-icon icon="calculator" />
                      10%
                    </button>
                    <span class="input-group-text">Propina $</span>
                  </div>
                  <input
                    class="form-control text-right"
                    v-model.number="ticket.tip"
                  />
                </div>
              </div>

              <div class="w-100 d-block mt-2"></div>

              <div
                class="col col-md-4 ml-auto"
                v-if="hasPermission(user, 'pdv_coupon')"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <small>CUPON DE DESCUENTO</small>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control font-weight-bold"
                    :disabled="false"
                    ref="couponInput"
                    v-model="couponCode"
                    @input="couponCode = $event.target.value.toUpperCase()"
                    placeholder="(Opcional)"
                    @keyup.enter="applyCoupon()"
                  />
                  <button
                    class="btn btn-outline-success"
                    @click="applyCoupon()"
                  >
                    <font-awesome-icon icon="arrow-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-12 border-bottom my-3"></div>
              <div class="col text-right">
                <h3 class="font-weight-bold">SUB-TOTAL:</h3>
              </div>
              <div class="col-6 col-md-3 text-right total">
                <h3 class="font-weight-bold">${{ formatNumber(subtotal) }}</h3>
              </div>
            </div>
          </div>

          <div class="col-12" v-if="coupon && coupon._id">
            <div class="row">
              <div class="col-12 border-bottom my-3"></div>
              <div class="col text-right">
                <h3 class="font-weight-bold">CUPON:</h3>
              </div>
              <div class="col-6 col-md-3 text-right total">
                <h3 class="font-weight-bold">
                  -${{ formatNumber(coupon.amount) }}
                </h3>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-12 border-bottom my-3"></div>
              <div class="col text-right">
                <h3 class="font-weight-bold">TOTAL:</h3>
              </div>
              <div class="col-6 col-md-3 text-right total">
                <h3 class="font-weight-bold">${{ formatNumber(total) }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 py-1"></div>

      <div class="col px-0">
        <div class="row justify-content-center no-gutters">
          <div class="col-md-6 px-0">
            <ul class="list-group">
              <!-- client -->
               <li class="list-group-item" v-if="false">
                <div class="row no-gutters align-items-end">
                  <div class="col">
                    <small class="font-weight-bold d-block">CLIENTE</small>
                    <v-select :options="clients" v-model="client" />
                  </div>
                  <div class="col-md-4 btn-group">
                    <button
                      class="btn  btn-sm btn-info"
                      @click="searchClient()"
                      
                    >
                      <font-awesome-icon icon="search" />
                    </button>
                    <button
                      class="btn btn-sm btn-outline-primary"
                      @click="createClient()"
           
                    >
                      <font-awesome-icon icon="plus" />
                    </button>
                    <!-- <b-tooltip
                        target="clientNameInput"
                        placement="bottom"
                      >
                        Usar boton anonimo
                      </b-tooltip> -->
                  </div>
                </div>
              </li>
              <li class="list-group-item" v-if="info.deliveryEnabled">
                <div class="row no-gutters align-items-end">
                  <div class="col">
                    <small class="font-weight-bold d-block">NOMBRE</small>
                    <b-form-input
                      id="clientNameInput"
                      ref="clientNameInput"
                      :disabled="anon"
                      :state="clientNameState"
                      size="sm"
                      v-model="ticket.clientName"
                      placeholder="Nombre del cliente. Minimo 5 caracteres"
                    />
                  </div>
                  <div class="col-md-4">
                    <button
                      class="btn btn-block btn-sm"
                      @click="setAnon()"
                      :class="{
                        'btn-info': anon,
                        'btn-outline-info': !anon,
                      }"
                    >
                      anonimo
                    </button>
                    <!-- <b-tooltip
                        target="clientNameInput"
                        placement="bottom"
                      >
                        Usar boton anonimo
                      </b-tooltip> -->
                  </div>
                </div>
              </li>

              <li class="list-group-item" v-if="info.deliveryEnabled">
                <div class="row align-items-center">
                  <div class="col">
                    <small class="font-weight-bold d-block">TIPO</small>
                    <div class="btn-group btn-block">
                      <button
                        class="btn"
                        :class="{
                          'btn-primary': ticket.type == 'Retiro',
                          'btn-outline-secondary': ticket.type != 'Retiro',
                        }"
                        @click="setType('Retiro')"
                      >
                        Retiro
                      </button>
                      <button
                        class="btn"
                        :class="{
                          'btn-primary': ticket.type == 'Domicilio',
                          'btn-outline-secondary': ticket.type != 'Domicilio',
                        }"
                        @click="setType('Domicilio')"
                      >
                        Domicilio
                      </button>
                    </div>
                  </div>
                </div>
              </li>

              <li class="list-group-item" v-if="ticket.type == 'Domicilio'">
                <b-tabs
                  content-class="mt-2"
                  active-tab-class="active"
                  v-model="addressType"
                >
                  <b-tab title="Clasico">
                    <div class="row align-items-center">
                      <div class="col">
                        <small class="font-weight-bold d-block"
                          >DIRECCION</small
                        >
                        <b-form-input
                          id="input-invalid"
                          :state="addressState"
                          v-model="ticket.address"
                          placeholder="Ej: calle 1234"
                        />
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Web - Nuevo" :disabled="!info.webEnabled">
                    <div class="row align-items-center">
                      <div class="col">
                        <small class="font-weight-bold d-block"
                          >DIRECCION
                          <span class="badge badge-danger">NUEVO</span></small
                        >
                        <gmap-autocomplete
                          :value="ticket.address"
                          ref="addressInput"
                          :componentRestrictions="{ country: 'CL' }"
                          placeholder="Ej: Avenida 123, Comuna"
                          @place_changed="getAddressData"
                          class="form-control"
                          :select-first-on-enter="true"
                        >
                        </gmap-autocomplete>
                        <select
                          v-model="zone"
                          disabled
                          class="form-control"
                          :class="{
                            'is-invalid': !zone._id,
                            'is-valid': zone.name,
                          }"
                        >
                          <option :value="{}" disabled>- - - - - - -</option>
                          <option :value="z" :key="i" v-for="(z, i) in zones">
                            {{ z.name }}
                            <span v-if="z.value"
                              >- ${{ formatNumber(z.value) }}</span
                            >
                          </option>
                        </select>
                      </div>
                      <div class="col-12">
                        <gmap-map
                          :center="center"
                          :options="{
                            mapTypeControl: false,
                            scaleControl: true,
                            streetViewControl: false,
                          }"
                          :zoom="14"
                          class="mt-2"
                          style="width: 100%; height: 200px"
                        >
                          <GmapMarker :position="center" />

                          <component
                            :is="
                              path.type == 'zone'
                                ? 'gmap-polygon'
                                : 'gmap-circle'
                            "
                            v-for="(path, i) in zones"
                            :key="`path-${i}`"
                            :paths="path.paths"
                            :center="path.center"
                            :radius="path.radius"
                            :options="{
                              strokeColor: path.color,
                            }"
                          />
                        </gmap-map>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </li>

              <li class="list-group-item" v-if="info.deliveryEnabled">
                <div class="row align-items-center">
                  <div class="col">
                    <small class="font-weight-bold d-block">TELEFONO</small>
                    <b-form-input
                      id="input-invalid"
                      :state="phoneState"
                      v-model="ticket.phone"
                      placeholder="Ej: 987654321 (Opcional)"
                    />
                    <small class="form-text text-muted">
                      Telefono de contacto (Opcional)
                    </small>
                  </div>
                </div>
              </li>
              <li class="list-group-item" v-if="info.deliveryEnabled">
                <div class="row align-items-center">
                  <div class="col">
                    <small class="font-weight-bold d-block">ESTADO PAGO</small>
                    <div class="btn-group btn-block">
                      <button
                        class="btn"
                        id="btnPending"
                        :class="{
                          'btn-warning': ticket.status == 'Por pagar',
                          'btn-outline-secondary': ticket.status != 'Por pagar',
                        }"
                        @click="setStatus('Por pagar')"
                      >
                        Por pagar
                      </button>
                      <b-tooltip
                        target="btnPending"
                        triggers="hover"
                        placement="bottom"
                      >
                        El cliente debe pagar el total de la compra
                      </b-tooltip>
                      <button
                        class="btn"
                        id="btnPayed"
                        :class="{
                          'btn-success': ticket.status == 'Pagado',
                          'btn-outline-secondary': ticket.status != 'Pagado',
                        }"
                        @click="setStatus('Pagado')"
                      >
                        Pagado
                      </button>
                      <b-tooltip
                        target="btnPayed"
                        triggers="hover"
                        placement="bottom"
                        >El cliente ya pago el total de la compra</b-tooltip
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-5" id="payments">
                    <small class="form-text text-muted">
                      Seleccione medio de pago
                    </small>
                    <div class="custom_scroll" style="max-height: 220px">
                      <button
                        class="btn btn-block btn-sm"
                        v-for="(payment, i) in payments"
                        :key="`payment-${i}`"
                        :disabled="total == 0"
                        :class="{
                          'btn-outline-info': !paymentIn(payment),
                          'btn-info': paymentIn(payment),
                        }"
                        @click="setPayment(payment, 1, 0, false)"
                      >
                        <small>{{ payment }}</small>
                      </button>
                    </div>
                  </div>

                  <div class="col pt-2">
                    <div class="row no-gutters">
                      <div class="col-12">
                        <div class="alert alert-info d-none">
                          <small class=""
                            >Si desea agregar otro medio de pago, indique
                            cantidad inferior al total</small
                          >
                        </div>
                        <small>PAGOS SELECCIONADOS</small>
                        <div
                          class="row align-items-center no-gutters mb-2 border py-2 px-3"
                          v-for="(p, i) in ticket.payments"
                          :key="i"
                          :ref="'paymentinput'"
                        >
                          <div
                            class="col-12 col-sm-12 col-md-12 font-weight-bold text-uppercase"
                          >
                            <small class="font-weight-bold">{{ p.name }}</small>
                          </div>
                          <div class="col-12 col-md">
                            <input
                              type="number"
                              class="form-control form-control-sm text-right"
                              v-model.number="p.amount"
                              @focus="$event.target.select()"
                            />
                          </div>
                          <div
                            class="col-1 text-danger text-center click font-weight-bold"
                          >
                            <button
                              class="btn btn-sm text-danger"
                              @click="removePayment(p)"
                            >
                              <font-awesome-icon
                                icon="times"
                              ></font-awesome-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-2">
                        <div class="row">
                          <div class="w-100 border-top"></div>
                          <div class="col">
                            <h6 class="text-right font-weight-bold py-2">
                              PAGOS:
                            </h6>
                          </div>
                          <div class="col-5">
                            <h6 class="text-right font-weight-bold py-2">
                              ${{ formatNumber(total_payments) }}
                            </h6>
                          </div>
                          <div class="w-100 border-top"></div>
                          <div class="col">
                            <h6 class="text-right font-weight-bold py-2">
                              PEDIDO:
                            </h6>
                          </div>
                          <div class="col-5">
                            <h6 class="text-right font-weight-bold py-2">
                              ${{ formatNumber(total) }}
                            </h6>
                          </div>
                          <div class="w-100 border-top"></div>
                          <div class="col">
                            <h6 class="text-right font-weight-bold py-2">
                              VUELTO:
                            </h6>
                          </div>
                          <div class="col-5">
                            <h6 class="text-right font-weight-bold py-2">
                              ${{ formatNumber(total_payments - total) }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="col px-1">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row">
                  <div class="col" id="notes">
                    <small class="font-weight-bold d-block"
                      >NOTAS DEL PEDIDO</small
                    >
                    <textarea
                      rows="2"
                      class="form-control"
                      v-model="ticket.notes"
                      :placeholder="notasPlaceholder"
                    ></textarea>
                    <small class="form-text text-muted"
                      >Notas del pedido, como llegar, referencias de direccion,
                      etc.</small
                    >
                  </div>
                </div>
              </li>

              <li class="list-group-item" v-if="info.deliveryEnabled">
                <div class="row">
                  <div class="col">
                    <small class="font-weight-bold">HORA PEDIDO</small>
                    <div class="w-100"></div>
                    <div class="input-group">
                      <!-- <b-form-timepicker
                        reset-button
                        v-model="fakeHour"
                        locale="es"
                        placeholder="Opcional. Hora de salida aprox"
                        customClass="w-100 text-center"
                      ></b-form-timepicker> -->
                      <input
                        type="time"
                        class="form-control"
                        v-model="ticket.hour"
                        placeholder="Hora de retiro o entrega"
                      />
                    </div>
                    <div class="row" style="padding: 0 15px">
                      <div
                        class="col-2"
                        style="padding: 0"
                        v-for="i in 24"
                        :key="i"
                      >
                        <button
                          class="btn btn-sm btn-block"
                          :class="{
                            'btn-secondary': i == timeSelected,
                            'btn-outline-secondary': i != timeSelected,
                          }"
                          @click="setHour(i * 5, i)"
                        >
                          {{ i * 5 }}"
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="list-group-item">
                <div class="row align-items-end">
                  <div class="col-md-12 col-12" id="date">
                    <small class="font-weight-bold d-block">FECHA</small>
                    <date-picker
                      v-model="ticket.adminDate"
                      valueType="format"
                      input-class="form-control"
                      id="inputDate"
                    />
                    <small class="form-text text-muted"
                      >Fecha (opcional)
                    </small>
                    <b-tooltip
                      target="inputDate"
                      triggers="hover"
                      placement="left"
                    >
                      Debe ser igual o superior a hoy
                      {{ Date.now() | formatDate }}.<br />
                      Usar para reservas
                    </b-tooltip>
                  </div>
                  <div class="col" v-if="info.beEnabled" id="documentType">
                    <small class="font-weight-bold d-block">DOCUMENTO</small>
                    <select
                      class="form-control"
                      v-model="ticket.documentType"
                      :disabled="!this.hasPermission(this.user, 'pdv_dte')"
                    >
                      <option value="TICKET">Comprobante de venta</option>
                      <option
                        :value="`DTE-${caf.type}`"
                        v-for="caf in cafList"
                        :key="caf._id"
                      >
                        {{ getDTEName(caf.type) }} - N#{{ caf.current }} -
                        {{ caf.end - caf.current }} restantes
                      </option>
                      <!-- <option value="DTE">
                        Boleta electronica afecta SII (cod. 39)
                      </option> -->
                    </select>
                    <small class="form-text text-muted">
                      Tipo de documento al generar
                    </small>
                  </div>
                  <div
                    class="col"
                    v-if="user.role == 'admin'"
                    id="documentType"
                  >
                    <small class="font-weight-bold d-block">USUARIO</small>
                    <select
                      class="form-control"
                      v-model="ticket.user"
                      :disabled="!this.hasPermission(this.user, 'pdv_dte')"
                    >
                      <option :value="user._id" v-if="user.role == 'admin'">
                        Usuario actual ({{ user.name }})
                      </option>
                      <option
                        :value="`${us._id}`"
                        v-for="us in users"
                        :key="us._id"
                      >
                        {{ us.name }}
                      </option>
                      <!-- <option value="DTE">
                        Boleta electronica afecta SII (cod. 39)
                      </option> -->
                    </select>
                    <small class="form-text text-muted">
                      Tipo de documento al generar
                    </small>
                  </div>
                </div>
              </li>

              <li
                class="list-group-item"
                v-if="ticket.documentType == 'DTE-33'"
              >
                <div class="row align-items-end">
                  <div class="col-5">
                    <small class="font-weight-bold d-block">RUT RECEPTOR</small>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="ticket.rut"
                        :class="{
                          // check rut 12345678-9
                          'is-invalid':
                            !ticket.rut &&
                            !ticket.rut.match(/^([0-9]+-[0-9kK])$/),
                          'is-valid':
                            ticket.rut &&
                            ticket.rut.length <= 10 &&
                            ticket.rut.match(/^([0-9]+-[0-9kK])$/),
                        }"
                        :disabled="loadingBusiness"
                        @keyup.enter="searchBussiness()"
                      />

                      <button
                        class="btn btn-outline-secondary"
                        @click="searchBussiness()"
                        :disabled="loadingBusiness"
                      >
                        <div v-if="loadingBusiness">
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <font-awesome-icon v-else icon="search" />
                      </button>
                    </div>
                  </div>
                  <div class="col">
                    <small class="font-weight-bold d-block"
                      >RAZON SOCIAL RECEPTOR</small
                    >
                    <div
                      class="form-control disabled overflow-hidden text-nowrap bg-light"
                    >
                      {{ ticket.rs || "Ingrese el rut del receptor y ENTER" }}
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col">
                    <small class="font-weight-bold d-block"
                      >Direccion tributaria</small
                    >
                    <div
                      class="form-control disabled overflow-hidden text-nowrap bg-light"
                    >
                      {{ ticket.billing_address || "No calle" }}
                      {{ ticket.commune || " " }}
                    </div>
                  </div>
                  <div class="w-100 py-1"></div>
                  <div class="col">
                    <small class="font-weight-bold d-block"
                      >TIPO DE VENTA</small
                    >
                    <select
                      name="TpoVenta"
                      class="form-control"
                      v-model="ticket.tpoTranVenta"
                    >
                      <option value="1">Del giro</option>
                      <option value="2">Bien fijo</option>
                      <option value="3">Bienes raices</option>
                    </select>
                  </div>

                  <div class="col">
                    <small class="font-weight-bold d-block"
                      >FORMA DE PAGO</small
                    >
                    <select
                      name="TpoVenta"
                      class="form-control"
                      v-model="ticket.fmaPago"
                    >
                      <option value="1">Contado</option>
                      <option value="2">Credito</option>
                      <option value="3">Sin costo</option>
                    </select>
                  </div>
                </div>
              </li>

              <li
                class="list-group-item"
                v-if="ticket.documentType == 'DTE-33'"
              >
                <div class="row align-items-center">
                  <div class="col">
                    <small class="font-weight-bold"
                      >ACTIVIDAD ECONOMICA EMISOR</small
                    >
                    <select
                      class="form-control"
                      v-model="ticket.acteco"
                      :class="{
                        'is-invalid':
                          ticket.acteco == '' &&
                          ticket.documentType == 'DTE-33',
                      }"
                    >
                      <option
                        v-for="act in info.siiActeco"
                        :value="act.code"
                        :key="act.code"
                      >
                        {{ act.code }} - {{ act.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>

              <li
                class="list-group-item"
                v-if="
                  info.beEnabled &&
                  info.siiAmbient == 'CERT' &&
                  ticket.documentType != 'TICKET'
                "
              >
                <div class="row align-items-center">
                  <div class="col">
                    <small class="font-weight-bold d--inline-block"
                      >REF SII</small
                    >
                    <small
                      class="font-weight-bold mx-5 d-inline-block click text-link btn btn-link"
                      @click="ticket.ref.push({ TpoDoc: 'SET', razon: '' })"
                      >AGREGAR</small
                    >
                    <div
                      class="row mb-2"
                      v-for="(ref, i) in ticket.ref"
                      :key="`ref-${i}`"
                    >
                      <div class="col-6">
                        <small class="font-weight-bold d--inline-block"
                          >RAZON</small
                        >
                        <select class="form-control" v-model="ref.razon">
                          <option>ANULA DOCUMENTO DE REFERENCIA</option>
                          <option>CORRIGE TEXTO</option>
                          <option>CORRIGE MONTOS</option>
                          <option>DEVOLUCION TOTAL</option>
                          <option>DESCUENTO GLOBAL</option>
                          <option>DESCUENTO POR ITEM</option>
                          <option>DEVOLUCION POR ITEM</option>
                          <!-- <option value="CASO 1">CASO 1</option>
                          <option value="CASO 2">CASO 2</option>
                          <option value="CASO 3">CASO 3</option>
                          <option value="CASO 4">CASO 4</option>
                          <option value="CASO 5">CASO 5</option> -->
                        </select>
                        <!-- <input
                          type="text"
                          class="form-control"
                          v-model="ref.razon"
                        /> -->
                      </div>
                      <div class="col-6">
                        <small class="font-weight-bold d--inline-block"
                          >Tipo Doc</small
                        >
                        <select class="form-control" v-model="ref.TpoDoc">
                          <option value="33">Factura electronica</option>
                          <option value="34">Factura electronica exenta</option>
                          <option value="39">Boleta electronica</option>
                          <option value="41">Boleta electronica exenta</option>
                          <option value="56">Nota de debito electronica</option>
                          <option value="61">
                            Nota de credito electronica
                          </option>
                          <!-- <option value="SET">SET</option> -->
                        </select>
                        <!-- <input
                          type="text"
                          class="form-control"
                          v-model="ref.TpoDoc"
                        /> -->
                      </div>

                      <div class="col">
                        <small class="font-weight-bold d--inline-block"
                          >Folio</small
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="ref.folio"
                        />
                      </div>

                      <div class="col">
                        <button
                          class="btn btn-sm btn-block btn-outline-danger"
                          @click="ticket.ref.splice(i, 1)"
                        >
                          <font-awesome-icon icon="trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12">
        <b-modal :id="'modal-print' + tabID" size="xs" title="Imprimir">
          <div class="row">
            <div class="col-4 col-md">
              <button
                class="btn btn-block btn-outline-success"
                @click="printTicket(lastTicket, 1)"
              >
                Comanda completa
              </button>
              <button
                class="btn btn-block btn-outline-success"
                @click="printTicket(lastTicket, 2)"
              >
                Boleta simple
              </button>
              <button
                class="btn btn-block btn-outline-success"
                @click="printTicket(lastTicket, 3)"
              >
                Comanda cocina
              </button>
              <button
                class="btn btn-block btn-outline-success"
                @click="printTicket(lastTicket, 5)"
              >
                Cambio
              </button>
              <button
                class="btn btn-block btn-outline-success"
                @click="printTicket(lastTicket, 6)"
              >
                Ticket + Detalle + Simple
              </button>
            </div>
          </div>
        </b-modal>

        <div class="row mb-0 no-gutters mt-1" id="actions">
          <div class="col-4 col-md-3 mb-4">
            <button
              class="btn btn-lg btn-secondary btn-block h-100"
              @click="saveTicket()"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              {{ saving ? `Cargando...` : "Solo Generar" }}
            </button>
            <small class="d-block text-muted text-center"
              >Genera sin imprimir</small
            >
          </div>
          <div class="col-4 col-md-3 mb-4" v-if="!isElectron()">
            <button
              class="btn btn-lg btn-primary btn-block h-100"
              @click="saveTicket(true, 1)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              {{ saving ? `Cargando...` : "Generar ticket" }}
            </button>
            <small class="d-block text-muted text-center">Genera y ver</small>
          </div>

          <div class="col-4 col-md-3 mb-4" v-if="isElectron()">
            <button
              class="btn btn-lg btn-success btn-block h-100"
              @click="saveTicket(true)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" />
              {{ saving ? `Cargando...` : "(F4) Ticket" }}
            </button>
            <small class="d-block text-muted text-center"
              >El mismo ticket de siempre</small
            >
          </div>

          <div class="col-4 col-md-3 mb-4" v-if="isElectron()">
            <button
              class="btn btn-lg btn-primary btn-block h-100"
              @click="saveTicket(true, 3)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" />
              {{ saving ? `Cargando...` : "Detalle" }}
            </button>
            <small class="d-block text-muted text-center"
              >Solo detalle del pedido</small
            >
          </div>

          <div class="col-4 col-md-3 mb-4">
            <button
              class="btn btn-lg btn-warning btn-block h-100"
              @click="saveTicket(true, 4)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" />
              {{ saving ? `Cargando...` : "Ticket + Detalle" }}
            </button>
            <small class="d-block text-muted text-center"
              >Ambas comandas en una</small
            >
          </div>

          <div class="col-4 col-md-3 mb-4 d-none1">
            <button
              class="btn btn-lg btn-info btn-block h-100"
              @click="saveTicket(true, 6)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" />
              {{ saving ? `Cargando...` : "Ticket + Detalle +  Simple" }}
            </button>
            <small class="d-block text-muted text-center">Completa, Detalle, y simple</small>
          </div>

          <div class="col-4 col-md-3 mb-4 d-none1">
            <button
              class="btn btn-lg btn-info btn-block h-100"
              @click="saveTicket(true, 5)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" />
              {{ saving ? `Cargando...` : "Ticket + Cambio" }}
            </button>
            <small class="d-block text-muted text-center">Ambas en una</small>
          </div>

          <div class="col-2 col-md-3 mb-4">
            <button
              class="btn btn-lg btn-outline-warning btn-block h-100"
              v-b-modal="'modal-print' + tabID"
              :disabled="!lastTicket._id"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" />
            </button>
            <small class="d-block text-muted text-center">Ultimo pedido</small>
          </div>

          <div class="col-2 col-md-2 d-none2">
            <select
              v-model.number="copiesTicket"
              class="form-control form-control-xl text-center"
              style="min-height: 70%"
            >
              <option :value="1">1</option>
              <option :value="2">2</option>
              <option :value="3">3</option>
              <option :value="4">4</option>
              <option :value="5">5</option>
            </select>
            <small class="d-block text-muted text-center">COPIAS</small>
          </div>

          <div class="w-100"></div>
          <div class="col-4 col-md-3 mb-4" v-if="info.remotePrintEnabled">
            <select
              class="form-control form-control-lg h-100 text-center"
              v-model="printMode"
            >
              <option value="1">Completa</option>
              <option value="2">Simple</option>
              <option value="3">Detalle</option>
              <option value="4">Completa + Detalle</option>
            </select>
            <small class="d-block text-muted text-center"
              >Modo ticket impresion remota</small
            >
          </div>
          <div class="col-4 col-md-3 mb-4" v-if="info.remotePrintEnabled">
            <button
              class="btn btn-lg btn-outline-success btn-block h-100"
              @click="saveTicket(false, printMode, true)"
              :disabled="saving"
            >
              <div
                class="spinner-border spinner-border-sm text-light mb-1"
                role="status"
                v-if="saving"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <font-awesome-icon icon="print" class="mr-2" />
              {{ saving ? `Cargando...` : "Imprimir Remoto" }}
            </button>
            <!-- dropdown -->
            <div class="dropdown h-100 d-none">
              <button
                class="btn btn-lg btn-outline-success btn-block dropdown-toggle h-100"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="saving"
              >
                <font-awesome-icon icon="print" class="mr-2" />
                {{ saving ? `Cargando...` : "Imprimir Remoto" }}
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="saveTicket(false, 1, true, 'cocina')"
                >
                  Cocina
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="saveTicket(false, 1, true, 'barra')"
                >
                  Barra
                </a>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="saveTicket(false, 1, true, 'caja')"
                >
                  Caja
                </a>
              </div>
            </div>

            <small class="d-block text-muted text-center"
              >El mismo ticket de siempre</small
            >
          </div>
        </div>

        <div class="row" v-if="false">
          <div class="col-12">
            <!-- collapse button -->
            <b-button v-b-toggle.collapse-1 variant="primary"
              >Impresion avanzada</b-button
            >
            <b-collapse id="collapse-1" class="mt-2">
              <b-card>
                <advanced-print @print="saveTicket" />
              </b-card>
            </b-collapse>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-2">
            <small>OPCIONES</small>
            <b-checkbox v-model="saveDraft" class=""
              >Almacenar tickets no generados?</b-checkbox
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Component -->
    <b-modal
      ref="modal"
      :id="'modal-search' + tabID"
      size="xxl"
      title="Buscar producto"
    >
      <label for="">Buscar</label>

      <input
        type="text"
        class="form-control"
        placeholder="Ingrese nombre o codigo del producto"
        v-model="filter"
        ref="filterInput"
      />
      <hr />
      <ul class="list-group">
        <li class="list-group-item list-group-item-dark">
          <div class="row font-weight-bold">
            <div class="col-5">ITEM</div>
            <div class="col text-right">VALOR</div>
            <div class="col-3"></div>
          </div>
        </li>
        <li
          class="list-group-item"
          v-for="product in filteredProducts"
          :key="product._id"
          :class="{
            'list-group-item-danger': product.qty <= 0 && product.stock,
          }"
        >
          <div class="row align-items-center">
            <div class="col-md">
              <span class="font-weight-bold text-uppercase">{{
                product.name
              }}</span>
              <div class="w-100"></div>
              <small>{{ product.description }}</small>
              <div class="w-100"></div>
              <small class="text-muted">CODIGO: {{ product.code }}</small>
              <small v-if="product.stock" class="text-muted ml-4"
                >DISPONIBLES: {{ product.qty }}</small
              >
              <div class="w-100"></div>
              <div v-if="product.stock">
                <div class="w-100"></div>
                <div class="row" v-for="s in product.storages" :key="s._id">
                  <div class="col text-uppercase">
                    <small>{{ s.qty }} - {{ s.storage.name }}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3 text-right font-weight-bold">
              ${{ formatNumber(product.value) }}
            </div>
            <div class="col-md-3 px-4">
              <button
                class="btn btn-sm btn-outline-success btn-block"
                @click="addCode(product.code)"
              >
                AGREGAR
              </button>
            </div>
          </div>
        </li>
      </ul>
    </b-modal>

    <b-modal
      title="Datos tributarios"
      size="lg"
      ref="invoice-modal"
      no-close-on-backdrop
    >
      <div class="row">
        <div class="col-4">
          <strong>RUT</strong>
        </div>
        <div class="col">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="ticket.rut"
              disabled
            />
            <button
              class="btn btn-outline-success"
              @click="searchBussiness(true)"
            >
              <font-awesome-icon icon="redo" />
            </button>
          </div>
        </div>

        <div class="w-100 py-1"></div>

        <div class="col-4">
          <strong>Razon social</strong>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            v-model="ticket.rs"
            disabled
          />
        </div>

        <div class="w-100 py-1"></div>

        <div class="col-4">
          <strong>Giro</strong>
        </div>
        <div class="col">
          <select
            class="form-control"
            v-model="ticket.activity"
            :class="{
              'is-invalid': ticket.activity == null,
              'is-valid': ticket.activity != null,
            }"
          >
            <option :value="act.name" v-for="act in activities" :key="act.code">
              {{ act.name }}
            </option>
          </select>
        </div>

        <div class="w-100 py-1"></div>

        <div class="col-4">
          <strong>Direccion</strong>
        </div>
        <div class="col">
          <v-select
            v-model="ticket.baddress"
            taggable
            class="py-1"
            label="street"
            @input="setBAddress()"
            :options="getBAddress"
          ></v-select>
          <small class="text-danger" v-show="ticket.baddress == ''"
            >Seleccione una direccion o escriba y presione enter</small
          >
        </div>

        <div class="w-100 py-1"></div>

        <div class="col-4">
          <strong>Region</strong>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control d-none"
            v-model="ticket.city"
          />
          <v-select
            v-model="city"
            class="py-1"
            @input="setCity()"
            :options="getRegions"
          ></v-select>
        </div>

        <div class="w-100 py-1"></div>

        <div class="col-4">
          <strong>Comuna</strong>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control d-none"
            v-model="ticket.commune"
          />
          <v-select
            v-model="ticket.commune"
            class="py-1"
            :options="getCommunes"
          ></v-select>
        </div>

        <div class="w-100 py-1"></div>

        <div class="col-4">
          <strong>Correo</strong>
        </div>
        <div class="col">
          <input type="email" class="form-control" v-model="ticket.email" />
        </div>
      </div>
    </b-modal>

    <b-modal
      title="Crear producto temporal"
      ref="newproduct-modal"
      size="lg"
      @ok="saveProduct()"
    >
      <div class="row">
        <div class="col">
          <strong>Nombre</strong>
          <input
            type="text"
            class="form-control"
            @focus="$event.target.select()"
            v-model="newProduct.name"
          />
        </div>
        <div class="w-100 py-2"></div>
        <div class="col">
          <strong>AFECTO IVA</strong>
          <b-checkbox switch v-model="newProduct.iva"></b-checkbox>
        </div>
        <div class="col">
          <strong>Cantidad</strong>
          <input
            type="number"
            class="form-control text-center"
            v-model.number="newProduct.qty"
            @focus="$event.target.select()"
          />
        </div>
        <div class="col">
          <strong>Valor</strong>
          <input
            type="number"
            class="form-control text-right"
            @focus="$event.target.select()"
            v-model.number="newProduct.value"
          />
        </div>
      </div>
    </b-modal>

    <b-modal title="Crear cliente" ref="client-modal" size="lg" @ok="saveClient">
      <div class="row">
        <div class="col">
          <strong>RUT</strong>
          <input
            type="text"
            class="form-control"
            v-model="client.rut"
          />
        </div>
        <div class="col">
          <strong>Nombre</strong>
          <input
            type="text"
            class="form-control"
            v-model="client.name"
          />
        </div>
        <div class="col">
          <strong>Telefono</strong>
          <input
            type="text"
            class="form-control"
            v-model="client.phone"
          />
        </div>
        <div class="col">
          <strong>Correo</strong>
          <input
            type="email"
            class="form-control"
            v-model="client.email"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import featured from "./featuredProducts";
import productOption from "./productOption";
import { ipcRenderer, storage } from "../../multi-utils";
import { getDTEName, log, pushProduct, copyOptions } from "../../utils";
import { regiones } from "../../regiones.json";
import adminBadge from "../../components/admin-badge";
import { isPointInPolygon, getCenter, getDistance } from "geolib";
import { useShepherd } from "vue-shepherd";
import advancedPrint from "./advancedPrint";

export default {
  props: ["tabID", "current", "payload", "index"],
  name: "NewTicket",
  components: {
    featured,
    adminBadge,
    productOption,
    advancedPrint,
  },
  data() {
    return {
      client: {
        name: "",
        rut: "",
        phone: "",
        email: "",
      },
      printMode: 1,
      couponCode: "",
      coupon: {},
      loadingBusiness: false,
      saveDraft: false,
      addressType: 0,
      zone: {},
      center: {
        lat: 0,
        lng: 0,
      },
      address: "",
      newProduct: {
        code: "",
        name: "",
        value: 0,
        qty: 1,
        iva: true,
      },
      city: {},
      baddress: [],
      activities: [],
      ticketDiscount: 0,
      open: true,
      code: null,
      loading: true,
      fecha: new Date(),
      showChangeValue: null,
      newValue: 0,
      timeSelected: 0,
      ticket: {
        products: [],
        amount: 0,
        clientName: "",
        client: null,
        createdAt: null,
        address: "",
        hour: null,
        phone: "",
        type: "Retiro",
        print: true,
        notes: "",
        status: "Por pagar",
        payment: "",
        discount: 0,
        tip: 0,
        documentType: "TICKET",
        adminDate: null,
        payments: [],
        // SII PROPS
        rut: "",
        rs: "",
        giro: "",
        baddress: "",
        commune: "",
        email: "",
        region: {},
        billing_address: "",
        ref: [],
        fmaPago: "2",
        tpoTranVenta: 1,
        acteco: "",
        user: null,
      },
      product_error: false,
      saving: false,
      filter: "",
      notasPlaceholder: "(Opcional) Datos para el reparto o local",
      featured: [],
      anon: false,
      printMode: 1,
      //      lastTicket: {},
      copiesTicket: 1,
      autoPayment: true,
      fakeHour: "",
    };
  },
  mounted() {
    //if (localStorage.autoHideMenu != "false") this.$store.commit("hideMenu");
    console.log("payload data", this.payload);

    this.loadPayload();

    if (localStorage.getItem("copiesTicket"))
      this.copiesTicket = localStorage.getItem("copiesTicket");

    if (localStorage.getItem("addressType"))
      this.addressType = parseInt(localStorage.getItem("addressType"));

    if (localStorage.getItem("printMode"))
      this.printMode = parseInt(localStorage.getItem("printMode"));

    if (localStorage.getItem("saveDraft"))
      this.saveDraft = localStorage.getItem("saveDraft") == "true";
    // this.ticket.createdAt = this.$moment().format("YYYY-MM-DD")

    // si hay payments elejimos el primero
    // if (this.payments) this.setPayment(this.payments[0]);

    // si el local no tiene habilitado el delivery
    if (this.info.deliveryEnabled == false) {
      if (this.ticket.clientName == "") this.setAnon();
      this.setStatus("Pagado");
      //this.setPayment("Efectivo");
    }

    this.loadUsers();

    // load data
    this.loadData();
    window.scrollTo(0, 0);
    this.$nextTick(() => {
      log("Cargando...");

      this.$refs["inputCode"].focus();
      this.ticket.user = this.user._id;
    });

    this.$store.dispatch("getClients")

    // DEPOSITS

    if (this.$route.query.ot && this.tabID == 0) {
      //let otid = this.$route.query.ot;
      let number = this.$route.query.otnumber;
      let deposits = JSON.parse(this.$route.query.deposits);

      this.ticket.payment = "OT";
      this.ticket.clientName = "OT #" + number;

      deposits.forEach((deposit) => {
        this.ticket.products.push({
          code: deposit._id,
          name: `ABONO - ${deposit.payment}`,
          value: deposit.amount,
          total: deposit.amount,
          unit: "UN",
          discount: 0,
          iva: true,
          details: `REF: OT N°${number}`,
          description: `OT`,
          newOptions: [],
          _options: [],
          selected_options: [],
          qty: 1,
        });
      });

      setTimeout(() => {
        this.ticket.payments = [];
      }, 500);
    }

    // WEB TICKET

    if (this.$route.query.wid) {
      if (this.ticket.products.length > 0) {
        // this.$swal({
        //   title: "Atención",
        //   text: "Ya hay productos en el ticket, borre los productos para cargar el ticket web",
        //   icon: "warning",
        //   buttons: ["Cancelar", "Ok"],
        // });
        console.log("hay productos");
        this.$emit("newTab");
        return false;
      }
      this.loadWebTicket(this.$route.query.wid);
      this.$router.replace({ query: null });
    }

    // si estamos copiando un ticket
    // DEPRECATED
    if (this.$route.query.action == "copy") {
      if (this.ticket.products.length > 0) return this.$emit("newTab");
      // create new ticket
      // and reset createdAt, products attr
      console.log("copy ticket", this.$store.state.copyTicket);
      const { receptor } = this.$store.state.copyTicket;
      this.ticket = {
        ...this.$store.state.copyTicket,
        rut: receptor ? receptor.rut : null,
        createdAt: Date.now(),
        products: [],
      };
      // ticket
      this.$router.replace({ query: null });
      let ticket = this.$store.state.copyTicket;

      ticket.products.forEach((p) => {
        // product find in list of products
        let _product = this.products.find((element) => {
          return element.code == p.code.toUpperCase();
        });
        // if product exists
        if (_product) {
          // copy product object
          let product = JSON.parse(JSON.stringify(_product));
          let newOptions = [];
          product.value = parseInt(p.value) || 0;

          // if product have newOptions prop
          if (product.newOptions) {
            // get product options
            newOptions = product.newOptions.map((newoption, i) => {
              // default object
              let nopt = {
                ...newoption,
              };
              // find in current product
              let opProduct = p.options[i];
              let optionOriginal = product.newOptions[i];

              console.log({ optionOriginal });

              console.log({ opProduct });

              if (opProduct == undefined) {
                return {
                  _id: nopt._id,
                  name: nopt.name,
                  value: nopt.value,
                  enabled: true,
                  extra: nopt.extra,
                };
              }
              // if have extra
              if (opProduct.extra) {
                log("opProduct.extra", { opProduct });
                p.value += opProduct.extra;
                product.value += opProduct.extra;
              }

              if (nopt.ref.type == "number")
                nopt.ref.selected = parseInt(opProduct.selected) || 0;
              else if (nopt.ref.type == "select" && nopt.ref.multiple) {
                nopt.ref.selected = opProduct.values.map((value) => {
                  // fix product value extra
                  product.value = product.value + value.value;
                  return {
                    _id: value._id,
                    name: value.name,
                    value: value.value,
                    enabled: true,
                    extra: value.value,
                  };
                });
              } else if (nopt.ref.type == "select") {
                let find = optionOriginal.ref.options.find(
                  (o) => o._id.name == opProduct.selected
                );
                console.log({ find });
                nopt.ref.selected = find._id;
                nopt.ref.extra = find._id.value;
              }
              return nopt;
            });
          }

          let discount = 0;
          if (p.discount) discount = p.discount;

          // #productoadd
          let productToAdd = {
            code: product.code,
            name: product.name,
            original_value: product.value,
            image: product.image,
            value: product.value || 0,
            total: product.value * p.qty,
            unit: product.unit,
            discount: discount,
            promos: product.promos,
            iva: product.iva,
            details: p.details,
            description: product.description,
            newOptions: newOptions,

            selected_options: [],
            qty: p.qty,
            stockEnabled: product.stock,
            stockqty: product.qty,
            storages: product.storages,
            storage:
              product.storages && product.storages.length > 0
                ? product.storages[0].storage._id
                : null,
          };
          log({ productToAdd });
          this.ticket.products.push(productToAdd);
        }
      });
    }

    // si esta habilitada el DTE
    if (this.info.beEnabled && this.hasPermission(this.user, "pdv_dte")) {
      if (this.cafs.find((caf) => caf.type == 39))
        this.ticket.documentType = "DTE-39";
      else if (this.cafs.find((caf) => caf.type == 33))
        this.ticket.documentType = "DTE-33";
    }
    window.addEventListener("keyup", this.keyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyDown);
  },
  methods: {
    focusInput() {
      this.$refs["inputCode"].focus();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loadPayload() {
      try {
        if (this.payload && this.payload.products) {
          console.log("loading data from payload...");
          const {
            table,
            status,
            hour,
            products,
            receptor,
            discount,
            clientName,
            phone,
            address,
            type,
            notes,
            //payments,
          } = this.payload;

          if (receptor && receptor.rut) this.ticket.rut = receptor.rut;
          if (clientName) this.ticket.clientName = clientName;
          if (phone) this.ticket.phone = phone;
          if (address) this.ticket.address = address;
          if (type) this.ticket.type = type;
          if (notes) this.ticket.notes = notes;
          if (discount) this.ticket.discount = discount;
          if (hour) this.ticket.hour = hour;
          if (status) this.ticket.status = status;
          if (table) {
            console.log("table", table);
            this.ticket.table = table;
            let newTickets = JSON.parse(localStorage.getItem("newTickets"));
            if (newTickets) {
              newTickets = newTickets.filter(
                (t) => t.table != this.ticket.table
              );
              localStorage.setItem("newTickets", JSON.stringify(newTickets));
            }

            this.payload.products.forEach((p) => {
              // product find in list of products
              let _product = this.products.find((element) => {
                return element.code == p.code.toUpperCase();
              });

              // if product exists
              if (_product) {
                // copy product object
                let product = JSON.parse(JSON.stringify(_product));
                let newOptions = [];
                product.value = p.value;

                // if product have newOptions prop
                if (product.newOptions) {
                  // get product options
                  newOptions = product.newOptions.map((newoption, i) => {
                    console.log({ newoption });
                    // default object
                    let nopt = {
                      ...newoption,
                    };
                    // find in current product
                    let opProduct = p.options[i];
                    console.log({ opProduct });

                    if (opProduct == undefined) {
                      return {
                        _id: nopt._id,
                        name: nopt.name,
                        value: nopt.value,
                        enabled: true,
                        extra: nopt.extra,
                      };
                    }

                    // if have extra
                    if (opProduct.extra) {
                      p.value += opProduct.extra;
                      //product.value += opProduct.extra;
                    }

                    if (nopt.ref.type == "number") {
                      nopt.ref.selected = parseInt(opProduct.selected) || 0;
                    } else if (nopt.ref.type == "select" && nopt.ref.multiple) {
                      console.log(
                        "setting multiple for",
                        nopt.ref.name,
                        opProduct,
                        nopt.ref
                      );
                      const selected = `${opProduct.selected}`.split(`,`);
                      console.log({ selected });

                      const exists = nopt.ref.options.find((opt) => {
                        console.log(opt._id.name, selected[i]);
                        return opt._id.name == selected[i];
                      });

                      console.log({ exists });
                    } else if (
                      nopt.ref.type == "select" &&
                      nopt.ref.multiple == false
                    ) {
                      nopt.ref.selected = {
                        _id: opProduct.ref,
                        name: opProduct.selected,
                        value: opProduct.extra,
                        enabled: true,
                      };
                    }

                    return nopt;
                  });
                }

                console.log({ p });
                let discount = parseFloat(
                  (p.discount * 100) / (p.value * p.qty)
                );

                // #productoadd
                let productToAdd = {
                  code: product.code,
                  name: product.name,
                  image: product.image,
                  original_value: product.value,
                  value: product.value,
                  total: product.value * product.qty,
                  unit: product.unit,
                  discount: discount,
                  iva: product.iva,
                  details: p.details,
                  description: product.description,
                  newOptions: [], // newOptions,
                  _options: [],
                  selected_options: [],
                  qty: p.qty,
                  stockEnabled: product.stock,
                  stockqty: product.qty,
                  storages: product.storages,
                  storage:
                    product.storages && product.storages.length > 0
                      ? product.storages[0].storage._id
                      : null,
                };
                //log({ productToAdd });
                this.ticket.products.push(productToAdd);
              }
            });
          } else {
            //console.log({ products });
            products.forEach((p) => {
              const product = this.products.find((element) => {
                return element.code == p.code.toUpperCase();
              });

              if (!product)
                return console.log(
                  "no se encontro por el codigo",
                  p.code.toUpperCase()
                );

              if (product.newOptions) {
                console.log("set new options");
                copyOptions(product, p, this.setNewOption);
              }

              product.details = p.details;
              pushProduct(this.ticket, product, 1);
            });
            this.clearFromNewTickes();
          } //if(payments) this.ticket.payments = payments;
        }
      } catch (error) {
        console.error("error loading payload", error);
      }
    },
    createClient() {
      this.$refs["client-modal"].show();
    },
    saveClient() {
      this.$http
        .post("client", this.client)
        .then((res) => {
          this.$noty.success("Cliente creado");
          this.loadUsers();
        })
        .catch((err) => {
          this.$noty.error("Error al crear cliente");
        });
    },
    loadWebTicketFromCode() {
      if (!this.code) return this.$noty.error("Ingrese un codigo");
      this.loadWebTicket(this.code);
      this.code = "";
    },
    loadWebTicket(id) {
      this.$http
        .get("web/ticket/" + id)
        .then((res) => {
          let data = res.data.data;
          console.log("webticket", data);

          if (data.address) this.ticket.address = data.address;
          if (data.clientName) this.ticket.clientName = data.clientName;
          if (data.rut) this.ticket.clientName += ` - ${data.rut}`;
          if (data.phone) this.ticket.phone = data.phone;
          if (data.notes) this.ticket.notes = data.notes;
          if (data.date)
            this.ticket.adminDate = this.$moment()
              .add(data.date, "days")
              .format("YYYY-MM-DD");
          if (data.time > -1)
            this.ticket.hour = this.$moment()
              .hour(data.time)
              .minutes(0)
              .format("HH:mm");
          // this.ticket.payment = data.payment;
          if (data.status == "Pendiente") this.ticket.wid = data._id;

          if (data.tip) this.ticket.tip = data.tip;

          if (data.type == "Envio") this.ticket.type = "Domicilio";
          else this.ticket.type = data.type;

          setTimeout(() => {
            this.ticket.amount = data.amount;
          }, 1500);

          // let p is current product of webticket
          data.products.forEach((p) => {
            // product find in list of products
            let _product = this.products.find((element) => {
              return element.code == p.code.toUpperCase();
            });

            // if product exists
            if (_product) {
              // copy product object
              let product = JSON.parse(JSON.stringify(_product));
              //product.value = p.value;

              copyOptions(product, p, this.setNewOption);
              console.log({ p });
              let discount = parseFloat((p.discount * 100) / (p.value * p.qty));

              // #productoadd
              let productToAdd = {
                code: product.code,
                name: product.name,
                image: product.image,
                original_value: product.value,
                value: product.value,
                total: product.value * product.qty,
                unit: product.unit,
                discount: discount,
                iva: product.iva,
                details: p.details,
                description: product.description,
                promos: product.promos,
                newOptions: product.newOptions,
                _options: product.options || [],
                selected_options: [],
                qty: p.qty,
                stockEnabled: product.stock,
                stockqty: product.qty,
                storages: product.storages,
                storage:
                  product.storages && product.storages.length > 0
                    ? product.storages[0].storage._id
                    : null,
              };
              //log({ productToAdd });
              pushProduct(this.ticket, productToAdd, p.qty);
            }
          });

          if (data.deliveryValue && data.type == "Domicilio") {
            setTimeout(() => {
              this.ticket.products.unshift({
                code: "ENVIODOMICILIO",
                name: `ENVIO A DOMICILIO`,
                value: data.deliveryValue,
                total: data.deliveryValue,
                unit: "Unid",
                discount: 0,
                iva: true,
                details: "",
                description: "",
                newOptions: [],
                _options: [],
                selected_options: [],
                qty: 1,
              });
            }, 500);
          }

          if (data.discount) this.ticket.discount = data.discount;

          setTimeout(() => {
            log("set payment");
            this.setPayment(data.payment, false, data.amount);
            this.autoPayment = false;
          }, 2000);
          // console.log("info", this.hideWebTicketInfo);
          // if (!this.hideWebTicketInfo) {
          //   this.$swal({
          //     icon: "info",
          //     title: "Importante",
          //     text: "Esta copiando un ticket web, cambiar opciones solo si se necesita modificar",
          //     buttons: {
          //       not_show: {
          //         text: "No volver a mostrar",
          //         value: "hide",
          //       },
          //       aceptar: true,
          //     },
          //   }).then((value) => {
          //     if (value == "hide") storage.set("hideWebTicketInfo", 1);
          //   });
          // }
        })
        .catch(() => {
          this.$noty.error("Error al cargar el ticket web");
        });
    },
    checkPromos() {
      const self = this;
      console.log("checking promos");
      this.ticket.products.forEach(function (product) {
        log("foreach watch ticket products");
        // promos
        if (product.promos) {
          product.promos.forEach(function (promo) {
            if (!promo.enabled) return false;
            log("Promo enabled", promo);
            // when qty
            if (promo.when == "qty") {
              if (self.checkCondition(product.qty, promo.is, promo.value)) {
                if (promo.to == "total") {
                  if (promo.in == "$") {
                    let total = product.value * product.qty;
                    let newTotal = total - promo.discount;
                    let discount = (newTotal * 100) / total;
                    product.discount = 100 - discount;
                  }
                  if (promo.in == "%") {
                    product.discount = promo.discount;
                  }
                } else if (product.to == "product") {
                  if (promo.in == "$") {
                    product.discount = (promo.discount * 100) / product.value;
                  }
                  if (promo.in == "%") product.discount = promo.discount;
                }
              } else {
                product.discount = 0;
              }
            } else if (promo.when == "total") {
              if (product.total == promo.is) {
                // to do
              }
            } else if (promo.when == "minopcion") {
              // get minoption
              let minOption = null;
              product.newOptions.forEach((p) => {
                //if(p.ref.extra == 0) return false
                if (p.ref.extra > promo.value)
                  if (
                    !minOption &&
                    self.checkCondition(p.ref.extra, promo.is, promo.value)
                  )
                    minOption = p.ref.extra;
                  else if (minOption > p.ref.extra) minOption = p.ref.extra;
              });

              log({ minOption, promo });
              if (promo.in == "%") {
                let originalValue = product.value;
                let discount =
                  (minOption * parseInt(promo.discount)) / originalValue;
                log({ discount, originalValue });
                // product.discount = parseInt(discount)
                product.discount = discount;
              }

              // if (promo.in == "$") {
              //   product.discount = (promo.discount * 100) / product.total;
              // }
            }
          });
        }
        // discount
        product.discountAmount = Math.round(
          (product.value * product.qty * product.discount) / 100
        );

        // total
        product.total = Math.round(
          product.value * product.qty -
            (product.value * product.qty * product.discount) / 100
        );

        // product.total = Math.round(prodi)
      });
    },
    loadUsers() {
      if (!this.hasPermission(this.user, "pdv_user")) return;

      this.$http.get("user").then((res) => {
        this.$store.commit("setUsers", res.data.data);
      });
    },
    clearFromNewTickes() {
      console.log("clear from new tickets", this.payload);
      if (this.payload) {
        let newTickets = localStorage.getItem("newTickets");
        if (newTickets) {
          console.log("clear from index", this.index);
          newTickets = JSON.parse(newTickets);
          newTickets.splice(this.index, 1);
          localStorage.setItem("newTickets", JSON.stringify(newTickets));
        }
      }
    },
    startTour() {
      const tour = useShepherd({
        useModalOverlay: true,
        exitOnEsc: true,
        defaultStepOptions: {
          classes: "shadow-md",
          scrollTo: true,
        },
      });

      tour.addStep({
        title: "Bienvenido a la guía de TicketApp",
        text: "Esta es la pantalla de venta de TicketApp",
        attachTo: {
          element: "#nav-pdv",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.cancel();
            },
            classes: "btn btn-secondary",
            text: "Cancelar",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Escanea o escribe",
        text: "Aquí puedes escanear o escribir el código de barras de un producto",
        attachTo: {
          element: "#inputCode",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Productos",
        text: "Aquí puedes ver los productos disponibles",
        attachTo: {
          element: "#btnSearch",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      // tour.addStep({
      //   title: "Productos",
      //   text: "Puedes agregar productos al ticket haciendo click en ellos",
      //   attachTo: {
      //     element: ".product-list",
      //     on: "bottom",
      //   },
      //   buttons: [
      //     {
      //       action() {
      //         return this.back();
      //       },
      //       classes: "btn btn-secondary",
      //       text: "Atrás",
      //     },
      //     {
      //       action() {
      //         return this.next();
      //       },
      //       classes: "btn btn-primary",
      //       text: "Siguiente",
      //     },
      //   ],
      // });

      tour.addStep({
        title: "Ticket",
        text: "Aquí puedes ver los productos agregados al ticket",
        attachTo: {
          element: ".cart",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Monto total",
        text: "Aquí puedes ver el monto total del ticket, se actualiza automáticamente al agregar o eliminar productos",
        attachTo: {
          element: ".total",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Descuento",
        text: "Aquí puedes aplicar un descuento al ticket",
        attachTo: {
          element: "#discountInput",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Descuento",
        text: "O puedes seleccionar un descuento predefinido",
        attachTo: {
          element: "#discountBtn",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-secondary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Pagos",
        text: `
        <p>Selecciona el tipo de pago</p>
        Si la cantidad es mayor al total, la diferencia sera indicada como vuelto<br>
        Si la cantidad es menor al total, se podra indicar otro medio de pago para la diferencia<br>
        `,
        attachTo: {
          element: "#payments",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Notas del pedido",
        text: "Aquí puedes agregar notas al pedido, estas serán impresas en el ticket",
        attachTo: {
          element: "#notes",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });

      tour.addStep({
        title: "Fecha",
        text: `Modifica la fecha solo de ser necesario, en caso contrario dejar en blanco<br>
          Usar para reservas o pedidos a futuro<br>
          Para ingresar pedidos del dia anterior despues de las 00:00, usar la fecha de ayer
        `,
        attachTo: {
          element: "#date",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Siguiente",
          },
        ],
      });
      // actions

      // tipo documento
      if (this.info.beEnabled)
        tour.addStep({
          title: "Tipo de documento",
          text: "Selecciona el tipo de documento",
          attachTo: {
            element: "#documentType",
            on: "bottom",
          },
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: "btn btn-primary",
              text: "Atrás",
            },
            {
              action() {
                return this.next();
              },
              classes: "btn btn-primary",
              text: "Siguiente",
            },
          ],
        });

      tour.addStep({
        title: "Acciones",
        text: "Aquí puedes guardar el ticket, imprimirlo o solo generarlo",
        attachTo: {
          element: "#actions",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: "btn btn-primary",
            text: "Atrás",
          },
          {
            action() {
              return this.next();
            },
            classes: "btn btn-primary",
            text: "Finalizar",
          },
        ],
      });

      tour.start();
    },
    applyCoupon() {
      if (!this.couponCode)
        return this.$swal({
          title: "Lo sentimos",
          text: "Debes ingresar un código de cupón",
        });
      if (this.ticket.products.length == 0)
        return this.$swal({
          title: "Lo sentimos",
          text: "No puedes aplicar un cupón a un pedido vacío",
        });
      this.loading = true;
      this.coupon = {};
      const id = this.info.webName;
      console.log("id", id);
      if (!id || id == undefined) return false;

      this.$http
        .post(`w/${id}/coupon`, { code: this.couponCode, b: this.info._id })
        .then((res) => {
          let coupon = res.data.data;
          //this.ticket.coupon = this.coupon._id;

          if (
            (coupon.min && this.total < coupon.min) ||
            (coupon.max && this.total > coupon.max)
          )
            return this.$swal({
              title: "Lo sentimos",
              text: `El cupón no es válido para este monto \n Min: $${this.formatNumber(
                coupon.min
              )} - Max: $${this.formatNumber(coupon.max)}`,
            });

          if (coupon.only && coupon.only.length > 0) {
            // check if product cart contains coupon only product
            let ids = coupon.only.map((p) => p.code);
            let filter = this.products.filter((p) => !ids.includes(p.code));

            if (filter && filter.length > 0)
              return this.$swal({
                title: "Lo sentimos",
                text: "El cupón no es válido para este pedido\nSolo aplica a los productos seleccionados",
              });
          }

          if (coupon.exclude && coupon.exclude.length > 0) {
            // check if product cart contains coupon only product
            let ids = coupon.exclude.map((p) => p.code);
            let filter = this.products.filter((p) => ids.includes(p.code));

            if (filter && filter.length > 0)
              return this.$swal({
                title: "Lo sentimos",
                text: "El cupón no es válido para este pedido\nNo aplica a los productos seleccionados",
              });
          }

          this.coupon = coupon;
          //this.cuponDisabled = true;
        })
        .catch((err) => {
          this.$swal({
            title: "Error",
            text: "Error al aplicar el codigo",
            icon: "error",
          });
          //this.$refs["couponInput"].focus();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showSearch() {
      if (this.current == this.tabID) this.$refs.modal.show();
      setTimeout(() => {
        this.$refs.filterInput.focus();
      }, 500);
    },
    setTip(val = 0) {
      this.ticket.tip = this.total * (val / 100);
    },
    addDelivery() {
      let deliveryValue = this.zone.value;
      let distance = 0;

      if (this.zone.type == "km") {
        distance = getDistance(
          { latitude: this.zone.center.lat, longitude: this.zone.center.lng },
          { latitude: this.center.lat, longitude: this.center.lng }
        );

        let km = this.zone.kms.find(
          (km) => km.start <= distance && km.end >= distance
        );

        if (km) deliveryValue = km.value;
        else this.$noty.error("No se encontro un valor para la distancia");
        console.log("distance", distance);
      }

      //if(deliveryValue == 0) return false

      this.ticket.products.unshift({
        code: "ENVIODOMICILIO",
        name: `ENVIO A DOMICILIO`,
        description: `Envio a ${this.zone.name}`,
        value: deliveryValue,
        total: deliveryValue,
        unit: "Unid",
        discount: 0,
        iva: true,
        details: "",
        promos: [],
        newOptions: [],
        qty: 1,
      });
      this.$noty.success("Envio agregado");
    },
    centerMap() {
      let zones = [];
      this.zones.forEach((zone) => {
        let z = zone.paths.map((z) => ({ latitude: z.lat, longitude: z.lng }));
        zones.push(...z);
      });

      let center = getCenter(zones);

      if (center) this.center = { lat: center.latitude, lng: center.longitude };
    },
    getAddressData(data) {
      const { geometry, name, vicinity } = data;

      // this.address = `${name}, ${vicinity}`;
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();
      // console.log({ data, lat, lng });

      console.log("address data", data);

      this.address = `${name || ""}, ${vicinity || ""}`;
      this.ticket.address = this.address;
      console.log({ data, lat, lng });
      this.addressGPS = { lat, lng };

      let zone = this.zones.find((z) => {
        let paths = z.paths.map((p) => {
          return { latitude: p.lat, longitude: p.lng };
        });
        let isIn = false;
        if (z.type == "zone")
          isIn = isPointInPolygon({ latitude: lat, longitude: lng }, paths);
        else {
          let distance = getDistance(
            { latitude: z.center.lat, longitude: z.center.lng },
            { latitude: lat, longitude: lng }
          );
          let km = z.kms.find(
            (km) => km.start <= distance && km.end >= distance
          );
          if (km) isIn = true;
          else this.$noty.error("No se encontro un valor para la distancia");
        }
        // console.log({ name: z.name, isIn });
        // console.table(paths);
        if (isIn) {
          this.zoom = 16;
          this.zone = z;
          this.center = { lat, lng };
          this.addDelivery();
          return z;
        }
      });
      if (!zone) {
        //alert('Fuera de la zona de reparto')
        this.$swal({
          title: "Lo sentimos",
          text: "La dirección esta fuera de la zona de reparto. Se quito el envio a domicilio",
        });
        // this.center = data.location;
        this.zoom = 16;
        this.center = { lat, lng };
        this.zone = {};
        // remove ENVIO from ticket products
        this.ticket.products = this.ticket.products.filter(
          (p) => p.code != "ENVIODOMICILIO"
        );
      }
    },
    setBAddress() {
      const { commune, city, street } = this.ticket.baddress;
      console.log("ticket addresss", this.ticket.baddress);
      this.ticket.city = city;
      this.city = this.getRegions.find((r) => r.label == city);
      this.ticket.billing_address = street || this.ticket.baddress;
      this.ticket.commune = commune;
    },
    setCity() {
      this.ticket.city = this.city.label;
    },
    searchBussiness(update = false) {
      //const rut = this.rut;
      this.ticket.rut = this.ticket.rut.replace(/\./g, "").toUpperCase();
      this.loadingBusiness = true;
      this.ticket.email = "";
      this.ticket.baddress = "";
      this.city = "";
      this.ticket.commune = "";

      this.$http
        .get(`siidata?rut=${this.ticket.rut}&update=${update}`)
        .then((response) => {
          console.log(response.data.data);
          const { rs, activities, address, email } = response.data.data;
          this.ticket.rs = rs;
          this.activities = activities;
          if (this.activities.length > 0)
            this.ticket.activity = this.activities[0].name;
          // all address of business
          this.baddress = address || [];
          this.ticket.tpoTranVenta = 1;
          this.ticket.fmaPago = 2;
          this.ticket.email = email;
          this.$refs["invoice-modal"].show();

          if (!this.ticket.acteco && this.info.siiActeco.length > 0) {
            this.ticket.acteco = this.info.siiActeco[0].code;
          }
        })
        .catch((error) => {
          console.log("Error al buscar info sii", error);
          this.$noty.error(
            "Error al buscar, verifique rut e intente nuevamente"
          );
        })
        .finally(() => {
          this.loadingBusiness = false;
        });
    },
    getDTEName(id) {
      return getDTEName(id);
    },
    removeOption({ product, index }) {
      product.newOptions.splice(index, 1);
    },
    paymentIn(p) {
      let pp = this.ticket.payments.map((_) => _.name);
      return pp.includes(p);
    },
    focusPaymentInput() {
      try {
        setTimeout(() => {
          // console.log(this.$refs)
          let index = this.$refs["paymentinput"].length - 1;
          let ref = this.$refs["paymentinput"][index];
          if (!ref) return false;
          ref.childNodes[1].childNodes[0].focus();
        }, 500);
      } catch (error) {
        log("error focus", error);
      }
    },
    applyDiscount(i) {
      let discount = (this.total_neto / 100) * (i * 5);
      this.ticket.discount = parseInt(discount);
    },
    setAnon() {
      this.anon = !this.anon;
      if (this.anon) this.ticket.clientName = "ANON";
      else this.ticket.clientName = "";
    },
    isIn(selected, value) {
      if (selected == null) return false;
      return selected.split(",").find((v) => v == value);
    },
    changeValue(product, val) {
      this.showChangeValue = null;
      product.value = val;
    },
    changeQty(product, type) {
      if (type == "add") product.qty++;
      if (type == "subs" && product.qty > 1) product.qty--;
    },
    setOption(option, value, parent = null) {
      var values = null;
      if (option.multiple == true) {
        if (option.selected != null) {
          var exists = option.selected.indexOf(value) != -1;
          if (exists) {
            values = option.selected.split(",").filter((el) => el != value);
            option.selected = values.join();
          } else {
            values = option.selected.split(",");
            values.push(value);
            option.selected = values.join();
          }
        } else {
          option.selected = value;
        }
      } else {
        if (option.selected == value) option.selected = null;
        else option.selected = value;
      }
      option.parent = parent;
    },
    addCode(code) {
      this.code = code;
      this.addProduct();
      this.$refs.modal.hide();
      this.filter = "";
      this.$noty.success("Producto agregado correctamente");
    },
    keyDown(e) {
      if (e.key == "F1") {
        this.$refs.inputCode.focus();
        e.preventDefault();
      }
      if (e.key == "F2") {
        if (this.current == this.tabID) {
          if (this.info.deliveryEnabled) {
            this.$refs.clientNameInput.focus();
          } else {
            this.$refs.inputCode.blur();
          }
          window.scrollTo(0, document.body.scrollHeight - 20);
        }
        e.preventDefault();
      }
      if (e.key == "F3") {
        // if (this.current == this.tabID) this.$refs.modal.show();
        // setTimeout(() => {
        //   this.$refs.filterInput.focus();
        // }, 500);
        this.showSearch();
        e.preventDefault();
      }
      if (e.key == "F4") {
        if (this.current == this.tabID) this.saveTicket(true);
        e.preventDefault();
      }
    },
    setDcto(product, dcto) {
      product.discount = dcto;
    },
    getProducts() {
      const products = this.$store.products.map((product) => {
        if (product.stock) {
          return {
            label: `${product.code} - (${product.qty}) ${product.name} `,
            code: product.code,
          };
        }
        return {
          label: `${product.code} - ${product.name}`,
          code: product.code,
        };
      });
      return products;
    },
    clearTicket(show = false) {
      console.log("clearing ticket");
      this.showChangeValue = null;
      this.ticket = {
        products: [],
        amount: 0,
        clientName: "",
        createdAt: null,
        address: "",
        hour: null,
        phone: "",
        type: "Retiro",
        print: true,
        notes: "",
        status: "Por pagar",
        payment: "",
        discount: 0,
        tip: 0,
        documentType: "TICKET",
        adminDate: null,
        rut: "",
        rs: "",
        giro: "",
        dir: "",
        payments: [],
        refs: [],
        fmaPago: "2",
        tpoTranVenta: 1,
        acteco: "",
      };

      // if (this.payments.length > 0) this.ticket.payment = this.payments[0].name;

      if (this.info.deliveryEnabled) {
        this.ticket.payment = "";
        this.ticket.status = "Por pagar";
        this.timeSelected = 0;
        this.ticket.clientName = "";
      } else {
        // this.ticket.payment = "Efectivo";
        setTimeout(() => {
          if (this.ticket.clientName == "") this.setAnon();
        }, 500);
        this.ticket.status = "Pagado";
      }
      if (this.anon) this.ticket.clientName = "ANON";
      // this.setHour(0);
      if (this.info.beEnabled) this.ticket.documentType = "DTE-39";
      else this.ticket.documentType = "TICKET";

      this.ticket.user = this.user._id;

      if (show) this.$noty.info("Ticket limpio");
    },

    loadData() {
      this.product_error = false;
      setTimeout(() => {
        this.$http
          .get("product")
          .then((res) => {
            const products = [];
            res.data.data.forEach((product) => {
              product.discountAmount = 0;
              products.push(product);
            });
            this.$store.commit("setProducts", products);
          })
          .catch(() => {
            this.product_error = true;
          });
        //if (!this.$route.query.id) this.setHour(0);
      }, 1000);

      if (this.info.webEnabled) {
        this.$http.get("web/ticket").then((res) => {
          this.$store.commit("setWebTickets", res.data.data);
        });
      }
    },
    setType(type) {
      this.ticket.type = type;
    },
    setStatus(status) {
      this.ticket.status = status;
    },
    removePayment(payment) {
      this.ticket.payments.splice(this.ticket.payments.indexOf(payment), 1);
    },
    setPayment(payment = null, focus = 0, amt = 0) {
      let amount = this.total - this.total_payments;

      // if(this.autoPayment == false && this.ticket.payments.length == 1) {
      //   let firstPayment = this.ticket.payments[0];
      //   this.ticket.payments = [{
      //     name: firstPayment.name,
      //     amount: this.total,
      //     total: this.total
      //   }]
      //   return
      // }

      // if(this.autoPayment == false && this.ticket.payments.length > 0) return;
      log({ payment });

      if (!payment) {
        if (this.ticket.payments.length == 0) payment = this.payments[0];
        if (this.ticket.payments.length == 1)
          payment = this.ticket.payments[0].name;
      }
      // total payments equal total
      // total payment length <= 1
      if (
        this.total_payments == this.total &&
        this.ticket.payments.length <= 1
      ) {
        this.ticket.payments = [];
        this.setPayment(payment, focus, amt);
      } else {
        if (this.total_payments == this.total) return false;

        this.ticket.payments.push({
          name: payment,
          amount: amt || amount,
          total: amount,
        });
      }
      // this.ticket.payment = payment;
      if (focus) {
        this.focusPaymentInput();
      }
    },
    setHour(min, i = 0) {
      this.timeSelected = i;
      let date = this.$moment();
      date.add(min, "minutes");
      const timeFormat = this.info.timeFormat;
      this.ticket.hour =
        timeFormat == 24 ? date.format("HH:mm") : date.format("hh:mm");
    },
    addPalito(val) {
      if (this.ticket.palitos == 0) this.ticket.palitos = `${val}`;
      else this.ticket.palitos = `${this.ticket.palitos} - ${val}`;
    },
    addExtra(extra) {
      var separator = "";
      if (this.ticket.salsas != "") separator = "/";
      this.ticket.salsas = `${this.ticket.salsas} ${separator} ${extra}`;
    },
    createProduct() {
      this.$refs["newproduct-modal"].show();
    },
    saveProduct() {
      let product = this.newProduct;
      let qty = product.qty;

      this.ticket.products.unshift({
        code: `TEMP_${Date.now()}`,
        name: product.name,
        image: null,
        original_value: product.value,
        value: product.value,
        total: product.value * qty,
        discount: 0,
        unit: "UNID",
        iva: product.iva,
        editableValue: true,
        description: "",
        options: [],
        _options: [],
        newOptions: [],
        selected_options: [],
        qty: qty,
        stockEnabled: false,
        stockqty: 0,
        storages: [],
        promos: [],
        storage: null,
      });
    },
    addProduct() {
      event && event.preventDefault();
      let code = this.code.toUpperCase();
      let qty = 1;
      let customValue = 0;

      if (code.indexOf("*") != -1) {
        let [nCode, nQty] = code.split("*");
        if (!parseInt(nQty)) return false;
        qty = parseInt(nQty);
        code = nCode;
      }

      if (code.indexOf("/") != -1) {
        let [nCode, nQty] = code.split("/");
        if (!parseInt(nQty)) return false;
        customValue = parseInt(nQty);
        code = nCode;
      }

      let p = this.products.find((el) => {
        return el.code == code;
      });

      if (!p) {
        this.$noty.error(
          "El codigo ingresado no coincide con ningun producto."
        );
        return false;
      }

      if (customValue && !p.editableValue) {
        this.$noty.error("Precio editable no habilitado en el producto");
        return false;
      }

      // remove vuejs reference from object
      let product = JSON.parse(JSON.stringify(p));

      if (product && product.code) {
        const options = [];
        // deprecated
        // if (product.options) {
        //   product.options.forEach((option) => {
        //     options.push({
        //       label: option.label,
        //       values: option.values.toString().split(","),
        //       multiple: option.multiple,
        //       repeat: option.repeat,
        //       selected: null,
        //     });
        //   });
        // }

        //let newOptions = [];
        // if (product.newOptions) {
        //   newOptions = product.newOptions.map((o) => {
        //     // o.filter = ''
        //     if (o.ref.type == "number") o.ref.selected = 0;
        //     else if (o.ref.type == "select") o.ref.selected = [];
        //     else o.ref.selected = "";
        //     return o;
        //   });
        // }

        // add product to ticket
        let cid = Date.now();
        console.log({ product });
        this.ticket.products.unshift({
          cid: cid,
          code: product.code,
          name: product.name,
          image: product.image,
          original_value: product.value,
          value: customValue || product.value,
          total: (customValue || product.value) * qty,
          discount: 0,
          unit: product.unit,
          iva: product.iva,
          editableValue: product.editableValue,
          description: product.description,
          newOptions: product.newOptions || [],
          selected_options: [],
          qty: qty,
          promos: product.promos,
          stockEnabled: product.stock,
          stockqty: product.qty,
          storages: product.storages,
          storage:
            product.storages && product.storages.length > 0
              ? product.storages[0].storage._id
              : null,
          discontIn: "%",
        });
      } else {
        this.$noty.error(
          "El codigo ingresado no coincide con ningun producto."
        );
      }
      this.code = null;
      this.ticket.amount = this.total;
    },
    printTicket(ticket, type) {
      const copies = this.copiesTicket || 1;
      this.$emit("printTicket", { ticket, type, copies });
    },
    printTicketold(ticket = null, type = 1) {
      if (!ticket) ticket = this.ticket;
      localStorage.ticket = JSON.stringify(ticket);
      localStorage.info = JSON.stringify(this.$store.state.info);
      localStorage.printMode = type;
      localStorage.copiesTicket = this.copiesTicket;
      //this.$store.commit('addPrintQueue', ticket)
      this.$store.commit("setTicket", ticket);
      ipcRenderer.send("printTicket");
      this.$noty.success("Ticket enviado a imprimir");
    },
    // deprecated
    printTicket2old(ticket = null, type = 1, copies) {
      if (!ticket) return this.$noty.info("Debe seleccionar un ticket");
      localStorage.printMode = type;
      localStorage.copiesTicket = copies;
      this.$store.commit("setTicket", ticket);
      setTimeout(() => {
        let tickethtml = document.querySelector("#ticket-hidden").innerHTML;
        let html = `<!DOCTYPE html>
        <html lang="es">
          <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </head>
          <body>
            ${tickethtml}
          </body>
        </html>`;

        let blob = new Blob([html], { type: "text/html" });
        let url = URL.createObjectURL(blob);
        if (this.isElectron()) {
          storage.get("printer", (err, data) => {
            if (err) this.$noty.error("Intente nuevamente");
            ipcRenderer.send("print", { printerName: data, url });
          });
        } else {
          var w = window.open(url);
          setTimeout(() => {
            w.print();
            w.close();
          }, 1000);
        }
      }, 500);
    },
    saveTicket(print = false, type = 1, remote = false) {
      if (this.total == 0 && this.ticket.documentType == "DTE") {
        return this.$noty.error(
          `No se puede generar boleta con total $0. 
          Cambie a comprobante para continuar o agrege otro producto`
        );
      }

      if (this.ticket.clientName == "") {
        return this.$noty.error(
          "Debe ingresar el nombre del cliente o usar ANONIMO"
        );
      }

      if (this.ticket.products.length == 0)
        return this.$noty.error("Debe ingresar al menos 1 producto");

      if (this.total < 0)
        return this.$noty.error("Total invalido. Debe ser mayor a 0");

      if (this.ticket.documentType == "DTE-33") {
        if (this.ticket.products.length > 50) {
          return this.$noty.error(
            "No se puede generar factura con mas de 60 productos. Genere documentos separados"
          );
        }

        if (this.ticket.rut == "") {
          return this.$noty.error("Debe ingresar el rut del cliente");
        }

        if (this.ticket.rs == "") {
          return this.$noty.error("Debe ingresar la razon social del cliente");
        }
      }

      if (this.ticket.adminDate) {
        if (
          this.$moment(this.ticket.adminDate).diff() < 0 &&
          this.ticket.documentType == "DTE"
        )
          return this.$noty.error(
            "La fecha debe ser superior a hoy o vacia para documentos electronicos"
          );
      }
      this.saving = true;

      this.ticket.products.reverse();

      if (this.coupon && this.coupon._id) {
        this.ticket.coupon = this.coupon._id;
      }

      this.$http
        .post("ticket", this.ticket)
        // .then((res) => {
        //   return this.$http.get(`ticket/${res.data.data._id}`);
        // })
        .then((res) => {
          // set print mode to full ticket 1=full 2=only details 3=simple
          localStorage.printMode = type;

          if (print) {
            //this.printTicket(res.data.data, mode);
            this.$store.commit("setTicket", res.data.data);
            this.$emit("printTicket", {
              ticket: res.data.data,
              type,
              copies: this.copiesTicket,
            });
          }
          if (remote) {
            this.$http
              .post("print", {
                ticket: res.data.data._id,
                type: this.printMode,
              })
              .then(() => {
                this.$noty.success("Enviado a imprimir en red");
              })
              .catch(() => {
                this.$noty.error(
                  "Error al imprimir en red. Intente nuevamente"
                );
              });
          }
          // this.lastTicket = res.data.data;
          this.$store.commit("setLastTicket", res.data.data);
          this.clearTicket();
          this.$noty.success("Ticket ingresado correctamente");
          this.loadData();
          this.clearFromNewTickes();
          this.scrollToTop();
          this.focusInput();
        })
        .catch((err) => {
          if (err.status == 0 && err.status == 10000) {
            let pending = this.$store.state.pending_tickets;
            // this.ticket.createdAt = Date.now();
            // get total of ticket
            this.ticket.total =
              this.ticket.products.reduce(
                (t, c) => Math.round(c.total + t),
                0
              ) - this.ticket.discount;

            // if ticket is DTE load caf and asing to ticket
            if (this.ticket.documentType == "DTE") {
              if (!this.$store.state.folio) {
                let caf = this.$store.state.cafs.find((c) => c.current < c.end);
                if (!caf)
                  return this.$swal({
                    title: "Error",
                    text: "No tienes CAF disponible.\nUsa comprobante de venta y luego cambia a boleta cuando dispongas conexion",
                    icon: "error",
                  });
                else {
                  this.$store.commit("setFolio", caf.current);
                }
              }
              this.ticket.folio = this.$store.state.folio;
              this.ticket.isDTE = true;
              this.$store.commit("setFolio", this.$store.state.folio + 1);
            }
            // push ticket
            pending.push(this.ticket);
            this.$store.commit("setPending", pending);
            // this.$store.state.pending_tickets = pending;
            this.$noty.info("Ticket ingresado en cola");
            this.clearTicket();
          } else {
            const { message } = err.data;
            console.log(err.data);
            this.$noty.error(
              message ||
                "Error al guardar el ticket, verifique los datos y reintente."
            );
          }
          this.saving = false;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    remove(product) {
      this.ticket.products.splice(this.ticket.products.indexOf(product), 1);
    },
    checkCondition(current, con, value) {
      if (con == "lt") return current < value;
      if (con == "lte") return current <= value;
      if (con == "eq") return current == value;
      if (con == "gt") return current > value;
      if (con == "gte") return current >= value;
    },
    updatePayment() {
      if (this.total == 0) return (this.ticket.payments = []);
      if (this.ticket.payments.length == 0) this.setPayment();
      else if (this.ticket.payments.length == 1) {
        let firstPaymentName = this.ticket.payments[0];
        this.ticket.payments = [];
        let paymentName = firstPaymentName ? firstPaymentName.name : null;
        this.setPayment(paymentName);
      }
    },
  },
  computed: {
    clients() {
      return this.$store.state.clients.map((c) => {
        return {
          label: `${c.rut} - ${c.name}`,
          value: c
        };
      });
    },
    users() {
      return this.$store.state.users.filter((u) => u.active);
    },
    lastTicket() {
      return this.$store.state.lastTicket;
    },
    cafList() {
      const cafs = this.$store.state.cafs;

      return cafs.filter((c) => [39, 33].includes(c.type));
    },
    cuponTotal() {
      let discount = 0;
      let coupon = this.coupon;
      if (coupon && coupon._id) {
        if (coupon.to == "delivery") {
          if (coupon.type == "percent") {
            //discount = this.deliveryValue * (coupon.amount / 100);
          } else {
            discount = coupon.amount;
          }

          if (discount > this.deliveryValue) discount = this.deliveryValue;
        } else if (coupon.to == "subtotal") {
          if (coupon.type == "percent") {
            discount = this.subtotal * (coupon.amount / 100);
          } else {
            discount = coupon.amount;
          }

          if (discount > this.subtotal) discount = this.subtotal;
        }
        if (coupon.to == "total") {
          if (coupon.type == "percent") {
            discount = this.total * (coupon.amount / 100);
          } else {
            discount = coupon.amount;
          }

          if (discount > this.total) discount = this.total;
        }
      }
      return discount;
    },
    zones() {
      let data = [];
      if (this.webSettings && this.webSettings && this.webSettings.zones)
        data = this.webSettings.zones;
      return data;
    },
    webSettings() {
      return this.$store.state.webSettings || {};
    },
    getBAddress() {
      return this.baddress;
    },
    getRegions() {
      return regiones.map((r) => {
        return {
          label: r.region,
          data: r.comunas,
        };
      });
    },
    getCommunes() {
      if (this.city) return this.city.data;
      return [];
    },
    cafs() {
      let cafs = this.$store.state.cafs;
      return cafs || [];
    },
    total_payments() {
      return this.ticket.payments.reduce((t, p) => t + p.amount, 0);
    },
    ticket_products() {
      let ps = this.ticket.products.map((o) => ({ ...o }));
      return ps.reverse();
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    user() {
      return this.$store.state.user;
    },
    info() {
      return this.$store.state.info;
    },
    products() {
      return this.$store.state.products;
    },
    getVuelto() {
      return this.formatNumber(this.ticket.amount - this.total);
    },
    clientNameState() {
      return this.ticket.clientName.length > 2;
    },
    addressState() {
      return this.ticket.address.length > 2;
    },
    phoneState() {
      if (this.ticket.phone.length == 0) return true;
      return this.ticket.phone.length > 7;
    },
    filteredProducts() {
      if (this.filter == "") {
        return this.products.slice(0, 50);
      } else {
        const filter = (product) => {
          return (
            product.name.toLowerCase().indexOf(this.filter.toLowerCase()) >
              -1 ||
            product.code.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
          );
        };
        return this.products.filter(filter).slice(0, 50);
      }
    },
    total_neto() {
      return this.ticket.products.reduce((total, product) => {
        return total + product.total;
      }, 0);
    },
    subtotal() {
      var total = this.ticket.products.reduce((total, product) => {
        return total + product.total;
      }, 0);
      return Math.round(total);
    },
    total() {
      let total = this.subtotal - this.ticket.discount;
      return Math.round(total);
    },
  },
  watch: {
    printMode() {
      localStorage.setItem("printMode", this.printMode);
    },
    coupon() {
      console.log("cuponTotal", this.cuponTotal);
      this.ticket.discount = this.cuponTotal;
    },
    saveDraft() {
      localStorage.setItem("saveDraft", this.saveDraft);
      this.ticket.createdAt = Date.now();
    },
    addressType() {
      localStorage.setItem("addressType", this.addressType);
    },
    "ticket.clientName": function (val) {
      if (val && val == "ANON" && this.anon) return;
      this.$emit("updateTitle", {
        title: val,
        tabID: this.tabID,
      });
    },
    "ticket.phone": function (val) {
      if (val) this.ticket.phone = val.replace("+56", "").replace(/\D/g, "");
    },
    "ticket.hour": function () {
      // this.fakeHour = this.ticket.hour;
    },
    fakeHour() {
      // this.ticket.hour = this.$moment(this.fakeHour, "HH:mm:ss").format(
      //   "HH:mm"
      // );
    },
    current() {
      console.log(this.current, this.tabID);
      if (this.current == this.tabID) this.$refs["inputCode"].focus();
    },
    copiesTicket() {
      localStorage.copiesTicket = this.copiesTicket;
    },
    ticket: {
      deep: true,
      handler: function (val) {
        console.log("watch ticket");
        if (val && val.products && this.saveDraft) {
          let newTickets = [];
          if (localStorage.getItem("newTickets") != null)
            newTickets = JSON.parse(localStorage.getItem("newTickets"));
          newTickets[this.tabID] = val;
          localStorage.setItem("newTickets", JSON.stringify(newTickets));
          localStorage.newTicket = JSON.stringify(val);
        }
      },
    },
    "ticket.type": function (val) {
      if (val == "Domicilio") {
        //this.ticket.status = "Por pagar";
        this.centerMap();
      }
    },
    "ticket.payment": function (val) {
      if (val == "Efectivo") this.ticket.amount = this.total;
    },
    code: function (val) {
      if (val) this.code = val.split(" ").join("");
    },
    "ticket.products": {
      deep: true,
      handler: function (products, oldProducts) {
        console.log("watch ticket products");
        // check if change length of products
        console.log("newValue", products.length);
        console.log("oldValue", oldProducts.length);
        this.checkPromos();
        this.ticket.amount = this.total;

        this.updatePayment();
      },
    },
    "ticket.discount": function () {
      this.ticket.amount = this.total;
      this.updatePayment();
      // if (this.total == 0) return (this.ticket.payments = []);
      // if (this.ticket.payments.length == 0) this.setPayment();
      // else if (this.ticket.payments.length == 1) {
      //   this.ticket.payments = [];
      //   this.setPayment();
      // }
    },
  },
};
</script>

<style scoped>
.product {
  background: white;
  padding: 20px 0;
  margin: 0px 0px;
  outline: 1px solid #e1e1e1;
}
.product-name {
  font-weight: bolder;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}
.total {
  font-weight: bold;
  font-size: 22px;
}
</style>
