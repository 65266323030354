<template>
  <b-overlay :show="loading">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12">
          <h1>Reporte Productos</h1>
        </div>

        <div class="ml-auto col-3">
          <small>MES</small>
          <div class="w-100"></div>
          <date-picker
            valueType="format"
            v-model="dateFilter"
            type="month"
            input-class=" form-control text-center"
          />
        </div>

        <div class="col-2">
          <small>ACTUALIZAR</small>
          <div class="w-100"></div>
          <button class="btn btn-primary btn-block" @click="load()">
            <font-awesome-icon icon="sync" />
          </button>
        </div>

        <div class="col-12 mt-3">
          <div class="row">
            <div class="col">
              <item-icon
                title="Total productos"
                icon="box"
                :text="products.length"
              />
            </div>
            <div class="col">
              <item-icon
                title="Total productos con costo"
                icon="box"
                :text="productsWithCostValue.length"
              />
            </div>
            <div class="col">
              <item-icon
                title="Productos vendidos dentro del mes"
                icon="box"
                :text="productsTickets.length"
              />
            </div>
          </div>
        </div>

        <div class="col-6 py-4" v-if="false">
          <span class="fs-3">Productos</span>
          <ul class="list-group custom_scroll" style="max-height: 60vh">
            <li
              class="list-group-item"
              v-for="product in productsWithCostValue"
              :key="product.id"
            >
              <div class="row">
                <div class="col">
                  <small>CODIGO: {{ product.code }}</small>
                  <div class="w-100"></div>
                  <span class="font-weight-bold text-uppercase">{{
                    product.name
                  }}</span>
                </div>
                <div class="col-auto text-right">
                  <small>Costo</small>
                  <div class="w-100"></div>
                  ${{ product.cost_value | formatNumber }}
                </div>
                <div class="col-auto text-right">
                  <small>Venta</small>
                  <div class="w-100"></div>
                  ${{ product.value | formatNumber }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="col-6 py-4" v-if="false">
          <span class="fs-3">Ventas</span>
          <ul class="list-group custom_scroll" style="max-height: 60vh">
            <li
              class="list-group-item"
              v-for="product in productsTickets"
              :key="product.id"
            >
              <div class="row">
                <div class="col-">
                  <small class="mr-3">{{
                    product.date | moment("DD/MM/YYYY")
                  }}</small>
                  <small>CODIGO: {{ product.code }}</small>
                  <div class="w-100"></div>
                  <span class="font-weight-bold text-uppercase">{{
                    product.name
                  }}</span>
                </div>
                <div class="col-auto text-right">
                  <small>Costo</small>
                  <div class="w-100"></div>
                  <span v-if="product.cost_value"
                    >${{ product.cost_value | formatNumber }}</span
                  >
                  <span v-else class="text-danger">Sin costo</span>
                </div>
                <div class="col-auto text-right">
                  <small>Venta</small>
                  <div class="w-100"></div>
                  ${{ product.value | formatNumber }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="col-12" v-if="false">
          <span class="fs-3">Historial precios costos</span>
          <ul class="list-group rounded-0">
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-1">
                  <small>FECHA</small>
                </div>
                <div class="col">
                  <small>DETALLE</small>
                </div>
                <div class="col-1 text-right">
                  <small>Costo</small>
                </div>
                <div class="col-1 text-right">
                  <small>Venta</small>
                </div>
              </div>
            </li>
          </ul>
          <ul class="list-group custom_scroll" style="max-height: 60vh">
            <li
              class="list-group-item"
              v-for="product in productHistory"
              :key="product._id"
            >
              <div class="row align-items-center">
                <div class="col-1">
                  <small class="font-weight-bold text-uppercase">{{
                    product.createdAt | moment("DD/MM/YYYY")
                  }}</small>
                </div>
                <div class="col">
                  <small>CODIGO: {{ product.code }}</small>
                  <div class="w-100"></div>
                  <span class="font-weight-bold text-uppercase">{{
                    product.name
                  }}</span>
                </div>
                <div class="col-auto text-right">
                  {{ product.user }}
                </div>
                <div class="col-auto text-right">
                  ${{ product.cost_value | formatNumber }}
                </div>
                <div class="col-auto text-right">
                  ${{ product.value | formatNumber }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="col-12">
          <span class="fs-3"
            >Movimientos de stock ({{ stockHistory.length }})</span
          >
          <data-table
            :headers="[
              {
                key: 'createdAt',
                label: 'Fecha',
                type: 'date',
                col: 1,
              },
              {
                key: 'code',
                label: 'Codigo',
                col: 2,
              },
              {
                key: 'name',
                label: 'Nombre',
                col: 7,
              },
              {
                key: 'type',
                label: 'Tipo',
                col: 2,
              },
            ]"
            :content="stockHistory"
          />

          <ul class="list-group custom_scroll" style="max-height: 80vh" v-if="false">
            <li
              class="list-group-item py-1 list-group-item-action"
              v-for="product in stockHistoryList"
              :class="{
                'list-group-item-success': product.type == 'in',
                'list-group-item-warning': product.type == 'calculate',
              }"
              :key="product._id"
            >
              <div class="row align-items-center no-gutters">
                <div class="col-1">
                  <small class="font-weight-bold text-uppercase">{{
                    product.createdAt | moment("DD/MM")
                  }}</small>
                </div>

                <div class="col-2">
                  <small class="font-weight-bold">{{ product.code }}</small>
                </div>
                <div class="col">
                  <small class="font-weight-bold text-uppercase">{{
                    product.name
                  }}</small>
                </div>
                <div class="col-2 text-right">
                  <small>{{ product.user && product.user.name }}</small>
                </div>
                <div class="col-2 text-right">
                  <strong class="pr-2">{{ product.qty | formatNumber }}</strong>
                  <font-awesome-icon
                    v-if="product.type == 'in'"
                    icon="arrow-up"
                    class="text-success"
                  />

                  <font-awesome-icon
                    v-if="product.type == 'out'"
                    icon="arrow-down"
                    class="text-danger"
                  />

                  <font-awesome-icon
                    v-if="product.type == 'calculate'"
                    icon="redo"
                    class="text-warning"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ItemIcon from "../../components/itemIcon.vue";
import DataTable from "../../components/data-table.vue";

export default {
  components: {
    ItemIcon,
    DataTable,
  },
  name: "Report",
  data() {
    return {
      dateFilter: null,
      report: [],
      productHistory: [],
      loading: false,
      stockHistory: [],
    };
  },
  mounted() {
    this.dateFilter = this.$moment().format("YYYY-MM");
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      let date = this.dateFilter;
      let end = this.$moment(date).endOf("month").format("YYYY-MM-DD");
      this.$http
        .get(`ticket/product?date=${date}&end=${end}`)
        .then((response) => {
          console.log(response.data.data);
          this.report = response.data.data;
          this.loadHistory();
        })

        .catch((error) => {
          this.$noty.error("Error al cargar reporte");
        })
        .finally(() => {
          this.loading = false;
        });

      this.$http
        .get(`product/stock_history?date=${date}&end=${end}`)
        .then((response) => {
          console.log(response.data.data);
          this.stockHistory = response.data.data;
        });
    },
    loadHistory() {
      let ids = this.productsWithCostValue.map((p) => p._id);
      this.$http
        .post(`product/history`, {
          ids,
          date: this.dateFilter,
        })
        .then((response) => {
          console.log(response.data.data);
          this.productHistory = response.data.data;
        })
        .catch((error) => {
          this.$noty.error("Error al cargar historial de precios");
        });
    },
  },
  computed: {
    stockHistoryList() {
      return this.stockHistory.slice(0, 100);
    },
    productsTickets() {
      const products = this.report;
      if (!products) return [];
      return products.map((p) => {
        let tmpP = this.productsWithCostValue.find((product) => {
          return product.code == p.code;
        });

        return {
          ...p,
          cost_value: tmpP ? tmpP.cost_value : null,
        };
      });
    },
    products() {
      return this.$store.state.products;
    },
    productsWithCostValue() {
      let products = this.products.filter((product) => {
        return product.cost_value > 0;
      });

      return products;
    },
  },
  watch: {
    productsWithCostValue() {
      this.loadHistory();
    },
  },
};
</script>