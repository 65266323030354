<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h3>INGRESO MODO MULTIPLE</h3>
      </div>
      <div class="col-2 ml-auto">
        <button class="btn btn-block btn-outline-info" @click="clearList()">LIMPIAR LISTA</button>
      </div>
    </div>

    <div class="w-100 py-4"></div>
    
    <ul class="list-group">
      <li class="list-group-item" v-for="p in newProducts" :key="p._id">
        <div class="row align-items-end">
          <div class="col-2">
            <small class="text-muted">CODIGO</small>
            <input type="text" class="form-control" @blur="checkCode(p)" v-model="p.code" />
          </div>
          <div class="col-2">
            <small class="text-muted">NOMBRE</small>
            <input type="text" class="form-control" v-model="p.name" />
          </div>
          <div class="col-3">
            <small class="text-muted">DESCRIPCION</small>
            <input
              type="text"
              class="form-control"
              v-model="p.description"
              placeholder="(Opcional)"
            />
          </div>
          
          <div class="col-2">
            <small class="text-muted">CATEGORIA</small>
            <v-select
              v-model="p.category"
              :reduce="c => c._id" 
              :options="getCategories"
            ></v-select>
          </div>
          <div class="col-2">
            <small class="text-muted">PRECIO</small>
            <div class="input-group">
              <div class="input-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input
                type="text"
                class="form-control text-right"
                @focus="$event.target.select()"
                v-model.number="p.value"
              />
            </div>
          </div>
          <div class="col">
            <button class="btn btn-block btn-outline-success" @click="saveProduct(p)">
              <font-awesome-icon icon="save"></font-awesome-icon>
            </button>
            <!-- <button class="btn btn-block btn-outline-danger">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button> -->
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <button class="btn btn-block btn-outline-secondary" @click="add()">
          NUEVA LINEA
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [],
      newProducts: [
        {
          name: "",
          description: "",
          value: 0,
          category: null
        },
      ],
    };
  },
  mounted() {
    this.loadCategories()
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
     getCategories() {
      if (!this.categories) return [];
      return this.categories.map((o) => ({
        ...o,
        label: `${o.name}`,
      }));
    },
  },
  methods: {
    checkCode(p) {
      if (p.code == "") return false;
        this.$http
          .get(`product/check/${p.code}`)
          .then(() => {})
          .catch(() => {
            this.$swal({
              icon: "error",
              text: `El codigo ingresado ya existe en el sistema`,
              title: "Error!",
            });
            p.code = "";
          });
    },
    saveProduct(p) {
      this.$http
        .post("product", p)
        .then(() => {
          this.$noty.success("Producto agregado correctamente");
          // todo edit product
        })
        .catch(() => {
          this.$noty.error("Error al guardar los datos");
        });
    },
    loadCategories() {
      this.$http.get("category").then((res) => {
        this.categories = res.data.data;
      });
    },
    add() {
      this.newProducts.push({
        name: "",
        description: "",
        total: 0,
        category: null
      });
    },
  },
};
</script>