<template>
  <div>
    <!-- Modal Component -->
    <b-modal
      ref="modal-options-cog"
      size="md"
      scrollable
      title="Configurar"
      hide-footer
    >
      <div class="row">
        <div class="col">
          <strong>Enlazar opciones</strong>
          <select class="form-control" v-model="subOption">
            <option :value="null">Ninguno</option>
            <option :value="op._id" :key="op._id" v-for="op in options">
              {{ op.name }} - {{ op.label }}
            </option>
          </select>
          <div class="w-100 py-2"></div>
          <button class="btn btn-success btn-block" @click="addSubOption()">
            GUARDAR
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-options"
      size="xxl"
      ref="manage_options"
      scrollable
      title="Administrar opciones de productos"
      hide-footer
    >
      <b-overlay :show="loading">
        <b-tabs pills justified>
          <b-tab title="GRUPOS">
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="row no-gutters align-items-end mb-2">
                  <div class="col">
                    <small class="text-uppercase">Buscar</small>
                    <input
                      type="text"
                      class="form-control"
                      id="tooltip-input-search"
                      v-model="fakeFilter"
                      ref="input-search"
                      placeholder="Ej: Color, Sabor, Extra Queso, etc."
                      @keyup.enter="filter = fakeFilter"
                    />
                    <b-tooltip ref="tooltip1" show target="tooltip-input-search"
                      >Busca por nombre y presiona enter</b-tooltip
                    >
                  </div>
                </div>
                <ul
                  class="list-group custom_scroll"
                  style="
                    max-height: 60vh;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  "
                >
                  <li class="list-group-item" v-if="options.length == 0">
                    <h5 class="text-center">No hay opciones</h5>
                  </li>
                  <li
                    class="list-group-item list-group-item-action click"
                    :class="{
                      active: option._id == op._id,
                    }"
                    v-for="op in filteredOptions"
                    :key="op._id"
                    @click="loadOption(op._id)"
                    :ref="`value-${op._id}`"
                  >
                    <div class="row align-items-end">
                      <div
                        class="col-12"
                        style="
                          position: absolute;
                          top: 10px;
                          width: 100%;
                          text-align: right;
                        "
                      >
                        <span
                          class="badge badge-success text-uppercase"
                          style="font-size: 0.9em !important"
                          >{{ getType(op.type) }}</span
                        >
                      </div>
                      <div class="col">
                        <small class="d-block">NOMBRE GRUPO</small>
                        <span class="text-uppercase font-weight-bold">
                          {{ op.name }}
                        </span>
                      </div>
                      <div class="w-100"></div>
                      <div class="col">
                        <small class="d-block">NOMBRE INTERNO</small>
                        <span class="text-uppercase font-weight-bold">
                          {{ op.label || "- - - -" }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="row no-gutters align-items-end mb-2">
                  <div class="col">
                    <small class="text-uppercase"
                      >INGRESE NOMBRE PARA CREAR</small
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="optionGroupName"
                      id="tooltip-input-add"
                      placeholder="Ej: Color, Sabor, Extra Queso, etc."
                      :class="{
                        'is-invalid': optionGroupName.length < 3,
                        'is-valid': optionGroupName.length >= 3,
                      }"
                      @keyup.enter="addOptionGroup()"
                    />
                    <b-tooltip show target="tooltip-input-add"
                      >Ingrese por nombre y presiona enter
                    </b-tooltip>
                  </div>
                  <div class="col-4">
                    <button
                      class="btn btn-outline-success btn-block"
                      @click="addOptionGroup()"
                      :disabled="optionGroupName.length < 3"
                    >
                      CREAR
                    </button>
                  </div>
                </div>
              </div>
              <div class="col mt-1 pl-2">
                <ul class="list-group" v-if="option._id">
                  <li class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <small>NOMBRE GRUPO</small>
                        <b-form-input v-model="option.name"></b-form-input>
                      </div>
                      <div class="col-6">
                        <small>VER COMO (NOMBRE INTERNO)</small>
                        <b-form-input v-model="option.label"></b-form-input>
                      </div>
                      <div class="col-6">
                        <small>TIPO</small>
                        <select class="form-control" v-model="option.type">
                          <option value="select">Lista de opciones</option>
                          <option value="number">Numero</option>
                          <option value="text">Texto</option>
                        </select>
                      </div>
                      <div class="w-100"></div>
                      <div
                        class="col-6 mb-2 mt-2"
                        v-if="option.type == 'select'"
                      >
                        <b-form-checkbox
                          size="md"
                          v-model="option.multiple"
                          switch
                        >
                          <strong>Multiple</strong>
                          <small class="ml-2 text-muted"
                            >Seleccionar mas de de uno</small
                          >
                        </b-form-checkbox>
                      </div>
                      <div
                        class="col-6 mb-2 mt-2"
                      >
                        <b-form-checkbox
                          size="md"
                          v-model="option.printStrong"
                          switch
                        >
                          <strong>Imprimir en negrita</strong>
                        </b-form-checkbox>
                      </div>
                      <div class="w-100 mt-2"></div>
                      <div
                        class="col-6"
                        v-if="option.type == 'number' || option.multiple"
                      >
                        <small class="d-block">MINIMO</small>
                        <div class="input-group d-none">
                          <button
                            class="btn btn-outline-danger"
                            @click="option.min--"
                          >
                            -
                          </button>
                          <b-form-input
                            class="text-center"
                            v-model="option.min"
                            min="0"
                          ></b-form-input>
                          <button
                            class="btn btn-outline-success"
                            @click="option.min++"
                          >
                            +
                          </button>
                        </div>
                        <b-form-spinbutton
                          v-model.number="option.min"
                          min="0"
                          max="100"
                        ></b-form-spinbutton>
                      </div>
                      <div
                        class="col-6"
                        v-if="option.type == 'number' || option.multiple"
                      >
                        <small class="d-block">MAXIMO</small>
                        <b-form-spinbutton
                          v-model.number="option.max"
                          min="1"
                          max="100"
                        ></b-form-spinbutton>

                        <div class="input-group d-none">
                          <button
                            class="btn btn-outline-danger"
                            @click="option.max--"
                          >
                            -
                          </button>
                          <b-form-input
                            class="text-center"
                            v-model="option.max"
                            :min="option.min + 1"
                          ></b-form-input>
                          <button
                            class="btn btn-outline-success"
                            @click="option.max++"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    v-if="option._id && option.type == 'select'"
                  >
                    <div class="row align-items-end">
                      <div class="col-8 d-none">
                        <div class="alert alert-info p-1">
                          <strong>IMPORTANTE: </strong>
                          <span
                            >Al cambiar una opcion esta se actualizara en todas
                            los grupos esten asociados</span
                          >
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row align-items-end">
                          <div class="col">
                            <small class="text-uppercase"
                              >NOMBRE (BUSQUE O INGRESE Y PRESIONE ENTER, LUEGO
                              AGREGAR)</small
                            >

                            <v-select
                              :options="getListValues"
                              v-model="optionName"
                              taggable
                              ref="optionInput"
                              append-to-body
                              @input="changeOption()"
                              style="z-index: 1"
                            >
                              <template
                                v-slot:no-options="{ search, searching }"
                              >
                                <template v-if="searching">
                                  No se encontro <em>{{ search }} </em>.
                                </template>
                                <em style="opacity: 0.5" v-else>
                                  Buscar o crea uno
                                </em>
                              </template>
                            </v-select>
                          </div>

                          <div class="col-3 d-none">
                            <button
                              class="btn btn-success btn-block"
                              @click="addOption()"
                            >
                              AGREGAR
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12"
                        style="max-height: 35vh; overflow: scroll"
                      >
                        <div
                          class="row border-bottom py-2 align-items-center list-group-item-action"
                          v-for="{ _id: op } in getOptionsReverse(
                            option.options
                          )"
                          :key="op._id"
                        >
                          <div class="col-6 col-md-12">
                            <h5 class="font-weight-bold">
                              {{ op.name }}
                              <small v-if="op.customName">
                                - {{ op.customName }}</small
                              >
                            </h5>

                            <div class="w-100"></div>
                            <small class="text-muted">{{
                              op.optionGroup ? "Enlazado" : ""
                            }}</small>
                          </div>
                          <div class="col-6 col-md">
                            <small class="d-block">HABILITAR</small>
                            <b-form-checkbox
                              size="lg"
                              v-model="op.enabled"
                              switch
                              @change="updateOption(op)"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="col-6 col-md-4">
                            <small class="d-block">VALOR EXTRA</small>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                type="text"
                                v-model.number="op.value"
                                @focus="$event.target.select()"
                                @blur="updateOption(op)"
                                class="form-control text-right"
                              />
                            </div>
                          </div>
                          <div class="ml-auto col d-none1">
                            <small>OPCIONES</small>
                            <div class="w-100"></div>
                            <div class="btn-group btn-block">
                              <button
                                class="btn btn-outline-secondary btn-sm"
                                @click="showSettings(op._id, op)"
                              >
                                <font-awesome-icon
                                  icon="cog"
                                ></font-awesome-icon>
                              </button>
                              <button
                                class="btn btn-outline-danger btn-sm"
                                @click="removeOption(op._id)"
                                v-if="user.role == 'admin'"
                              >
                                <font-awesome-icon
                                  icon="trash"
                                ></font-awesome-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row no-gutters">
                      <div class="col-5">
                        <button
                          class="btn btn-outline-danger btn-block h-100"
                          @click="removeOptionGroup()"
                        >
                          ELIMINAR
                        </button>
                      </div>
                      <div class="col">
                        <button
                          class="btn btn-success btn-block click h-100"
                          @click="saveOptionGroup()"
                        >
                          GUARDAR
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
          <b-tab title="VALORES">
            <div class="row">
              <div class="col-12 py-3">
                <small class="text-muted">PRESIONA ENTER PARA BUSCAR</small>
                <input
                  type="text"
                  placeholder="Buscar y presiona enter"
                  class="form-control"
                  v-model="fakeFilterValues"
                  @keyup.enter="filterValues()"
                />
              </div>
            </div>
            <div
              class="row custom-scroll"
              style="max-height: 60vh; overflow-y: scroll"
            >
              <div class="col-lg-7">
                <ul class="list-group">
                  <li
                    class="list-group-item click list-group-item-action"
                    :class="{
                      'list-group-item-warning': !val.enabled,
                      active: val._id == value._id,
                    }"
                    :key="val._id"
                    @click="loadvalue(val)"
                    v-for="val in filteredValues"
                  >
                    <div class="row">
                      <div class="col">
                        <small>NOMBRE</small>
                        <div class="w-100"></div>
                        <strong>{{ val.name }}</strong>
                      </div>
                      <div class="col">
                        <small>NOMBRE INTERNO</small>
                        <div class="w-100"></div>
                        <strong>{{ val.customName || "------" }}</strong>
                      </div>
                      <div class="col-2">
                        <small>CREADO</small>
                        <div class="w-100"></div>
                        {{ val.createdAt | moment("DD/MM/YY") }}
                      </div>
                      <div class="col-2 text-right">
                        <small>VALOR</small>
                        <div class="w-100"></div>
                        ${{ val.value | formatNumber }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-lg">
                <div class="" v-if="!value._id">
                  <span
                    class="d-block align-self-center text-center"
                    style="font-size: 1.3em"
                    >Selecciona una opcion</span
                  >
                </div>
                <div
                  class="row sticky-top align-items-end"
                  style="z-index: 1"
                  v-else
                >
                  <div class="col-6">
                    <small>NOMBRE</small>
                    <div class="w-100"></div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="value.name"
                    />
                  </div>
                  <div class="col-6">
                    <small>NOMBRE INTERNO</small>
                    <div class="w-100"></div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="value.customName"
                    />
                  </div>
                  <div class="col-6">
                    <small>VALOR</small>
                    <div class="w-100"></div>
                    <input
                      type="text"
                      class="form-control"
                      @focus="$event.target.select()"
                      v-model.number="value.value"
                    />
                  </div>
                  <div class="col-6">
                    <div class="w-100"></div>
                    <b-checkbox
                      switch
                      size="lg"
                      class=""
                      v-model="value.enabled"
                      ><small>HABILITAR</small></b-checkbox
                    >
                  </div>
                  <div class="col-6">
                    <div class="w-100"></div>
                    <b-checkbox
                      switch
                      size="lg"
                      class=""
                      v-model="value.printStrong"
                      ><small>IMPRIMIR EN NEGRITA</small></b-checkbox
                    >
                  </div>
                  <div class="col-12">
                    <small>USADO EN</small>
                    <div class="w-100"></div>
                    <select disabled multiple class="form-control">
                      <option
                        v-for="op in value.optionGroups"
                        :key="`value-${op._id}`"
                      >
                        {{ op.name }} - {{ op.label }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12">
                    <div class="alert alert-info">
                      <small
                        >AL ACTUALIZAR, TAMBIEN CAMBIARÁ EN LOS LISTADOS QUE SE
                        USE</small
                      >
                    </div>
                  </div>
                  <div class="w-100 py-2"></div>
                  <div class="col-5" v-if="value.optionGroups == 0">
                    <button
                      class="btn btn-danger btn-block btn-lg"
                      @click="deleteValue()"
                    >
                      ELIMINAR
                    </button>
                  </div>
                  <div class="col">
                    <button
                      class="btn btn-success btn-block btn-lg"
                      @click="updateValue()"
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subOption: null,
      option: "",
      optionGroupName: "",
      optionName: {
        name: "",
        _id: "",
      },
      values: [],
      loading: true,
      fakeFilter: "",
      filter: "",
      value: {},
      fakeFilterValues: "",
      filterValue: "",
    };
  },
  mounted() {
    this.loadOptions();
    this.loadOptionValues();
  },
  watch: {
    fakeFilter() {
      if (this.fakeFilter == "") this.filter = "";
    },
    fakeFilterValues() {
      if (this.fakeFilterValues == "") this.filterValue = "";
    },
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    filteredValues() {
      let filter = this.filterValue.toLowerCase();
      if (filter == "") return this.values;
      return this.values.filter((v) => {
        if (v.name.toLowerCase().indexOf(filter) != -1) return true;
      });
    },
    getListValues() {
      return this.values.map((v) => {
        let label = `${v.name} $${v.value}`;
        if (v.customName) label += ` - ${v.customName}`;
        return {
          ...v,
          label,
        };
      });
    },
    filteredOptions() {
      let filter = this.filter.toLowerCase();
      if (!this.filter) return this.options;

      return this.options.filter((op) => {
        return (
          op.name.toLowerCase().indexOf(filter) >= 0 ||
          op.label.toLowerCase().indexOf(filter) >= 0
        );
      });
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    filterValues() {
      this.filterValue = this.fakeFilterValues;
    },
    deleteValue() {
      this.loading = true;
      this.$http
        .delete(`option/value/${this.value._id}`)
        .then(() => {
          this.value = {};
          this.$noty.success("Eliminado");
        })
        .finally(() => {
          this.loading = false;
          this.loadOptionValues();
        });
    },
    updateValue() {
      let value = this.value;
      console.log({ value });
      this.$http
        .post(`option/value/${value._id}`, value)
        .then(() => {
          this.loadOptionValues();
          this.$noty.success("Actualizado");
        })
        .catch(() => {
          this.$noty.error("Error al actualizar");
        });
    },
    loadvalue(val) {
      this.loading = true;
      this.$http
        .get(`option/value/${val._id}`)
        .then((res) => {
          this.value = res.data.data;
          console.log("id", this.value._id);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addOption() {
      // if (this.option.label.length == 0 || this.option.values.length == 0)
      //   return false;
      // this.product.options.push({
      //   label: this.option.label,
      //   values: this.option.values,
      // });
      // this.option = {
      //   label: "",
      //   values: "",
      // };

      if (this.optionName == null || this.optionName == {}) {
        // this.optionName = "";
        return false;
      }

      console.log(this.optionName);
      let data = {
        name: "",
        gid: this.option._id,
      };

      if (this.optionName._id) {
        data._id = this.optionName._id;
        data.name = this.optionName.name;
      } else if (this.optionName.label) {
        data.name = this.optionName.label;
      } else {
        data.name = this.optionName;
      }

      this.$http.post("option/value", data).then(() => {
        this.optionName = "";
        this.loadOption(this.option._id);
        this.loadOptions();
        this.loadOptionValues();
      });
    },
    addOptionGroup() {
      let body = {
        name: this.optionGroupName,
        type: "select",
        min: 0,
        max: 0,
        multiple: false,
      };
      this.optionGroupName = "";
      this.$http.post(`option`, body).then((res) => {
        this.loadOptions(() => {
          this.loadOption(res.data.data._id);
        });
      });
    },
    getType(type) {
      let rs = "Lista";
      if (type == "number") rs = "Numero";
      if (type == "text") rs = "Texo";
      return rs;
    },
    showModal() {
      this.$refs["manage_options"].show();
      setTimeout(() => {
        this.$refs["input-search"].focus();
      }, 1000);
    },
    removeOptionGroup() {
      this.loading = true;
      // confirm
      this.$swal({
        title: "Estas seguro?",
        text: "Debea borrar el grupo de opciones? No se puede deshacer!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, agregar",
        cancelButtonText: "Cancelar",
        buttons: true,
      }).then((result) => {
        if (!result)
          this.$http
            .delete(`option/${this.option._id}`)
            .then(() => {
              this.$noty.success("Eliminado");
              this.option = {};
              this.loadOptions();
            })
            .catch(() => {
              this.$noty.error("Error al eliminar");
            })
            .finally(() => (this.loading = false));
      });
    },
    getOptionsReverse(ops) {
      // return ops.reverse()
      return ops.slice().reverse();
    },
    addSubOption() {
      this.loading = true;
      this.$http
        .post(`option/${this.subOptionId}/sub`, { id: this.subOption })
        .then(() => {
          this.$refs["modal-options-cog"].hide();
          this.subOptionId = null;
          this.loadOptions();
          this.loadOptionValues();
          this.loadOption(this.option._id);
        })
        .finally(() => (this.loading = false));
    },
    showSettings(id, option) {
      this.subOption = option.optionGroup ? option.optionGroup._id : null;
      this.subOptionId = id;
      this.$refs["modal-options-cog"].show();
    },
    changeOption() {
      this.addOption();
      setTimeout(() => {
        this.$refs.optionInput.searchEl.focus();
      }, 500);
      // this.$emit("search:focus");
    },
    updateOption(op) {
      this.loading = true;
      this.$http
        .patch(`option/value/${op._id}`, {
          enabled: op.enabled,
          value: op.value,
        })
        .then(() => {
          this.loadOption(this.option._id);
        })
        .catch(() => this.$noty.error("Error al actualizar intente nuevamente"))
        .finally(() => (this.loading = false));
    },
    removeOption(opid) {
      this.loading = true;
      this.$http
        .delete(`option/${this.option._id}/${opid}`)
        .then(() => {
          this.loadOption(this.option._id);
        })
        .catch(() => this.$noty.error("Error al borrar intente nuevamente"))
        .finally(() => (this.loading = false));
    },
    loadOptionValues() {
      this.loading = true;
      this.$http
        .get(`option/value`)
        .then((res) => {
          this.values = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    saveOptionGroup() {
      this.loading = true;
      let data = {
        name: this.option.name,
        label: this.option.label,
        type: this.option.type,
        min: this.option.min,
        max: this.option.max,
        multiple: this.option.multiple,
        printStrong: this.option.printStrong,
      };
      this.$http
        .patch(`option/${this.option._id}`, data)
        .then(() => {
          this.$noty.success("Guardado");
          this.loadOptions();
          setTimeout(() => this.scrollToOption(this.option._id), 1500);
        })
        .finally(() => (this.loading = false));
    },
    scrollToOption(id) {
      const [el] = this.$refs[`value-${id}`];
      if (el) el.scrollIntoView({ behavior: "smooth" });
    },
    loadOption(id) {
      this.loading = true;
      this.$http
        .get(`option/${id}`)
        .then((res) => {
          this.option = res.data.data;
          this.scrollToOption(id);
        })
        .finally(() => (this.loading = false));
    },
    loadOptions(cb) {
      this.loading = true;
      this.$http
        .get("option")
        .then((res) => {
          this.$store.commit("setOptions", res.data.data);
          if (cb) cb();
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>