<script>
export default {
  data() {
    return {
      closedUntilTime: null,
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.$refs.modal.show();
    },
    saveCustomMessage() {
      let data = this.customMessageList;
      let { customMessage } = this.webSettings;
      let exits = data.find((d) => d == customMessage);
      data = data.filter((d) => d != customMessage);
      if (!exits) {
        data.unshift(customMessage);
        localStorage.setItem("customMessageList", JSON.stringify(data));
      }
    },
    getShortMessage(msg) {
      if (msg.length > 30) {
        return msg.substring(0, 30) + "...";
      }
      return msg;
    },
    closeTomorrow() {
      let nextDay = this.$moment().add(1, "day").startOf("day");
      this.closedUntilTime = this.$moment(nextDay).diff(
        Date.now(),
        "minutes",
        true
      );
    },
    saveWebSetting() {
      let data = {
        ...this.webSettings,
        closedUntilTime: this.closedUntilTime,
      };
      this.saveCustomMessage();
      this.$http
        .post("web/status", data)
        .then((res) => {
          this.$store.commit("setWebSettings", res.data.data);
          this.$noty.success("Configuración guardada", {
            type: "success",
            position: "top-center",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$noty.error("Error al guardar configuración", {
            type: "error",
            position: "top-center",
            duration: 3000,
          });
        });
    },
  },
  computed: {
    closedUntil() {
      let webSettings = this.webSettings;
      if (webSettings.closedUntil) {
        let closedUntil = this.$moment(webSettings.closedUntil).diff(
          Date.now(),
          "minutes",
          true
        );
        if (closedUntil > 0) return true;
        return false;
      }
      return false;
    },
    customMessageList() {
      let data = localStorage.getItem("customMessageList");
      if (data && data != null) {
        let parsed = JSON.parse(data);
        parsed = parsed.filter((d) => d != null && d != "");
        console.log("message", parsed);
        return parsed;
      }
      return [];
    },
    webSettings() {
      return this.$store.state.webSettings || {};
    },
    getWebStatus() {
      let settings = this.webSettings;
      let status = settings.status;
      if (settings.closedUntil && settings.closedUntil > 0) {
        let diff = this.$moment(settings.closedUntil).diff(
          Date.now(),
          "minutes"
        );
        if (diff > 0) return "Offline";
      }
      switch (status) {
        case "always":
          return "Online";
        case "schedule":
          return "Horario";
        case "app":
          return "Solo App";
        default:
          return "Offline";
      }
    },
  },
};
</script>

<template>
  <div class="dropdown" role="group">
    <button class="btn btn-link text-white btn-sm" @click="showModal()">
      <span class="ml-2 d-inline-block text-nowrap text-uppercase">
        WEB:
        <span
          class="font-weight-bold"
          :class="{
            'text-white bg-danger px-2 rounded': getWebStatus == 'Offline',
            'text-white bg-success px-2 rounded': getWebStatus != 'Offline',
          }"
          >{{ getWebStatus }}</span
        >
        <span class="mx-2"></span>
        R:
        <font-awesome-icon
          class="rounded-circle bg-white"
          :icon="webSettings.pickupEnabled ? 'check-circle' : 'times-circle'"
          :class="{
            'text-success bg-white': webSettings.pickupEnabled,
            'text-danger': !webSettings.pickupEnabled,
          }"
        />
        D:
        <font-awesome-icon
          class="rounded-circle bg-white"
          :icon="webSettings.deliveryEnabled ? 'check-circle' : 'times-circle'"
          :class="{
            'text-success ': webSettings.deliveryEnabled,
            'text-danger ': !webSettings.deliveryEnabled,
          }"
        />
      </span>
    </button>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdownMenuButton"
      style="width: 400px; max-height: 450px; overflow-y: scroll"
    >
      <div class="dropdown-item"></div>
    </div>

    <b-modal
      ref="modal"
      size="md"
      title="Configuracion Web Rapida"
      @ok="saveWebSetting()"
    >
      <div class="row">
        <div class="col">
          <b-overlay :show="loading">
            <!-- <div class="dropdown-item border-bottom">
            <div class="row">
              <div class="col text-center">
                <router-link to="/web/config" v-if="user.role == 'admin'"
                  >Ir a la configuracion</router-link
                >
              </div>
            </div>
          </div> -->

            <div
              class="dropdown-item border-bottom pt-2"
              @click="$event.stopPropagation()"
            >
              <div class="row">
                <div class="col-12">
                  <small>ESTADO WEB</small>
                </div>
                <div class="col">
                  <div class="btn-group btn-block">
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: getWebStatus == 'Offline',
                      }"
                      @click="webSettings.status = 'offline'"
                    >
                      Offline
                    </button>
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: webSettings.status == 'always',
                      }"
                      @click="webSettings.status = 'always'"
                    >
                      Siempre
                    </button>
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: webSettings.status == 'app',
                      }"
                      @click="webSettings.status = 'app'"
                    >
                      APP
                    </button>
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      :class="{
                        active: webSettings.status == 'schedule',
                      }"
                      @click="webSettings.status = 'schedule'"
                    >
                      Horario
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="dropdown-item" @click="$event.stopPropagation()">
              <div class="row align-items-center">
                <div class="col-12">
                  <small>CERRAR TEMPORALMENTE</small>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <b-form-spinbutton
                        size="sm"
                        v-model="closedUntilTime"
                        min="0"
                        step="5"
                        max="18000"
                      ></b-form-spinbutton>
                      <div class="btn-group">
                        <button
                          class="btn btn-sm btn-link"
                          @click="closedUntilTime = 60"
                        >
                          60"
                        </button>
                        <button
                          class="btn btn-sm btn-link"
                          @click="closedUntilTime = 120"
                        >
                          120"
                        </button>
                        <button
                          class="btn btn-sm btn-link"
                          @click="closedUntilTime = 180"
                        >
                          180"
                        </button>
                        <button
                          class="btn btn-sm btn-link"
                          @click="closedUntilTime = 240"
                        >
                          240"
                        </button>
                        <button
                          class="btn btn-sm btn-link"
                          @click="closeTomorrow()"
                        >
                          SIG. DIA
                        </button>
                        <button
                          class="btn btn-sm btn-link text-danger"
                          @click="closedUntilTime = 0"
                        >
                          <font-awesome-icon icon="trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-item border-bottom">
              <div class="col-5 text-center">
                <small>CERRADO HASTA: </small>
                <small class="text-uppercase" v-if="closedUntil">
                  {{ webSettings.closedUntil | moment("dddd DD/MM HH:mm") }}
                </small>
                <small v-else>DESACTIVADO</small>
              </div>
            </div>

            <div class="dropdown-item" @click="$event.stopPropagation()">
              <div class="row">
                <div class="col-12">
                  <small class="font-weight-bold">Mensaje de la pagina</small>
                </div>
                <div class="col">
                  <div class="input-group">
                    <b-dropdown
                      variant="outline-secondary"
                      toggle-class="btn-sm"
                      class="input-group-append"
                      @click="$event.stopPropagation()"
                    >
                      <b-dropdown-item
                        v-for="(msg, i) in customMessageList"
                        :key="`msg-${i}`"
                        @click="webSettings.customMessage = msg"
                      >
                        {{ getShortMessage(msg) }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <input
                      type="text"
                      v-model="webSettings.customMessage"
                      class="form-control"
                    />
                    <button
                      class="btn btn-sm btn-link text-danger m-0 p-0 px-2"
                      @click="webSettings.customMessage = ``"
                    >
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="dropdown-item border-bottom pt-2"
              @click="$event.stopPropagation()"
            >
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <b-form-checkbox
                        v-model="webSettings.pickupEnabled"
                        switch
                        size="lg"
                      />
                    </div>
                    <div class="col">Retiros</div>
                    <div class="col">
                      <b-form-spinbutton
                        size="md"
                        v-model="webSettings.pickupWaitTime"
                        min="0"
                        class="mt-2"
                        step="5"
                        max="120"
                      ></b-form-spinbutton>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <b-form-checkbox
                        size="lg"
                        v-model="webSettings.deliveryEnabled"
                        switch
                      />
                    </div>
                    <div class="col">Repartos</div>
                    <div class="col-12">
                      <b-form-spinbutton
                        size="md"
                        class="mt-2"
                        v-model="webSettings.deliveryWaitTime"
                        min="0"
                        step="5"
                        max="120"
                      ></b-form-spinbutton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </b-modal>
  </div>
</template>