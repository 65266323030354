<template>
  <div>
    <!-- <div class="container-fluid px-2">
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div> -->
    <router-view @showTicket="showTicket" @printTicket="print" />
  </div>
</template>

<script>
export default {
  methods: {
    showTicket() {
      this.$emit('showTicket')
    },
    print(args) {
      this.$emit('printTicket', args)
    },
  },
  computed: {
    items() {
      const items = this.$route.path.split("/");
      return items.map((item, i) => {
        let active = false;
        if (i == (items.length - 1)) active = true;
        return { text: item, to: `/${item}`, active };
      });
    },
  },
};
</script>