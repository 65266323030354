var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown",attrs:{"role":"group"}},[_c('button',{staticClass:"btn btn-link btn-sm",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('font-awesome-icon',{staticClass:"text-light",attrs:{"icon":"bell","size":"1x"}}),(_vm.notificationsUnread != 0)?_c('div',{staticClass:"badge badge-danger"},[_vm._v("\n      "+_vm._s(_vm.notificationsUnread)+"\n    ")]):_vm._e()],1),_c('div',{staticClass:"dropdown-menu dropdown-menu-right",staticStyle:{"width":"400px","max-height":"450px","overflow-y":"scroll"},attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('div',{staticClass:"dropdown-item border-bottom",attrs:{"click":"$event.stopPropagation()"}},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-sm",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.load()}}},[_c('font-awesome-icon',{attrs:{"icon":"redo"}})],1)])])]),(_vm.notifications.length == 0)?_c('div',{staticClass:"dropdown-item"},[_vm._m(1)]):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.loading}},_vm._l((_vm.notifications),function(n){return _c('div',{key:n._id,staticClass:"dropdown-item border-bottom pt-2",on:{"click":function($event){return _vm.makeRead(n)}}},[_c('div',{staticClass:"row align-items-center position-relative"},[_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0px"}},[_c('button',{staticClass:"btn btn-sm btn-link d-none1",attrs:{"disabled":_vm.isRead(n.users)},on:{"click":function($event){return _vm.makeRead(n._id)}}},[_c('font-awesome-icon',{class:{
                  'text-muted': _vm.isRead(n.users),
                  'text-dark': !_vm.isRead(n.users),
                },attrs:{"icon":"check-circle"}})],1)]),_c('div',{staticClass:"col-11"},[_c('h6',{staticClass:"mb-0"},[_c('font-awesome-icon',{staticClass:"mr-1",class:{
                  'text-danger ': n.priority == 3,
                  'text-primary': n.priority == 2,
                  'text-primary ': n.priority == 1,
                  'text-secondary ': n.priority == 0,
                },attrs:{"icon":_vm.isRead(n.users) ? 'dot-circle' : 'circle',"size":"1x"}}),_vm._v("\n              "+_vm._s(n.title)+"\n            ")],1),_c('small',{staticClass:"text-muted"},[_vm._v("\n              "+_vm._s(_vm.$moment(n.createdAt).fromNow())+"\n            ")]),_c('div',{staticClass:"w-100"}),_c('small',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(n.content))])]),_c('div',{staticClass:"ml-auto float-right"},[(false)?_c('button',{staticClass:"btn btn-sm btn-link"},[_vm._v("Eliminar")]):_vm._e(),_vm._l((n.buttons),function(ac,i){return _c('button',{key:i,staticClass:"btn btn-sm btn-link",on:{"click":function($event){return _vm.doAction(ac.action)}}},[_vm._v("\n              "+_vm._s(ac.title)+"\n            ")])})],2)])])}),0)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('span',{staticClass:"d-block text-center text-uppercase"},[_vm._v("\n            Notificaciones\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('small',{staticClass:"text-muted d-none"},[_vm._v(" Hace 2 minutos ")]),_c('span',{staticClass:"d-block text-center"},[_vm._v(" No tienes notificaciones ")])])])}]

export { render, staticRenderFns }