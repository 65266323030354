<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-4">
        <small>MODULO</small>
        <select class="form-control" v-model="name">
          <option value="">TODOS</option>
          <option v-for="name in logsNames" :key="name">{{name}}</option>
        </select>
      </div>
      <div class="col-4">
        <small>LIMITE</small>
        <select class="form-control" v-model="limit">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="500">500</option>
        </select>
      </div>
      <div class="col">
        <small>ORDEN</small>
        <select class="form-control" v-model="order">
          <option :value="1">ASC</option>
          <option :value="-1">DESC</option>
        </select>
      </div>
      <div class="ml-auto col-2 align-content-end">
        <b-button @click="load" block variant="outline-primary"
          >Actualizar</b-button
        >
      </div>
      <div class="col-12 mt-2 ">
        <ul class="list-group-item mt-2 overflow-auto custom_scroll"  style="max-height: 50vh">
          <li
            class="list-group-item py-1 px-2"
            v-for="log in logs"
            :key="log._id"
            :class="`list-group-item-${getClass(log.type)}`"
          >
            <div class="row align-items-center no-gutters">
              <div class="col-auto text-center px-1">
                <small class="font-weight-bold">
                  {{ log.createdAt | moment("DD/MM/YY") }}
                </small>
                <small class="d-block">
                  {{ log.createdAt | moment("HH:mm") }}
                </small>
              </div>
              <div class="col-4 px-2">
                <small class="text-uppercase mr-2 font-weight-bold" >{{ log.name }}</small>
                <br>
                 <small class="d-inline-block mr" v-if="log.user">
                  {{ log.user.name }} -
                </small>
                <small> {{ log.business ? log.business.name : "SYSTEM" }}</small>
              </div>
              <div class="col">
                <small class="font-italic">{{ log.details || "----" }}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order: -1,
      logs: [],
      logsNames: [],
      name: "",
      limit: 20
    };
  },
  mounted() {
    this.load();
    this.loadNames();
  },
  methods: {
    getClass(type) {
      if (type == "error") return "danger";
      return type;
    },
    load() {
      this.$http.get(`admin/logs?name=${this.name}&limit=${this.limit}&order=${this.order}`).then((res) => {
        this.logs = res.data.data;
      });
    },
    loadNames() {
      this.$http.get(`admin/logs/names?name=${this.name}`).then((res) => {
        this.logsNames = res.data.data;
      });
    },
  },
  watch: {
    name() {
      this.load();
    },
  },
};
</script>