<script>
import adminBadge from "./admin-badge.vue";
export default {
  props: {
    printQueueEnabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    adminBadge,
  },
  data() {
    return {
      printUpdatedAt: null,
    };
  },
  methods: {
    clickNav() {
      document
        .querySelector("#navbarSupportedContent")
        .classList.remove("show");
    },
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    webTicketsPending() {
      return this.webTickets.reduce((t, w) => {
        if (w.status == "Pendiente") return t + 1;
        return t;
      }, 0);
    },
    webTickets() {
      return this.$store.state.webTickets;
    },
    printQueue() {
      return this.$store.state.printQueue;
    },
    logo() {
      return this.$store.state.info.logo;
    },
  },
};
</script>


<template>
  <div class="container-fluid container-app">
    <router-link
      class="navbar-brand d-block d-sm-none d-md-none d-xl-block animated bounce delay-1s"
      to="/"
    >
      <img
        :src="`${getLogo(logo)}`"
        class="img-fluid"
        v-if="info.logo"
        style="width: auto; max-width: 100px; max-height: 60px"
        alt="logo"
      />
      <img
        src="https://ticketapp.cl/logo.png"
        class="img-fluid"
        v-else
        style="width: auto; max-width: 100px; max-height: 40px"
      />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse px-3"
      id="navbarSupportedContent"
      ref="menu"
    >
      <div class="row text-uppercase" @click="clickNav()">
        <div
          class="col-auto px-1 text-center"
          id="nav-pdv"
          v-if="hasPermission(user, 'pdv')"
        >
          <router-link class="nav-link" to="/new">
            <font-awesome-icon
              class=""
              icon="cash-register"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-block">PDV</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-ticket"
          v-if="hasPermission(user, 'ticket_list')"
        >
          <router-link class="nav-link" to="/tickets">
            <font-awesome-icon
              class=""
              icon="file-alt"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-block">ventas</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-restaurant"
          v-if="info.restaurantEnabled && hasPermission(user, 'tables')"
        >
          <router-link class="nav-link" to="/restaurant">
            <font-awesome-icon
              class=""
              icon="sitemap"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-block">mesas</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-expenses"
          v-if="$store.state.user.role == 'admin'"
        >
          <router-link class="nav-link" to="/expenses">
            <font-awesome-icon
              class=""
              icon="money-bill-wave"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-inline-block">Gastos</small>
            <admin-badge />
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-products"
          v-if="hasPermission(user, 'products')"
        >
          <router-link class="nav-link" to="/products">
            <font-awesome-icon
              class=""
              icon="cubes"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <admin-badge />
            <small class="d-block">productos</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-map"
          v-if="hasPermission(user, 'visor') && info.deliveryEnabled"
        >
          <router-link class="nav-link" to="/map">
            <font-awesome-icon class="" icon="tv" size="lg"></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-block">visor</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-web"
          v-if="info.webEnabled && hasPermission(user, 'web')"
        >
          <router-link class="nav-link" to="/web">
            <font-awesome-icon
              class=""
              icon="globe"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-inline-block">web</small>
            <span
              class="badge animated infinite tada ml-1 position-absolute"
              v-if="webTicketsPending"
              :class="{
                'badge-light': webTicketsPending == 0,
                'badge-danger': webTicketsPending > 0,
              }"
            >
              {{ webTicketsPending }}
            </span>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-ots"
          v-if="info.otEnabled && hasPermission(user, 'ot')"
        >
          <router-link class="nav-link" to="/ots">
            <font-awesome-icon
              class=""
              icon="file-alt"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-1"></div>
            <small class="d-inline-block">OT</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          v-if="hasPermission(user, 'users')"
          id="nav-users"
        >
          <router-link class="nav-link" to="/users">
            <font-awesome-icon
              class=""
              icon="users"
              size="lg"
            ></font-awesome-icon>
            <admin-badge />
            <small class="d-inline-block">Usuarios</small>
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          v-if="info.socialNetworkEnabled && hasPermission(user, 'rrss')"
          id="nav-rrss"
        >
          <router-link class="nav-link" to="/rrss">
           <svg
              class="bi bi-meta"
              fill="currentColor"
              height="24"
              viewBox="0 0 16 16"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.217 5.243C9.145 3.988 10.171 3 11.483 3 13.96 3 16 6.153 16.001 9.907c0 2.29-.986 3.725-2.757 3.725-1.543 0-2.395-.866-3.924-3.424l-.667-1.123-.118-.197a54.944 54.944 0 0 0-.53-.877l-1.178 2.08c-1.673 2.925-2.615 3.541-3.923 3.541C1.086 13.632 0 12.217 0 9.973 0 6.388 1.995 3 4.598 3c.319 0 .625.039.924.122.31.086.611.22.913.407.577.359 1.154.915 1.782 1.714Zm1.516 2.224c-.252-.41-.494-.787-.727-1.133L9 6.326c.845-1.305 1.543-1.954 2.372-1.954 1.723 0 3.102 2.537 3.102 5.653 0 1.188-.39 1.877-1.195 1.877-.773 0-1.142-.51-2.61-2.87l-.937-1.565ZM4.846 4.756c.725.1 1.385.634 2.34 2.001A212.13 212.13 0 0 0 5.551 9.3c-1.357 2.126-1.826 2.603-2.581 2.603-.777 0-1.24-.682-1.24-1.9 0-2.602 1.298-5.264 2.846-5.264.091 0 .181.006.27.018Z"
                fill-rule="evenodd"
              />
            </svg>
            <div class="w-100 mt-2"></div>
            <small class="d-block">META</small>
            <admin-badge />
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-settings"
          v-if="hasPermission(user, 'settings')"
        >
          <router-link class="nav-link" to="/settings">
            <font-awesome-icon
              class=""
              icon="cog"
              size="lg"
            ></font-awesome-icon>
            <div class="w-100 mt-2"></div>
            <small class="d-block">CONFIG</small>
            <admin-badge />
          </router-link>
        </div>
        <div
          class="col-auto px-1 text-center"
          id="nav-remote-print"
          v-if="info.remotePrintEnabled && hasPermission(user, 'remote_print')"
        >
          <button class="btn nav-link" @click="$emit('showRemotePrintModal')">
            <font-awesome-icon
              icon="print"
              :class="{
                'text-danger': !printQueueEnabled,
                'text-success': printQueueEnabled,
              }"
              size="lg"
            />
            <div class="w-100 mt-1"></div>
            <small class="d-inline-block">COLA IMPR</small>
            <span
              class="badge badge-danger ml-1 position-absolute"
              v-if="printQueueEnabled"
              >{{ printQueue.length }}</span
            >
          </button>
        </div>
      </div>
      <ul class="navbar-nav mr-auto d-none" @click="clickNav()">
        <li class="nav-item">
          <router-link class="nav-link" to="/new">
            <font-awesome-icon
              class=""
              icon="cash-register"
              size="2x"
            ></font-awesome-icon>
            <small class="d-block">PDV</small>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/tickets">
            <font-awesome-icon
              class="d-md-inline-block d-lg-none"
              icon="file-alt"
              size="2x"
            ></font-awesome-icon>
            <span class="d-none d-lg-block">ventas</span>
            <small class="d-block">ventas</small>
          </router-link>
        </li>
        <li class="nav-item" v-if="user.role == 'admin'">
          <router-link class="nav-link" to="/products">
            <font-awesome-icon
              class="d-md-inline-block d-lg-none"
              icon="cubes"
              size="2x"
            ></font-awesome-icon>
            <span class="d-none d-lg-block">Productos</span>
            <small class="d-block">productos</small>
          </router-link>
        </li>
        <li class="nav-item" v-if="info.deliveryEnabled">
          <router-link class="nav-link" to="/map">
            <font-awesome-icon
              class="d-md-inline-block d-lg-none"
              icon="eye"
              size="2x"
            ></font-awesome-icon>
            <span class="d-none d-lg-block">VISOR</span>
            <small class="d-block">visor</small>
          </router-link>
        </li>
        <li class="nav-item" v-if="info.otEnabled">
          <router-link class="nav-link" to="/ots">
            <span>ORDENES</span>
            <small class="d-none">F8</small>
          </router-link>
        </li>
        <li
          class="nav-item d-none"
          v-if="info.beEnabled && hasPermission(user, 'be')"
        >
          <router-link class="nav-link" to="/sii">Facturacion</router-link>
        </li>
        <li
          class="nav-item"
          v-if="info.webEnabled && hasPermission(user, 'web')"
          v-show="webTicketsPending"
        >
          <router-link class="nav-link" to="/web">
            <font-awesome-icon
              class="d-md-inline-block d-lg-block"
              icon="laptop"
              size="2x"
            ></font-awesome-icon>
            <div class="w-100"></div>
            <span class="d-none d-lg-inline-block">Web</span>
            <span
              class="badge animated infinite tada ml-1"
              v-if="webTicketsPending"
              :class="{
                'badge-light': webTicketsPending == 0,
                'badge-danger': webTicketsPending > 0,
              }"
            >
              {{ webTicketsPending }}
            </span>
          </router-link>
        </li>

        <li class="nav-item px-2">
          <div class="dropdown" role="group">
            <button
              class="btn"
              type="button"
              id="dropdownMenuButton1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon icon="print" class="text-primary" size="lg" />
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton1"
              @click="$event.stopPropagation()"
              style="
                max-width: 400px;
                max-height: 450px;
                overflow: scroll;
                z-index: 9 !important;
              "
            >
              <div class="dropdown-item border-bottom d-none"></div>
              <div class="dropdown-item border-bottom">
                <span class="font-weight-bold"
                  ><span class="badge badge-info">BETA</span> COLA DE IMPRESION
                  <b-checkbox
                    v-model="printQueueEnabled"
                    switch
                    class="d-inline ml-2"
                  ></b-checkbox
                ></span>
                <small class="d-block text-muted" v-if="printQueueEnabled">{{
                  printUpdatedAt | moment("from", "now")
                }}</small>
              </div>

              <div class="dropdown-item" v-if="printQueue.length == 0">
                <span class="text-muted">No hay pendientes</span>
              </div>
              <div
                class="dropdown-item"
                v-for="pq in printQueue"
                :key="`pq-${pq._id}`"
              >
                <font-awesome-icon
                  icon="clock"
                  class="text-warning"
                  v-if="pq.status == 'in_queue'"
                />
                <font-awesome-icon
                  class="text-success"
                  icon="check-circle"
                  v-else
                />
                <span class="pl-2"
                  >#{{ pq.createdAt | moment("HH:mm:ss") }}</span
                >
                <!-- <button
                      class="btn btn-link"
                      @click="printTicket(pq.ticket || pq.table, pq.type, pq.copies, true)"
                    >
                      <font-awesome-icon icon="print" class="text-primary" />
                    </button> -->
              </div>
            </div>
          </div>
        </li>
      </ul>
      <span class="ml-auto navbar-text">
        <div class="row align-items-center">
          <div class="col"></div>
        </div>
      </span>
    </div>
  </div>
</template>
