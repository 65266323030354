//import Home from './pages/Home'
import ticketIndex from './pages/ticketIndex'
import ticketList from './pages/ticketList'
import productList from './pages/productList'
import coupons from './pages/products/coupons'
import productsMultiple from './pages/products/multiple'
import productReport from './pages/products/report'
//import ticketPrint from './pages/ticketPrint'
import ticketNew from './pages/ticketNew'
import sumup from './pages/sumup'
import stats from './pages/stats'
import stats2 from './pages/stats2'
import nstats from './pages/newStats'
import Login from './pages/Login'
import Report from './components/report'
import Report2 from './components/report2'
import Settings from './pages/settings'
import Users from './pages/Users'
import Map from './pages/Map'
import Recover from './pages/Recover'
import loading from './pages/loading'
import Expenses from './pages/expensesList'
import ExpensesListMode from './pages/expense/listmode'
import Ot from './pages/OtList'
// import OtNew from './pages/OtNew'
// import OtPrint from './pages/OtPrint'
import AdminIndex from './pages/admin/index'
import AdminBusiness from './pages/admin/business'
import AdminNoty from './pages/admin/noty'
import AdminCfs from './pages/admin/cfs'
import AdminLogs from './pages/admin/logs'
import AdminLogins from './pages/admin/logins'
import AdminWebTickets from './pages/admin/webTickets'
import AdminSii from './pages/admin/sii'
import AdminSocket from './pages/admin/socket'
import AdminHome from './pages/admin/home'
import AdminTicket from './pages/admin/ticket'
import Web from './pages/Web'
import WebIndex from './pages/web/index'
import WebClients from './pages/web/clients'
import WebProducts from './pages/web/products'
import WebPages from './pages/web/pages'
import Webay from './pages/web/webpay'
import WebStats from './pages/web/stats'
import WebConfig from './pages/web/config'
import Sii from './pages/Sii'
import SiiTool from './pages/sii/tool'
import ReportStock from './pages/ReportStock'

import DocumentIndex from './pages/document/index'
import documentList from './pages/document/list'
import documentTemplates from './pages/document/templates'

import WebMenu from './pages/web/menu'
import Invoices from './pages/invoices'
import AdminInvoice from './pages/admin/invoices'
import RRSS from './pages/rrss/index'

import TablesIndex from './pages/tables/index'
import TablesView from './pages/tables/view'

import SiiPdf from './pages/sii/pdf'
import AdminDTE from './pages/admin/dte'
import AdminMap from './pages/admin/map'
import productsCalculator from './pages/products/calculator'
import raffle from './pages/raffle'
import AdminRating from './pages/admin/rating'
import AdminEmailQueue from './pages/admin/emailQueue'
import AdminSiiBusiness from './pages/admin/siiBusiness'
import AdminStorage from './pages/admin/storage'

import quote from './pages/ticket/quote'

const routes = [
  { path: '/', component: loading },
  { path: '/loading', component: loading },
  { path: '/new', component: ticketNew },
  { path: '/raffle', component: raffle },
  {
    path: '/rrss', component: RRSS
  },
  {
    path: '/documents',
    component: DocumentIndex,
    children: [
      {
        path: '/',
        component: documentList
      },
      {
        path: 'templates',
        component: documentTemplates
      },
    ]
  },
  {
    path: '/tickets',
    component: ticketIndex,
    children: [
      {
        path: '/',
        component: ticketList
      },
      // {
      //   path: 'print',
      //   component: ticketPrint
      // },
      {
        path: 'quote',
        component: quote
      },
      {
        path: 'report',
        component: Report
      },
      {
        path: 'report2',
        component: Report2
      },
    ]
  },
  // { path: '/ot/print', component: OtPrint },
  { path: '/products', component: productList },
  { path: '/coupons', component: coupons },
  { path: '/products/multiple', component: productsMultiple },
  { path: '/products/calculator', component: productsCalculator },
  { path: '/products/report', component: productReport },
  { path: '/sumup', component: sumup },
  { path: '/stats', component: stats },
  { path: '/stats2', component: stats2 },
  { path: '/nstats', component: nstats },
  { path: '/invoices', component: Invoices },
  { path: '/login', component: Login },
  { path: '/settings', component: Settings },
  { path: '/users', component: Users },
  { path: '/map', component: Map },
  { path: '/expenses', component: Expenses },
  { path: '/expenses/listmode', component: ExpensesListMode },
  { path: '/recover', component: Recover },
  { path: '/ots', component: Ot },
  //{ path: '/ots/new', component: OtNew },
  {
    path: '/admin',
    component: AdminIndex,
    children: [
      { path: 'socket', component: AdminSocket },
      { path: 'invoices', component: AdminInvoice },
      { path: 'ticket', component: AdminTicket },
      { path: 'sii', component: AdminSii },
      { path: 'business', component: AdminBusiness },
      { path: 'noty', component: AdminNoty },
      { path: 'cfs', component: AdminCfs },
      { path: 'logs', component: AdminLogs },
      { path: 'logins', component: AdminLogins },
      { path: 'webtickets', component: AdminWebTickets },
      { path: 'dte', component: AdminDTE },
      { path: 'map', component: AdminMap },
      { path: 'rating', component: AdminRating },
      { path: 'emailqueue', component: AdminEmailQueue },
      { path: 'siibusiness', component: AdminSiiBusiness },
      { path: 'storage', component: AdminStorage },
      { path: '/', component: AdminHome },
    ]
  },
  {
    path: '/web',
    component: WebIndex,
    children: [
      { path: '/', component: Web },
      { path: 'clients', component: WebClients },
      { path: 'stats', component: WebStats },
      { path: 'webpay', component: Webay },
      { path: 'pages', component: WebPages },
      { path: 'menu', component: WebMenu },
      { path: 'products', component: WebProducts },
      { path: 'schedule', component: () => import('./pages/web/schedule') },
      { path: 'config', component: WebConfig },
    ]
  },
  {
    path: '/restaurant',
    component: TablesIndex
  },
  {
    path: '/restaurant/:id',
    component: TablesView
  },
  { path: '/sii', component: Sii },
  { path: '/sii/pdf', component: SiiPdf },
  { path: '/sii/tool', component: SiiTool },
  { path: '/reportstock', component: ReportStock },
  { path: '/tour', component: () => import('./pages/tour') },
  { path: '*', redirect: '/' },
]

export default routes