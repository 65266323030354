<template>
  <div class="">
    <div class="row">
      <div class="col">
        <ul class="list-group">
          <li class="list-group-item" :class="{
            'list-group-item-warning': cf.status != 'Envio Procesado'
          }" v-for="cf in cfs" :key="cf._id">
            <div class="row align-items-center">
              <div class="col-2 text-center font-weight-bold">
                {{ cf.date }}
                <div class="w-100"></div>
                <small
                  >ENVIADO: {{ cf.createdAt | moment("DD/MM/YY HH:mm") }}</small
                >
              </div>
              <div class="col-4 text-uppercase">
                <small class="d-block">{{ cf.business.rut }}</small>
                <small class="font-weight-bold">
                  {{ cf.business.name }}
                </small>
                <div class="w-100"></div>
                <small>Tipo de documento: {{ cf.type }}</small>
              </div>
              <div class="col-1">
                <font-awesome-icon
                  @click="showXML(cf.xml)"
                  icon="file-excel"
                  size="2x"
                  class="text-info click"
                ></font-awesome-icon>
                <div class="w-100"></div>
              </div>
              <div class="col-2 font-weight-bold">
                <small>USADOS {{ cf.used }}</small>
                <div class="w-100"></div>
                <small>ANULADOS: {{ cf.anuled }}</small>
              </div>
              <div class="col-2 font-weight-bold">
                <small>{{ cf.status }}</small>
                <div class="w-100"></div>
               <small class="font-weight-bold">TRACKID: </small>
                <small
                  >{{ cf.trackid || "------" }}</small
                >
                <div class="w-100"></div>
                <small class="font-weight-bold">AMBIENTE: </small>
                <small
                  >{{ cf.ambient }}</small
                >
              </div>
              <div class="col-1 text-right font-weight-bold">
                ${{ formatNumber(cf.total) }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <b-modal ref="viewxml" size="xl" title="Ver XML">
      <pre class="text-success">{{ xml }}</pre>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cfs: [],
      xml: ''
    };
  },
  mounted(){
    this.load()
  },
  methods: {
    showXML(xml) {
      this.xml = xml;
      this.$refs.viewxml.show();
    },
    load() {
      this.$http.get('sii/cfs')
        .then(res => this.cfs = res.data.data)
    }
  },
};
</script>