<template>
  <div class="px-4 bgwhite border border-top-0">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 my-4">
            <h2 class="border-bottom mb-3">ORDEN DE TRABAJO</h2>
          </div>

          <div class="ml-auto col-5">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <font-awesome-icon icon="calendar-alt" />
                </span>
              </div>
              <input
                type="date"
                v-model="date"
                class="form-control text-center"
                disabled
              />
            </div>
          </div>

          <div class="col-12">
            <b-overlay :show="loading">
              <div class="row">
                <div class="col-12 border-bottom">
                  <span class="text-muted text-uppercase"
                    >Informacion del cliente</span
                  >
                </div>

                <div class="col-12 mt-4">
                  <div class="row">
                    <div class="col-5">
                      <small class="font-weight-bold"
                        >RUT CLIENTE (opcional)</small
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="rut"
                          v-focus
                          v-on:keyup.enter="searchUser()"
                          placeholder="(Opcional)"
                          :class="{
                            'is-valid': rut.length > 8,
                            'is-invalid': rut.length <= 8 && rut.length > 0,
                          }"
                        />
                        <div class="input-group-append">
                          <button class="btn btn-primary" @click="searchUser()">
                            <font-awesome-icon icon="search" />
                          </button>
                        </div>
                      </div>
                      <small class="text-muted d-block"
                        >Sin puntos. Ej: 12345678-9</small
                      >
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row align-items-center mt-1">
                    <div class="col-10">
                      <small class="font-weight-bold">NOMBRE CLIENTE</small>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="name"
                          placeholder="Ej: Juan Perez"
                          :class="{
                            'is-valid': name.length > 3,
                            'is-invalid': name.length <= 3,
                          }"
                        />
                      </div>
                      <small class="text-muted">Minimo 4 caracteres</small>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row mt-1 align-items-center">
                    <div class="col">
                      <small class="font-weight-bold">TELEFONO</small>
                      <div class="input-group">
                        <input
                          type="text"
                          placeholder="Ej: 987654321 o +56987654321"
                          class="form-control"
                          v-model="phone"
                          :class="{
                            'is-valid': phone.length > 7,
                            'is-invalid': phone.length <= 7,
                          }"
                        />
                      </div>
                      <small class="text-muted">Minimo 8 caracteres</small>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row mt-3 align-items-center">
                    <div class="col">
                      <small class="font-weight-bold">CORREO</small>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Opcional"
                          v-model="email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row mt-3 align-items-center">
                    <div class="col">
                      <small class="font-weight-bold">FECHA DE ENTREGA</small>
                      <date-picker
                        v-model="endDate"
                        valueType="format"
                        :input-class="{
                          'form-control': true,
                          'is-valid': endDate != null,
                          'is-invalid': endDate == null,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>

          <div class="w-100"></div>

          <div class="w-100 my-2"></div>

          <div class="col-12">
            <small class="font-weight-bold">DESCRIPCION</small>
            <textarea
              class="form-control d-none"
              placeholder="Ej: Marca, modelo, color, diagnostico, informacion adicional, etc."
              rows="4"
              v-model="description"
            ></textarea>
            <vue-editor
              v-model="description"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>

          <div class="col-12 mt-4" v-if="false">
            <h6 class="font-weight-bold">Notas adicionales</h6>
            <textarea
              class="form-control"
              id=""
              cols="30"
              rows="10"
              v-model="notes"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-8 pt-3">
        <div class="">
          <ul class="list-group">
            <li class="list-group-item font-weight-bold list-group-item-dark">
              <div class="row">
                <div class="col">DETALLE</div>
                <div class="col-2 text-right">TOTAL</div>
              </div>
            </li>
            <li
              class="list-group-item"
              v-for="(p, i) in details"
              :key="`${p._id}-${i}`"
            >
              <div class="row align-items-center">
                <div class="col-2 align-self-start">
                  <button
                    class="btn btn-sm btn-outline-danger btn-block"
                    @click="deleteProduct(p)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                  <input
                    v-model.number="p.qty"
                    type="text"
                    class="form-control d-block text-center"
                    @focus="$event.target.select()"
                  />
                  <div class="btn-group btn-block" role="group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-secondary"
                      @click="p.qty--"
                    >
                      -
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="p.qty++"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div class="col">
                  <span class="d-block font-weight-bold text-uppercase">
                    {{ p.name || "- - - - - - -" }}
                  </span>
                  <small class="d-block">
                    <span>CODIGO: {{ p.code }}</span>
                  </small>
                  <small class="d-block">
                    <span>VALOR: ${{ formatNumber(p.value) }}</span>
                  </small>
                  <small class="d-block" v-if="p.stock">
                    <span>EN STOCK: {{ p.qty }}</span>
                  </small>
                  <div class="row align-items-center" v-if="p.storages && p.storages.length > 0">
                    <div class="col-3">
                      <small class="d-block">BODEGA</small>
                    </div>
                    <div class="col">
                      <select
                        v-model="p.storage"
                        class="form-control form-control-sm"
                        :class="{
                          'is-invalid': p.storage == null,
                          'is-valid': p.storage,
                        }"
                      >
                        <option
                          :value="s.storage._id"
                          v-for="s in p.storages"
                          :key="s.storage._id"
                        >
                          {{ s.storage.name }} ({{ s.qty }} {{ p.unit }})
                        </option>
                      </select>
                    </div>
                  </div>
                  
                  <span class="d-block text-muted" v-if="p.description">
                    {{ p.description }}
                  </span>
                  <textarea
                    placeholder="Informacion adicional"
                    class="form-control mt-2"
                    v-model="p.details"
                  ></textarea>
                </div>
                <div class="col-2 text-right font-weight-bold align-self-start">
                  ${{ formatNumber(p.value * p.qty) }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <input
                        type="text"
                        class="form-control text-uppercase"
                        placeholder="Codigo EJ: 1234"
                        v-model="code"
                        v-on:keyup.enter="addProduct()"
                      />
                      <small class="text-muted"
                        >PRESIONE ENTER PARA ACEPTAR</small
                      >
                    </div>
                    <div class="col">
                      <button
                        class="btn btn-outline-secondary btn-block btn-sm"
                        @click="showModalSearch()"
                      >
                        BUSQUEDA AVANZADA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="ml-auto col-3">
                  <span class="font-weight-bold">SUB-TOTAL</span>
                </div>
                <div class="col-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$ </span>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="0"
                      disabled
                      v-model.number="subtotal"
                    />
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="ml-auto col-3">
                  <span class="font-weight-bold">DESCUENTO</span>
                </div>
                <div class="col-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$ -</span>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="0"
                      v-model.number="discount"
                      @focus="$event.target.select()"
                    />
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="ml-auto col-3">
                  <span class="font-weight-bold">ABONO</span>
                </div>
                <div class="col-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="0"
                      @focus="$event.target.select()"
                      v-model.number="deposit"
                    />
                  </div>
                </div>
              </div>
              <div class="row align-items-center mt-2" v-if="deposit">
                <div class="ml-auto col-3">
                  <span class="font-weight-bold">MEDIO DE PAGO</span>
                </div>
                <div class="col-3">
                  <div class="input-group">
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="payment"
                    >
                      <option
                        :value="p"
                        v-for="p in payments"
                        :key="`payment-${p}`"
                      >
                        {{ p }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="ml-auto col-3">
                  <span class="font-weight-bold">TOTAL</span>
                </div>
                <div class="col-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      disabled
                      placeholder="0"
                      v-model.number="total"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 mt-4">
        <button
          class="btn btn-lg btn-block btn-success py-3"
          @click="save()"
          :disabled="saving"
        >
          {{ saving ? "Espere un momento..." : "GENERAR OT" }}
        </button>
      </div>
    </div>

    <!-- Modal Component -->
    <b-modal ref="modal" :id="'modal-search'" size="xl" title="Buscar producto">
      <label for="">Buscar</label>

      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Ingrese nombre o codigo del producto"
          v-model="filter"
          ref="filterInput"
          @keyup.enter="filterProducts()"
        />
        <button class="btn btn-outline-primary" @click="filterProducts()">
          BUSCAR
        </button>
      </div>
      <hr />
      <ul class="list-group">
        <li class="list-group-item list-group-item-dark">
          <div class="row font-weight-bold">
            <div class="col-3">CODIGO</div>
            <div class="col-4">NOMBRE</div>
            <div class="col-2"></div>
            <div class="col-1 text-right">VALOR</div>
            <div class="col-1"></div>
          </div>
        </li>
        <li
          class="list-group-item list-group-item-action"
          v-for="product in productList"
          :key="product._id"
          :class="{
            'list-group-item-danger': product.qty <= 0 && product.stock,
          }"
        >
          <div class="row align-items-center">
            <div class="col-5">
              <small>CODIGO: {{ product.code }}</small>
              <div class="w-100"></div>
              <strong class="text-uppercase">{{ product.name }}</strong>
              <div class="w-100"></div>
              <small v-if="product.description">{{
                product.description
              }}</small>
            </div>
            <div class="col-1 text-center">
              <small class="d-block">STOCK</small>
              <span class="font-weight-bold">{{
                product.stock ? product.qty : "- - -" | formatNumber
              }}</span>
            </div>
            <div class="col text-right">${{ formatNumber(product.value) }}</div>
            <div class="col-2 d-none" v-if="product.storages">
              <small class="d-block">BODEGA</small>
              <select v-model="product.storage" class="form-control">
                <option
                  :value="s._id"
                  v-for="{ storage: s } in product.storages"
                  :key="s._id"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
             <div class="col-2">
                  <small class="d-block">CANTIDAD</small>
                  <input
                    type="text"
                    v-model.number="product.q"
                    class="form-control text-center"
                    @focus="$event.target.select()"
                  />
                </div>
            <div class="col-1 px-4">
              <button
                class="btn btn-sm btn-primary btn-block"
                @click="add(product.code, product.q)"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
          </div>
        </li>
      </ul>
    </b-modal>

    <ot-modal ref="modalOT" @printOT="printOT" />
  </div>
</template>

<script>
import otModal from "../components/ot-modal.vue";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    otModal,
  },
  mounted() {
    this.date = this.$moment().format("YYYY-MM-DD");
    this.loadProducts();

    if (localStorage.rut) this.rut = localStorage.rut;
    if (localStorage.name) this.name = localStorage.name;
    if (localStorage.phone) this.phone = localStorage.phone;
    if (localStorage.description) this.description = localStorage.description;
    if (localStorage.details && localStorage.details != "null")
      this.details = JSON.parse(localStorage.details);
  },
  data() {
    return {
      saving: false,
      loading: false,
      rut: "",
      date: null,
      code: "",
      name: "",
      email: "",
      phone: "",
      notes: "",
      product: {
        name: "",
        qty: 1,
      },
      details: [],
      discount: 0,
      endDate: null,
      description: "",
      deposit: 0,
      filter: "",
      payment: "Efectivo",
      productList: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  computed: {
    payments() {
      let p = this.$store.state.info.payments;
      if (p) return p.split(",");
      else return [];
    },
    products() {
      return this.$store.state.products.map((p) => {
        let storage = null;
        if (p.storages && p.storages.length > 0)
          storage = p.storages[0].storage._id;
        return {
          ...p,
          q: 1,
          storage,
        };
      });
    },
    info() {
      return this.$store.state.info;
    },
    filteredProducts() {
      if (this.filter == "") {
        return this.products;
      } else {
        const filter = (product) => {
          return (
            product.name.toLowerCase().indexOf(this.filter.toLowerCase()) >
              -1 ||
            product.code.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
          );
        };
        return this.products.filter(filter);
      }
    },
    subtotal() {
      return this.details.reduce(
        (acc, current) => acc + current.qty * current.value,
        0
      );
    },
    total() {
      let val = this.details.reduce(
        (acc, current) => acc + current.qty * current.value,
        0
      );
      return val - this.discount;
    },
  },
  methods: {
    printOT(ot) {
      console.log("print ot");
      this.$store.commit("setOT", ot);
      this.$emit("printOT", ot);
    },
    showModalSearch() {
      this.$refs.modal.show();
      setTimeout(() => {
        this.$refs.filterInput.focus();
      }, 500);
    },
    filterProducts() {
      let filter = (p) => {
        p.words = 0;
        let words = this.filter.trim().toLowerCase().split(" ");
        let pwords = p.name.toLowerCase().split(" ");
        // check code
        if (this.filter.toLowerCase() == p.code.toString().toLowerCase()) {
          p.words = p.words + 500;
          return p;
        }
        // check words
        if (pwords.join(" ").indexOf(words.join(" ")) != -1) {
          // console.log(p.name)
          p.words = p.words + 100;
          // return p;
        }

        if (pwords.some((w) => words.includes(w))) {
          pwords.forEach((pw) => {
            if (words.includes(pw)) p.words = p.words + 10;
            if (words.join(" ").indexOf(pw) != -1) p.words = p.words + 5;
            words.forEach((w) => {
              if (pwords.join(" ").indexOf(w) != -1) p.words = p.words + 5;
            });
          });
          return p;
        }
        if (p.words > words.length * 5) return p;
      };
      let sort = (a, b) => {
        if (a.words > b.words) return -1;
        if (a.words < b.words) return 1;
        return 0;
      };
      this.productList = this.products.filter(filter).sort(sort);
    },
    clearData() {
      this.rut = "";
      this.date = this.$moment().format("YYYY-MM-DD");
      this.code = "";
      this.name = "";
      this.email = "";
      this.phone = "";
      this.notes = "";
      this.product = {
        name: "",
        qty: 1,
      };
      this.details = [];
      this.discount = 0;
      this.endDate = null;
      this.description = "";
      this.deposit = 0;
      this.filter = "";
      this.payment = "Efectivo";
    },
    searchUser() {
      const { rut } = this;
      if (rut.length <= 8) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "El rut ingresado no es valido",
        });
      } else {
        this.loading = true;
        this.$http
          .post("ot/search", { rut })
          .then((res) => {
            if (res.data.data != null) {
              this.name = res.data.data.name;
              this.phone = res.data.data.phone;
              this.email = res.data.data.email;
            } else {
              this.$noty.info("Usuario no encontrado");
            }
          })
          .catch((err) => {
            console.log("OtSearchUserError: ", err);
            this.$noty.error("Error al buscar usuario, intente nuevamente");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    save() {
      if (this.name.length < 3 || this.phone.length < 7)
        return this.$swal({
          icon: "info",
          title: "Error",
          text: "Debes ingresar los datos del cliente",
        });
      if (this.endDate == null)
        return this.$swal({
          icon: "info",
          title: "Error",
          text: "Debes seleccionar una fecha de entrega",
        });
      if (this.details.length == 0)
        return this.$swal({
          icon: "info",
          title: "Error",
          text: "Debes ingresar al menos un codigo valido",
        });
      this.saving = true;
      this.$http
        .post("ot", {
          name: this.name,
          rut: this.rut,
          phone: this.phone,
          email: this.email,
          endDate: this.endDate,
          description: this.description,
          products: this.details,
          discount: this.discount,
          deposit: this.deposit,
          notes: this.notes,
          payment: this.payment,
        })
        .then((res) => {
          this.$noty.success("Orden de trabajo generada");
          localStorage.rut = "";
          localStorage.name = "";
          localStorage.phone = "";
          localStorage.description = "";
          localStorage.details = null;
          //  this.$router.push("/ots");
          this.saving = false;
          this.clearData();
          this.$refs.modalOT.view(res.data.data._id);
        })
        .catch(() => {
          this.saving = false;
          this.$noty.error("Error al generar la OT");
        });
    },
    deleteProduct(product) {
      this.details.splice(this.details.indexOf(product), 1);
    },
    add(code, qty = 1) {
      this.code = code.toUpperCase();
      this.addProduct(qty);
    },
    addProduct(q = 1) {
      let code = this.code;
      let qty = q || 1;
      let customValue;

      if (code.indexOf("*") != -1) {
        let [nCode, nQty] = code.split("*");
        if (!parseInt(nQty)) return false;
        qty = parseInt(nQty);
        code = nCode;
      }
      if (code.indexOf("/") != -1) {
        let [nCode, nQty] = code.split("/");
        if (!parseInt(nQty)) return false;
        customValue = parseInt(nQty);
        code = nCode;
      }

      let product = this.products.find((obj) => obj.code == code.toUpperCase());

      if (product) {
        product._id = undefined;
        let p = {
          ...product,
          value: customValue || product.value,
          total: (customValue || product.value) * qty,
          qty: qty,
        };
        if (p.storages && p.storages.length > 0) {
          console.log('hay storage')
          p.storage = p.storages[0].storage._id;
        }
        this.details.push(p);
        this.code = "";
        this.$noty.success("Producto agregado!");
      } else {
        this.$noty.error("El codigo ingresado no fue encontrado");
        this.code = "";
      }
    },
    loadProducts() {
      this.$http.get("product").then((res) => {
        this.$store.commit("setProducts", res.data.data);
        this.productList = this.products.slice(0, 100);
      });
    },
  },
  watch: {
    rut: function (val) {
      localStorage.rut = val;
    },
    name: function (val) {
      localStorage.name = val;
    },
    phone: function (val) {
      localStorage.phone = val;
    },
    email: function (val) {
      localStorage.email = val;
    },
    description: function (val) {
      localStorage.description = val;
    },
    details: function (val) {
      localStorage.details = JSON.stringify(val);
    },
  },
};
</script>