<template>
  <div>
    <div class="container-fluid">
      <b-tabs
        pills
        justified
        content-class="bgwhite"
        nav-class="text-uppercase"
      >
        <b-tab title="Crear">
          <div class="row p-4 py-5">
            <div class="col-12">
              <h1 class="text-center">Cotización</h1>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="rut">RUT</label>
                <input
                  type="text"
                  class="form-control"
                  id="rut"
                  v-model="quote.rut"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="name">NOMBRE</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="quote.name"
                />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="name">ACTIVIDAD</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="quote.activity"
                />
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="email">EMAIL</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="quote.email"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="phone">TELEFONO</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  v-model="quote.phone"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address">DIRECCION</label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  v-model="quote.address"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="city">CIUDAD</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  v-model="quote.city"
                />
              </div>
            </div>
            <div class="col-12 py-5">
              <ul class="list-group">
                <li
                  class="list-group-item list-group-item"
                  v-for="item in quote.items"
                  :key="item.id"
                >
                  <div class="row align-items-end">
                    <div class="col-2">
                      <div class="input-group">
                        <small class="btn-block">CODIGO</small>
                        <input
                          type="text"
                          class="form-control"
                          v-model="item.code"
                          placeholder="Código"
                          @focus="$event.target.select()"
                        />
                        <button
                          class="btn btn-light"
                          @click="searchProduct(item)"
                        >
                          <font-awesome-icon icon="search" />
                        </button>
                      </div>
                    </div>
                    <div class="col-4">
                      <small>NOMBRE</small>
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.name"
                        placeholder="Nombre"
                        @focus="$event.target.select()"
                      />
                    </div>
                    <div class="col-1 px-0">
                      <small>CANTIDAD</small>
                      <input
                        type="number"
                        class="form-control"
                        v-model="item.qty"
                        placeholder="Cantidad"
                        @focus="$event.target.select()"
                      />
                    </div>
                    <div class="col-2">
                      <small>VALOR</small>
                      <input
                        type="number"
                        class="form-control"
                        v-model="item.value"
                        placeholder="Precio"
                        @focus="$event.target.select()"
                      />
                    </div>
                    <div class="col-2">
                      <small>TOTAL CON IVA</small>
                      <div class="form-control input">
                        {{ (item.qty * item.value) | formatNumber }}
                      </div>
                    </div>
                    <div class="col">
                      <button
                        class="btn btn-danger btn-block"
                        @click="removeItem(item)"
                      >
                        <font-awesome-icon icon="trash" />
                      </button>
                    </div>
                    <div class="col-12">
                      <small>DESCRIPCION</small>
                      <textarea
                        class="form-control"
                        rows="3"
                        placeholder="Descripción"
                        v-model="item.details"
                      ></textarea>
                    </div>
                  </div>
                </li>
              </ul>

              <div>
                <button class="btn btn-dark btn-block" @click="addItem()">
                  <font-awesome-icon icon="plus" /> Agregar
                </button>
              </div>
            </div>

            <div class="col-12">
              <div class="row fs-2">
                <div class="col-4 text-right ml-auto">SUB TOTAL</div>
                <div class="col-3">
                  <div class="form-control fs-3">
                    {{ subtotal | formatNumber }}
                  </div>
                </div>
              </div>
              <div class="row fs-2 mt-2">
                <div class="col-4 text-right ml-auto">DESCUENTO</div>
                <div class="col-3">
                  <div class="input-group">
                    <div class="dropdown" id="discountBtn">
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        %
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div class="row no-gutters">
                          <div class="col">
                            <button
                              @click="applyDiscount(0)"
                              class="dropdown-item"
                            >
                              {{ 0 }}%
                            </button>
                          </div>
                          <div class="col-6" v-for="i in 20" :key="i">
                            <button
                              @click="applyDiscount(i)"
                              class="dropdown-item"
                            >
                              {{ i * 5 }}%
                            </button>
                          </div>
                          <div class="col-12">
                            <input
                              type="text"
                              class="form-control"
                              v-model.number="discountPercent"
                            />
                            <button
                              class="btn btn-success"
                              @click="applyDiscount(discountPercent)"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="number"
                      class="form-control fs-3"
                      v-model="quote.discount"
                      placeholder="Descuento"
                      @focus="$event.target.select()"
                    />
                  </div>
                </div>
              </div>
              <div class="row fs-2 mt-2">
                <div class="col-4 text-right ml-auto">TOTAL NETO:</div>
                <div class="col-3">
                  <div class="form-control fs-3">
                    {{ total | formatNumber }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 my-3">
              <textarea
                class="form-control"
                rows="7"
                placeholder="Observaciones"
                v-model="quote.notes"
              ></textarea>
            </div>
            <div class="col-12">
              <button
                type="submit"
                class="btn btn-success btn-lg py-3 btn-block"
                @click="submitForm"
              >
                CREAR
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Lista" active>
          <div class="row py-2">
            <div class="col">
              <input
                type="text"
                class="form-control"
                placeholder="Buscar"
                v-model="search"
              />
            </div>
            <div class="col-2 ml-auto">
              <button
                class="btn btn-success btn-lg py-2 btn-block"
                @click="loadQuotes()"
              >
                <font-awesome-icon icon="plus" /> Actualizar
              </button>
            </div>
          </div>
          <ul class="list-group">
            <li class="list-group-item" v-if="quotes.length == 0">
              <div class="row align-items-center py-5">
                <div class="col text-center">
                  <strong>No hay cotizaciones</strong>
                </div>
              </div>
            </li>
            <li class="list-group-item" v-for="item in quotes" :key="item.id">
              <div class="row align-items-center">
                <div class="col-1 text-center">
                  #{{ item.number }}
                  <div class="w-100"></div>
                  <small>{{ item.createdAt | formatDate }}</small>
                </div>
                <div class="col">
                  <small>{{ item.rut }}</small>
                  <div class="w-100"></div>
                  <strong class="text-uppercase">{{ item.name }}</strong>
                </div>
                <div class="col-3">{{ item.email }}</div>
                <div class="col-auto text-center">
                  <div
                    class="badge px-4 py-1 text-uppercase"
                    :class="{
                      'badge-success': item.status === 'Aceptado',
                      'badge-danger': item.status === 'Rechazado',
                      'badge-warning': item.status === 'pending',
                    }"
                  >
                    {{ item.status }}
                  </div>
                </div>
                <div class="col-2 text-right">
                  ${{ item.total | formatNumber }}
                </div>
                <div class="col-auto">
                  <div class="btn-group btn-block">
                    <button class="btn btn-warning" @click="copy(item)">
                      <font-awesome-icon icon="file-alt" />
                    </button>
                    <button class="btn btn-light" @click="view(item)">
                      <font-awesome-icon icon="eye" />
                    </button>
                    <button class="btn btn-primary" @click="view(item, 1)">
                      <font-awesome-icon icon="file-alt" />
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Quote",
  data() {
    return {
      discountPercent: 0,
      quotes: [],
      dateFilter: null,
      quote: {
        clientName: "",
        email: "",
        giro: "",
        phone: "",
        rut: "",
        address: "",
        city: "",
        createdAt: Date.now(),
        items: [],
        notes: "",
        discount: 0,
        
      },
      search: ''
    };
  },
  mounted() {
    if (localStorage.getItem("quote")) {
      try {
        this.quote = JSON.parse(localStorage.getItem("quote"));
      } catch (e) {
        localStorage.removeItem("quote");
      }
    }
    this.loadQuotes();
  },
  methods: {
    copy(item) {
      const products = item.products.map((product, index) => {
        return {
          code: product.code || index,
          name: product.name,
          qty: product.qty,
          value: product.value,
          details: product.details,
        };
      });
      this.quote = {
        name: item.name,
        email: item.email,
        giro: item.giro,
        phone: item.phone,
        rut: item.rut,
        address: item.address,
        city: item.city,
        createdAt: Date.now(),
        items: products,
        notes: item.notes,
        discount: item.discount,
      };
    },
    applyDiscount(value) {
      console.log({ value });
      let discountPercent = value * 5;
      this.quote.discount = (this.subtotal * discountPercent) / 100;
    },
    searchProduct(item) {
      let { code } = item;

      console.log(code);

      let product = this.products.find((product) => {
        return product.code === code;
      });

      if (product) {
        item.name = product.name;
        item.value = product.value;
        item.details = product.description;
      } else {
        this.$noty.info("Producto no encontrado");
      }
    },
    loadQuotes() {
      this.$http
        .get("quote")
        .then((response) => {
          this.quotes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.$noty.info("Error");
        });
    },
    removeItem(item) {
      this.quote.items = this.quote.items.filter((i) => {
        return (
          i.code !== item.code && i.name !== item.name && i.value !== item.value
        );
      });
    },
    submitForm() {
      
      let total = this.quote.items.reduce((acc, item) => {
        return acc + item.value * item.qty;
      }, 0);

      let products = this.quote.items.map((item) => {
        return {
          code: item.code,
          name: item.name,
          qty: item.qty,
          value: item.value,
          total: item.value * item.qty,
          details: item.details,
        };
      });

      let sub_total = total;
      let neto = Math.round(total / 1.19);
      let iva = total - neto;

      this.$http
        .post("quote", {
          name: this.quote.name,
          email: this.quote.email,
          activity: this.quote.activity,
          phone: this.quote.phone,
          rut: this.quote.rut,
          address: this.quote.address,
          city: this.quote.city,
          notes: this.quote.notes,
          total: total,
          type: "quote",
          iva: iva,
          neto: neto,
          subtotal: sub_total,
          status: "Por pagar",
          products: products,
          discount: this.quote.discount,
        })
        .then((response) => {
          const data = response.data.data;
          this.$noty.success("Cotización creada");
          this.loadQuotes();
          this.view(data);
        })
        .catch((error) => {
          console.log(error);
          this.$noty.info("Error");
        });
    },
    addItem() {
      this.quote.items.push({
        id: this.quote.items.length + 1,
        code: "",
        name: "",
        qty: 1,
        value: 0,
      });
    },
    view(data, print = false) {
      let sub_total = data.subtotal;
      let neto = Math.round(data.total / 1.19);
      let iva = data.total - neto;

      let ticket = {
        createdAt: this.$moment().hour(9),
        isDTE: 1,
        dteType: "QUOTE",
        folio: data.number,
        TED: false,
        receptor: {
          rs: data.name,
          activity: data.activity || "- - - - - - - - -",
          rut: data.rut,
          email: data.email,
          phone: data.phone,
          address: data.address,
          city: data.city,
        },
        notes: data.notes,
        total: data.total,
        type: "quote",
        neto: neto,
        sub_total: sub_total,
        discount: data.discount || 0,
        iva: iva,
        status: "Por pagar",
        products: data.products,
      };

      this.$store.commit("setTicket", ticket);

      if (print) this.$emit("printTicket", { ticket });
      else this.$emit("showTicket", { ticket });
    },
  },
  computed: {
    subtotal() {
      if (!this.quote.items) return 0;
      let total = this.quote.items.reduce((acc, item) => {
        return acc + item.value * item.qty;
      }, 0);

      return total;
    },
    total() {
      if (!this.quote.items) return 0;
      let total = this.quote.items.reduce((acc, item) => {
        return acc + item.value * item.qty;
      }, 0);

      let discount = this.quote.discount || 0;

      return total - discount;
    },
    products() {
      return this.$store.state.products;
    },
    quoteNumber() {
      let quoteNumber = localStorage.getItem("quoteNumber");
      if (!quoteNumber) {
        quoteNumber = 1;
      } else {
        quoteNumber = parseInt(quoteNumber) + 1;
      }
      localStorage.setItem("quoteNumber", quoteNumber + 1);
      return quoteNumber;
    },
  },
  watch: {
    discountPercent() {
      this.applyDiscount(this.discountPercent);
    },
    quote: {
      handler: function (val) {
        localStorage.setItem("quote", JSON.stringify(val));
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>