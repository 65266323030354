<template>
  <div class="container">
    <b-modal ref="viewxml" size="xl" title="Ver XML Consumo de folios">
      <pre class="text-success">{{ xml }}</pre>
    </b-modal>
    <b-modal
      id="newcf"
      size="md"
      title="Enviar consumo de folios al SII"
      @ok="sendCF"
    >
      <div class="row">
        <div class="col-12">
          <div class="alert alert-info">
            <strong>Importante!</strong>
            <span class="d-block"
              >Use esta herramienta solo de necesitarlo por el SII</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <strong>Fecha</strong>
        </div>
        <div class="col">
          <date-picker
            valueType="format"
            v-model="date"
            input-class="form-control"
          />
          <small class="text-muted">Fecha del consumo de folios</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <strong>Tipo</strong>
        </div>
        <div class="col">
          <select class="form-control">
            <option value="39">Boleta electronica 39</option>
            <option value="41">Boleta electronica exenta 41</option>
            <option value="33">Factura electronica 33</option>
          </select>
          <small class="text-muted">Tipo de consumo de folios a enviar</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <strong class="d-block">Secuencia</strong>
        </div>
        <div class="col">
          <input type="number" class="form-control" v-model="secuence" />
          <small class="text-muted">Solo cambiar si es necesario</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <strong class="d-block">Inicio</strong>
          <small class="text-muted">Folio inicial</small>
        </div>
        <div class="col">
          <input type="number" class="form-control" v-model="folioStart" />
          <small class="text-muted">Solo cambiar si es necesario</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <strong class="d-block">Fin</strong>
          <small class="text-muted">Folio final</small>
        </div>
        <div class="col">
          <input type="number" class="form-control" v-model="folioEnd" />
          <small class="text-muted">Solo cambiar si es necesario</small>
        </div>
      </div>
    </b-modal>

    <b-overlay :show="loading">
      <div class="row">
        <div class="col-12">
          <h4>Consumo de folios</h4>
        </div>

        <div class="col text-center col-md-3">
          <div class="card">
            <div class="card-body">
              <font-awesome-icon icon="database" size="4x"></font-awesome-icon>
              <h3 class="text-uppercase mt-2">{{ docs }}</h3>
              <small>USADOS + ANULADOS</small>
            </div>
          </div>
        </div>

        <div class="col text-center col-md-3">
          <div class="card">
            <div class="card-body">
              <font-awesome-icon
                icon="file-upload"
                size="4x"
              ></font-awesome-icon>
              <h3 class="text-uppercase mt-2">
                
                {{ cfs.length }} / {{ daysInMonth }}
              </h3>
              <small>REPORTES ENVIADOS</small>
            </div>
          </div>
        </div>

        <div class="col text-center col-md-3">
          <div class="card">
            <div class="card-body">
              <font-awesome-icon
                icon="money-bill-wave"
                size="4x"
              ></font-awesome-icon>
              <h3 class="text-uppercase mt-2">${{ formatNumber(iva) }}</h3>
              <small>IVA (19%)</small>
            </div>
          </div>
        </div>

        <div class="col text-center col-md-3">
          <div class="card">
            <div class="card-body">
              <font-awesome-icon
                icon="money-bill-wave"
                size="4x"
              ></font-awesome-icon>
              <h2 class="text-uppercase mt-2">${{ formatNumber(total) }}</h2>
              <small>TOTAL</small>
            </div>
          </div>
        </div>

        <div class="col-8"></div>
        <div class="col-12 mt-2">
          <ul class="list-group">
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-1">
                  <span class="font-weight-bold">FECHA</span>
                </div>
                <div class="col-3">
                  <span class="font-weight-bold">ESTADO</span>
                </div>
                <div class="col-2 text-center">
                  <span class="font-weight-bold">RANGO</span>
                </div>
                <div class="col-2">
                  <span class="font-weight-bold">DETALLE</span>
                </div>
                <div class="col text-right">
                  <span class="font-weight-bold">TOTAL</span>
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="cfs.length == 0">
              <h4 class="text-center py-4">No hay informacion</h4>
            </li>
            <li class="list-group-item" v-for="cf in cfs" :key="cf._id">
              <div class="row align-items-center">
                <div class="col-1">
                  <small class="font-weight-bold text-uppercase">
                    {{ cf.date | moment("DD/MM/YY") }}
                  </small>
                </div>
                <div class="col-4">
                  <div class="row no-gutters align-items-center">
                    <div class="col-1 mr-2">
                      <font-awesome-icon
                        icon="check-circle"
                        class="text-primary"
                        v-if="cf.status == 'Enviado'"
                      ></font-awesome-icon>
                      <font-awesome-icon
                        icon="times-circle"
                        class="text-danger"
                        v-else-if="cf.status == 'No enviado'"
                      ></font-awesome-icon>
                      <font-awesome-icon
                        icon="check-circle"
                        class="text-success"
                        v-else-if="cf.status == 'Envio Procesado'"
                      ></font-awesome-icon>
                      <font-awesome-icon
                        icon="check-circle"
                        class="text-success"
                        v-else-if="cf.status == 'Generado'"
                      ></font-awesome-icon>
                      <font-awesome-icon
                        icon="times"
                        class="text-danger"
                        v-else
                      ></font-awesome-icon>
                      <div class="w-100"></div>
                      <font-awesome-icon
                        @click="showXML(cf.xml)"
                        icon="file-excel"
                        class="text-info click"
                      ></font-awesome-icon>
                    </div>
                    <div class="col-10">
                      <span class="font-weight-bold">
                        {{ cf.status }}
                      </span>
                      <small class="d-block">
                        Secuencia: {{ cf.secuence }}
                      </small>
                      <small class="d-block">
                        TrackID SII:
                        <span class="click" @click="updateCF(cf.trackid)"
                          >{{ cf.trackid }}
                        </span>
                      </small>
                      <small class="text-muted">
                        actualizado {{ $moment(cf.updatedAt).fromNow() }}</small
                      >
                    </div>
                  </div>
                </div>
                <div class="col-2 text-center">
                  <span class="d-block">{{ cf.start }} - {{ cf.end }}</span>
                  <small>TOTAL: {{ cf.tickets.length }}</small>
                </div>
                <div class="col-2">
                  <small class="d-block">USADOS: {{ cf.used }}</small>
                  <small>ANULADOS: {{ cf.anuled }}</small>
                </div>
                <div class="col text-right">
                  <small class="d-block"
                    >NETO: ${{ formatNumber(cf.neto) }}</small
                  >
                  <small class="d-block"
                    >EXENTO: ${{ formatNumber(cf.exent) }}</small
                  >
                  <small class="d-block"
                    >IVA: ${{ formatNumber(cf.iva) }}</small
                  >
                  <small class="font-weight-bold">
                    TOTAL: ${{ formatNumber(cf.total) }}
                  </small>
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="col-1 ml-auto">${{ formatNumber(iva) }}</div>
                <div class="col-2 text-right font-weight-bold">
                  ${{ formatNumber(total) }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: ["date"],
  name: "SiiCf",
  data() {
    return {
      tickets: 0,
      cfs: [],
      cert: {},
      m: null,
      loading: true,
      folioStart: null,
      folioEnd: null,
      secuence: null,
      xml: "",
      year: 0,
      cf: {},
    };
  },
  mounted() {
    //this.load();
  },
  watch: {
    date() {
      this.load();
    },
  },
  computed: {
    daysInMonth() {
       let days = this.$moment(this.date, "YYYY-MM").daysInMonth()
       return days
    },
    docs() {
      return this.cfs.reduce((t, a) => {
        if (a.status == "Envio Procesado" || a.status == "Generado")
          return t + a.tickets.length;
        return t;
      }, 0);
    },
    iva() {
      return this.cfs.reduce((t, a) => {
        if (a.status == "Envio Procesado" || a.status == "Generado")
          return t + a.iva;
        return t;
      }, 0);
    },
    total() {
      return this.cfs.reduce((t, a) => {
        if (a.status == "Envio Procesado" || a.status == "Generado")
          return t + a.total;
        return t;
      }, 0);
    },
  },
  methods: {
    updateCF(trackid) {
      this.$http
        .get(`sii/cf/${trackid}`)
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.$noty.error("Error al actualizar intente nuevamente");
        });
    },

    load() {
      this.loading = true;
      this.$http
        .get(`sii?date=${this.date}`)
        .then(({ data }) => {
          this.cfs = data.data.cfs;
          this.tickets = data.data.tickets;
       
        })
        .catch(() => {
          this.$noty.error("Ha ocurrido un error al obtener los datos");
        }).finally(() => {
          this.loading = false;
        });
    },
    showXML(xml) {
      this.xml = xml;
      this.$refs.viewxml.show();
    },
    sendCF() {
      this.loading = true;
      this.$http
        .post(`sii/cf?date=${this.date}`, {
          folioStart: this.folioStart,
          folioEnd: this.folioEnd,
          secuence: this.secuence,
        })
        .then(() => {
          this.$noty.success("Enviado al SII");
          this.load();
        })
        .catch(() => {
          this.loading = false;
          this.$noty.error("Error al enviar, intente nuevamente");
        });
    },
  },
};
</script>


     