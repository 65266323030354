<template>
  <div>
    <b-modal
      ref="edit-invoice"
      size="xl"
      title="Editar Cobro"
      @ok="updateInvoice()"
      @hide="closeModal()"
    >
      <div class="row">
        <div class="col">
          <strong>NOMBRE</strong>
          <input type="text" class="form-control" v-model="invoice.name" />
        </div>
        <div class="col-4">
          <strong>FECHA</strong>
          <date-picker
            valueType="format"
            v-model="invoice.createdAt"
            input-class=" form-control text-center"
          />
        </div>
        <div class="col-4">
          <strong>EXPIRA</strong>
          <date-picker
            valueType="format"
            v-model="invoice.expire"
            input-class=" form-control text-center"
          />
        </div>
        <div class="col-12">
          <strong>ESTADO</strong>
          <div class="btn-group btn-block">
            <button
              class="btn btn-sm"
              :class="{
                'btn-danger': invoice.status === 'pendiente',
                'btn-outline-danger': invoice.status !== 'pendiente',
              }"
              @click="invoice.status = 'pendiente'"
            >
              Pendiente
            </button>
            <button
              class="btn btn-sm"
              :class="{
                'btn-success': invoice.status === 'pagada',
                'btn-outline-success': invoice.status !== 'pagada',
              }"
              @click="invoice.status = 'pagada'"
            >
              Pagada
            </button>
          </div>
          <select class="form-control" v-model="invoice.status">
            <option value="pagada">Pagada</option>
            <option value="pendiente">Pendiente</option>
          </select>
          <!-- <input
            type="text"
            class="form-control"
            disabled
            v-model="invoice.status"
          /> -->
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col">
              <strong>DETALLES</strong>
            </div>
            <div class="col-4 ml-auto">
              <button
                class="btn btn-link btn-sm float-right"
                @click="addItem()"
              >
                AGREGAR ITEM
              </button>
            </div>
          </div>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(item, i) in invoice.items"
              :key="`item-${i}`"
            >
              <div class="row">
                <div class="col">
                  <small>NOMBRE</small>
                  <input
                    ref="inputItemName"
                    type="text"
                    class="form-control"
                    v-model="item.name"
                  />
                </div>
                <div class="col-2">
                  <small>CANT</small>
                  <input type="text" class="form-control" v-model="item.qty" />
                </div>
                <div class="col-3">
                  <small>VALOR</small>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item.value"
                  />
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="ml-auto col-2">
                  <strong>TOTAL</strong>
                </div>
                <div class="col-3 text-right">
                  <strong>${{ totalInvoice(invoice) | formatNumber }}</strong>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-5">
          <small class="font-weight-bold">NOTAS</small>
          <textarea
            class="form-control"
            cols="30"
            rows="10"
            v-model="invoice.notes"
          ></textarea>
        </div>
        <div class="col-7">
          <small class="font-weight-bold">HISTORIAL</small>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="event in invoice.events"
              :key="event._id"
            >
              <div class="row">
                <div class="col-3">
                  <small class="">{{
                    event.createdAt | moment("DD/MM/YY HH:mm")
                  }}</small>
                </div>

                <div class="col-3">
                  <small class="font-weight-bold">{{
                    event.user && event.user.name
                  }}</small>
                </div>
                <div class="col">
                  <small>{{ event.name }}</small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modal"
      size="lg"
      title="Crear Factura de cliente"
      @ok="saveInvoice()"
    >
      <div class="row">
        <div class="col">
          <div class="row align-items-center">
            <div class="col">
              <strong>NOMBRE</strong>
              <input type="text" class="form-control" v-model="invoice.name" />
            </div>
            <div class="col-4">
              <strong>FECHA</strong>
              <date-picker
                valueType="format"
                v-model="invoice.createdAt"
                input-class=" form-control text-center"
              />
              <button class="btn btn-link btn-sm" @click="addDays(31, 1)">
                ADD 1 MONTH
              </button>
            </div>
            <div class="col-4">
              <strong>EXPIRA</strong>
              <date-picker
                valueType="format"
                v-model="invoice.expire"
                input-class=" form-control text-center"
              />
              <button class="btn btn-link btn-sm" @click="addDays(31, 2)">
                ADD 1 MONTH
              </button>
            </div>
            <div class="col-12">
              <strong>EMPRESA</strong>
              <v-select
                v-model="invoice.business"
                class="py-1"
                :reduce="(c) => c._id"
                :options="getBusiness"
              ></v-select>
              <!-- <select class="form-control" v-model="invoice.business">
                <option :value="b._id" v-for="b in business" :key="b.id">
                  {{ b.name }} | {{ b.custom_name || " - - - -" }}
                </option>
              </select> -->
            </div>
          </div>

          <div class="w-100 py-3"></div>

          <div class="row">
            <div class="col">
              <strong>DETALLES</strong>
            </div>
            <div class="col-4 ml-auto">
              <button
                class="btn btn-link btn-sm float-right"
                @click="addItem()"
              >
                AGREGAR ITEM
              </button>
            </div>
          </div>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(item, i) in invoice.items"
              :key="`item-${i}`"
            >
              <div class="row">
                <div class="col">
                  <small>NOMBRE</small>
                  <input
                    ref="inputItemName"
                    type="text"
                    class="form-control"
                    v-model="item.name"
                  />
                </div>
                <div class="col-2">
                  <small>CANT</small>
                  <input type="text" class="form-control" v-model="item.qty" />
                </div>
                <div class="col-3">
                  <small>VALOR</small>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item.value"
                  />
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row">
                <div class="ml-auto col-2">
                  <strong>TOTAL</strong>
                </div>
                <div class="col-3 text-right">
                  <strong>${{ totalInvoice(invoice) | formatNumber }}</strong>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <div class="container-fluid py-4">
      <b-tabs>
        <b-tab title="Facturas">
          <div class="row py-2 align-items-end">
            <div class="col">
              <h3>Facturas de clientes {{ invoices.length }}</h3>
            </div>

            <div class="w-100"></div>
            <div class="col-3">
              <div class="badge badge-info py-2 w-100">
                PENDIENTE ${{ total | formatNumber }}
              </div>
            </div>
            <div class="col-3">
              <div class="badge badge-success py-2 w-100">
                PAGADA ${{ totalPay | formatNumber }}
              </div>
            </div>
            <div class="col-3">
              <div class="badge badge-primary py-2 w-100">
                TOTAL ${{ (total + totalPay) | formatNumber }}
              </div>
            </div>
            <div class="w-100 py-2"></div>
            <div class="col">
              <input
                type="text"
                placeholder="Buscar..."
                class="form-control"
                v-model="fakeFilter"
                @keyup.enter="filter = fakeFilter"
                enterkeyhint="go"
              />
            </div>
            <div class="col-3 ml-auto">
              <small>ESTADO</small>
              <select class="form-control" v-model="status">
                <option value="">Todos</option>
                <option value="pagada">Pagada</option>
                <option value="pendiente">Pendiente</option>
              </select>
            </div>
            <div class="col-3">
              <small>MES</small>
              <date-picker
                valueType="format"
                type="month"
                :input-attr="{
                  placeholder: 'Seleccione mes',
                  id: 'month',
                  name: 'month'
                }"
                v-model="dateFilter"
                input-class=" form-control text-center"
              />
            </div>
            <div class="col-1 px-1">
              <small>MANUAL</small>
              <button class="btn btn-primary w-100" @click="create()">
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div class="col-1 px-1">
              <small>SERVER</small>
              <button class="btn btn-success w-100" @click="createInvoices()">
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <div class="col-1 pl-0">
              <button class="btn btn-success w-100" @click="load()">
                <font-awesome-icon icon="redo" />
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <span class="mx-4"
                >PENDIENTE: ${{
                  getTotal(getInvoices, "pendiente") | formatNumber
                }}</span
              >
              <span class=""
                >TOTAL: ${{ getTotal(getInvoices) | formatNumber }}</span
              >
            </div>
            <div class="col overflow-auto custom_scroll" style="height: 60vh">
              <ul class="list-group">
                <li
                  class="list-group-item list-group-item-action click"
                  v-for="invoice in getInvoices"
                  :class="{
                    active: invoice._id == iid,
                  }"
                  :key="invoice._id"
                >
                  <div class="row align-items-center">
                    <div class="col-1">
                      <small>{{
                        invoice.createdAt | moment("DD/MM/YY")
                      }}</small>
                    </div>
                    <div class="col">
                      <small>{{
                        invoice.business && invoice.business.name.slice(0, 30)
                      }}</small>
                      <small class="ml-4">{{
                        invoice.business && invoice.business.custom_name
                      }}</small>
                      <div class="w-100"></div>
                      <strong>{{ invoice.name }}</strong>
                      <div class="w-100"></div>
                      <small>{{ invoice.updatedAt }}</small>
                      <small>{{ invoice.notes }}</small>
                    </div>
                    <div class="col-2 text-center">
                      <small>VENCE</small>
                      <div class="w-100"></div>
                      <strong>{{ invoice.expire | moment("DD/MM/YY") }}</strong>
                    </div>
                    <div class="col-2 text-right">
                      <div
                        class="badge w-100 py-2"
                        :class="{
                          'badge-success': invoice.status == 'pagada',
                          'badge-danger': invoice.status == 'pendiente',
                        }"
                      >
                        <strong class="text-uppercase">{{
                          invoice.status
                        }}</strong>
                      </div>
                    </div>
                    <div class="col-1 text-right">
                      <strong>${{ invoice.total | formatNumber }}</strong>
                    </div>
                    <div class="col-2 text-right">
                      <button
                        class="btn btn-link text-dark btn-sm p-0 mr-4"
                        @click="viewInvoice(invoice)"
                      >
                        <font-awesome-icon icon="pen" size="xs" />
                      </button>
                      <button
                        class="btn btn-link text-dark btn-sm p-0 mr-2"
                        @click="copyInvoice(invoice)"
                      >
                        <font-awesome-icon icon="copy" size="xs" />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-tab>
        <b-tab title="Movimientos">
          <b-overlay :show="loadingMovs">
            <div class="row">
              <div class="col-12">
                <small>Conectar cuenta</small>
              </div>
              <div class="col-2">
                <small>SERVER</small>
                <input
                  type="text"
                  name="server"
                  class="form-control"
                  v-model="server"
                />
              </div>
              <div class="col">
                <small>RUT EMPRESA</small>
                <input
                  type="text"
                  name="rut-empresa"
                  class="form-control"
                  v-model="account.empresa"
                />
              </div>
              <div class="col">
                <small>RUT ADMINISTRADOR</small>
                <input
                  type="text"
                  class="form-control"
                  v-model="account.rut"
                  name="rut"
                />
              </div>
              <div class="col">
                <small>CONTRASEÑA</small>
                <input
                  type="password"
                  class="form-control"
                  name="pass"
                  autocomplete="nope"
                  v-model="account.pass"
                />
              </div>
               <div class="col">
                <small>SALDOS</small>
                <select class="form-control" v-model="showValues">
                  <option value="1">MOSTRAR</option>
                  <option value="2">OCULTAR</option>
                </select>
              </div>
            </div>
            <div class="row py-3">
              <div class="col">
                <small>MODO</small>
                <select class="form-control" v-model="mode">
                  <option value="en_linea">EN LINEA</option>
                  <option value="historica">HISTORICO</option>
                </select>
              </div>

              <div class="col">
                <small>FECHA INICIO</small>
                <date-picker
                  valueType="format"
                  v-model="startDate"
                  input-class=" form-control text-center"
                />
              </div>

              <div class="col">
                <small>FECHA FIN</small>
                <date-picker
                  valueType="format"
                  v-model="endDate"
                  input-class=" form-control text-center"
                />
              </div>

              <div class="col align-self-end">
                <button class="btn btn-primary btn-block" @click="loadMovs()">
                  <font-awesome-icon icon="redo" />
                </button>
              </div>
  
            </div>
            <div class="row py-3">
              <div class="col">
                <item-icon
                  title="Saldo Inicial"
                  icon="piggy-bank"
                  format
                  textClass="text-warning"
                  subtitle="Banco Estado"
                  :text="`$${bank.inicial}`"
                />
              </div>
              <div class="col">
                <item-icon
                  title="Saldo Disponible"
                  icon="piggy-bank"
                  textClass="text-success"
                  format
                  :text="`$${bank.disponible}`"
                />
              </div>
              <div class="col-3">
                <div class="click" @click="downloadMovs()">
                  <item-icon title="Descargar" icon="file-excel" text="EXCEL" />
                </div>
              </div>
            </div>
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(mov, i) in bank.movs"
                :key="`${mov.n}-${i}`"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <small>{{ mov.fecha }}</small>
                  </div>
                  <div class="col">
                    <small>{{ mov.sucursal }}</small>
                    <small class="mx-4">{{ mov.n }}</small>
                    <div class="w-100"></div>
                    <strong>{{ mov.descripcion }}</strong>
                    <div class="w-100"></div>
                    <button
                      class="btn btn-link text-dark btn-sm p-0 mr-2"
                      @click="editObservacion(mov, i)"
                    >
                      <font-awesome-icon icon="pen" size="xs" />
                    </button>
                    <small v-if="mov.observacion">{{ mov.observacion }}</small>
                    <small class="font-italic text-muted" v-else
                      >Sin asignar</small
                    >
                  </div>
                  <div class="col-2 text-success text-right">
                    <small>ABONO</small>
                    <div class="w-100"></div>
                    +${{ mov.abono | formatNumber }}
                  </div>
                  <div
                    class="col-2 text-right"
                    :class="{
                      'text-blur': showValues == 2,
                    }"
                  >
                    <small>CARGO</small>
                    <div class="w-100"></div>
                    <span
                      :class="{
                        'text-danger': mov.cargo > 0,
                      }"
                    >
                      -${{ mov.cargo | formatNumber }}</span
                    >
                  </div>
                  <div
                    class="col-2 text-right"
                    :class="{
                      'text-blur': showValues == 2,
                    }"
                  >
                    <small>SALDO</small>
                    <div class="w-100"></div>
                    <strong>${{ mov.saldo | formatNumber }}</strong>
                  </div>
                </div>
              </li>
            </ul>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<style>
.text-blur {
  /* opacity: 0.5; */
  filter: blur(10px);
  /* no selectable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
}
</style>

<script>
import itemIcon from "../../components/itemIcon";
export default {
  components: {
    itemIcon,
  },
  data() {
    return {
      server: "192.168.1.",
      startDate: this.$moment().format("YYYY-MM-DD"),
      endDate: this.$moment().format("YYYY-MM-DD"),
      mode: "historica",
      showValues: 2,
      filter: "",
      fakeFilter: "",
      status: "",
      iid: null,
      invoices: [],
      business: [],
      account: {
        rut: "18828624-0",
        empresa: "76434084-1",
        pass: "",
      },
      loadingMovs: false,
      bank: {
        movs: [],
      },
      invoice: {
        createdAt: null,
        expire: null,
        items: [],
      },
      dateFilter: null,
    };
  },
  mounted() {
    if (localStorage.getItem("bank"))
      this.bank = JSON.parse(localStorage.getItem("bank"));

    if (localStorage.getItem("server"))
      this.server = localStorage.getItem("server");
    this.dateFilter = this.$moment().format("YYYY-MM");
    //this.load();
    window.addEventListener("keyup", this.keyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyDown);
  },
  computed: {
    getInvoices() {
      let filter = this.filter.toLowerCase();
      if (filter == "") return this.invoices;
      else
        return this.invoices.filter((i) => {
          let { name } = i;
          let { custom_name, name: bname } = i.business;
          return (
            bname.toLowerCase().indexOf(filter) != -1 ||
            name.toLowerCase().indexOf(filter) != -1 ||
            custom_name.toLowerCase().indexOf(filter) != -1
          );
        });
    },
    getBusiness() {
      return this.business.map((b) => {
        return {
          label: `${b.name} - ${b.custom_name || "- - -"}`,
          _id: b._id,
        };
      });
    },
    totalPay() {
      let invoices = this.invoices.filter((i) => i.status == "pagada");
      let reducer = (total, current) => total + current.total;
      return invoices.reduce(reducer, 0);
    },
    total() {
      let invoices = this.invoices.filter((i) => i.status == "pendiente");
      let reducer = (total, current) => total + current.total;
      return invoices.reduce(reducer, 0);
    },
  },
  methods: {
    createInvoices() {
      this.$http
        .post("admin/invoice/create")
        .then(() => {
          this.load();
        })
        .catch(() => this.$noty.error("Error al crear facturas"));
    },
    closeModal() {
      this.$router.push({
        query: {},
      });
    },
    copyInvoice(invoice) {
      const month = this.$moment(invoice.createdAt)
        .add(1, "month")
        .format("MMMM");
      const year = this.$moment(invoice.createdAt)
        .add(1, "month")
        .format("YYYY");

      this.invoice = {
        ...invoice,
        name: `Pago ${month} ${year}`,
        _id: null,
        createdAt: this.$moment(invoice.createdAt)
          .add(1, "month")
          .format("YYYY-MM-DD"),
        expire: this.$moment(invoice.expire)
          .add(1, "month")
          .format("YYYY-MM-DD"),
        status: "pendiente",
        business: invoice.business._id,
        items: invoice.items.map((i) => {
          return {
            ...i,
            _id: null,
          };
        }),
      };
      this.$refs["modal"].show();
    },
    editObservacion(mov, index) {
      let observacion = prompt("Ingrese observación", mov.observacion);

      if (observacion) {
        this.$set(this.bank.movs, index, {
          ...mov,
          observacion,
        });
        this.$forceUpdate();
      }
    },
    downloadMovs() {
      console.log("downloading file");
      try {
        let movs = this.bank.movs;
        let csv =
          "FECHA;SUCURSAL;N;DESCRIPCION;OBSERVACION;ABONO;CARGO;SALDO\n";
        movs.forEach((mov) => {
          csv += `${mov.fecha};${mov.sucursal};${mov.n};${mov.descripcion};${
            mov.observacion || ""
          };${mov.abono};${mov.cargo};${mov.saldo}\n`;
        });
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "movimientos.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
      }
    },
    loadMovs() {
      this.loadingMovs = true;
      this.$http
        .post(`http://${this.server}/bancoestado`, {
          empresa: this.account.empresa,
          rut: this.account.rut,
          pass: this.account.pass,
          modo: this.mode,
          start: this.startDate.replace(/-/g, ""),
          end: this.endDate.replace(/-/g, ""),
        })
        .then((res) => {
          if (res.data.data.error)
            return this.$noty.error(res.data.data.message);
          else this.bank = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.message) this.$noty.error(err.response.message);
          else this.$noty.error("Error al cargar movimientos");
        })
        .finally(() => {
          this.loadingMovs = false;
        });
    },
    addDays(days, toDate) {
      if (toDate == 1) {
        this.invoice.createdAt = this.$moment(this.invoice.createdAt)
          .add(days, "days")
          .hour(6)
          .format("YYYY-MM-DD");
      }
      if (toDate == 2) {
        this.invoice.expire = this.$moment(this.invoice.expire)
          .add(days, "days")
          .hour(6)
          .format("YYYY-MM-DD");
      }
    },
    getTotal(data = [], status = "") {
      if (status != "") data = data.filter((i) => i.status == status);
      return data.reduce((total, current) => total + current.total, 0);
    },
    keyDown(e) {
      if (e.key == "F2") {
        this.create();
      }
    },
    totalInvoice(invoice) {
      let { items } = invoice;
      let reducer = (total, current) => total + current.total;
      return items.reduce(reducer, 0);
    },
    updateInvoice() {
      let invoice = this.invoice;
      this.$http
        .patch(`admin/invoice/${invoice._id}`, invoice)
        .then(() => {
          this.$noty.success("Success");
          this.load();
          this.$router.push({
            query: {},
          });
        })
        .catch(() => {
          this.$noty.error("Error");
        });
    },
    viewInvoice(invoice) {
      this.$router.push({
        query: {
          id: invoice._id,
        },
      });
    },
    loadInvoice() {
      let invoiceid = this.$route.query.id;
      this.$http
        .get(`admin/invoice/${invoiceid}`)
        .then((res) => {
          this.invoice = res.data.data;
          this.$refs["edit-invoice"].show();
        })
        .catch(() => {
          this.$noty.error("Error al cargar factura");
        });
    },
    addItem() {
      this.invoice.items.push({
        name: "",
        qty: 1,
        value: 0,
      });
    },
    create() {
      this.$refs["modal"].show();
    },
    saveInvoice() {
      this.$http
        .post("admin/invoice", this.invoice)
        .then(() => {
          this.load();
        })
        .catch(() => this.$noty.error("Error al guardar factura"));
    },
    load() {
      this.$http
        .get("admin/invoice", {
          params: {
            date: this.dateFilter,
          },
        })
        .then((res) => {
          this.invoices = res.data.data;
        });

      this.$http.get(`business?enabled=${this.benabled}`).then((res) => {
        this.business = res.data.data;
      });
    },
  },
  watch: {
    server: function () {
      localStorage.setItem("server", this.server);
    },
    $route: function () {
      console.log("change route");
      if (this.$route.query.id) this.loadInvoice();
    },
    dateFilter() {
      this.load();
    },
    fakeFilter: function () {
      if (this.fakeFilter == "") this.filter = "";
    },
    bank: {
      deep: true,
      handler: function () {
        localStorage.setItem("bank", JSON.stringify(this.bank));
      },
    },
  },
};
</script>