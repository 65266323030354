<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          El reporte se ha actualizado
          <router-link :to="`/tickets/report?date=${dateFilter}`"
            >Ir al reporte nuevo</router-link
          >
        </div>
      </div>
    </div>
    <b-overlay :show="loading">
      <div class="row justify-content-md-center">
        <div class="col-12">
          <h4 class="text-center" v-if="dateFilter">
            INFORME DEL DIA {{ dateFilter }}
          </h4>
        </div>
        <div
          class="col-4"
          v-if="hasPermission(user, 'ticket_list_change_date')"
        >
          <div class="row no-gutters">
            <div class="col-2">
              <button
                class="btn btn-outline-primary btn-block"
                @click="dateSubs()"
              >
                <font-awesome-icon icon="arrow-left" />
              </button>
            </div>
            <div class="col-6">
              <div class="input-group">
                <date-picker
                  valueType="format"
                  v-model="dateFilter"
                  input-class="form-control pl-4"
                />
              </div>
            </div>
            <div class="col-2">
              <button
                class="btn btn-outline-primary btn-block"
                @click="dateAdd()"
              >
                <font-awesome-icon icon="arrow-right" />
              </button>
            </div>
            <div class="col-2">
              <button class="btn btn-block btn-success" @click="load()">
                <div
                  class="spinner-border spinner-border-sm text-light mb"
                  role="status"
                  v-if="loading"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <font-awesome-icon icon="redo" v-if="!loading" />
              </button>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col py-3 text-center" v-if="cash && cash._id">
          <div class="alert alert-info">
            <h3>
              Caja abierta | {{ cash.createdAt | moment("DD-MM-YY HH:mm") }}
            </h3>
            <h5>Monto apertura: ${{ cash.amount | formatNumber }}</h5>
            <h5>Monto cierre: ${{ cash.closeAmount | formatNumber }}</h5>
            <span class="d-block">{{ user.name }}</span>
            <small>{{ user.email }}</small>
            <div class="w-100 py-2"></div>
            <button
              class="btn btn-primary px-5"
              v-if="cash.closedAt == null"
              @click="showModal()"
            >
              CERRAR TURNO
            </button>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-lg-3" style="padding: 0px 0; margin: 0">
          <br />
          <ul class="list-group">
            <li class="list-group-item" v-if="false">
              <div class="row">
                <div class="col">
                  <strong>CAJERO</strong>
                </div>
                <div class="col-3">
                  {{ cash.user ? cash.user.name : "Administrador" }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Cantidad de tickets</strong>
                  <small class="text-uppercase">Ventas generadas</small>
                </div>
                <div class="col-4 text-right">{{ tickets.length || 0 }}</div>
              </div>
            </li>
            <li class="list-group-item d-none">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Tickets pendientes</strong>
                  <small class="text-uppercase">Pendientes por enviar</small>
                </div>
                <div class="col-4 text-right">
                  {{ this.$store.state.pending_tickets.length || 0 }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Tickets cancelados</strong>
                  <small class="text-uppercase">Canceladas o anuladas</small>
                </div>
                <div class="col-4 text-right text-danger">
                  {{ ticketsDeleted }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.webEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">WebTickets</strong>
                  <small class="text-uppercase">Pedidos por la web</small>
                </div>
                <div class="col-4 text-right text-info">
                  {{ ticketsWeb }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.deliveryEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Envio a domicilio</strong>
                  <small class="text-uppercase"
                    >Cod: ENVIO o ENVIODOMICILIO</small
                  >
                </div>
                <div class="col-4 text-right text-info">
                  ${{ ticketsWebEnvioInProducts | formatNumber }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Gastos</strong>
                  <small class="text-uppercase"> Total a la fecha </small>
                </div>
                <div class="col-4 text-right">{{ expenses.length }}</div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Descuentos productos</strong>
                  <small class="text-uppercase">Realizados a productos</small>
                </div>
                <div class="col-4 text-right text-danger">
                  - ${{ formatNumber(totalDiscountProducts) }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Descuentos generales</strong>
                  <small class="text-uppercase">Realizados a tickets</small>
                </div>
                <div class="col-4 text-right text-danger">
                  - ${{ formatNumber(totalDiscount) }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.sumupEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Total SumUP</strong>
                  <small class="text-uppercase">Desde sumup.com</small>
                </div>
                <div class="col-4 text-right text-success">
                  ${{ formatNumber(sumupTotal) }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.tipEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Propinas</strong>
                  <small class="text-uppercase">Propinas en tickets</small>
                </div>
                <div class="col-4 text-right text-success">
                  ${{ formatNumber(totalTipsTickets) }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.sumupEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Propinas SumUP</strong>
                  <small class="text-uppercase">Cobros con propinas</small>
                </div>
                <div class="col-4 text-right text-success">
                  ${{ formatNumber(totalTips) }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.otEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Abonos OT</strong>
                  <small class="text-uppercase">A bonos a la fecha </small>
                </div>
                <div class="col-4 text-right text-success">
                  +${{ formatNumber(deposit) || 0 }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="info.otEnabled">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Total Tickets</strong>
                  <small class="text-uppercase">Suma total tickets</small>
                </div>
                <div class="col-4 text-right text-success">
                  +${{ formatNumber(totalTickets) || 0 }}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-5">
          <ul class="list-group mt-4">
            <li class="list-group-item text-success">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Total del dia</strong>
                  <small class="text-uppercase">Todas las ventas</small>
                </div>
                <div class="col-4 text-right font-weight-bold">
                  ${{ formatNumber(totalTickets + deposit) || 0 }}
                </div>
              </div>
            </li>
            <li class="list-group-item text-danger">
              <div class="row align-items-center">
                <div class="col font-weight-bold">
                  <strong class="d-block">Gastos del dia</strong>
                  <small class="text-uppercase">Suma de todas los gastos</small>
                </div>
                <div class="col text-right font-weight-bold">
                  -${{ formatNumber(expensesTotal) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Balance del dia</strong>
                  <small class="text-uppercase">VENTA - GASTOS</small>
                </div>
                <div class="col text-right font-weight-bold">
                  ${{
                    formatNumber(totalTickets + deposit - expensesTotal) || 0
                  }}
                </div>
              </div>
            </li>
          </ul>
          <div
            class="alert alert-warning mt-2 font-weight-bold text-center"
            v-if="ticketWithoutPayment > 0"
          >
            <font-awesome-icon icon="bell" class="mr-2" /> Tienes ${{
              formatNumber(ticketWithoutPayment)
            }}
            sin medios de pagos
          </div>
          <ul class="list-group mt-3">
            <li class="list-group-item bgwhite font-weight-bold text-uppercase">
              <div class="row">
                <div class="col">MEDIO DE PAGO</div>
                <div class="col text-right"  v-if="info.otEnabled">Tickets</div>
                <div class="col-2 text-right" v-if="info.otEnabled">OT</div>
                <div class="col text-right">TOTAL</div>
              </div>
            </li>
            <li
              class="list-group-item list-group-item-action"
              v-for="(p, i) in payments"
              :key="`p-${i}`"
            >
              <div class="row">
                <div class="col">
                  <strong>{{ p }}</strong>
                </div>
                <div class="col text-right"  v-if="info.otEnabled">
                  ${{ formatNumber(totalPayment(p)) }}
                </div>
                <div class="col-2 text-right" v-if="info.otEnabled">
                  ${{ formatNumber(totalPaymentOT(p)) }}
                </div>
                <div class="col text-right font-weight-bold">
                  ${{ formatNumber(totalPaymentOT(p) + totalPayment(p)) }}
                </div>
              </div>
            </li>
            <li class="list-group-item list-group-item-dark font-weight-bold">
              <div class="row">
                <div class="col">TOTAL</div>
                <div class="col text-right">
                  ${{ totalInPayments | formatNumber }}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <small class="pt-2" v-if="cashers.length > 0">CAJEROS</small>
          <ul class="list-group" v-if="cashers.length > 0">
            <li
              class="list-group-item"
              v-for="(s, i) in cashers"
              :key="`casher-${i}`"
            >
              <div class="row no-gutters align-items-center">
                <div class="col-1">
                  <font-awesome-icon
                    :class="{}"
                    icon="user-circle"
                    size="2x"
                  ></font-awesome-icon>
                </div>
                <div class="col pl-3">
                  <strong class="d-block text-uppercase">{{
                    s._id.name
                  }}</strong>
                  <small class="d-block">{{ s._id.email }}</small>
                </div>
                <div class="col-3 ml-auto font-weight-bold">
                  <span class="d-block text-uppercase text-right">
                    ${{ formatNumber(s.total) }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div v-if="info.deliveryEnabled">
            <small class="pt-2 mt-2 d-block"
              >REPARTOS CON ENVIO - ENVIODOMICILIO</small
            >
            <ul class="list-group" v-if="deliverys.length > 0">
              <li
                class="list-group-item"
                v-for="(s, i) in deliverys"
                :key="`delivery-${i}`"
              >
                <div class="row no-gutters align-items-center">
                  <div class="col-1">
                    <font-awesome-icon
                      :class="{}"
                      icon="user-circle"
                      size="2x"
                    ></font-awesome-icon>
                  </div>
                  <div class="col pl-3">
                    <strong class="d-block text-uppercase">{{ s.name }}</strong>
                    <small class="d-block">{{ s.qty }} TICKETS</small>
                  </div>
                  <div class="col-3 ml-auto font-weight-bold">
                    <span class="d-block text-uppercase text-right">
                      ${{ formatNumber(s.total) }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group" v-if="deliverys.length == 0">
              <li class="list-group-item">
                <h4 class="text-center py-3">Sin informacion</h4>
              </li>
            </ul>
          </div>
          <div v-if="info.tipEnabled">
            <small class="pt-2 mt-2 d-block"
              >PROPINAS EN TICKETS POR USUARIO</small
            >
            <ul class="list-group" v-if="tips.length > 0">
              <li
                class="list-group-item"
                v-for="(s, i) in tips"
                :key="`delivery-${i}`"
              >
                <div class="row no-gutters align-items-center">
                  <div class="col-1">
                    <font-awesome-icon
                      :class="{}"
                      icon="user-circle"
                      size="2x"
                    ></font-awesome-icon>
                  </div>
                  <div class="col pl-3">
                    <strong class="d-block text-uppercase">{{ s.name }}</strong>
                    <small class="d-block">{{ s.qty }} TICKETS</small>
                  </div>
                  <div class="col-3 ml-auto font-weight-bold">
                    <span class="d-block text-uppercase text-right">
                      ${{ formatNumber(s.total) }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group" v-if="tips.length == 0">
              <li class="list-group-item">
                <h4 class="text-center py-3">Sin informacion</h4>
              </li>
            </ul>
          </div>

          <div v-if="info.openCashRequired">
            <small class="d-block mt-3">CIERRES DE CAJA</small>
            <ul class="list-group">
              <li class="list-group-item" v-if="cashDay.length == 0">
                <h4 class="text-center py-3">Sin informacion</h4>
              </li>
              <li
                class="list-group-item"
                v-for="(cash, i) in cashDay"
                :key="`cashday-${i}`"
              >
                <div class="row align-items-center">
                  <div class="col">
                    <strong class="d-block">{{
                      cash.user && cash.user.name
                    }}</strong>
                    <div :id="'tooltip-' + cash._id">
                      <small class="text-uppercase" v-if="diffDay(cash)">
                        {{ cash.createdAt | moment("DD/MM/YY") }}
                      </small>
                      <small class="text-uppercase font-weight-bold">
                        {{ cash.createdAt | moment("HH:mm") }}
                      </small>
                      <small>-</small>
                      <small class="text-uppercase" v-if="diffDay(cash)">
                        {{ cash.closedAt | moment("DD/MM/YY") }}
                      </small>
                      <small class="text-uppercase font-weight-bold">
                        {{ cash.closedAt | moment("HH:mm") }}
                      </small>
                    </div>
                    <b-tooltip
                      :target="'tooltip-' + cash._id"
                      triggers="hover"
                      placement="left"
                    >
                      INICIO - CIERRE
                    </b-tooltip>
                  </div>
                  <div class="col-auto text-right">
                    <span class="text-success"
                      >${{ cash.amount | formatNumber }}</span
                    >
                    <div class="w-100"></div>
                    <small> ${{ cash.closeAmount | formatNumber }}</small>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="info.beEnabled">
            <small class="d-block mt-3">DOCUMENTOS</small>
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <strong class="d-block">Documentos SII</strong>
                    <small class="text-uppercase">
                      Total documentos emitidos
                    </small>
                  </div>
                  <div class="col-2 text-center">
                    {{ totalDTE }}
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <strong class="d-block">Comprobantes de venta</strong>
                    <small class="text-uppercase">
                      Total comprobantes emitidos
                    </small>
                  </div>
                  <div class="col-2 text-center">
                    {{ totalNoDTE }}
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <small class="d-block mt-3" v-if="info.deliveryEnabled"
            >TIPO DE PEDIDOS</small
          >
          <ul class="list-group" v-if="info.deliveryEnabled">
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Repartos</strong>
                  <small class="text-uppercase"
                    >Total de pedidos para reparto</small
                  >
                </div>
                <div class="col-2 text-center">
                  {{ totalDelivery }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row align-items-center">
                <div class="col">
                  <strong class="d-block">Retiros</strong>
                  <small class="text-uppercase"
                    >Total de pedidos para retiro</small
                  >
                </div>
                <div class="col-2 text-center">
                  {{ totalLocal }}
                </div>
              </div>
            </li>
          </ul>

          <b-button
            :aria-expanded="visibleCat ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visibleCat = !visibleCat"
            class="btn-block mt-4"
          >
            DETALLE POR CATEGORIAS VENTAS
          </b-button>
          <b-collapse id="collapse-4" v-model="visibleCat" class="mt-2">
            <div class="" style="height: 300px; overflow-y: scroll">
              <ul class="list-group">
                <li class="list-group-item" v-if="perCategory.length == 0">
                  <h4 class="text-center p-3">Sin informacion</h4>
                </li>
                <li
                  class="list-group-item"
                  v-for="(t, i) in perCategory"
                  :key="`perCategory-${i}`"
                >
                  <div class="row">
                    <div class="col-6">
                      <span class="font-weight-bold">
                        {{ t._id.name }}
                      </span>
                    </div>
                    <div class="col">${{ formatNumber(t.total) }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </b-collapse>

          <div v-if="info.otEnabled">
            <b-button
              :aria-expanded="visibleCatOt ? 'true' : 'false'"
              aria-controls="collapse-4"
              @click="visibleCatOt = !visibleCatOt"
              class="btn-block mt-2"
            >
              DETALLE POR CATEGORIAS OT
            </b-button>
            <b-collapse id="collapse-4" v-model="visibleCatOt" class="mt-2">
              <div class="" style="height: 300px; overflow-y: scroll">
                <div class="text-center">
                  <small class="d-block alert alert-info"
                    >Equivale a las OT generadas, no tiene relacion con los
                    abonos</small
                  >
                </div>
                <ul class="list-group">
                  <li class="list-group-item" v-if="otCategory.length == 0">
                    <h4 class="text-center p-3">Sin informacion</h4>
                  </li>
                  <li
                    class="list-group-item"
                    v-for="(t, i) in otCategory"
                    :key="`otCategory-${i}`"
                  >
                    <div class="row">
                      <div class="col-6">
                        <span class="font-weight-bold">
                          {{ t._id.name }}
                        </span>
                      </div>
                      <div class="col">${{ formatNumber(t.total) }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </b-collapse>
          </div>

          <div v-if="info.otEnabled">
            <b-button
              :aria-expanded="visibleDeposit ? 'true' : 'false'"
              aria-controls="collapse-4"
              @click="visibleDeposit = !visibleDeposit"
              class="btn-block mt-2"
            >
              DETALLE ABONOS
            </b-button>
            <b-collapse id="collapse-4" v-model="visibleDeposit" class="mt-2">
              <div class="" style="height: 300px; overflow-y: scroll">
                <ul class="list-group">
                  <li class="list-group-item" v-if="deposits.length == 0">
                    <h4 class="text-center p-3">Sin informacion</h4>
                  </li>
                  <li
                    :class="{
                      'list-group-item-danger': t.deletedAt,
                    }"
                    class="list-group-item"
                    v-for="(t, i) in deposits"
                    :key="`deposit-${i}`"
                  >
                    <div class="row">
                      <div class="col-2 font-weight-bold">
                        {{ t.createdAt | moment("HH:mm") }}
                      </div>
                      <div class="col-3 font-weight-bold">
                        OT#{{ t.ot.number }}
                      </div>
                      <div class="col-4">
                        <span class="font-weight-bold">
                          {{ t.payment }}
                        </span>
                      </div>
                      <div class="col text-right">
                        ${{ formatNumber(t.amount) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </b-collapse>
          </div>

          <b-button
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible = !visible"
            class="btn-block mt-2"
          >
            DETALLE DE VENTAS
          </b-button>
          <b-collapse id="collapse-4" v-model="visible" class="mt-2">
            <div class="" style="height: 300px; overflow-y: scroll">
              <ul class="list-group">
                <li class="list-group-item" v-if="tickets.length == 0">
                  <h4 class="text-center py-3">Sin informacion</h4>
                </li>
                <li
                  class="list-group-item list-group-item-action click"
                  v-for="t in tickets"
                  :key="`ticket-${t._id}`"
                  @click="loadTicket(t)"
                  :class="{
                    'list-group-item-danger': t.deletedAt,
                  }"
                >
                  <div class="row">
                    <div class="col-12" v-if="t.deletedAt">
                      Cancelado: {{ t.reason }}
                    </div>
                    <div class="col-2">
                      <span class="font-weight-bold">
                        {{ t.createdAt | moment("HH:mm") }}
                      </span>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div
                          class="col-12 mb-1 bg-light"
                          v-for="(p, i) in t.products"
                          :key="`ticket-${t._id}-product-${i}`"
                        >
                          <div class="row align-items-center">
                            <div class="col">
                              <span class="d-block font-weight-bold">
                                {{ p.qty }} x
                                <span class="text-uppercase">
                                  {{ p.name }}
                                </span>
                              </span>
                              <small class="d-block">
                                CODIGO: {{ p.code }}
                              </small>
                              <small class="d-block">
                                VALOR UNITARIO: ${{ formatNumber(p.value) }}
                              </small>
                            </div>
                            <div class="col-3 text-right">
                              ${{ formatNumber(p.total) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-1 font-weight-bold" v-if="t.discount">
                      <div class="row">
                        <div class="col-4 ml-auto text-right">DESCUENTO</div>
                        <div class="col-3 text-right">
                          ${{ formatNumber(t.discount) }}
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-1 font-weight-bold">
                      <div class="row align-items-center">
                        <div class="col-6 ml-auto text-right">
                          Total ({{ t.payment }})
                        </div>
                        <div class="col-3 text-right bg-light">
                          ${{ formatNumber(t.total) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-collapse>

          <b-button
            :aria-expanded="visibleExpenses ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visibleExpenses = !visibleExpenses"
            class="btn-block mt-2"
          >
            DETALLE DE GASTOS
          </b-button>
          <b-collapse id="collapse-4" v-model="visibleExpenses" class="mt-2">
            <div class="" style="height: 300px; overflow-y: scroll">
              <ul class="list-group">
                <li class="list-group-item" v-if="expenses.length == 0">
                  <h4 class="text-center py-3">Sin informacion</h4>
                </li>
                <li
                  class="list-group-item"
                  v-for="t in expenses"
                  :key="`expense-${t._id}`"
                  :class="{
                    'list-group-item-danger': t.deletedAt,
                  }"
                >
                  <div class="row">
                    <div class="col-2">
                      <span class="font-weight-bold">
                        {{ t.createdAt | moment("HH:mm") }}
                      </span>
                    </div>
                    <div class="col text-uppercase font-weight-bold">
                      {{ t.name }}
                    </div>
                    <div class="col-3 text-right">
                      $ {{ formatNumber(t.value) }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-collapse>

          <b-button
            :aria-expanded="visibleOptions ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visibleOptions = !visibleOptions"
            class="btn-block mt-2"
          >
            DETALLE POR OPCIONES
          </b-button>
          <b-collapse id="collapse-4" v-model="visibleOptions" class="mt-2">
            <div class="" style="height: 650px; overflow-y: scroll">
              <ul class="list-group">
                <li class="list-group-item" v-if="options.length == 0">
                  <h4 class="text-center py-3">Sin informacion</h4>
                </li>
                <li
                  class="list-group-item"
                  v-for="(t, i) in options"
                  :key="`t-${t._id.code}-${i}`"
                >
                  <div class="row">
                    <div class="col">
                      <small> {{ t._id.code }}</small>
                      <span class="font-weight-bold d-block">
                        {{ t.total }} x {{ t._id.name }}
                      </span>
                    </div>
                    <div class="col">
                      <small class="d-block">
                        {{ t._id.option.label }}
                      </small>
                      <div
                        v-if="
                          t._id.option.values && t._id.option.values.length > 0
                        "
                      >
                        <small
                          class="font-weight-bold values"
                          v-for="selected in t._id.option.values"
                        >
                          {{ selected.name || "- - -" }}
                        </small>
                      </div>
                      <div v-else>
                        <small class="font-weight-bold selected">
                          {{ t._id.option.selected || "- - -" }}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-overlay>
    <b-modal
      ref="modal"
      title="Cierre de turno"
      @ok="closeCash"
    >
      <div class="row">
        <div class="col">
          <small>INGRESE CANTIDAD</small>
          <input
            type="number"
            ref="closeAmountInput"
            class="form-control"
            v-model="cash.closeAmount"
            @keyup.enter="closeCash"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleOptions: false,
      options: [],
      cashDay: [],
      loading: true,
      cash: {
        closeAmount: 0,
      },
      sumup: [],
      totalTips: 0,
      deposit: 0,
      deposits: [],
      categories: [],
      visible: false,
      visibleExpenses: false,
      visibleCat: false,
      visibleDeposit: false,
      visibleCatOt: false,
      perCategory: [],
      otCategory: [],
      expenses: [],
      dateFilter: null,
      users: [0],
      cashers: [],
      payments_tickets: new Map(),
      closeAmount: 0,
    };
  },
  methods: {
    loadTicket(t) {
      this.$store.commit("setTicket", t);
      this.$emit("showTicket");
    },
    diffDay(cash) {
      let date = this.$moment(cash.createdAt).format("YYYY-MM-DD");
      let date2 = this.$moment(cash.closedAt).format("YYYY-MM-DD");
      return date != date2;
    },
    closeCash() {
      //if(this.cash.closeAmount == 0) return this.$noty.error("Ingrese un monto")
      this.$http
        .post("cash/close", {
          amount: this.cash.closeAmount,
          cash: this.cash._id,
        })
        .then((res) => {
          this.$noty.success("Caja cerrada");
          this.$refs.modal.hide();
          this.load();
        })
        .catch((err) => {
          this.$noty.error(err);
        });
    },
    showModal() {
      this.$refs.modal.show();
      setTimeout(() => {
        this.$refs.closeAmountInput.focus();
      }, 500);
    },
    dateAdd() {
      this.dateFilter = this.$moment(this.dateFilter)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    dateSubs() {
      this.dateFilter = this.$moment(this.dateFilter)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    totalPayment(payment) {
      let total = 0;
      // iterate tickets
      let tickets = this.tickets.filter((t) => !t.deletedAt);
      tickets.forEach((t) => {
        // check if ticket has payments
        if (t.payments) {
          // iterate payments
          t.payments.forEach((p) => {
            // check if payment is the same
            if (p.name == payment) {
              if (p.amount == t.total) total += p.total || 0;
              else if (t.total < p.amount) total += t.total || 0;
              else if (t.total > p.amount) total += p.amount || 0;
            }
          });
        } else if (t.payment == payment) {
          total += t.total;
        }
      });
      // return this.tickets.reduce((c, t) => {
      //   if (t.deletedAt) return c;
      //   if (t.payment == payment) return c + t.total;
      //   if (t.payments && t.payments) {
      //     //  TODO
      //     console.log("Hay mas payments");
      //   }
      //   return c;
      // }, 0);
      return total;
    },
    countPayment(payment) {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + 1;
        return c;
      }, 0);
    },
    totalPaymentOT(payment) {
      return this.deposits.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + t.amount;
        return c;
      }, 0);
    },
    countPaymentOT(payment) {
      return this.deposits.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == payment) return c + 1;
        return c;
      }, 0);
    },
    async load() {
      this.loading = true;
      let category = await this.$http.get(
        `ticket/category?date=${this.dateFilter}`
      );
      this.perCategory = category.data.data;

      let cash = await this.$http.get(`cash?date=${this.dateFilter}`);
      if (cash.data.data) this.cash = cash.data.data;
      else {
        this.cash = {
          closeAmount: 0,
          closedAt: null,
        };
      }

      let cashDay = await this.$http.get(`cash/day?date=${this.dateFilter}`);
      this.cashDay = cashDay.data.data;

      let cashers = await this.$http.get(`ticket/cash?date=${this.dateFilter}`);
      this.cashers = cashers.data.data;

      if (this.info.otEnabled) {
        let otCategory = await this.$http.get(
          `ot/category?date=${this.dateFilter}`
        );
        this.otCategory = otCategory.data.data;

        let deposits = await this.$http.get(`deposit?date=${this.dateFilter}`);
        this.deposits = deposits.data.data;
        this.deposit = deposits.data.data.reduce((t, item) => {
          if (item.deletedAt) return t;
          return item.amount + t;
        }, 0);
      }

      let expenses = await this.$http.get(`expenses?date=${this.dateFilter}`);
      this.expenses = expenses.data.data;

      await this.$http.get(`ticket?date=${this.dateFilter}`).then((res) => {
        this.$store.commit("setTickets", res.data.data);
      });

      await this.$http
        .get(`ticket/option?date=${this.dateFilter}`)
        .then((res) => {
          this.options = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;

      if (this.info.sumupEnabled) {
        this.$http
          .get(`payments?date=${this.dateFilter}`)
          .then((res) => {
            this.sumup = res.data.data;
            return this.$http.get(`sumup/all?date=${this.dateFilter}`);
          })
          .then((res) => {
            this.totalTips = res.data.data.reduce((total, item) => {
              if (item.status == "success") return item.tip + total;
              return total;
            }, 0);
          })
          .catch(() => {
            //console.log(err);
            this.$noty.error("Error en sumup");
          });
      }
    },
  },
  computed: {
    totalInPayments() {
      let payments = this.payments;
      let total = 0;
      payments.forEach((p) => {
        total += this.totalPayment(p);
      });
      return total;
    },
    tips() {
      let tickets = this.tickets.filter((t) => {
        if (t.deletedAt) return false;
        if (t.tip) return true;
        return false;
      });
      // group by delivery.user
      let group = tickets.reduce((r, ticket) => {
        r[ticket.user] = [...(r[ticket.user] || []), ticket];
        return r;
      }, {});
      // map to array
      let map = Object.keys(group).map((k) => {
        return {
          _id: group[k][0].user,
          name: group[k][0].user_sell.name,
          qty: group[k].length,
          total: group[k].reduce((t, item) => {
            return t + item.tip;
          }, 0),
        };
      });
      console.log({ map });
      return map;
    },

    deliverys() {
      let tickets = this.tickets.filter((t) => {
        if (t.deletedAt) return false;
        if (
          t.type == "Domicilio" &&
          t.delivery &&
          t.delivery.user &&
          t.delivery.status == "Entregado"
        )
          return true;
        return false;
      });
      // group by delivery.user
      let group = tickets.reduce((r, ticket) => {
        r[ticket.delivery.user] = [...(r[ticket.delivery.user] || []), ticket];
        return r;
      }, {});
      // map to array
      let map = Object.keys(group).map((k) => {
        return {
          _id: group[k][0].delivery.user,
          name: group[k][0].delivery_user.name,
          email: group[k][0].delivery_user.email,
          qty: group[k].length,
          total: group[k].reduce((t, item) => {
            const delivery_item = item.products.find((p) => {
              return (
                p.code == "ENVIO" ||
                p.code == "ENVIODOMICILIO" ||
                p.code == "DELIVERY"
              );
            });
            if (delivery_item) return t + delivery_item.total;
            return t;
          }, 0),
        };
      });
      return map;
    },
    totalTipsTickets() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.tip) return c + t.tip;
        return c;
      }, 0);
    },
    ticketWithoutPayment() {
      let total = 0;
      // this.tickets.forEach((t) => {
      //   // not deleted
      //   if (!t.deletedAt) {
      //     // not payments
      //     if (t.payments && t.payments.length == 0) {
      //       console.log(t.payments);
      //       if (t.payment == null || t.payment == "") {
      //         return (total = total + t.total);
      //       }
      //     }
      //   }
      // });
      return total;
    },
    totalNoDTE() {
      return this.tickets.reduce((c, t) => {
        if (!t.isDTE && !t.deletedAt) return c + 1;
        return c;
      }, 0);
    },
    totalDTE() {
      return this.tickets.reduce((c, t) => {
        if (t.isDTE && !t.deletedAt) return c + 1;
        return c;
      }, 0);
    },
    payments() {
      if (this.info.payments) return this.info.payments.split(",");
      else return [];
    },
    info() {
      return this.$store.state.info;
    },
    expensesTotal() {
      return this.expenses.reduce((c, t) => {
        if (t.deletedAt) return c;
        return c + t.value;
      }, 0);
    },
    totalDiscount() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.discount) return c + t.discount;
        return c;
      }, 0);
    },
    totalDiscountProducts() {
      let total = 0;
      this.tickets.forEach((t) => {
        if (t.deletedAt) return false;
        else
          t.products.forEach((p) => {
            if (p.discount) {
              total += parseInt(p.value * p.qty - p.total);
            }
          });
      });
      return total;
    },
    totalTrans() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Transferencia") return c + t.total;
        return c;
      }, 0);
    },
    totalUnknown() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Desconocido") return c + 1;
        return c;
      }, 0);
    },
    totalUnknownMount() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Desconocido") return c + t.total;
        return c;
      }, 0);
    },
    totalCashCount() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Efectivo") return c + 1;
        return c;
      }, 0);
    },
    totalTransfer() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Transferencia") return c + 1;
        return c;
      }, 0);
    },
    totalCard() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Tarjeta") return c + 1;
        return c;
      }, 0);
    },
    totalDelivery() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.type == "Domicilio") return c + 1;
        return c;
      }, 0);
    },
    totalLocal() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.type == "Retiro") return c + 1;
        return c;
      }, 0);
    },
    totalCash() {
      return this.tickets.reduce((c, t) => {
        if (t.deletedAt) return c;
        if (t.payment == "Efectivo") return c + t.total;
        return c;
      }, 0);
    },
    ticketsDeleted() {
      return this.tickets.reduce((total, ticket) => {
        if (ticket.deletedAt != null) return 1 + total;
        else return total;
      }, 0);
    },
    ticketsWeb() {
      return this.tickets.reduce((total, ticket) => {
        if (ticket.deletedAt == null && ticket.webTicket) return 1 + total;
        else return total;
      }, 0);
    },
    ticketsWebEnvioInProducts() {
      return this.tickets.reduce((total, ticket) => {
        if (ticket.deletedAt == null) {
          ticket.products.forEach((p) => {
            //console.log(p.code)
            if (p.code.includes("ENVIO")) total += p.total;
            if (p.code.includes("REPARTO")) total += p.total;
          });
        }
        return total;
      }, 0);
    },
    totalTickets() {
      if (this.tickets == []) return 0;
      else {
        return this.tickets.reduce((total, ticket) => {
          if (ticket.deletedAt == null && ticket.payment != "OT")
            return ticket.total + total;
          else return total;
        }, 0);
      }
    },
    totalItems() {
      return null;
    },
    tickets() {
      if (this.dateFilter == this.$moment().format("YYYY-MM-DD")) {
        return [
          ...this.$store.state.pending_tickets,
          ...this.$store.state.tickets,
        ];
      } else {
        return this.$store.state.tickets;
      }
    },
    sumupTotal() {
      return this.sumup.reduce((total, sumup) => {
        if (sumup.status == "FAILED") return total;
        else return total + sumup.amount;
      }, 0);
    },
  },
  mounted() {
    this.dateFilter = this.$route.query.date;
    // this.load();
  },
  watch: {
    dateFilter() {
      this.load();
      this.$router.push({ query: { date: this.dateFilter } });
    },
  },
};
</script>

<style scoped>
th {
  font-size: 12px;
}
</style>
